import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import {
  assertPaginationPageNumber,
  FakePaginationProps,
  formatNumberToCurrency,
} from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Util } from "@/utils/utility";
import { usePagination } from "@/hooks/usePagination";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { useDispatch, useSelector } from "react-redux";
// import { currencies } from "..";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { BugissApi } from "@/redux/slices";
import Redacted from "@/components/common/shimmer/redact";
import { useAppDispatch } from "@/redux/store";

const Table = ({ tab }: { tab: Currencies }) => {
  const {
    currentPage,
    setCurrentPage,
    searchQuery,
    filterValue,
    setSearchQuery,
    setFilterValue,
  } = usePagination();

  const { data, isFetching, isLoading, refetch } =
    businessReduxAPI.useGetGlobalAccountsQuery({
      current_page: currentPage,
      search_term: searchQuery,
      ...filterValue,
      currency: tab.code,
    });

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "EMAIL ADDRESS": "email",
    "MOBILE NUMBER": "phone",
    "DATE JOINED": "created_at",
    STATUS: ["block_business", ""],
  };

  const loading = isLoading || isFetching;

  const result = data?.global_accounts;
  const accounts = data?.global_accounts?.data;
  const navigate = useNavigate();
  const { isRedacting } = useSelector((state: RootState) => state.general);
  const [statementFunc, setStatementFunc] = useState<boolean>(false);
  const [bizId, setBizId] = useState("");
  const [accountId, setAccountId] = useState("");
  const handleExport = useIsolatedExport({
    setFilterValue,
    data: result,
    loading,
    valuePair,
    filterValue,
    title: "Global Account Export(Bugiss)",
    dataPath: "data",
    // apiFunction: BugissApi.business.getTransactions,
  });

  const { toggleModal } = useExportModal(handleExport);

  const dispatch = useAppDispatch();
  async function handleExportStatement() {
    const exportVal: any = exportValue;
    const resp = await dispatch(
      BugissApi.business.generateStatement({
        business_id: bizId as string,
        send_to: "download",
        currency: tab?.code,
        file_type: exportValue.fileType,
        date: `${exportValue.start_date.replaceAll(
          "/",
          "-"
        )}_${exportValue.end_date.replaceAll("/", "-")}`,
        currency_type: "foreign",
        account_id: String(accountId),
      })
    );

    if (resp.payload?.status === "success") {
      const url = resp.payload.data.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Statement of Account #${bizId}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      clearExportValue();
      toggleModal(false);

      setStatementFunc(false);
    }
  }
  const {
    toggleModal: toggleExport,
    exportValue,
    clearExportValue,
  } = useExportModal(statementFunc ? handleExportStatement : () => {});

  return (
    <div>
      <SmartFilter
        pagination={{
          currentPage,
          itemsPerPage: assertPaginationPageNumber(result?.per_page as number),
          totalItems: assertPaginationPageNumber(result?.total),
          onPageChange: function (page: number): void {
            setCurrentPage(page);
          },

          activeFilter: "All Global Account",
        }}
        onExport={() => toggleModal("Export Global Accounts")}
        onSearchChange={(e: string) => setSearchQuery(e)}
        // onFilter={(e: any) => {
        //   setFilterValue(Util.filterStruct(e) as any);
        // }}
        hideFilter
        searchTitle="Search global accounts..."
      />
      {(loading || accounts?.length === 0 || !Boolean(accounts)) &&
      (!isRedacting || accounts?.length === 0) ? (
        <div style={{ height: "60.5vh" }}>
          <EmptyScreen loading={loading} />
        </div>
      ) : (
        <div>
          <RavenTable
            headerList={[
              "BUSINESS NAME",
              "BUSINESS EMAIL",
              "BALANCE",
              "DATE CREATED",
              " ",
            ]}
          >
            {accounts?.map((chi, idx) => {
              return (
                <RavenTableRow
                  key={idx}
                  onRowClick={() => {
                    navigate(`${chi?.id}?biz=${chi?.business_id}`);
                  }}
                  one={
                    loading ? (
                      <Redacted.Table />
                    ) : (
                      <Avatar name={Util.safeValue(chi?.business_name)} full />
                    )
                  }
                  two={loading ? <Redacted.Table /> : Util.safeValue(chi?.business_email)}
                  three={
                    loading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(chi?.balance, chi?.currency)
                    )
                  }
                  four={loading ? <Redacted.Table /> : formatDateTime(chi?.created_at)}
                  five={
                    loading ? (
                      <Redacted.Avatar />
                    ) : (
                      <Dropdown
                        rightPosition
                        items={[
                          { label: "Generate Statement", img: IconVault(icons.file) },
                          {
                            label: "View Account Information",
                            img: IconVault(icons.arrow_circle_broken),
                          },
                          // {
                          //   label: "Lien",
                          //   img: IconVault(
                          //     React.cloneElement(icons.info_circle_inherit, {
                          //       stroke: "#020202",
                          //     })
                          //   ),
                          // },
                        ]}
                        onChange={(e: any) => {
                          if (e.label.includes("View")) {
                            navigate(`${chi?.id}?biz=${chi?.business_id}`);
                          } else if (e.label.includes("Generate")) {
                            setStatementFunc(true);
                            toggleExport("Generate Wallet Statement");
                            setAccountId(String(chi?.id));
                            setBizId(String(chi?.business_id));
                          }
                        }}
                        buttonChild={() => {
                          return <ActionDrop more />;
                        }}
                      />
                    )
                  }
                />
              );
            })}
          </RavenTable>
        </div>
      )}
    </div>
  );
};

export const BGlobalAccounts = () => {
  const activeCurr = useSelector(
    (state: RootState) => state.general
  ).selectedGlobalCurrency;
  return <Table tab={activeCurr as any} />;
};
