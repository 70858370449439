/* eslint-disable jsx-a11y/iframe-has-title */
import React, { ReactElement, useEffect, useState } from "react";
import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import { RavenInputField, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { AvatarWithTwinValue } from "../../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
  mapHotkey,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../../components/common/modal/ActionModal";
import TwinValue from "@/components/common/twinValue";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "../styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import { detectStatus } from "@/utils/helpers";
import ImagePreview from "@/components/common/imagePreview";
import { cn } from "@/utils/colorConvert";
import Gap from "@/components/common/styleComponents/Gap";
import { CopyText } from "@/components/common/copyCheck";
import { XStack } from "@/components/common/stacks";
import { FaLink } from "react-icons/fa";
import { BIZ_VERIFICATION_STATUS } from "../../__internals__/constants";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { useAppDispatch } from "@/redux/store";

const Tabs = [
  {
    label: "BVN Verification",
    value: "bvn",
  },
  { label: "NIN Verification", value: "nin" },

  {
    label: "Address Verification",
    value: "address",
  },
  {
    label: "CAC Verification",
    value: "cac",
  },
  {
    label: "Business Details",
    value: "business_info",
  },
  {
    label: "Details Checker",
    value: "details_checker",
  },
];

const BCacVerification = ({ tab }: { tab?: string }) => {
  const [empty, setEmpty] = useState(false);
  const [revokeModal, showRevokeModal] = useState({
    chi: {
      label: undefined,
      value: undefined,
    },
    on: false,
  });
  const [declineModal, showDeclineModal] = useState({
    chi: {
      label: undefined,
      value: undefined,
    },
    on: false,
  });
  const [filterValue, setFilterValue] = useState<any>({});
  const [selectedDirectorTab, setSelectedDirectorTab] = useState<any>("CAC Details");
  const [tabIndex, setTabIndex] = useState(0);
  const [verify, showVerifyModal] = useState<any>({
    chi: "",
    on: false,
    address_url: "",
  });
  const [view, setView] = useState<{ on: boolean; chi: Partial<VerificationDetails> }>({
    chi: {},
    on: false,
  });
  // const navigate = useNavigate();
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<{
    label: string;
    value: string;
  }>({ label: "CAC Verification", value: "cac" });

  const tableHeader = [
    "Business Details",
    "Business Type",
    "RC Number",
    "Date",
    "Status",
    "",
  ];

  const dropdown = [
    {
      label: `Verify ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.info_circle),
      func: showVerifyModal,
    },
    {
      label: `Revoke ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
    {
      label: `Decline ${selectedTab.label.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showDeclineModal,
    },
  ] as const;

  function returnDropdown(status: SN) {
    if (status === 1) {
      return [dropdown[0], dropdown[2]];
    } else if (status === 3) {
      return [dropdown[1]];
    } else return [dropdown[0]];
  }
  const dispatch = useAppDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: selectedTab.value.trim(),
        current_page: currentPage,
        search_term: searchQuery,
        status: BIZ_VERIFICATION_STATUS.PENDING,
        ...filterValue,
      })
    );
  }, [selectedTab, currentPage, searchQuery, filterValue]);

  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  type Business = BusinessComplianceModel["business"];
  type DirectorDetails = BusinessComplianceModel["directors_details_object"];
  type Verifications = BusinessComplianceModel["business_verification_details"];
  const businesses: Business = business_compliances?.data?.business;
  const director_details: DirectorDetails =
    business_compliances?.data?.directors_details_object;
  const compliance=
    business_compliances?.data?.business_verification_details;

  const assertVeri = (email: string): any => {
    var data: any = compliance[email as any];

    // const filter = data.filter((d) => d.type === selectedTab.value);
    const l = data?.filter(
      (d: VerificationDetails) => d?.id_verification_type === selectedTab?.value
    )[0];

    if (l) {
      return l;
    } else {
      return [];
    }
  };
  const { isRedacting } = useSelector((store: RootState) => store.general);

  const handleRevokeDecline = async () => {
    const data: Partial<Business[0]> = view.chi;

    const payload = {
      status: 0,
      business_id: view.chi.business_owner_id,
      cac_decline_reason: Boolean(revokeModal?.chi?.label)
        ? revokeModal?.chi?.label
        : declineModal?.chi?.label,
      action_to_take: declineReason.find((d) => d.reason === declineModal.chi?.label)
        ?.action,
      deny_or_revoke: assertIsRevoke(data?.cac ?? 0),
    };

    const resp = await dispatch(BugissApi.business.verifyDeclineCacVerification(payload));

    if (resp?.payload) {
      showRevokeModal({
        on: false,
        chi: {
          label: undefined,
          value: undefined,
        },
      });

      showVerifyModal({
        on: false,
        chi: "",
      });

      showDeclineModal({
        on: false,
        chi: {
          label: undefined,
          value: undefined,
        },
      });
    }

    setView({ on: false, chi: {} });

    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "cac",
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
        status: filterValue?.filterValue?.split(",")[0],
      })
    );
  };

  const handleVerifyCac = async () => {
    const resp = await dispatch(
      BugissApi.business.verifyDeclineCacVerification({
        status: 1,
        business_id: view.chi.business_owner_id,
        deny_or_revoke: "",
      })
    );

    if (resp?.payload) {
      showRevokeModal({
        on: false,
        chi: {
          label: undefined,
          value: undefined,
        },
      });

      showVerifyModal({
        on: false,
        chi: "",
      });

      showDeclineModal({
        on: false,
        chi: {
          label: undefined,
          value: undefined,
        },
      });
    }

    setView({ on: false, chi: {} });

    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "cac",
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
        status: filterValue?.filterValue?.split(",")[0],
      })
    );
  };

  const navigate = useNavigate();

  const reasonReq = businessReduxAPI.useFetchReasonsQuery(
    { type: "cac_decline" },
    { skip: !declineModal?.on }
  );

  const declineReason = reasonReq?.data?.reasons ?? [];

  function formatDeclineReason() {
    const newReason = declineReason.map((reason) => {
      return {
        label: Util.cleanText(reason?.reason),
        value: reason?.id,
      };
    });

    return newReason;
  }

  function handleKeyDown(event: KeyboardEvent): void {
    if (event.key === "ArrowDown") {
      handleDirectorsPrev(
        view,
        director_details,
        selectedDirectorTab,
        tabIndex,
        setTabIndex
      );
    } else if (event.key === "ArrowUp") {
      handleDirectorsNext(
        view,
        director_details,
        selectedDirectorTab,
        tabIndex,
        setTabIndex
      );
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [tabIndex]);

  console.log(loading, "fi");

  useEffect(() => {
    setSelectedTab(Tabs.find((d) => d.value === tab) as any);
  }, [tab]);
  return (
    <>
      <div>
        <div style={{ marginTop: "2rem" }}></div>
        <SmartFilter
          page="compliance"
          // simple
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
            // reset current page to 1 on filter
            setCurrentPage(1);
          }}
          defaultFilterBy="status"
          filters={[
            {
              label: "Status",
              filterBy: "status",
              options: [
                {
                  label: "Pending",
                  value: "0",
                },
                {
                  label: "Completed",
                  value: "1",
                },
              ],
            },
          ]}
          onSearchChange={(e: string) => setSearchQuery(e)}
          searchTitle={`Search ${selectedTab.label}...`}
          pagination={{
            currentPage: currentPage,
            itemsPerPage: Number(business_compliances.per_page),
            totalItems: Number(business_compliances.total),
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
            activeFilter: `All ${selectedTab}`,
          }}
        />

        {!businesses || businesses?.length === 0 || (loading && !isRedacting) ? (
          <EmptyScreen loading={loading} />
        ) : (
          <div>
            <div className="table">
              <RavenTable action={false} className="table__main" headerList={tableHeader}>
                {businesses?.map((data, index) => {
                  const veriData: VerificationDetails = assertVeri(data.email);

                  // console.log(data, "the within", data.email);
                  return (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => {
                        // if (!veriData.flow_id) {
                        //   toast.error("No verification details found for user");
                        // } else
                        setView({
                          on: true,
                          chi: {
                            ...veriData,
                            ...data,
                            business_owner_id: data.id,
                            email: data.email,
                          },
                        });
                      }}
                      {...{
                        one: loading ? (
                          <Redacted.AvatarWithValue />
                        ) : (
                          <div className="table-icon-row">
                            <AvatarWithTwinValue
                              name={Util.safeValue(data.business_name)}
                              one={Util.safeValue(data.business_name)}
                              two={Util.safeValue(
                                data.business_details_from_central.business_email
                              )}
                              // full
                            />
                          </div>
                        ),

                        two: loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(data.business_details_from_central.business_type)
                        ),
                        three: loading ? (
                          <Redacted.Table />
                        ) : (
                          <div onClick={(e) => e.stopPropagation()}>
                            <CopyText
                              text={Util.safeValue(
                                data.business_details_from_central.registration_number
                              )}
                            />
                          </div>
                        ),
                        four: loading ? (
                          <Redacted.Table />
                        ) : (
                          formatDateTime(data.created_at)
                        ),
                        // five: loading ? (
                        //   <Redacted.Table />
                        // ) : (
                        //   Util.safeValue(formatDateTime(data.created_at))
                        // ),
                        six: loading ? (
                          <Redacted.Table />
                        ) : (
                          formatStatus(detectStatus(data.cac, "compliance"))
                        ),
                        seven: loading ? (
                          <Redacted.Badge />
                        ) : (
                          <div>
                            <Dropdown
                              items={returnDropdown(data.cac)}
                              defaultValue={dropdown[0]}
                              onChange={(e: any) => {
                                e.event.stopPropagation();
                                setView({
                                  on: false,
                                  chi: {
                                    ...veriData,
                                    ...data,
                                    business_owner_id: data.id,
                                  },
                                });

                                e.func({ chi: "", on: true });
                              }}
                              className="ragna-dropdown"
                              buttonChild={({ selected }) => (
                                <ActionDrop
                                  more
                                  // onMoreClick={() => {
                                  //   navigate("/personal-users/re43rfs");
                                  // }}
                                />
                              )}
                            />
                          </div>
                        ),
                      }}
                    />
                  );
                })}
              </RavenTable>
            </div>
          </div>
        )}
      </div>
      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          showRevokeModal({
            chi: {
              label: undefined,
              value: undefined,
            },
            on: false,
          });
        }}
        onClick={handleRevokeDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${selectedTab.label}?`}
      >
        <>
          <p>
            Are you sure you want to cancel the verification process? You can always come
            back to perform the action..
          </p>
          <Gap size={20} />
          <RavenInputField
            color={"black-light"}
            onChange={(e: any) => {
              showRevokeModal({
                ...revokeModal,
                chi: e,
              });
            }}
            type="select"
            selectOption={formatDeclineReason()}
            value={revokeModal.chi}
            label={"Revoke reason*"}
            placeholder="e.g 'Fake CAC'"
          />
        </>
      </ActionModal>

      <ActionModal
        visible={declineModal.on}
        onCancel={() => {
          showDeclineModal({
            chi: {
              label: undefined,
              value: undefined,
            },
            on: false,
          });
        }}
        onClick={handleRevokeDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Decline"
        btnColor="#FF0F00"
        body="Are you sure to decline this verification data? You can always come back to perform the action.."
        title={`Decline ${selectedTab.label}?`}
      >
        <>
          <p>
            Are you sure to decline this verification data? You can always come back to
            perform the action..
          </p>
          <Gap size={20} />
          <RavenInputField
            color={"black-light"}
            onChange={(e: any) => {
              showDeclineModal({
                ...declineModal,
                chi: e,
              });
            }}
            type="select"
            selectOption={formatDeclineReason()}
            value={declineModal.chi}
            label={"Decline reason*"}
            placeholder="e.g 'Fake CAC'"
          />
        </>
      </ActionModal>

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        loading={loading}
        cancelText="No, Cancel"
        onClick={handleVerifyCac}
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure to verify this compliance details? You can always come back to perform the action.."
        title={`Verify ${selectedTab.label}?`}
      ></ActionModal>

      <div className={cn(selectedTab.value, "biz-info-modal")}>
        <ActionModal
          visible={view.on}
          onCancel={() => {
            setView({ chi: {}, on: false });
            setSelectedDirectorTab("CAC Details");
            setTabIndex(0);
          }}
          big={true}
          cancelText="No, Cancel"
          actionText="View Full Profile"
          onClick={() => {
            navigate(
              `/business-businesses/${
                view.chi.email && view.chi.email?.length > 5
                  ? view.chi.email
                  : view?.chi?.alt_email
              }/transactions`
            );
          }}
          btnColor="black"
          loading={loading}
          hideCancel
          body="Are you sure to verify this compliance data? You can always come back to perform the action.."
          title={`${selectedTab.label}`}
        >
          {selectedTab.value === "cac" && (
            <div style={{ marginTop: "2rem" }}>
              <TabComponent
                onTabValueChange={(e: any) => {
                  if (e === "Director Details" && tabIndex === 0) {
                    setTabIndex(1);
                  }
                  setSelectedDirectorTab(e);
                }}
                tabs={renderCacTab(view.chi, director_details)}
                defaultValue={renderCacTab(view.chi, director_details)[0]}
              >
                <TabComponent.Tabs tabs={renderCacTab(view.chi, director_details)} />
              </TabComponent>
            </div>
          )}

          {selectedDirectorTab === "Director Details" && (
            <div className="cac-details__pagination">
              <figure
                onClick={() => {
                  handleDirectorsPrev(
                    view,
                    director_details,
                    selectedDirectorTab,
                    tabIndex,
                    setTabIndex
                  );
                }}
              >
                {icons.arrow_forward_circular}
              </figure>

              <span>
                <p>
                  {tabIndex} of{" "}
                  {assertDirectorList(view.chi, director_details)?.length ?? 0}{" "}
                  {" Directors "}
                </p>
              </span>

              <figure
                onClick={() => {
                  handleDirectorsNext(
                    view,
                    director_details,
                    selectedDirectorTab,
                    tabIndex,
                    setTabIndex
                  );
                }}
              >
                {icons.arrow_forward_circular}
              </figure>
            </div>
          )}

          {
            <div className="biz-compliance-results">
              {renderCacImage(view.chi)}

              <div className="compliance-results__main">
                <InlineDetails
                  direction={""}
                  className="no-shadow"
                  title={"Verification Details"}
                  simple
                  isTransaction={
                    selectedTab.value === "business_info" || selectedTab.value === "cac"
                  }
                  amount={0}
                  onClick={() => {}}
                  content={cacComplianceData({
                    data: view.chi,
                    selectedTab: selectedDirectorTab,
                    activeIndex: tabIndex,
                    directors: assertDirectors(director_details, view.chi),
                  })}
                />
              </div>
            </div>
          }
        </ActionModal>
      </div>
    </>
  );
};

export default BCacVerification;

function handleDirectorsPrev(
  view: { on: boolean; chi: Partial<VerificationDetails> },
  director_details: DirectorDetails,
  selectedDirectorTab: any,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {
  const rectors = assertDirectorList(view.chi, director_details);

  if (selectedDirectorTab.includes("director") && tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === 1) {
      setTabIndex(rectors?.length);
    } else {
      setTabIndex(tabIndex - 1);
    }
  }
}

function handleDirectorsNext(
  view: { on: boolean; chi: Partial<VerificationDetails> },
  director_details: DirectorDetails,
  selectedDirectorTab: any,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {
  const rectors = assertDirectorList(view.chi, director_details);

  if (selectedDirectorTab === "Director Details" && tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === rectors?.length) {
      setTabIndex(1);
    } else {
      setTabIndex(tabIndex + 1);
    }
  }
}

function renderCacImage(data: Partial<Business>): React.ReactNode {
  return (
    <ImagePreview
      imgData={[
        {
          src: Util.safeValue(data?.business_details_from_central?.cac_certificate),
          alt: "image",
        },
      ]}
    />
  );
}

function cacComplianceData({
  data,
  selectedTab,
  directors,
  activeIndex,
}: {
  data: Partial<Business>;
  selectedTab: string;
  directors: SingleDirector[];
  activeIndex?: SN;
}): {
  label: string;
  activeIndex?: SN;
  value: string | ReactElement;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  function detectIndex(text: string) {
    const match = text.match(/\d+/);
    return match ? Number(match[0]) : null;
  }

  const index = detectIndex(selectedTab);

  const director = directors && directors[(Number(activeIndex) - 1)];

  if (selectedTab.includes("CAC")) {
    return [
      {
        label: "Business Name",
        value: Util.safeValue(data.business_name),
      },

      {
        label: "Business Email",
        value: Util.safeValue(data.business_owner_email),
        copy: true,
        trim: true,
      },

      {
        label: "Business Type",
        value: Util.safeValue(
          capitalizeFirstLetter(data?.business_details_from_central?.business_type)
        ),
      },

      {
        label: "RC Number",
        value: Util.safeValue(data?.business_details_from_central?.registration_number),
      },

      {
        label: "Date Authorized",
        value: Util.safeValue(formatDateTime(data.created_at ?? "")),
      },
    ];
  } else {
    return [
      {
        label: "Full Name",
        value: Util.join(Util.safeValue(director?.fname)).with(
          Util.safeValue(director?.lname)
        ),
      },

      {
        label: "Directors Email",
        value: Util.safeValue(director?.email),
      },

      {
        label: "Mobile Number",
        value: Util.safeValue(capitalizeFirstLetter(director?.phone_number)),
      },

      {
        label: "BVN",
        value: Util.safeValue(capitalizeFirstLetter(director?.bvn)),
      },
      {
        label: "NIN",
        value: Util.safeValue(capitalizeFirstLetter(director?.nin)),
      },

      {
        label: "Verification Status",
        value: formatStatus(detectStatus(Number(director?.bvn_verified))) as ReactElement,
      },
      {
        label: "Full Address",
        value: Util.safeValue(director?.address),
      },
    ];
  }
}

function renderCacTab(data: Partial<Business>, compliance: any): string[] {
  try {
    var director_details: any = compliance[data?.business_owner_email as any] ?? [];

    let directorMap = [];
    directorMap = director_details?.map((d: any, idx: string) => {
      return `Director ${idx + 1}`;
    });

    if (directorMap?.length > 0) {
      return ["CAC Details", "Director Details"];
    } else {
      return ["CAC Details"];
    }
  } catch (error) {
    return [];
  }
}

function assertDirectorList(data: Partial<Business>, compliance: any): string[] {
  try {
    var director_details: any = compliance[data?.business_owner_email as any] ?? [];

    return director_details;
  } catch (error) {
    return [];
  }
}

const assertDirectors = (compliance: DirectorDetails, data: any): any => {
  try {
    var director_details: any = compliance[data?.business_owner_email as any] ?? [];

    return director_details;
  } catch (error) {
    return [];
  }
};

function assertIsRevoke(status: SN) {
  if (status === 3) {
    return "revoke";
  } else return "deny";
}
