import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import { RavenTable } from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import "../../styles/index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchBizTransactions } from "@/redux/switches/business/transaction.sw";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { $event } from "@/utils/events/eventEmitter";
import { useParams, useSearchParams } from "react-router-dom";
import { detectSingleBizValuePair } from "@/apps/business/businesses/__exports__/value_pairs";
import BusinessUsersTables from "../../tables/BusinessTables";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { detectStatus, renderConditionally } from "@/utils/helpers";
import { BIZ_TRANSACTION_STATUS } from "@/apps/business/__internals__/constants";
import { useAppDispatch } from "@/redux/store";

const SingleBusinessTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { email } = useParams();
  let [searchParams] = useSearchParams();
  const bulk_ref = searchParams.get("bulk_ref") ?? undefined;
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});

  const header = ["REFERENCE ID", "TYPE", "DESCRIPTION", "DATE", "AMOUNT", "STATUS", ""];

  let timeoutId: ReturnType<typeof setTimeout>;

  // Debounce function to delay execution
  const debounce = <F extends (...args: any[]) => void>(func: F, delay: number) => {
    return function (this: ThisParameterType<F>, ...args: Parameters<F>) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // declare const
  const { transactions } = useSelector((state: RootState) => state.businessTransactions);

  const { loading } = useSelector((state: RootState) => state.businessBusinesses);

  const trx = transactions?.data ?? [];

  const Tables = new BusinessUsersTables({ type: "transactions", trx: trx });
  const dispatch = useAppDispatch();
  const [exportTriggered, triggerExport] = useState(false);
  useEffect(() => {
    dispatch(toggleRedact(true));
    const debouncedDispatch = debounce(() => {
      dispatch(
        fetchBizTransactions({
          identifier: "transactions",
          payload: {
            business_email: email as string,
            // business_id: businessId,
            current_page: currentPage,
            bulk_transfer_reference: bulk_ref,
            search_term: searchQuery,
            join_business: "yes",
            transaction_type: "",
            ...filterValue,
          },
        })
      );
    }, 10); // Adjust the delay as needed

    // Execute the debounced dispatch function
    debouncedDispatch();

    // Cleanup function to cancel pending dispatches
    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentPage, searchQuery, filterValue]);

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);
  const { isRedacting } = useSelector((state: RootState) => state.general);

  function dispatchFunction() {
    dispatch(
      fetchBizTransactions({
        identifier: "transactions",
        payload: {
          business_email: email as string,
          //   business_id: businessId,
          current_page: currentPage,
          bulk_transfer_reference: bulk_ref,
          search_term: searchQuery,
          join_business: "yes",
          transaction_type: "transactions",
          ...filterValue,
          paginate: "no",
        },
      })
    );
  }

  const handleExport = useIsolatedExport({
    setFilterValue,
    data: trx,
    loading,
    ...detectSingleBizValuePair("transactions"),
    title: `Transactions Export`,
    dispatchFunction,
    filterValue,
  });

  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  useEffect(() => {
    const handleExporting = (e: any) => {
      if (e.detail === "done") {
        setTimeout(() => {
          dispatch(
            fetchBizTransactions({
              identifier: "transactions",
              payload: {
                business_email: email as string,
                // business_id: businessId,
                current_page: currentPage,
                bulk_transfer_reference: bulk_ref,
                search_term: searchQuery,
                join_business: "yes",
                transaction_type: "",
                ...filterValue,
              },
            })
          );
        }, 100);
      }
    };
    $event.addEventListener("exporting", handleExporting);

    // Cleanup function to remove the event listener
    return () => {
      $event.removeEventListener("exporting", handleExporting);
    };
  }, []);

  return (
    <div className="business-trx-wrap">
      {/* <h6>Transactions</h6> */}

      <>
        {/* <div className="type-title">
          <h6>{capitalizeFirstLetter(type.label)}</h6>
        </div> */}
        <SmartFilter
          page="insights"
          onExport={() => {
            toggleModal(`Export Transactions`);
            triggerExport(true);
          }}
          searchTitle="Search transactions..."
          onSearchChange={(e: string) => {
            setSearchQuery(e);
          }}
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
          }}
          filters={[
            {
              label: "Status",
              filterBy: "status",
              options: [
                { value: BIZ_TRANSACTION_STATUS.PENDING.toString(), label: "Pending" },
                {
                  value: BIZ_TRANSACTION_STATUS.PROCESSING.toString(),
                  label: "Processing",
                },
                { value: BIZ_TRANSACTION_STATUS.FAILED.toString(), label: "Failed" },
                { value: BIZ_TRANSACTION_STATUS.SUCCESS.toString(), label: "Success" },
                {
                  value: BIZ_TRANSACTION_STATUS.REVERSED.toString(),
                  label: "Reversed",
                },
              ],
            },
          ]}
          // onExport={toggleModal}
          singleSelect
          pagination={{
            currentPage: currentPage,
            itemsPerPage: transactions.per_page as number,
            totalItems: transactions.total,
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
          }}
        />

        {(!isRedacting && loading) || trx?.length === 0 ? (
          <EmptyScreen loading={loading} />
        ) : (
          <RavenTable action={false} className="table__main" headerList={header}>
            {/* Bills Table */}
            {trx.map((chi: BusinessTransactionData, idx: SN) => {
              return (
                <React.Fragment key={idx}>
                  <Tables.Transactions
                    onRowClick={() => setView({ on: true, data: chi })}
                    loading={loading}
                    content={chi}
                  />
                </React.Fragment>
              );
            })}
          </RavenTable>
        )}
      </>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {}}
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={view?.data?.direction}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "Amount",
                value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
                // copy: true,
              },
              // {
              //   label: "Business Email",
              //   value: view.data?.email,
              // },

              {
                label: "Bal Before",
                value: `₦${formatNumWithComma(view.data.bal_before ?? 0, "ngn")}`,
              },
              {
                label: "Bal After",
                value: `₦${formatNumWithComma(view.data.bal_after ?? 0, "ngn")}`,
              },

              // {
              //   label: "Narration",
              //   value: trimLongString(
              //     formatMetaData(view.data.meta_data ?? "")?.narration,
              //     30
              //   ),
              // },
              ...renderConditionally(
                "Narration",
                trimLongString(formatMetaData(view.data.meta_data ?? "")?.narration, 30)
              ),
              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },

              ...renderConditionally(
                "Recipient",
                formatMetaData(view.data.meta_data ?? "")?.account_name
              ),
              // {
              //   label: "Recipient",
              //   value: formatMetaData(view.data.meta_data ?? "")?.account_name,
              // },

              {
                label: "Transaction Ref",
                value:
                  view.data?.trx_ref ??
                  view.data?.bulk_transfer_reference ??
                  (typeof view?.data?.meta_data === "string"
                    ? JSON.parse(String(view?.data?.meta_data)).session_id
                    : null) ??
                  "--",
                copy: true,
                trim: true,
              },

              {
                label: "Status",
                value: formatStatus(detectStatus(Number(view.data?.status))),
              },

              {
                label: "Date Authorized",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </div>
  );
};

export default SingleBusinessTransactions;
