import {
  ONSWATCH,
  SET_GLOBAL_CURRENCY,
  TOGGLE_MENU,
  TOGGLE_REDACT,
  TOGGLE_THEME,
} from "./actionTypes";

export const toggleTheme = (payload: "light" | "dark") => ({
  type: TOGGLE_THEME,
  payload,
});

export const toggleMenu = (payload?: boolean) => ({
  type: TOGGLE_MENU,
  payload,
});

export const toggleRedact = (payload?: boolean) => ({
  type: TOGGLE_REDACT,
  payload,
});

export const setBrandColor = (payload?: string) => ({
  type: ONSWATCH,
  payload,
});

export const setGlobalCurrency = (payload?: string) => ({
  type: SET_GLOBAL_CURRENCY,
  payload,
});

export const setStandupTime = (payload?: string) => ({
  type: "SET_STANDUP_TIME",
  payload,
});
