import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { GLOBAL_PER_PAGE } from "@/constants";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { usePbPocketExport } from "../../__internals__/internal-hooks/savings";
import { ConditionalCopyText } from "@/components/common/copyCheck";

export const Pockets = () => {
  const pagination = usePagination();
  const { data, isFetching, isLoading } = personalReduxAPI.useGetPocketsQuery({
    page: pagination.currentPage,
    search: pagination.searchQuery,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
  });
  const { ExportModal, onExport } = usePbPocketExport();

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );
  const navigate = useNavigate();

  const handleClick = (row: PersonalPocketData) => {
    navigate(`/personal-savings/pocket/${row.id}`);
  };

  if (isLoading || !data || data.pockets?.length < 1)
    return (
      <div className="savings-page-item-wrapper">
        <TransferEmptyScreen loading={joinedLoader} />;
      </div>
    );

  return (
    <div className="savings-page-item-wrapper">
      <SmartFilter
        searchTitle="Search by email or reference"
        {...SmartFilterProps}
        onExport={onExport}
      />
      <RavenTable
        className="table__main"
        headerList={["EMAIL", "REFERENCE", "TITLE", "BALANCE", "DATE CREATED"]}
      >
        {data.pockets.map((row) => {
          const { id, balance, reference, created_at, email, name } = row;

          return (
            <RavenTableRow
              key={id}
              one={RedactedTable(
                email ? <ClickAbleEmail email={Util.safeText(email)} /> : "--"
              )}
              two={RedactedTable(<ConditionalCopyText text={reference} />)}
              four={RedactedTable(Util.safeText(formatNumberToCurrency(balance)))}
              three={RedactedTable(Util.safeText(name))}
              five={RedactedTable(
                Util.safeText(created_at ? formatDateTime(created_at) : "--")
              )}
              onRowClick={() => handleClick(row)}
            />
          );
        })}
      </RavenTable>
      <ExportModal />
    </div>
  );
};
