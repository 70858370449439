import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Column, Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import styled from "styled-components";
import RequestCard from "../components/requestCard";
import EmptyScreen from "@/components/common/emptyScreen";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";

export const PosInsightsTerminals = () => {
  const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
    module: INSIGHT_TYPES.TERMINAL,
  });

  const uMetrics: InsightTerminals = data?.overview as any;
  const combinedLoading = isFetching || isLoading;

  const topCardData = [
    {
      title: "All Terminals",
      count: uMetrics?.allTerminals,
    },
    {
      title: "Active Terminals",
      count: uMetrics?.activeTerminals,
    },
    {
      title: "Terminal Requests",
      count: uMetrics?.terminalRequests,
    },
    {
      title: "Inactive Terminals",
      count: uMetrics?.inactiveTerminals,
    },
    // {
    //   title: "Yearly Request",
    //   count: uMetrics?.yearlyRequests,
    // },
  ];
  return (
    <div style={{ height: "100%" }} className="pos-insights-tabs">
      {combinedLoading ? (
        <EmptyScreen loading={combinedLoading} />
      ) : (
        <Column>
          <Row ai="center" style={{ justifyContent: "space-between" }}>
            {topCardData?.map((d, i) => (
              <RequestCard
                {...d}
                footer={
                  i === 0 ? (
                    <p>Amount •  {RavenNumberFormat(uMetrics?.totalYearVol)}</p>
                  ) : i === 1 ? (
                    <p>Amount •  {RavenNumberFormat(uMetrics?.totalActiveVol)}</p>
                  ) : (
                    ""
                  )
                }
              />
            ))}
          </Row>
          {/* <PosInsightsSideBar metrics={formatMetrics(data?.overview?.statusMetrics)} /> */}

          <Row>
            <CategoryMetricsCard
              title="POS TERMINAL METRICS"
              data={[
                { label: "Bankbox", value: uMetrics?.CATEGORIES.bankBox },
                { label: "K11", value: uMetrics?.CATEGORIES["k-11"] },
              ]}
            />
            <MetricsGraph
              data={uMetrics?.terminalsPerMonth as any}
              dataKey="totalCount"
              count={`Yearly Terminal Count`}
              totalVolume={uMetrics?.totalYearCount ?? 0}
              volumeNumberOptions={{
                hideDecimal: true,
                hideSymbol: true,
              }}
              title="Terminal Metrics"
              containerHeight="33rem"
              graphStyle={{
                width: 100,
                height: 100,
              }}
              maintainAspectRatio={false}
              // countNumberOptions={{ hideDecimal: true, hideSymbol: true }}
            />
          </Row>
        </Column>
      )}
    </div>
  );
};
