// RequestCard.tsx

import { ReactNode } from "react";
import styled from "styled-components";

const Card = styled.div`
  height: 11.6rem;
  width: 24.9rem;
  border-radius: 0.8rem;
  border: 1px solid var(--RAVEN-black-100, #eee);
  background: #fff;
`;

const Title = styled.h3`
  color: var(--BaseBlack-Base-BG-Dark, #676767);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  padding-top: 1.6rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

const Count = styled.p`
  color: var(--BaseBlack-BaseBackground-dark, #020202);
  font-size: 2rem;
  font-style: normal;
  padding: 0 1.6rem;
  font-weight: 600;
`;

const Footer = styled.div`
  width: 24.9rem;
  height: 4rem;
  margin-top: 0.8rem;
  flex-shrink: 0;
  padding: 0 1.6rem;
  display: flex;
  color: var(--BaseBlack-Base-BG-Dark, #676767);
  font-size: 1.2rem;
  align-items: center;
  border-top: 1px solid var(--RAVEN-black-100, #eee);
`;

const RequestCard = ({
  title,
  count,
  footer,
}: {
  title: string;
  count: SN;
  footer?: ReactNode;
}) => {
  return (
    <Card>
      <Title>{title}</Title>
      <Count>{count?.toLocaleString()}</Count>
      <Footer>{footer}</Footer>
    </Card>
  );
};

export default RequestCard;
