import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosTransactionsApi = (builder: PosBuilder) => {
  return {
    getTransactions: createQueryWithRoute<
      PosTransactionsModel,
      Partial<PosPaginationParams>
    >(builder, "transaction", { name: "getTransactions" }),

    getTransactionsExport: createQueryWithRoute<
      PosTransactionsModel,
      Partial<PosPaginationParams>
    >(builder, "transaction", { export: true, name: "getTransactionsExport" }),

    getATransaction: builder.query<PosTransactions, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler({ route: `transaction/${params?.id}` }, thunk);
      }
    }),

    getReversals: builder.query<{ data: any; pagination: Pagination }, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler({ route: `pending_reversals`, params }, thunk);
      },
    }),
  };
};
