import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./styles/index.css";
import { TypeIs } from "@/utils/helpers";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";

ChartJS.register(ArcElement, Tooltip, Legend);
type NumberOption = {
  hideDecimal?: boolean;
  hideSymbol?: boolean;
};

const POSCategoryMetrics = ({
  title,
  data,
  numberOption = { hideDecimal: true, hideSymbol: true },
}: {
  title: string;
  numberOption?: NumberOption;
  data: {
    label: string;
    value: number;
  }[];
}) => {
  const datar = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => item.value),
        backgroundColor: data.map((item, index) => {
          const colors = ["#F0EEFF", "#FFF6ED", "#FFD6A5", "#FFA07A", "#FF6347"];
          return colors[index % colors.length];
        }),
        borderColor: data.map((item, index) => {
          const colors = ["#755AE2", "#EA872D", "#FFA07A", "#FF6347", "#FF4500"];
          return colors[index % colors.length];
        }),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="pos-category-metrics-card">
      <div className="header">
        <h2>{title?.toUpperCase()}</h2>
        <div className="year-selector">
          <span>2024</span>
        </div>
      </div>
      <div className="chart-container">
        <Pie data={datar} options={options} />
      </div>
      <div className="metrics">
        {data.map((item, index) => (
          <div key={index} className="metric">
            <span
              className="dot"
              style={{ backgroundColor: datar.datasets[0].backgroundColor[index] }}
            ></span>
            <span className="label">{item.label}</span>
            <span className="value">
              {TypeIs.number(item.value)
                ? RavenNumberFormat(item.value, numberOption)
                : item.value}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default POSCategoryMetrics;
