import { PersonalPhysicalCards } from "~pb/cards/pages/physical-cards/page";
import { PersonalCardsPage } from "~pb/cards";
import { PersonalCardVirtualCardsPage } from "~pb/cards/pages/virtual-cards/page";
import { PersonalSingleCardDetails } from "~pb/cards/pages/card-details";
import { PersonalPhysicalCardsRequest } from "~pb/cards/pages/physical-card-requests/page";
import { PhysicalCardsDetailsPage } from "~pb/cards/pages/physical-cards/card-details/page";
import PBVirtualCardFunding from "@/apps/personal/__internals__/combined-tables/cards/virtual-card-funding";

export const personal_cards_routes: RouteArray[] = [
  {
    path: "/personal-cards",
    element: PersonalCardsPage,
    children: [
      { path: "", element: PersonalCardVirtualCardsPage },
      { path: "virtual-cards", element: PersonalCardVirtualCardsPage },
      { path: "physical-cards", element: PersonalPhysicalCards },
      { path: "physical-cards-request", element: PersonalPhysicalCardsRequest },
    ],
  },
  { path: "/personal-cards/virtual/:card_id", element: PersonalSingleCardDetails },
  {
    path: "/personal-cards/virtual-funding/:card_id",
    element: PBVirtualCardFunding,
  },
  {
    path: "/personal-cards/physical/:id",
    element: PhysicalCardsDetailsPage,
    children: [
      { path: "", element: PhysicalCardsDetailsPage },
      {
        path: "transactions",
        element: PhysicalCardsDetailsPage,
      },
      { path: "lien", element: PhysicalCardsDetailsPage },
    ],
  },
];
