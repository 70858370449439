import env from "@/env";
import { APIResponseHandler } from "@/utils/api";
import { filterAPIQueryParams } from "@/utils/helpers";
import { BaseQueryApi, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateBuilderContext } from "../..";
import { PosTerminalsApi } from "./terminals/api";
import PosApi from "@/utils/axios/pos";
import { PosCollectionsApi } from "./collections/api";
import { PosSettlementApi } from "./settlement/api";
import { PosDailySummaryApi } from "./dailySummary/api";
import { PosFeesApi } from "./fees/api";
import { PosAuditTrailApi } from "./auditTrail/api";
import { PosBillsApi } from "./bills/api";
import { PosTransactionsApi } from "./transactions/api";
import { toggleRedact } from "@/redux/actions/actions";
import { createExportEndpoint } from "./util";
import { PosHniApi } from "./hni/api";
import { PosOverviewApi } from "./overview/api";

export const PosTagTypes = ["PosApi.getMerchants"] as const;

/**`start_date` `end_date` `per_page` `page` `search` */
export type PosBaseParams = {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
  per_page: SN;
  page: SN;
  search?: string;
  email?: string;
};

// "pagination": {
//   "perPage": 200,
//   "currentPage": 1,
//   "nextPage": null,
//   "prevPage": null,
//   "totalPages": 1,
//   "total": 151
// }

type TagTypes = (typeof PosTagTypes)[number];

export const posReduxAPI = createApi({
  reducerPath: "pos-api",
  baseQuery: fetchBaseQuery({ baseUrl: env.pos_api }),
  tagTypes: PosTagTypes,
  endpoints: (builder) => ({
    ...PosTerminalsApi(builder),
    ...PosCollectionsApi(builder),
    ...PosSettlementApi(builder),
    ...PosDailySummaryApi(builder),
    ...PosFeesApi(builder),
    ...PosAuditTrailApi(builder),
    ...PosBillsApi(builder),
    ...PosTransactionsApi(builder),
    ...PosHniApi(builder),
    ...PosOverviewApi(builder),
  }),
});

export const posExportAPI = createApi({
  reducerPath: "posExportAPI",
  baseQuery: fetchBaseQuery({ baseUrl: env.pos_api }),
  endpoints: (builder) => ({
    ...createExportEndpoint("getAirtimeRecords", posReduxAPI)(builder),
    ...createExportEndpoint("getDataRecords", posReduxAPI)(builder),
    ...createExportEndpoint("getCableRecords", posReduxAPI)(builder),
    ...createExportEndpoint("getElectricityRecords", posReduxAPI)(builder),
    ...createExportEndpoint("getBettingRecords", posReduxAPI)(builder),
    // Add other endpoints here as needed
  }),
});



export type PosBuilder = CreateBuilderContext<TagTypes, "pos-api">;

interface GetHandlerArgs<T> {
  route: string;
  params?: T;
  baseUrl?: string;
  objectKey?: string;
  mutateData?: (data: any) => any;
}

export const createPosURL = (route: string, search: IsUncertain<SN>) => {
  if (search) return `${route}/search`;
  return route;
};

export const posGetHandler = async <T extends Record<string, IsUncertain<SN>>>(
  args: GetHandlerArgs<T>,
  thunk?: BaseQueryApi
) => {
  const { params = {}, route, baseUrl = "admin/v2", objectKey, mutateData } = args;

  thunk && thunk?.dispatch(toggleRedact(true));
  const _fakeMutate = (data: any) => data;
  const p: any = params;

  const finalRoute = createPosURL(route, p.search);

  const mutateFunc = mutateData ?? _fakeMutate;

  try {
    const response = await PosApi.get<APIResponse<any>>(`${baseUrl}/${finalRoute}`, {
      params: filterAPIQueryParams(params),
    });

    APIResponseHandler.tryHandler({
      response,
      dispatch: thunk?.dispatch,
    });

    if (objectKey) return { data: mutateFunc(response.data.data[objectKey]) };
    thunk && thunk?.dispatch(toggleRedact(false));

    return { data: mutateFunc(response.data.data) };
  } catch (error) {
    APIResponseHandler.catchHandler(error);
    throw error;
  }
};
