import "./styles/index.css";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";

const tabElements = ["Transfer", "Airtime", "Data", "Electricity", "Cable TV", "Betting"];

const PageBaseURL = "/pos-reversal-center";

export const PosReversalCenter = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Reversal Center">
        <div className="bb-kickbacks">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabElements}
              baseURL={PageBaseURL}
              defaultOutletUrl="transfer"
            ></OutletTab.Tabs>
            <Gap size={15} />
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};
