import { icons } from "@/assets/icons";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { cleanWhiteSpace, cn } from "@/utils/colorConvert";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency, getPreviousRoute } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { OutletTab } from "@/components/common/OutletTab";
import { RavenButton, RavenToolTip } from "@ravenpay/raven-bank-ui";
import { TabsElement, findActiveDropdownTab } from "@/components/common/Tabs";
import { useEffect, useState } from "react";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import {
  BlockUnblockModal,
  DisableTwofa,
  GenerateStatementModal,
  LienUnlienModal,
  MakeDeductionModal,
} from "./modal-actions";
import { useLocation } from "react-router-dom";
import { IconButton } from "@/components/common/icon-button";
import { XStack } from "@/components/common/stacks";
import {
  PersonalUserDeviceModal,
  PersonalAccountsModal,
  PersonalBankAccountModal,
} from "./modal";
import Redacted, { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { useActionBar } from "@/hooks/useActionBar";
import { GENERAL_CLASSES } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { getRewardIcons, ranks } from "../../rewards";
import OffsetModal from "@/components/common/redpay-modal/RedPayModal";
import RewardRankOffset from "../components/off-canvas/reward-canvas";
import { iife } from "@/utils/general";
import Image from "@/components/common/image/Image";
import Dropdown from "@/components/common/dropdown";

interface PersonalSingleUserPageProps {
  data: PersonalUserModel;
  email: string;
  tabs: TabsElement[];
  refetch(): void;
  redactedUtil: ReturnType<typeof renderRedactedComponent>;
}

type LoaderActions =
  | "block"
  | "lien"
  | "deduction"
  | "strip"
  | "statement"
  | "disable_2fa";

type UserActions = "device" | "accounts" | "user_accounts";

export const PersonalSingleUserPage = (props: PersonalSingleUserPageProps) => {
  const { redactedUtil, data, tabs, refetch, email } = props;
  const { RedactedTable, RedactedLabel } = redactedUtil;
  const [modalToShow, setModalToShow] = useState<LoaderActions>();
  const { renderDynamicModal } = useDynamicModal(Boolean(modalToShow));
  const { state } = useLocation();
  const [usersAction, setUsersAction] = useState<UserActions>();
  const { renderDynamicModal: renderUsersAction } = useDynamicModal(Boolean(usersAction));
  const [showActionBar, setShowActionBar] = useState(true);

  const {
    data: ravenBeneficiaries,
    isLoading,
    isFetching,
  } = personalReduxAPI.useGetUserRavenBeneficiariesQuery(email);

  const beneficiariesJoinedLoader = isLoading || isFetching;

  const selectedDropdown = findActiveDropdownTab(tabs);

  const { ActionBar } = useActionBar({
    actionText: "This User is blocked",
    onCancel: () => setShowActionBar(false),
    visible: showActionBar,
    type: "error",
  });

  const assertModal = (ctx: LoaderActions) => modalToShow === ctx;
  const handleModalCancel = () => {
    setModalToShow(undefined);
  };

  const isBlocked = data._blocked === 1;

  const isUserActionActive = (mode: UserActions) => usersAction === mode;
  const handleClose = () => setUsersAction(undefined);

  const parsedDevices = data.devices.filter(({ device_os }) => device_os);

  const [reward, showReward] = useState("");

  const {
    data: rewardsInsight,
    isLoading: rewardLoading,
    isFetching: rewardFetching,
  } = personalReduxAPI.useGetRewardsInsightQuery({ email });

  const {
    data: refferedUserInsight,
    isLoading: refferalLoading,
    isFetching: refferalFetching,
  } = personalReduxAPI.useGetRefferedUserInsightsQuery({
    email,
  });

  const userRank = () => {
    if (rewardsInsight) {
      const totalReward = rewardsInsight.total_reward;
      for (let i = ranks.length - 1; i >= 0; i--) {
        if (totalReward >= ranks[i].value) {
          return ranks[i].label;
        }
      }
    }
    return "Raven Nestling";
  };

  const [rewardRank, setRewardRank] = useState("Raven Nestling");
  useEffect(() => {
    setRewardRank(userRank());
  }, [rewardsInsight]);

  const hasSelfie = Boolean(data?.selfie_url ?? data?.image_url);
  return (
    <>
      <DashboardLayout className={GENERAL_CLASSES.ACTION_BAR_PRESENT}>
        {isBlocked && <ActionBar />}
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton to={getPreviousRoute({ state })} />}
          style={{ backgroundColor: "unset" }}
          pageTitle="User Details"
        >
          <div className="single-user-details">
            <div
              onClick={() => showReward("on")}
              className="single-user-details__reward-badge"
            >
              {rewardLoading || rewardFetching ? (
                <Redacted.Avatar />
              ) : (
                <figure>{getRewardIcons(rewardRank)}</figure>
              )}
            </div>
            <div className="single-user-details__top-wrap">
              <div className="top-wrap__main-content">
                <div
                  style={{
                    position: "relative",
                  }}
                  className={cn(
                    "top-wrap__main-content--profile-img",
                    isBlocked && "blocked-gradient"
                  )}
                >
                  {!hasSelfie ? (
                    icons.image_placeholder
                  ) : (
                    <Image
                      className="top-wrap__user-img"
                      src={data?.selfie_url ?? data?.image_url}
                    />
                  )}

                  <div className="top-wrap__twofa-status">
                    <span>
                      <figure>{icons.twofa_lock}</figure>

                      <p>2FA Status</p>
                    </span>

                    <span className="">
                      {Boolean(data?.two_factor_enabled)
                        ? icons.check_verified
                        : icons.slash_circle_01}
                      <p className={cn(!Boolean(data?.two_factor_enabled) && "disabled")}>
                        {Boolean(data?.two_factor_enabled) ? "Enabled" : "Disabled"}
                      </p>
                    </span>
                  </div>
                  <div className="top-wrap__main-content--profile-img__user-status user-status-icon">
                    {isBlocked ? icons.slash_circle_01 : icons.check_verified}
                    <p>{isBlocked ? "Blocked User" : "Active User"}</p>
                  </div>
                </div>

                <div className="top-wrap__main-content--user-data">
                  <div className="user-data__main-content">
                    <div className="main-content__col">
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Fullname</small>
                          <p>{cn(data?.fname, data?.lname)}</p>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Date Joined</small>
                          <p>
                            {data?.created_at ? formatDateTime(cn(data.created_at)) : ""}
                          </p>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Total Referral Earned</small>
                          <span>
                            <p>
                              {formatNumberToCurrency(data?.total_earn_ref ?? 0, "NGN")}
                            </p>
                            {data?.total_earn_ref ? (
                              <figure>{icons.arrow_circle_right}</figure>
                            ) : null}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="main-content__col">
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Username</small>
                          <p>{cleanWhiteSpace("@", data?.username)}</p>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Last Seen</small>
                          <p>
                            {data?.last_active_at
                              ? formatDateTime(data.last_active_at)
                              : "--"}
                          </p>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Referral Bonus</small>
                          <p>{Util.safeValue(cn(data?.ref_bonus))}</p>
                        </div>
                      )}
                    </div>
                    <div className="main-content__col">
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Email Address</small>
                          <p>{Util.safeValue(data?.email)}</p>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>BVN Verification Status</small>
                          {VerificationStatus(data?.bvn_verified_at)}
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item cursor-pointer">
                          <small>Beneficiary Accounts</small>
                          {Util.safeArray(data?.accounts).length > 0 ? (
                            <XStack gap={0.5} onClick={() => setUsersAction("accounts")}>
                              <p>{data?.accounts.length}</p>
                              <figure>{icons.arrow_circle_right}</figure>
                            </XStack>
                          ) : (
                            <p>0</p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="main-content__col">
                      {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>Mobile Number</small>
                          <p>{Util.safeValue(data?.phone)}</p>
                        </div>
                      )}
                      {/* {RedactedLabel(
                        <div className="main-content__col--item">
                          <small>NIN Verification Status</small>
                          {VerificationStatus(data?.nin_verified_at)}
                        </div>
                      )} */}
                      {RedactedLabel(
                        <div className="main-content__col--item cursor-pointer">
                          <small>
                            {data?.beststar_accounts?.length === 1
                              ? "Account Number"
                              : "Accounts"}
                          </small>
                          <>
                            {data?.beststar_accounts?.length === 1 ? (
                              data?.beststar_accounts[0]?.account_number
                            ) : Util.safeArray(data?.beststar_accounts).length > 0 ? (
                              <XStack
                                gap={0.5}
                                onClick={() => setUsersAction("user_accounts")}
                              >
                                <p>{data?.beststar_accounts?.length}</p>
                                <figure>{icons.arrow_circle_right}</figure>
                              </XStack>
                            ) : (
                              <p>No Devices Yet</p>
                            )}
                          </>
                        </div>
                      )}
                      {RedactedLabel(
                        <div className="main-content__col--item cursor-pointer">
                          <small>Authorised Devices</small>
                          <>
                            {Util.safeArray(parsedDevices).length > 0 ? (
                              <XStack gap={0.5} onClick={() => setUsersAction("device")}>
                                <p>{parsedDevices.length}</p>
                                <figure>{icons.arrow_circle_right}</figure>
                              </XStack>
                            ) : (
                              <p>No Devices Yet</p>
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="user-data__account-summary">
                    {RedactedLabel(
                      <div className="user-data__account-summary--item">
                        <p>User Balance</p>
                        <h6>{formatNumberToCurrency(data?.available_bal ?? 0, "NGN")}</h6>
                      </div>
                    )}
                    {RedactedLabel(
                      <div className="user-data__account-summary--item">
                        <p>Bank Transfer Count</p>
                        <h6>{Util.safeValue(data?.bank_transfer_count ?? 0)}</h6>
                      </div>
                    )}
                    {RedactedLabel(
                      <div className="user-data__account-summary--item">
                        <p>Deposit Limit</p>
                        <h6>{formatNumberToCurrency(data?.deposit_limit ?? 0, "NGN")}</h6>
                      </div>
                    )}
                    {RedactedLabel(
                      <div className="user-data__account-summary--item">
                        <p style={{ color: "#FF0F00" }}>Lien</p>
                        <h6>{formatNumberToCurrency(data?.liened_amount ?? 0, "NGN")}</h6>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="top-wrap__actions">
                <RavenButton
                  onClick={() => setModalToShow("block")}
                  className={cn("block-user-btn", isBlocked && "block--enabled")}
                  color="white-light"
                >
                  <figure>
                    {isBlocked ? icons.clock_rewind_green : icons.slash_circle_01}
                  </figure>
                  <p>{RedactedTable(data._blocked ? "Unblock User" : "Block User")}</p>
                </RavenButton>
                <IconButton
                  icon="file_07"
                  text="Get Bank Statement"
                  onClick={() => setModalToShow("statement")}
                />
                <IconButton
                  onClick={() => setModalToShow("deduction")}
                  className="make-deduction-btn"
                  icon="minus_circle"
                  text="Make a Deduction"
                />
                <IconButton
                  onClick={() => setModalToShow("lien")}
                  className="lien-user-btn"
                  icon="minus_circle"
                  text="Lien Amount"
                />
                <Dropdown
                  items={[{ label: "Disable 2FA", value: "disable_2fa" }]}
                  className={cn(
                    "ragna-dropdown more-user-btn",
                    GENERAL_CLASSES.TOOL_TIP_WRAPPER
                  )}
                  rightPosition
                  onChange={(e) => {
                    if (e.value === "disable_2fa") {
                      setModalToShow(e.value);
                    }
                  }}
                  buttonChild={() => (
                    <>
                      <IconButton
                        // onClick={() => setModalToShow("lien")}
                        className="users-more-btn"
                        icon="more_plain"
                      />
                      <RavenToolTip color={"black-light"} position="top-right">
                        Show More
                      </RavenToolTip>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="single-user-details__table-wrap">
              <OutletTab>
                <OutletTab.Tabs
                  baseURL={`/personal-users/${email}`}
                  tabs={tabs}
                  selectedDropdown={selectedDropdown}
                />
                <OutletTab.Outlet />
              </OutletTab>
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>
      {assertModal("block") &&
        renderDynamicModal(
          <BlockUnblockModal
            refetch={refetch}
            isMainAction={data._blocked === 0}
            onCancel={handleModalCancel}
            visible
            email={data.email}
          />
        )}
      {assertModal("lien") &&
        renderDynamicModal(
          <LienUnlienModal
            refetch={refetch}
            isMainAction
            onCancel={handleModalCancel}
            visible
            email={data.email}
          />
        )}{" "}
      {assertModal("statement") &&
        renderDynamicModal(
          <GenerateStatementModal
            refetch={refetch}
            onCancel={handleModalCancel}
            visible
            email={data.email}
          />
        )}
      {assertModal("deduction") &&
        renderDynamicModal(
          <MakeDeductionModal
            refetch={refetch}
            isMainAction
            onCancel={handleModalCancel}
            visible
            email={data.email}
          />
        )}
      {assertModal("disable_2fa") &&
        renderDynamicModal(
          <DisableTwofa
            refetch={refetch}
            isMainAction
            onCancel={handleModalCancel}
            visible
            email={data.email}
          />
        )}
      {isUserActionActive("device") &&
        renderUsersAction(
          <PersonalUserDeviceModal data={parsedDevices} onCancel={handleClose} />
        )}
      {isUserActionActive("accounts") &&
        renderUsersAction(
          <PersonalAccountsModal
            data={data.accounts}
            ravenBeneficiaries={Util.safeArray(ravenBeneficiaries)}
            onCancel={handleClose}
            loading={beneficiariesJoinedLoader}
          />
        )}
      {isUserActionActive("user_accounts") &&
        renderUsersAction(
          <PersonalBankAccountModal
            data={data.beststar_accounts}
            onCancel={handleClose}
            loading={beneficiariesJoinedLoader}
          />
        )}
      <OffsetModal
        visible={Boolean(reward)}
        onClose={() => {
          showReward("");
        }}
        dontShowClose
      >
        <RewardRankOffset
          rank={rewardRank}
          insight={rewardsInsight as RewardInsight}
          referral={refferedUserInsight as RewardReferral[]}
        />
      </OffsetModal>
    </>
  );
};

const VerificationStatus = (status?: SN) => (
  <p className={cn(status && "verified")}>
    {status ? "Verified" : <span className="text-danger">Not Verified</span>}
  </p>
);
