import { icons } from "@/assets/icons";
import { formatStatus, trimLongString } from "@/utils/helper/Helper";
import React from "react";
import "./styles/index.css";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
const PersonalBankAccounts = ({ data }: { data: PersonalUserAccount[] }) => {
  return (
    <div className="auth-device-container flex col">
      <p style={{ color: "#676767", marginTop: "1rem" }}></p>
      {data?.map((d) => {
        return (
          <div className="auth-device-container__item">
            <div className="auth-device-container__item--title-wrap">
              <figure>{icons.bank_icon}</figure>
              <span className="device-title">
                <p>{trimLongString(Util.safeValue(d.account_name), 20)}</p>
                <small>{Util.safeValue(d.account_number)} • BestStar MFB </small>
              </span>
            </div>

            <div className="status">
              {/* {d.primary === 1 ? (
                formatStatus("primary")
              ) : (
                <p>Bal: {formatNumberToCurrency(d.balance, "NGN")}</p>
              )} */}
              {/* <figure>{icons.more}</figure> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PersonalBankAccounts;
