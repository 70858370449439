import bankbox from "@/assets/images/bankbox-img.png";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { IconButton } from "@/components/common/icon-button";
import Image from "@/components/common/image/Image";
import { MainContentCol } from "@/components/common/mainContentCol";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import env from "@/env";
import { sendRequest } from "@/utils/api-methods";
import { formatDate, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency, isEven } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "~/common/shared/single-user/index.css";
import "~/common/used-by-details/index.css";
import { useQueryLoadingState } from "../../__internals__/hooks/useQueryLoadingState";
import "../styles/index.css";
import AddAlertRecievers, { ViewAlertRecievers } from "./__modals__/alert-reciever";
import PosDetailsTable from "./table";
import { Column } from "@/components/common/stacks";
import Gap from "@/components/common/styleComponents/Gap";
import { partnersReduxAPI } from "@/redux/slices/partners/apis";

export const PartnersTerminalDetails = () => {
  const { id } = useParams();

  const res = partnersReduxAPI.useGetATerminalQuery({ id });
  const [modal, setModal] = useState<any>("");

  const [addRecieverForm, setAddRecieverForm] = useState({
    phone: "",
    email: "",
    name: "",
  });
  const { data } = res;
  const skip = !data?.tid;
  const alertRecievers = partnersReduxAPI.useGetAlertRecieversQuery(
    {
      tid: data?.tid,
    },
    { skip }
  );

  const recievers = alertRecievers.data;

  const combinedLoading = useQueryLoadingState(res);
  const [isReqn, setIsReqn] = useState(false);

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/de_re_activate",
      {
        tid: modal.tid,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function handleKeyExchange() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "/force_key_exchange",
      {
        serial: modal.serial,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function addAlertRecievers() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/alert/detail/add",
      {
        tid: modal.tid,
        serial: data?.serial,
        phone: addRecieverForm?.phone,
        fname: addRecieverForm?.name?.split(" ")[0],
        lname: addRecieverForm?.name?.split(" ")[1],
        contact_email: addRecieverForm?.email,
        whatsapp_enabled: "1",
        sms_enabled: "0",
        email_enabled: "0",
        poseidon_owner_email: data?.email,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function removeAlertRecievers() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/alert/detail/remove",
      {
        id: modal?.id,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  async function handleUpdateTerminal() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "/terminal/update",
      {
        oldSerial: modal.old_serial,
        newSerial: modal.new_serial,
        pin: modal?.user_pin,
        userEmail: data?.email,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  useEffect(() => {
    if (modal?.alert) {
      alertRecievers.refetch();
    }
  }, [modal?.alert]);

  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Terminal Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img product-single-details-page__avatar">
              <Image width={"100%"} height={"100%"} src={bankbox} />
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  <MainContentCol.Item title="Terminal ID">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.tid)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Date Disbursed">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      formatDate(new Date(String(data?.created_at)))
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Status">
                    <p className="terminal-status active">
                      {combinedLoading ? (
                        <Redacted.Table />
                      ) : (
                        <span className="ul">
                          {formatStatus(
                            Boolean(data?.status) ? "active" : "deactivated"
                          )}
                        </span>
                      )}
                    </p>
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Terminal Label ">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.tid_label)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Account Number">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.account_number)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Cash In">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(data?.cash_in as number)
                    )}
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Serial Number">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(data?.serial)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Account Name">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(
                        data?.account_name
                      )
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Cash Out">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(data?.cash_out as number)
                    )}
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Volume">
                    {combinedLoading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(data?.volume as number)
                    )}
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Bank">
                    {combinedLoading ? <Redacted.Table /> : "Best Star"}
                  </MainContentCol.Item>
                </MainContentCol>
              </div>
              <div>
                <div className="top-wrap__actions">
                  <IconButton
                    onClick={() => {
                      setModal({
                        tid: data?.tid,
                        disable: true,
                        label: Boolean(data?.status) ? "Disable" : "Activate",
                      });
                    }}
                    borderColor="#FF0F00"
                    text="Disable Terminal"
                    icon="slash_circle_01"
                  />
                  <IconButton
                    text="Update Terminal"
                    icon="edit_04"
                    onClick={() => {
                      setModal({
                        tid: data?.tid,
                        update_terminal: true,
                        label: Boolean(data?.status) ? "Disable" : "Activate",
                      });
                    }}
                  />{" "}
                  <IconButton
                    borderColor="#EA872D"
                    text="View Alert Reciever"
                    icon="arrow_circle_broken"
                    onClick={() => {
                      setModal({
                        tid: data?.tid,
                        alert: true,
                        label: Boolean(data?.status) ? "Disable" : "Activate",
                      });
                    }}
                  />
                  <IconButton
                    text="Force Key Exchange"
                    icon="arrow_circle_broken"
                    onClick={() => {
                      setModal({
                        serial: data?.serial,
                        keyExchange: true,
                        label: "Force Key Exchange",
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PosDetailsTable appId={data?.tid as string} />

        <ActionModal
          visible={Boolean(modal?.disable)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            handleActivateDeactivate();
          }}
          cancelText="No, Cancel"
          actionText={`Yes, Disable`}
          btnColor={"red"}
          body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
          title={`${modal?.label} Terminal?`}
        ></ActionModal>

        <ActionModal
          visible={Boolean(modal?.keyExchange)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            handleKeyExchange();
          }}
          cancelText="No, Cancel"
          actionText={`Yes, Proceed`}
          btnColor={"red"}
          body={`Are you sure to force key exchange on this Terminal? This action cannot be undone`}
          title={`Force Key Exchange`}
        ></ActionModal>

        <ActionModal
          visible={Boolean(modal?.delete)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            removeAlertRecievers();
          }}
          cancelText="No, Cancel"
          actionText={`Yes, Remove`}
          btnColor={"red"}
          body={`Are you sure to delete this alert reciever? This action can't be undone`}
          title={`Remove Alert Reciever?`}
        ></ActionModal>

        <ActionModal
          visible={Boolean(modal?.add_alert)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            addAlertRecievers();
          }}
          hideCancel
          actionText={`Add Alert Reciever`}
          btnColor={"#EA872D"}
          body={`Alert recievers are contact where certain notifications concerning your transactions will be sent to`}
          title={`Add Alert Recievers`}
        >
          <p style={{ color: "#676767" }}>
            Alert recievers are contact where certain notifications concerning your
            transactions will be sent to
          </p>

          <AddAlertRecievers onChange={setAddRecieverForm} value={addRecieverForm} />
        </ActionModal>

        <ActionModal
          visible={Boolean(modal?.alert)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            setModal({
              tid: data?.tid,
              add_alert: true,
              label: Boolean(data?.status) ? "Disable" : "Activate",
            });
          }}
          hideCancel
          actionText={`Add Alert Reciever`}
          btnColor={"#EA872D"}
          body={`Alert recievers are contact where certain notifications concerning your transactions will be sent to`}
          title={`Add Alert Recievers`}
        >
          <p style={{ color: "#676767" }}>
            Here is a list of all your current alert recievers
          </p>
          <ViewAlertRecievers
            data={recievers}
            onDelete={(id: number) => setModal({ id, delete: true })}
          />
        </ActionModal>

        {/* Update terminals */}

        <ActionModal
          visible={Boolean(modal?.update_terminal)}
          onCancel={() => {
            setModal("");
          }}
          loading={isReqn}
          onClick={() => {
            handleUpdateTerminal();
          }}
          disableButton={
            !modal?.user_pin ||
            modal?.user_pin?.length !== 6 ||
            !modal?.new_serial ||
            !modal.old_serial
          }
          hideCancel
          actionText={`Update Terminals`}
          btnColor={"#EA872D"}
          title={`Update Terminal`}
        >
          <p style={{ color: "#676767" }}>
            Updating Terminal allows you swap terminals with merchants, this is a critical
            operation, be sure you know what you are doing.
          </p>

          <Gap size={10} />

          <Column className="mt-2">
            <RavenInputField
              textColor="orange-light"
              placeholder="Enter old terminal serial"
              color="orange-light"
              label="Old Terminal Serial"
              // value={modal?.old_serial}
              onChange={(e: any) => {
                setModal((prev: any) => ({
                  ...prev,
                  old_serial: e.target?.value,
                }));
              }}
            />

            <RavenInputField
              textColor="orange-light"
              color="orange-light"
              label="New Terminal Serial"
              placeholder="Enter new terminal serial"
              // value={modal?.new_serial}
              onChange={(e: any) => {
                setModal((prev: any) => ({
                  ...prev,
                  new_serial: e.target?.value,
                }));
              }}
            />
            <RavenInputField
              textColor="orange-light"
              color="orange-light"
              label="Support PIN"
              type="pin"
              // value={modal?.user_pin}
              onChange={(e: any) => {
                setModal((prev: any) => ({
                  ...prev,
                  user_pin: e,
                }));
              }}
            />
          </Column>
        </ActionModal>
      </PageLayout>
    </DashboardLayout>
  );
};
