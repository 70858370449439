import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../../shared";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { STRING_TRIM_LENGTH } from "@/constants";
import { getPBPhysicalCardsStatus } from "../utils";
import { Util } from "@/utils/utility";
import { formatDateTime, formatStatus, IconVault } from "@/utils/helper/Helper";
import { XStack } from "@/components/common/stacks";
import { assertCardType } from "@/assets/images/cards";
import { CopyText } from "@/components/common/copyCheck";
import { useNavigate } from "react-router-dom";
import { icons } from "@/assets/icons";
import Dropdown from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { formatNumberToCurrency } from "@/utils/helpers";
import { cn } from "@/utils/colorConvert";
import React, { useState } from "react";
import "./style/index.css";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
type Props = CreateTableRowProps<{ data: PersonalCardFunding[]; onRefetch: () => void }>;

export const PBVirtualCardFundingTable = (props: Props) => {
  const { data, redactedFn, ctx, onRefetch } = props;
  const { RedactedTable } = redactedFn;
  const navigate = useNavigate();
  const [isReqn, setIsReqn] = useState(false);
  const [modal, setModal] = useState("");
  const [view, setView] = useState<any>("");

  async function handleRefund() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "/reverse-card-funding2",
      {
        id: modal,
      },
      {
        base_url: env.personal_api + "/support",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      onRefetch && onRefetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal("");
      toast.error(resp?.message);
    }
  }
  return (
    <RavenTable
      headerList={[
        "USER DETAILS",
        "CARD NUMBER",
        "AMOUNT",
        "CONVERTED AMOUNT",
        "CREATED AT",
        "STATUS",
        "Refund",
      ]}
    >
      {data.map((card) => {
        const {
          id,
          created_at,
          status,
          email,
          amount,
          converted_amount,
          conversion_rate,
          refunded,
          currency,
          card_id,
          reference,
        } = card;

        const cardStatus = getPBPhysicalCardsStatus(status);

        const RowOne =
          ctx === "page"
            ? RedactedTable(<ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />)
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            onRowClick={() => setView(card)}
            two={RedactedTable(
              <XStack gap={1}>
                {/* <img className="pb__avatar" src={assertCardType(amount)} alt="" /> */}
                {reference ? <CopyText text={reference} /> : "--"}
              </XStack>
            )}
            three={RedactedTable(formatNumberToCurrency(amount, currency))}
            four={RedactedTable(formatNumberToCurrency(converted_amount, "ngn"))}
            six={RedactedTable(formatStatus(translateStatus(status)))}
            seven={RedactedTable(
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  translateRefund(status, refunded).refund &&
                    status !== 1 &&
                    setModal(id.toString());
                }}
                className={cn(
                  "bugiss-status",
                  translateRefund(status, refunded).label === "refunded"
                    ? "Refunded"
                    : "pending",
                  status === 1 ? "refund-disabled" : ""
                )}
              >
                <p>{translateRefund(status, refunded).label}</p>
                <figure>
                  {translateRefund(status, refunded).refund
                    ? React.cloneElement(icons.refund, { width: 20 })
                    : icons?.purple_check}
                </figure>
              </div>
            )}
            five={RedactedTable(formatDateTime(created_at))}
          />
        );
      })}
      <ActionModal
        title={"Make a Refund"}
        body="Are you sure you want to refund this funding? This action can not be undone"
        visible={Boolean(modal)}
        onCancel={function (): void {
          setModal("");
        }}
        loading={isReqn}
        cancelText="No Cancel"
        actionText="Yes, Refund"
        onClick={function (): void {
          handleRefund();
        }}
      />

      <ActionModal
        title={"Funding Details"}
        // body="Are you sure you want to refund this funding? This action can not be undone"
        visible={Boolean(view)}
        onCancel={function (): void {
          setView("");
        }}
        loading={isReqn}
        normal
        cancelText="No Cancel"
        hideNormalButton
        onClick={() => {}}
      >
        <InlineDetails
          className="no-shadow"
          simple
          isTransaction
          content={[
            {
              label: "Amount",
              value: view?.amount,
            },
            {
              label: "Conversion Rate",
              value: view?.conversion_rate,
            },
            {
              label: "Converted Amount",
              value: view?.converted_amount,
            },
            {
              label: "Currency",
              value: view?.currency,
            },
            {
              label: "Reference",
              value: view?.reference,
            },
            {
              label: "Email",
              value: view?.email,
            },
            {
              label: "Created At",
              value: formatDateTime(view?.created_at),
            },
          ]}
        />
      </ActionModal>
    </RavenTable>
  );
};

function translateStatus(statusCode: number) {
  switch (statusCode) {
    case 9:
      return "Failed";
    case 2:
      return "Failed";
    case 0:
      return "Pending";
    case 1:
      return "Successful";
    default:
      return "Unknown";
  }
}

function translateRefund(statusCode: number, refunded: number) {
  if (statusCode === 9 && refunded === 0) {
    return { label: "Make a Refund", refund: true };
  } else if (statusCode === 2 && refunded === 1) {
    return { label: "Refunded", refund: false };
  } else {
    return { label: "Make a Refund", refund: true };
  }
}
