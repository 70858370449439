import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";

import EmptyScreen from "../../../components/common/emptyScreen";
import {
  RavenButton,
  RavenDropDown,
  RavenInputField,
  RavenModal,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import {
  capitalizeFirstLetter,
  formatDate,
  formatStatus,
  IconVault,
  mapHotkey, trimLongString
} from "@/utils/helper/Helper";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";

import { icons } from "@/assets/icons";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import { useProductColor } from "@/context/ProductTheme";
import { Column, Row } from "@/components/common/stacks";
import "./styles/index.css";
import NewNotificationModal from "./components/new_notification_modal";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import PreviewNotification from "./components/preview_notification";
import { cn } from "@/utils/colorConvert";
import { Util } from "@/utils/utility";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import UpdateNotification from "./components/edit_notification";
import RefireNotification from "./components/refire_notification";

// const Tabs = ["Push Notifications", "App Message", "Email Notification"];
const Tabs = ["Push Notifications"];

const PersonalNotification = () => {
  const [empty, setEmpty] = useState(false);
  const [showActions, setShowAction] = useState<{ type: string; data: any }>({
    type: "",
    data: {
      id: "",
    },
  });

  const [view, setView] = useState<{ chi: any; on: boolean }>({
    chi: {},
    on: false,
  });
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<string>("Push Notifications");

  const [subTab, setSubTab] = useState("scheduled");
  const [showEdit, setShowEdit] = useState("");
  const [showRefire, setShowRefire] = useState("");

  const tableHeader =
    subTab === "scheduled"
      ? ["Notification Details", "Date Scheduled", "Status", ""]
      : ["Notification Details", "Date Created", "Status", ""];

  const [formData, setFormData] = useState({
    title: undefined,
    topic: undefined,
    message: undefined,
  });
  const [reqn, setIsReqn] = useState(false);

  const [filter, setFilter] = useState({
    filterBy: "",
    filterValue: "",
    date_1: "",
    date_2: "",
  });

  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetNotificationsQuery({
      [filter.filterBy]: filter.filterValue,
      scheduled: subTab === "scheduled" ? 1 : 0,
      start_date: filter.date_1,
      end_date: filter.date_2,
    });

  async function handleNotificationActions() {
    setIsReqn(true);

    const uriMatch = showActions.type === "edit" ? "update" : showActions.type;
    const api = `/support/${uriMatch}-notification/${showActions.data.id}`;
    let resp;

    resp = await sendRequest(api, formData, {
      base_url: env.personal_api,
    });
    if (resp?.status === "success") {
      setFormData({
        title: undefined,
        topic: undefined,
        message: undefined,
      });

      toast.success(resp?.message);
      refetch();
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }
  const { colorVariable } = useProductColor();

  const [showCreate, setShowCreate] = useState(false);

  const loading = isFetching || isLoading;

  const { RedactedTable, RedactedAvatar, RedactedLabel } =
    renderRedactedComponent(loading);

  return (
    <>
      <DashboardLayout>
        <PageLayout
          topRightContent={
            <RavenButton
              onClick={() => setShowCreate(true)}
              className="new_notificaton_btn"
              color={colorVariable}
              label={
                (
                  <Row ai="center" gap={1}>
                    Create New Notification{" "}
                    {React.cloneElement(icons.chevron_down, {
                      class: "new_notification_btn_icon",
                    })}
                  </Row>
                ) as any
              }
            />
          }
          pageTitle="Notification Center"
        >
          <div className="personal-notification__top-wrap">
            <TabComponent onValueChange={(e) => setSelectedTab(e)} defaultValue={Tabs[0]}>
              <TabComponent.Tabs tabs={Tabs} />
              {Tabs.map((tab) => (
                <TabComponent.Content key={tab} value={tab}></TabComponent.Content>
              ))}
            </TabComponent>
          </div>
          <div className="personal-notification__container">
            <div className="sidebar">
              {selectedTab === "Push Notifications" ? (
                <Column gap={1} className="vus" style={{ width: "100%" }}>
                  <div
                    onClick={() => setSubTab("scheduled")}
                    className={cn(
                      "tb-item cursor-pointer",
                      subTab === "scheduled" && "active"
                    )}
                  >
                    <p>Scheduled</p>
                  </div>
                  <div
                    onClick={() => setSubTab("onetime")}
                    className={cn(
                      "tb-item cursor-pointer",
                      subTab === "onetime" && "active"
                    )}
                  >
                    <p>One-time Dispatch</p>
                  </div>
                  {/* <div
                    onClick={() => setSubTab("saved")}
                    className={cn(
                      "tb-item cursor-pointer",
                      subTab === "saved" && "active"
                    )}
                  >
                    <p>Saved Notification</p>
                  </div> */}
                </Column>
              ) : (
                <></>
              )}

              {selectedTab === "Email Notification" ? (
                <></>
              ) : selectedTab === "App Message" ? (
                <></>
              ) : (
                <></>
              )}
            </div>

            <div className="personal-notification-table focused">
              {/* <EmptyScreen noBorder /> */}
              {data && data?.length > 0 ? (
                <>
                  <SmartFilter
                    page="insights"
                    simple
                    searchTitle={`Search Notifications...`}
                    filters={[
                      {
                        label: "Status",
                        filterBy: "status",
                        options: [
                          { label: "Approved", value: "approve" },
                          { label: "Rejected", value: "rejected" },
                        ],
                      },
                      {
                        label: "Dispatch Status",
                        filterBy: "dispatch_status",
                        options: [
                          { label: "Pending", value: "pending" },
                          { label: "Sent", value: "sent" },
                          { label: "Failed", value: "failed" },
                          { label: "Sending", value: "sending" },
                          { label: "Cancelled", value: "cancelled" },
                        ],
                      },
                    ]}
                    onFilter={(e) => setFilter(Util.filterStruct(e as any) as any)}
                    singleSelect
                    pagination={{
                      currentPage: 0,
                      itemsPerPage: 0,
                      totalItems: 0,
                      onPageChange: function (page: number): void {
                        throw new Error("Function not implemented.");
                      },
                      activeFilter: `All ${selectedTab}`,
                    }}
                  />

                  <div className="table">
                    <RavenTable
                      action={false}
                      className="table__main"
                      headerList={tableHeader}
                    >
                      {data.map((data: any, index: number) => (
                        <RavenTableRow
                          key={index}
                          onRowClick={() => setView({ on: true, chi: data })}
                          {...{
                            one: RedactedLabel(
                              <div className="table-icon-row">
                                <TwinValue
                                  one={(data.title as string) ?? " - -"}
                                  two={trimLongString(data?.message, 65)}
                                />
                              </div>
                            ),

                            two: RedactedTable(formatDate(data.created_at)),
                            three: RedactedTable(formatStatus(data?.status)),
                            four: RedactedAvatar(
                              <Dropdown
                                rightPosition
                                onChange={(e) => {
                                  if (e.label.split(" ")[0].toLowerCase() === "edit") {
                                    setShowEdit(data);
                                    return;
                                  }

                                  if (e.label.split(" ")[0].toLowerCase() === "refire") {
                                    setShowRefire(data);
                                    return;
                                  }
                                  setShowAction({
                                    type: e.label.split(" ")[0].toLowerCase(),
                                    data: data,
                                  });
                                }}
                                items={[
                                  ...(data?.status?.toLowerCase() !== "approved"
                                    ? [
                                        {
                                          label: "Edit Notifications",
                                          img: IconVault(icons.edit_04),
                                        },
                                      ]
                                    : [
                                        {
                                          label: "Refire Notifications",
                                          img: IconVault(icons.refire),
                                        },
                                      ]),

                                  ...(data?.status?.toLowerCase() !== "approved"
                                    ? [
                                        {
                                          label: "Approve Notifications",
                                          img: IconVault(icons.check_circle_green),
                                        },
                                      ]
                                    : []),

                                  ...( !["rejected", "approved"].includes(data?.status?.toLowerCase())
                                    ? [
                                        {
                                          label: "Reject Notifications",
                                          img: IconVault(icons.x_circle_red),
                                        },
                                      ]
                                    : []),
                                ]}
                                buttonChild={() => {
                                  return <ActionDrop more />;
                                }}
                              />
                            ),
                          }}
                        />
                      ))}
                    </RavenTable>
                  </div>
                </>
              ) : (
                <EmptyScreen loading={loading} />
              )}
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>
      <RavenModal
        className={"new_notification_modal"}
        onClose={() => setShowCreate(false)}
        visble={showCreate}
        onBtnClick={function (e?: any | undefined): void {
          throw new Error("Function not implemented.");
        }}
      >
        <NewNotificationModal triggerClear={showCreate} onSuccess={() => {setShowCreate(false); refetch()}} />
      </RavenModal>

      <RavenModal
        className={"new_notification_modal"}
        onClose={() => setShowEdit("")}
        visble={Boolean(showEdit)}
        onBtnClick={function (e?: any | undefined): void {
          throw new Error("Function not implemented.");
        }}
      >
        <UpdateNotification  data={showEdit as any} onSuccess={() => setShowEdit("")} />
      </RavenModal>

      <RavenModal
        className={"new_notification_modal"}
        onClose={() => setView({ on: false, chi: "" })}
        visble={view.on}
        onBtnClick={function (e?: any | undefined): void {
          throw new Error("Function not implemented.");
        }}
      >
        <PreviewNotification
          data={view.chi}
          onAction={(e) => {

            if(e.type !== "approve" && e.type !== "reject"){
              setView({ on: false, chi: "" })
              setShowEdit(e.data)
              return
            } else {
              setShowAction(e);
            }


          }}
        />
      </RavenModal>

      <RavenModal
        className={"new_notification_modal"}
        onClose={() => setShowRefire("")}
        visble={Boolean(showRefire)}
        onBtnClick={function (e?: any | undefined): void {
          throw new Error("Function not implemented.");
        }}
      >
        <RefireNotification
          data={showRefire as any}
          onSuccess={() => setShowRefire("")}
        />
      </RavenModal>

      <ActionModal
        visible={Boolean(showActions.type)}
        onCancel={() => {
          setShowAction({ type: "", data: {} });
        }}
        onClick={handleNotificationActions}
        cancelText="No, Cancel"
        loading={reqn}
        actionText={`Yes ${capitalizeFirstLetter(showActions.type)}`}
        // btnColor="#1ACE37"
        body={`Are you sure you want to ${capitalizeFirstLetter(
          showActions.type
        )} this notification, this action is irreversible!`}
        title={`${capitalizeFirstLetter(showActions.type)} Notification`}
      />
    </>
  );
};

export default PersonalNotification;
