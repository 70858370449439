import PosInsights from "@/apps/partners/insights";
import { PosInsightBills } from "@/apps/partners/insights/pages/bills";
import { PosInsightsCardCollection } from "@/apps/partners/insights/pages/card_collection";
import { PartnersCollectionInsight } from "@/apps/partners/insights/pages/collection";
import { PosInsightsDeposits } from "@/apps/partners/insights/pages/deposits";
import { PosInsightsDeviceRequest } from "@/apps/partners/insights/pages/device-request";
import { PosInsightsTerminals } from "@/apps/partners/insights/pages/terminals";
import { PosInsightsTransfers } from "@/apps/partners/insights/pages/transfers";
import { PosInsightsUsers } from "@/apps/partners/insights/pages/users";
import { InsightsTransfersTable } from "@/apps/partners/insights/tables/transfers";
import {
  FailedSettlementPage,
  PendingSettlementPage,
} from "@/apps/pos/settlement/components/pages";
export const partners_insights_routes: RouteArray[] = [
  {
    path: "/partners-insights",
    element: PosInsights,
    children: [
      {
        path: "/partners-insights",
        element: PosInsightsTransfers,
      },
      {
        path: "/partners-insights/transfers",
        element: PosInsightsTransfers,
      },
      {
        path: "/partners-insights/transfers/table",
        element: InsightsTransfersTable,
      },
      {
        path: "/partners-insights/table",
        element: InsightsTransfersTable,
      },
      {
        path: "/partners-insights/transfers/table",
        element: InsightsTransfersTable,
      },
      {
        path: "/partners-insights/collection/table",
        element: InsightsTransfersTable,
      },
      {
        path: "/partners-insights/collection",
        element: PartnersCollectionInsight,
      },
      {
        path: "/partners-insights/settlements",
        element: PosInsightsTransfers,
        children: [
          { path: "", element: PendingSettlementPage },
          { path: "failed-settlement", element: FailedSettlementPage },
          { path: "pending-settlement", element: PendingSettlementPage },
        ],
      },
      {
        path: "/partners-insights/users",
        element: PosInsightsUsers,
      },
      {
        path: "/partners-insights/deposits",
        element: PosInsightsDeposits,
      },
      {
        path: "/partners-insights/device-request",
        element: PosInsightsDeviceRequest,
      },
      {
        path: "/partners-insights/terminals",
        element: PosInsightsTerminals,
      },

      {
        path: "/partners-insights/card-collections",
        element: PosInsightsCardCollection,
      },
      {
        path: "/partners-insights/bills",
        element: () => <PosInsightBills bill={"Airtime"} />,
      },
      {
        path: "/partners-insights/bills/airtime",
        element: () => <PosInsightBills bill={"Airtime"} />,
      },
      {
        path: "/partners-insights/bills/electricity",
        element: () => <PosInsightBills bill={"Electricity"} />,
      },
      {
        path: "/partners-insights/bills/data",
        element: () => <PosInsightBills bill={"Data"} />,
      },
      {
        path: "/partners-insights/bills/betting",
        element: () => <PosInsightBills bill={"Bet"} />,
      },
      {
        path: "/partners-insights/bills/cable tv",
        element: () => <PosInsightBills bill={"Cable"} />,
      },
      //   {
      //     path: "/pos-insights/cable",
      //     element: PosReversalBillsCablePage,
      //   },
    ],
  },
];
