import "./style/index.css";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { XStack } from "@/components/common/stacks";
import { GENERAL_CLASSES } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import TabComponent from "@/components/common/Tabs";

export interface ApproveManualAddressProps {
  view: ManualCompliance;
  setView: (view?: ManualCompliance) => void;
  showRevokeModal(id: SN): void;
  handleVerification(): void;
  tab: string;
}

export const ApproveManualNin = (props: ApproveManualAddressProps) => {
  const { setView, showRevokeModal, view, handleVerification, tab } = props;
  const { isLoading, data, isFetching } =
    personalReduxAPI.useGetManualNinVerificationInfoQuery({
      nin: view.token,
      email: view.email,
    });

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable, RenderCustom } = renderRedactedComponent(joinedLoader);

  const getButtonText = () => {
    if (!view) return "--";

    if (view.status === "pending") return "Verify User";
    return "Close Modal";
  };

  const generateNinView = () => {
    if (!view) return {};

    const nin = data?.nin_details;

    const info = nin?.info;
    return {
      nin: RedactedTable(cn(Util.safeValue(nin?.token))),
      name: RedactedTable(
        cn(
          Util.safeText(info?.firstname),
          Util.safeText(info?.middlename),
          Util.safeText(info?.lastname)
        )
      ),
      residential_address: RedactedTable(Util.safeText(info?.residentialAddress)),
      phone: RedactedTable(Util.safeText(info?.phone)),
      gender: RedactedTable(Util.safeText(info?.gender)),
      // photo: RedactedTable(Util.safeText(home_address)),
      // landmark: RedactedTable(Util.safeText(landmark)),
      // email: RedactedTable(Util.safeText(email)),
      // document_type: RedactedTable(Util.safeText(document_type))
    };
  };

  const generateBvnView = () => {
    if (!view) return {};

    const nin = data?.bvn_details;

    const info = data?.bvn_details;
    return {
      bvn: RedactedTable(cn(Util.safeValue(nin?.bvn))),
      name: RedactedTable(
        cn(
          Util.safeText(info?.first_name),
          Util.safeText(info?.middle_name),
          Util.safeText(info?.last_name)
        )
      ),
      residential_address: RedactedTable(Util.safeText(info?.address)),
      phone: RedactedTable(Util.safeText(info?.phone)),
      gender: RedactedTable(Util.safeText(info?.gender)),
      // landmark: RedactedTable(Util.safeText(landmark)),
      // email: RedactedTable(Util.safeText(email)),
      // document_type: RedactedTable(Util.safeText(document_type))
    };
  };

  const InfoText = styled.span`
    color: #676767;
    font-size: 1.4rem;
    font-style: italic;
    padding-top: 2rem;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
  `;

  const tabs = ["Details", "Image"];

  return (
    <ActionModal
      visible={Boolean(view)}
      onCancel={() => setView()}
      altCancelFunc={() => {
        setView();
        showRevokeModal(view.id);
      }}
      big
      extraBig={true}
      onClick={handleVerification}
      actionText={getButtonText()}
      btnColor="var(--primary-product-color)"
      className={cn(
        "address-verification-modal compliance-modal-main-lg ",
        GENERAL_CLASSES.SCROLLABLE_MODAL
      )}
      cancelText={TypeIs.any("Reject Verification")}
      title={`Verify ${formatWord(tab).toUpperCase()} Manually`}
      loading={joinedLoader}
      hideCancel={joinedLoader}
    >
      <TabComponent className={"w-full"} defaultValue={tabs[0]}>
        <TabComponent.Tabs tabs={tabs} className="pb-insights-sidebar__tab w-full" />
        <TabComponent.Content className="w-full pt-4" value={tabs[0]} key={tabs[0]}>
          <div className="biz-compliance-results w-full pt-4 manual-compliance-result">
            {/*{RenderCustom(*/}
            {/*  <ImagePreview*/}
            {/*    imgData={[*/}
            {/*      {*/}
            {/*        src: TypeIs.any(view?.location_image_url),*/}
            {/*        alt: "Image 1",*/}
            {/*        containerClassName: "biz-compliance-results__image-preview",*/}
            {/*      },*/}
            {/*      {*/}
            {/*        src: TypeIs.any(view?.location_image2_url),*/}
            {/*        alt: "Image 1",*/}
            {/*        containerClassName: "biz-compliance-results__image-preview",*/}
            {/*      },*/}
            {/*    ]}*/}
            {/*  />,*/}
            {/*  { height: 278, width: 280, className: "shimmer redacted curved mt-20" }*/}
            {/*)}*/}

            <div className="compliance-results__main compliance-lg-modal flex flex-row w-full">
              <div className={"w-[50%]"}>
                <p className={"px-8 font-bold"}>NIN Details</p>
                <InlineDetails
                  direction={""}
                  title={"Transaction Details"}
                  className="no-shadow w-full"
                  simple
                  amount={0}
                  onClick={() => {}}
                  content={Object.entries(generateNinView()).map(([key, value]) => ({
                    label: formatWord(key),
                    value,
                  }))}
                />
              </div>

              <div className={"w-[50%]"}>
                <p className={"px-8 font-bold"}>BVN Details</p>
                <InlineDetails
                  direction={""}
                  title={"Transaction Details"}
                  className="no-shadow w-full"
                  simple
                  amount={0}
                  onClick={() => {}}
                  content={Object.entries(generateBvnView()).map(([key, value]) => ({
                    label: formatWord(key),
                    value,
                  }))}
                />
              </div>
            </div>
          </div>
        </TabComponent.Content>

        <TabComponent.Content className="w-full pt-4" value={tabs[1]} key={tabs[1]}>
          <div className="biz-compliance-results w-full pt-4 manual-compliance-result">
            <div
              style={{ gap: "2rem" }}
              className="compliance-results__main compliance-lg-modal flex flex-row w-full"
            >
              <div className={"w-[50%] "}>
                <p style={{ paddingBottom: "2rem" }} className={"px-8 font-bold mb-2"}>
                  NIN Images
                </p>
                {RenderCustom(
                  <ImagePreview
                    imgData={[
                      {
                        src: TypeIs.any(data?.nin_details.image),
                        alt: "Image 1",
                        containerClassName:
                          "biz-compliance-results__image-preview w-full",
                      },
                      {
                        src: TypeIs.any(data?.nin_details?.info.photo),
                        alt: "Image 2",
                        containerClassName:
                          "biz-compliance-results__image-preview w-full",
                      },
                    ]}
                  />,
                  { height: 278, width: 280, className: "shimmer redacted curved" }
                )}
              </div>

              <div className={"w-[50%]"}>
                <p style={{ paddingBottom: "2rem" }} className={"px-8 font-bold"}>
                  BVN Images
                </p>
                {RenderCustom(
                  <ImagePreview
                    imgData={[
                      {
                        src: TypeIs.any(data?.bvn_details.photo),
                        alt: "Image 1",
                        containerClassName:
                          "biz-compliance-results__image-preview w-full",
                      },
                      {
                        src: TypeIs.any(data?.bvn_details.user_image),
                        alt: "Image 1",
                        containerClassName:
                          "biz-compliance-results__image-preview w-full",
                      },
                    ]}
                  />,
                  { height: 278, width: 280, className: "shimmer redacted curved " }
                )}
              </div>
            </div>
          </div>
        </TabComponent.Content>
      </TabComponent>
      <InfoText>
        Kindly compare between the NIN and BVN data before you verify the User
      </InfoText>
    </ActionModal>
  );
};
