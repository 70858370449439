import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PartnersMerchantsApi = (builder: PartnersBuilder) => {
  return {
    fetchMerchants: createQueryWithRoute<
      PartnersMerchantsModel,
      Partial<PartnersPaginationParams>
    >(builder, "all-merchant", { name: "fetchMerchants" }),

    /*getTransactionsExport: createQueryWithRoute<
      PartnersTransactionsModel,
      Partial<PartnersPaginationParams>
    >(builder, "transaction", { export: true, name: "getTransactionsExport" }),

    getATransaction: builder.query<PartnersTransactions, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `transaction/${params?.id}` }, thunk);
      },
    }),

    getReversals: builder.query<{ data: any; pagination: Pagination }, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `pending_reversals`, params }, thunk);
      },
    }),*/
  };
};
