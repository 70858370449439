import { useState } from "react";
import { useExportModal } from "@/hooks/useExportModal";
import { Util } from "@/utils/utility";
import { CustomFilterFilters } from "@/components/common/smartFilter/custom-filter";

type FilterOptions = {
  label: string;
  value: string;
};

type FilterValue = {
  startDate: string;
  endDate: string;
};

export const usePartnersFilter = (filters?: CustomFilterFilters<string>[]) => {
  const [filterValue, setFilterValue] = useState<FilterValue>({
    startDate: "",
    endDate: "",
  });

  const exportOptions = useExportModal();
  // useEffect(() => {
  //   $event.addEventListener("onExportFilterChange", (e) => alert(e));

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     $event.removeEventListener("onExportFilterChange", (e) => setExportValue(e as any));
  //   };
  // }, [])

  return {
    filterOptions: filters
      ? {
          onFilter: (e: any) => setFilterValue(Util.filterStruct(e, "partners") as any),
          customFilter: { filters },
        }
      : {
          onFilter: (e: any) => setFilterValue(Util.filterStruct(e, "partners") as any),
        },
    exportOptions,
    filterValue,
  };
};
