import ActionDrop from "@/components/common/actionDrop/ActionDrop";

import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import { posIcons } from "@/assets/icons/posIcons";
import TwinValue from "@/components/common/twinValue";
import { useNavigate } from "react-router-dom";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { sendRequest } from "@/utils/api-methods";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import usePartnersGetQuery from "@/apps/partners/__internals__/hooks/usePartnersGetQuery";

export const PosMerchantDeviceRequest = ({ email }: { email?: string }) => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } = usePartnersGetQuery<PartnersDeviceRequests>('getTerminalRequests', {
    email: email,
  })
  const data  = tableData;


  const merchants = data;
  const [btnLoading, setBtnLoading] = useState(false);

  const [modal, setModal] = useState({
    approve: "",
    dispatch: "",
  });
  async function handleStatus({ id, status }: { id: number; status: number }) {
    setBtnLoading(true);
    const resp = await sendRequest("terminal/request/update/status", {
      id,
      status,
    });

    if (resp) {
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
    }
  }

  return (
    <div className="bg-partners">
      <SmartFilter {...SmartFilterProps} />

      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "TYPE",
          "DELIVERY",
          "REQUEST DETAILS",
          "DATE REQUESTED",
          "STATUS",
          "",
        ]}
      >
        {merchants?.map((data, idx) => (
          <RavenTableRow
            key={idx}
            one={isRedacting ? <Redacted.Table /> : Util.safeValue(data.email)}
            two={
              isRedacting ? (
                <Redacted.Labelled />
              ) : (
                <TwinValue
                  one={Util.safeValue(Util.capitalizeEachWord(data?.quantity))}
                  two={`@ ${formatNumberToCurrency(data?.amount)} per piece`}
                />
              )
            }
            three={Util.cleanText(formatNumberToCurrency(data?.total_cost))}
            four={formatDateTime(data?.created_at)}

            five={<DeviceRequestStatus status={data?.status} />}
            eight={
              <Dropdown
                rightPosition
                items={[
                  {
                    label: "Set to In Approved",
                    img: IconVault(icons.check_circle_green),
                  },
                  { label: "Set to Dispatched", img: IconVault(posIcons.dispatch_van) },
                  {
                    label: "View Details",
                    img: IconVault(posIcons.arrow_right_circular),
                  },
                ]}
                onChange={(e: any) => {
                  e.event.stopPropagation();
                  setModal({
                    dispatch: "",
                    approve: String(data?.id),
                  });

                  // e.func({ chi: "", on: true });
                }}
                buttonChild={() => {
                  return <ActionDrop more />;
                }}
              />
            }
          />
        ))}
      </RavenTable>
      )}

      <ActionModal
        title={"Set To Approved"}
        body="Are you sure to set this device request to approved? You can always come back to perform the action."
        visible={Boolean(modal.approve)}
        onCancel={function (): void {
          setModal({
            ...modal,
            approve: "",
          });
        }}
        cancelText="No Cancel"
        onClick={function (): void {
          handleStatus({
            status: 1,
            id: Number(modal.approve),
          });
        }}
      />
    </div>
  );
};

const DeviceRequestStatus = ({ status }: { status: number }) => {
  return formatStatus(detectStatus(status));
};
