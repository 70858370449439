import { RavenTable } from "@ravenpay/raven-bank-ui";
import { BillsTable } from "../tables/PosTables";
import { ReactElement } from "react";
import SmartFilter from "@/components/common/smartFilter";
import { Util } from "@/utils/utility";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import usePartnersGetQuery from "@/apps/partners/__internals__/hooks/usePartnersGetQuery";

const AirtimeHeader = [
  "BUSINESS DETAILS",
  "REFERENCE",
  "PROVIDER",
  "AMOUNT",
  "MOBILE NUMBER",
  "DATE",
  "STATUS",
];
const BillsAirtimePage = ({ email }: { email?: string }) => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePartnersGetQuery<PartnersBillAirtime>("getAirtimeRecords", { email });
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Airtime key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps,
  );
};

const BillsDataPage = ({ email }: { email?: string }) => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
  usePartnersGetQuery<PartnersBillData>("getDataRecords", { email });
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Data key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps,
  );
};

const BillsElectricityPage = ({ email }: { email?: string }) => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
  usePartnersGetQuery<PartnersBillElectricity>("getElectricityRecords", { email });
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "AMOUNT",
          "METER NUMBER",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Electricity key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps,
  );
};

const BillsCablePage = ({ email }: { email?: string }) => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
  usePartnersGetQuery<PartnersBillCable>("getCableRecords", { email });
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "CABLE PLAN",
          "AMOUNT",
          "CARD NUMBER",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Cable key={idx} {...bill} isRedacting={isRedacting} />
        ))}
      </RavenTable>
    ),
    SmartFilterProps,
  );
};

const BillsBettingPage = ({ email }: { email?: string }) => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
  usePartnersGetQuery<PartnersBillBet>("getBettingRecords", { email });
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "BUSINESS DETAILS",
          "PROVIDER",
          "AMOUNT",
          "USER ID",
          "DATE",
          "STATUS",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Betting
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            loading={combinedLoading}
          />
        ))}
      </RavenTable>
    ),
    SmartFilterProps,
  );
};

function renderWithSmartFilter(node: ReactElement, SmartFilterProps: any): ReactElement {
  return (
    <>
      <SmartFilter {...SmartFilterProps} />
      {node}
    </>
  );
}

export const MerchantBills = {
  BillsBettingPage,
  BillsCablePage,
  BillsElectricityPage,
  BillsDataPage,
  BillsAirtimePage,
};
