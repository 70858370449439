import env from "@/env";
import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const PartnersTerminalsApi = (builder: PartnersBuilder) => {
  return {
    getTerminals: createQueryWithRoute<PartnersTerminalsModel, Partial<PartnersPaginationParams>>(
      builder,
      "all-terminals",
      { name: "getTerminals" }
    ),

    getTerminalsExport: createQueryWithRoute<
      PartnersTerminalsModel,
      Partial<PartnersPaginationParams>
    >(builder, "all-terminals", { export: true, name: "getTerminalsExport" }),

    getATerminal: builder.query<SinglePartnersTerminal, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `partner/terminal/?tid=${params?.id}` }, thunk);
      },
    }),

    getMerchants: createQueryWithRoute<PosMerchantsModel, Partial<PosPaginationParams>>(
      builder,
      "users",
      { name: "getMerchants" }
    ),

    getMerchantsExport: createQueryWithRoute<
      PosMerchantsModel,
      Partial<PosPaginationParams>
    >(builder, "users", { export: true, name: "getMerchantsExport" }),

    getAMerchant: builder.query<PosMerchants, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `users/${params.id}`, params }, thunk);
      },
    }),

    getTerminalRequests: createQueryWithRoute<PartnersDeviceRequestsModel, any>(
      builder,
      "partner/device-requests",
      { name: "getTerminalRequests" }
    ),

    getTerminalRequestsExport: createQueryWithRoute<PosDeviceRequestsModel, any>(
      builder,
      "partner/device-requests",
      {
        export: true,
        name: "getTerminalRequestsExport",
      }
    ),

    getAlertRecievers: builder.query<PosAlertRecievers[], any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `terminal/alert/detail/`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
