import React, { useEffect, useState } from "react";
import "./style/toggleView.css";
import { partnersIcons } from "@/assets/icons/partnersIcons";
import { useLocation, useNavigate } from "react-router-dom";

type View = "table" | "chart";

function ToggleView() {
  const [view, setView] = useState<View>("chart");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const current = location.pathname.split("/").at(-1);
    if (current === "table") {
      setView("table");
    } else {
      setView("chart");
    }
  }, [location]);
  return (
    <div className={`toggle_view`}>
      <button
        className={`${view === "chart" ? "active" : ""}`}
        onClick={() => {
          setView("chart");
          navigate(-1);
        }}
      >
        {(view === "chart" && partnersIcons.chartActive) || partnersIcons.chart}
      </button>
      <button
        className={`${view === "table" ? "active" : ""}`}
        onClick={() => {
          setView("table");
          location.pathname.includes("/table")
            ? navigate(location.pathname)
            : navigate(location.pathname + "/table");
        }}
      >
        {(view === "table" && partnersIcons.tableActive) || partnersIcons.table}
      </button>
    </div>
  );
}

export default ToggleView;
