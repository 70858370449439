// import { getCookie } from "../../../../utils/helper/Helper";
import React, { Fragment, useContext, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ActionContext from "../../../../context/ActionContext";
import "./style/index.css";
import {
  atlasSideMenuList,
  businessSideMenuList,
  partnersSideMenuList,
  personalSideMenuList,
  posSideMenuList,
} from "./menuList";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../../../../redux/actions/actions";

import { brands } from "../../../../assets/icons/index";
import { cn } from "@/utils/colorConvert";
import { useState } from "react";
import { Util } from "@/utils/utility";
import { trimLongString } from "@/utils/helper/Helper";
import Dropdown from "@/components/common/dropdown";
import { iife } from "@/utils/general";
import { posIcons } from "@/assets/icons/posIcons";

interface MyComponentProps {
  theme: any;
  product: RavenProducts;
}

const SideMenuComponent: React.FC<MyComponentProps> = ({ theme, product }) => {
  const { isMenuOpen } = useSelector((state: RootState) => state.general);
  const [user, setUser] = useState<any>({});

  const actionCtx = useContext(ActionContext);

  const sideMenuList: SidebarMenuDataList = iife(() => {
    switch (product) {
      case "personal":
        return personalSideMenuList;
      case "pos":
        return posSideMenuList;
      case "atlas":
        return atlasSideMenuList;
      case "business":
        return businessSideMenuList;
      case "partners":
        return partnersSideMenuList;
    }
  });

  const getProductName = (): string => {
    switch (product) {
      case "atlas":
        return "Raven Atlas";
      case "business":
        return "Business Banking";
      case "pos":
        return "RavenPOS";
      case "personal":
        return "Personal Banking";
      case "partners":
        return "Partners Console";
    }
  };

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [enabledApps, setEnabledApps] = useState<any>([]);

  async function getUser() {
    var d = await Util.getUser();

    setUser(d);
  }

  function toggleNav() {
    dispatch(toggleMenu(false));
  }

  useEffect(() => {
    getUser();
  }, []);

  const apps = [
    {
      label: "Business Banking",
      value: "business_banking",
      link: "/business-overview",
    },
    {
      label: "Personal Banking",
      value: "personal_banking",
      link: "/personal-overview",
    },
    {
      label: "Atlas",
      value: "atlas",
      link: "/atlas-overview",
    },
    {
      label: "POS",
      value: "pos",
      link: "/pos-overview",
    },

    {
      label: "Partners",
      value: "partners",
      link: "/partners-overview",
    },
  ];

  function supportedApps(): (
    | { label: string; value: string; link: string }
    | undefined
  )[] {
    const supported = (user && user?.platforms?.split(",")) ?? [];

    const cleanSupported = supported.map((d: any) => d.trim());

    const enabled: { label: string; value: string; link: string }[] = [];

    cleanSupported &&
      cleanSupported.forEach((e: string) => {
        const foundApp = apps.find(
          (d) => String(d.value.trim().toLowerCase()) === String(e)
        );

        if (foundApp) {
          enabled.push(foundApp);
        }
      });

    return enabled;
  }

  function listenCookieChange(callback: any, interval = 1000) {
    let lastCookie = document.cookie;
    setInterval(() => {
      let cookie = document.cookie;
      if (cookie !== lastCookie) {
        try {
          callback({ oldValue: lastCookie, newValue: cookie });
        } finally {
          lastCookie = cookie;
        }
      }
    }, interval);
  }

  useEffect(() => {
    const apps = supportedApps();
    setEnabledApps(apps);
  }, [user?.platforms]);

  return (
    <>
      <figure
        onClick={() => dispatch(toggleMenu(!isMenuOpen))}
        className={`tablet-menu-toggle non-desktop non-mobile ${
          isMenuOpen && "collapsed"
        } `}
      ></figure>
      <div className={cn("dashboard-sidemenu-wrap", `${product}-sidebar`)}>
        <div className="side_menu__header">
          {/* <div className="app_name"><p>{getProductName()}</p></div> */}
          <div className="app_header__content">
            <div className={cn("sidemenu-logo", product)}>
              {/* <figure className="img-box">
                {product === "pos"
                  ? posIcons.raven_logo
                  : theme === "light"
                  ? brands.raven_wordmark_white
                  : brands.raven_wordmark_white}
              </figure> */}

              <div className="text side-nav-logo-text"> {product} • Bugisss</div>
            </div>

            <div
              className={cn(
                "name-email-drop-box remove-dark-theme-border transparent-fade-white-bg",
                product
              )}
            >
              <div className="name-email-box">
                <p
                  style={{
                    textTransform: "uppercase",
                    fontSize: "10px",
                  }}
                  className="name"
                >
                  {getProductName()}
                </p>

                <p className="email">
                  {enabledApps?.length === 1
                    ? trimLongString(Util.safeValue(user.email), 18)
                    : "Switch Product"}
                </p>
              </div>
              <Dropdown
                rightPosition={"-1.6rem"}
                items={enabledApps as any}
                onChange={(e: any) => {
                  navigate(e?.link);
                }}
                buttonChild={(e: any) => {
                  return (
                    <div className="icon-box">
                      <FaAngleDown className="icon" />
                    </div>
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="nav-list-box">
          {sideMenuList.map((c, idx) => {
            if (c.hide) return <Fragment key={idx}></Fragment>;

            return (
              <div
                key={idx}
                //   style={{ marginBottom: !actionCtx.sideMenuDrop.one ? "1.5rem" : "" }}
                className="toggle-nav-box"
              >
                <div
                  onClick={() => {
                    actionCtx?.setSideMenuDrop(c.numb);
                  }}
                  className="get-started"
                >
                  <p className="title">{c.name}</p>

                  {c.numb !== "one" ? (
                    !actionCtx.sideMenuDrop[c.numb as "one"] ? (
                      <FaAngleDown className="icon" />
                    ) : (
                      <FaAngleUp className="icon" />
                    )
                  ) : (
                    <></>
                  )}
                </div>

                {c.children &&
                  c.children.map((chi, idx) => {
                    if (chi.hide) {
                      return <React.Fragment key={idx}></React.Fragment>;
                    }

                    return (
                      <div
                        key={idx}
                        className={`toggle-hide-show ${
                          (actionCtx?.sideMenuDrop[c.numb as "one"] as any) &&
                          "toggle-show"
                        }`}
                      >
                        <div
                          onClick={() => {
                            navigate(chi.link);
                            toggleNav();
                          }}
                          className={`nav-link ${
                            location.pathname.includes(chi.link) && "nav-link-active"
                          }`}
                        >
                          {product === "partners" ? (
                            <figure className="side-icon">
                              {location.pathname.includes(chi.link)
                                ? chi.activeIcon
                                : chi.icon}
                            </figure>
                          ) : (
                            <figure className="side-icon">{chi.icon}</figure>
                          )}

                          <p className="text">{chi.name}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SideMenuComponent;
