export const INSIGHT_TYPES = {
  EXPENSE: 'expense',
  DEVICE_REQUEST: 'device-request',
  DEPOSIT: 'deposit',
  USER: 'user',
  TERMINAL: 'terminal',
  AIRTIME: 'airtime',
  ELECTRICITY: 'electricity',
  DATA: 'data',
  CABLE: 'cable',
  BET: 'bet',
  CARD_COLLECTION: 'card-collection',
  SETTLEMENT: 'settlement',
} as const;



export type InsightType = typeof INSIGHT_TYPES[keyof typeof INSIGHT_TYPES];
