import env from "@/env";
import axios from "axios";
import { getCookie } from "../helper/Helper";
import qs from "qs";
import { AUTH_ERROR_STATUS, AUTH_EXPIRED_CUSTOM_EVENT } from "@/constants";

export const PartnersApi = axios.create({
  baseURL: env.partners_api,
  paramsSerializer: (params) => qs.stringify(params, { encode: false }),
});

PartnersApi.interceptors.request.use(
  async (config: any) => {
    const _reToken = getCookie("g-rec-response");
    if (_reToken) {
      config.headers["g-rec-response"] = _reToken;
    }
    config.headers["ngrok-skip-browser-warning"] = "69420";
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

PartnersApi.interceptors.response.use(
  async (response: any) => {
    if (AUTH_ERROR_STATUS.includes(response.data.status)) {
      window.dispatchEvent(new Event(AUTH_EXPIRED_CUSTOM_EVENT));
      return Promise.reject(response);
    }

    delete response.config.transformRequest;
    delete response.data?.header;
    return response;
  },
  async (error: any) => {
    let val;
    if (error.response.data.status === "fail") {
      val = error.response;
      delete val.config.transformRequest;
    }
    return val;
  }
);

export default PartnersApi;
