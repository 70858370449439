import { useEffect } from 'react';

const useDynamicStyles = () => {
  useEffect(() => {
    // Function to apply dynamic styles based on utility classes
    const applyStyles = () => {
      document.querySelectorAll('[class*="color-["], [class*="bg-["], [class*="border-["], [class*="w-["], [class*="h-["], [class*="p-["], [class*="m-["], [class*="text-["]').forEach((element) => {
        // Ensure the element is an HTMLElement to access the style property
        if (element instanceof HTMLElement) {

          // Handle color, bg, and border classes
          const colorMatch = element.className.match(/(color|bg|border)-\[#([0-9a-fA-F]+)\]/);

          if (colorMatch) {
            const property = colorMatch[1]; // color, bg, or border
            const value = `#${colorMatch[2]}`; // The hex color code

            // Apply the color to the corresponding CSS property
            if (property === 'color') {
              element.style.color = value;
            } else if (property === 'bg') {
              element.style.backgroundColor = value;
            } else if (property === 'border') {
              element.style.borderColor = value;
            }
          }

          // Handle width utility classes like w-[100px], w-[50%]
          const widthMatch = element.className.match(/w-\[([^\]]+)\]/);
          if (widthMatch) {
            const widthValue = widthMatch[1]; // Get the value inside the square brackets
            element.style.width = widthValue;
          }

          // Handle height utility classes like h-[100px], h-[50%]
          const heightMatch = element.className.match(/h-\[([^\]]+)\]/);
          if (heightMatch) {
            const heightValue = heightMatch[1];
            element.style.height = heightValue;
          }

          // Handle padding utility classes like p-[10px], px-[20px], py-[15px]
          const paddingMatch = element.className.match(/p-\[([^\]]+)\]|px-\[([^\]]+)\]|py-\[([^\]]+)\]/);
          if (paddingMatch) {
            const paddingValue = paddingMatch[1] || paddingMatch[2] || paddingMatch[3]; // Extract the padding value
            element.style.padding = paddingValue;
            if (paddingMatch[2]) {
              // Handle specific padding for left/right (px-)
              element.style.paddingLeft = paddingValue;
              element.style.paddingRight = paddingValue;
            }
            if (paddingMatch[3]) {
              // Handle specific padding for top/bottom (py-)
              element.style.paddingTop = paddingValue;
              element.style.paddingBottom = paddingValue;
            }
          }

          // Handle margin utility classes like m-[10px], mx-[20px], my-[15px]
          const marginMatch = element.className.match(/m-\[([^\]]+)\]|mx-\[([^\]]+)\]|my-\[([^\]]+)\]/);
          if (marginMatch) {
            const marginValue = marginMatch[1] || marginMatch[2] || marginMatch[3]; // Extract the margin value
            element.style.margin = marginValue;
            if (marginMatch[2]) {
              // Handle specific margin for left/right (mx-)
              element.style.marginLeft = marginValue;
              element.style.marginRight = marginValue;
            }
            if (marginMatch[3]) {
              // Handle specific margin for top/bottom (my-)
              element.style.marginTop = marginValue;
              element.style.marginBottom = marginValue;
            }
          }

          // Handle text size utility classes like text-[16px]
          const textSizeMatch = element.className.match(/text-\[([^\]]+)\]/);
          if (textSizeMatch) {
            const textSizeValue = textSizeMatch[1];
            element.style.fontSize = textSizeValue;
          }
        }
      });
    };

    // Apply styles on initial load
    applyStyles();

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(() => {
      applyStyles(); // Reapply styles on any DOM change
    });

    // Observe changes to the entire document (with subtree and childList options)
    observer.observe(document.body, {
      childList: true, // Watch for child nodes being added or removed
      subtree: true,   // Watch the entire document, not just the body
      attributes: true, // Watch for attribute changes (e.g., class changes)
    });

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []); // Run only once when the component mounts

  return null; // This hook doesn't need to return anything
};

export default useDynamicStyles;
