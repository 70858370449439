import { RavenButton, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { ReactNode } from "react";
import "./styles/index.css";
import { cn } from "@/utils/colorConvert";
import { useProductColor } from "@/context/ProductTheme";
import ReactDOM from "react-dom";
import Copy, { CopyText } from "../copyCheck";
import { handleCopy } from "@/utils/helper/Helper";
import ReactDOMServer from "react-dom/server";
// import { Input } from "../input";

type Modal = {
  title: string;
  body?: string;
  cancelText?: string;
  actionText?: string;
  className?: string;
  btnClassName?: string;
  visible: boolean;
  onCancel: () => void;
  onClick: () => void;
  btnColor?: string;
  hideCancel?: boolean;
  big?: boolean;
  extraBig?: boolean;
  normal?: boolean;
  children?: ReactNode;
  loading?: boolean;
  btnTextColor?: string;
  hideNormalButton?: boolean;
  preventOuterClose?: boolean;
  altCancelFunc?(): void;
  subTitle?: string;
  disableButton?: boolean;
  pinEnabled?: true;
  inlineCopyContent?: any;
};
const ActionModal = (props: Modal) => {
  const {
    title,
    body,
    cancelText,
    actionText,
    className,
    btnClassName,
    onCancel,
    visible = false,
    onClick,
    btnColor,
    hideCancel = false,
    big = false,
    extraBig = false,
    normal = false,
    subTitle,
    inlineCopyContent,
    children,
    loading,
    btnTextColor,
    hideNormalButton,
    altCancelFunc,
    preventOuterClose,
    disableButton,
  } = props;

  const { colorToken } = useProductColor();

  const portal = document.getElementById("modal") as Element;
  const [inlineCopied, setInlineCopied] = React.useState(false);
  if (children) {
    return ReactDOM.createPortal(
      <RavenModal
        className={cn(
          `general-action-modal ${big ? "action-big-modal" : ""} ${extraBig ? "extra-big-modal" : ""} ${
            normal ? "action-normal-modal" : ""
          }`,
          hideNormalButton && "close--button-space"
        )}
        outerClose={preventOuterClose}
        visble={visible}
        onClose={onCancel}
        onBtnClick={function (e?: any): void {
          throw new Error("Function not implemented.");
        }}
      >
        
        {Boolean(inlineCopyContent) &&
          <CopyText onClick={() => handleCopy(formatTableDataForCopy(inlineCopyContent), setInlineCopied, inlineCopied)} className="inline-copy cursor-pointer" 
          alwaysVisible 
          textToCopy={formatTableDataForCopy(inlineCopyContent)}
          text={
            <div className="copy--text">
            <p>{inlineCopied ? "Copied" : "Copy Details"}</p>
            </div> as any
              }/>
        }
      
     

        <div className={`bl-modal ${className}`}>
          <div className="bl-modal__content hide-scrollbar">
            <h6 className="bl-modal__title">{title}</h6>
            {subTitle && (
              <p
                style={{ color: "#676767", fontSize: "1.4rem", fontWeight: "400" }}
                className="bl-modal__title"
              >
                {subTitle}
              </p>
            )}
            <div className="action-modal-child ">{children}</div>
          </div>

          <div className="bl-modal__action-btn-contain">
            {!hideCancel && (
              <RavenButton
                className={`cancel-btn`}
                color="error-light"
                onClick={altCancelFunc ?? onCancel}
                label={cancelText || "Cancel"}
              />
            )}
            {!hideNormalButton && (
              <RavenButton
                className={`action-btn ${btnClassName} `}
                style={{
                  backgroundColor: btnColor ?? colorToken,
                  color: btnTextColor,
                  width: hideCancel && !big ? "100%" : "",
                }}
                color="deep-green-light"
                onClick={onClick}
                loading={loading}
                label={actionText || "Click"}
                disabled={disableButton}
              />
            )}
          </div>
        </div>
      </RavenModal>,
      portal
    );
  } else {
    return ReactDOM.createPortal(
      <RavenModal
        className={`general-action-modal ${big ? "action-big-modal" : ""}`}
        visble={visible}
        onClose={onCancel}
        onBtnClick={function (e?: any): void {
          throw new Error("Function not implemented.");
        }}
      >
        <div className={`bl-modal ${className}`}>
          <div className="bl-modal__content">
            <h6 className="bl-modal__title">{title}</h6>
            <p className="bl-modal__body">{body || "the body goes here"}</p>
          </div>

          <div className="bl-modal__action-btn-contain">
            {!hideCancel && (
              <RavenButton
                className={`cancel-btn`}
                color="error-light"
                onClick={altCancelFunc ?? onCancel}
                label={cancelText || "Cancel"}
              />
            )}
            <RavenButton
              className={`action-btn ${btnClassName} `}
              style={{
                backgroundColor: btnColor ?? colorToken,
                width: hideCancel && !big ? "100%" : "",
              }}
              loading={loading}
              color="deep-green-light"
              onClick={onClick}
              label={actionText || "Click"}
            />
          </div>
        </div>
      </RavenModal>,
      portal
    );
  }
};

function extractText(content: any): string {
  // Check if the content is a React element
  if (React.isValidElement(content)) {
    // Convert JSX to plain text
    return ReactDOMServer.renderToStaticMarkup(content)
      .replace(/<[^>]+>/g, "") // Remove HTML tags
      .trim();
  }
  // Handle other content types
  return content?.toString() || "--";
}

interface TableData {
  label: string;
  value: any;
}

function formatTableDataForCopy(data: TableData[]): string {
  const rows = data.map(({ label, value }) => [label, extractText(value)]);
  const columnWidths = [
    Math.max(...rows.map(([label]) => label.length)) + 2, // Add padding for alignment
    Math.max(...rows.map(([, value]) => value.length)) + 2,
  ];

  const formattedRows = rows.map(
    ([label, value]) =>
      `${label.padEnd(columnWidths[0])}: ${value.padEnd(columnWidths[1])}`
  );

  return formattedRows.join("\n");
}

export default ActionModal;
