import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import {
  IconVault,
  formatDateTime,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import { Util } from "@/utils/utility";
import { useNavigate } from "react-router-dom";
import Redacted from "@/components/common/shimmer/redact";
import { useState } from "react";
import ActionModal from "@/components/common/modal/ActionModal";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import Gap from "@/components/common/styleComponents/Gap";
import { CopyText } from "@/components/common/copyCheck";
import usePartnersGetQuery from "@/apps/partners/__internals__/hooks/usePartnersGetQuery";
import { isEmpty } from "@/apps/partners/__internals__/assertIfEmpty";

const PartnersTable = () => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } =
    usePartnersGetQuery<PartnersPartners>("getPartners");

  const navigate = useNavigate();
  const [modal, setModal] = useState<any>("");
  const [isReqn, setIsReqn] = useState(false);

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      modal?.blocked ? "users/un_block" : "users/block",
      {
        email: modal.email,
      },
      {
        base_url: env.partners_api,
      }
    );

    if (resp?.status === "success") {
      setModal("");

      refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }
  return (
    <>
      <SmartFilter {...SmartFilterProps} searchTitle="Search Partners"  />

      {isEmpty(tableData) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "PARTNER'S NAME",
            "EMAIL ADDRESS",
            "MOBILE NUMBER",
            "REGION",
            "DATE REGISTERED",
            " ",
          ]}
        >
          {tableData?.map((data, idx) => {
            // const blocked = Boolean(data?.block_);

            return (
              <RavenTableRow
                key={idx}
                onRowClick={() => {
                  navigate(`${data.email}`);
                }}
                one={
                  isRedacting ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <Avatar
                      name={String(
                        trimLongString(Util.safeText(data?.app_name), 20)?.toUpperCase()
                      )}
                      full
                    />
                  )
                }
                two={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    <CopyText
                      textToCopy={Util.safeText(data?.email)}
                      text={Util.safeValue(trimLongString(data.email, 23))}
                    />
                  )
                }
                three={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    <CopyText
                      textToCopy={Util.safeText(data?.phone)}
                      text={Util.safeValue(trimLongString(data.phone, 23))}
                    />
                  )
                }
                four={isRedacting ? <Redacted.Table /> : Util.safeValue(data?.region)}
                five={isRedacting ? <Redacted.Table /> : formatDateTime(data.created_at)}
                // six={
                //   isRedacting ? (
                //     <Redacted.Table />
                //   ) : (
                //     formatStatus(!Boolean(data.block_) ? "active" : "blocked")
                //   )
                // }
                /* seven={
                  isRedacting ? (
                    <Redacted.Avatar />
                  ) : (
                    <Dropdown
                      rightPosition
                      items={[
                        { label: "View Details", img: IconVault(icons.edit_04) },

                        {
                          label: blocked ? "Unblock Merchant" : "Block Merchant",
                          img: blocked
                            ? IconVault(icons?.check_verified)
                            : IconVault(icons.slash_circle_01),
                        },
                      ]}
                      buttonChild={() => {
                        return <ActionDrop more />;
                      }}
                      onChange={(e) => {
                        if (e.label.includes("View")) {
                          navigate(`${data.id}`);
                        } else {
                          setModal({
                            email: data?.email,
                            blocked,
                            label: blocked ? "Unblock" : "Block",
                            reason: "",
                          });
                        }
                      }}
                    />
                  )
                }*/
              />
            );
          })}
        </RavenTable>
      )}

      <ActionModal
        visible={Boolean(modal)}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleActivateDeactivate();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${modal?.label}`}
        btnColor={"red"}
        disableButton={!modal.reason && modal.blocked}
        body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
        title={`${modal?.label} Merchant?`}
      >
        {Util.renderIf(
          !modal.blocked,
          <>
            <p>Are you sure to Block Merchant</p>

            <Gap size={20} />

            <RavenInputField
              onChange={(e: { target: { value: any } }) =>
                setModal({
                  ...modal,
                  reason: e.target?.value,
                })
              }
              label="Block Reason*"
              placeholder="Enter Block Reason"
              color={"orange-light"}
            />
          </>
        )}
      </ActionModal>
    </>
  );
};

export const PartnersPartners = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Partners">
        <PartnersTable />
      </PageLayout>
    </DashboardLayout>
  );
};
