import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import {
  IconVault,
  formatStatus,
  formatDateTime,
  trimLongString,
} from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import { RavenTable, RavenTableRow, toast } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import TwinValue from "@/components/common/twinValue";
import usePartnersGetQuery from "../__internals__/hooks/usePartnersGetQuery";
import { useState } from "react";
import { isEmpty } from "../__internals__/assertIfEmpty";
import { sendRequest } from "@/utils/api-methods/partners";
import env from "@/env";

const MerchantsTable = () => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } =
    usePartnersGetQuery<PartnersMerchants>("fetchMerchants");
  const merchants = tableData;
  const [modal, setModal] = useState<any>("");
  const [isReqn, setIsReqn] = useState(false);

  return (
    <div>
      <SmartFilter {...SmartFilterProps} />
      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "PARTNER",
            "PHONE NUMBER",
            "CATEGORY",
            "REGION",
            "DATE REGISTERED",
            "",
          ]}
        >
          {merchants.map((data, index) => {
            return (
              <RavenTableRow
                key={index}
                one={
                  isRedacting ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <TwinValue
                      one={Util.safeValue(data?.business_name)}
                      two={Util.safeValue(data?.business_email)}
                    />
                  )
                }
                two={
                  <TwinValue
                    one={Util.safeValue(data?.partner_name)}
                    two={Util.safeValue(data?.partner_email)}
                  />
                }
                three={Util.safeValue(data?.phone)}
                four={Util.safeValue(data?.business_category)}
                five={Util.safeValue(data?.region)}
                six={formatDateTime(data?.created_at)}
                // seven={
                //   <Dropdown
                //     rightPosition
                //     items={[
                //       { label: "View Details", img: IconVault(icons.edit_04) },
                //       {
                //         label: "Deactivate Merchant",
                //         img: IconVault(icons.user_profile_x),
                //       },
                //       { label: "Generate Statement", img: IconVault(icons.file) },
                //       { label: "Block Merchant", img: IconVault(icons.slash_circle_01) },
                //     ]}
                //     buttonChild={() => {
                //       return <ActionDrop more />;
                //     }}
                //   />
                // }
              />
            );
          })}
        </RavenTable>
      )}
    </div>
  );
};

export const PartnersMerchants = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Merchants">
        <MerchantsTable />
      </PageLayout>
    </DashboardLayout>
  );
};
