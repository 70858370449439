import PosInsights from "@/apps/pos/insights";
import { PosInsightBills } from "@/apps/pos/insights/pages/bills";
import { PosInsightsCardCollection } from "@/apps/pos/insights/pages/card_collection";
import { PosInsightsDeposits } from "@/apps/pos/insights/pages/deposits";
import { PosInsightsDeviceRequest } from "@/apps/pos/insights/pages/device-request";
import { PosInsightsTerminals } from "@/apps/pos/insights/pages/terminals";
import { PosInsightsTransfers } from "@/apps/pos/insights/pages/transfers";
import { PosInsightsUsers } from "@/apps/pos/insights/pages/users";
export const pos_insights_routes: RouteArray[] = [
  {
    path: "/pos-insights",
    element: PosInsights,
    children: [
      {
        path: "/pos-insights/",
        element: PosInsightsUsers,
      },
      {
        path: "/pos-insights/settlements",
        element: PosInsightsTransfers.BankTransfers,
      },
      {
        path: "/pos-insights/users",
        element: PosInsightsUsers,
      },
      {
        path: "/pos-insights/deposits",
        element: PosInsightsDeposits,
      },
      {
        path: "/pos-insights/device-request",
        element: PosInsightsDeviceRequest,
      },
      {
        path: "/pos-insights/terminals",
        element: PosInsightsTerminals,
      },
      {
        path: "/pos-insights/card-collection",
        element: PosInsightsCardCollection,
      },
      {
        path: "/pos-insights/bills",
        element: () => <PosInsightBills bill={"Airtime"} />,
      },
      {
        path: "/pos-insights/bills/airtime",
        element: () => <PosInsightBills bill={"Airtime"} />,
      },
      {
        path: "/pos-insights/bills/electricity",
        element: () => <PosInsightBills bill={"Electricity"} />,
      },
      {
        path: "/pos-insights/bills/data",
        element: () => <PosInsightBills bill={"Data"} />,
      },
      {
        path: "/pos-insights/bills/betting",
        element: () => <PosInsightBills bill={"Bet"} />,
      },
      {
        path: "/pos-insights/bills/cable tv",
        element: () => <PosInsightBills bill={"Cable"} />,
      },
      //   {
      //     path: "/pos-insights/cable",
      //     element: PosReversalBillsCablePage,
      //   },
    ],
  },
];
