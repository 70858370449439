import { PosBuilder } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed

export const PosHniApi = (builder: PosBuilder) => {
  return {
    getManagers: createQueryWithRoute<PosHniManagerModel, Partial<PosPaginationParams>>(builder, "hni/managers", { name: "getManagers" }),

    getHni: createQueryWithRoute<PosHniBusinessModel, Partial<PosPaginationParams>>(builder, "hni/businesses", { name: "getHni" }),

    getHniExport: createQueryWithRoute<PosHniBusinessModel, Partial<PosPaginationParams>>(builder, "transaction", {
      export: true,
      name: "getHniExport"
    }),

    getManagersExport: createQueryWithRoute<
      PosHniBusinessModel,
      Partial<PosPaginationParams>
    >(builder, "hni/managers", { export: true, name: "getManagersExport" }),
  };
};
