import EmptyScreen from "@/components/common/emptyScreen";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { formatNumberToCurrency } from "@/utils/helpers";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import TwinValue from "@/components/common/twinValue";
import { Util } from "@/utils/utility";
import { useSelector } from "react-redux";
import Redacted from "@/components/common/shimmer/redact";
import { formatDateTime, formatMetaData, trimLongString } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import Txreference from "@/components/common/reference/TxRef";

export const MerchantPosBankCollections = ({ email }: { email?: string }) => {
  const { SmartFilterProps, combinedLoading, tableData } =
    usePosGetQuery<PosBankCollection>("getBankCollections", { email }, { skip: !email });

  const merchants = tableData;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  return (
    <>
      <SmartFilter {...SmartFilterProps} />
      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "SESSION ID",
            "AMOUNT",
            "SOURCE",
            "ACCOUNT NAME",
            "ACCOUNT NUMBER",
            "DATE DEPOSITED",
          ]}
        >
          {merchants?.map((chi, idx) => {
            const meta: PosBankCollectionMeta = JSON.parse(chi.source);

            return (
              <RavenTableRow
                key={idx}
                one={
                  isRedacting ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <Txreference
                      reference={Util.safeValue(
                        formatMetaData(chi?.meta_data)?.session_id || chi?.session_id
                      )}
                      textToCopy={Util.safeValue(
                        formatMetaData(chi?.meta_data)?.session_id || chi?.session_id
                      )}
                      trimLength={15}
                      trim
                    />
                  )
                }
                two={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(Number(chi?.amount), chi.currency)
                  )
                }
                three={isRedacting ? <Redacted.Table /> : Util.safeValue(meta.bank)}
                four={
                  isRedacting ? (
                    <Redacted.Table />
                  ) : (
                    trimLongString(Util.join(meta?.first_name).with(meta?.last_name), 30)
                    // <TwinValue
                    //   one={trimLongString(
                    //     Util.join(meta?.first_name).with(meta?.last_name),
                    //     30
                    //   )}
                    //   // two={Util.safeValue(chi.account_number)}
                    // />
                  )
                }
                five={
                  isRedacting ? <Redacted.Table /> : Util.safeValue(chi.account_number)
                }
                six={isRedacting ? <Redacted.Table /> : formatDateTime(chi.created_at)}
              />
            );
          })}
        </RavenTable>
      )}
    </>
  );
};
