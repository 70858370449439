import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import SmartFilter from "../../../components/common/smartFilter";
import EmptyScreen from "../../../components/common/emptyScreen";
import { RavenModal, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar, { AvatarWithTwinValue } from "../../../components/common/avatar";
import { useNavigate } from "react-router-dom";
import {
  IconVault,
  capitalizeFirstLetter,
  formatStatus,
  mapHotkey, formatDateTime
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";
import TwinValue from "@/components/common/twinValue";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "./styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import { businessReduxAPI } from "@/redux/slices/business/api";


const BusinessDispute = () => {
  const [empty, setEmpty] = useState(false);
  const [revokeModal, showRevokeModal] = useState({ chi: "", on: false });
  const [verify, showVerifyModal] = useState({ chi: "", on: false });
  const [view, setView] = useState<{ chi: any; on: boolean }>({
    chi: {},
    on: false,
  });
  const navigate = useNavigate();
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<string>("NIN Verification");

  const tableHeader = [
    "User Details",
    "Reference",
    "Description",
    "Date Logged",
    "Status",
    " ",
  ];


  const dropdown = [
    {
      label: `Set to Resolved`,
      img: IconVault(icons.check_circle),
      func: showVerifyModal,
    },
  ] as const;

  const {data,isLoading, isFetching } = businessReduxAPI.useGetDisputesQuery({})


  const disputes = data?.dispute_records?.data  as BusinessDispute[];

  const loading = isLoading || isFetching
  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Dispute">
          <div className="personal-savings__top-wrap"></div>
          {disputes && Array.isArray(disputes) && disputes.length > 0 && !loading ? (
            <>
              <SmartFilter
                page="insights"
                simple
                searchTitle={`Search Dispute...`}
                pagination={{
                  currentPage: 0,
                  itemsPerPage: 0,
                  totalItems: 0,
                  onPageChange: function (page: number): void {
                    throw new Error("Function not implemented.");
                  },
                  activeFilter: `All ${selectedTab}`,
                }}
              />

              <div className="table">
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={tableHeader}
                >
                  {disputes.map((data, index) => (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => setView({ on: true, chi: data })}
                      {...{
                        one: (
                          <div className="table-icon-row">
                            <AvatarWithTwinValue one={data?.business_name} two={data?.business_email} name={(data.business_name as string) ?? " - -"}  />
                          </div>
                        ),

                        two: data.phone,
                        three: data.more_details,
                        four: formatDateTime(data.created_at),
                        five: formatStatus(data.status),
                        six: (
                          <div>
                            <Dropdown
                              items={dropdown}
                              defaultValue={dropdown[0]}
                              onChange={(e: any) => {
                                e.event.stopPropagation();

                                e.func({ chi: "", on: true });
                              }}
                              className="ragna-dropdown"
                              buttonChild={({ selected }) => (
                                <ActionDrop
                                  more
                                  // onMoreClick={() => {
                                  //   navigate("/personal-users/re43rfs");
                                  // }}
                                />
                              )}
                            />
                          </div>
                        ),
                      }}
                    />
                  ))}
                </RavenTable>
              </div>
            </>
          ) : (
            <EmptyScreen loading={loading} />
          )}
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          showRevokeModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${selectedTab.split(" ")[0]}?`}
      />

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Verify ${selectedTab.split(" ")[0]}?`}
      />

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ chi: "", on: false });
        }}
        onClick={() => {}}
        actionText={
          view.chi.status === "pending" ? "Set to In Review" : "Set to In Resolved"
        }
        btnColor={view.chi.status === "pending" ? "#FAFAFF" : "#E8FFF6"}
        btnTextColor={view.chi.status === "pending" ? "#755AE2" : "#1ACE37"}
        className="dispute-modal"
        normal
        hideCancel
        title={`Dispute Details`}
      >
        {
          <InlineDetails
            direction={""}
            title={"Transaction Details"}
            simple
            className="no-shadow"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: selectedTab.split(" ")[0],
                value: "4343490232",
                copy: true,
              },
              {
                label: "Fullname",
                value: "Emmanuel Chinonso",
              },

              {
                label: "Mobile Number",
                value: "6859438374576",
              },

              {
                label: "Address",
                value: "10, Akinboye Drive, Orchid",
              },

              {
                label: "Date of Birth",
                value: "2nd June 2003",
              },

              {
                label: "Date Authorized",
                value: "5 Sept, 2022 - 5:48PM",
              },
            ]}
          />
        }
      </ActionModal>
    </>
  );
};

export default BusinessDispute;
