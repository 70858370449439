import { isValidElement } from "react";
import { formatWord } from "./formatWord";
import { Util } from "./utility";
import dayjs from "dayjs";
import {
  ALL_CURRENCIES,
  CUSTOM_FILTER_VALUE_FOR_ALL,
  FILTER_KEYS_TO_EXCLUDE_ALL_FROM,
} from "@/constants";
import { cn } from "./colorConvert";
import { Validator } from "./validator";
import { BIZ_VERIFICATION_STATUS } from "@/apps/business/__internals__/constants/index";

/**
 *@example
 *```ts
console.log(Object.prototype.toString.call({}) = '[object Object]');
console.log(Object.prototype.toString.call([]) = '[object Array]');
console.log(Object.prototype.toString.call(null) = '[object Null]');
 *```
 * @see {@link https://bobbyhadz.com/blog/javascript-check-if-value-is-object}
 * 
 */
export const isObject = (value: unknown): value is object => {
  if (!value) return false;

  return Object.prototype.toString.call(value) === "[object Object]";
};

export const isEmptyObject = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

/**
 * @returns `false` if object is empty and `true` if it has content
 */
export function isObjectWithValidValues(obj: any): boolean {
  // Check if the object is defined and not null
  if (obj === undefined || obj === null || typeof obj !== "object") {
    return false;
  }

  // Check if the object has at least one property
  if (Object.keys(obj).length === 0) {
    return false;
  }

  // Check each key-value pair for empty string or null
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      // Check if the value is null or an empty string
      if (value === null || value === "") {
        return false;
      }
    }
  }

  return true;
}

export const assertProductTheme = (theme: string | null): RavenProducts => {
  const allThemes: RavenProducts[] = ["atlas", "pos", "business", "personal", "partners"];

  if (!theme) return "personal";

  if (allThemes.includes(theme as any)) return theme as RavenProducts;

  return "personal";
};

export const getThemePrimaryColor = (color?: RavenProducts) => {
  const storageColor = color ?? localStorage.getItem("raven-product-theme");

  switch (assertProductTheme(storageColor)) {
    case "personal":
      return "#755AE2";
    case "atlas":
      return "#0B8376";
    case "business":
      return "#020202";
    case "pos":
      return "#EA872D";
    case "partners":
      return "#476885";
  }
};

export const getProductColorScheme = (color?: RavenProducts) => {
  const storageColor = color ?? localStorage.getItem("raven-product-theme");

  switch (assertProductTheme(storageColor)) {
    case "personal":
      return "purple-light";
    case "atlas":
      return "green-light";
    case "business":
      return "black-light";
    case "pos":
      return "orange-light";
    case "partners":
      return "blue-light";
  }
};

export function createStyleCSSProperty(name: string, gap: SN) {
  if (typeof gap === "string") return { [name]: gap } as React.CSSProperties;

  return { [name]: `${gap}rem` } as React.CSSProperties;
}


export function createStyleCSSJustifyProperty(name: string, gap: any) {
  if (typeof gap === "string") return { [name]: gap } as React.CSSProperties;

  return { [name]: `${gap}` } as React.CSSProperties;
}

export const FakePaginationProps = {
  currentPage: 0,
  itemsPerPage: 0,
  totalItems: 0,
  onPageChange: function (page: number): void {
    throw new Error("Function not implemented.");
  },
};

export function detectStatus(tx: number, type?: string): string {
  if (type === "pb-savings") {
    if (tx === 0) return "completed";
    return "in-progress";
  }

  if (type === "transactions") {
    switch (tx) {
      case 0:
      case 6:
      case 5:
        return "pending";
      case 1:
      case 7:
        return "processing";
      case 2:
        return "failed";
      case 3:
        return "success";
      case 11:
        return "reversed";
      case 26:
      case 25:
        return "pending approval";
      default:
        return "unknown";
    }
  }

  if (type === "pb-deposits") {
    switch (tx) {
      case 1:
        return "Credited";
      case 3:
        return "Pending Crediting";
      case 10:
        return "Delayed Crediting";
    }
  }

  if (type === "personal-users-status") {
    return tx === 0 ? "active" : "blocked";
  }

  if (type === "pb-transfer") {
    if (tx === 5) {
      return "failed";
    }

    if (tx === 4) return "success";
  }

  if (type === "invoice") {
    if (tx === 0) {
      return "pending";
    } else if (tx === 1) {
      return "unpaid";
    } else if (tx === 2) {
      return "paid";
    } else if (tx === 3) {
      return "payment-due";
    }
  }

  if (type === "paymentLinks") {
    if (tx === 2) {
      return "inactive";
    } else if (tx === 1) {
      return "active";
    }
  }
  if (type === "compliance") {
    if (tx === BIZ_VERIFICATION_STATUS.UNVERIFIED) {
      return "yettostart";
    } else if (tx === BIZ_VERIFICATION_STATUS.PENDING) {
      return "pending";
    } else if (tx === BIZ_VERIFICATION_STATUS.FAILED) {
      return "failed";
    } else if (tx === BIZ_VERIFICATION_STATUS.REVOKED) {
      return "revoked";
    } else if (tx === BIZ_VERIFICATION_STATUS.DECLINED) {
      return "declined";
    } else if (tx === BIZ_VERIFICATION_STATUS.SUCCESSFUL) {
      return "completed";
    } else {
      return "unknown";
    }
  } else if (tx === 0) {
    return "pending";
  } else if (tx === 1) {
    return "processing";
  } else if (tx === 2) {
    return "failed";
  } else if (tx === 3) {
    return "success";
  } else if (tx === 11) {
    return "reversed";
  } else return "unknown";
}

export const isEven = (num: number) => num % 2;

export const formatNumberToCurrency = (amount: number, identifier?: string) => {
  const currency = (identifier ?? "NGN").toUpperCase();

  const TEMP = "XXX";

  const selectedCurrency = ALL_CURRENCIES.find(({ label }) => label === currency);

  const newAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: TEMP,
    maximumFractionDigits: 2,
  }).format(amount);

  return newAmount
    .replaceAll(TEMP, selectedCurrency?.value ?? currency)
    .replace(/\s/g, "");
};

export const objectToFormData = (
  data: object,
  formData = new FormData(),
  parentKey = ""
): FormData => {
  Object.entries(data).forEach(([key, value]) => {
    const formKey = parentKey ? `${parentKey}[${key}]` : key;

    if (value instanceof Date) {
      formData.append(formKey, value.toISOString());
    } else if (Array.isArray(value)) {
      value.forEach((arrayValue, i) => {
        const arrayKey = `${formKey}[${i}]`;

        if (typeof arrayValue === "object" && arrayValue !== null) {
          if (arrayValue instanceof File) {
            formData.append(arrayKey, arrayValue, arrayValue.name);
          } else {
            objectToFormData(arrayValue, formData, arrayKey);
          }
        } else {
          formData.append(arrayKey, arrayValue.toString());
        }
      });
    } else if (typeof value === "object" && value !== null) {
      if (value instanceof File) {
        formData.append(formKey, value, value.name);
      } else {
        objectToFormData(value, formData, formKey);
      }
    } else if (value !== undefined && value !== null) {
      formData.append(formKey, value.toString());
    }
  });

  return formData;
};

export function downloadFile(url: any, filename: string, safe?: boolean) {
  console.log(filename);
  const a = document.createElement("a");

  a.href = url.url ?? url;
  if (safe) {
    a.target = "_blank";
  }
  a.download = filename || "download";
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      // eslint-disable-next-line no-restricted-globals
      removeEventListener("click", clickHandler);
    }, 150);
  };

  a.addEventListener("click", clickHandler, false);

  a.click();

  return a;
}

export const createDefaultReduxPaginationState = <Data>(data: Data) => {
  const defaultPaginationEmptyState: ResponseWithPagination<Data> = {
    total: 0,
    per_page: "20",
    offset: 0,
    to: 0,
    last_page: 0,
    current_page: "",
    from: 0,
    data,
    first_page_url_params: "",
    prev_page_url_params: "",
    more_page_url_params: "",
    next_page_url_params: "",
    last_page_url_params: "",
  };

  return defaultPaginationEmptyState;
};

export const assertPaginationPageNumber = (num?: unknown, fallback = 0) => {
  const number = Number(num);

  return isNaN(number) ? fallback : number;
};

export const filterAPIQueryParams = <
  T extends BasePaginationPayload | Record<string, unknown>
>(
  params: T
) => {
  type Keys = keyof T;
  const filteredParams: any = {};
  // keys that might have all in them
  const SpecialKeys = FILTER_KEYS_TO_EXCLUDE_ALL_FROM;

  const dateRelatedKeys = ["end_date", "start_date"];

  Object.keys(params).forEach((key) => {
    const value = params[key as Keys];
    if (value !== null && value !== undefined) {
      // key is not a special key that would include all
      if (!SpecialKeys.includes(key as any)) {
        filteredParams[key] = value;
        // else condition means its a special key, then we want to remove all from it
      } else if (value !== CUSTOM_FILTER_VALUE_FOR_ALL) {
        filteredParams[key] = value;
      }

      if (dateRelatedKeys.includes(key)) {
        filteredParams[key] = ((value as string) ?? "").replaceAll("/", "-");
      }
    }
  });

  return filteredParams;
};

export function getQueryVariable<Type = string>(variable: string): Type | undefined {
  //"app=article&act=news_content&aid=160990"
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  //[ 'app=article', 'act=news_content', 'aid=160990' ]
  for (let i = 0; i < vars?.length; i++) {
    //[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ]
    const pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1] as Type;
    }
  }
}

export class TypeIs {
  static string = (str: unknown): str is string => typeof str === "string";
  static any = (value: unknown) => value as any;

  /**
 *@example
 *```ts
console.log(Object.prototype.toString.call({}) = '[object Object]');
console.log(Object.prototype.toString.call([]) = '[object Array]');
console.log(Object.prototype.toString.call(null) = '[object Null]');
 *```
 * @see {@link https://bobbyhadz.com/blog/javascript-check-if-value-is-object}
 * 
 */
  static object = (value: unknown): value is object =>
    Object.prototype.toString.call(value) === "[object Object]";

  static array = (value: unknown) => Array.isArray(value);

  static number = (value: unknown): value is number => typeof value === "number";

  static reactNode = (node?: unknown): node is React.ReactNode =>
    isValidElement<any>(node);

  static stringOrNumber = (value: unknown): value is string | number =>
    this.string(value) || this.number(value);

  static custom<T>(value?: unknown) {
    return value as T;
  }

  static safe = <T>(value: IsUncertain<T>) => value as T;

  static email = (value: unknown) => Validator.email("Type").safeParse(value).success;
}

export const safeParse = <T>(data: string): T | undefined => {
  try {
    const res = JSON.parse(data) as T;
    return res;
  } catch (error) {
    return undefined;
  }
};

export const cleanOutObject = <T extends Record<SN, any>>(params: T) => {
  type Keys = keyof T;
  const filteredParams: any = {};

  Object.keys(params).forEach((key) => {
    const value = params[key as Keys];
    if (value) {
      filteredParams[key] = value;
    }
  });

  return filteredParams as T;
};

export function renderConditionally(label: any, data: any) {
  if (Boolean(data)) {
    return [{ label: label, value: data }];
  } else {
    return [];
  }
}

export const createInlineDetailsContent = (row: Record<SN, any>) => {
  const renderValue = (value: unknown) => {
    if (TypeIs.number(value) || TypeIs.string(value)) return Util.safeText(value);
    return TypeIs.custom<string>(value ?? "--");
  };

  const content = Object.entries(row).map(([key, value]) => ({
    label: formatWord(key),
    value: renderValue(value),
  }));

  return content;
};

interface GetPreviousRoute {
  state: unknown;
  key?: string;
  fallback?: string;
}

export const getPreviousRoute = (args: GetPreviousRoute): string => {
  const { fallback = "/personal-users", key = "previousRoute", state } = args;
  if (state && isObject(state) && key in state && TypeIs.string((state as any)[key]))
    return (state as any)[key];
  return fallback;
};

type AcceptableDate = string | number | Date | dayjs.Dayjs | null | undefined;
export const formatWithDayJs = (date: AcceptableDate, template = "DD/MM/YYYY") =>
  dayjs(date).format(template);

export const fileDownloadUtil = (fileUrl: string, filename?: string) => {
  const _assumedName = fileUrl.split("/");
  const fileName = filename ?? _assumedName[_assumedName.length - 1];

  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  window.location.replace(fileUrl);

};

export const createDialogText = (action: string) =>
  cn(
    "Are you sure you want to",
    action,
    "? You can always come back to perform the action."
  );
