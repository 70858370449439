import React, { useEffect, useState } from "react";
import { brands } from "../../../assets/icons";
import "./styles/index.css";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { useNavigate } from "react-router-dom";
import { useProductTheme } from "@/context/ProductTheme";
import { BugissApi } from "@/redux/slices";
import { useDispatch } from "react-redux";
import { Util } from "@/utils/utility";
import { getCookie, returnInitial } from "@/utils/helper/Helper";

const AuthWelcome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { setProductTheme } = useProductTheme();
  const [user, setUser] = useState<any>({});

  const handleLoginRedirect = (path: RavenProducts) => {
    setProductTheme(path);

    navigate(`/${path}-overview`);
  };

  async function getData() {
    var u = await Util.getUser();

    setUser(u);
  }

  useEffect(() => {
    setTimeout(() => {
      Util.checkUser(dispatch, true);
      getData();
    }, 300);
  }, [dispatch]);

  const { platforms } = user;

  const isEnabled = (app: string) => {
    if (platforms && platforms?.includes(app)) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (platforms?.length === 1) {
      handleLoginRedirect(platforms[0]?.split(" ")[0]);
    }
  }, [platforms]);

  return (
    <div className="auth-welcome d-flex f-column">
      <header>
        <div className="auth-welcome__header d-flex">
          <div className="auth-welcome__header--logo d-flex">
            <figure>{brands.raven}</figure>
            <h5>Bugisss</h5>
          </div>

          <div className="auth-welcome__header--profile">
            <figure>
              <h6>{returnInitial(Util.safeValue(user?.name))}</h6>
            </figure>
          </div>
        </div>
      </header>

      <section className="auth-welcome__body-contain">
        <div className="auth-welcome__body-contain--main">
          <div className="auth-welcome__body--welcome-text">
            <figure>
              <h3>👋</h3>
            </figure>

            <span>
              <h5>Hi {Util.safeValue(user?.name).split(" ")[0]},</h5>
              <p>Where would you like to login today?</p>
            </span>
          </div>

          <div className="auth-welcome__body--app-cards ">
            {isEnabled("personal") && (
              <div className="app-cards__item personal-banking">
                <div className="app-cards__item--top">
                  {/* <figure>{brands.raven_wordmark_white}</figure> */}
                  <h6>Personal Banking</h6>
                </div>

                <div className="app-cards__item--bottom">
                  <RavenButton
                    onClick={() => handleLoginRedirect("personal")}
                    color="white-light"
                    label="Continue to PB"
                  />
                </div>
              </div>
            )}

            {isEnabled("pos") && (
              <div className="app-cards__item pos">
                <div className="app-cards__item--top">
                  {/* <figure>{brands.raven_wordmark_orange}</figure> */}

                  <h6>POS • BankBox</h6>
                </div>

                <div className="app-cards__item--bottom">
                  <RavenButton
                    onClick={() => handleLoginRedirect("pos")}
                    color="white-light"
                    label="Continue to POS"
                  />
                </div>
              </div>
            )}
            {isEnabled("atlas") && (
              <div className="app-cards__item atlas">
                <div className="app-cards__item--top">
                  {/* <figure>{brands.raven_wordmark_blue}</figure> */}

                  <h6>Atlas</h6>
                </div>

                <div className="app-cards__item--bottom">
                  <RavenButton
                    onClick={() => handleLoginRedirect("atlas")}
                    color="white-light"
                    label="Continue to Atlas"
                  />
                </div>
              </div>
            )}

            {isEnabled("business") && (
              <div className="app-cards__item business-banking">
                <div className="app-cards__item--top">
                  {/* <figure>{brands.raven_wordmark_black}</figure> */}

                  <h6>Business Banking</h6>
                </div>

                <div className="app-cards__item--bottom">
                  <RavenButton
                    onClick={() => handleLoginRedirect("business")}
                    color="white-light"
                    label="Continue to BB"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AuthWelcome;
