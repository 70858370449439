import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow, RavenButton } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import ActionModal from "@/components/common/modal/ActionModal";
import { useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import "../../styles/index.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import {
  assertPaginationPageNumber,
  formatNumberToCurrency,
  getQueryVariable,
} from "@/utils/helpers";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import EmptyScreen from "@/components/common/emptyScreen";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { useAppDispatch } from "@/redux/store";
const BusinessPayrollEmployeeDetails = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [filterValue, setFilterValue] = useState<any>({});
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });
  const { employee_details, loading } = useSelector(
    (state: RootState) => state.businessPayroll
  );
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useAppDispatch();
  const biz = getQueryVariable("biz");
  const { id: employee } = useParams();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(
      BugissApi.business.getEmployeeDetails({
        business_email: biz,
        employees_email: employee,
        search_term: searchQuery,
        current_page: currentPage,
        ...filterValue,
      })
    );
  }, [biz, dispatch, employee, currentPage, searchQuery, filterValue]);

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const { salary_transactions } = employee_details;

  return (
    <>
      <DashboardLayout>
        <PageLayout
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "transparent", width: "100%", padding: 0 }}
          pageTitle="Employee Details"
        >
          <div className="bb-payroll-top-wrap">
            <div className="bb-payroll-top-wrap__main-content">
              <div className="bb-payroll-top-wrap__main-content--profile-img">
                {icons.bulk_transfer_illus}
              </div>

              <div className="bb-payroll-top-wrap__main-content--user-data">
                <div className="user-data__main-content">
                  <div className="main-content__col">
                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Employee's Fullname</small>
                        <p>{Util.safeValue(employee_details.account_name)}</p>
                      </div>
                    )}

                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Total Deductions</small>
                        <p>
                          {formatNumberToCurrency(
                            employee_details.total_employee_deductions,
                            "NGN"
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Employee’s Email Address</small>
                        <p>{Util.safeValue(employee_details.email)}</p>
                      </div>
                    )}
                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Overdrafts</small>
                        <p>
                          {formatNumberToCurrency(
                            employee_details.overall_overdraft_collected,
                            "NGN"
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  {/*  */}
                  <div className="main-content__col">
                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Total Salaries Paid</small>
                        <p>
                          {formatNumberToCurrency(
                            employee_details.total_salary_paid,
                            "NGN"
                          )}
                        </p>
                      </div>
                    )}

                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Bank</small>
                        <p>{Util.safeValue(employee_details.bank)}</p>
                      </div>
                    )}
                  </div>

                  <div className="main-content__col">
                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Date Joined</small>
                        <p>{formatDateTime(employee_details.created_at)}</p>
                      </div>
                    )}

                    {loading ? (
                      <Redacted.Labelled />
                    ) : (
                      <div className="main-content__col--item">
                        <small>Account Number</small>
                        <p>{Util.safeValue(employee_details.account_number)}</p>
                      </div>
                    )}
                  </div>
                </div>

                {/* <div className="bb-payroll-action-btn">
                  <div className="edit-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.bb_edit}</figure>
                      Edit Employee Details
                    </RavenButton>
                  </div>

                  <div className="del-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.delete}</figure>
                      Delete Employee
                    </RavenButton>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="bb-payroll-table">
            <SmartFilter
              onSearchChange={(e: string) => setSearchQuery(e)}
              pagination={{
                currentPage: currentPage,
                itemsPerPage: assertPaginationPageNumber(salary_transactions?.per_page),
                totalItems: assertPaginationPageNumber(salary_transactions?.total),
                onPageChange: function (page: number): void {
                  setCurrentPage(page);
                },
                activeFilter: "All Pending Deposits",
              }}
              onFilter={(e: any) => setFilterValue(Util.filterStruct(e))}
              searchTitle="Search pending deposits..."
            />

            {(!isRedacting && loading) || salary_transactions?.data?.length === 0 ? (
              <EmptyScreen loading={loading} />
            ) : (
              <div className={cn("bb-trx-table", isDarkMode && "pb-trx-table-dark")}>
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={[
                    "Salary",
                    "Deduction",
                    "Overdraft",
                    "Date Created",
                    "Payout Date",
                    "Status",
                  ]}
                >
                  {salary_transactions?.data?.map((data, index) => (
                    <RavenTableRow
                      key={index}
                      one={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data.net_amount ?? 0, "NGN")
                        )
                      }
                      two={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data.employer_dedcutions, "NGN")
                        )
                      }
                      three={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(data?.overdraft_deductions ?? 0, "NGN")
                        )
                      }
                      four={
                        loading ? <Redacted.Table /> : formatDateTime(data.created_at)
                      }
                      five={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatDateTime(data.date_of_payment)
                        )
                      }
                      six={loading ? <Redacted.Badge /> : formatStatus(data.status)}
                    />
                  ))}
                </RavenTable>
              </div>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPayrollEmployeeDetails;
