import SmartFilter from "@/components/common/smartFilter";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { transfersDummy } from "@/apps/partners/insights/tables/data/data";
import { formatDateTime, formatNumWithCommaNairaSymbol } from "@/utils/helper/Helper";

export const InsightsTransfersTable = () => {
  const transfers = transfersDummy.data;
  return (
    <>
      <SmartFilter hideFilter />
      <RavenTable
        headerList={[
          "DATE",
          "TOTAL TRANSFERS",
          "SUCCESSFUL",
          "FAILED",
          "PENDING",
          "PNL",
          "TRANSFER COST",
          " ",
        ]}
      >
        {transfers?.map((data, index) => (
          <RavenTableRow
            key={index}
            one={formatDateTime(data.date)}
            two={formatNumWithCommaNairaSymbol(data.total_transfers)}
            three={formatNumWithCommaNairaSymbol(data.successful)}
            four={formatNumWithCommaNairaSymbol(data.failed)}
            five={formatNumWithCommaNairaSymbol(data.pending)}
            six={formatNumWithCommaNairaSymbol(data.pnl)}
            seven={formatNumWithCommaNairaSymbol(data.transfer_cost)}
          />
        ))}
      </RavenTable>
    </>
  );
};
