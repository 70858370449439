import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PartnersSettlementApi = (builder: PartnersBuilder) => {
  return {
    getSettlements: createQueryWithRoute<
      PartnersSettlementsModel,
      Partial<PosPaginationParams & { status?: SN; email?: string }>
    >(builder, "partner/settlements", { name: "getSettlements" }),

    getSettlementsExport: createQueryWithRoute<
      PosSettlementsModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "partner/settlements", { export: true, name: "getSettlementsExport" }),
  };
};
