import "./styles/index.css";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";
import { OutletTab } from "@/components/common/OutletTab";
import { GENERAL_CLASSES, SPECIAL_ROUTES } from "@/constants";
import { TabElementDefaults, TabsElement } from "@/components/common/Tabs";
import { useNavigate } from "react-router-dom";
import Dropdown from "@/components/common/dropdown";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { icons } from "@/assets/icons";
import React, { useState } from "react";
import { ManuallyVerifyApproveBVN } from "./pages/modals/manuall-verify-approve-bvn";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { ManuallyApproveTokens } from "./components/modals";

export const PersonalCompliance = () => {
  const navigate = useNavigate();
  const [view, setView] = useState<string>();
  const { renderDynamicModal: renderView } = useDynamicModal(Boolean(view));
  const [acceptModal, setAccept] = useState<
    { token?: string; email?: string; type: string; image_url?: string } | undefined
  >();
  const { renderDynamicModal: renderAcceptModal } = useDynamicModal(Boolean(acceptModal));

  const Tabs: TabsElement[] = [
    {
      label: "BVN Verification",
      value: "bvn-verification",
      // isParent: true,
      dropdown: {
        onChange: (e: { event: Event; label: string; value: string }) =>
          navigate(`${e.value}`),
        tabs: [
          {
            label: "Verified BVN",
            value: "bvn-verification",
          },
          {
            label: "Unverified BVN",
            value: "manual-bvn-verification",
          },
        ],
      },
    },

    {
      label: "NIN Verification",
      value: "nin-verification",
      // isParent: true,
      dropdown: {
        onChange: (e: { event: Event; label: string; value: string }) =>
          navigate(`${e.value}`),
        tabs: [
          {
            label: "Verified NIN",
            value: "nin-verification",
          },
          {
            label: "Unverified NIN",
            value: "manual-nin-verification",
          },
        ],
      },
    },
    "Manual Address Verification",
    "Manual ID Verification",
    "Limit Requests",
    { label: "Details Checker", value: SPECIAL_ROUTES.DETAILS_CHECKER },
  ];

  const handleVerification = (data: any) => {
    if (data?.email && data?.token) {
      setAccept(data);
    }
    return setView(undefined);
  };
  return (
    <DashboardLayout>
      <style>
        {`.zedindex {
        z-index:500000}`}
      </style>
      <PageLayout
        topRightContent={
          <Dropdown
            buttonChild={() => (
              <RavenButton color={"purple-light"}>
                Verify Identity
                {React.cloneElement(icons.chevron_down_white, { color: "white" })}
              </RavenButton>
            )}
            items={[
              { label: "NIN Verification", value: "nin" },
              { label: "BVN Verification", value: "bvn" },
            ]}
            className="zedindex"
            onChange={(e) => {
              console.log("implementation", e);
              setView(e.value);
            }}
          />
        }
        pageTitle="Compliance"
      >
        <OutletTab>
          <OutletTab.Tabs
            className={GENERAL_CLASSES.OUTLET}
            baseURL="/personal-compliance"
            tabs={Tabs}
            specialUrls={[SPECIAL_ROUTES.DETAILS_CHECKER]}
          />
          <OutletTab.Outlet />
        </OutletTab>
      </PageLayout>
      {view &&
        renderView(
          <ManuallyVerifyApproveBVN
            view={view}
            setView={setView}
            handleVerification={handleVerification}
            tab={view}
          />
        )}

      {acceptModal &&
        renderAcceptModal(
          <ManuallyApproveTokens
            refetch={() => {}}
            onCancel={() => setAccept(undefined)}
            image_url={acceptModal.image_url as string}
            id=""
            token={acceptModal?.token ?? ""}
            email={acceptModal?.email ?? ""}
            type={acceptModal?.type as "bvn"}
          />
        )}
    </DashboardLayout>
  );
};
