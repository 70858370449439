import { PartnersBuilder } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PosBillsApi = (builder: PartnersBuilder) => {
  const apis = {
    getAirtimeRecords: createQueryWithRoute<
    PartnersBillAirtime,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-airtime", { name: "getAirtimeRecords" }),

    getAirtimeRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-airtime", { export: true, name: "getAirtimeRecordsExport" }),

    getDataRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-data", { name: "getDataRecords" }),

    getDataRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-data", { export: true, name: "getDataRecordsExport" }),

    getCableRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-cable", { name: "getCableRecords" }),

    getCableRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-cable", { export: true, name: "getCableRecordsExport" }),

    getElectricityRecords: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-electricity", { name: "getElectricityRecords" }),

    getElectricityRecordsExport: createQueryWithRoute<
      PosBillAirtimeModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-electricity", { export: true, name: "getElectricityRecordsExport" }),

    getBettingRecords: createQueryWithRoute<
      PosBillBetModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-bet", { name: "getBettingRecords" }),

    getBettingRecordsExport: createQueryWithRoute<
      PosBillBetModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "all-bet", { export: true, name: "getBettingRecordsExport" }),
  };

  return apis;
};
