import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import env from "@/env";
import { sendRequest } from "@/utils/api-methods";
import {
  IconVault,
  formatDateTime,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import {
  RavenNumberFormat,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import usePartnersGetQuery from "@/apps/partners/__internals__/hooks/usePartnersGetQuery";
import { formatNumberToCurrency } from "@/utils/helpers";

const PartnersTerminalsTable = () => {
  const [modal, setModal] = useState<any>("");
  const [isReqn, setIsReqn] = useState(false);

  const { tableData, SmartFilterProps, isRedacting, refetch, combinedLoading } =
    usePartnersGetQuery<PartnersTerminals>("getTerminals");

  const merchants = tableData;

  const navigate = useNavigate();

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "terminal/de_re_activate",
      {
        tid: modal.tid,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }
  return (
    <>
      <SmartFilter {...SmartFilterProps} searchTitle={"Search terminals..."} />

      {isEmpty(merchants) ? (
        <EmptyScreen loading={combinedLoading || isRedacting} />
      ) : (
        <RavenTable
          headerList={[
            "MERCHANT DETAILS",
            "TERMINAL DETAILS",
            "TRANSACTION",
            "DATE IMBURSED",
            "STATUS",
            " ",
          ]}
        >
          {merchants?.map((data, idx) => (
            <RavenTableRow
              onRowClick={() => {
                navigate(`${data.tid}`);
              }}
              one={
                isRedacting ? (
                  <Redacted.AvatarWithValue />
                ) : (
                  <TwinValue
                    one={trimLongString(Util.safeText(data?.merchant_name), 20)}
                    two={`Partner: ${Util.safeText(data?.partner)}`}
                    copy
                  />
                )
              }
              two={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  <TwinValue
                    one={`Label: ${Util.safeText(data?.tid_label)}`}
                    two={`TID:${Util.safeText(data?.tid)}`}
                  />
                )
              }
              three={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  <TwinValue
                    one={`${formatNumberToCurrency(data?.total_transaction_volume)}`}
                    two={`${Util.safeText(RavenNumberFormat(data?.transaction_count, {hideSymbol:true, hideDecimal:true}))} Transactions`}
                  />
                )
              }
              four={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  formatDateTime(data?.created_at)
                )
              }
              five={
                isRedacting ? (
                  <Redacted.Table />
                ) : (
                  formatStatus(
                    Boolean(data?.status) ? "active" : "deactivated"
                  )             )
              }
        
           
            />
          ))}
        </RavenTable>
      )}

      <ActionModal
        visible={Boolean(modal)}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleActivateDeactivate();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${modal?.label}`}
        btnColor={"red"}
        body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
        title={`${modal?.label} Terminal?`}
      ></ActionModal>
    </>
  );
};

export const PartnersTerminals = () => {
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Terminals">
        <PartnersTerminalsTable />
      </PageLayout>
    </DashboardLayout>
  );
};
