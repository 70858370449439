import { cn } from "@/utils/colorConvert";
import { HTMLAttributes } from "react";
import "./styles/index.css";
import { createStyleCSSJustifyProperty, createStyleCSSProperty } from "@/utils/helpers";
import Image from "../image/Image";
import { Util } from "@/utils/utility";

interface StackProps extends HTMLAttributes<HTMLDivElement> {
  /** Defaults to 2.4rem, and if number is passed, it coerced to rem */
  gap?: SN;
  jc?: React.CSSProperties["justifyContent"];
}

function createStackGap(gap: SN) {
  return createStyleCSSProperty("--stack-gap", gap);
}

function createStackJustify(jc: StackProps['jc']) {
  return createStyleCSSJustifyProperty("--stack-justify", jc);
}


export const YStack = (props: StackProps) => {
  const { children, className, gap = "2.4rem", jc="flex", style, ...rest } = props;

  return (
    <div
      style={{ ...createStackGap(gap), ...createStackJustify(jc), ...style }}
      className={cn("ystack", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

interface XStackProps extends StackProps {
  ai?: React.CSSProperties["alignItems"];
}
export const XStack = (props: XStackProps) => {
  const { children, className, gap = "2.4rem", style, jc, ai, ...rest } = props;

  return (
    <div
      style={{ ...createStackGap(gap), ...createStackJustify(jc), alignItems: ai, ...style }}
      className={cn("xstack", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

export { XStack as Row, YStack as Column };

interface BillerProps {
  biller: string | JSX.Element;
  provider: string;
}

export const BillerStack = ({ biller, provider }: BillerProps) => (
  <XStack style={{ alignItems: "center", zIndex: 0 }} className="biller-row">
    <figure className="bills-logo">
      {typeof biller === "string" ? <Image src={biller} alt="" /> : biller}
    </figure>
    <p>{Util.safeValue(provider)}</p>
  </XStack>
);

interface ControlledTableContentProps extends IProps {
  maxWidth?: SN;
}

export const ControlledTableContent = (props: ControlledTableContentProps) => {
  const { maxWidth, children, className } = props;
  return (
    <div
      style={
        maxWidth ? { ...createStyleCSSProperty("--cc-max-width", maxWidth) } : undefined
      }
      className={cn("controlled-table-content", className)}
    >
      {children}
    </div>
  );
};
