/* eslint-disable jsx-a11y/anchor-is-valid */
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { icons } from "@/assets/icons";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { usePagination } from "@/hooks/usePagination";
import {
  mapTransfersPagination,
  usePaginationRefresh,
  useSmartFilterProps,
} from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatWord } from "@/utils/formatWord";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import React, { useState } from "react";
import {
  RejectManualAddressVerification,
  AcceptManualAddressVerification, AcceptManualNinverification, RejectManualNinverification
} from "../components/modals";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { ApproveManualAddress } from "./modals/approve-manual-address";
import { GLOBAL_PER_PAGE } from "@/constants";
import { ControlledTableContent } from "@/components/common/stacks";
import styled from "styled-components";
import { ApproveManualNin } from "~pb/compliance/pages/modals/approve-manual-nin";
import PersonalApi from "@/utils/axios/personal";

interface ComplianceNINOrBVNProps {
  tab: string;
}

export const ManualNinVerification = (props: ComplianceNINOrBVNProps) => {
  const { tab } = props;
  const [view, setView] = useState<ManualCompliance>();
  const [acceptModal, setAccept] = useState<SN>();
  const [revokeModal, showRevokeModal] = useState<SN>();
  const { renderDynamicModal } = useDynamicModal(Boolean(revokeModal));
  const { renderDynamicModal: renderView } = useDynamicModal(Boolean(view));
  const { renderDynamicModal: renderAcceptModal } = useDynamicModal(Boolean(acceptModal));


  const pagination = usePagination();
  const { data, isLoading, isFetching, refetch } =
    personalReduxAPI.useGetManualComplianceReviewQuery({
      page: pagination.currentPage,
      per_page: GLOBAL_PER_PAGE,
      email: pagination.searchQuery,
      type: tab,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const joinedLoader = isLoading || isFetching;

  const { RedactedTable, RedactedLabel } = renderRedactedComponent(joinedLoader);

  usePaginationRefresh(pagination, [tab]);

  if (isLoading || !data) {
    return (
      <div className="mt-20">
        <TransferEmptyScreen height="80vh" loading={joinedLoader} />
      </div>
    );
  }

  const handleVerification = () => {
    if (view?.status === "pending") {
      setAccept(view.id);
      setView(undefined);
    }
    return setView(undefined);
  };

  const PurpleBadge = styled.div`
      display: flex;
      height: 2.8rem;
      padding: 0.8rem;
      width: max-content;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      border-radius: 6px;
      border: 0.08rem solid  #5D5FEF;
      background: #FAFAFF;

      color: #755AE2;
      text-align: center;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
  `


  return (
    <>
      <SmartFilter searchTitle="Search by email" {...SmartFilterProps} />

      {data.reviews?.length > 0 ? (
        <div className="table">
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              // "Full Name",
              "Email Address",
              "NIN",
              "Date Logged",
              "Action",
            ]}
          >
            {data.reviews.map((row) => {
              const { id } = row;

              return (
                <RavenTableRow
                  key={id}
                  onRowClick={() => setView(row)}
                  {...{
                    one: RedactedTable(<ClickAbleEmail email={row.email} />),
                    two: RedactedTable(<ClickAbleEmail email={row.token} />),
                    three: RedactedLabel(formatDateTime(row.created_at)),
                    four: <PurpleBadge >
                      Verify Manually
                      {React.cloneElement(icons.arrow_forward_circular_purple, {style: {size: '15px'}} )}
                    </PurpleBadge>
                  }}
                />
              );
            })}
          </RavenTable>
        </div>
      ) : (
        <TransferEmptyScreen height="100%" loading={joinedLoader} />
      )}

      {revokeModal &&
        renderDynamicModal(
          <RejectManualNinverification
            id={revokeModal}
            refetch={refetch}
            type={'nin'}
            onCancel={() => showRevokeModal(undefined)}
          />
        )}
      {acceptModal &&
        renderAcceptModal(
          <AcceptManualNinverification
            id={acceptModal}
            refetch={refetch}
            onCancel={() => setAccept(undefined)}
          />
        )}

      {view &&
        renderView(
          <ApproveManualNin
            view={view}
            setView={setView}
            handleVerification={handleVerification}
            showRevokeModal={showRevokeModal}
            tab={tab}
          />
        )}
    </>
  );
};
