import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import usePosGetQuery from "../__internals__/hooks/usePosGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";

const PartnersFeeDetails = () => {
  const { isDarkMode } = useThemeMode();
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosFees>("getFees");

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Fee Details">
        <SmartFilter {...SmartFilterProps} searchTitle="Search fees..." hideFilter />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {isEmpty(tableData) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "PARTNER",
                "FEE CATEGORY",
                "FEE RATE",
                "TRF FEE",
                "COLLECTION FEE",
                "MANDATORY FEE",
                "DATE CREATED",
              ]}
            >
              {Util.safeArray(tableData).map((data, idx) => (
                <RavenTableRow
                  key={idx}
                  one={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      Util.cleanText(Util.safeValue(data?.reference))
                    )
                  }
                  two={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(data?.fee), "NGN")
                    )
                  }
                  three={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(data?.cap), "NGN")
                    )
                  }
                  four={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(data?.bank_collection_fee), "NGN")
                    )
                  }
                  five={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(data?.transfer_fee), "NGN")
                    )
                  }
                  six={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(data?.collection_amount), "NGN")
                    )
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PartnersFeeDetails;
