import EmptyScreen from "@/components/common/emptyScreen";
import SmartFilter from "@/components/common/smartFilter";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import React, { useEffect, useState } from "react";
import "../../styles/index.css";

import { useDispatch, useSelector } from "react-redux";
import { fetchBizTransactions } from "@/redux/switches/business/transaction.sw";
import { toggleRedact } from "@/redux/actions/actions";
import { Util } from "@/utils/utility";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { $event } from "@/utils/events/eventEmitter";
import { useParams, useSearchParams } from "react-router-dom";
import { detectSingleBizValuePair } from "@/apps/business/businesses/__exports__/value_pairs";
import BusinessUsersTables from "../../tables/BusinessTables";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { detectStatus, renderConditionally } from "@/utils/helpers";
import { BIZ_TRANSACTION_STATUS } from "@/apps/business/__internals__/constants";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { cn } from "@/utils/colorConvert";
import { useProductColor } from "@/context/ProductTheme";
import Gap from "@/components/common/styleComponents/Gap";
import { sendRequest } from "@/utils/api-methods";
import Dropdown from "@/components/common/dropdown";
import Redacted from "@/components/common/shimmer/redact";

const SingleBusinessTeams = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { email } = useParams();
  let [searchParams] = useSearchParams();
  const bizId = searchParams.get("biz-id") ?? undefined;
  const [searchQuery, setSearchQuery] = useState("");
  const [filterValue, setFilterValue] = useState<any>({});

  const header = ["FULLNAME", "EMAIL ADDRESS", "ROLE", "DATE ADDED", "2FA STATUS", ""];
  type TeamMembers = BusinessTeams['users']['data'][0]

  // declare const

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const { data, isFetching, isLoading, refetch } =
    businessReduxAPI.useFetchBusinessTeamsQuery({
      business_id: bizId,
      current_page: currentPage,
    });

  const [show2fa, setShow2fa] = useState<any>("");
  const loading = isFetching || isLoading;

  const teamMembers = data?.users?.data;
  const pagin = data?.users;
  const biz = data?.business;

  const { colorToken, colorVariable } = useProductColor();

  const dropdown = () => {
    return [
      // { label: "Unlien Account", img: IconVault(icons.info_circle) },

      {
        label: "Disable 2FA",
        value: "disable_2fa",
        // img: IconVault(icons.check_circle_green),
      },
    ] as const;
  };

  const handleDisableUser = async () => {
    setShow2fa((prev: any) => {
      return {
        ...prev,
        loading: true,
      };
    });
    const req = await sendRequest("two-factor-auth/disable-two-factor-authentication", {
      user_id: String(show2fa?.user_id),
    });

    if (req?.status === "success") {
      setShow2fa({ validateValue: "" });
      refetch();
      toast.success("Two Factor Authentication Disabled Successfully");
    } else {
      // toast.error("Something Went Wrong!");
      // setShow2fa((prev: any) => {
      //   return {
      //     ...prev,
      //     loading: false,
      //     validateValue: "",
      //   };
      // });

      setShow2fa({ validateValue: "" });
    }
  };


  return (
    <div className="business-trx-wrap">
      {/* <h6>Transactions</h6> */}

      <>
        {/* <div className="type-title">
          <h6>{capitalizeFirstLetter(type.label)}</h6>
        </div> */}
        <SmartFilter
          page="insights"
          onExport={() => {
            // toggleModal(`Export Transactions`);
          }}
          hideExport
          hideFilter
          searchTitle="Search transactions..."
          onSearchChange={(e: string) => {
            setSearchQuery(e);
          }}
          onFilter={(e: any) => {
            setFilterValue(Util.filterStruct(e));
          }}
          // onExport={toggleModal}
          singleSelect
          pagination={{
            currentPage: currentPage,
            itemsPerPage: Number(pagin?.per_page),
            totalItems: Number(pagin?.total),
            onPageChange: function (page: number): void {
              setCurrentPage(page);
            },
          }}
        />

        {(!isRedacting && loading) || teamMembers?.length === 0 || !teamMembers ? (
          <EmptyScreen loading={loading} />
        ) : (
          <RavenTable action={false} className="table__main" headerList={header}>
            {teamMembers?.map((chi: TeamMembers, idx: React.Key | null | undefined) => (
              <RavenTableRow
                key={idx}
                one={
                  isRedacting ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    <AvatarWithTwinValue
                      name={Util.join(chi?.fname).with(chi?.lname)}
                      full
                      one=""
                      two=""
                    />
                  )
                }
                two={isRedacting ? <Redacted.Table /> : Util.safeText(chi?.user_email)}
                three={isRedacting ? <Redacted.Table /> : returnRole(chi?.clearance)}
                four={isRedacting ? <Redacted.Table /> : formatDateTime(chi?.created_at)}
                five={
                  isRedacting ? (
                    <Redacted.Badge />
                  ) : (
                    formatStatus(chi?.two_factor === 0 ? "inactive" : "active")
                  )
                }
                six={
                  isRedacting ? (
                    <Redacted.Avatar />
                  ) : (
                    <Dropdown
                      items={dropdown() as any}
                      defaultValue={dropdown()[0]}
                      onChange={(e) => {
                        setShow2fa(chi);
                      }}
                      className="ragna-dropdown"
                      buttonChild={() => <ActionDrop more />}
                    />
                  )
                }
              />
            ))}
          </RavenTable>
        )}
      </>

      <ActionModal
        title={"Disable 2FA"}
        visible={Boolean(show2fa?.user_id)}
        onCancel={function (): void {
          setShow2fa({ validateValue: "" });
        }}
        hideCancel
        loading={show2fa?.loading}
        btnColor="#FF0F00"
        disableButton={show2fa?.validateValue !== biz?.business_name?.toUpperCase()}
        actionText="Disable 2FA"
        onClick={function (): void {
          handleDisableUser();
        }}
      >
        <p>
          Fill in the name of the business to complete the disable of 2 factor
          authentication
        </p>

        <Gap size={20} />

        <div className="biz_name">
          <p>{biz?.business_name?.toUpperCase()}</p>
        </div>

        <Gap size={20} />

        <RavenInputField
          color={colorVariable}
          value={show2fa?.validateValue}
          placeholder="Enter business name as displayed above"
          label="Business Name"
          onChange={(e: { target: { value: any } }) => {
            setShow2fa((prev: any) => {
              return {
                ...prev,
                validateValue: e.target.value,
              };
            });
          }}
        ></RavenInputField>
      </ActionModal>
    </div>
  );
};

export default SingleBusinessTeams;

const returnRole = (s: string) => {
  return (
    <div className={cn("bugiss-status", "deposit__status--processing")}>
      <p>{s}</p>
    </div>
  );
};
