import { PersonalCompliance } from "@/apps/personal/compliance";
import {
  PersonalAddressCompliancePage,
  PersonalBVNCompliancePage,
  PersonalIdCompliancePage,
  PersonalLimitRequestCompliancePage, PersonalManualBVN, PersonalManualNin,
  PersonalNINCompliancePage
} from "@/apps/personal/compliance/pages";
import { ComplianceDetailsPage } from "@/apps/personal/compliance/pages/details-checker";
import {
  BVNDetailsChecker,
  DriversLicenseDetailsChecker,
  MeterNumberDetailsChecker,
  NINDetailsChecker,
  PassportDetailsChecker,
  VotersCardDetailsChecker,
} from "@/apps/personal/compliance/pages/details-checker/pages";
import { CableInfoDetailsChecker } from "@/apps/personal/compliance/pages/details-checker/pages/cable-info";

export const personal_compliance_rotes: RouteArray[] = [
  {
    path: "/personal-compliance",
    element: PersonalCompliance,
    children: [
      { element: PersonalBVNCompliancePage, path: "bvn-verification" },
      { element: PersonalNINCompliancePage, path: "nin-verification" },
      { element: PersonalManualNin, path: "manual-nin-verification" },
      { element: PersonalManualBVN, path: "manual-bvn-verification" },
      { element: PersonalAddressCompliancePage, path: "manual-address-verification" },
      { element: PersonalIdCompliancePage, path: "manual-id-verification" },
      { element: PersonalLimitRequestCompliancePage, path: "limit-requests" },
      {
        element: ComplianceDetailsPage,
        path: "details-checker",
        children: [
          { element: NINDetailsChecker, path: "" },
          { element: NINDetailsChecker, path: "nin" },
          { element: BVNDetailsChecker, path: "bvn" },
          { element: PassportDetailsChecker, path: "passport" },
          { element: DriversLicenseDetailsChecker, path: "drivers-license" },
          { element: VotersCardDetailsChecker, path: "voters-card" },
          { element: CableInfoDetailsChecker, path: "cable-info" },
          { element: MeterNumberDetailsChecker, path: "meter-info" },
        ],
      },
    ],
  },
];
