import React, { useEffect, useState } from "react";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "../../../components/common/avatar";
import { IconVault, formatStatus, mapHotkey } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";
import TwinValue from "@/components/common/twinValue";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "./styles/index.css";
import InlineDetails from "@/components/common/inlineDetails";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import SmartFilter from "@/components/common/smartFilter";
import EmptyScreen from "@/components/common/emptyScreen";
import { getThemePrimaryColor } from "@/utils/helpers";

const Tabs = [
  "NIN Verification",
  "BVN Verification",
  "Address Verification",
  "CAC Verification",
  "Limit Request",
];

const generateMockData = (tab: string) => {
  const mockData = [];

  if (!tab.includes("Address")) {
    mockData.push(
      {
        email: "tim.jennings@example.com",
        phone: "+234 9077 3437",
        bvn: "24348938934",
        initiator: "Dayo Aderamo",
        date: "Today • 12:48pm",
      },
      {
        email: "john.doe@example.com",
        phone: "+234 9077 3437",
        bvn: "24348938934",
        initiator: "Admin Jope",
        date: "Yesterday • 10:30am",
      }
    );
  } else {
    mockData.push(
      {
        username: "@3mmi4sure",
        email: "emmy4sure.web@gmail.com",
        amount: "₦1,325,000.00",
        address: "123 Main St Yaba",
        city: "Shomolu",
        type: "Deposit",
        date: "Today • 12:48pm",
        status: "pending",
      },
      {
        username: "@cryptoKing",
        email: "crypto.king@example.com",
        amount: "₦2,500,000.00",
        type: "Withdrawal",
        address: "123 Main St Yaba",
        city: "Shomolu",
        date: "Yesterday • 3:15pm",
        status: "successful",
      }
      // Add more mock data as needed
    );
  }

  return mockData;
};

const PartnersCompliance = () => {
  const [empty, setEmpty] = useState(false);
  const [revokeModal, showRevokeModal] = useState({ chi: "", on: false });
  const [verify, showVerifyModal] = useState({ chi: "", on: false });
  const [view, setView] = useState({ chi: "", on: false });

  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<string>("NIN Verification");

  const tableHeader =
    selectedTab !== "Address Verification"
      ? ["Full Name", "Email Address", "Mobile Number", "BVN", "Date Logged", "Action"]
      : ["User Details", "State", "Full Address", "Date", "Status", " "];

  const mockData = generateMockData(selectedTab);

  const dropdown = [
    {
      label: `Verify ${selectedTab.split(" ")[0]}`,
      img: IconVault(icons.info_circle),
      func: showVerifyModal,
    },
    {
      label: `Revoke ${selectedTab.split(" ")[0]}`,
      img: IconVault(icons.block_circle),
      func: showRevokeModal,
    },
  ] as const;

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Compliance">
          <div className="personal-savings__top-wrap">
            <TabComponent onValueChange={(e) => setSelectedTab(e)} defaultValue={Tabs[0]}>
              <TabComponent.Tabs tabs={Tabs} />
              {Tabs.map((tab) => (
                <TabComponent.Content key={tab} value={tab}></TabComponent.Content>
              ))}
            </TabComponent>
          </div>
          {!empty ? (
            <>
              <SmartFilter
                page="insights"
                simple
                searchTitle={`Search ${selectedTab} by ${
                  selectedTab === "Blocked User" ? "username" : "user details"
                }...`}
                pagination={{
                  currentPage: 0,
                  itemsPerPage: 0,
                  totalItems: 0,
                  onPageChange: function (page: number): void {
                    throw new Error("Function not implemented.");
                  },
                  activeFilter: `All ${selectedTab}`,
                }}
              />

              <div className="table">
                <RavenTable
                  action={false}
                  className="table__main"
                  headerList={tableHeader}
                >
                  {mockData.map((data, index) => (
                    <RavenTableRow
                      key={index}
                      onRowClick={() => setView({ on: true, chi: "" })}
                      {...(selectedTab.includes("Address")
                        ? {
                            one: (
                              <TwinValue one={data.username as string} two={data.email} />
                            ),
                            two: (
                              <TwinValue
                                one={data.address as string}
                                two={data.city as string}
                              />
                            ),
                            three: data.type,
                            four: data.date,
                            five: <div>{formatStatus(data.status as string)}</div>,
                            six: (
                              <div>
                                <Dropdown
                                  rightPosition
                                  items={dropdown}
                                  defaultValue={dropdown[0]}
                                  onChange={(e: any) => {
                                    e.event.stopPropagation();
                                    e.func({ chi: "", on: true });
                                  }}
                                  className="ragna-dropdown"
                                  buttonChild={({ selected }) => <ActionDrop more />}
                                />
                              </div>
                            ),
                          }
                        : {
                            one: (
                              <div className="table-icon-row">
                                <Avatar
                                  name={(data.initiator as string) ?? " - -"}
                                  full
                                />
                              </div>
                            ),

                            two: data.phone,
                            three: data.email,
                            four: data.bvn,
                            five: data.date,
                            six: (
                              <div>
                                <Dropdown
                                  rightPosition
                                  items={dropdown}
                                  defaultValue={dropdown[0]}
                                  onChange={(e: any) => {
                                    e.event.stopPropagation();

                                    e.func({ chi: "", on: true });
                                  }}
                                  className="ragna-dropdown"
                                  buttonChild={() => <ActionDrop more />}
                                />
                              </div>
                            ),
                          })}
                    />
                  ))}
                </RavenTable>
              </div>
            </>
          ) : (
            <EmptyScreen />
          )}
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          showRevokeModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${selectedTab.split(" ")[0]}?`}
      />

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor={getThemePrimaryColor()}
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Verify ${selectedTab.split(" ")[0]}?`}
      />

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ chi: "", on: false });
        }}
        big={true}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor={getThemePrimaryColor()}
        hideCancel
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`${selectedTab.split(" ")[0]} Details`}
      >
        {
          <div className="compliance-results">
            <div className="compliance-results__image-preview">
              <div className="image-preview__pagination">
                <figure className="disabled">{icons.arrow_back_circular_inactive}</figure>

                <span>
                  <p>1 of 3</p>
                </span>
                <figure>{icons.arrow_forward_circular}</figure>
              </div>
            </div>

            <div className="compliance-results__main">
              <InlineDetails
                direction={""}
                title={"Transaction Details"}
                simple
                amount={0}
                onClick={() => {}}
                content={[
                  {
                    label: selectedTab.split(" ")[0],
                    value: "4343490232",
                    copy: true,
                  },
                  {
                    label: "Fullname",
                    value: "Emmanuel Chinonso",
                  },

                  {
                    label: "Mobile Number",
                    value: "6859438374576",
                  },

                  {
                    label: "Address",
                    value: "10, Akinboye Drive, Orchid",
                  },

                  {
                    label: "Date of Birth",
                    value: "2nd June 2003",
                  },

                  {
                    label: "Date Authorized",
                    value: "5 Sept, 2022 - 5:48PM",
                  },
                ]}
              />
            </div>
          </div>
        }
      </ActionModal>
    </>
  );
};

export default PartnersCompliance;
