import { useState, useEffect, ReactNode } from "react";
import "./style/index.css";
import { icons, illustrations } from "../../../assets/icons";
import mascot from "../../../assets/images/mascot.png";
import mascotDark from "../../../assets/images/mascot-dark.png";
import { IconVault } from "../../../utils/helper/Helper";
import Loader from "../loader/index";
import { useDarkMode } from "../../../hooks/useDarkMode";
import BBMascot from "@/assets/images/mascot-bb.png";
import AtlasMascot from "@/assets/images/atlast-mascot.png";
import { useProductTheme } from "@/context/ProductTheme";
import { LOADING_WORDS } from "@/constants/loading-words";
import { Button } from "../overrides/button";

export interface EmptyProps {
  withMascot?: boolean;
  button?: boolean;
  buttonLabel?: string;
  btnIcon?: any;
  title?: string;
  subTitle?: string;
  isBankbox?: boolean;
  loading?: boolean;
  onClick?: (event?: Event) => any;
  loadingText?: string;
  mascot?: ReactNode;
  noMascot?: boolean;
  noBorder?: boolean;
}

function EmptyScreen(props: EmptyProps) {
  const [theme] = useDarkMode(localStorage.getItem("theme"));
  const { productTheme } = useProductTheme();

  const renderMascot = () => {
    const isDarkMode = theme === "dark";

    switch (productTheme) {
      case "atlas":
        return AtlasMascot;
      case "personal":
      case "pos":
        return isDarkMode ? mascotDark : mascot;
      case "business":
        return BBMascot;
      case "partners":
        return BBMascot;
    }
  };

  const CTAButton = () => (
    <Button onClick={props.onClick} label={props.buttonLabel ?? "Help"} />
  );

  return (
    <>
      {!props.isBankbox ? (
        <>
          {props.noMascot ? (
            <div
              style={{
                backgroundImage:
                  theme === "dark"
                    ? `url("${IconVault(illustrations.no_graph_dark)}")`
                    : "",
              }}
              className="no-record"
            >
              {props.loading ? (
                <Loader loadingText={props?.loadingText} />
              ) : (
                <>
                  <div className="no-record__text">
                    <h5>{props.title ?? "No Data at the moment"}</h5>
                    <p>
                      {props.subTitle ??
                        "Transaction data chart show here once transfers and collections are been made."}
                    </p>
                    {props.button && (
                      <div className="mt-20">
                        <CTAButton />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              style={{ border: props.noBorder ? "none" : "" }}
              className="no-record-mascot"
            >
              {props.loading ? (
                <Loader loadingText={props?.loadingText ?? <RandomWords />} />
              ) : (
                <>
                  <div
                    style={{
                      backgroundImage: `url("${IconVault(icons.bg_lines)}")`,
                    }}
                    className="no-record-mascot__top"
                  >
                    {/* <figure>{icons.bg_lines}</figure> */}
                  </div>

                  <div className="no-record-mascot__bottom">
                    <h5>{props.title ?? "Nothing to see here for now..."}</h5>
                    <p>
                      {props.subTitle ??
                        "New records created on the platform get to show up here. There are none for now"}
                    </p>

                    {props.button && <CTAButton />}
                  </div>
                  <div className="mascot">
                    {props.mascot || (
                      <figure>
                        <img src={renderMascot()} alt="" />
                      </figure>
                    )}
                  </div>
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default EmptyScreen;
const RandomWords = () => {
  const [currentWord, setCurrentWord] = useState<string>(
    LOADING_WORDS[Math.floor(Math.random() * LOADING_WORDS?.length)]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const newIndex = Math.floor(Math.random() * LOADING_WORDS?.length);
      setCurrentWord(LOADING_WORDS[newIndex]);
    }, 2000);

    // Clean up interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div>{currentWord}</div>;
};
