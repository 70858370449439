import { RavenInputField, RavenModal } from "@ravenpay/raven-bank-ui";
import React, { useEffect } from "react";
import { icons } from "../../../assets/icons";
import "./style/index.css";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  capitalizeFirstLetter,
  formatDate,
  trimLongString,
} from "../../../utils/helper/Helper";
import { logger } from "../../../services/logger";
import { BugissApi } from "@/redux/slices";
import { useProductColor } from "@/context/ProductTheme";
import { formatNumberToCurrency } from "@/utils/helpers";
import { cn } from "@/utils/colorConvert";
import Gap from "../styleComponents/Gap";

const days = ["All", "Today", "Last 7 Days", "Last 14 Days", "Last 30 Days", "Custom"];

const ExportModal = ({
  visible,
  onClose,
  onClick,
  title,
  business_id,
  onChange,
  value,
  page = "wallet",
}: {
  visible: boolean;
  onClose?: () => void;
  onClick?: () => void;
  page?: string;
  title?: string;
  business_id?: string;
  loading?: boolean;
  value?: {
    business_account_number?: string;
    date:
      | string
      | undefined
      | {
          start_date?: string;
          end_date?: string;
        };
    fileType: string;
  };
  onChange?: any;
}) => {
  const [selectedDay, setSelectedDay] = React.useState<
    | string
    | undefined
    | {
        start_date?: string | undefined;
        end_date?: string | undefined;
      }
  >("All");
  const [fileType, setFileType] = React.useState("");
  const [bankAccounts, setBankAccounts] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<{
    start_date?: string | undefined;
    end_date?: string | undefined;
  }>({
    start_date: "2022-06-01",
    end_date: formatDate(addDays(new Date(), 1)),
  });

  const dispatch = useDispatch<AppDispatch>();
  function getNthDaysAgo(d: number) {
    const today = new Date(); // Get today's date
    const nthDay = new Date(today); // Create a new date object

    nthDay.setDate(nthDay.getDate() - d); // Subtract nth days

    return nthDay;
  }

  function downloadFile(url: any, filename: string) {
    const a = document.createElement("a");

    // logger.log(url.url ?? url, 'oinu');
    a.href = url.url ?? url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        // removeEventListener("click", clickHandler);
      }, 150);
    };

    a.addEventListener("click", clickHandler, false);

    a.click();

    return a;
  }

  function handleFilter(str?: string) {
    const seventhDayAgo = getNthDaysAgo(7);
    const last30Days = getNthDaysAgo(30);

    const formated30days = formatDate(last30Days);
    const formated7days = formatDate(seventhDayAgo);
    const formated14days = formatDate(getNthDaysAgo(14));

    setDateRange({
      start_date:
        str === "All"
          ? formatDate(new Date("2022-06-01"))
          : str === "Today"
          ? formatDate(new Date())
          : str === "Last 7 Days"
          ? formated7days
          : str === "Last 14 Days"
          ? formated14days
          : str === "Last 30 Days"
          ? formated30days
          : dateRange.start_date,
      end_date:
        str === "All"
          ? formatDate(addDays(new Date(), 1))
          : str === "Today"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 7 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 14 Days"
          ? formatDate(addDays(new Date(), 1))
          : str === "Last 30 Days"
          ? formatDate(addDays(new Date(), 1))
          : dateRange.end_date,
    });
  }

  async function handleExport() {
    onClick && onClick();
    setLoading(true);
    handleFilter(selectedDay as string);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // let resp;

    // if (page === "wallet" || page === "transactions") {
    //   // resp = await dispatch(BugissApi.business.fetchAirtime({}));
    //   // exportTransactions({
    //   // 	start_date:
    //   // 		dateRange?.start_date && dateRange.start_date.replaceAll('/', '-'),
    //   // 	end_date:
    //   // 		dateRange?.end_date && dateRange.end_date.replaceAll('/', '-'),
    //   // })
    // }

    // logger.log(resp, 'from here');

    // if (resp?.payload?.status === "success") {
    //   setLoading(false);
    //   onClose && onClose();

    //   downloadFile(
    //     resp?.payload?.data?.data,
    //     "Atlas  " +
    //       capitalizeFirstLetter(page) +
    //       " - " +
    //       dateRange.start_date +
    //       " / " +
    //       dateRange.end_date
    //   );
    // } else {
    //   setLoading(false);
    // }
  }

  function addDays(date: Date | string, days: number) {
    // Function to add Days
    var result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;
  }

  useEffect(() => {
    onChange &&
      onChange({
        ...dateRange});
  }, [dateRange]);

  const { colorVariable } = useProductColor();

  const isStatement = title
    ? (title.includes("Statement") as boolean) && !title.includes("Wallet")
    : false;
  async function fetchBankAccounts() {
    const resp = await dispatch(
      BugissApi.business.getBizBankAccounts({
        business_id: String(business_id),
      })
    );

    if (resp.payload?.status === "success") {
      const selectData = resp.payload.data?.bank_account.data.map((d: any) => {
        return {
          label: d.account_name,
          value: d.account_number,
          id: d.id,
          bal: d.balance,
        };
      });

      setBankAccounts(selectData);
    }
  }
  useEffect(() => {
    if (isStatement) {
      fetchBankAccounts();
    }
  }, [visible]);
  return (
    <RavenModal
      visble={visible}
      onClose={onClose}
      loading={loading}
      disabled={!fileType || !dateRange}
      btnLabel={isStatement ? "Generate Statement" : "Export Data"}
      btnColor={colorVariable}
      onBtnClick={handleExport}
    >
      <div className="export-modal">
        <div className="export-modal__title">
          <h5>
            {Boolean(title) ? (
              title
            ) : (
              <>
                Export{" "}
                {capitalizeFirstLetter(page).replaceAll("_", " ").replaceAll("-", " ")}
              </>
            )}
          </h5>
          <p>Choose how you would like to export your data</p>
        </div>

        <div className="export-modal__export-as">
          <label htmlFor="">Export as</label>
          <div className={"export-modal__export-as--selector-wrap"}>
            <div
              onClick={() => {
                setFileType("csv");
                onChange && onChange({ ...value, fileType: "csv" });
              }}
              className={`selector-wrap__item ${fileType === "csv" && "selected"}`}
            >
              <figure>
                {fileType === "csv" ? icons.radio_check : icons.radio_unchecked}
              </figure>
              <p>CSV File</p>
            </div>
            <div
              onClick={() => {
                if (isStatement) {
                  setFileType("pdf");
                  onChange && onChange({ ...value, fileType: "pdf" });
                } else {
                  setFileType("excel");
                  onChange && onChange({ ...value, fileType: "excel" });
                }
              }}
              className={`selector-wrap__item ${
                (fileType === "excel" || fileType === "pdf") && "selected"
              }`}
            >
              <figure>
                {fileType === "excel" || fileType === "pdf"
                  ? icons.radio_check
                  : icons.radio_unchecked}
              </figure>
              <p>{isStatement ? "PDF" : "Excel File"}</p>
            </div>
          </div>

          {isStatement && (
            <>
              {/* <Gap v={10} /> */}
              <RavenInputField
                color={"black-light"}
                value={value?.business_account_number}
                style={{ zIndex: "444" }}
                className="bugiss"
                loadingSelect={loading}
                selectComponents={{ Option: CustomOption }}
                label="Select Statement Account*"
                onChange={(e: any) =>
                  onChange({
                    ...value,
                    business_account_number: e,
                  })
                }
                placeholder="Select a bank account"
                selectOption={bankAccounts.length > 0 ? bankAccounts : []}
                type="select"
              />
              <Gap v={28} />
            </>
          )}
          <div className="export-modal__days-selector">
            <label htmlFor="">Date</label>

            <div className={`days-selector__item-wrap `}>
              {days.map((d) => {
                return (
                  <div
                    key={d}
                    onClick={() => {
                      setSelectedDay(d);
                      handleFilter(d as string);
                      onChange && onChange({ ...value, date: d });
                    }}
                    className={`days-selector__item ${selectedDay === d && "selected"}`}
                  >
                    <p>{d}</p>
                    {selectedDay === d && "selected" && (
                      <figure>{icons.check_circle_green}</figure>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className={`export-modal__custom-date ${selectedDay === "Custom" && "show"}`}
          >
            <RavenInputField
              color="deep-green-dark"
              label="From"
              placeholder="Select a Date"
              type="date"
              onChange={(e: string) =>
                setDateRange({
                  ...(dateRange as {
                    start_date: string | undefined;
                    end_date: string | undefined;
                  }),
                  start_date: formatDate(new Date(e)),
                })
              }
            />
            <RavenInputField
              color={colorVariable}
              label="To"
              placeholder="Select a Date"
              type="date"
              onChange={(e: string) =>
                setDateRange({
                  ...(dateRange as {
                    start_date: string | undefined;
                    end_date: string | undefined;
                  }),
                  end_date: formatDate(new Date(e)),
                })
              }
            />
          </div>
        </div>
      </div>
    </RavenModal>
  );
};

export default ExportModal;

const CustomOption = ({
  innerProps,
  label,
  data,
}: {
  innerProps: any;
  label: string;
  data: any;
}) => {
  return (
    <div
      {...innerProps}
      className={cn("custom-select__bank-account", innerProps.isFocused && "active")}
    >
      <span>{trimLongString(label, 35)}</span>
      <small>{data.value}</small>

      <span>
        <p>Bal:</p> {formatNumberToCurrency(data.bal, "NGN")}
      </span>
    </div>
  );
};
