import { icons } from "@/assets/icons";

export const personalSideMenuList: SidebarMenuDataList = [
  {
    name: "Main Menu",
    numb: "one",
    icon: "",
    link: "",
    children: [
      { name: "Overview", link: "/personal-overview", icon: icons.sidebar_overview },
      { name: "Insights", link: "/personal-insights", icon: icons.sidebar_insights },
      { name: "Users", link: "/personal-users", icon: icons.sidebar_users },
      {
        name: "Transactions",
        link: "/personal-transactions",
        icon: icons.sidebar_transactions,
      },
      {
        name: "Kickbacks",
        link: "/personal-kickbacks",
        icon: icons.sidebar_kickbacks,
        hide: true,
      },
      {
        name: "Expenses",
        link: "/personal-expenses",
        icon: icons.sidebar_expenses,
        hide: true,
      },
    ],
  },
  {
    name: "Features",
    numb: "two",
    icon: "",
    link: "",
    children: [
      {
        name: "Bank Transfer",
        link: "/personal-transfer/transfers",
        icon: icons.sidebar_bank_transfers,
      },
      { name: "Savings", link: "/personal-savings/fixed", icon: icons.sidebar_savings },
      { name: "Cards", link: "/personal-cards", icon: icons.sidebar_cards },
      { name: "Deposits", link: "/personal-deposits", icon: icons.sidebar_bb_deposits },
      { name: "Rewards", link: "/personal-rewards", icon: icons.raven_donation },
      {
        name: "Scheduled Transactions",
        link: "/personal-scheduled-transactions",
        icon: icons.scheduled_tx,
      },
    ],
  },
  {
    name: "Support",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Fraud Center",
        link: "/personal-fraud-center",
        icon: icons.sidebar_fraud_center,
      },

      {
        name: "Resolution Center",
        link: "/personal-resolution-center",
        icon: icons.sidebar_resolution_center,
        hide: true,
      },
      {
        name: "Compliance",
        link: "/personal-compliance/bvn-verification",
        icon: icons.sidebar_compliace,
      },
      {
        name: "Audit Logs",
        link: "/personal-audit-trail",
        icon: icons.sidebar_bb_audit_trail,
      },
      { name: "Notification", link: "/personal-notification-center", icon: icons.bell },

      { name: "Dispute", link: "/personal-dispute", icon: icons.chat },
    ],
  },
  {
    name: "Admin Tools",
    numb: "four",
    icon: "",
    link: "",
    hide: true,
    children: [
      { name: "Notification", link: "/personal-notification", icon: icons.bell },
    ],
  },
];
