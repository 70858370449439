import { PartnersBuilder } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PartnersFeesApi = (builder: PartnersBuilder) => {
  return {
    getFees: createQueryWithRoute<
      PartnersFeesModel,
      Partial<PartnersPaginationParams & { status?: SN }>
    >(builder, "fees", { name: "getFees" }),

    getFeesExport: createQueryWithRoute<
      PosFeesModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "fees", { export: true, name: "getFeesExport" }),
  };
};
