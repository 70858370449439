import "~/common/used-by-details/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import {
  RavenInputField,
  RavenTable,
  RavenTableRow,
  toast,
} from "@ravenpay/raven-bank-ui";
import Avatar from "@/components/common/avatar";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import { Row } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import Gap from "@/components/common/styleComponents/Gap";
import { useParams, useSearchParams } from "react-router-dom";
import { businessReduxAPI } from "@/redux/slices/business/api";
import {
  assertPaginationPageNumber,
  detectStatus,
  formatNumberToCurrency,
  renderConditionally,
} from "@/utils/helpers";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { usePagination } from "@/hooks/usePagination";
import EmptyScreen from "@/components/common/emptyScreen";
import { useDispatch, useSelector } from "react-redux";
import ActionModal from "@/components/common/modal/ActionModal";
import InlineDetails from "@/components/common/inlineDetails";
import { useState } from "react";
import { sendRequest } from "@/utils/api-methods";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { useExportModal } from "@/hooks/useExportModal";
import Image from "@/components/common/image/Image";
import { BugissApi } from "@/redux/slices";
import { useAppDispatch } from "@/redux/store";

export const GlobalAccountDetails = () => {
  const [params] = useSearchParams();
  const { id } = useParams();

  const businessId = params.get("biz");
  const [modal, setModal] = useState<{
    block?: boolean;
    strip?: boolean;
    cardView?: boolean;
    cardFreeze?: boolean;
    cardDestroy?: boolean;
    beneficiary?: boolean;
    subAccount?: boolean;
    devices?: boolean;
    lien?: boolean;
    deduct?: boolean;
  }>({
    block: false,
    strip: false,
    lien: false,
    deduct: false,
    cardFreeze: false,
    cardDestroy: false,
    beneficiary: false,
    subAccount: false,
    devices: false,
    cardView: false,
  });
  const {
    currentPage,
    setCurrentPage,
    searchQuery,
    filterValue,
    setSearchQuery,
    setFilterValue,
  } = usePagination();
  const { data, isFetching, isLoading, refetch } =
    businessReduxAPI.useGetSingleGlobalAccountsQuery({
      business_id: businessId ?? "",
      global_account_id: id,
    });

  const business = data?.business;
  const account = data?.global_account;
  const summary = data?.transaction_summary;
  const gTrx = businessReduxAPI.useGetGlobalAccountsTrxQuery({
    business_id: businessId ?? "",
    currency: String(account?.currency?.toUpperCase()),
    per_page: 20,
    current_page: currentPage,
    search_term: searchQuery,
    ...filterValue,
  });

  const [isReqn, setIsReqn] = useState(false);
  const loading = isFetching || isLoading;

  const transactions = gTrx.data?.transactions;
  const trx = gTrx.data?.transactions?.data;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  // MODAL DEPS
  const [deductForm, setDeductForm] = useState<Required<IDeductBusinessModel>>({
    amount: null,
    business_id: "",
    business_account_number: "",
    reason: "",
    user_pin: 0,
  });
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });

  const [lienForm, setLienForm] = useState<Partial<ILienUnlien>>({});

  interface FormState {
    amount?: SN;
    reason_for_deduction?: string;
    reason_for_block?: string;
    reason?: string;
    user_id?: string;
    user_pin?: string;
  }
  type Errors = Partial<Record<keyof FormState, string>>;

  const [errors, setErrors] = useState<Errors>({});
  async function handleLienUnlien() {
    setIsReqn(true);

    if (lienForm?.amount === null || Number(lienForm?.amount) < 1) {
      setErrors({ amount: "This field is required" });
      setIsReqn(false);
      return;
    }

    let resp = await sendRequest("global_accounts_controller/lien_global_account", {
      global_account_id: id,
      amount: lienForm.amount,
      reason: lienForm.reason,
    });

    if (resp?.status === "success") {
      setModal({
        lien: false,
      });

      setLienForm({});

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }

  async function handleDeduction() {
    setIsReqn(true);

    if (deductForm?.amount === null || deductForm.amount < 1) {
      if (deductForm?.amount === null || deductForm.amount < 1) {
        setErrors({ amount: "This field is required" });
      }
      setIsReqn(false);

      return;
    }

    //  send req

    let resp = await sendRequest("global_accounts_controller/debit_a_global_account", {
      amount: deductForm?.amount,
      user_pin: deductForm?.user_pin,
      global_account_id: String(account?.id),
      business_id: String(business?.id),
      source: "web",
    });
    if (resp?.status === "success") {
      setIsReqn(false);
      refetch();
      setDeductForm({
        amount: "" as any,
        business_id: "",
        business_account_number: "",
        reason: "",
        user_pin: 0,
      });

      toast.success(resp.message);

      setModal({
        block: false,
      });
    } else {
      setIsReqn(false);
    }
  }

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "email",
    REFERENCE: ["trx_ref", "bulk_transfer_reference"],
    SESSION_ID: "meta_data.session_id",
    DIRECTION: "direction",
    BANK: "meta_data.bank",
    "ACCOUNT NO": "meta_data.account_number",
    RECIPIENT: "meta_data.account_name",
    NARRATION: "meta_data.narration",
    DESCRIPTIOM: "type",
    CURRENCY: "currency",
    AMOUNT: "amount",
    FEE: "fee",
    "BAL BEFORE": "bal_before",
    "BAL AFTER": "bal_after",
    STATUS: ["status", "transactions"],
    DATE: "created_at ",
  };
  const handleExport = useIsolatedExport({
    setFilterValue,
    data: gTrx.data,
    // apiFunction: BugissApi.business.fetchInvoices,
    loading,
    valuePair,
    dataPath: "transactions.data",
    title: `${account?.currency} Wallet (Bugiss)`,
    filterValue,
  });
  const { toggleModal } = useExportModal(handleExport);

  const accountLiens = businessReduxAPI.useFetchGlobalAccLienQuery({
    business_id: id,
  });

  // statement
  const [statementFunc, setStatementFunc] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  async function handleExportStatement() {
    const exportVal: any = exportValue;

    const resp = await dispatch(
      BugissApi.business.generateStatement({
        business_id: businessId as string,
        send_to: "download",
        currency: account?.currency as string,
        file_type: exportValue.fileType,
        date: `${exportValue.start_date.replaceAll(
          "/",
          "-"
        )}_${exportValue.end_date.replaceAll("/", "-")}`,
        currency_type: "foreign",
        account_id: String(account?.id),
      })
    );

    if (resp.payload?.status === "success") {
      const url = resp.payload.data.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `Statement of Account #${businessId}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      clearExportValue();
      toggleModal(false);

      setStatementFunc(false);
    }
  }
  const {
    toggleModal: toggleExport,
    exportValue,
    clearExportValue,
  } = useExportModal(statementFunc ? handleExportStatement : () => {});

  return (
    <>
      <DashboardLayout>
        <PageLayout
          pageTitle="Account Details"
          topLeftContent={<PageLayoutTopBackButton />}
          style={{ backgroundColor: "unset" }}
          childrenClassName="product-single-details-page"
        >
          <div className="single-user-details__top-wrap">
            <div className="top-wrap__main-content">
              <div className="top-wrap__main-content--user-data">
                <Row gap={1}>
                  <figure className="currency_flag">
                    {loading || !account?.currency ? (
                      <Redacted.Avatar />
                    ) : (
                      <Image
                        src={`https://businessapi.getraventest.com/files/currencies/${account?.currency.toLowerCase()}.png`}
                        alt={account?.currency}
                        fallbackSrc="/assets/eur.png"
                      />
                    )}
                  </figure>
                  <h6>
                    {loading ? (
                      <Redacted.Table />
                    ) : (
                      formatNumberToCurrency(Number(account?.balance), account?.currency)
                    )}
                  </h6>
                </Row>

                <Gap size={20} />

                <div className="user-data__main-content">
                  <MainContentCol>
                    <MainContentCol.Item title="EMAIL">
                      {loading ? <Redacted.Table /> : account?.business_email}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="BUSINESS NAME">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeValue(business?.business_name)
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="CURRENCY">
                      {loading ? <Redacted.Table /> : Util.safeValue(account?.currency)}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="DATE CREATED">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        formatDateTime(String(account?.created_at))
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                  <MainContentCol>
                    <MainContentCol.Item title="TRANSACTION VOLUME">
                      {loading ? (
                        <Redacted.Table />
                      ) : (
                        String(
                          formatNumberToCurrency(
                            Number(summary?.summation),
                            account?.currency
                          )
                        )
                      )}
                    </MainContentCol.Item>
                  </MainContentCol>
                </div>
              </div>
            </div>
            <div className="user-data__account-summary">
              <div className="top-wrap__actions">
                <IconButton
                  text={Boolean(account?.freeze) ? "Unlien Account" : "Lien Account"}
                  icon="info_circle"
                  onClick={() => setModal({ lien: true })}
                />
                <IconButton
                  text="Make a Deduction  "
                  icon="minus_circle"
                  onClick={() => setModal({ deduct: true })}
                />
                <IconButton
                  text="Generate Statement  "
                  icon="generate_reciept"
                  onClick={() => {
                    setStatementFunc(true);
                    toggleExport("Generate Wallet Statement");
                  }}
                />
              </div>
            </div>
          </div>

          <div className="single-user-details__table-wrap">
            <SmartFilter
              pagination={{
                currentPage,
                itemsPerPage: assertPaginationPageNumber(
                  transactions?.per_page as number
                ),
                totalItems: assertPaginationPageNumber(transactions?.total),
                onPageChange: function (page: number): void {
                  setCurrentPage(page);
                },

                activeFilter: "All Global Account",
              }}
              onExport={() => toggleModal("Export Wallet")}
              onSearchChange={(e: string) => setSearchQuery(e)}
              onFilter={(e: any) => setFilterValue(Util.filterStruct(e) as any)}
            />

            {(trx?.length === 0 || loading || !trx) && !isRedacting ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable
                headerList={[
                  "REFERENCE",
                  "AMOUNT",
                  "TYPE",
                  "DESCRIPTION",
                  "STATUS",
                  "DATE",
                ]}
              >
                {trx?.map((chi, idx) => {
                  const meta = JSON.parse(chi.meta_data);

                  return (
                    <RavenTableRow
                      key={idx}
                      one={loading ? <Redacted.Table /> : Util.safeValue(chi.trx_ref)}
                      onRowClick={() => {
                        setView({ on: true, data: chi });
                      }}
                      two={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          formatNumberToCurrency(chi?.amount, chi?.currency)
                        )
                      }
                      three={
                        loading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(Util.cleanText(chi?.type))
                        )
                      }
                      four={
                        loading ? <Redacted.Table /> : Util.safeValue(meta?.narration)
                      }
                      five={
                        loading ? (
                          <Redacted.Labelled />
                        ) : (
                          formatStatus(detectStatus(Number(chi?.status), "transactions"))
                        )
                      }
                      six={loading ? <Redacted.Table /> : formatDateTime(chi?.created_at)}
                    />
                  );
                })}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      {/* MODALS HERE */}

      <ActionModal
        visible={modal?.lien as boolean}
        onCancel={() => {
          setModal({ lien: false });
        }}
        loading={isReqn}
        onClick={() => {
          handleLienUnlien();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${Number(business?.lien) > 0 ? "Unlien" : "Lien"}`}
        btnColor={"red"}
        body={`${
          Number(business?.block_business) === 1
            ? `Are you sure to lien this  ${account?.currency} wallet? You can always come back to perform the action.`
            : `Are you sure to unlien this Business account? You can always come back to perform the action.`
        }`}
        title={
          Number(business?.lien) > 0
            ? `Unlien ${account?.currency} Wallet`
            : `Lien  ${account?.currency} Wallet`
        }
      >
        <p style={{ marginBottom: "2rem" }}>
          {`${
            Number(business?.lien) > 0
              ? `Are you sure to unlien this Business account? You can always come back to perform the action.`
              : `Are you sure to lien this Business account? You can always come back to perform the action.`
          }`}
        </p>

        <RavenInputField
          color={"black-light"}
          value={lienForm.amount}
          className="bugiss-block-modal"
          label="Amount*"
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          onChange={(e: any) =>
            setLienForm({
              ...lienForm,
              amount: e.target.value,
            })
          }
          placeholder="Amount to lien.."
          type="number"
        />

        <Gap v={10} />
        <RavenInputField
          color={"black-light"}
          value={lienForm.reason}
          className="bugiss-block-modal"
          label="Reason for Lien*"
          showError={errors.reason ? true : false}
          errorText={errors.reason}
          onChange={(e: any) =>
            setLienForm({
              ...lienForm,
              reason: e.target.value,
            })
          }
          placeholder="Reason for Lien"
          type="textarea"
        />
      </ActionModal>

      <ActionModal
        visible={modal?.deduct as boolean}
        onCancel={() => {
          setModal({ deduct: false });
          setDeductForm({
            amount: "" as any,
            business_id: "",
            business_account_number: "",
            reason: "",
            user_pin: "" as any,
          });
        }}
        loading={isReqn}
        onClick={() => {
          handleDeduction();
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Make a Deduction`}
        btnColor={"black"}
        body="Deduct specified amount from user balance"
        title={"Make a Deduction"}
      >
        <p style={{ marginBottom: "2rem" }}>Deduct a certain amount from this business</p>
        <RavenInputField
          color={"black-light"}
          // value={blockReason}
          className="bugiss-block-modal"
          label="Amount*"
          value={deductForm.amount}
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              amount: e.target.value,
            })
          }
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          placeholder="Amount*"
          type="number"
        />
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          value={deductForm.user_pin}
          className="bugiss-block-modal"
          label="User pin*"
          showError={errors.user_pin ? true : false}
          errorText={errors.user_pin}
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              user_pin: e,
            })
          }
          placeholder="User pin"
          type="pin"
        />

        {/* <RavenInputField
          color={"black-light"}
          value={deductForm.user_pin}
          className="bugiss-block-modal"
          label="User *"
          onChange={(e: any) =>
            setDeductForm({
              ...deductForm,
              user_pin: e.target.value,
            })
          }
          placeholder="Reason for Deduction"
          type="textarea"
        /> */}
      </ActionModal>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {}}
        actionText={"Close"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        hideCancel
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={"debit"}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "Amount",
                value: `${formatNumberToCurrency(
                  view.data.amount ?? 0,
                  view?.data?.currency
                )}`,
                // copy: true,
              },
              {
                label: "Type",
                value: view.data?.type,
              },

              {
                label: "Bal Before",
                value: `${formatNumberToCurrency(
                  view.data.bal_before ?? 0,
                  view?.data?.currency
                )}`,
              },
              {
                label: "Bal After",
                value: `${formatNumberToCurrency(
                  view.data.bal_after ?? 0,
                  view.data.currency
                )}`,
              },

              // {
              //   label: "Narration",
              //   value: trimLongString(
              //     formatMetaData(view.data.meta_data ?? "")?.narration,
              //     30
              //   ),
              // },
              ...renderConditionally(
                "Narration",
                trimLongString(formatMetaData(view.data.meta_data ?? "")?.narration, 30)
              ),
              {
                label: "Fee",
                value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
              },

              ...renderConditionally(
                "Recipient",
                formatMetaData(view.data.meta_data ?? "")?.account_name
              ),
              // {
              //   label: "Recipient",
              //   value: formatMetaData(view.data.meta_data ?? "")?.account_name,
              // },

              {
                label: "Transaction Ref",
                value:
                  view.data?.trx_ref ??
                  view.data?.bulk_transfer_reference ??
                  (typeof view?.data?.meta_data === "string"
                    ? JSON.parse(String(view?.data?.meta_data)).session_id
                    : null) ??
                  "--",
                copy: true,
                trim: true,
              },

              {
                label: "Status",
                value: formatStatus(detectStatus(Number(view.data?.status))),
              },

              {
                label: "Date Authorized",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </>
  );
};
