import "./styles/index.css";
import TabComponent from "@/components/common/Tabs";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import PosInsightsSideBar from "~pb/___not__ready__/insights/components/insightsSidebar";
import Dropdown from "@/components/common/dropdown";
import { TabsElement } from "@/components/common/Tabs/shared";
import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Column, Row } from "@/components/common/stacks";
import { Outlet, useNavigate } from "react-router-dom";
import { OutletTab } from "@/components/common/OutletTab";
import Gap from "@/components/common/styleComponents/Gap";

const PosInsights = () => {
  const navigate = useNavigate();
  const tabs: TabsElement[] = [
    "Users",
    "Settlements",
    "Deposits",
    "Card Collection",
    {
      label: "Bills",
      value: "Bills",
      dropdown: {
        maxWidth: "15rem",
        onChange: (e) => navigate(`/pos-insights/bills/${String(e)?.toLowerCase()}`),
        tabs: ["Airtime", "Data", "Cable TV", "Betting"],
      },
    },
    "Device Request",
    "Terminals",
  ];
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Insights">
        {/* <div className="pb-insights__header">
        <Dropdown
          items={dropdown}
          defaultValue={dropdown[0]}
          className="hdr-dropdown"
          buttonChild={({ selected }) => <div>Insights • {selected?.label}</div>}
        />
      </div> */}
        <div className="pb-insights__tabs">
          <OutletTab>
            <OutletTab.Tabs
              tabs={tabs}
              baseURL={"/pos-insights"}
              defaultOutletUrl="users"
            ></OutletTab.Tabs>
            <Gap size={15} />
            <OutletTab.Outlet />
          </OutletTab>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PosInsights;
