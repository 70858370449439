/* eslint-disable react/style-prop-object */
export const rankIcons = {
  // reward icons

  raven_nestling: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.00928 7.01953H9.06874V9.86809C9.06874 10.0059 8.9364 10.105 8.80416 10.0664L6.03901 9.2574L3.27385 10.0664C3.14161 10.105 3.00928 10.0059 3.00928 9.86809V7.01953Z"
        fill="url(#paint0_radial_12072_6802)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.52393 8.67188H7.51923V10.0639C7.51923 10.1568 7.45717 10.2383 7.36759 10.263L6.07651 10.6192C6.04056 10.6291 6.00259 10.6291 5.96664 10.6192L4.67556 10.263C4.58598 10.2383 4.52393 10.1568 4.52393 10.0639V8.67188Z"
        fill="url(#paint1_radial_12072_6802)"
      />
      <mask
        id="mask0_12072_6802"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="8"
        width="4"
        height="3"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.52393 8.67188H7.51923V10.0639C7.51923 10.1568 7.45717 10.2383 7.36759 10.263L6.07651 10.6192C6.04056 10.6291 6.00259 10.6291 5.96664 10.6192L4.67556 10.263C4.58598 10.2383 4.52393 10.1568 4.52393 10.0639V8.67188Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_12072_6802)">
        <rect
          x="5.79785"
          y="8.06738"
          width="0.447574"
          height="3.48113"
          fill="url(#paint2_linear_12072_6802)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23145 2.21056C5.34994 1.5648 6.72797 1.5648 7.84646 2.21056L8.0181 2.30965C9.13659 2.95541 9.82561 4.14883 9.82561 5.44035V5.63854C9.82561 6.93006 9.13659 8.12347 8.0181 8.76923L7.84646 8.86833C6.72797 9.51409 5.34993 9.51409 4.23144 8.86832L4.05981 8.76923C2.94132 8.12347 2.2523 6.93006 2.2523 5.63853V5.44035C2.2523 4.14883 2.94132 2.95541 4.05981 2.30965L4.23145 2.21056Z"
        fill="url(#paint3_linear_12072_6802)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.23143 2.27892C5.34992 1.63316 6.72796 1.63316 7.84645 2.27892L7.95845 2.34358C9.07694 2.98934 9.76596 4.18276 9.76596 5.47428V5.60361C9.76596 6.89513 9.07695 8.08854 7.95846 8.73431L7.84645 8.79897C6.72796 9.44473 5.34993 9.44473 4.23143 8.79897L4.11943 8.73431C3.00094 8.08855 2.31192 6.89513 2.31192 5.60361V5.47428C2.31192 4.18276 3.00094 2.98934 4.11943 2.34358L4.23143 2.27892Z"
        fill="url(#paint4_linear_12072_6802)"
      />
      <path
        d="M9.16807 4.12185L9.15679 4.04211L9.0767 4.03362C8.90463 4.01536 8.72738 4.07046 8.59947 4.2029C8.47157 4.33535 8.4227 4.51441 8.44695 4.68574L8.45823 4.76549L8.53832 4.77398C8.71039 4.79223 8.88764 4.73714 9.01554 4.60469C9.14344 4.47225 9.19232 4.29318 9.16807 4.12185Z"
        fill="url(#paint5_linear_12072_6802)"
        stroke="url(#paint6_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M8.76868 4.95437L8.79418 4.87327L8.7195 4.83265C8.59678 4.76589 8.44607 4.75213 8.3063 4.8086C8.16652 4.86507 8.06767 4.97966 8.02576 5.11294L8.00026 5.19404L8.07494 5.23466C8.19767 5.30141 8.34838 5.31517 8.48815 5.2587C8.62792 5.20223 8.72677 5.08764 8.76868 4.95437Z"
        fill="url(#paint7_linear_12072_6802)"
        stroke="url(#paint8_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M8.2362 5.46436L8.28798 5.39533L8.22927 5.3321C8.15048 5.24725 8.03548 5.19654 7.91042 5.20309C7.78536 5.20965 7.67629 5.2721 7.6068 5.36472L7.55502 5.43375L7.61374 5.49698C7.69252 5.58183 7.80753 5.63254 7.93258 5.62599C8.05764 5.61943 8.16671 5.55698 8.2362 5.46436Z"
        fill="url(#paint9_linear_12072_6802)"
        stroke="url(#paint10_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M7.63707 5.76916L7.71019 5.71914L7.67188 5.63925C7.63214 5.55639 7.55681 5.49091 7.46092 5.46522C7.36502 5.43952 7.26704 5.45856 7.1912 5.51046L7.11808 5.56048L7.15639 5.64036C7.19613 5.72322 7.27146 5.7887 7.36735 5.8144C7.46325 5.8401 7.56123 5.82105 7.63707 5.76916Z"
        fill="url(#paint11_linear_12072_6802)"
        stroke="url(#paint12_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M7.10463 5.85401L7.17698 5.81864L7.16031 5.73984C7.14437 5.66449 7.09616 5.59536 7.0203 5.55836C6.94444 5.52136 6.86029 5.52594 6.79109 5.55977L6.71874 5.59514L6.73541 5.67393C6.75135 5.74929 6.79956 5.81842 6.87541 5.85542C6.95127 5.89242 7.03543 5.88784 7.10463 5.85401Z"
        fill="url(#paint13_linear_12072_6802)"
        stroke="url(#paint14_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M6.58762 5.79917L6.66502 5.7769L6.66229 5.69641C6.66008 5.63142 6.63008 5.56639 6.57188 5.52411C6.51369 5.48183 6.44257 5.47339 6.38009 5.49138L6.30269 5.51365L6.30542 5.59414C6.30763 5.65912 6.33763 5.72415 6.39583 5.76644C6.45402 5.80872 6.52514 5.81715 6.58762 5.79917Z"
        fill="url(#paint15_linear_12072_6802)"
        stroke="url(#paint16_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        d="M6.15296 5.69807L6.25094 5.72117L6.27655 5.62381C6.29077 5.56972 6.28138 5.51113 6.25186 5.46199C6.22233 5.41285 6.175 5.37705 6.12057 5.36421L6.02258 5.34111L5.99697 5.43847C5.98275 5.49256 5.99214 5.55116 6.02167 5.6003C6.05119 5.64944 6.09852 5.68524 6.15296 5.69807ZM6.09579 5.46932L6.07316 5.56527C6.07212 5.56503 6.07169 5.56481 6.07169 5.56481C6.07168 5.56483 6.07312 5.5656 6.07479 5.56838C6.07646 5.57116 6.07647 5.57279 6.07648 5.57279C6.07649 5.57279 6.0765 5.57231 6.07677 5.57127L6.17211 5.59635C6.17362 5.59679 6.17514 5.59719 6.17666 5.59754C6.17706 5.59603 6.17742 5.59451 6.17774 5.59297L6.09579 5.46932Z"
        fill="url(#paint17_linear_12072_6802)"
        stroke="url(#paint18_linear_12072_6802)"
        stroke-width="0.206573"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36942 2.6476C5.40264 2.05107 6.67561 2.05107 7.70883 2.6476C8.74205 3.24413 9.37853 4.34656 9.37853 5.53961C9.37853 6.73267 8.74205 7.8351 7.70883 8.43163C6.67561 9.02815 5.40264 9.02815 4.36942 8.43163C3.3362 7.8351 2.69971 6.73267 2.69971 5.53961C2.69971 4.34656 3.3362 3.24413 4.36942 2.6476Z"
        fill="url(#paint19_radial_12072_6802)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.36941 2.6476C5.40263 2.05107 6.67561 2.05107 7.70882 2.6476C8.74204 3.24413 9.37853 4.34656 9.37853 5.53961C9.37853 6.73267 8.74204 7.8351 7.70882 8.43163C6.67561 9.02815 5.40263 9.02815 4.36941 8.43163C3.33619 7.8351 2.69971 6.73267 2.69971 5.53961C2.69971 4.34656 3.3362 3.24413 4.36941 2.6476Z"
        fill="#CFE9D5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.06626 1.82422H7.01119C7.7369 1.82422 8.40749 2.21138 8.77034 2.83987L9.74281 4.52423C10.1057 5.15271 10.1057 5.92704 9.74281 6.55552L8.77034 8.23988C8.40749 8.86836 7.7369 9.25553 7.01119 9.25553H5.06626C4.34055 9.25553 3.66996 8.86836 3.30711 8.23988L2.33464 6.55552C1.97179 5.92704 1.97179 5.15271 2.33464 4.52423L3.30711 2.83987C3.66996 2.21138 4.34055 1.82422 5.06626 1.82422ZM3.55746 2.95743C3.56403 2.95353 3.57064 2.94967 3.57727 2.94584L5.0231 2.11109C5.02967 2.1073 5.03626 2.10354 5.04286 2.09983C4.43235 2.10793 3.86973 2.43276 3.55746 2.95743ZM7.03463 2.09983C7.04124 2.10355 7.04782 2.1073 7.05439 2.11109L8.50022 2.94584C8.50683 2.94965 8.5134 2.9535 8.51995 2.95738C8.20768 2.43276 7.6451 2.10795 7.03463 2.09983ZM9.51574 4.68214C9.51583 4.68975 9.51587 4.69737 9.51587 4.705V6.3745C9.51587 6.38223 9.51583 6.38995 9.51574 6.39766C9.81411 5.86481 9.81411 5.215 9.51574 4.68214ZM8.52023 8.12195C8.51359 8.12588 8.50692 8.12978 8.50022 8.13365L7.05439 8.9684C7.04764 8.9723 7.04087 8.97616 7.03408 8.97997C7.64495 8.97204 8.20792 8.64701 8.52023 8.12195ZM5.04342 8.97998C5.03663 8.97616 5.02986 8.9723 5.0231 8.9684L3.57727 8.13365C3.57054 8.12977 3.56385 8.12585 3.55718 8.1219C3.86949 8.647 4.43251 8.97206 5.04342 8.97998ZM2.56175 6.39777C2.56167 6.39002 2.56162 6.38226 2.56162 6.3745V4.705C2.56162 4.69734 2.56167 4.68968 2.56175 4.68204C2.26331 5.21495 2.26331 5.86486 2.56175 6.39777ZM6.91672 2.34973C6.37345 2.03607 5.70412 2.03607 5.16085 2.34973L3.71502 3.18448C3.17176 3.49813 2.83709 4.07779 2.83709 4.7051V6.3746C2.83709 7.00191 3.17176 7.58157 3.71502 7.89523L5.16085 8.72998C5.70412 9.04363 6.37345 9.04363 6.91672 8.72998L8.36255 7.89523C8.90581 7.58157 9.24048 7.00191 9.24048 6.3746V4.7051C9.24048 4.07779 8.90581 3.49813 8.36255 3.18448L6.91672 2.34973Z"
        fill="url(#paint20_linear_12072_6802)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.29168 3.06647L7.05457 2.35222C6.42608 1.98936 5.65176 1.98936 5.02327 2.35222L3.78616 3.06647C3.15767 3.42933 2.77051 4.09991 2.77051 4.82562V6.25412C2.77051 6.97983 3.15767 7.65042 3.78616 8.01328L5.02327 8.72752C5.65176 9.09038 6.42608 9.09038 7.05457 8.72752L8.29168 8.01328C8.92017 7.65042 9.30733 6.97983 9.30733 6.25412V4.82562C9.30733 4.09991 8.92017 3.42933 8.29168 3.06647ZM5.16088 2.59079C5.70415 2.27713 6.37348 2.27713 6.91674 2.59079L8.15386 3.30504C8.69713 3.61869 9.03179 4.19835 9.03179 4.82566V6.25416C9.03179 6.88147 8.69713 7.46113 8.15386 7.77479L6.91674 8.48904C6.37348 8.80269 5.70415 8.80269 5.16088 8.48904L3.92376 7.77479C3.3805 7.46113 3.04583 6.88147 3.04583 6.25416V4.82566C3.04583 4.19835 3.3805 3.61869 3.92376 3.30504L5.16088 2.59079Z"
        fill="url(#paint21_linear_12072_6802)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.94472 2.87408C6.00804 2.8311 6.18906 3.029 6.2853 3.25229C6.2405 3.32173 6.18884 3.40208 6.13031 3.49334C6.17828 3.42807 6.24309 3.37252 6.32474 3.32669C6.29067 3.41202 6.40588 3.62601 6.40406 3.67807C6.40285 3.71278 6.33877 3.80311 6.21181 3.94906C6.25381 3.91009 6.31139 3.85407 6.38454 3.781L6.40105 3.76448C6.48499 4.20299 6.527 4.91218 6.48596 5.06413C6.44492 5.21607 6.35005 5.23583 6.24696 5.55397C6.29534 5.47795 6.36127 5.39019 6.44476 5.29067C6.43257 5.45384 6.38422 5.60649 6.29969 5.74864C6.17695 5.95503 6.06238 6.07808 6.04805 6.28984C6.04805 6.28984 6.03026 6.62982 6.03026 6.79981C6.03026 6.91115 6.03506 7.41696 6.03506 7.41696L5.93655 7.40982C5.93655 7.40982 5.94132 6.90157 5.94132 6.78929C5.94133 6.60953 5.92354 6.25001 5.92354 6.25001C5.91846 6.23081 5.91309 6.21249 5.90743 6.19506C5.84409 5.99986 5.63342 5.83644 5.56735 5.62895C5.5233 5.49063 5.47738 5.3348 5.4296 5.16146C5.50402 5.18164 5.57818 5.22836 5.6521 5.30163C5.6032 5.15927 5.47911 4.98603 5.40723 4.80403C5.47415 4.45866 5.4393 3.88563 5.57969 3.60986C5.63171 3.24429 5.88141 2.91706 5.94472 2.87408Z"
        fill="url(#paint22_linear_12072_6802)"
      />
      <mask
        id="mask1_12072_6802"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="2"
        width="2"
        height="6"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.94472 2.87408C6.00804 2.8311 6.18906 3.029 6.2853 3.25229C6.2405 3.32173 6.18884 3.40208 6.13031 3.49334C6.17828 3.42807 6.24309 3.37252 6.32474 3.32669C6.29067 3.41202 6.40588 3.62601 6.40406 3.67807C6.40285 3.71278 6.33877 3.80311 6.21181 3.94906C6.25381 3.91009 6.31139 3.85407 6.38454 3.781L6.40105 3.76448C6.48499 4.20299 6.527 4.91218 6.48596 5.06413C6.44492 5.21607 6.35005 5.23583 6.24696 5.55397C6.29534 5.47795 6.36127 5.39019 6.44476 5.29067C6.43257 5.45384 6.38422 5.60649 6.29969 5.74864C6.17695 5.95503 6.06238 6.07808 6.04805 6.28984C6.04805 6.28984 6.03026 6.62982 6.03026 6.79981C6.03026 6.91115 6.03506 7.41696 6.03506 7.41696L5.93655 7.40982C5.93655 7.40982 5.94132 6.90157 5.94132 6.78929C5.94133 6.60953 5.92354 6.25001 5.92354 6.25001C5.91846 6.23081 5.91309 6.21249 5.90743 6.19506C5.84409 5.99986 5.63342 5.83644 5.56735 5.62895C5.5233 5.49063 5.47738 5.3348 5.4296 5.16146C5.50402 5.18164 5.57818 5.22836 5.6521 5.30163C5.6032 5.15927 5.47911 4.98603 5.40723 4.80403C5.47415 4.45866 5.4393 3.88563 5.57969 3.60986C5.63171 3.24429 5.88141 2.91706 5.94472 2.87408Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_12072_6802)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.50197 4.77435C6.50197 5.03724 6.26773 5.32082 6.24707 5.55364C6.40418 5.29865 6.45094 5.20415 6.52548 5.16399C6.60001 5.12384 6.50197 4.51145 6.50197 4.77435Z"
          fill="url(#paint23_linear_12072_6802)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.65187 5.30173L5.71271 5.37975C5.71271 5.37975 5.66796 5.21612 5.6153 5.14266C5.56264 5.0692 5.43291 4.85769 5.407 4.80413C5.38109 4.75057 5.37793 4.90059 5.37793 4.90059L5.65187 5.30173Z"
          fill="url(#paint24_linear_12072_6802)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.38765 3.60417C6.40347 3.64789 6.36956 3.71889 6.30542 3.81235C6.24127 3.90581 6.14893 4.01238 6.14893 4.01238L6.21164 3.94901L6.43234 3.71371L6.4751 3.62155C6.4751 3.62155 6.37183 3.56045 6.38765 3.60417Z"
          fill="url(#paint25_linear_12072_6802)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.14896 3.47032L6.08105 3.553C6.08105 3.553 6.20125 3.36374 6.2211 3.30289C6.24095 3.24205 6.27544 3.20171 6.24827 3.1167C6.2211 3.03169 6.36586 3.24874 6.36586 3.24874L6.20887 3.39476L6.14896 3.47032Z"
          fill="url(#paint26_linear_12072_6802)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.91553 7.57712C5.91553 7.57712 5.93685 7.03704 5.94094 6.7967C5.94688 6.44741 5.96635 3.07227 5.96635 3.07227C5.96635 3.07227 6.03073 6.09787 6.03073 6.81906C6.03073 7.54026 6.19042 7.68706 6.19042 7.68706L5.91553 7.63221L5.91553 7.57712Z"
          fill="#268C69"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.97687 7.42338C5.96138 7.41853 5.94546 7.41354 5.93185 7.45678C5.93185 7.73953 5.89014 7.85409 5.89014 7.85409C5.89014 7.85409 5.99354 8.00927 5.99354 8.10085C5.99354 8.01205 6.09694 7.85409 6.09694 7.85409C6.09694 7.85409 6.05523 7.71383 6.05523 7.45678C6.04007 7.40863 6.02502 7.41484 6.00878 7.42154C6.00383 7.42359 5.99876 7.42568 5.99354 7.42629C5.98813 7.42691 5.98253 7.42516 5.97687 7.42338ZM6.01802 7.6631C6.01802 7.67729 6.00651 7.6888 5.99232 7.6888C5.97812 7.6888 5.96661 7.67729 5.96661 7.6631C5.96661 7.6489 5.97812 7.63739 5.99232 7.63739C6.00651 7.63739 6.01802 7.6489 6.01802 7.6631Z"
        fill="url(#paint27_linear_12072_6802)"
      />
      <mask
        id="mask2_12072_6802"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="2"
        height="2"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.97687 7.42338C5.96138 7.41853 5.94546 7.41354 5.93185 7.45678C5.93185 7.73953 5.89014 7.85409 5.89014 7.85409C5.89014 7.85409 5.99354 8.00927 5.99354 8.10085C5.99354 8.01205 6.09694 7.85409 6.09694 7.85409C6.09694 7.85409 6.05523 7.71383 6.05523 7.45678C6.04007 7.40863 6.02502 7.41484 6.00878 7.42154C6.00383 7.42359 5.99876 7.42568 5.99354 7.42629C5.98813 7.42691 5.98253 7.42516 5.97687 7.42338ZM6.01802 7.6631C6.01802 7.67729 6.00651 7.6888 5.99232 7.6888C5.97812 7.6888 5.96661 7.67729 5.96661 7.6631C5.96661 7.6489 5.97812 7.63739 5.99232 7.63739C6.00651 7.63739 6.01802 7.6489 6.01802 7.6631Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_12072_6802)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.99365 7.45703H6.28154V8.1011H5.99365V7.45703Z"
          fill="url(#paint28_linear_12072_6802)"
        />
        <rect
          opacity="0.6"
          x="5.83936"
          y="7.40527"
          width="0.308454"
          height="0.102818"
          fill="url(#paint29_linear_12072_6802)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.99354 8.01791C6.01292 7.93811 6.09694 7.85449 6.09694 7.85449C6.09694 7.85449 5.99354 8.01245 5.99354 8.10126C5.99354 8.00968 5.89014 7.85449 5.89014 7.85449C5.89014 7.85449 5.97102 7.93901 5.99354 8.01791Z"
        fill="url(#paint30_linear_12072_6802)"
      />
      <mask
        id="mask3_12072_6802"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="2"
        height="2"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.99354 8.01791C6.01292 7.93811 6.09694 7.85449 6.09694 7.85449C6.09694 7.85449 5.99354 8.01245 5.99354 8.10126C5.99354 8.00968 5.89014 7.85449 5.89014 7.85449C5.89014 7.85449 5.97102 7.93901 5.99354 8.01791Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_12072_6802)"></g>
      <g filter="url(#filter0_d_12072_6802)">
        <rect
          x="5.8877"
          y="7.36914"
          width="0.195674"
          height="0.101649"
          rx="0.0508245"
          fill="url(#paint31_linear_12072_6802)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.89404 7.39596C5.89404 7.38438 5.90343 7.375 5.915 7.375H6.05309C6.06467 7.375 6.07405 7.38438 6.07405 7.39596C6.07405 7.40753 6.06467 7.41692 6.05309 7.41692H5.915C5.90343 7.41692 5.89404 7.40753 5.89404 7.39596Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_12072_6802"
          x="4.95714"
          y="6.87937"
          width="2.15487"
          height="2.06064"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0489768" dy="0.489768" />
          <feGaussianBlur stdDeviation="0.489768" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.620833 0 0 0 0 0.428789 0 0 0 0 0.320764 0 0 0 0.44 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6802"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6802"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_12072_6802"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.13093 8.87478) rotate(90) scale(3.64697 14.5426)"
        >
          <stop stop-color="#003E65" />
          <stop offset="1" stop-color="#57BE6C" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_12072_6802"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.02158 9.21547) rotate(90) scale(2.20277 6.91245)"
        >
          <stop stop-color="#006565" />
          <stop offset="1" stop-color="#57BE6C" />
        </radialGradient>
        <linearGradient
          id="paint2_linear_12072_6802"
          x1="6.02843"
          y1="9.07155"
          x2="6.02843"
          y2="14.1982"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#003E65" />
          <stop offset="1" stop-color="#57BE6C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12072_6802"
          x1="3.61371"
          y1="3.15922"
          x2="3.61371"
          y2="8.0097"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#868EA9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12072_6802"
          x1="1.90881"
          y1="1.42901"
          x2="1.90881"
          y2="9.68929"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E4E7F1" />
          <stop offset="1" stop-color="#A3AAC2" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_6802"
          x1="8.84091"
          y1="4.86926"
          x2="9.25501"
          y2="4.38091"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_6802"
          x1="9.01953"
          y1="4.7207"
          x2="9.08079"
          y2="4.1379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_6802"
          x1="8.59331"
          y1="5.1908"
          x2="8.62182"
          y2="4.91954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_6802"
          x1="8.63818"
          y1="5.22754"
          x2="8.67323"
          y2="4.8941"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_6802"
          x1="8.08609"
          y1="5.50941"
          x2="8.10362"
          y2="5.34258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_6802"
          x1="8.13818"
          y1="5.54883"
          x2="8.16137"
          y2="5.32824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_6802"
          x1="7.53498"
          y1="5.70802"
          x2="7.54752"
          y2="5.58863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_6802"
          x1="7.57324"
          y1="5.73633"
          x2="7.58983"
          y2="5.57848"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_6802"
          x1="7.03056"
          y1="5.76275"
          x2="7.04122"
          y2="5.6613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_6802"
          x1="7.05664"
          y1="5.78613"
          x2="7.07074"
          y2="5.65199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_6802"
          x1="6.54037"
          y1="5.6901"
          x2="6.54916"
          y2="5.60646"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_6802"
          x1="6.55811"
          y1="5.70898"
          x2="6.56973"
          y2="5.59839"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_6802"
          x1="6.16814"
          y1="5.57772"
          x2="6.17792"
          y2="5.48465"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1DCD40" />
          <stop offset="1" stop-color="#00851D" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12072_6802"
          x1="6.17773"
          y1="5.59766"
          x2="6.19067"
          y2="5.4746"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#808AAC" />
          <stop offset="1" stop-color="#EFF1F6" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_12072_6802"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.03912 5.53961) rotate(87.8894) scale(3.17888)"
        >
          <stop stop-color="#253364" />
          <stop offset="1" stop-color="#757E9C" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_12072_6802"
          x1="2.26861"
          y1="1.95724"
          x2="2.26861"
          y2="9.00334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#C4C9DA" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12072_6802"
          x1="3.05675"
          y1="2.60095"
          x2="3.05675"
          y2="8.91452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A5ACC4" />
          <stop offset="0.507047" stop-color="#C3C7D9" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12072_6802"
          x1="5.86785"
          y1="1.72793"
          x2="6.683"
          y2="1.78754"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#248A67" />
          <stop offset="0.195" stop-color="#176067" />
          <stop offset="0.39" stop-color="#1D8368" />
          <stop offset="0.605" stop-color="#4AA451" />
          <stop offset="0.805" stop-color="#048F23" />
          <stop offset="1" stop-color="#048C21" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12072_6802"
          x1="6.37599"
          y1="4.49726"
          x2="6.60261"
          y2="4.52224"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#248A67" />
          <stop offset="0.195" stop-color="#176067" />
          <stop offset="0.39" stop-color="#1D8368" />
          <stop offset="0.605" stop-color="#4AA451" />
          <stop offset="0.805" stop-color="#048F23" />
          <stop offset="1" stop-color="#048C21" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12072_6802"
          x1="5.5188"
          y1="4.64222"
          x2="5.76219"
          y2="4.68421"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#248A67" />
          <stop offset="0.195" stop-color="#176067" />
          <stop offset="0.39" stop-color="#1D8368" />
          <stop offset="0.605" stop-color="#4AA451" />
          <stop offset="0.805" stop-color="#048F23" />
          <stop offset="1" stop-color="#048C21" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_12072_6802"
          x1="6.28618"
          y1="3.47904"
          x2="6.51786"
          y2="3.5329"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#248A67" />
          <stop offset="0.195" stop-color="#176067" />
          <stop offset="0.39" stop-color="#1D8368" />
          <stop offset="0.605" stop-color="#4AA451" />
          <stop offset="0.805" stop-color="#048F23" />
          <stop offset="1" stop-color="#048C21" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_12072_6802"
          x1="6.2009"
          y1="2.97741"
          x2="6.40684"
          y2="3.01614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#248A67" />
          <stop offset="0.195" stop-color="#176067" />
          <stop offset="0.39" stop-color="#1D8368" />
          <stop offset="0.605" stop-color="#4AA451" />
          <stop offset="0.805" stop-color="#048F23" />
          <stop offset="1" stop-color="#048C21" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_12072_6802"
          x1="5.97716"
          y1="7.24557"
          x2="6.13069"
          y2="7.25968"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#248A68" />
          <stop offset="0.360439" stop-color="#176067" />
          <stop offset="0.47754" stop-color="#1D8368" />
          <stop offset="0.655872" stop-color="#048C21" />
          <stop offset="0.7" stop-color="#4AA451" />
          <stop offset="0.80404" stop-color="#048F23" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_12072_6802"
          x1="6.1148"
          y1="7.29558"
          x2="6.32643"
          y2="7.32433"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#248A68" />
          <stop offset="0.360439" stop-color="#176067" />
          <stop offset="0.47754" stop-color="#1D8368" />
          <stop offset="0.655872" stop-color="#048C21" />
          <stop offset="0.7" stop-color="#4AA451" />
          <stop offset="0.80404" stop-color="#048F23" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_12072_6802"
          x1="5.96915"
          y1="7.3795"
          x2="6.09525"
          y2="7.49447"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#248A68" />
          <stop offset="0.360439" stop-color="#176067" />
          <stop offset="0.47754" stop-color="#1D8368" />
          <stop offset="0.655872" stop-color="#048C21" />
          <stop offset="0.7" stop-color="#4AA451" />
          <stop offset="0.80404" stop-color="#048F23" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_12072_6802"
          x1="5.97716"
          y1="7.79264"
          x2="6.12256"
          y2="7.82967"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#248A68" />
          <stop offset="0.360439" stop-color="#176067" />
          <stop offset="0.47754" stop-color="#1D8368" />
          <stop offset="0.655872" stop-color="#048C21" />
          <stop offset="0.7" stop-color="#4AA451" />
          <stop offset="0.80404" stop-color="#048F23" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_12072_6802"
          x1="5.97004"
          y1="7.34366"
          x2="6.07917"
          y2="7.40751"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#248A68" />
          <stop offset="0.360439" stop-color="#176067" />
          <stop offset="0.47754" stop-color="#1D8368" />
          <stop offset="0.655872" stop-color="#048C21" />
          <stop offset="0.7" stop-color="#4AA451" />
          <stop offset="0.80404" stop-color="#048F23" />
        </linearGradient>
      </defs>
    </svg>
  ),

  soaring_sparrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M1.25342 3.71777H2.38883V6.39861C1.6792 6.39861 1.25342 5.89693 1.25342 5.48932V3.71777Z"
        fill="url(#paint0_linear_12072_6952)"
      />
      <path
        d="M1.25342 3.71777H2.38883V6.39861C1.6792 6.39861 1.25342 5.89693 1.25342 5.48932V3.71777Z"
        stroke="url(#paint1_linear_12072_6952)"
        stroke-width="0.336162"
      />
      <path
        d="M10.7778 3.71777H9.64242V6.39861C10.3521 6.39861 10.7778 5.89693 10.7778 5.48932V3.71777Z"
        fill="url(#paint2_linear_12072_6952)"
      />
      <path
        d="M10.7778 3.71777H9.64242V6.39861C10.3521 6.39861 10.7778 5.89693 10.7778 5.48932V3.71777Z"
        stroke="url(#paint3_linear_12072_6952)"
        stroke-width="0.336162"
      />
      <path
        d="M4.50195 6.14648H7.57711V10.5549L6.26207 11.3096C6.11222 11.3955 5.92791 11.3952 5.77837 11.3087L4.50195 10.57V6.14648Z"
        fill="#BB9986"
        stroke="url(#paint4_linear_12072_6952)"
        stroke-width="0.336162"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.28123 6.14648V10.4687L3.19583 9.83198C3.0476 9.74503 2.95654 9.58604 2.95654 9.41419V6.14648H4.28123Z"
        fill="#8C685C"
        stroke="url(#paint5_linear_12072_6952)"
        stroke-width="0.336162"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.78176 6.14648V10.4687L8.86716 9.83198C9.01539 9.74503 9.10645 9.58604 9.10645 9.41419V6.14648H7.78176Z"
        fill="#8C685C"
        stroke="url(#paint6_linear_12072_6952)"
        stroke-width="0.336162"
      />
      <path
        d="M8.99609 8.02344V9.52155C8.92776 9.52681 8.78793 9.58463 8.77532 9.77387L7.90799 10.2785V8.55961"
        stroke="url(#paint7_linear_12072_6952)"
        stroke-width="0.168081"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.64384 8.85938H4.6123V10.4182L4.62548 10.4204C4.67194 10.4281 4.71164 10.4393 4.74569 10.4698C4.77986 10.5004 4.81029 10.5521 4.83355 10.6452L4.83519 10.6517L6.01164 11.3194H6.04733L6.04735 11.3195L6.05616 11.3169L7.22796 10.6518L7.2296 10.6452C7.25286 10.5522 7.28328 10.5004 7.31746 10.4698C7.3515 10.4394 7.3912 10.4282 7.43767 10.4204L7.45084 10.4182V8.85943H7.4193V10.3916C7.37578 10.3998 7.33356 10.4131 7.29643 10.4463C7.25664 10.4819 7.22456 10.5387 7.20067 10.631L6.04328 11.2879H6.01997L4.86248 10.6309C4.83859 10.5386 4.80651 10.4819 4.76672 10.4463C4.72959 10.4131 4.68737 10.3997 4.64384 10.3916V8.85938Z"
        fill="url(#paint8_linear_12072_6952)"
      />
      <path
        d="M3.08252 8.02344V9.52155C3.15085 9.52681 3.29068 9.58463 3.30329 9.77387L4.17062 10.2785V8.55961"
        stroke="url(#paint9_linear_12072_6952)"
        stroke-width="0.168081"
      />
      <g filter="url(#filter0_d_12072_6952)">
        <path
          d="M4.65023 1.24375L3.56519 1.834C2.64565 2.33422 2.07324 3.29722 2.07324 4.34402V5.82025C2.07324 6.86705 2.64565 7.83005 3.56519 8.33027L4.65023 8.92052C5.5016 9.38366 6.5297 9.38366 7.38108 8.92052L8.46611 8.33027C9.38565 7.83005 9.95806 6.86705 9.95806 5.82025L9.95806 4.34402C9.95806 3.29722 9.38565 2.33422 8.46611 1.834L7.38108 1.24375C6.5297 0.780615 5.5016 0.780615 4.65023 1.24375Z"
          fill="url(#paint10_linear_12072_6952)"
        />
        <path
          d="M4.65051 1.93529L4.1587 2.20284C3.24262 2.70118 2.67236 3.66055 2.67236 4.70341V5.44587C2.67236 6.48873 3.24262 7.44811 4.1587 7.94645L4.65051 8.21399C5.49868 8.67539 6.52292 8.67539 7.37109 8.21399L7.8629 7.94645C8.77898 7.44811 9.34924 6.48873 9.34924 5.44587L9.34924 4.70341C9.34924 3.66055 8.77898 2.70118 7.8629 2.20284L7.37109 1.93529C6.52292 1.4739 5.49868 1.4739 4.65051 1.93529Z"
          fill="url(#paint11_linear_12072_6952)"
        />
        <g filter="url(#filter1_f_12072_6952)">
          <path
            d="M4.65051 1.93529L4.1587 2.20284C3.24262 2.70118 2.67236 3.66055 2.67236 4.70341V5.44587C2.67236 6.48873 3.24262 7.44811 4.1587 7.94645L4.65051 8.21399C5.49868 8.67539 6.52292 8.67539 7.37109 8.21399L7.8629 7.94645C8.77898 7.44811 9.34924 6.48873 9.34924 5.44587L9.34924 4.70341C9.34924 3.66055 8.77898 2.70118 7.8629 2.20284L7.37109 1.93529C6.52292 1.4739 5.49868 1.4739 4.65051 1.93529Z"
            stroke="url(#paint12_linear_12072_6952)"
            stroke-width="0.168081"
          />
        </g>
        <g opacity="0.5">
          <mask
            id="mask0_12072_6952"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="2"
            y="1"
            width="8"
            height="8"
          >
            <path
              d="M4.73859 2.21253L4.37127 2.41217C3.49938 2.88604 2.95654 3.7989 2.95654 4.79125V5.36846C2.95654 6.36082 3.49938 7.27367 4.37127 7.74755L4.73859 7.94718C5.54491 8.38542 6.51833 8.38542 7.32466 7.94718L7.69197 7.74755C8.56387 7.27367 9.1067 6.36082 9.1067 5.36846V4.79125C9.1067 3.7989 8.56387 2.88604 7.69197 2.41217L7.32465 2.21253C6.51833 1.77429 5.54491 1.77429 4.73859 2.21253Z"
              fill="#A6D3DE"
            />
          </mask>
          <g mask="url(#mask0_12072_6952)">
            <path
              d="M4.73859 2.21253L4.37127 2.41217C3.49938 2.88604 2.95654 3.7989 2.95654 4.79125V5.36846C2.95654 6.36082 3.49938 7.27367 4.37127 7.74755L4.73859 7.94718C5.54491 8.38542 6.51833 8.38542 7.32466 7.94718L7.69197 7.74755C8.56387 7.27367 9.1067 6.36082 9.1067 5.36846V4.79125C9.1067 3.7989 8.56387 2.88604 7.69197 2.41217L7.32465 2.21253C6.51833 1.77429 5.54491 1.77429 4.73859 2.21253Z"
              fill="url(#paint13_radial_12072_6952)"
            />
            <path
              opacity="0.5"
              d="M5.51766 8.79077L5.39551 1.36816H6.66795L6.5458 8.79077H5.51766Z"
              fill="url(#paint14_linear_12072_6952)"
            />
            <path
              opacity="0.5"
              d="M5.50117 8.72451L1.68408 2.35742L2.78605 1.7212L6.39157 8.21044L5.50117 8.72451Z"
              fill="url(#paint15_linear_12072_6952)"
            />
            <path
              opacity="0.5"
              d="M5.39498 8.21092L9.00049 1.72168L10.1025 2.3579L6.28537 8.72499L5.39498 8.21092Z"
              fill="url(#paint16_linear_12072_6952)"
            />
            <rect
              x="2.95654"
              y="4.65527"
              width="6.15016"
              height="3.99407"
              fill="url(#paint17_linear_12072_6952)"
            />
            <g filter="url(#filter2_i_12072_6952)">
              <path
                d="M4.73859 2.21253L4.37127 2.41217C3.49938 2.88604 2.95654 3.7989 2.95654 4.79125V5.36846C2.95654 6.36082 3.49938 7.27367 4.37127 7.74755L4.73859 7.94718C5.54491 8.38542 6.51833 8.38542 7.32466 7.94718L7.69197 7.74755C8.56387 7.27367 9.1067 6.36082 9.1067 5.36846V4.79125C9.1067 3.7989 8.56387 2.88604 7.69197 2.41217L7.32465 2.21253C6.51833 1.77429 5.54491 1.77429 4.73859 2.21253Z"
                fill="#F5C4AF"
                fill-opacity="0.03"
              />
            </g>
          </g>
        </g>
      </g>
      <g filter="url(#filter3_d_12072_6952)">
        <g filter="url(#filter4_d_12072_6952)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.98887 1.95949C6.06745 1.90614 6.29212 2.15177 6.41156 2.42888C6.35596 2.51508 6.29184 2.6148 6.2192 2.72805C6.27874 2.64705 6.35918 2.5781 6.46051 2.52122C6.41822 2.62714 6.56121 2.89271 6.55896 2.95733C6.55745 3.00041 6.47792 3.11252 6.32035 3.29365C6.37248 3.24529 6.44394 3.17576 6.53472 3.08507L6.55521 3.06457C6.65939 3.60881 6.71154 4.48899 6.6606 4.67757C6.60966 4.86615 6.49192 4.89067 6.36398 5.28552C6.42402 5.19117 6.50585 5.08224 6.60947 4.95874C6.59434 5.16124 6.53432 5.3507 6.42942 5.52712C6.27709 5.78327 6.13489 5.93599 6.1171 6.19881C6.1171 6.19881 6.09503 6.62076 6.09503 6.83174C6.09503 6.96991 6.10098 7.59768 6.10098 7.59768L5.97873 7.58882C5.97873 7.58882 5.98465 6.95803 5.98465 6.81868C5.98465 6.59557 5.96257 6.14937 5.96257 6.14937C5.95627 6.12555 5.94961 6.10281 5.94259 6.08118C5.86397 5.83891 5.60251 5.63609 5.5205 5.37858C5.46584 5.2069 5.40885 5.0135 5.34954 4.79837C5.44191 4.82342 5.53395 4.88141 5.62569 4.97234C5.565 4.79565 5.41099 4.58065 5.32178 4.35477C5.40483 3.92612 5.36158 3.21493 5.53583 2.87268C5.60038 2.41896 5.91029 2.01284 5.98887 1.95949Z"
            fill="url(#paint18_linear_12072_6952)"
          />
          <mask
            id="mask1_12072_6952"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="1"
            width="2"
            height="7"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.98887 1.95949C6.06745 1.90614 6.29212 2.15177 6.41156 2.42888C6.35596 2.51508 6.29184 2.6148 6.2192 2.72805C6.27874 2.64705 6.35918 2.5781 6.46051 2.52122C6.41822 2.62714 6.56121 2.89271 6.55896 2.95733C6.55745 3.00041 6.47792 3.11252 6.32035 3.29365C6.37248 3.24529 6.44394 3.17576 6.53472 3.08507L6.55521 3.06457C6.65939 3.60881 6.71154 4.48899 6.6606 4.67757C6.60966 4.86615 6.49192 4.89067 6.36398 5.28552C6.42402 5.19117 6.50585 5.08224 6.60947 4.95874C6.59434 5.16124 6.53432 5.3507 6.42942 5.52712C6.27709 5.78327 6.13489 5.93599 6.1171 6.19881C6.1171 6.19881 6.09503 6.62076 6.09503 6.83174C6.09503 6.96991 6.10098 7.59768 6.10098 7.59768L5.97873 7.58882C5.97873 7.58882 5.98465 6.95803 5.98465 6.81868C5.98465 6.59557 5.96257 6.14937 5.96257 6.14937C5.95627 6.12555 5.94961 6.10281 5.94259 6.08118C5.86397 5.83891 5.60251 5.63609 5.5205 5.37858C5.46584 5.2069 5.40885 5.0135 5.34954 4.79837C5.44191 4.82342 5.53395 4.88141 5.62569 4.97234C5.565 4.79565 5.41099 4.58065 5.32178 4.35477C5.40483 3.92612 5.36158 3.21493 5.53583 2.87268C5.60038 2.41896 5.91029 2.01284 5.98887 1.95949Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_12072_6952)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.68061 4.31843C6.68061 4.64471 6.3899 4.99666 6.36426 5.28561C6.55925 4.96914 6.61728 4.85185 6.70979 4.80202C6.8023 4.75218 6.68061 3.99215 6.68061 4.31843Z"
              fill="url(#paint19_linear_12072_6952)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.62564 4.97198L5.70114 5.0688C5.70114 5.0688 5.6456 4.86572 5.58025 4.77455C5.51489 4.68338 5.35388 4.42088 5.32173 4.3544C5.28957 4.28793 5.28564 4.47411 5.28564 4.47411L5.62564 4.97198Z"
              fill="url(#paint20_linear_12072_6952)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.53896 2.86541C6.55859 2.91967 6.51651 3.00778 6.4369 3.12377C6.35729 3.23977 6.24268 3.37203 6.24268 3.37203L6.32051 3.29339L6.59443 3.00135L6.6475 2.88697C6.6475 2.88697 6.51932 2.81114 6.53896 2.86541Z"
              fill="url(#paint21_linear_12072_6952)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.24248 2.70002L6.1582 2.80263C6.1582 2.80263 6.30738 2.56774 6.33202 2.49222C6.35666 2.41671 6.39945 2.36664 6.36573 2.26114C6.33202 2.15563 6.51168 2.42501 6.51168 2.42501L6.31684 2.60624L6.24248 2.70002Z"
              fill="url(#paint22_linear_12072_6952)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.95264 7.79607C5.95264 7.79607 5.9791 7.12578 5.98418 6.8275C5.99155 6.39399 6.01572 2.20508 6.01572 2.20508C6.01572 2.20508 6.09562 5.96017 6.09562 6.85525C6.09562 7.75033 6.2938 7.93252 6.2938 7.93252L5.95264 7.86444L5.95264 7.79607Z"
              fill="#E1C2A4"
            />
          </g>
        </g>
        <g filter="url(#filter5_d_12072_6952)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.02854 7.60559C6.00932 7.59957 5.98956 7.59338 5.97267 7.64704C5.97267 7.99796 5.9209 8.14014 5.9209 8.14014C5.9209 8.14014 6.04923 8.33274 6.04923 8.4464C6.04923 8.33619 6.17757 8.14014 6.17757 8.14014C6.17757 8.14014 6.1258 7.96606 6.1258 7.64704C6.10698 7.58728 6.08831 7.59498 6.06815 7.6033C6.062 7.60584 6.05571 7.60844 6.04923 7.60919C6.04252 7.60997 6.03556 7.60779 6.02854 7.60559ZM6.07963 7.90335C6.07963 7.92096 6.06535 7.93525 6.04773 7.93525C6.03011 7.93525 6.01583 7.92096 6.01583 7.90335C6.01583 7.88573 6.03011 7.87144 6.04773 7.87144C6.06535 7.87144 6.07963 7.88573 6.07963 7.90335Z"
            fill="url(#paint23_linear_12072_6952)"
          />
          <mask
            id="mask2_12072_6952"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="7"
            width="2"
            height="2"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.02854 7.60559C6.00932 7.59957 5.98956 7.59338 5.97267 7.64704C5.97267 7.99796 5.9209 8.14014 5.9209 8.14014C5.9209 8.14014 6.04923 8.33274 6.04923 8.4464C6.04923 8.33619 6.17757 8.14014 6.17757 8.14014C6.17757 8.14014 6.1258 7.96606 6.1258 7.64704C6.10698 7.58728 6.08831 7.59498 6.06815 7.6033C6.062 7.60584 6.05571 7.60844 6.04923 7.60919C6.04252 7.60997 6.03556 7.60779 6.02854 7.60559ZM6.07963 7.90335C6.07963 7.92096 6.06535 7.93525 6.04773 7.93525C6.03011 7.93525 6.01583 7.92096 6.01583 7.90335C6.01583 7.88573 6.03011 7.87144 6.04773 7.87144C6.06535 7.87144 6.07963 7.88573 6.07963 7.90335Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_12072_6952)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.04932 7.64746H6.40662V8.44682H6.04932V7.64746Z"
              fill="url(#paint24_linear_12072_6952)"
            />
            <rect
              opacity="0.6"
              x="5.85791"
              y="7.58301"
              width="0.382824"
              height="0.127608"
              fill="url(#paint25_linear_12072_6952)"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.04923 8.34344C6.07329 8.2444 6.17757 8.14062 6.17757 8.14062C6.17757 8.14062 6.04923 8.33667 6.04923 8.44688C6.04923 8.33322 5.9209 8.14062 5.9209 8.14062C5.9209 8.14062 6.02128 8.24552 6.04923 8.34344Z"
            fill="url(#paint26_linear_12072_6952)"
          />
          <mask
            id="mask3_12072_6952"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="5"
            y="8"
            width="2"
            height="1"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.04923 8.34344C6.07329 8.2444 6.17757 8.14062 6.17757 8.14062C6.17757 8.14062 6.04923 8.33667 6.04923 8.44688C6.04923 8.33322 5.9209 8.14062 5.9209 8.14062C5.9209 8.14062 6.02128 8.24552 6.04923 8.34344Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_12072_6952)"></g>
        </g>
        <g filter="url(#filter6_d_12072_6952)">
          <rect
            x="5.91846"
            y="7.53906"
            width="0.242852"
            height="0.126157"
            rx="0.0630785"
            fill="url(#paint27_linear_12072_6952)"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.92627 7.57191C5.92627 7.55754 5.93792 7.5459 5.95228 7.5459H6.12367C6.13803 7.5459 6.14968 7.55754 6.14968 7.57191C6.14968 7.58628 6.13803 7.59792 6.12367 7.59792H5.95228C5.93792 7.59792 5.92627 7.58628 5.92627 7.57191Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_12072_6952"
          x="1.06476"
          y="0.896484"
          width="9.90174"
          height="10.7242"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.34465" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.491667 0 0 0 0 0.276808 0 0 0 0 0.208958 0 0 0 0.54 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6952"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6952"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_12072_6952"
          x="2.48753"
          y="1.40403"
          width="7.04642"
          height="7.34135"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0504243"
            result="effect1_foregroundBlur_12072_6952"
          />
        </filter>
        <filter
          id="filter2_i_12072_6952"
          x="2.95654"
          y="1.88379"
          width="6.15039"
          height="6.3916"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.1302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.283333 0 0 0 0 0.158667 0 0 0 0 0.0566667 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_6952" />
        </filter>
        <filter
          id="filter3_d_12072_6952"
          x="4.64945"
          y="1.95215"
          width="2.70305"
          height="7.83976"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.672323" />
          <feGaussianBlur stdDeviation="0.336162" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.566667 0 0 0 0 0.374405 0 0 0 0 0.165278 0 0 0 0.51 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6952"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6952"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_12072_6952"
          x="4.16685"
          y="1.34429"
          width="3.78981"
          height="8.07692"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.620833 0 0 0 0 0.428789 0 0 0 0 0.320764 0 0 0 0.44 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6952"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6952"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_12072_6952"
          x="4.76598"
          y="6.9898"
          width="2.68825"
          height="3.28103"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.620833 0 0 0 0 0.428789 0 0 0 0 0.320764 0 0 0 0.44 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6952"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6952"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_12072_6952"
          x="4.76353"
          y="6.93121"
          width="2.67409"
          height="2.55739"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.620833 0 0 0 0 0.428789 0 0 0 0 0.320764 0 0 0 0.44 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_6952"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_6952"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_6952"
          x1="1.82112"
          y1="3.71777"
          x2="1.82112"
          y2="6.39861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFD9BD" />
          <stop offset="1" stop-color="#C39470" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_6952"
          x1="1.25342"
          y1="3.71777"
          x2="1.82112"
          y2="6.39861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3DDC4" />
          <stop offset="0.378992" stop-color="#F3DDC4" />
          <stop offset="0.46253" stop-color="#F3DDC4" />
          <stop offset="1" stop-color="#74523F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_6952"
          x1="10.2101"
          y1="3.71777"
          x2="10.2101"
          y2="6.39861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFD9BD" />
          <stop offset="1" stop-color="#C39470" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12072_6952"
          x1="10.7778"
          y1="3.71777"
          x2="10.2101"
          y2="6.39861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F3DDC4" />
          <stop offset="0.378992" stop-color="#F3DDC4" />
          <stop offset="0.46253" stop-color="#F3DDC4" />
          <stop offset="1" stop-color="#74523F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12072_6952"
          x1="4.50191"
          y1="10.2466"
          x2="7.59276"
          y2="10.5147"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8B697" />
          <stop offset="0.374385" stop-color="#F0D6BD" />
          <stop offset="0.71988" stop-color="#C89E78" />
          <stop offset="0.9381" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_6952"
          x1="2.95652"
          y1="9.48893"
          x2="4.29517"
          y2="9.55028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8B697" />
          <stop offset="0.374385" stop-color="#F0D6BD" />
          <stop offset="0.71988" stop-color="#C89E78" />
          <stop offset="0.9381" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_6952"
          x1="9.10647"
          y1="9.48893"
          x2="7.76782"
          y2="9.55028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8B697" />
          <stop offset="0.374385" stop-color="#F0D6BD" />
          <stop offset="0.71988" stop-color="#C89E78" />
          <stop offset="0.9381" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_6952"
          x1="8.99611"
          y1="9.7673"
          x2="7.89993"
          y2="9.8464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8B697" />
          <stop offset="0.374385" stop-color="#F0D6BD" />
          <stop offset="0.71988" stop-color="#C89E78" />
          <stop offset="0.9381" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_6952"
          x1="4.67538"
          y1="9.5848"
          x2="7.21431"
          y2="10.6256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.192454" stop-color="#DFCBB9" />
          <stop offset="0.475371" stop-color="#D8B290" />
          <stop offset="0.678187" stop-color="#DEBFA4" />
          <stop offset="0.866423" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_6952"
          x1="3.0825"
          y1="9.7673"
          x2="4.17868"
          y2="9.8464"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8B697" />
          <stop offset="0.374385" stop-color="#F0D6BD" />
          <stop offset="0.71988" stop-color="#C89E78" />
          <stop offset="0.9381" stop-color="#DFCBB9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_6952"
          x1="4.35984"
          y1="1.60485"
          x2="7.90801"
          y2="8.65388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F1DCC1" />
          <stop offset="1" stop-color="#BD8A65" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_6952"
          x1="4.32817"
          y1="2.1888"
          x2="7.38748"
          y2="7.83433"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BF8E69" />
          <stop offset="1" stop-color="#E4C196" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_6952"
          x1="4.65934"
          y1="1.98379"
          x2="7.95519"
          y2="7.91318"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EBD4B7" />
          <stop offset="1" stop-color="#FFE3CE" />
        </linearGradient>
        <radialGradient
          id="paint13_radial_12072_6952"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.03162 5.07986) rotate(90) scale(3.57009 3.07508)"
        >
          <stop stop-color="#E3C29C" />
          <stop offset="1" stop-color="#D1A479" />
        </radialGradient>
        <linearGradient
          id="paint14_linear_12072_6952"
          x1="6.03173"
          y1="1.36816"
          x2="6.03173"
          y2="8.79077"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_6952"
          x1="2.23507"
          y1="2.03931"
          x2="5.94637"
          y2="8.46747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_6952"
          x1="9.55147"
          y1="2.03979"
          x2="5.84017"
          y2="8.46796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_6952"
          x1="6.03162"
          y1="6.38721"
          x2="6.01307"
          y2="8.40188"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.291667" stop-color="#FFFAF7" stop-opacity="0" />
          <stop offset="1" stop-color="#E4CBAD" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12072_6952"
          x1="5.89346"
          y1="0.536992"
          x2="6.90515"
          y2="0.610982"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_12072_6952"
          x1="6.52426"
          y1="3.97453"
          x2="6.80552"
          y2="4.00553"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_12072_6952"
          x1="5.46048"
          y1="4.15345"
          x2="5.76256"
          y2="4.20557"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12072_6952"
          x1="6.41302"
          y1="2.71011"
          x2="6.70056"
          y2="2.77695"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12072_6952"
          x1="6.30695"
          y1="2.08827"
          x2="6.56254"
          y2="2.13634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12072_6952"
          x1="6.0289"
          y1="7.3849"
          x2="6.21945"
          y2="7.40242"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12072_6952"
          x1="6.19967"
          y1="7.44709"
          x2="6.46232"
          y2="7.48277"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_12072_6952"
          x1="6.019"
          y1="7.55102"
          x2="6.17551"
          y2="7.69371"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_12072_6952"
          x1="6.0289"
          y1="8.06386"
          x2="6.20935"
          y2="8.10982"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_12072_6952"
          x1="6.02065"
          y1="7.50744"
          x2="6.1561"
          y2="7.58668"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFEFDC" />
          <stop offset="0.360439" stop-color="#FFF3E3" />
          <stop offset="0.47754" stop-color="#FFEAD1" />
          <stop offset="0.655872" stop-color="#F0DBC0" />
          <stop offset="0.80404" stop-color="#F0DBC2" />
        </linearGradient>
      </defs>
    </svg>
  ),
  fledgling_flyer: (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_12072_7057)">
        <path
          d="M2.17041 10.6063V7.21582H5.10056V9.95209L2.64096 10.9261C2.41521 11.0155 2.17041 10.8491 2.17041 10.6063Z"
          fill="#755957"
        />
        <path
          d="M9.59229 10.6063V7.21582H6.66214V9.95209L9.12173 10.9261C9.34749 11.0155 9.59229 10.8491 9.59229 10.6063Z"
          fill="#755957"
        />
        <g filter="url(#filter0_d_12072_7057)">
          <path
            d="M4.96841 1.37197L2.53088 2.68077C1.89847 3.02033 1.50391 3.68002 1.50391 4.39782V7.41812C1.50391 8.13592 1.89847 8.79562 2.53087 9.13518L4.96841 10.444C5.54417 10.7531 6.23654 10.7531 6.8123 10.444L9.24983 9.13518C9.88224 8.79562 10.2768 8.13592 10.2768 7.41812V4.39782C10.2768 3.68002 9.88224 3.02033 9.24983 2.68077L6.8123 1.37197C6.23654 1.06283 5.54417 1.06283 4.96841 1.37197Z"
            fill="url(#paint0_linear_12072_7057)"
          />
          <path
            d="M4.96639 2.13183L3.19351 3.08374C2.56349 3.42202 2.17041 4.07924 2.17041 4.79434V7.00355C2.17041 7.71865 2.56349 8.37586 3.19351 8.71415L4.96639 9.66606C5.53998 9.97404 6.22974 9.97404 6.80333 9.66606L8.57621 8.71415C9.20623 8.37586 9.59931 7.71865 9.59931 7.00355L9.59931 4.79434C9.59931 4.07924 9.20623 3.42202 8.57621 3.08374L6.80333 2.13183C6.22974 1.82385 5.53998 1.82385 4.96639 2.13183Z"
            fill="url(#paint1_linear_12072_7057)"
          />
          <g filter="url(#filter1_f_12072_7057)">
            <path
              d="M4.96639 2.13183L3.19351 3.08374C2.56349 3.42202 2.17041 4.07924 2.17041 4.79434V7.00355C2.17041 7.71865 2.56349 8.37586 3.19351 8.71415L4.96639 9.66606C5.53998 9.97404 6.22974 9.97404 6.80333 9.66606L8.57621 8.71415C9.20623 8.37586 9.59931 7.71865 9.59931 7.00355L9.59931 4.79434C9.59931 4.07924 9.20623 3.42202 8.57621 3.08374L6.80333 2.13183C6.22974 1.82385 5.53998 1.82385 4.96639 2.13183Z"
              stroke="url(#paint2_linear_12072_7057)"
              stroke-width="0.114642"
            />
          </g>
          <g opacity="0.5">
            <mask
              id="mask0_12072_7057"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="2"
              y="2"
              width="8"
              height="8"
            >
              <path
                d="M5.0347 2.4537L3.46013 3.29838C2.8605 3.62005 2.48633 4.24539 2.48633 4.92586V6.88618C2.48633 7.56665 2.8605 8.19199 3.46013 8.51366L5.0347 9.35833C5.57998 9.65085 6.23553 9.65085 6.78082 9.35833L8.35538 8.51366C8.95501 8.19199 9.32919 7.56665 9.32919 6.88618L9.32919 4.92586C9.32919 4.24539 8.95501 3.62005 8.35538 3.29838L6.78082 2.4537C6.23553 2.16119 5.57999 2.16119 5.0347 2.4537Z"
                fill="#A6D3DE"
              />
            </mask>
            <g mask="url(#mask0_12072_7057)">
              <path
                d="M5.0347 2.4537L3.46013 3.29838C2.8605 3.62005 2.48633 4.24539 2.48633 4.92586V6.88618C2.48633 7.56665 2.8605 8.19199 3.46013 8.51366L5.0347 9.35833C5.57998 9.65085 6.23553 9.65085 6.78082 9.35833L8.35538 8.51366C8.95501 8.19199 9.32919 7.56665 9.32919 6.88618L9.32919 4.92586C9.32919 4.24539 8.95501 3.62005 8.35538 3.29838L6.78082 2.4537C6.23553 2.16119 5.57999 2.16119 5.0347 2.4537Z"
                fill="url(#paint3_radial_12072_7057)"
              />
              <path
                opacity="0.5"
                d="M5.33611 9.98157L5.2002 1.83008H6.61596L6.48005 9.98157H5.33611Z"
                fill="url(#paint4_linear_12072_7057)"
              />
              <path
                opacity="0.5"
                d="M5.31782 9.90931L1.0708 2.91699L2.29689 2.21829L6.3085 9.34477L5.31782 9.90931Z"
                fill="url(#paint5_linear_12072_7057)"
              />
              <path
                opacity="0.5"
                d="M5.19982 9.34522L9.21143 2.21875L10.4375 2.91745L6.1905 9.90977L5.19982 9.34522Z"
                fill="url(#paint6_linear_12072_7057)"
              />
              <rect
                x="2.48633"
                y="5.44043"
                width="6.84286"
                height="4.38628"
                fill="url(#paint7_linear_12072_7057)"
              />
              <g filter="url(#filter2_i_12072_7057)">
                <path
                  d="M5.0347 2.4537L3.46013 3.29838C2.8605 3.62005 2.48633 4.24539 2.48633 4.92586V6.88618C2.48633 7.56665 2.8605 8.19199 3.46013 8.51366L5.0347 9.35833C5.57998 9.65085 6.23553 9.65085 6.78082 9.35833L8.35538 8.51366C8.95501 8.19199 9.32919 7.56665 9.32919 6.88618L9.32919 4.92586C9.32919 4.24539 8.95501 3.62005 8.35538 3.29838L6.78082 2.4537C6.23553 2.16119 5.57999 2.16119 5.0347 2.4537Z"
                  fill="#BCB0AE"
                  fill-opacity="0.03"
                />
              </g>
            </g>
          </g>
        </g>
        <g filter="url(#filter3_d_12072_7057)">
          <g filter="url(#filter4_d_12072_7057)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.82524 2.47877C5.91266 2.42018 6.16264 2.68993 6.29553 2.99425C6.23367 3.08891 6.16233 3.19842 6.08151 3.3228C6.14776 3.23384 6.23725 3.15813 6.34999 3.09566C6.30295 3.21197 6.46204 3.50363 6.45953 3.57459C6.45786 3.6219 6.36937 3.74502 6.19405 3.94394C6.25205 3.89083 6.33156 3.81447 6.43257 3.71488L6.45537 3.69236C6.57128 4.29005 6.6293 5.25665 6.57262 5.46375C6.51594 5.67086 6.38494 5.69778 6.24259 6.1314C6.30939 6.02779 6.40044 5.90817 6.51573 5.77253C6.4989 5.99492 6.43213 6.20299 6.3154 6.39673C6.14592 6.67804 5.9877 6.84575 5.96791 7.13438C5.96791 7.13438 5.94335 7.59777 5.94335 7.82946C5.94335 7.9812 5.94997 8.67061 5.94997 8.67061L5.81395 8.66088C5.81395 8.66088 5.82054 7.96815 5.82054 7.81511C5.82054 7.5701 5.79598 7.08009 5.79598 7.08009C5.78897 7.05392 5.78155 7.02896 5.77374 7.0052C5.68627 6.73913 5.39536 6.5164 5.30412 6.2336C5.24329 6.04507 5.17988 5.83267 5.1139 5.59642C5.21667 5.62392 5.31908 5.68761 5.42115 5.78747C5.35363 5.59343 5.18227 5.35731 5.08301 5.10925C5.17542 4.63851 5.12729 3.85749 5.32117 3.48162C5.39299 2.98335 5.73781 2.53735 5.82524 2.47877Z"
              fill="url(#paint8_linear_12072_7057)"
            />
            <mask
              id="mask1_12072_7057"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="2"
              width="2"
              height="7"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.82524 2.47877C5.91266 2.42018 6.16264 2.68993 6.29553 2.99425C6.23367 3.08891 6.16233 3.19842 6.08151 3.3228C6.14776 3.23384 6.23725 3.15813 6.34999 3.09566C6.30295 3.21197 6.46204 3.50363 6.45953 3.57459C6.45786 3.6219 6.36937 3.74502 6.19405 3.94394C6.25205 3.89083 6.33156 3.81447 6.43257 3.71488L6.45537 3.69236C6.57128 4.29005 6.6293 5.25665 6.57262 5.46375C6.51594 5.67086 6.38494 5.69778 6.24259 6.1314C6.30939 6.02779 6.40044 5.90817 6.51573 5.77253C6.4989 5.99492 6.43213 6.20299 6.3154 6.39673C6.14592 6.67804 5.9877 6.84575 5.96791 7.13438C5.96791 7.13438 5.94335 7.59777 5.94335 7.82946C5.94335 7.9812 5.94997 8.67061 5.94997 8.67061L5.81395 8.66088C5.81395 8.66088 5.82054 7.96815 5.82054 7.81511C5.82054 7.5701 5.79598 7.08009 5.79598 7.08009C5.78897 7.05392 5.78155 7.02896 5.77374 7.0052C5.68627 6.73913 5.39536 6.5164 5.30412 6.2336C5.24329 6.04507 5.17988 5.83267 5.1139 5.59642C5.21667 5.62392 5.31908 5.68761 5.42115 5.78747C5.35363 5.59343 5.18227 5.35731 5.08301 5.10925C5.17542 4.63851 5.12729 3.85749 5.32117 3.48162C5.39299 2.98335 5.73781 2.53735 5.82524 2.47877Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_12072_7057)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.59466 5.06956C6.59466 5.42788 6.27121 5.81439 6.24268 6.13172C6.45963 5.78417 6.5242 5.65537 6.62712 5.60064C6.73005 5.54591 6.59466 4.71124 6.59466 5.06956Z"
                fill="url(#paint9_linear_12072_7057)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.42125 5.78727L5.50526 5.8936C5.50526 5.8936 5.44347 5.67058 5.37075 5.57046C5.29803 5.47034 5.11889 5.18206 5.08312 5.10905C5.04734 5.03605 5.04297 5.24052 5.04297 5.24052L5.42125 5.78727Z"
                fill="url(#paint10_linear_12072_7057)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.43707 3.47407C6.45892 3.53366 6.4121 3.63043 6.32352 3.75781C6.23495 3.8852 6.10742 4.03045 6.10742 4.03045L6.19402 3.94408L6.49879 3.62337L6.55784 3.49776C6.55784 3.49776 6.41523 3.41448 6.43707 3.47407Z"
                fill="url(#paint11_linear_12072_7057)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.10744 3.29194L6.01367 3.40462C6.01367 3.40462 6.17965 3.14666 6.20706 3.06373C6.23448 2.9808 6.28209 2.92582 6.24458 2.80995C6.20706 2.69409 6.40696 2.98992 6.40696 2.98992L6.19018 3.18894L6.10744 3.29194Z"
                fill="url(#paint12_linear_12072_7057)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.78467 8.88904C5.78467 8.88904 5.81411 8.15293 5.81976 7.82535C5.82797 7.34928 5.85485 2.74902 5.85485 2.74902C5.85485 2.74902 5.94375 6.87285 5.94375 7.85583C5.94375 8.83881 6.16426 9.03889 6.16426 9.03889L5.78467 8.96413L5.78467 8.88904Z"
                fill="#C3BCBC"
              />
            </g>
          </g>
          <g filter="url(#filter5_d_12072_7057)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.86293 8.6718C5.84155 8.66519 5.81956 8.65839 5.80076 8.71732C5.80076 9.1027 5.74316 9.25884 5.74316 9.25884C5.74316 9.25884 5.88595 9.47035 5.88595 9.59518C5.88595 9.47414 6.02874 9.25884 6.02874 9.25884C6.02874 9.25884 5.97114 9.06766 5.97114 8.71732C5.95021 8.65169 5.92943 8.66015 5.907 8.66929C5.90016 8.67208 5.89316 8.67493 5.88595 8.67575C5.87848 8.67661 5.87075 8.67422 5.86293 8.6718ZM5.91973 8.99857C5.91973 9.01792 5.90384 9.03361 5.88423 9.03361C5.86463 9.03361 5.84874 9.01792 5.84874 8.99857C5.84874 8.97922 5.86463 8.96354 5.88423 8.96354C5.90384 8.96354 5.91973 8.97922 5.91973 8.99857Z"
              fill="url(#paint13_linear_12072_7057)"
            />
            <mask
              id="mask2_12072_7057"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="2"
              height="2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.86293 8.6718C5.84155 8.66519 5.81956 8.65839 5.80076 8.71732C5.80076 9.1027 5.74316 9.25884 5.74316 9.25884C5.74316 9.25884 5.88595 9.47035 5.88595 9.59518C5.88595 9.47414 6.02874 9.25884 6.02874 9.25884C6.02874 9.25884 5.97114 9.06766 5.97114 8.71732C5.95021 8.65169 5.92943 8.66015 5.907 8.66929C5.90016 8.67208 5.89316 8.67493 5.88595 8.67575C5.87848 8.67661 5.87075 8.67422 5.86293 8.6718ZM5.91973 8.99857C5.91973 9.01792 5.90384 9.03361 5.88423 9.03361C5.86463 9.03361 5.84874 9.01792 5.84874 8.99857C5.84874 8.97922 5.86463 8.96354 5.88423 8.96354C5.90384 8.96354 5.91973 8.97922 5.91973 8.99857Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_12072_7057)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.88574 8.7168H6.28329V9.59466H5.88574V8.7168Z"
                fill="url(#paint14_linear_12072_7057)"
              />
              <rect
                opacity="0.6"
                x="5.67285"
                y="8.64746"
                width="0.425942"
                height="0.140139"
                fill="url(#paint15_linear_12072_7057)"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.88595 9.48152C5.91272 9.37276 6.02874 9.25879 6.02874 9.25879C6.02874 9.25879 5.88595 9.47409 5.88595 9.59512C5.88595 9.4703 5.74316 9.25879 5.74316 9.25879C5.74316 9.25879 5.85485 9.37398 5.88595 9.48152Z"
              fill="url(#paint16_linear_12072_7057)"
            />
            <mask
              id="mask3_12072_7057"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="9"
              width="2"
              height="1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.88595 9.48152C5.91272 9.37276 6.02874 9.25879 6.02874 9.25879C6.02874 9.25879 5.88595 9.47409 5.88595 9.59512C5.88595 9.4703 5.74316 9.25879 5.74316 9.25879C5.74316 9.25879 5.85485 9.37398 5.88595 9.48152Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask3_12072_7057)"></g>
          </g>
          <rect
            x="5.74658"
            y="8.60645"
            width="0.270205"
            height="0.138545"
            rx="0.0692727"
            fill="url(#paint17_linear_12072_7057)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.75537 8.64185C5.75537 8.62607 5.76816 8.61328 5.78394 8.61328H5.97538C5.99115 8.61328 6.00394 8.62607 6.00394 8.64185C6.00394 8.65762 5.99115 8.67041 5.97538 8.67041H5.78394C5.76816 8.67041 5.75537 8.65762 5.75537 8.64185Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_12072_7057"
          x="0.816055"
          y="1.13965"
          width="10.1487"
          height="11.1411"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.917135" />
          <feGaussianBlur stdDeviation="0.343926" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.475 0 0 0 0 0.425521 0 0 0 0 0.425521 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7057"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_12072_7057"
          x="2.07889"
          y="1.80936"
          width="7.61224"
          height="8.17914"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0171963"
            result="effect1_foregroundBlur_12072_7057"
          />
        </filter>
        <filter
          id="filter2_i_12072_7057"
          x="2.48633"
          y="2.23438"
          width="6.84277"
          height="7.34375"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.770868" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.508333 0 0 0 0 0.437506 0 0 0 0 0.415139 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_7057" />
        </filter>
        <filter
          id="filter3_d_12072_7057"
          x="4.62444"
          y="2.4707"
          width="2.42885"
          height="8.04116"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.458568" />
          <feGaussianBlur stdDeviation="0.229284" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.354167 0 0 0 0 0.284809 0 0 0 0 0.284809 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7057"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_12072_7057"
          x="4.29528"
          y="2.05611"
          width="3.1701"
          height="7.85858"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0414595" dy="0.414595" />
          <feGaussianBlur stdDeviation="0.414595" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.729167 0 0 0 0 0.653971 0 0 0 0 0.647135 0 0 0 0.38 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7057"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_12072_7057"
          x="4.72726"
          y="8.1284"
          width="2.42439"
          height="3.07038"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0534686" dy="0.534685" />
          <feGaussianBlur stdDeviation="0.534685" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.729167 0 0 0 0 0.653971 0 0 0 0 0.647135 0 0 0 0.38 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7057"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7057"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_7057"
          x1="4.04805"
          y1="2.08923"
          x2="7.91424"
          y2="9.871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EDE9EA" />
          <stop offset="1" stop-color="#BEB5B6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_7057"
          x1="4.01272"
          y1="2.72972"
          x2="7.34838"
          y2="8.96612"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3BBBB" />
          <stop offset="1" stop-color="#ECE8E9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_7057"
          x1="4.38118"
          y1="2.50458"
          x2="7.9756"
          y2="9.05608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9C2C3" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_12072_7057"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.90776 5.90602) rotate(90) scale(3.92067 3.42143)"
        >
          <stop stop-color="#BA9D9B" />
          <stop offset="1" stop-color="#918888" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_12072_7057"
          x1="5.90808"
          y1="1.83008"
          x2="5.90808"
          y2="9.98157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_7057"
          x1="1.68384"
          y1="2.56764"
          x2="5.73281"
          y2="9.67283"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_7057"
          x1="9.82447"
          y1="2.5681"
          x2="5.77551"
          y2="9.67328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_7057"
          x1="5.90776"
          y1="7.34244"
          x2="5.88765"
          y2="9.55495"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DEDADA" stop-opacity="0" />
          <stop offset="1" stop-color="#DECFCF" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_7057"
          x1="5.71907"
          y1="0.916581"
          x2="6.84456"
          y2="0.999975"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_7057"
          x1="6.4207"
          y1="4.69189"
          x2="6.73354"
          y2="4.72683"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_7057"
          x1="5.2375"
          y1="4.88837"
          x2="5.57334"
          y2="4.94707"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_7057"
          x1="6.29696"
          y1="3.30352"
          x2="6.61645"
          y2="3.37877"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_7057"
          x1="6.17917"
          y1="2.6201"
          x2="6.46329"
          y2="2.67424"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_7057"
          x1="5.86333"
          y1="8.42944"
          x2="6.0753"
          y2="8.44918"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_7057"
          x1="6.05303"
          y1="8.49675"
          x2="6.34513"
          y2="8.53695"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_7057"
          x1="5.85209"
          y1="8.61233"
          x2="6.02415"
          y2="8.77128"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_7057"
          x1="5.86333"
          y1="9.17448"
          x2="6.06379"
          y2="9.22621"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FFFCFA" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#EEEBE6" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_7057"
          x1="5.74658"
          y1="8.60645"
          x2="5.74658"
          y2="8.74499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D2D2" />
          <stop offset="0.606973" stop-color="#F3F1EE" />
          <stop offset="1" stop-color="#BFB6B7" />
        </linearGradient>
        <clipPath id="clip0_12072_7057">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  wealth_connoisseur: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_12072_7155)">
        <path
          d="M2.70898 9.72221V6.16992H9.35633V9.6883C9.35633 9.8618 9.26353 10.022 9.11305 10.1084L6.23247 11.7615C6.08262 11.8475 5.89832 11.8471 5.74878 11.7606L2.95075 10.1414C2.80112 10.0549 2.70898 9.89509 2.70898 9.72221Z"
          fill="#84B7C4"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.64097 6.16992V11.1195L2.95075 10.1414C2.80112 10.0549 2.70898 9.89509 2.70898 9.72221V6.16992H4.64097Z"
          fill="#6B9BAA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.57568 11.0816V6.16992H4.64117V11.1195L4.57568 11.0816Z"
          fill="#B0D2DD"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.42446 6.13672V11.0863L9.11468 10.1082C9.26431 10.0217 9.35645 9.86188 9.35645 9.68901V6.13672H7.42446Z"
          fill="#6B9BAA"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.48975 11.0485V6.13672H7.42425V11.0864L7.48975 11.0485Z"
          fill="#B0D2DD"
        />
        <g filter="url(#filter0_d_12072_7155)">
          <path
            d="M4.65009 1.11875L3.24634 1.88238C2.3268 2.3826 1.75439 3.3456 1.75439 4.3924V6.26258C1.75439 7.30937 2.3268 8.27237 3.24634 8.7726L4.65009 9.53622C5.50146 9.99936 6.52957 9.99936 7.38094 9.53622L8.78469 8.77259C9.70423 8.27237 10.2766 7.30937 10.2766 6.26258V4.3924C10.2766 3.3456 9.70423 2.3826 8.78469 1.88238L7.38094 1.11875C6.52957 0.655615 5.50146 0.655616 4.65009 1.11875Z"
            fill="url(#paint0_linear_12072_7155)"
          />
          <path
            d="M4.64989 1.86596L3.88819 2.28032C2.97211 2.77866 2.40186 3.73803 2.40186 4.78089V5.85695C2.40186 6.89981 2.97211 7.85919 3.88819 8.35753L4.64989 8.77188C5.49806 9.23328 6.52229 9.23328 7.37047 8.77188L8.13217 8.35752C9.04825 7.85919 9.6185 6.89981 9.6185 5.85695L9.6185 4.78089C9.6185 3.73803 9.04825 2.77866 8.13217 2.28032L7.37047 1.86596C6.5223 1.40456 5.49806 1.40456 4.64989 1.86596Z"
            fill="url(#paint1_linear_12072_7155)"
          />
          <g filter="url(#filter1_f_12072_7155)">
            <path
              d="M4.64989 1.86596L3.88819 2.28032C2.97211 2.77866 2.40186 3.73803 2.40186 4.78089V5.85695C2.40186 6.89981 2.97211 7.85919 3.88819 8.35753L4.64989 8.77188C5.49806 9.23328 6.52229 9.23328 7.37047 8.77188L8.13217 8.35752C9.04825 7.85919 9.6185 6.89981 9.6185 5.85695L9.6185 4.78089C9.6185 3.73803 9.04825 2.77866 8.13217 2.28032L7.37047 1.86596C6.5223 1.40456 5.49806 1.40456 4.64989 1.86596Z"
              stroke="url(#paint2_linear_12072_7155)"
              stroke-width="0.168081"
            />
          </g>
          <g opacity="0.5">
            <mask
              id="mask0_12072_7155"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="2"
              y="1"
              width="8"
              height="8"
            >
              <path
                d="M4.73963 2.16956L4.12371 2.50431C3.25182 2.97819 2.70898 3.89104 2.70898 4.88339V5.76761C2.70898 6.75996 3.25182 7.67282 4.12371 8.14669L4.73963 8.48144C5.54595 8.91968 6.51937 8.91968 7.32569 8.48144L7.94161 8.14669C8.8135 7.67282 9.35633 6.75996 9.35633 5.76761L9.35633 4.88339C9.35633 3.89104 8.8135 2.97819 7.94161 2.50431L7.32569 2.16956C6.51937 1.73132 5.54595 1.73132 4.73963 2.16956Z"
                fill="#A6D3DE"
              />
            </mask>
            <g mask="url(#mask0_12072_7155)">
              <path
                d="M4.73963 2.16956L4.12371 2.50431C3.25182 2.97819 2.70898 3.89104 2.70898 4.88339V5.76761C2.70898 6.75996 3.25182 7.67282 4.12371 8.14669L4.73963 8.48144C5.54595 8.91968 6.51937 8.91968 7.32569 8.48144L7.94161 8.14669C8.8135 7.67282 9.35633 6.75996 9.35633 5.76761L9.35633 4.88339C9.35633 3.89104 8.8135 2.97819 7.94161 2.50431L7.32569 2.16956C6.51937 1.73132 5.54595 1.73132 4.73963 2.16956Z"
                fill="url(#paint3_radial_12072_7155)"
              />
              <path
                opacity="0.5"
                d="M5.47676 9.33614L5.34473 1.31348H6.72004L6.58801 9.33614H5.47676Z"
                fill="url(#paint4_linear_12072_7155)"
              />
              <path
                opacity="0.5"
                d="M5.45917 9.26463L1.3335 2.38281L2.52455 1.69516L6.42154 8.709L5.45917 9.26463Z"
                fill="url(#paint5_linear_12072_7155)"
              />
              <path
                opacity="0.5"
                d="M5.34471 8.70916L9.2417 1.69531L10.4328 2.38297L6.30708 9.26478L5.34471 8.70916Z"
                fill="url(#paint6_linear_12072_7155)"
              />
              <rect
                x="2.70898"
                y="4.86621"
                width="6.64735"
                height="4.31696"
                fill="url(#paint7_linear_12072_7155)"
              />
              <g filter="url(#filter2_i_12072_7155)">
                <path
                  d="M4.73963 2.16956L4.12371 2.50431C3.25182 2.97819 2.70898 3.89104 2.70898 4.88339V5.76761C2.70898 6.75996 3.25182 7.67282 4.12371 8.14669L4.73963 8.48144C5.54595 8.91968 6.51937 8.91968 7.32569 8.48144L7.94161 8.14669C8.8135 7.67282 9.35633 6.75996 9.35633 5.76761L9.35633 4.88339C9.35633 3.89104 8.8135 2.97819 7.94161 2.50431L7.32569 2.16956C6.51937 1.73132 5.54595 1.73132 4.73963 2.16956Z"
                  fill="#AFF5F1"
                  fill-opacity="0.03"
                />
              </g>
            </g>
          </g>
        </g>
        <g filter="url(#filter3_d_12072_7155)">
          <g filter="url(#filter4_d_12072_7155)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.98665 1.95227C6.07158 1.89461 6.31441 2.16009 6.4435 2.45961C6.38341 2.55277 6.31411 2.66056 6.2356 2.78297C6.29995 2.69541 6.38689 2.62089 6.49641 2.55941C6.45071 2.67389 6.60526 2.96094 6.60282 3.03078C6.6012 3.07734 6.51523 3.19851 6.34492 3.39429C6.40127 3.34201 6.4785 3.26687 6.57662 3.16885L6.59877 3.14669C6.71137 3.73493 6.76774 4.68626 6.71268 4.89008C6.65762 5.09391 6.53036 5.12041 6.39208 5.54718C6.45697 5.44521 6.54542 5.32747 6.65741 5.19398C6.64107 5.41286 6.5762 5.61763 6.46281 5.80831C6.29816 6.08518 6.14447 6.25023 6.12525 6.53431C6.12525 6.53431 6.10139 6.99037 6.10139 7.2184C6.10139 7.36775 6.10782 8.04626 6.10782 8.04626L5.97568 8.03668C5.97568 8.03668 5.98208 7.3549 5.98208 7.20428C5.98209 6.96315 5.95822 6.48087 5.95822 6.48087C5.95141 6.45512 5.94421 6.43055 5.93662 6.40717C5.85165 6.14531 5.56905 5.9261 5.48042 5.64777C5.42133 5.46221 5.35973 5.25317 5.29563 5.02066C5.39546 5.04772 5.49495 5.1104 5.5941 5.20869C5.52851 5.01771 5.36205 4.78533 5.26562 4.54118C5.35539 4.07788 5.30865 3.30921 5.49698 2.93928C5.56676 2.44888 5.90172 2.00993 5.98665 1.95227Z"
              fill="url(#paint8_linear_12072_7155)"
            />
            <mask
              id="mask1_12072_7155"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="1"
              width="2"
              height="8"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.98665 1.95227C6.07158 1.89461 6.31441 2.16009 6.4435 2.45961C6.38341 2.55277 6.31411 2.66056 6.2356 2.78297C6.29995 2.69541 6.38689 2.62089 6.49641 2.55941C6.45071 2.67389 6.60526 2.96094 6.60282 3.03078C6.6012 3.07734 6.51523 3.19851 6.34492 3.39429C6.40127 3.34201 6.4785 3.26687 6.57662 3.16885L6.59877 3.14669C6.71137 3.73493 6.76774 4.68626 6.71268 4.89008C6.65762 5.09391 6.53036 5.12041 6.39208 5.54718C6.45697 5.44521 6.54542 5.32747 6.65741 5.19398C6.64107 5.41286 6.5762 5.61763 6.46281 5.80831C6.29816 6.08518 6.14447 6.25023 6.12525 6.53431C6.12525 6.53431 6.10139 6.99037 6.10139 7.2184C6.10139 7.36775 6.10782 8.04626 6.10782 8.04626L5.97568 8.03668C5.97568 8.03668 5.98208 7.3549 5.98208 7.20428C5.98209 6.96315 5.95822 6.48087 5.95822 6.48087C5.95141 6.45512 5.94421 6.43055 5.93662 6.40717C5.85165 6.14531 5.56905 5.9261 5.48042 5.64777C5.42133 5.46221 5.35973 5.25317 5.29563 5.02066C5.39546 5.04772 5.49495 5.1104 5.5941 5.20869C5.52851 5.01771 5.36205 4.78533 5.26562 4.54118C5.35539 4.07788 5.30865 3.30921 5.49698 2.93928C5.56676 2.44888 5.90172 2.00993 5.98665 1.95227Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_12072_7155)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.73402 4.50174C6.73402 4.8544 6.4198 5.23481 6.39209 5.54712C6.60285 5.20506 6.66557 5.07829 6.76555 5.02443C6.86554 4.97056 6.73402 4.14908 6.73402 4.50174Z"
                fill="url(#paint9_linear_12072_7155)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.59404 5.20887L5.67564 5.31352C5.67564 5.31352 5.61562 5.09402 5.54498 4.99548C5.47434 4.89694 5.30032 4.61322 5.26556 4.54137C5.23081 4.46951 5.22656 4.67075 5.22656 4.67075L5.59404 5.20887Z"
                fill="url(#paint10_linear_12072_7155)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.58098 2.93169C6.6022 2.99034 6.55672 3.08557 6.47067 3.21094C6.38462 3.33631 6.26074 3.47927 6.26074 3.47927L6.34487 3.39427L6.64093 3.07862L6.69829 2.955C6.69829 2.955 6.55975 2.87304 6.58098 2.93169Z"
                fill="url(#paint11_linear_12072_7155)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.26052 2.75255L6.16943 2.86346C6.16943 2.86346 6.33067 2.60957 6.3573 2.52795C6.38393 2.44634 6.43019 2.39222 6.39374 2.27819C6.3573 2.16415 6.55149 2.45531 6.55149 2.45531L6.34089 2.65119L6.26052 2.75255Z"
                fill="url(#paint12_linear_12072_7155)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.94727 8.26076C5.94727 8.26076 5.97587 7.53627 5.98135 7.21388C5.98933 6.74533 6.01544 2.21777 6.01544 2.21777C6.01544 2.21777 6.10181 6.27643 6.10181 7.24387C6.10181 8.21132 6.31601 8.40823 6.31601 8.40823L5.94727 8.33465L5.94727 8.26076Z"
                fill="#A3D3DF"
              />
            </g>
          </g>
          <g filter="url(#filter5_d_12072_7155)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.02357 8.04666C6.0028 8.04015 5.98144 8.03346 5.96318 8.09146C5.96318 8.47075 5.90723 8.62443 5.90723 8.62443C5.90723 8.62443 6.04593 8.8326 6.04593 8.95545C6.04593 8.83632 6.18464 8.62443 6.18464 8.62443C6.18464 8.62443 6.12869 8.43627 6.12869 8.09146C6.10835 8.02687 6.08817 8.0352 6.06638 8.04419C6.05973 8.04693 6.05293 8.04974 6.04593 8.05055C6.03868 8.05139 6.03116 8.04904 6.02357 8.04666ZM6.0789 8.36886C6.0789 8.38791 6.06346 8.40334 6.04441 8.40334C6.02537 8.40334 6.00993 8.38791 6.00993 8.36886C6.00993 8.34982 6.02537 8.33438 6.04441 8.33438C6.06346 8.33438 6.0789 8.34982 6.0789 8.36886Z"
              fill="url(#paint13_linear_12072_7155)"
            />
            <mask
              id="mask2_12072_7155"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="2"
              height="1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.02357 8.04666C6.0028 8.04015 5.98144 8.03346 5.96318 8.09146C5.96318 8.47075 5.90723 8.62443 5.90723 8.62443C5.90723 8.62443 6.04593 8.8326 6.04593 8.95545C6.04593 8.83632 6.18464 8.62443 6.18464 8.62443C6.18464 8.62443 6.12869 8.43627 6.12869 8.09146C6.10835 8.02687 6.08817 8.0352 6.06638 8.04419C6.05973 8.04693 6.05293 8.04974 6.04593 8.05055C6.03868 8.05139 6.03116 8.04904 6.02357 8.04666ZM6.0789 8.36886C6.0789 8.38791 6.06346 8.40334 6.04441 8.40334C6.02537 8.40334 6.00993 8.38791 6.00993 8.36886C6.00993 8.34982 6.02537 8.33438 6.04441 8.33438C6.06346 8.33438 6.0789 8.34982 6.0789 8.36886Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_12072_7155)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.0459 8.09082H6.43209V8.95481H6.0459V8.09082Z"
                fill="url(#paint14_linear_12072_7155)"
              />
              <rect
                opacity="0.6"
                x="5.83887"
                y="8.02246"
                width="0.413772"
                height="0.137924"
                fill="url(#paint15_linear_12072_7155)"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.04593 8.84421C6.07194 8.73716 6.18464 8.625 6.18464 8.625C6.18464 8.625 6.04593 8.8369 6.04593 8.95602C6.04593 8.83317 5.90723 8.625 5.90723 8.625C5.90723 8.625 6.01572 8.73837 6.04593 8.84421Z"
              fill="url(#paint16_linear_12072_7155)"
            />
            <mask
              id="mask3_12072_7155"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="2"
              height="1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.04593 8.84421C6.07194 8.73716 6.18464 8.625 6.18464 8.625C6.18464 8.625 6.04593 8.8369 6.04593 8.95602C6.04593 8.83317 5.90723 8.625 5.90723 8.625C5.90723 8.625 6.01572 8.73837 6.04593 8.84421Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask3_12072_7155)"></g>
          </g>
          <g filter="url(#filter6_d_12072_7155)">
            <rect
              x="5.91064"
              y="7.98242"
              width="0.262485"
              height="0.136356"
              rx="0.068178"
              fill="url(#paint17_linear_12072_7155)"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.91895 8.01835C5.91895 8.00282 5.93153 7.99023 5.94706 7.99023H6.1323C6.14783 7.99023 6.16041 8.00282 6.16041 8.01835C6.16041 8.03388 6.14783 8.04646 6.1323 8.04646H5.94706C5.93153 8.04646 5.91895 8.03388 5.91895 8.01835Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_12072_7155"
          x="0.745909"
          y="0.771484"
          width="10.5394"
          height="11.4654"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.34465" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.208958 0 0 0 0 0.389892 0 0 0 0 0.491667 0 0 0 0.54 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7155"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_12072_7155"
          x="2.26745"
          y="1.38512"
          width="7.48561"
          height="7.86745"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0252121"
            result="effect1_foregroundBlur_12072_7155"
          />
        </filter>
        <filter
          id="filter2_i_12072_7155"
          x="2.70898"
          y="1.84082"
          width="6.64746"
          height="6.96973"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.1302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.37849 0 0 0 0 0.586923 0 0 0 0 0.704167 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_7155" />
        </filter>
        <filter
          id="filter3_d_12072_7155"
          x="4.5933"
          y="1.94434"
          width="2.81291"
          height="8.35637"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.672323" />
          <feGaussianBlur stdDeviation="0.336162" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.284809 0 0 0 0 0.354167 0 0 0 0 0.341682 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7155"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_12072_7155"
          x="4.1107"
          y="1.33648"
          width="3.89968"
          height="8.53298"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.564618 0 0 0 0 0.775175 0 0 0 0 0.841667 0 0 0 0.65 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7155"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_12072_7155"
          x="4.7523"
          y="7.43023"
          width="2.70876"
          height="3.34938"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.564618 0 0 0 0 0.775175 0 0 0 0 0.841667 0 0 0 0.65 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7155"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_12072_7155"
          x="4.75572"
          y="7.37457"
          width="2.69411"
          height="2.56813"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.564618 0 0 0 0 0.775175 0 0 0 0 0.841667 0 0 0 0.65 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7155"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7155"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_7155"
          x1="4.22585"
          y1="1.56909"
          x2="8.06086"
          y2="9.18798"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D8ECF1" />
          <stop offset="1" stop-color="#82B3BF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_7155"
          x1="4.19153"
          y1="2.19978"
          x2="7.49816"
          y2="8.30171"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A1C9D3" />
          <stop offset="1" stop-color="#D0ECF2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_7155"
          x1="4.54946"
          y1="1.9782"
          x2="8.11176"
          y2="8.38693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D0ECF2" />
          <stop offset="1" stop-color="#E6F8FC" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_12072_7155"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.03266 5.3255) rotate(90) scale(3.85871 3.32368)"
        >
          <stop stop-color="#D0EBF2" />
          <stop offset="1" stop-color="#8DD3E2" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_12072_7155"
          x1="6.03238"
          y1="1.31348"
          x2="6.03238"
          y2="9.33614"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_7155"
          x1="1.92902"
          y1="2.03898"
          x2="5.94036"
          y2="8.98681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_7155"
          x1="9.83723"
          y1="2.03914"
          x2="5.8259"
          y2="8.98697"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_7155"
          x1="6.03266"
          y1="6.73817"
          x2="6.01261"
          y2="8.9157"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EEFFFF" stop-opacity="0" />
          <stop offset="1" stop-color="#B5EBFB" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_7155"
          x1="5.88352"
          y1="0.414776"
          x2="6.977"
          y2="0.494747"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_7155"
          x1="6.56503"
          y1="4.13004"
          x2="6.86902"
          y2="4.16355"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_7155"
          x1="5.41554"
          y1="4.32417"
          x2="5.74203"
          y2="4.3805"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_7155"
          x1="6.44486"
          y1="2.76383"
          x2="6.75565"
          y2="2.83608"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_7155"
          x1="6.3302"
          y1="2.09134"
          x2="6.60646"
          y2="2.1433"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_7155"
          x1="6.02396"
          y1="7.80813"
          x2="6.22992"
          y2="7.82706"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_7155"
          x1="6.20841"
          y1="7.87425"
          x2="6.49229"
          y2="7.91281"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_7155"
          x1="6.01298"
          y1="7.98789"
          x2="6.18214"
          y2="8.14212"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_7155"
          x1="6.02396"
          y1="8.54202"
          x2="6.219"
          y2="8.5917"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_7155"
          x1="6.0211"
          y1="7.94824"
          x2="6.1675"
          y2="8.03389"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#E3FAFF" />
          <stop offset="0.360439" stop-color="#DDF7FF" />
          <stop offset="0.451944" stop-color="#CBEDF8" />
          <stop offset="0.627859" stop-color="#C8F1FA" />
          <stop offset="0.80404" stop-color="#D8F5FC" />
        </linearGradient>
        <clipPath id="clip0_12072_7155">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  mighty_falcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
    >
      <g clip-path="url(#clip0_12072_7292)">
        <path
          d="M3.15479 4.29199L3.37825 3.14734L0.121215 2.51149C-0.0184498 3.2269 0.403509 3.75488 0.814438 3.8351L3.15479 4.29199Z"
          fill="url(#paint0_linear_12072_7292)"
        />
        <path
          d="M3.12158 4.31348L3.34505 3.16883L0.17117 2.54921C0.0315054 3.26461 0.453465 3.79259 0.864393 3.87282L3.12158 4.31348Z"
          stroke="url(#paint1_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <path
          d="M3.17627 5.36035L3.39973 4.2157L0.697088 3.68808C0.557423 4.40349 0.979382 4.93147 1.39031 5.01169L3.17627 5.36035Z"
          fill="url(#paint2_linear_12072_7292)"
        />
        <path
          d="M3.17627 5.36035L3.39973 4.2157L0.697088 3.68808C0.557423 4.40349 0.979382 4.93147 1.39031 5.01169L3.17627 5.36035Z"
          stroke="url(#paint3_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <path
          d="M3.93164 6.56348L4.1551 5.41883L1.45246 4.8912C1.31279 5.60661 1.73475 6.13459 2.14568 6.21481L3.93164 6.56348Z"
          fill="url(#paint4_linear_12072_7292)"
        />
        <path
          d="M3.93164 6.56348L4.1551 5.41883L1.45246 4.8912C1.31279 5.60661 1.73475 6.13459 2.14568 6.21481L3.93164 6.56348Z"
          stroke="url(#paint5_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <g filter="url(#filter0_d_12072_7292)">
          <path
            d="M3.31641 9.18195V6.92578H4.77091V10.2725L3.57979 9.62495C3.41746 9.53669 3.31641 9.36672 3.31641 9.18195Z"
            fill="#ECD8BA"
          />
          <path
            d="M3.31641 9.18195V6.92578H4.77091V10.2725L3.57979 9.62495C3.41746 9.53669 3.31641 9.36672 3.31641 9.18195Z"
            stroke="url(#paint6_linear_12072_7292)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.47632 9.35817V8.19629H3.45743V9.37391L3.46532 9.37523C3.49144 9.37958 3.52049 9.39504 3.54421 9.41763C3.56791 9.4402 3.58545 9.46907 3.5898 9.49952L3.59052 9.50457L4.35498 9.86261V8.58396H4.33608V9.83289L3.60767 9.49174C3.60145 9.45805 3.58191 9.42744 3.55724 9.40395C3.53358 9.38141 3.5045 9.36476 3.47632 9.35817Z"
          fill="url(#paint7_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.33502 9.76332C4.35539 9.72887 4.35656 9.68 4.35498 9.64844L4.33611 9.64938C4.33768 9.6808 4.33601 9.72453 4.31875 9.75369C4.31039 9.76784 4.29855 9.77825 4.28151 9.78253C4.26417 9.78688 4.24021 9.78519 4.20725 9.77237L4.2004 9.78999C4.23547 9.80362 4.26363 9.8065 4.28611 9.80085C4.30889 9.79513 4.32457 9.78098 4.33502 9.76332Z"
          fill="#D4B195"
        />
        <g filter="url(#filter1_d_12072_7292)">
          <path
            d="M8.80957 9.18195V6.92578H7.35507V10.2725L8.54618 9.62495C8.70851 9.53669 8.80957 9.36672 8.80957 9.18195Z"
            fill="#ECD8BA"
          />
          <path
            d="M8.80957 9.18195V6.92578H7.35507V10.2725L8.54618 9.62495C8.70851 9.53669 8.80957 9.36672 8.80957 9.18195Z"
            stroke="url(#paint8_linear_12072_7292)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.64965 9.35817V8.19629H8.66855V9.37391L8.66066 9.37523C8.63453 9.37958 8.60549 9.39504 8.58176 9.41763C8.55807 9.4402 8.54053 9.46907 8.53618 9.49952L8.53546 9.50457L7.771 9.86261V8.58396H7.78989V9.83289L8.51831 9.49174C8.52453 9.45805 8.54407 9.42744 8.56873 9.40395C8.5924 9.38141 8.62148 9.36476 8.64965 9.35817Z"
          fill="url(#paint9_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.79096 9.76332C7.77058 9.72887 7.76942 9.68 7.771 9.64844L7.78987 9.64938C7.7883 9.6808 7.78997 9.72453 7.80722 9.75369C7.81559 9.76784 7.82743 9.77825 7.84447 9.78253C7.8618 9.78688 7.88576 9.78519 7.91872 9.77237L7.92557 9.78999C7.89051 9.80362 7.86235 9.8065 7.83986 9.80085C7.81708 9.79513 7.80141 9.78098 7.79096 9.76332Z"
          fill="#D4B195"
        />
        <path
          d="M8.95166 4.3291L8.7282 3.18445L11.9852 2.5486C12.1249 3.264 11.7029 3.79199 11.292 3.87221L8.95166 4.3291Z"
          fill="url(#paint10_linear_12072_7292)"
        />
        <path
          d="M8.98486 4.35059L8.7614 3.20594L11.9353 2.58632C12.0749 3.30172 11.653 3.8297 11.2421 3.90993L8.98486 4.35059Z"
          stroke="url(#paint11_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <path
          d="M8.93018 5.39746L8.70671 4.25281L11.4094 3.72519C11.549 4.44059 11.1271 4.96857 10.7161 5.0488L8.93018 5.39746Z"
          fill="url(#paint12_linear_12072_7292)"
        />
        <path
          d="M8.93018 5.39746L8.70671 4.25281L11.4094 3.72519C11.549 4.44059 11.1271 4.96857 10.7161 5.0488L8.93018 5.39746Z"
          stroke="url(#paint13_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <path
          d="M8.1748 6.60156L7.95134 5.45691L10.654 4.92929C10.7937 5.6447 10.3717 6.17268 9.96076 6.2529L8.1748 6.60156Z"
          fill="url(#paint14_linear_12072_7292)"
        />
        <path
          d="M8.1748 6.60156L7.95134 5.45691L10.654 4.92929C10.7937 5.6447 10.3717 6.17268 9.96076 6.2529L8.1748 6.60156Z"
          stroke="url(#paint15_linear_12072_7292)"
          stroke-width="0.385597"
        />
        <g filter="url(#filter2_d_12072_7292)">
          <path
            d="M4.50244 6.78418H7.60914V10.7293L6.25645 11.4169C6.118 11.4873 5.9542 11.487 5.81599 11.4162L4.50244 10.7428V6.78418Z"
            fill="url(#paint16_linear_12072_7292)"
          />
          <path
            d="M4.50244 6.78418H7.60914V10.7293L6.25645 11.4169C6.118 11.4873 5.9542 11.487 5.81599 11.4162L4.50244 10.7428V6.78418Z"
            stroke="url(#paint17_linear_12072_7292)"
            stroke-width="0.336162"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.50762 11.2614L6.25638 11.3891C6.11794 11.4595 5.95414 11.4592 5.81593 11.3884L5.54736 11.2507V8.36621H6.50762V11.2614Z"
          fill="url(#paint18_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.67188 8.90234H4.71688V10.6681L6.04461 11.3544L7.39466 10.668V8.90234H7.43966V10.684L6.04442 11.3877L4.67188 10.6839V8.90234Z"
          fill="url(#paint19_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.05385 10.7383V11.2507H6.0293V10.7383H6.05385Z"
          fill="url(#paint20_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.96823 11.0683C5.91937 11.1246 5.85984 11.1372 5.81885 11.1304L5.82288 11.1061C5.85554 11.1116 5.90649 11.102 5.94968 11.0522C5.99305 11.0022 6.02954 10.9104 6.02954 10.75H6.05409C6.05409 10.9136 6.01693 11.0121 5.96823 11.0683Z"
          fill="url(#paint21_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.11526 11.0683C6.16413 11.1246 6.22366 11.1372 6.26465 11.1304L6.26061 11.1061C6.22795 11.1116 6.17701 11.102 6.13381 11.0522C6.09044 11.0022 6.05396 10.9104 6.05396 10.75H6.02941C6.02941 10.9136 6.06657 11.0121 6.11526 11.0683Z"
          fill="url(#paint22_linear_12072_7292)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.03648 11.2619L5.75928 11.1066L5.82065 11.1066L6.04162 11.2372L6.26255 11.1055H6.31672L6.04668 11.2619C6.04344 11.2634 6.03972 11.2634 6.03648 11.2619Z"
          fill="url(#paint23_linear_12072_7292)"
        />
        <g filter="url(#filter3_d_12072_7292)">
          <path
            d="M4.69031 2.24571L4.01734 2.61179C3.0978 3.11202 2.52539 4.07502 2.52539 5.12181V6.0887C2.52539 7.1355 3.0978 8.0985 4.01734 8.59872L4.69031 8.96481C5.54168 9.42795 6.56978 9.42795 7.42116 8.96481L8.09412 8.59872C9.01367 8.0985 9.58607 7.1355 9.58607 6.0887V5.12181C9.58607 4.07502 9.01367 3.11202 8.09412 2.61179L7.42116 2.24571C6.56978 1.78257 5.54168 1.78257 4.69031 2.24571Z"
            fill="url(#paint24_linear_12072_7292)"
          />
          <path
            d="M4.69122 2.864L4.54835 2.94173C3.63226 3.44007 3.06201 4.39944 3.06201 5.4423V5.75345C3.06201 6.79631 3.63226 7.75569 4.54835 8.25403L4.69122 8.33175C5.53939 8.79315 6.56363 8.79315 7.4118 8.33175L7.55467 8.25403C8.47076 7.75569 9.04101 6.79631 9.04101 5.75346V5.4423C9.04101 4.39945 8.47076 3.44007 7.55467 2.94173L7.4118 2.864C6.56363 2.40261 5.53939 2.40261 4.69122 2.864Z"
            fill="url(#paint25_linear_12072_7292)"
          />
          <g filter="url(#filter4_f_12072_7292)">
            <path
              d="M4.69122 2.864L4.54835 2.94173C3.63226 3.44007 3.06201 4.39944 3.06201 5.4423V5.75345C3.06201 6.79631 3.63226 7.75569 4.54835 8.25403L4.69122 8.33175C5.53939 8.79315 6.56363 8.79315 7.4118 8.33175L7.55467 8.25403C8.47076 7.75569 9.04101 6.79631 9.04101 5.75346V5.4423C9.04101 4.39945 8.47076 3.44007 7.55467 2.94173L7.4118 2.864C6.56363 2.40261 5.53939 2.40261 4.69122 2.864Z"
              stroke="url(#paint26_linear_12072_7292)"
              stroke-width="0.168081"
            />
          </g>
          <g opacity="0.5">
            <mask
              id="mask0_12072_7292"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="3"
              y="2"
              width="6"
              height="7"
            >
              <path
                d="M4.77704 3.10901L4.73113 3.13396C3.85924 3.60784 3.31641 4.52069 3.31641 5.51305V5.69333C3.31641 6.68568 3.85924 7.59854 4.73113 8.07241L4.77704 8.09736C5.58336 8.5356 6.55678 8.5356 7.36311 8.09736L7.40901 8.07241C8.28091 7.59854 8.82374 6.68568 8.82374 5.69333V5.51305C8.82374 4.52069 8.28091 3.60784 7.40901 3.13396L7.36311 3.10901C6.55678 2.67078 5.58336 2.67078 4.77704 3.10901Z"
                fill="#A6D3DE"
              />
            </mask>
            <g mask="url(#mask0_12072_7292)">
              <path
                d="M4.77704 3.10901L4.73113 3.13396C3.85924 3.60784 3.31641 4.52069 3.31641 5.51305V5.69333C3.31641 6.68568 3.85924 7.59854 4.73113 8.07241L4.77704 8.09736C5.58336 8.5356 6.55678 8.5356 7.36311 8.09736L7.40901 8.07241C8.28091 7.59854 8.82374 6.68568 8.82374 5.69333V5.51305C8.82374 4.52069 8.28091 3.60784 7.40901 3.13396L7.36311 3.10901C6.55678 2.67078 5.58336 2.67078 4.77704 3.10901Z"
                fill="url(#paint27_radial_12072_7292)"
              />
              <path
                opacity="0.5"
                d="M5.60939 8.92608L5.5 2.2793H6.63945L6.53006 8.92608H5.60939Z"
                fill="url(#paint28_linear_12072_7292)"
              />
              <path
                opacity="0.5"
                d="M5.59488 8.8676L2.17676 3.16602L3.16355 2.59629L6.39221 8.40727L5.59488 8.8676Z"
                fill="url(#paint29_linear_12072_7292)"
              />
              <path
                opacity="0.5"
                d="M5.49986 8.40668L8.72852 2.5957L9.71531 3.16543L6.29718 8.86701L5.49986 8.40668Z"
                fill="url(#paint30_linear_12072_7292)"
              />
              <rect
                x="3.31641"
                y="5.22266"
                width="5.50733"
                height="3.5766"
                fill="url(#paint31_linear_12072_7292)"
              />
              <g filter="url(#filter5_i_12072_7292)">
                <path
                  d="M4.77704 3.10901L4.73113 3.13396C3.85924 3.60784 3.31641 4.52069 3.31641 5.51305V5.69333C3.31641 6.68568 3.85924 7.59854 4.73113 8.07241L4.77704 8.09736C5.58336 8.5356 6.55678 8.5356 7.36311 8.09736L7.40901 8.07241C8.28091 7.59854 8.82374 6.68568 8.82374 5.69333V5.51305C8.82374 4.52069 8.28091 3.60784 7.40901 3.13396L7.36311 3.10901C6.55678 2.67078 5.58336 2.67078 4.77704 3.10901Z"
                  fill="#6052B4"
                  fill-opacity="0.01"
                />
              </g>
            </g>
          </g>
        </g>
        <g filter="url(#filter6_d_12072_7292)">
          <g filter="url(#filter7_d_12072_7292)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.02705 2.80833C6.09742 2.76056 6.29861 2.98051 6.40556 3.22866C6.35577 3.30585 6.29836 3.39515 6.23331 3.49656C6.28663 3.42402 6.35866 3.36229 6.4494 3.31135C6.41153 3.4062 6.53958 3.64401 6.53756 3.70188C6.53621 3.74045 6.46499 3.84084 6.32389 4.00305C6.37057 3.95973 6.43456 3.89748 6.51585 3.81627L6.5342 3.79791C6.62749 4.28526 6.67419 5.07344 6.62857 5.24231C6.58296 5.41118 6.47752 5.43314 6.36295 5.78672C6.41672 5.70223 6.48999 5.60469 6.58279 5.49409C6.56924 5.67543 6.5155 5.84509 6.42155 6.00306C6.28515 6.23245 6.15781 6.3692 6.14189 6.60455C6.14189 6.60455 6.12212 6.9824 6.12212 7.17133C6.12212 7.29506 6.12745 7.85721 6.12745 7.85721L6.01797 7.84927C6.01797 7.84927 6.02327 7.28441 6.02327 7.15963C6.02328 6.95984 6.00351 6.56028 6.00351 6.56028C5.99786 6.53894 5.9919 6.51859 5.98561 6.49921C5.91521 6.28227 5.68107 6.10065 5.60764 5.87005C5.55869 5.71632 5.50766 5.54313 5.45455 5.35049C5.53726 5.37292 5.61969 5.42484 5.70183 5.50627C5.64749 5.34805 5.50958 5.15552 5.42969 4.95325C5.50406 4.5694 5.46533 3.93255 5.62137 3.62607C5.67917 3.21978 5.95669 2.8561 6.02705 2.80833Z"
              fill="url(#paint32_linear_12072_7292)"
            />
            <mask
              id="mask1_12072_7292"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="2"
              width="2"
              height="6"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.02705 2.80833C6.09742 2.76056 6.29861 2.98051 6.40556 3.22866C6.35577 3.30585 6.29836 3.39515 6.23331 3.49656C6.28663 3.42402 6.35866 3.36229 6.4494 3.31135C6.41153 3.4062 6.53958 3.64401 6.53756 3.70188C6.53621 3.74045 6.46499 3.84084 6.32389 4.00305C6.37057 3.95973 6.43456 3.89748 6.51585 3.81627L6.5342 3.79791C6.62749 4.28526 6.67419 5.07344 6.62857 5.24231C6.58296 5.41118 6.47752 5.43314 6.36295 5.78672C6.41672 5.70223 6.48999 5.60469 6.58279 5.49409C6.56924 5.67543 6.5155 5.84509 6.42155 6.00306C6.28515 6.23245 6.15781 6.3692 6.14189 6.60455C6.14189 6.60455 6.12212 6.9824 6.12212 7.17133C6.12212 7.29506 6.12745 7.85721 6.12745 7.85721L6.01797 7.84927C6.01797 7.84927 6.02327 7.28441 6.02327 7.15963C6.02328 6.95984 6.00351 6.56028 6.00351 6.56028C5.99786 6.53894 5.9919 6.51859 5.98561 6.49921C5.91521 6.28227 5.68107 6.10065 5.60764 5.87005C5.55869 5.71632 5.50766 5.54313 5.45455 5.35049C5.53726 5.37292 5.61969 5.42484 5.70183 5.50627C5.64749 5.34805 5.50958 5.15552 5.42969 4.95325C5.50406 4.5694 5.46533 3.93255 5.62137 3.62607C5.67917 3.21978 5.95669 2.8561 6.02705 2.80833Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_12072_7292)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.64608 4.9203C6.64608 5.21248 6.38575 5.52765 6.36279 5.78639C6.53741 5.503 6.58937 5.39797 6.67221 5.35335C6.75505 5.30872 6.64608 4.62812 6.64608 4.9203Z"
                fill="url(#paint33_linear_12072_7292)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.70192 5.50619L5.76953 5.59289C5.76953 5.59289 5.7198 5.41104 5.66127 5.3294C5.60274 5.24776 5.45857 5.01269 5.42977 4.95316C5.40098 4.89363 5.39746 5.06036 5.39746 5.06036L5.70192 5.50619Z"
                fill="url(#paint34_linear_12072_7292)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.51971 3.61988C6.53729 3.66847 6.49961 3.74737 6.42832 3.85124C6.35703 3.95511 6.25439 4.07355 6.25439 4.07355L6.32409 4.00313L6.56938 3.74161L6.6169 3.63919C6.6169 3.63919 6.50213 3.57129 6.51971 3.61988Z"
                fill="url(#paint35_linear_12072_7292)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.25418 3.47136L6.17871 3.56325C6.17871 3.56325 6.31229 3.3529 6.33436 3.28528C6.35642 3.21766 6.39474 3.17283 6.36455 3.07835C6.33436 2.98387 6.49524 3.22509 6.49524 3.22509L6.32077 3.38738L6.25418 3.47136Z"
                fill="url(#paint36_linear_12072_7292)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.99463 8.03494C5.99463 8.03494 6.01833 7.4347 6.02287 7.1676C6.02948 6.7794 6.05111 3.02832 6.05111 3.02832C6.05111 3.02832 6.12267 6.39092 6.12267 7.19245C6.12267 7.99397 6.30014 8.15712 6.30014 8.15712L5.99463 8.09616L5.99463 8.03494Z"
                fill="#EAC893"
              />
            </g>
          </g>
          <g filter="url(#filter8_d_12072_7292)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.0627 7.86453C6.04549 7.85913 6.02779 7.85359 6.01267 7.90164C6.01267 8.21589 5.96631 8.34321 5.96631 8.34321C5.96631 8.34321 6.08123 8.51567 6.08123 8.61745C6.08123 8.51876 6.19615 8.34321 6.19615 8.34321C6.19615 8.34321 6.14979 8.18732 6.14979 7.90164C6.13294 7.84813 6.11622 7.85503 6.09817 7.86248C6.09266 7.86475 6.08703 7.86708 6.08123 7.86775C6.07522 7.86845 6.06899 7.8665 6.0627 7.86453ZM6.10845 8.1313C6.10845 8.14708 6.09566 8.15987 6.07988 8.15987C6.0641 8.15987 6.05131 8.14708 6.05131 8.1313C6.05131 8.11553 6.0641 8.10274 6.07988 8.10274C6.09566 8.10274 6.10845 8.11553 6.10845 8.1313Z"
              fill="url(#paint37_linear_12072_7292)"
            />
            <mask
              id="mask2_12072_7292"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="7"
              width="2"
              height="2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.0627 7.86453C6.04549 7.85913 6.02779 7.85359 6.01267 7.90164C6.01267 8.21589 5.96631 8.34321 5.96631 8.34321C5.96631 8.34321 6.08123 8.51567 6.08123 8.61745C6.08123 8.51876 6.19615 8.34321 6.19615 8.34321C6.19615 8.34321 6.14979 8.18732 6.14979 7.90164C6.13294 7.84813 6.11622 7.85503 6.09817 7.86248C6.09266 7.86475 6.08703 7.86708 6.08123 7.86775C6.07522 7.86845 6.06899 7.8665 6.0627 7.86453ZM6.10845 8.1313C6.10845 8.14708 6.09566 8.15987 6.07988 8.15987C6.0641 8.15987 6.05131 8.14708 6.05131 8.1313C6.05131 8.11553 6.0641 8.10274 6.07988 8.10274C6.09566 8.10274 6.10845 8.11553 6.10845 8.1313Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_12072_7292)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.08105 7.90137H6.40101V8.61718H6.08105V7.90137Z"
                fill="url(#paint38_linear_12072_7292)"
              />
              <rect
                opacity="0.6"
                x="5.90967"
                y="7.84473"
                width="0.34281"
                height="0.11427"
                fill="url(#paint39_linear_12072_7292)"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.08123 8.52537C6.10277 8.43668 6.19615 8.34375 6.19615 8.34375C6.19615 8.34375 6.08123 8.51931 6.08123 8.618C6.08123 8.51622 5.96631 8.34375 5.96631 8.34375C5.96631 8.34375 6.0562 8.43768 6.08123 8.52537Z"
              fill="url(#paint40_linear_12072_7292)"
            />
            <mask
              id="mask3_12072_7292"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="2"
              height="1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.08123 8.52537C6.10277 8.43668 6.19615 8.34375 6.19615 8.34375C6.19615 8.34375 6.08123 8.51931 6.08123 8.618C6.08123 8.51622 5.96631 8.34375 5.96631 8.34375C5.96631 8.34375 6.0562 8.43768 6.08123 8.52537Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask3_12072_7292)"></g>
          </g>
          <g filter="url(#filter9_d_12072_7292)">
            <rect
              x="5.96387"
              y="7.80469"
              width="0.217469"
              height="0.112971"
              rx="0.0564855"
              fill="url(#paint41_linear_12072_7292)"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.9707 7.83384C5.9707 7.82098 5.98113 7.81055 5.994 7.81055H6.14747C6.16033 7.81055 6.17076 7.82098 6.17076 7.83384C6.17076 7.8467 6.16033 7.85713 6.14747 7.85713H5.994C5.98113 7.85713 5.9707 7.8467 5.9707 7.83384Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_12072_7292"
          x="1.88778"
          y="5.49715"
          width="4.31185"
          height="6.26156"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7 0 0 0 0 0.561568 0 0 0 0 0.32375 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12072_7292"
          x="5.92635"
          y="5.49715"
          width="4.31185"
          height="6.26156"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7 0 0 0 0 0.561568 0 0 0 0 0.32375 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_12072_7292"
          x="2.98983"
          y="5.27156"
          width="6.13216"
          height="7.71078"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7 0 0 0 0 0.561568 0 0 0 0 0.32375 0 0 0 0.78 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_12072_7292"
          x="1.51691"
          y="1.89844"
          width="9.07752"
          height="9.76719"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.34465" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.4375 0 0 0 0 0.287401 0 0 0 0 0.111198 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_f_12072_7292"
          x="2.9276"
          y="2.38317"
          width="6.24782"
          height="6.42897"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0252121"
            result="effect1_foregroundBlur_12072_7292"
          />
        </filter>
        <filter
          id="filter5_i_12072_7292"
          x="3.31641"
          y="2.78027"
          width="5.50732"
          height="5.64551"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.1302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.458333 0 0 0 0 0.256743 0 0 0 0 0.0706597 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_7292" />
        </filter>
        <filter
          id="filter6_d_12072_7292"
          x="4.75736"
          y="2.80176"
          width="2.56096"
          height="7.16105"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.672323" />
          <feGaussianBlur stdDeviation="0.336162" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.632621 0 0 0 0 0.441024 0 0 0 0.47 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_12072_7292"
          x="4.27477"
          y="2.1939"
          width="3.64772"
          height="7.48708"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.519745 0 0 0 0 0.195642 0 0 0 0.42 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_12072_7292"
          x="4.81139"
          y="7.24957"
          width="2.6614"
          height="3.19216"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.519745 0 0 0 0 0.195642 0 0 0 0.42 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_12072_7292"
          x="4.80894"
          y="7.19683"
          width="2.6487"
          height="2.5447"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0607854" dy="0.607854" />
          <feGaussianBlur stdDeviation="0.607854" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.795833 0 0 0 0 0.519745 0 0 0 0 0.195642 0 0 0 0.42 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7292"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7292"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_7292"
          x1="1.55113"
          y1="3.97892"
          x2="0.770685"
          y2="2.81983"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2A86B" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_7292"
          x1="0.330149"
          y1="2.58024"
          x2="1.02876"
          y2="3.90491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#F5E3C4" />
          <stop offset="0.480868" stop-color="#E6CA9E" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_7292"
          x1="2.12701"
          y1="5.15551"
          x2="1.34656"
          y2="3.99641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C08E" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12072_7292"
          x1="0.856067"
          y1="3.71912"
          x2="2.42684"
          y2="5.58387"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#FFF5DE" />
          <stop offset="0.480868" stop-color="#EAD2A9" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12072_7292"
          x1="2.88238"
          y1="6.35863"
          x2="2.10193"
          y2="5.19954"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C08E" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_7292"
          x1="1.61144"
          y1="4.92224"
          x2="3.18222"
          y2="6.78699"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#FFF5DE" />
          <stop offset="0.480868" stop-color="#EAD2A9" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_7292"
          x1="3.31641"
          y1="9.20676"
          x2="4.8097"
          y2="9.54416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8D664F" />
          <stop offset="0.208702" stop-color="#C49F7D" />
          <stop offset="0.648432" stop-color="#FFF1DC" />
          <stop offset="0.827985" stop-color="#B38368" />
          <stop offset="0.971703" stop-color="#8C5F4C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_7292"
          x1="3.49978"
          y1="9.15654"
          x2="4.03994"
          y2="8.99551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C7A383" />
          <stop offset="0.309893" stop-color="#D5B198" />
          <stop offset="0.523799" stop-color="#CBA68C" />
          <stop offset="0.77229" stop-color="#BB9478" />
          <stop offset="1" stop-color="#D7B49B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_7292"
          x1="8.80957"
          y1="9.20676"
          x2="7.31628"
          y2="9.54416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8D664F" />
          <stop offset="0.208702" stop-color="#C49F7D" />
          <stop offset="0.648432" stop-color="#FFF1DC" />
          <stop offset="0.827985" stop-color="#B38368" />
          <stop offset="0.971703" stop-color="#8C5F4C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_7292"
          x1="8.62619"
          y1="9.15654"
          x2="8.08603"
          y2="8.99551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C7A383" />
          <stop offset="0.309893" stop-color="#D5B198" />
          <stop offset="0.523799" stop-color="#CBA68C" />
          <stop offset="0.77229" stop-color="#BB9478" />
          <stop offset="1" stop-color="#D7B49B" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_7292"
          x1="10.5553"
          y1="4.01603"
          x2="11.3358"
          y2="2.85693"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2A86B" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_7292"
          x1="11.7763"
          y1="2.61735"
          x2="11.0777"
          y2="3.94202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#F5E3C4" />
          <stop offset="0.480868" stop-color="#E6CA9E" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_7292"
          x1="9.97944"
          y1="5.19262"
          x2="10.7599"
          y2="4.03352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C08E" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_7292"
          x1="11.2504"
          y1="3.75623"
          x2="9.6796"
          y2="5.62098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#FFF5DE" />
          <stop offset="0.480868" stop-color="#EAD2A9" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_7292"
          x1="9.22407"
          y1="6.39672"
          x2="10.0045"
          y2="5.23762"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C08E" />
          <stop offset="0.875" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_7292"
          x1="10.495"
          y1="4.96033"
          x2="8.92423"
          y2="6.82508"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="0.378992" stop-color="#FFF5DE" />
          <stop offset="0.480868" stop-color="#EAD2A9" />
          <stop offset="0.729174" stop-color="#D2A86B" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_7292"
          x1="6.05579"
          y1="8.87414"
          x2="6.05579"
          y2="11.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B58D70" />
          <stop offset="0.2286" stop-color="#B58D70" />
          <stop offset="0.47675" stop-color="#D8B59B" />
          <stop offset="0.88344" stop-color="#B58D70" />
          <stop offset="1" stop-color="#B58D70" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_7292"
          x1="4.50244"
          y1="10.018"
          x2="7.5067"
          y2="11.0406"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8D664F" />
          <stop offset="0.208702" stop-color="#FFF5E6" />
          <stop offset="0.648432" stop-color="#ECD8BA" />
          <stop offset="0.780206" stop-color="#D0A568" />
          <stop offset="0.971703" stop-color="#8C5F4C" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12072_7292"
          x1="6.02749"
          y1="8.36621"
          x2="6.02749"
          y2="11.4417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DDBFA9" />
          <stop offset="0.22229" stop-color="#DDBFA9" />
          <stop offset="0.669825" stop-color="#EBD4C4" />
          <stop offset="0.898796" stop-color="#DDBFA9" />
          <stop offset="1" stop-color="#DDBFA9" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_12072_7292"
          x1="7.30905"
          y1="10.3805"
          x2="6.17573"
          y2="9.5024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#ECD8BA" />
          <stop offset="0.309893" stop-color="#EBD3AE" />
          <stop offset="0.523799" stop-color="#E2C69C" />
          <stop offset="0.77229" stop-color="#E3C8A0" />
          <stop offset="1" stop-color="#ECD8BA" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_12072_7292"
          x1="6.04157"
          y1="10.7383"
          x2="6.04157"
          y2="11.217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAECD8" />
          <stop offset="0.491891" stop-color="#F9EBD7" />
          <stop offset="1" stop-color="#ECD5B4" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12072_7292"
          x1="5.93647"
          y1="10.75"
          x2="5.93647"
          y2="11.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9EBD7" />
          <stop offset="0.479167" stop-color="#FAEDD9" />
          <stop offset="1" stop-color="#F8E9D4" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12072_7292"
          x1="6.14703"
          y1="10.75"
          x2="6.14703"
          y2="11.132"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAEDD8" />
          <stop offset="0.479167" stop-color="#FAECD8" />
          <stop offset="1" stop-color="#EAD4B2" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12072_7292"
          x1="6.01189"
          y1="11.1593"
          x2="5.69343"
          y2="11.197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E5CBA6" />
          <stop offset="0.309893" stop-color="#FAEDDA" />
          <stop offset="0.523799" stop-color="#F3E2C9" />
          <stop offset="0.613641" stop-color="#EFDCC0" />
          <stop offset="1" stop-color="#EFDCC0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12072_7292"
          x1="4.57299"
          y1="2.49143"
          x2="7.7503"
          y2="8.80368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF5DE" />
          <stop offset="1" stop-color="#CFA365" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_12072_7292"
          x1="4.54475"
          y1="3.01367"
          x2="7.2843"
          y2="8.06912"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2A86C" />
          <stop offset="1" stop-color="#F8E6C0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_12072_7292"
          x1="4.8413"
          y1="2.83009"
          x2="7.79267"
          y2="8.13972"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF6E7" />
          <stop offset="1" stop-color="#FFF8EC" />
        </linearGradient>
        <radialGradient
          id="paint27_radial_12072_7292"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.07007 5.60319) rotate(90) scale(3.19694 2.75367)"
        >
          <stop stop-color="#FFE4BA" />
          <stop offset="1" stop-color="#DEA44D" />
        </radialGradient>
        <linearGradient
          id="paint28_linear_12072_7292"
          x1="6.06972"
          y1="2.2793"
          x2="6.06972"
          y2="8.92608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_12072_7292"
          x1="2.67015"
          y1="2.88115"
          x2="5.99354"
          y2="8.63743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_12072_7292"
          x1="9.22191"
          y1="2.88057"
          x2="5.89852"
          y2="8.63685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_12072_7292"
          x1="6.07007"
          y1="6.77357"
          x2="6.05346"
          y2="8.57766"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#FFF1E3" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_12072_7292"
          x1="5.94161"
          y1="1.53452"
          x2="6.84756"
          y2="1.60077"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_12072_7292"
          x1="6.50607"
          y1="4.61234"
          x2="6.75793"
          y2="4.64011"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_12072_7292"
          x1="5.55403"
          y1="4.77321"
          x2="5.82453"
          y2="4.81988"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_12072_7292"
          x1="6.40694"
          y1="3.48081"
          x2="6.66442"
          y2="3.54066"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_12072_7292"
          x1="6.31191"
          y1="2.92354"
          x2="6.54079"
          y2="2.96659"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_12072_7292"
          x1="6.06302"
          y1="7.66691"
          x2="6.23366"
          y2="7.68259"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_12072_7292"
          x1="6.21569"
          y1="7.72194"
          x2="6.45089"
          y2="7.75389"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_12072_7292"
          x1="6.05392"
          y1="7.81608"
          x2="6.19407"
          y2="7.94386"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_12072_7292"
          x1="6.06302"
          y1="8.275"
          x2="6.22462"
          y2="8.31616"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_12072_7292"
          x1="6.05538"
          y1="7.77637"
          x2="6.17667"
          y2="7.84733"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#FDF2D9" />
          <stop offset="0.304755" stop-color="#FFF7E3" />
          <stop offset="0.451944" stop-color="#FFFCF4" />
          <stop offset="0.581036" stop-color="#FFF1D9" />
          <stop offset="0.705947" stop-color="#F3E0BF" />
          <stop offset="0.940592" stop-color="#FFF5DE" />
        </linearGradient>
        <clipPath id="clip0_12072_7292">
          <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  ),

  platinum_phoenix: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_12072_7464)">
        <path
          d="M2.81738 4.62305L2.81738 3.4299L0.000232234 3.4299C0.000232225 4.17562 0.527418 4.62305 0.955757 4.62305L2.81738 4.62305Z"
          fill="url(#paint0_linear_12072_7464)"
        />
        <path
          d="M2.81738 4.62305L2.81738 3.4299L0.000232234 3.4299C0.000232225 4.17562 0.527418 4.62305 0.955757 4.62305L2.81738 4.62305Z"
          stroke="url(#paint1_linear_12072_7464)"
          stroke-width="0.385597"
        />
        <path
          d="M3.81152 5.68359L3.81152 4.49045L0.994373 4.49045C0.994373 5.23616 1.52156 5.68359 1.9499 5.68359L3.81152 5.68359Z"
          fill="url(#paint2_linear_12072_7464)"
        />
        <path
          d="M3.81152 5.68359L3.81152 4.49045L0.994373 4.49045C0.994373 5.23616 1.52156 5.68359 1.9499 5.68359L3.81152 5.68359Z"
          stroke="url(#paint3_linear_12072_7464)"
          stroke-width="0.385597"
        />
        <path
          d="M9.02393 4.62305L9.02393 3.4299L11.8411 3.4299C11.8411 4.17562 11.3139 4.62305 10.8856 4.62305L9.02393 4.62305Z"
          fill="url(#paint4_linear_12072_7464)"
        />
        <path
          d="M9.02393 4.62305L9.02393 3.4299L11.8411 3.4299C11.8411 4.17562 11.3139 4.62305 10.8856 4.62305L9.02393 4.62305Z"
          stroke="url(#paint5_linear_12072_7464)"
          stroke-width="0.385597"
        />
        <path
          d="M8.02979 5.68359L8.02979 4.49045L10.8469 4.49045C10.8469 5.23616 10.3197 5.68359 9.89141 5.68359L8.02979 5.68359Z"
          fill="url(#paint6_linear_12072_7464)"
        />
        <path
          d="M8.02979 5.68359L8.02979 4.49045L10.8469 4.49045C10.8469 5.23616 10.3197 5.68359 9.89141 5.68359L8.02979 5.68359Z"
          stroke="url(#paint7_linear_12072_7464)"
          stroke-width="0.385597"
        />
        <g filter="url(#filter0_d_12072_7464)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.30268 7.12793V9.69491L4.9507 10.0431C5.06269 10.1033 5.19837 10.0222 5.19837 9.89508C5.19843 8.99704 5.19922 8.00338 5.19922 7.12793H4.30268Z"
            fill="#9CB7ED"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.30268 7.12793V9.69491L4.9507 10.0431C5.06269 10.1033 5.19837 10.0222 5.19837 9.89508C5.19843 8.99704 5.19922 8.00338 5.19922 7.12793H4.30268Z"
            stroke="url(#paint8_linear_12072_7464)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.37688 9.57961V8.57227H4.3605V9.59326L4.36734 9.5944C4.38999 9.59818 4.41517 9.61157 4.43574 9.63116C4.45628 9.65073 4.47149 9.67576 4.47526 9.70216L4.47589 9.70654L5.13867 10.0459V8.90837H5.12229V10.0201L4.49075 9.69541C4.48536 9.66621 4.46842 9.63966 4.44704 9.6193C4.42652 9.59976 4.40131 9.58532 4.37688 9.57961Z"
          fill="url(#paint9_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.12143 9.95995C5.1391 9.93009 5.1401 9.88772 5.13874 9.86035L5.12237 9.86117C5.12374 9.88841 5.12229 9.92632 5.10733 9.95161C5.10008 9.96387 5.08981 9.9729 5.07504 9.97661C5.06001 9.98038 5.03924 9.97892 5.01066 9.9678L5.00472 9.98307C5.03512 9.9949 5.05954 9.99739 5.07903 9.9925C5.09878 9.98754 5.11237 9.97527 5.12143 9.95995Z"
          fill="#E3EFFB"
        />
        <g filter="url(#filter1_d_12072_7464)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.15869 9.63712V7.12793H3.1181V8.8159C3.1181 9.00896 3.22833 9.18507 3.40197 9.26944L4.15869 9.63712Z"
            fill="#C6D9FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.15869 9.63712V7.12793H3.1181V8.8159C3.1181 9.00896 3.22833 9.18507 3.40197 9.26944L4.15869 9.63712Z"
            stroke="url(#paint10_linear_12072_7464)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.19532 9.00996V7.82129H3.17599V9.02606L3.18407 9.02741C3.21079 9.03186 3.24051 9.04767 3.26478 9.07079C3.28902 9.09387 3.30696 9.12342 3.31141 9.15456L3.31215 9.15973L4.09424 9.52603V8.21789H4.07491V9.49563L3.32969 9.1466C3.32333 9.11214 3.30334 9.08082 3.27811 9.05679C3.2539 9.03373 3.22415 9.01669 3.19532 9.00996Z"
          fill="url(#paint11_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.0738 9.42514C4.09465 9.3899 4.09584 9.33991 4.09423 9.30762L4.07492 9.30858C4.07653 9.34073 4.07482 9.38546 4.05717 9.4153C4.04861 9.42977 4.03649 9.44042 4.01906 9.4448C4.00133 9.44925 3.97682 9.44753 3.94309 9.43441L3.93609 9.45243C3.97196 9.46638 4.00077 9.46932 4.02377 9.46355C4.04708 9.4577 4.06311 9.44322 4.0738 9.42514Z"
          fill="#F2F9FF"
        />
        <g filter="url(#filter2_d_12072_7464)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.56842 7.12793V9.69491L6.92039 10.0431C6.80841 10.1033 6.67273 10.0222 6.67272 9.89508C6.67266 8.99704 6.67188 8.00338 6.67188 7.12793H7.56842Z"
            fill="#9CB7ED"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.56842 7.12793V9.69491L6.92039 10.0431C6.80841 10.1033 6.67273 10.0222 6.67272 9.89508C6.67266 8.99704 6.67188 8.00338 6.67188 7.12793H7.56842Z"
            stroke="url(#paint12_linear_12072_7464)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.49421 9.57961V8.57227H7.5106V9.59326L7.50375 9.5944C7.4811 9.59818 7.45592 9.61157 7.43535 9.63116C7.41481 9.65073 7.3996 9.67576 7.39583 9.70216L7.39521 9.70654L6.73242 10.0459V8.90837H6.7488V10.0201L7.38034 9.69541C7.38573 9.66621 7.40267 9.63966 7.42406 9.6193C7.44457 9.59976 7.46979 9.58532 7.49421 9.57961Z"
          fill="url(#paint13_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.74967 9.95995C6.732 9.93009 6.73099 9.88772 6.73236 9.86035L6.74872 9.86117C6.74736 9.88841 6.74881 9.92632 6.76377 9.95161C6.77102 9.96387 6.78128 9.9729 6.79606 9.97661C6.81109 9.98038 6.83186 9.97892 6.86044 9.9678L6.86637 9.98307C6.83597 9.9949 6.81156 9.99739 6.79207 9.9925C6.77232 9.98754 6.75873 9.97527 6.74967 9.95995Z"
          fill="#E3EFFB"
        />
        <g filter="url(#filter3_d_12072_7464)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.7124 9.63712V7.12793H8.75299V8.8159C8.75299 9.00896 8.64277 9.18507 8.46912 9.26944L7.7124 9.63712Z"
            fill="#C6D9FF"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.7124 9.63712V7.12793H8.75299V8.8159C8.75299 9.00896 8.64277 9.18507 8.46912 9.26944L7.7124 9.63712Z"
            stroke="url(#paint14_linear_12072_7464)"
            stroke-width="0.168081"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.67577 9.00996V7.82129H8.6951V9.02606L8.68703 9.02741C8.6603 9.03186 8.63058 9.04767 8.60632 9.07079C8.58207 9.09387 8.56413 9.12342 8.55968 9.15456L8.55894 9.15973L7.77686 9.52603V8.21789H7.79619V9.49563L8.5414 9.1466C8.54776 9.11214 8.56775 9.08082 8.59298 9.05679C8.61719 9.03373 8.64695 9.01669 8.67577 9.00996Z"
          fill="url(#paint15_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.79729 9.42514C7.77644 9.3899 7.77525 9.33991 7.77687 9.30762L7.79618 9.30858C7.79457 9.34073 7.79628 9.38546 7.81393 9.4153C7.82249 9.42977 7.8346 9.44042 7.85203 9.4448C7.86977 9.44925 7.89428 9.44753 7.928 9.43441L7.93501 9.45243C7.89913 9.46638 7.87032 9.46932 7.84732 9.46355C7.82402 9.4577 7.80798 9.44322 7.79729 9.42514Z"
          fill="#F2F9FF"
        />
        <g filter="url(#filter4_d_12072_7464)">
          <path
            d="M4.93848 6.37695H6.93215V10.413L6.17523 10.8748C6.02015 10.9694 5.82518 10.9693 5.67022 10.8745L4.93848 10.4269V6.37695Z"
            fill="#6A88C4"
          />
          <path
            d="M4.93848 6.37695H6.93215V10.413L6.17523 10.8748C6.02015 10.9694 5.82518 10.9693 5.67022 10.8745L4.93848 10.4269V6.37695Z"
            stroke="url(#paint16_linear_12072_7464)"
            stroke-width="0.336162"
          />
        </g>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.03955 8.89062H5.06844V10.3417L5.92088 10.8674L6.78763 10.3416V8.89062H6.81652V10.3579L5.92075 10.9012L5.03955 10.3578V8.89062Z"
          fill="url(#paint17_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.93332 10.2773V10.8016H5.9082V10.2773H5.93332Z"
          fill="url(#paint18_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.84521 10.6157C5.79522 10.6733 5.73432 10.6862 5.69238 10.6792L5.69651 10.6544C5.72992 10.66 5.78204 10.6502 5.82624 10.5992C5.87061 10.548 5.90793 10.4542 5.90793 10.29H5.93305C5.93305 10.4575 5.89503 10.5582 5.84521 10.6157Z"
          fill="url(#paint19_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.9961 10.6157C6.04609 10.6733 6.10699 10.6862 6.14893 10.6792L6.1448 10.6544C6.11139 10.66 6.05927 10.6502 6.01507 10.5992C5.9707 10.548 5.93338 10.4542 5.93338 10.29H5.90826C5.90826 10.4575 5.94628 10.5582 5.9961 10.6157Z"
          fill="url(#paint20_linear_12072_7464)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.91543 10.8133L5.63184 10.6544L5.69463 10.6544L5.92069 10.7881L6.14671 10.6533H6.20213L5.92586 10.8134C5.92255 10.8149 5.91874 10.8149 5.91543 10.8133Z"
          fill="url(#paint21_linear_12072_7464)"
        />
        <g filter="url(#filter5_d_12072_7464)">
          <path
            d="M4.55539 1.74571L3.80103 2.15607C2.88149 2.65629 2.30908 3.61929 2.30908 4.66609V5.73358C2.30908 6.78038 2.88149 7.74338 3.80103 8.2436L4.55539 8.65396C5.40676 9.1171 6.43486 9.1171 7.28624 8.65396L8.0406 8.2436C8.96014 7.74338 9.53254 6.78038 9.53254 5.73358L9.53254 4.66609C9.53255 3.61929 8.96014 2.65629 8.0406 2.15607L7.28624 1.74571C6.43486 1.28257 5.40676 1.28257 4.55539 1.74571Z"
            fill="url(#paint22_linear_12072_7464)"
          />
          <path
            d="M4.55604 2.37865L4.34425 2.49387C3.42816 2.99221 2.85791 3.95159 2.85791 4.99444V5.39079C2.85791 6.43365 3.42816 7.39302 4.34424 7.89136L4.55604 8.00658C5.40421 8.46797 6.42845 8.46797 7.27662 8.00658L7.48841 7.89136C8.4045 7.39302 8.97475 6.43365 8.97475 5.39079V4.99444C8.97475 3.95159 8.4045 2.99221 7.48841 2.49387L7.27662 2.37865C6.42845 1.91726 5.40421 1.91726 4.55604 2.37865Z"
            fill="url(#paint23_linear_12072_7464)"
          />
          <g filter="url(#filter6_f_12072_7464)">
            <path
              d="M4.55604 2.37865L4.34425 2.49387C3.42816 2.99221 2.85791 3.95159 2.85791 4.99444V5.39079C2.85791 6.43365 3.42816 7.39302 4.34424 7.89136L4.55604 8.00658C5.40421 8.46797 6.42845 8.46797 7.27662 8.00658L7.48841 7.89136C8.4045 7.39302 8.97475 6.43365 8.97475 5.39079V4.99444C8.97475 3.95159 8.4045 2.99221 7.48841 2.49387L7.27662 2.37865C6.42845 1.91726 5.40421 1.91726 4.55604 2.37865Z"
              stroke="url(#paint24_linear_12072_7464)"
              stroke-width="0.168081"
            />
          </g>
          <g opacity="0.5">
            <mask
              id="mask0_12072_7464"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="3"
              y="2"
              width="6"
              height="7"
            >
              <path
                d="M4.64228 2.6305L4.53289 2.68995C3.661 3.16383 3.11816 4.07668 3.11816 5.06903V5.32772C3.11816 6.32007 3.66099 7.23293 4.53289 7.7068L4.64228 7.76626C5.44861 8.20449 6.42202 8.20449 7.22835 7.76626L7.33774 7.7068C8.20964 7.23293 8.75247 6.32007 8.75247 5.32772V5.06904C8.75247 4.07668 8.20964 3.16383 7.33774 2.68995L7.22835 2.6305C6.42202 2.19226 5.44861 2.19226 4.64228 2.6305Z"
                fill="#A6D3DE"
              />
            </mask>
            <g mask="url(#mask0_12072_7464)">
              <path
                d="M4.64228 2.6305L4.53289 2.68995C3.661 3.16383 3.11816 4.07668 3.11816 5.06903V5.32772C3.11816 6.32007 3.66099 7.23293 4.53289 7.7068L4.64228 7.76626C5.44861 8.20449 6.42202 8.20449 7.22835 7.76626L7.33774 7.7068C8.20964 7.23293 8.75247 6.32007 8.75247 5.32772V5.06904C8.75247 4.07668 8.20964 3.16383 7.33774 2.68995L7.22835 2.6305C6.42202 2.19226 5.44861 2.19226 4.64228 2.6305Z"
                fill="url(#paint25_radial_12072_7464)"
              />
              <path
                opacity="0.5"
                d="M5.46445 8.59787L5.35254 1.79785H6.51826L6.40635 8.59787H5.46445Z"
                fill="url(#paint26_linear_12072_7464)"
              />
              <path
                opacity="0.5"
                d="M5.44956 8.53714L1.95264 2.7041L2.96218 2.12124L6.26527 8.06619L5.44956 8.53714Z"
                fill="url(#paint27_linear_12072_7464)"
              />
              <path
                opacity="0.5"
                d="M5.35267 8.06701L8.65576 2.12207L9.6653 2.70493L6.16838 8.53796L5.35267 8.06701Z"
                fill="url(#paint28_linear_12072_7464)"
              />
              <rect
                x="3.11816"
                y="4.80957"
                width="5.6343"
                height="3.65906"
                fill="url(#paint29_linear_12072_7464)"
              />
              <g filter="url(#filter7_i_12072_7464)">
                <path
                  d="M4.64228 2.6305L4.53289 2.68995C3.661 3.16383 3.11816 4.07668 3.11816 5.06903V5.32772C3.11816 6.32007 3.66099 7.23293 4.53289 7.7068L4.64228 7.76626C5.44861 8.20449 6.42202 8.20449 7.22835 7.76626L7.33774 7.7068C8.20964 7.23293 8.75247 6.32007 8.75247 5.32772V5.06904C8.75247 4.07668 8.20964 3.16383 7.33774 2.68995L7.22835 2.6305C6.42202 2.19226 5.44861 2.19226 4.64228 2.6305Z"
                  fill="#6052B4"
                  fill-opacity="0.01"
                />
              </g>
            </g>
          </g>
        </g>
        <g filter="url(#filter8_d_12072_7464)">
          <g filter="url(#filter9_d_12072_7464)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.89874 2.33876C5.97072 2.28989 6.17655 2.51491 6.28597 2.76878C6.23503 2.84774 6.1763 2.9391 6.10975 3.04286C6.16429 2.96864 6.23798 2.90548 6.33081 2.85337C6.29208 2.9504 6.42307 3.1937 6.42101 3.2529C6.41963 3.29237 6.34676 3.39507 6.20241 3.56101C6.25017 3.51671 6.31563 3.45301 6.3988 3.36993L6.41758 3.35115C6.51302 3.84974 6.56079 4.65609 6.51412 4.82885C6.46746 5.00162 6.35959 5.02408 6.24238 5.38581C6.29738 5.29937 6.37235 5.19958 6.46728 5.08644C6.45342 5.27196 6.39844 5.44552 6.30233 5.60714C6.16278 5.84181 6.03251 5.98172 6.01621 6.2225C6.01621 6.2225 5.99599 6.60906 5.99599 6.80234C5.99599 6.92892 6.00144 7.50403 6.00144 7.50403L5.88944 7.49591C5.88944 7.49591 5.89487 6.91803 5.89487 6.79037C5.89487 6.58598 5.87465 6.17721 5.87465 6.17721C5.86887 6.15538 5.86277 6.13455 5.85634 6.11473C5.78431 5.89278 5.54478 5.70698 5.46966 5.47106C5.41957 5.31379 5.36736 5.13661 5.31303 4.93952C5.39765 4.96247 5.48198 5.01559 5.56602 5.0989C5.51042 4.93703 5.36933 4.74006 5.2876 4.53312C5.36369 4.14043 5.32406 3.4889 5.48369 3.17535C5.54284 2.75969 5.82675 2.38763 5.89874 2.33876Z"
              fill="url(#paint30_linear_12072_7464)"
            />
            <mask
              id="mask1_12072_7464"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="2"
              width="2"
              height="6"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.89874 2.33876C5.97072 2.28989 6.17655 2.51491 6.28597 2.76878C6.23503 2.84774 6.1763 2.9391 6.10975 3.04286C6.16429 2.96864 6.23798 2.90548 6.33081 2.85337C6.29208 2.9504 6.42307 3.1937 6.42101 3.2529C6.41963 3.29237 6.34676 3.39507 6.20241 3.56101C6.25017 3.51671 6.31563 3.45301 6.3988 3.36993L6.41758 3.35115C6.51302 3.84974 6.56079 4.65609 6.51412 4.82885C6.46746 5.00162 6.35959 5.02408 6.24238 5.38581C6.29738 5.29937 6.37235 5.19958 6.46728 5.08644C6.45342 5.27196 6.39844 5.44552 6.30233 5.60714C6.16278 5.84181 6.03251 5.98172 6.01621 6.2225C6.01621 6.2225 5.99599 6.60906 5.99599 6.80234C5.99599 6.92892 6.00144 7.50403 6.00144 7.50403L5.88944 7.49591C5.88944 7.49591 5.89487 6.91803 5.89487 6.79037C5.89487 6.58598 5.87465 6.17721 5.87465 6.17721C5.86887 6.15538 5.86277 6.13455 5.85634 6.11473C5.78431 5.89278 5.54478 5.70698 5.46966 5.47106C5.41957 5.31379 5.36736 5.13661 5.31303 4.93952C5.39765 4.96247 5.48198 5.01559 5.56602 5.0989C5.51042 4.93703 5.36933 4.74006 5.2876 4.53312C5.36369 4.14043 5.32406 3.4889 5.48369 3.17535C5.54284 2.75969 5.82675 2.38763 5.89874 2.33876Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask1_12072_7464)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.532 4.5001C6.532 4.79901 6.26568 5.12145 6.24219 5.38616C6.42083 5.09623 6.47399 4.98878 6.55874 4.94313C6.64348 4.89747 6.532 4.20119 6.532 4.5001Z"
                fill="url(#paint31_linear_12072_7464)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.56587 5.0984L5.63504 5.1871C5.63504 5.1871 5.58416 5.00105 5.52429 4.91753C5.46441 4.83401 5.31691 4.59353 5.28745 4.53263C5.25799 4.47172 5.25439 4.64229 5.25439 4.64229L5.56587 5.0984Z"
                fill="url(#paint32_linear_12072_7464)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.40278 3.16917C6.42077 3.21888 6.38221 3.2996 6.30928 3.40587C6.23635 3.51213 6.13135 3.6333 6.13135 3.6333L6.20265 3.56126L6.4536 3.29371L6.50221 3.18893C6.50221 3.18893 6.38479 3.11946 6.40278 3.16917Z"
                fill="url(#paint33_linear_12072_7464)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.13092 3.01717L6.05371 3.11117C6.05371 3.11117 6.19037 2.89598 6.21294 2.8268C6.23552 2.75762 6.27472 2.71175 6.24383 2.61509C6.21294 2.51844 6.37754 2.76522 6.37754 2.76522L6.19904 2.93125L6.13092 3.01717Z"
                fill="url(#paint34_linear_12072_7464)"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.86572 7.68649C5.86572 7.68649 5.88997 7.07242 5.89462 6.79916C5.90138 6.40201 5.92351 2.56445 5.92351 2.56445C5.92351 2.56445 5.99671 6.00458 5.99671 6.82458C5.99671 7.64459 6.17827 7.81149 6.17827 7.81149L5.86572 7.74913L5.86572 7.68649Z"
                fill="#C8CAD5"
              />
            </g>
          </g>
          <g filter="url(#filter10_d_12072_7464)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.93504 7.51117C5.91743 7.50566 5.89933 7.49999 5.88385 7.54915C5.88385 7.87063 5.83643 8.00089 5.83643 8.00089C5.83643 8.00089 5.95399 8.17733 5.95399 8.28146C5.95399 8.18049 6.07156 8.00089 6.07156 8.00089C6.07156 8.00089 6.02414 7.84141 6.02414 7.54915C6.0069 7.4944 5.98979 7.50146 5.97133 7.50908C5.96569 7.51141 5.95993 7.51379 5.95399 7.51447C5.94784 7.51519 5.94147 7.51319 5.93504 7.51117ZM5.98183 7.78398C5.98183 7.80012 5.96874 7.81321 5.9526 7.81321C5.93646 7.81321 5.92338 7.80012 5.92338 7.78398C5.92338 7.76784 5.93646 7.75475 5.9526 7.75475C5.96874 7.75475 5.98183 7.76784 5.98183 7.78398Z"
              fill="url(#paint35_linear_12072_7464)"
            />
            <mask
              id="mask2_12072_7464"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="7"
              width="2"
              height="2"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.93504 7.51117C5.91743 7.50566 5.89933 7.49999 5.88385 7.54915C5.88385 7.87063 5.83643 8.00089 5.83643 8.00089C5.83643 8.00089 5.95399 8.17733 5.95399 8.28146C5.95399 8.18049 6.07156 8.00089 6.07156 8.00089C6.07156 8.00089 6.02414 7.84141 6.02414 7.54915C6.0069 7.4944 5.98979 7.50146 5.97133 7.50908C5.96569 7.51141 5.95993 7.51379 5.95399 7.51447C5.94784 7.51519 5.94147 7.51319 5.93504 7.51117ZM5.98183 7.78398C5.98183 7.80012 5.96874 7.81321 5.9526 7.81321C5.93646 7.81321 5.92338 7.80012 5.92338 7.78398C5.92338 7.76784 5.93646 7.75475 5.9526 7.75475C5.96874 7.75475 5.98183 7.76784 5.98183 7.78398Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_12072_7464)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.9541 7.54883H6.28143V8.28114H5.9541V7.54883Z"
                fill="url(#paint36_linear_12072_7464)"
              />
              <rect
                opacity="0.6"
                x="5.77881"
                y="7.49023"
                width="0.350714"
                height="0.116905"
                fill="url(#paint37_linear_12072_7464)"
              />
            </g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.95399 8.18678C5.97604 8.09605 6.07156 8.00098 6.07156 8.00098C6.07156 8.00098 5.95399 8.18058 5.95399 8.28155C5.95399 8.17742 5.83643 8.00098 5.83643 8.00098C5.83643 8.00098 5.92839 8.09707 5.95399 8.18678Z"
              fill="url(#paint38_linear_12072_7464)"
            />
            <mask
              id="mask3_12072_7464"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="5"
              y="8"
              width="2"
              height="1"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.95399 8.18678C5.97604 8.09605 6.07156 8.00098 6.07156 8.00098C6.07156 8.00098 5.95399 8.18058 5.95399 8.28155C5.95399 8.17742 5.83643 8.00098 5.83643 8.00098C5.83643 8.00098 5.92839 8.09707 5.95399 8.18678Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask3_12072_7464)"></g>
          </g>
          <g filter="url(#filter11_d_12072_7464)">
            <rect
              x="5.83398"
              y="7.4502"
              width="0.222483"
              height="0.115575"
              rx="0.0577877"
              fill="url(#paint39_linear_12072_7464)"
            />
          </g>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.84131 7.47988C5.84131 7.46672 5.85198 7.45605 5.86514 7.45605H6.02215C6.03531 7.45605 6.04598 7.46672 6.04598 7.47988C6.04598 7.49305 6.03531 7.50371 6.02215 7.50371H5.86514C5.85198 7.50371 5.84131 7.49305 5.84131 7.47988Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_12072_7464"
          x="2.8741"
          y="5.6993"
          width="3.75375"
          height="5.79281"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.288889 0 0 0 0 0.357333 0 0 0 0 0.533333 0 0 0 0.74 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12072_7464"
          x="1.68953"
          y="5.6993"
          width="3.89779"
          height="5.41683"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.288889 0 0 0 0 0.357333 0 0 0 0 0.533333 0 0 0 0.74 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_12072_7464"
          x="5.24324"
          y="5.6993"
          width="3.75375"
          height="5.79281"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.288889 0 0 0 0 0.357333 0 0 0 0 0.533333 0 0 0 0.74 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_12072_7464"
          x="6.28377"
          y="5.6993"
          width="3.89779"
          height="5.41683"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.288889 0 0 0 0 0.357333 0 0 0 0 0.533333 0 0 0 0.74 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_12072_7464"
          x="3.42586"
          y="4.86434"
          width="5.01888"
          height="7.59359"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.672323" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.288889 0 0 0 0 0.357333 0 0 0 0 0.533333 0 0 0 0.8 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_12072_7464"
          x="1.3006"
          y="1.39844"
          width="9.2406"
          height="9.95567"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.34465" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.141181 0 0 0 0 0.190317 0 0 0 0 0.316667 0 0 0 0.51 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_f_12072_7464"
          x="2.7235"
          y="1.89782"
          width="6.38552"
          height="6.58913"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0252121"
            result="effect1_foregroundBlur_12072_7464"
          />
        </filter>
        <filter
          id="filter7_i_12072_7464"
          x="3.11816"
          y="2.30176"
          width="5.63428"
          height="5.79297"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.1302" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.297222 0 0 0 0 0.329917 0 0 0 0 0.445833 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_7464" />
        </filter>
        <filter
          id="filter8_d_12072_7464"
          x="4.61527"
          y="2.33203"
          width="2.58928"
          height="7.29387"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.672323" />
          <feGaussianBlur stdDeviation="0.336162" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.416875 0 0 0 0 0.431496 0 0 0 0 0.483333 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_12072_7464"
          x="4.32954"
          y="1.82779"
          width="3.2616"
          height="7.18885"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0504243" dy="0.504243" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.625387 0 0 0 0 0.624375 0 0 0 0 0.675 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_12072_7464"
          x="4.87836"
          y="6.99966"
          width="2.25232"
          height="2.79431"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0504243" dy="0.504243" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.625387 0 0 0 0 0.624375 0 0 0 0 0.675 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_12072_7464"
          x="4.87592"
          y="6.94595"
          width="2.23963"
          height="2.1322"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0504243" dy="0.504243" />
          <feGaussianBlur stdDeviation="0.504243" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.625387 0 0 0 0 0.624375 0 0 0 0 0.675 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7464"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7464"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_7464"
          x1="1.72367"
          y1="4.62305"
          x2="0.712805"
          y2="3.61219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3C5D1" />
          <stop offset="0.875" stop-color="#F9F9F9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_7464"
          x1="0.165947"
          y1="3.4299"
          x2="1.12709"
          y2="4.62305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="0.378992" stop-color="#F9F9F9" />
          <stop offset="0.480868" stop-color="#DFDFE6" />
          <stop offset="0.729174" stop-color="#B8BAC6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_7464"
          x1="2.41952"
          y1="5.53445"
          x2="1.40866"
          y2="4.49045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3C5D1" />
          <stop offset="0.875" stop-color="#F9F9F9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12072_7464"
          x1="1.14352"
          y1="4.49045"
          x2="1.65723"
          y2="5.53445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4F4F5" />
          <stop offset="0.306027" stop-color="#F4F4F5" />
          <stop offset="0.462087" stop-color="#E8E9EC" />
          <stop offset="0.682292" stop-color="#DFDFE6" />
          <stop offset="1" stop-color="#B8BAC6" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12072_7464"
          x1="10.1176"
          y1="4.62305"
          x2="11.1285"
          y2="3.61219"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3C5D1" />
          <stop offset="0.875" stop-color="#F9F9F9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_7464"
          x1="11.6754"
          y1="3.4299"
          x2="10.7142"
          y2="4.62305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F9F9F9" />
          <stop offset="0.378992" stop-color="#F9F9F9" />
          <stop offset="0.480868" stop-color="#DFDFE6" />
          <stop offset="0.729174" stop-color="#B8BAC6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_7464"
          x1="9.42179"
          y1="5.53445"
          x2="10.4326"
          y2="4.49045"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C3C5D1" />
          <stop offset="0.875" stop-color="#F9F9F9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_7464"
          x1="10.6978"
          y1="4.49045"
          x2="10.1841"
          y2="5.53445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4F4F5" />
          <stop offset="0.306027" stop-color="#F4F4F5" />
          <stop offset="0.462087" stop-color="#E8E9EC" />
          <stop offset="0.682292" stop-color="#DFDFE6" />
          <stop offset="1" stop-color="#B8BAC6" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_7464"
          x1="5.19922"
          y1="9.18033"
          x2="4.25441"
          y2="9.32657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91B1E3" />
          <stop offset="0.208702" stop-color="#F0F6FF" />
          <stop offset="0.514766" stop-color="#A4BFE9" />
          <stop offset="0.728274" stop-color="#8BADE0" />
          <stop offset="0.971703" stop-color="#D0DEF3" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_7464"
          x1="4.39722"
          y1="9.4048"
          x2="4.86554"
          y2="9.26519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFCFF" />
          <stop offset="0.309893" stop-color="#D7E6FC" />
          <stop offset="0.523799" stop-color="#C1D8F4" />
          <stop offset="0.77229" stop-color="#BBD6FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_7464"
          x1="3.0892"
          y1="8.57262"
          x2="4.20477"
          y2="9.8225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91B1E3" />
          <stop offset="0.208702" stop-color="#F0F6FF" />
          <stop offset="0.648432" stop-color="#A4BFE9" />
          <stop offset="0.780206" stop-color="#8BADE0" />
          <stop offset="0.971703" stop-color="#93B3E4" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_7464"
          x1="3.21932"
          y1="8.80368"
          x2="3.77194"
          y2="8.63894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFCFF" />
          <stop offset="0.309893" stop-color="#D7E6FC" />
          <stop offset="0.523799" stop-color="#C1D8F4" />
          <stop offset="0.77229" stop-color="#BBD6FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_7464"
          x1="6.67188"
          y1="9.18033"
          x2="7.61668"
          y2="9.32657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91B1E3" />
          <stop offset="0.208702" stop-color="#F0F6FF" />
          <stop offset="0.514766" stop-color="#A4BFE9" />
          <stop offset="0.728274" stop-color="#8BADE0" />
          <stop offset="0.971703" stop-color="#D0DEF3" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_7464"
          x1="7.47387"
          y1="9.4048"
          x2="7.00556"
          y2="9.26519"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFCFF" />
          <stop offset="0.309893" stop-color="#D7E6FC" />
          <stop offset="0.523799" stop-color="#C1D8F4" />
          <stop offset="0.77229" stop-color="#BBD6FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_7464"
          x1="8.7819"
          y1="8.57262"
          x2="7.66632"
          y2="9.8225"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91B1E3" />
          <stop offset="0.208702" stop-color="#F0F6FF" />
          <stop offset="0.648432" stop-color="#A4BFE9" />
          <stop offset="0.780206" stop-color="#8BADE0" />
          <stop offset="0.971703" stop-color="#93B3E4" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_7464"
          x1="8.65177"
          y1="8.80368"
          x2="8.09915"
          y2="8.63894"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFCFF" />
          <stop offset="0.309893" stop-color="#D7E6FC" />
          <stop offset="0.523799" stop-color="#C1D8F4" />
          <stop offset="0.77229" stop-color="#BBD6FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_7464"
          x1="4.93848"
          y1="9.6853"
          x2="6.99601"
          y2="10.1246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91B1E3" />
          <stop offset="0.208702" stop-color="#F0F6FF" />
          <stop offset="0.648432" stop-color="#A4BFE9" />
          <stop offset="0.780206" stop-color="#8BADE0" />
          <stop offset="0.971703" stop-color="#93B3E4" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_7464"
          x1="6.73267"
          y1="10.0493"
          x2="5.79303"
          y2="9.58966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFCFF" />
          <stop offset="0.309893" stop-color="#D7E6FC" />
          <stop offset="0.523799" stop-color="#C1D8F4" />
          <stop offset="0.77229" stop-color="#BBD6FF" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12072_7464"
          x1="5.92076"
          y1="10.2773"
          x2="5.92076"
          y2="10.7671"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6A88C3" />
          <stop offset="0.479167" stop-color="#E4EFFD" />
          <stop offset="1" stop-color="#E1ECFD" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_12072_7464"
          x1="5.81272"
          y1="10.29"
          x2="5.81272"
          y2="10.6809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E2F5FF" stop-opacity="0" />
          <stop offset="0.479167" stop-color="#B6CAFE" />
          <stop offset="1" stop-color="#CCD7FF" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_12072_7464"
          x1="6.02859"
          y1="10.29"
          x2="6.02859"
          y2="10.6809"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E2F5FF" stop-opacity="0" />
          <stop offset="0.479167" stop-color="#B6CAFE" />
          <stop offset="1" stop-color="#CCD7FF" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12072_7464"
          x1="5.89027"
          y1="10.7084"
          x2="5.56447"
          y2="10.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C9DEFC" />
          <stop offset="0.309893" stop-color="#ECF4FF" />
          <stop offset="0.523799" stop-color="#EAF6FF" />
          <stop offset="0.613641" stop-color="#CCE7FF" />
          <stop offset="1" stop-color="#CEE4F9" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12072_7464"
          x1="4.40389"
          y1="2.01421"
          x2="7.65445"
          y2="8.47199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#C2C4CD" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12072_7464"
          x1="4.37484"
          y1="2.54883"
          x2="7.17754"
          y2="7.72083"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CDCFD6" />
          <stop offset="1" stop-color="#EFEFF1" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12072_7464"
          x1="4.67822"
          y1="2.36102"
          x2="7.69763"
          y2="7.79306"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DDDFE2" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_12072_7464"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.93531 5.19838) rotate(90) scale(3.27064 2.81715)"
        >
          <stop stop-color="#F5F7FF" />
          <stop offset="1" stop-color="#B3B5C5" />
        </radialGradient>
        <linearGradient
          id="paint26_linear_12072_7464"
          x1="5.9354"
          y1="1.79785"
          x2="5.9354"
          y2="8.59787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_12072_7464"
          x1="2.45741"
          y1="2.41267"
          x2="5.85742"
          y2="8.30166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_12072_7464"
          x1="9.16053"
          y1="2.4135"
          x2="5.76052"
          y2="8.30249"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_12072_7464"
          x1="5.93531"
          y1="6.39624"
          x2="5.91832"
          y2="8.24192"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#D0DBFF" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_12072_7464"
          x1="5.81132"
          y1="1.03557"
          x2="6.73816"
          y2="1.10336"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_12072_7464"
          x1="6.38877"
          y1="4.18504"
          x2="6.64644"
          y2="4.21345"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_12072_7464"
          x1="5.41457"
          y1="4.34853"
          x2="5.69131"
          y2="4.39627"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_12072_7464"
          x1="6.28741"
          y1="3.0269"
          x2="6.55083"
          y2="3.08813"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_12072_7464"
          x1="6.18998"
          y1="2.45672"
          x2="6.42413"
          y2="2.50076"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_12072_7464"
          x1="5.93537"
          y1="7.309"
          x2="6.10994"
          y2="7.32504"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_12072_7464"
          x1="6.09184"
          y1="7.36526"
          x2="6.33247"
          y2="7.39795"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_12072_7464"
          x1="5.92639"
          y1="7.46093"
          x2="6.06977"
          y2="7.59166"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_12072_7464"
          x1="5.93537"
          y1="7.93065"
          x2="6.10069"
          y2="7.97275"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_12072_7464"
          x1="5.92761"
          y1="7.42122"
          x2="6.0517"
          y2="7.49382"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <clipPath id="clip0_12072_7464">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),

  mythical_raven: (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15136 6.68176C2.17157 6.69688 2.29473 6.98162 2.35378 7.1221L2.38868 7.28609C2.27607 7.20842 2.04011 7.03058 1.9971 6.94062C1.95409 6.85066 1.95572 6.63608 1.96191 6.54004L2.15136 6.68176Z"
        fill="url(#paint0_linear_12072_7934)"
      />
      <path
        d="M2.75894 2.92103C2.75794 2.78897 2.85114 2.64858 2.93925 2.47814C2.96617 2.47726 3.07407 2.65777 2.89082 3.2889L2.80588 3.48144C2.77869 3.60554 2.76169 3.46151 2.73107 3.35324C2.73602 3.10087 2.76019 3.08612 2.75894 2.92103Z"
        fill="url(#paint1_linear_12072_7934)"
      />
      <path
        d="M3.4125 2.60916C3.45454 2.58808 3.63319 2.49848 3.9013 2.48239L3.9013 2.48239C3.87611 2.51607 3.80367 2.6129 3.54519 2.77409C3.50525 2.7836 3.31179 2.79645 3.21396 2.76923C3.19211 2.7844 3.2164 2.75135 3.22338 2.73031C3.28986 2.6356 3.37047 2.63024 3.4125 2.60916Z"
        fill="url(#paint2_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.29606 4.76669C2.56256 3.62219 3.19 2.66028 3.67193 2.10451L3.69298 2.12026C3.21833 2.66764 2.64085 3.65689 2.37799 4.78577C2.11539 5.91351 2.1922 7.19697 3.07159 8.37734L3.00413 8.42759C2.10652 7.22277 2.02929 5.91233 2.29606 4.76669Z"
        fill="url(#paint3_linear_12072_7934)"
      />
      <path
        d="M3.41635 2.29682L3.31689 1.78906L3.25076 1.87747C3.11748 2.0995 3.19654 2.25713 3.16732 2.44539C3.14395 2.59599 3.19779 2.70893 3.22655 2.72607L3.3273 2.56507L3.41635 2.29682Z"
        fill="url(#paint4_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.3186 1.79775C3.29286 2.0247 3.26511 2.29778 3.28393 2.36847C3.30106 2.4328 3.27156 2.57921 3.2366 2.71001L3.22655 2.72607C3.19779 2.70893 3.14395 2.59599 3.16732 2.44539C3.17718 2.38185 3.17471 2.32179 3.17223 2.26158C3.16736 2.14339 3.16247 2.02456 3.25076 1.87747L3.31689 1.78906L3.3186 1.79775Z"
        fill="url(#paint5_linear_12072_7934)"
      />
      <path
        d="M2.67654 5.16407C2.70507 5.11188 2.79665 4.78117 2.83886 4.62235L2.78841 4.63713C2.48905 4.90563 2.46723 5.11911 2.40434 5.28217C2.34145 5.44523 2.11356 5.6884 2.23758 5.63673C2.36159 5.58506 2.64086 5.22931 2.67654 5.16407Z"
        fill="url(#paint6_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.8329 4.64469C2.72812 4.81061 2.58098 5.05702 2.52407 5.20118C2.47084 5.336 2.34696 5.51394 2.24638 5.63269C2.24337 5.63419 2.24043 5.63554 2.23758 5.63673C2.16559 5.66673 2.21218 5.59737 2.27624 5.502C2.32254 5.43308 2.37795 5.35057 2.40434 5.28217C2.41571 5.2527 2.42573 5.22158 2.4363 5.18879C2.48419 5.04014 2.54316 4.8571 2.78841 4.63713L2.83886 4.62235C2.83697 4.62946 2.83498 4.63692 2.8329 4.64469Z"
        fill="url(#paint7_linear_12072_7934)"
      />
      <path
        d="M2.46161 3.95933C2.43709 3.85169 2.34873 3.65383 2.30762 3.56836C2.25099 3.69671 2.28261 3.75976 2.23341 3.93963C2.19405 4.08353 2.32676 4.32683 2.39804 4.43049C2.42945 4.31829 2.48614 4.06698 2.46161 3.95933Z"
        fill="url(#paint8_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.45898 3.94922C2.45991 3.95289 2.46077 3.95647 2.46157 3.95996C2.48609 4.06761 2.4294 4.31892 2.39799 4.43112C2.37275 4.39441 2.33981 4.34019 2.30953 4.27907C2.34104 4.15245 2.40549 4.03153 2.45898 3.94922Z"
        fill="url(#paint9_linear_12072_7934)"
      />
      <path
        d="M2.70961 2.91672C2.7028 2.84859 2.62172 2.69776 2.58203 2.63086C2.54574 2.76129 2.45724 2.91176 2.44805 3.1347C2.44425 3.22686 2.58665 3.54917 2.6685 3.70014L2.69678 3.60968C2.64183 3.38472 2.71813 3.00188 2.70961 2.91672Z"
        fill="url(#paint10_linear_12072_7934)"
      />
      <path
        d="M3.0595 3.57313C3.19735 3.48716 3.28345 3.26792 3.30927 3.16904C3.06866 3.32391 2.99079 3.27879 2.79553 3.5398L2.72843 3.69971C2.78135 3.69334 2.92165 3.6591 3.0595 3.57313Z"
        fill="url(#paint11_linear_12072_7934)"
      />
      <path
        d="M2.17943 5.33744C2.09582 5.07004 1.79297 4.8798 1.65201 4.83125C1.81979 5.31789 1.92796 5.20841 2.03224 5.35864C2.11567 5.47884 2.18773 5.53406 2.2007 5.5372L2.17943 5.33744Z"
        fill="url(#paint12_linear_12072_7934)"
      />
      <path
        d="M2.08005 4.77665C2.05138 4.72369 1.81324 4.42822 1.69775 4.28711C1.75389 4.62458 1.81517 4.8674 1.90259 4.97876C1.97251 5.06784 2.1179 5.35213 2.18185 5.48314C2.18812 5.4572 2.20087 5.39101 2.20164 5.3338C2.20261 5.2623 2.11588 4.84285 2.08005 4.77665Z"
        fill="url(#paint13_linear_12072_7934)"
      />
      <path
        d="M2.67546 5.85936C2.51918 6.00508 2.37525 6.28699 2.32282 6.40973L2.30842 6.67473C2.40433 6.56701 2.60411 6.32863 2.63597 6.2369C2.66782 6.14517 2.67557 5.94699 2.67546 5.85936Z"
        fill="url(#paint14_linear_12072_7934)"
      />
      <path
        d="M2.08218 5.75704C2.07118 5.70154 1.96454 5.57492 1.9126 5.51855C1.88537 5.60762 1.84337 5.83052 1.89321 6.00963C1.94306 6.18875 2.10779 6.35182 2.18392 6.41096C2.19897 6.39377 2.22064 6.34782 2.18694 6.3016C2.14481 6.24382 2.09593 5.82643 2.08218 5.75704Z"
        fill="url(#paint15_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17236 6.40173C2.03862 6.14843 1.95005 5.75734 1.91073 5.52479C1.91136 5.52265 1.91198 5.52057 1.9126 5.51855C1.96454 5.57492 2.07118 5.70154 2.08218 5.75704C2.08562 5.77439 2.09125 5.81348 2.09843 5.86327C2.11995 6.01265 2.15534 6.25826 2.18694 6.3016C2.22064 6.34782 2.19897 6.39377 2.18392 6.41096C2.18026 6.40812 2.1764 6.40504 2.17236 6.40173Z"
        fill="url(#paint16_linear_12072_7934)"
      />
      <path
        d="M2.07254 7.33211C2.12216 7.32196 2.31335 7.34374 2.40275 7.3559L2.41531 7.31277C2.38234 7.29248 2.29156 7.23595 2.19222 7.17214C2.09288 7.10833 1.91462 7.17458 1.83791 7.21568C1.89545 7.25872 2.02292 7.34227 2.07254 7.33211Z"
        fill="url(#paint17_linear_12072_7934)"
      />
      <path
        d="M3.86372 1.56763C3.90907 1.507 4.1811 1.42424 4.31145 1.39043L4.1909 1.70077C4.15941 1.74287 4.07015 1.84287 3.96507 1.90609C3.86 1.96931 3.71328 2.08761 3.67199 2.13988L3.64673 2.12098C3.67276 1.91065 3.80703 1.6434 3.86372 1.56763Z"
        fill="url(#paint18_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.67602 2.11688C3.74459 1.96014 4.08996 1.597 4.29814 1.39403C4.30267 1.39283 4.30706 1.39167 4.3113 1.39057L4.19076 1.70092C4.15926 1.74301 4.07 1.84302 3.96493 1.90623C3.85986 1.96945 3.7142 2.09511 3.6729 2.14738L3.67602 2.11688Z"
        fill="url(#paint19_linear_12072_7934)"
      />
      <g filter="url(#filter0_d_12072_7934)">
        <path
          d="M3.47649 2.38742C3.49498 2.36973 3.66999 2.20388 3.97779 2.16494C3.96174 2.22735 3.86116 2.38367 3.78388 2.47293C3.7066 2.56219 3.40567 2.66055 3.24028 2.70316C3.34176 2.56459 3.45801 2.40511 3.47649 2.38742Z"
          fill="url(#paint20_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.97391 2.17746C3.9755 2.17296 3.97681 2.16877 3.97779 2.16494C3.66999 2.20388 3.49498 2.36973 3.47649 2.38742C3.45952 2.40366 3.36016 2.53939 3.26547 2.66875C3.25702 2.6803 3.2486 2.6918 3.24028 2.70316C3.25271 2.69996 3.2659 2.69644 3.2797 2.69264C3.49697 2.55882 3.80087 2.32006 3.97391 2.17746Z"
          fill="url(#paint21_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.93604 2.48535C2.93948 2.51422 2.9415 2.54843 2.93956 2.58403C2.93442 2.67867 2.81969 3.44641 2.78401 3.51165C2.78166 3.51596 2.77934 3.5195 2.77707 3.52236C2.76854 3.50724 2.76012 3.47151 2.75056 3.43099C2.74465 3.4059 2.7383 3.37897 2.73122 3.35396C2.73381 3.22217 2.74164 3.15518 2.74834 3.09774C2.75448 3.04519 2.75969 3.00063 2.75909 2.92175C2.76973 2.78972 2.82106 2.69631 2.88558 2.57893C2.90171 2.54956 2.91868 2.5187 2.93604 2.48535Z"
        fill="url(#paint22_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.67767 3.6708C2.61291 3.53833 2.56447 3.35686 2.57422 3.12405C2.5849 2.86893 2.58271 2.72589 2.57618 2.65067C2.57825 2.64401 2.58021 2.63741 2.58203 2.63086C2.62172 2.69776 2.7028 2.84859 2.70961 2.91672C2.71234 2.94396 2.70638 3.00169 2.69881 3.07497C2.68274 3.23071 2.65941 3.4567 2.69678 3.60968L2.67767 3.6708Z"
        fill="url(#paint23_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.30778 3.16964C3.22946 3.21992 3.16841 3.249 3.1149 3.27449C3.00486 3.32692 2.92668 3.36416 2.79558 3.53941L2.72847 3.69932C2.73095 3.69902 2.73361 3.69866 2.73645 3.69824C2.94765 3.57477 3.19207 3.31109 3.30778 3.16964Z"
        fill="url(#paint24_linear_12072_7934)"
      />
      <g filter="url(#filter1_d_12072_7934)">
        <path
          d="M2.63398 3.85165C2.65502 3.85863 2.65444 3.85196 2.71496 3.86627C2.935 3.91829 3.22243 3.73564 3.34025 3.56583L3.12468 3.55558C2.7966 3.55177 2.66329 3.74226 2.63398 3.85165Z"
          fill="url(#paint25_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.33476 3.57358C3.33663 3.571 3.33846 3.56841 3.34025 3.56583L3.12467 3.55558C2.7966 3.55177 2.66329 3.74226 2.63398 3.85165C2.63482 3.85193 2.63562 3.85218 2.63639 3.85242C2.78082 3.74269 3.14202 3.62629 3.33476 3.57358Z"
          fill="url(#paint26_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter2_d_12072_7934)">
        <path
          d="M2.28537 4.03777C2.25166 3.99154 2.00881 3.74333 1.8916 3.625C1.87961 4.14131 2.10797 4.29245 2.15434 4.37967C2.26492 4.52149 2.34393 4.64407 2.37235 4.66752L2.38588 4.55289C2.36642 4.40044 2.31907 4.08399 2.28537 4.03777Z"
          fill="url(#paint27_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.89111 3.63281C1.88349 4.04342 2.02873 4.22065 2.10967 4.31942C2.12923 4.34329 2.14504 4.36258 2.15402 4.37947C2.20444 4.44413 2.24829 4.50479 2.28378 4.55388C2.3198 4.60371 2.34721 4.64161 2.36411 4.65968C2.34462 4.40935 2.06356 3.90794 1.89111 3.63281Z"
          fill="url(#paint28_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.19855 5.38198C2.09205 5.12121 1.85063 4.60603 1.69922 4.28906C1.81529 4.43093 2.05139 4.72394 2.07992 4.77666C2.11575 4.84286 2.20249 5.26231 2.20152 5.33382C2.20131 5.34953 2.20019 5.36593 2.19855 5.38198Z"
        fill="url(#paint29_linear_12072_7934)"
      />
      <g filter="url(#filter3_d_12072_7934)">
        <path
          d="M2.23834 5.22383C2.2532 4.99332 2.37244 4.81638 2.4302 4.75673L2.43772 4.88711C2.54076 5.22683 2.33263 5.51323 2.21568 5.61397C2.21704 5.57997 2.22348 5.45434 2.23834 5.22383Z"
          fill="url(#paint30_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.22066 5.60962C2.21898 5.61111 2.21732 5.61256 2.21568 5.61397C2.21704 5.57997 2.22348 5.45434 2.23834 5.22383C2.2532 4.99332 2.37244 4.81638 2.4302 4.75673L2.43772 4.88711C2.53931 5.22206 2.33841 5.50518 2.22066 5.60962Z"
          fill="url(#paint31_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.19513 5.53427C2.12001 5.30701 1.82067 4.99045 1.65381 4.83594C1.76709 5.16251 1.85315 5.21733 1.92927 5.26583C1.96522 5.28872 1.99895 5.31021 2.03228 5.35823C2.10738 5.46642 2.17326 5.52197 2.19513 5.53427Z"
        fill="url(#paint32_linear_12072_7934)"
      />
      <path
        d="M3.67156 2.11351C3.52104 2.27667 3.12621 2.77578 2.82661 3.36583C2.61158 3.78933 2.5135 3.94577 2.30254 4.75972C2.25265 4.9522 2.15574 5.44569 2.1672 5.87974C2.15036 6.15385 2.19617 6.87843 2.51416 7.58382C2.60484 7.78735 2.83083 8.23832 3.00938 8.41391"
        stroke="#FADA81"
        stroke-width="0.0630906"
      />
      <g filter="url(#filter4_d_12072_7934)">
        <path
          d="M2.27351 5.93973C2.33012 5.80481 2.33456 5.70307 2.35205 5.67676C2.45437 5.94372 2.47677 6.20341 2.48419 6.25493C2.49013 6.29614 2.3503 6.54215 2.27964 6.66001L2.19599 6.18378C2.22839 6.0176 2.2064 6.09964 2.27351 5.93973Z"
          fill="url(#paint33_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.36257 5.70495C2.35916 5.69557 2.35566 5.68617 2.35205 5.67676C2.34511 5.6872 2.34022 5.70953 2.33331 5.7411C2.32282 5.78906 2.30765 5.85836 2.27351 5.93973C2.23961 6.0205 2.22845 6.03954 2.22152 6.06002C2.21474 6.08009 2.21202 6.10153 2.19599 6.18378L2.27766 6.64874C2.36648 6.44105 2.37267 5.9683 2.36257 5.70495Z"
          fill="url(#paint34_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter5_d_12072_7934)">
        <path
          d="M1.89198 6.00158C1.81951 5.89483 1.64433 5.83819 1.5658 5.82321C1.5406 5.85689 1.65938 6.10771 1.72191 6.22891C1.74787 6.24395 1.8223 6.28956 1.91238 6.3517C2.00247 6.41384 2.13854 6.41802 2.19531 6.41235C2.1244 6.3199 1.96445 6.10832 1.89198 6.00158Z"
          fill="url(#paint35_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.15595 6.41418C2.1713 6.41413 2.1847 6.41341 2.19531 6.41235C2.1244 6.3199 1.96445 6.10832 1.89198 6.00158C1.81951 5.89483 1.64433 5.83819 1.5658 5.82321C1.56511 5.82413 1.56453 5.82521 1.56405 5.82644C1.62411 5.9434 1.78989 6.1488 2.15595 6.41418Z"
          fill="url(#paint36_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter6_d_12072_7934)">
        <path
          d="M2.28544 7.01191C2.18695 6.95399 1.89174 6.93843 1.75644 6.9379C1.93235 7.19425 1.96067 7.13008 2.09853 7.22665C2.20883 7.30391 2.33304 7.24669 2.38137 7.20843C2.39043 7.16706 2.38393 7.06984 2.28544 7.01191Z"
          fill="url(#paint37_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.38341 7.19672C2.10623 7.02426 1.87891 6.95787 1.75793 6.94028C1.87928 7.11673 1.93009 7.13989 1.99592 7.1699C2.02483 7.18308 2.05665 7.19758 2.09861 7.22698C2.10786 7.23346 2.11721 7.23899 2.12661 7.24367C2.14895 7.25056 2.17093 7.25722 2.19241 7.26359C2.27242 7.27373 2.34663 7.23633 2.38144 7.20876C2.38224 7.20515 2.38291 7.20112 2.38341 7.19672Z"
          fill="url(#paint38_linear_12072_7934)"
        />
      </g>
      <path
        d="M9.65333 6.68176C9.63312 6.69688 9.50996 6.98162 9.45091 7.1221L9.41601 7.28609C9.52861 7.20842 9.76458 7.03058 9.80759 6.94062C9.8506 6.85066 9.84897 6.63608 9.84277 6.54004L9.65333 6.68176Z"
        fill="url(#paint39_linear_12072_7934)"
      />
      <path
        d="M9.04575 2.92103C9.04675 2.78897 8.95355 2.64858 8.86544 2.47814C8.83852 2.47726 8.73062 2.65777 8.91387 3.2889L8.99881 3.48144C9.02599 3.60554 9.04299 3.46151 9.07362 3.35324C9.06867 3.10087 9.0445 3.08612 9.04575 2.92103Z"
        fill="url(#paint40_linear_12072_7934)"
      />
      <path
        d="M8.39218 2.60916C8.35015 2.58808 8.1715 2.49848 7.90338 2.48239L7.90339 2.48239C7.92858 2.51607 8.00102 2.6129 8.25949 2.77409C8.29944 2.7836 8.4929 2.79645 8.59072 2.76923C8.61258 2.7844 8.58829 2.75135 8.58131 2.73031C8.51483 2.6356 8.43422 2.63024 8.39218 2.60916Z"
        fill="url(#paint41_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.50863 4.76669C9.24213 3.62219 8.61469 2.66028 8.13275 2.10451L8.1117 2.12026C8.58636 2.66764 9.16384 3.65689 9.4267 4.78577C9.6893 5.91351 9.61249 7.19697 8.7331 8.37734L8.80056 8.42759C9.69817 7.22277 9.7754 5.91233 9.50863 4.76669Z"
        fill="url(#paint42_linear_12072_7934)"
      />
      <path
        d="M8.38834 2.29682L8.48779 1.78906L8.55393 1.87747C8.6872 2.0995 8.60815 2.25713 8.63736 2.44539C8.66074 2.59599 8.6069 2.70893 8.57814 2.72607L8.47739 2.56507L8.38834 2.29682Z"
        fill="url(#paint43_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.4861 1.79772C8.51183 2.02468 8.53959 2.29778 8.52077 2.36847C8.50364 2.4328 8.53314 2.57923 8.5681 2.71003L8.57814 2.72607C8.6069 2.70893 8.66074 2.59599 8.63736 2.44539C8.6275 2.38185 8.62998 2.32179 8.63246 2.26158C8.63733 2.14339 8.64222 2.02456 8.55393 1.87747L8.48779 1.78906L8.4861 1.79772Z"
        fill="url(#paint44_linear_12072_7934)"
      />
      <path
        d="M9.12815 5.16407C9.09961 5.11188 9.00804 4.78117 8.96582 4.62235L9.01628 4.63713C9.31563 4.90563 9.33746 5.11911 9.40035 5.28217C9.46324 5.44523 9.69113 5.6884 9.56711 5.63673C9.44309 5.58506 9.16382 5.22931 9.12815 5.16407Z"
        fill="url(#paint45_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.97181 4.64478C9.07659 4.8107 9.22368 5.05705 9.28059 5.20118C9.33381 5.33599 9.45767 5.51391 9.55825 5.63266C9.56128 5.63417 9.56424 5.63553 9.56711 5.63673C9.6391 5.66673 9.59251 5.59737 9.52845 5.502C9.48215 5.43308 9.42673 5.35057 9.40035 5.28217C9.38898 5.2527 9.37896 5.22158 9.36839 5.18879C9.3205 5.04014 9.26153 4.8571 9.01628 4.63713L8.96582 4.62235C8.96772 4.62949 8.96972 4.63698 8.97181 4.64478Z"
        fill="url(#paint46_linear_12072_7934)"
      />
      <path
        d="M9.34308 3.95933C9.3676 3.85169 9.45596 3.65383 9.49707 3.56836C9.5537 3.69671 9.52207 3.75976 9.57128 3.93963C9.61064 4.08353 9.47793 4.32683 9.40665 4.43049C9.37524 4.31829 9.31855 4.06698 9.34308 3.95933Z"
        fill="url(#paint47_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.3457 3.94922C9.34478 3.95289 9.34392 3.95647 9.34312 3.95996C9.3186 4.06761 9.37528 4.31892 9.40669 4.43112C9.43193 4.39441 9.46488 4.34019 9.49516 4.27907C9.46365 4.15245 9.3992 4.03153 9.3457 3.94922Z"
        fill="url(#paint48_linear_12072_7934)"
      />
      <path
        d="M9.09508 2.91672C9.10189 2.84859 9.18297 2.69776 9.22266 2.63086C9.25895 2.76129 9.34745 2.91176 9.35664 3.1347C9.36044 3.22686 9.21804 3.54917 9.13619 3.70014L9.10791 3.60968C9.16286 3.38472 9.08656 3.00188 9.09508 2.91672Z"
        fill="url(#paint49_linear_12072_7934)"
      />
      <path
        d="M8.74519 3.57313C8.60733 3.48716 8.52124 3.26792 8.49542 3.16904C8.73603 3.32391 8.81389 3.27879 9.00916 3.5398L9.07626 3.69971C9.02334 3.69334 8.88304 3.6591 8.74519 3.57313Z"
        fill="url(#paint50_linear_12072_7934)"
      />
      <path
        d="M9.62526 5.33744C9.70886 5.07004 10.0117 4.8798 10.1527 4.83125C9.9849 5.31789 9.87673 5.20841 9.77244 5.35864C9.68902 5.47884 9.61696 5.53406 9.60399 5.5372L9.62526 5.33744Z"
        fill="url(#paint51_linear_12072_7934)"
      />
      <path
        d="M9.72464 4.77665C9.75331 4.72369 9.99145 4.42822 10.1069 4.28711C10.0508 4.62458 9.98951 4.8674 9.9021 4.97876C9.83217 5.06784 9.68679 5.35213 9.62284 5.48314C9.61656 5.4572 9.60381 5.39101 9.60304 5.3338C9.60208 5.2623 9.68881 4.84285 9.72464 4.77665Z"
        fill="url(#paint52_linear_12072_7934)"
      />
      <path
        d="M9.12922 5.85936C9.28551 6.00508 9.42944 6.28699 9.48186 6.40973L9.49626 6.67473C9.40036 6.56701 9.20058 6.32863 9.16872 6.2369C9.13687 6.14517 9.12912 5.94699 9.12922 5.85936Z"
        fill="url(#paint53_linear_12072_7934)"
      />
      <path
        d="M9.72251 5.75704C9.73351 5.70154 9.84015 5.57492 9.89209 5.51855C9.91932 5.60762 9.96132 5.83052 9.91147 6.00963C9.86163 6.18875 9.6969 6.35182 9.62077 6.41096C9.60572 6.39377 9.58404 6.34782 9.61775 6.3016C9.65988 6.24382 9.70875 5.82643 9.72251 5.75704Z"
        fill="url(#paint54_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.63242 6.40166C9.76612 6.1484 9.85467 5.75743 9.89399 5.52489C9.89335 5.52271 9.89271 5.5206 9.89209 5.51855C9.84015 5.57492 9.73351 5.70154 9.72251 5.75704C9.71907 5.77439 9.71344 5.81348 9.70626 5.86327C9.68474 6.01265 9.64935 6.25826 9.61775 6.3016C9.58404 6.34782 9.60572 6.39377 9.62077 6.41096C9.62445 6.4081 9.62834 6.40499 9.63242 6.40166Z"
        fill="url(#paint55_linear_12072_7934)"
      />
      <path
        d="M9.73215 7.33211C9.68253 7.32196 9.49134 7.34374 9.40194 7.3559L9.38938 7.31277C9.42235 7.29248 9.51313 7.23595 9.61247 7.17214C9.71181 7.10833 9.89006 7.17458 9.96677 7.21568C9.90924 7.25872 9.78176 7.34227 9.73215 7.33211Z"
        fill="url(#paint56_linear_12072_7934)"
      />
      <path
        d="M7.94097 1.56763C7.89562 1.507 7.62359 1.42424 7.49324 1.39043L7.61379 1.70077C7.64528 1.74287 7.73454 1.84287 7.83961 1.90609C7.94469 1.96931 8.09141 2.08761 8.1327 2.13988L8.15796 2.12098C8.13193 1.91065 7.99766 1.6434 7.94097 1.56763Z"
        fill="url(#paint57_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.12867 2.11688C8.0601 1.96014 7.71472 1.597 7.50655 1.39403C7.50202 1.39283 7.49763 1.39167 7.49338 1.39057L7.61393 1.70092C7.64543 1.74301 7.73468 1.84302 7.83976 1.90623C7.94483 1.96945 8.09049 2.09511 8.13179 2.14738L8.12867 2.11688Z"
        fill="url(#paint58_linear_12072_7934)"
      />
      <g filter="url(#filter7_d_12072_7934)">
        <path
          d="M8.3282 2.38742C8.30971 2.36973 8.1347 2.20388 7.82689 2.16494C7.84294 2.22735 7.94352 2.38367 8.02081 2.47293C8.09809 2.56219 8.39902 2.66055 8.5644 2.70316C8.46293 2.56459 8.34668 2.40511 8.3282 2.38742Z"
          fill="url(#paint59_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.83078 2.17745C7.82918 2.17295 7.82788 2.16877 7.82689 2.16494C8.1347 2.20388 8.30971 2.36973 8.3282 2.38742C8.34517 2.40366 8.44453 2.53939 8.53921 2.66875C8.54767 2.6803 8.55609 2.6918 8.5644 2.70316C8.55198 2.69996 8.5388 2.69644 8.525 2.69264C8.30772 2.55882 8.00381 2.32005 7.83078 2.17745Z"
          fill="url(#paint60_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86865 2.48535C8.86521 2.51421 8.8632 2.54839 8.86513 2.58397C8.87027 2.67861 8.98501 3.44635 9.02068 3.51159C9.02303 3.51588 9.02534 3.51942 9.0276 3.52227C9.03612 3.50713 9.04454 3.47143 9.05409 3.43093C9.06001 3.40584 9.06635 3.37891 9.07343 3.35389C9.07085 3.22211 9.06302 3.15512 9.05631 3.09768C9.05017 3.04513 9.04497 3.00057 9.04556 2.92169C9.03493 2.78966 8.98359 2.69625 8.91908 2.57887C8.90295 2.54952 8.886 2.51867 8.86865 2.48535Z"
        fill="url(#paint61_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.12703 3.67086C9.19181 3.53838 9.24027 3.3569 9.23052 3.12405C9.21984 2.86907 9.22202 2.72605 9.22854 2.65079C9.22646 2.64409 9.22449 2.63745 9.22266 2.63086C9.18297 2.69776 9.10189 2.84859 9.09508 2.91672C9.09235 2.94396 9.09831 3.00169 9.10587 3.07497C9.12195 3.23071 9.14528 3.4567 9.10791 3.60968L9.12703 3.67086Z"
        fill="url(#paint62_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.49688 3.16966C8.57524 3.21997 8.63631 3.24906 8.68984 3.27456C8.79988 3.32699 8.87806 3.36424 9.00916 3.53948L9.07627 3.69939C9.07378 3.69909 9.0711 3.69873 9.06824 3.6983C8.85701 3.57481 8.61257 3.31109 8.49688 3.16966Z"
        fill="url(#paint63_linear_12072_7934)"
      />
      <g filter="url(#filter8_d_12072_7934)">
        <path
          d="M9.17071 3.85165C9.14967 3.85863 9.15024 3.85196 9.08973 3.86627C8.86969 3.91829 8.58226 3.73564 8.46444 3.56583L8.68001 3.55558C9.00809 3.55177 9.1414 3.74226 9.17071 3.85165Z"
          fill="url(#paint64_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.46993 3.57358C8.46806 3.571 8.46623 3.56841 8.46444 3.56583L8.68001 3.55558C9.00809 3.55177 9.1414 3.74226 9.17071 3.85165C9.16987 3.85193 9.16907 3.85218 9.16829 3.85242C9.02387 3.74269 8.66266 3.62629 8.46993 3.57358Z"
          fill="url(#paint65_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter9_d_12072_7934)">
        <path
          d="M9.51932 4.03777C9.55303 3.99154 9.79587 3.74333 9.91309 3.625C9.92508 4.14131 9.69672 4.29245 9.65035 4.37967C9.53976 4.52149 9.46075 4.64407 9.43233 4.66752L9.4188 4.55289C9.43827 4.40044 9.48562 4.08399 9.51932 4.03777Z"
          fill="url(#paint66_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.91357 3.63281C9.92119 4.0434 9.77595 4.22064 9.69502 4.3194C9.67545 4.34327 9.65965 4.36256 9.65067 4.37945C9.60025 4.44411 9.5564 4.50477 9.52091 4.55386C9.4849 4.60367 9.4575 4.64157 9.44059 4.65965C9.46009 4.40932 9.74113 3.90794 9.91357 3.63281Z"
          fill="url(#paint67_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.60595 5.38238C9.71246 5.12157 9.95404 4.60606 10.1055 4.28906C9.98947 4.43084 9.75312 4.72415 9.72457 4.7769C9.68874 4.8431 9.60201 5.26255 9.60297 5.33405C9.60318 5.34982 9.60431 5.36628 9.60595 5.38238Z"
        fill="url(#paint68_linear_12072_7934)"
      />
      <g filter="url(#filter10_d_12072_7934)">
        <path
          d="M9.56635 5.22383C9.55148 4.99332 9.43225 4.81638 9.37448 4.75673L9.36697 4.88711C9.26393 5.22683 9.47206 5.51323 9.58901 5.61397C9.58765 5.57997 9.58121 5.45434 9.56635 5.22383Z"
          fill="url(#paint69_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.37566 4.75853C9.4367 4.87894 9.49282 5.10085 9.50482 5.49333C9.53491 5.53846 9.56108 5.57714 9.58378 5.60993C9.58546 5.61142 9.58712 5.61288 9.58877 5.61429C9.5874 5.58029 9.58097 5.45467 9.5661 5.22416C9.55136 4.99552 9.43394 4.81959 9.37566 4.75853Z"
          fill="url(#paint70_linear_12072_7934)"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.60949 5.53436C9.6846 5.30706 9.98402 4.99043 10.1509 4.83594C10.0376 5.16256 9.95152 5.21738 9.87539 5.26588C9.83945 5.28878 9.80572 5.31027 9.77239 5.35829C9.69724 5.46656 9.63131 5.52211 9.60949 5.53436Z"
        fill="url(#paint71_linear_12072_7934)"
      />
      <path
        d="M8.13313 2.11351C8.28365 2.27667 8.67848 2.77578 8.97808 3.36583C9.19311 3.78933 9.29119 3.94577 9.50214 4.75972C9.55203 4.9522 9.64895 5.44569 9.63748 5.87974C9.65433 6.15385 9.60851 6.87843 9.29052 7.58382C9.19985 7.78735 8.97386 8.23832 8.79531 8.41391"
        stroke="#FADA81"
        stroke-width="0.0630906"
      />
      <g filter="url(#filter11_d_12072_7934)">
        <path
          d="M9.53118 5.93973C9.47456 5.80481 9.47013 5.70307 9.45264 5.67676C9.35032 5.94372 9.32792 6.20341 9.3205 6.25493C9.31456 6.29614 9.45439 6.54215 9.52505 6.66001L9.6087 6.18378C9.5763 6.0176 9.59828 6.09964 9.53118 5.93973Z"
          fill="url(#paint72_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.44212 5.70492C9.44553 5.69555 9.44903 5.68616 9.45264 5.67676C9.45958 5.6872 9.46447 5.70953 9.47137 5.7411C9.48187 5.78906 9.49704 5.85836 9.53118 5.93973C9.56507 6.0205 9.57624 6.03954 9.58316 6.06002C9.58995 6.08009 9.59266 6.10153 9.6087 6.18378L9.52702 6.64878C9.4382 6.44104 9.43203 5.96824 9.44212 5.70492Z"
          fill="url(#paint73_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter12_d_12072_7934)">
        <path
          d="M9.91271 6.00158C9.98518 5.89483 10.1604 5.83819 10.2389 5.82321C10.2641 5.85689 10.1453 6.10771 10.0828 6.22891C10.0568 6.24395 9.98239 6.28956 9.8923 6.3517C9.80222 6.41384 9.66615 6.41802 9.60937 6.41235C9.68029 6.3199 9.84023 6.10832 9.91271 6.00158Z"
          fill="url(#paint74_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.64874 6.41418C9.63339 6.41413 9.61999 6.41341 9.60937 6.41235C9.68029 6.3199 9.84023 6.10832 9.91271 6.00158C9.98518 5.89483 10.1604 5.83819 10.2389 5.82321C10.2396 5.82413 10.2402 5.82522 10.2406 5.82645C10.1806 5.94341 10.0148 6.14881 9.64874 6.41418Z"
          fill="url(#paint75_linear_12072_7934)"
        />
      </g>
      <g filter="url(#filter13_d_12072_7934)">
        <path
          d="M9.51925 7.01191C9.61774 6.95399 9.91295 6.93843 10.0482 6.9379C9.87233 7.19425 9.84402 7.13008 9.70615 7.22665C9.59586 7.30391 9.47164 7.24669 9.42332 7.20843C9.41426 7.16706 9.42075 7.06984 9.51925 7.01191Z"
          fill="url(#paint76_linear_12072_7934)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.42128 7.19672C9.69846 7.02426 9.92578 6.95787 10.0468 6.94028C9.92541 7.11673 9.8746 7.13989 9.80877 7.1699C9.77985 7.18308 9.74804 7.19758 9.70608 7.22698C9.69682 7.23346 9.68747 7.23899 9.67808 7.24367C9.65574 7.25056 9.63376 7.25722 9.61228 7.26359C9.53227 7.27373 9.45806 7.23633 9.42324 7.20876C9.42245 7.20515 9.42178 7.20112 9.42128 7.19672Z"
          fill="url(#paint77_linear_12072_7934)"
        />
      </g>
      <path
        d="M5.21156 3.00691L3.15615 4.12503C2.70028 4.37302 2.4165 4.85043 2.4165 5.36939V7.97696C2.4165 8.49591 2.70028 8.97333 3.15615 9.22132L5.21156 10.3394C5.63363 10.569 6.14332 10.569 6.5654 10.3394L8.62081 9.22132C9.07668 8.97333 9.36045 8.49592 9.36045 7.97696L9.36045 5.36939C9.36045 4.85043 9.07668 4.37302 8.62081 4.12503L6.5654 3.00691C6.14332 2.7773 5.63363 2.7773 5.21156 3.00691Z"
        fill="url(#paint78_linear_12072_7934)"
      />
      <path
        d="M5.21004 3.61685L3.6812 4.44852C3.22704 4.69558 2.94434 5.1712 2.94434 5.6882V7.64464C2.94434 8.16165 3.22704 8.63727 3.6812 8.88432L5.21004 9.71599C5.63052 9.94473 6.13829 9.94473 6.55878 9.71599L8.08762 8.88432C8.54177 8.63727 8.82448 8.16165 8.82448 7.64464L8.82448 5.6882C8.82448 5.1712 8.54177 4.69558 8.08762 4.44852L6.55878 3.61685C6.13829 3.38811 5.63052 3.38811 5.21004 3.61685Z"
        fill="url(#paint79_linear_12072_7934)"
      />
      <g filter="url(#filter14_f_12072_7934)">
        <path
          d="M5.21004 3.61685L3.6812 4.44852C3.22704 4.69558 2.94434 5.1712 2.94434 5.6882V7.64464C2.94434 8.16165 3.22704 8.63727 3.6812 8.88432L5.21004 9.71599C5.63052 9.94473 6.13829 9.94473 6.55878 9.71599L8.08762 8.88432C8.54177 8.63727 8.82448 8.16165 8.82448 7.64464L8.82448 5.6882C8.82448 5.1712 8.54177 4.69558 8.08762 4.44852L6.55878 3.61685C6.13829 3.38811 5.63052 3.38811 5.21004 3.61685Z"
          stroke="url(#paint80_linear_12072_7934)"
          stroke-width="0.0833274"
        />
      </g>
      <g opacity="0.5">
        <mask
          id="mask0_12072_7934"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="6"
          height="7"
        >
          <path
            d="M5.26144 3.87574L3.8957 4.61803C3.46345 4.85296 3.19434 5.30551 3.19434 5.79748V7.54538C3.19434 8.03734 3.46345 8.4899 3.8957 8.72483L5.26144 9.46711C5.66119 9.68437 6.14377 9.68437 6.54351 9.46711L7.90925 8.72483C8.3415 8.4899 8.61062 8.03734 8.61062 7.54538L8.61062 5.79748C8.61062 5.30551 8.3415 4.85296 7.90925 4.61803L6.54351 3.87574C6.14377 3.65848 5.66119 3.65849 5.26144 3.87574Z"
            fill="#A6D3DE"
          />
        </mask>
        <g mask="url(#mask0_12072_7934)">
          <path
            d="M5.26144 3.87574L3.8957 4.61803C3.46345 4.85296 3.19434 5.30551 3.19434 5.79748V7.54538C3.19434 8.03734 3.46345 8.4899 3.8957 8.72483L5.26144 9.46711C5.66119 9.68437 6.14377 9.68437 6.54351 9.46711L7.90925 8.72483C8.3415 8.4899 8.61062 8.03734 8.61062 7.54538L8.61062 5.79748C8.61062 5.30551 8.3415 4.85296 7.90925 4.61803L6.54351 3.87574C6.14377 3.65848 5.66119 3.65849 5.26144 3.87574Z"
            fill="url(#paint81_radial_12072_7934)"
          />
          <path
            opacity="0.5"
            d="M5.44986 9.94021L5.34229 3.40332H6.46289L6.35532 9.94021H5.44986Z"
            fill="url(#paint82_linear_12072_7934)"
          />
          <path
            opacity="0.5"
            d="M5.43534 9.88271L2.07373 4.27539L3.04421 3.71509L6.21949 9.42999L5.43534 9.88271Z"
            fill="url(#paint83_linear_12072_7934)"
          />
          <path
            opacity="0.5"
            d="M5.34181 9.42975L8.51709 3.71484L9.48757 4.27515L6.12596 9.88247L5.34181 9.42975Z"
            fill="url(#paint84_linear_12072_7934)"
          />
          <rect
            x="3.19434"
            y="6.29785"
            width="5.41628"
            height="3.51747"
            fill="url(#paint85_linear_12072_7934)"
          />
          <g filter="url(#filter15_i_12072_7934)">
            <path
              d="M5.26144 3.87574L3.8957 4.61803C3.46345 4.85296 3.19434 5.30551 3.19434 5.79748V7.54538C3.19434 8.03734 3.46345 8.4899 3.8957 8.72483L5.26144 9.46711C5.66119 9.68437 6.14377 9.68437 6.54351 9.46711L7.90925 8.72483C8.3415 8.4899 8.61062 8.03734 8.61062 7.54538L8.61062 5.79748C8.61062 5.30551 8.3415 4.85296 7.90925 4.61803L6.54351 3.87574C6.14377 3.65848 5.66119 3.65849 5.26144 3.87574Z"
              fill="#6052B4"
              fill-opacity="0.01"
            />
          </g>
        </g>
      </g>
      <g filter="url(#filter16_d_12072_7934)">
        <mask
          id="mask1_12072_7934"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="17"
          height="20"
        >
          <path
            d="M10.6777 3.87574L3.8957 7.56178C3.46345 7.79671 3.19434 8.24926 3.19434 8.74123L3.19434 17.178C3.19434 17.6699 3.46345 18.1225 3.8957 18.3574L10.6777 22.0434C11.0775 22.2607 11.56 22.2607 11.9598 22.0434L18.7418 18.3574C19.1741 18.1225 19.4432 17.6699 19.4432 17.178L19.4432 8.74123C19.4432 8.24926 19.1741 7.79671 18.7418 7.56178L11.9598 3.87574C11.56 3.65848 11.0775 3.65849 10.6777 3.87574Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_12072_7934)">
          <path
            d="M11.3604 6.31853C11.3604 5.61858 10.2494 4.55476 9.69385 4.11035H11.3604V6.31853Z"
            fill="url(#paint86_linear_12072_7934)"
          />
          <path
            d="M11.3603 6.31853C11.3603 5.61858 12.4713 4.55476 13.0269 4.11035H11.3603V6.31853Z"
            fill="url(#paint87_linear_12072_7934)"
          />
          <rect
            x="11.3613"
            y="2.94434"
            width="1.65104"
            height="1.65104"
            transform="rotate(45 11.3613 2.94434)"
            fill="url(#paint88_linear_12072_7934)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.3613 5.25568L12.517 4.10001L12.5406 4.12358L11.3613 5.30282L10.1821 4.12358L10.2057 4.10001L11.3613 5.25568Z"
            fill="url(#paint89_linear_12072_7934)"
          />
          <g filter="url(#filter17_d_12072_7934)">
            <path
              d="M10.6521 3.90264L9.94385 4.27761V3.44434H12.777V4.31927L12.0687 3.90264C11.4354 3.63599 10.8604 3.79154 10.6521 3.90264Z"
              fill="#C4C4C4"
            />
          </g>
          <g filter="url(#filter18_d_12072_7934)">
            <g filter="url(#filter19_d_12072_7934)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.2536 6.54176C11.4079 6.43702 11.849 6.91927 12.0835 7.46334C11.9743 7.63256 11.8484 7.82835 11.7058 8.05071C11.8227 7.89167 11.9806 7.75631 12.1796 7.64463C12.0965 7.85258 12.3773 8.37399 12.3729 8.50086C12.3699 8.58544 12.2137 8.80555 11.9044 9.16118C12.0067 9.06622 12.147 8.92971 12.3253 8.75166L12.3655 8.71141C12.57 9.77994 12.6724 11.508 12.5724 11.8783C12.4724 12.2485 12.2412 12.2967 11.99 13.0719C12.1079 12.8866 12.2686 12.6728 12.472 12.4303C12.4423 12.8279 12.3245 13.1999 12.1185 13.5462C11.8194 14.0492 11.5403 14.349 11.5053 14.865C11.5053 14.865 11.462 15.6934 11.462 16.1077C11.462 16.3789 11.4737 17.6114 11.4737 17.6114L11.2337 17.594C11.2337 17.594 11.2453 16.3556 11.2453 16.082C11.2453 15.644 11.202 14.7679 11.202 14.7679C11.1896 14.7212 11.1765 14.6765 11.1627 14.634C11.0084 14.1584 10.495 13.7602 10.334 13.2546C10.2267 12.9175 10.1148 12.5378 9.99836 12.1155C10.1797 12.1646 10.3604 12.2785 10.5405 12.457C10.4214 12.1101 10.119 11.688 9.94385 11.2445C10.1069 10.4029 10.022 9.00662 10.3641 8.33465C10.4908 7.44385 11.0993 6.64649 11.2536 6.54176Z"
                fill="url(#paint90_linear_12072_7934)"
              />
              <mask
                id="mask2_12072_7934"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="9"
                y="6"
                width="4"
                height="12"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.2536 6.54176C11.4079 6.43702 11.849 6.91927 12.0835 7.46334C11.9743 7.63256 11.8484 7.82835 11.7058 8.05071C11.8227 7.89167 11.9806 7.75631 12.1796 7.64463C12.0965 7.85258 12.3773 8.37399 12.3729 8.50086C12.3699 8.58544 12.2137 8.80555 11.9044 9.16118C12.0067 9.06622 12.147 8.92971 12.3253 8.75166L12.3655 8.71141C12.57 9.77994 12.6724 11.508 12.5724 11.8783C12.4724 12.2485 12.2412 12.2967 11.99 13.0719C12.1079 12.8866 12.2686 12.6728 12.472 12.4303C12.4423 12.8279 12.3245 13.1999 12.1185 13.5462C11.8194 14.0492 11.5403 14.349 11.5053 14.865C11.5053 14.865 11.462 15.6934 11.462 16.1077C11.462 16.3789 11.4737 17.6114 11.4737 17.6114L11.2337 17.594C11.2337 17.594 11.2453 16.3556 11.2453 16.082C11.2453 15.644 11.202 14.7679 11.202 14.7679C11.1896 14.7212 11.1765 14.6765 11.1627 14.634C11.0084 14.1584 10.495 13.7602 10.334 13.2546C10.2267 12.9175 10.1148 12.5378 9.99836 12.1155C10.1797 12.1646 10.3604 12.2785 10.5405 12.457C10.4214 12.1101 10.119 11.688 9.94385 11.2445C10.1069 10.4029 10.022 9.00662 10.3641 8.33465C10.4908 7.44385 11.0993 6.64649 11.2536 6.54176Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask2_12072_7934)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6113 11.1729C12.6113 11.8135 12.0406 12.5046 11.9902 13.0719C12.3731 12.4505 12.487 12.2202 12.6686 12.1224C12.8503 12.0246 12.6113 10.5323 12.6113 11.1729Z"
                  fill="url(#paint91_linear_12072_7934)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M10.5406 12.4578L10.6888 12.6479C10.6888 12.6479 10.5798 12.2492 10.4515 12.0702C10.3231 11.8912 10.007 11.3758 9.94389 11.2453C9.88076 11.1148 9.87305 11.4803 9.87305 11.4803L10.5406 12.4578Z"
                  fill="url(#paint92_linear_12072_7934)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.3332 8.3208C12.3717 8.42734 12.2891 8.60033 12.1328 8.82807C11.9765 9.0558 11.7515 9.31548 11.7515 9.31548L11.9043 9.16108L12.4421 8.58771L12.5463 8.36315C12.5463 8.36315 12.2946 8.21427 12.3332 8.3208Z"
                  fill="url(#paint93_linear_12072_7934)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7519 7.99518L11.5864 8.19663C11.5864 8.19663 11.8793 7.73545 11.9277 7.58719C11.9761 7.43893 12.0601 7.34064 11.9939 7.13349C11.9277 6.92635 12.2804 7.45523 12.2804 7.45523L11.8979 7.81104L11.7519 7.99518Z"
                  fill="url(#paint94_linear_12072_7934)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.1821 18.0015C11.1821 18.0015 11.2341 16.6854 11.2441 16.0998C11.2585 15.2487 11.306 7.02441 11.306 7.02441C11.306 7.02441 11.4629 14.3969 11.4629 16.1543C11.4629 17.9116 11.852 18.2693 11.852 18.2693L11.1821 18.1357L11.1821 18.0015Z"
                  fill="#C8CAD5"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <mask
        id="mask3_12072_7934"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="1"
        width="12"
        height="10"
      >
        <path d="M0 1H11.7769V10.4715H0V1Z" fill="#ABABAB" />
      </mask>
      <g mask="url(#mask3_12072_7934)">
        <g filter="url(#filter20_d_12072_7934)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.17322 6.61983C9.25626 6.09856 9.07245 5.66311 8.86788 5.41397L9.29149 4.40365L10.7805 1.22787C11.1047 1.94472 11.4086 3.84605 10.0309 5.71664C9.64676 6.2382 9.36955 6.51336 9.17322 6.61983Z"
            fill="url(#paint95_linear_12072_7934)"
          />
          <path
            d="M9.35806 4.43203L10.7756 1.40872C11.062 2.19865 11.2438 3.94775 9.97259 5.67372C9.668 6.08727 9.43573 6.3383 9.26242 6.47339C9.29201 6.02152 9.13456 5.64248 8.95113 5.40256L9.35806 4.43203Z"
            stroke="url(#paint96_linear_12072_7934)"
            stroke-width="0.144719"
          />
          <g filter="url(#filter21_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.87744 7.34567C9.24236 6.76878 9.23281 6.23348 9.09433 5.84005L11.3552 3.32944C11.3036 4.58276 10.7395 7.14026 8.89577 7.34368C8.88962 7.34436 8.88352 7.34502 8.87744 7.34567Z"
              fill="url(#paint97_linear_12072_7934)"
            />
          </g>
          <path
            d="M9.17353 5.85708L11.296 3.50015C11.2517 4.11542 11.0946 4.96668 10.7535 5.708C10.3941 6.48924 9.83699 7.13561 9.00375 7.27244C9.3039 6.73637 9.29802 6.2378 9.17353 5.85708Z"
            stroke="url(#paint98_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            opacity="0.8"
            d="M10.0748 6.13129L9.2152 6.68423L9.16322 6.88596L10.0748 6.13129Z"
            fill="url(#paint99_linear_12072_7934)"
          />
          <g filter="url(#filter22_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.14556 8.14418C8.7203 7.69004 9.01155 7.23383 9.12905 6.82035L11.2294 5.27972C11.1108 6.3922 10.3278 8.50608 8.14556 8.14418Z"
              fill="url(#paint100_linear_12072_7934)"
            />
          </g>
          <path
            d="M9.18127 6.85682L11.1519 5.41132C11.0732 5.94968 10.8498 6.66643 10.4137 7.22785C9.96209 7.80929 9.28272 8.22477 8.29013 8.10408C8.79415 7.67957 9.06406 7.25229 9.18127 6.85682Z"
            stroke="url(#paint101_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M10.1761 7.01786L8.88359 7.35883L8.78474 7.5198L10.1761 7.01786Z"
            fill="url(#paint102_linear_12072_7934)"
          />
          <g filter="url(#filter23_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.82325 7.44616C8.56648 7.8122 8.15572 8.1925 7.52965 8.56201C7.28779 8.70476 7.07943 8.83825 6.90079 8.96292L6.88385 8.97704C9.87872 9.15168 10.8383 7.58724 10.9478 6.76728L8.82325 7.44616Z"
              fill="url(#paint103_linear_12072_7934)"
            />
          </g>
          <path
            d="M6.90048 8.96282C7.07916 8.83812 7.28759 8.70458 7.52954 8.56178C8.15562 8.19226 8.56638 7.81195 8.82316 7.4459L10.9477 6.76704"
            stroke="url(#paint104_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M8.89915 8.46792L8.19108 8.09901L8.04884 8.20577L8.89915 8.46792Z"
            fill="url(#paint105_linear_12072_7934)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.13462 8.1528C7.95998 8.2899 7.75943 8.42679 7.52996 8.56223C6.96576 8.89523 6.58382 9.17787 6.33631 9.4155C6.36707 9.48725 6.53732 9.47868 6.79394 9.32722C7.31411 9.02021 7.77515 8.96811 8.58684 9.00975C9.37559 9.05021 9.61952 8.96508 9.75958 8.82358C9.43775 8.68337 8.63275 8.3507 8.13462 8.1528Z"
            fill="url(#paint106_linear_12072_7934)"
          />
          <path
            d="M7.56061 8.61416C7.78027 8.48451 7.97404 8.35332 8.14442 8.2216C8.60104 8.40348 9.28962 8.68759 9.64461 8.83964C9.50655 8.92802 9.24526 8.98315 8.58993 8.94953C7.77385 8.90767 7.29857 8.95936 6.76329 9.27529C6.63873 9.34881 6.53945 9.38495 6.47123 9.39547C6.46076 9.39709 6.45136 9.39804 6.44299 9.39849C6.68651 9.17784 7.04634 8.91768 7.56061 8.61416ZM6.39089 9.39045C6.39092 9.39044 6.39108 9.39058 6.3913 9.39091L6.39089 9.39045Z"
            stroke="url(#paint107_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M8.91846 5.33105C9.11201 5.58172 9.35563 6.08335 9.26413 6.43839"
            stroke="url(#paint108_linear_12072_7934)"
            stroke-width="0.149989"
          />
          <g filter="url(#filter24_d_12072_7934)">
            <path
              d="M7.63859 8.57269C9.65203 7.13652 9.42774 6.0753 8.94634 5.37414C8.93781 5.36171 8.91949 5.36216 8.9113 5.37482C8.90714 5.38125 8.90679 5.38947 8.91032 5.39628C9.34666 6.23834 9.36552 7.13236 7.50493 8.42351C5.04129 10.0034 5.39098 10.5606 5.43008 10.7428C5.46136 10.8886 5.6045 10.8451 5.67216 10.8052C5.71886 10.4013 5.7786 10.0582 7.63859 8.57269Z"
              fill="url(#paint109_linear_12072_7934)"
            />
            <path
              d="M7.63859 8.57269C9.65294 7.13587 9.42754 6.07435 8.94569 5.37319C8.93747 5.36122 8.91981 5.36165 8.91192 5.37385C8.90746 5.38075 8.90759 5.38969 8.91216 5.39652C9.44509 6.19303 9.50562 7.14054 7.50493 8.42351C5.04129 10.0034 5.39098 10.5606 5.43008 10.7428C5.46136 10.8886 5.6045 10.8451 5.67216 10.8052C5.44348 10.5981 5.63619 10.001 7.63859 8.57269Z"
              fill="url(#paint110_linear_12072_7934)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.5048 8.42334C7.58448 8.43578 7.70737 8.44582 7.82445 8.44194C8.23804 8.12919 8.54655 7.8313 8.76676 7.55871C8.80161 7.51023 8.83761 7.466 8.8661 7.42603C8.82448 7.4153 8.75818 7.42201 8.68393 7.46514C8.4146 7.76126 8.03377 8.08357 7.5048 8.42334Z"
              fill="url(#paint111_linear_12072_7934)"
            />
            <g filter="url(#filter25_f_12072_7934)">
              <path
                d="M8.92841 5.34835V5.34835C8.91395 5.37071 8.91437 5.3996 8.92894 5.42189C9.44614 6.21315 9.48442 7.15413 7.50493 8.42351C5.04129 10.0034 5.39098 10.5606 5.43008 10.7428C5.46136 10.8886 5.6045 10.8451 5.67216 10.8052"
                stroke="url(#paint112_linear_12072_7934)"
                stroke-width="0.0666619"
              />
            </g>
            <g filter="url(#filter26_f_12072_7934)">
              <path
                d="M5.45962 10.4061C5.61698 9.88442 6.27374 9.30599 6.58743 9.09043C6.91485 8.84017 7.65879 8.31671 7.97522 8.15731"
                stroke="#EED7B5"
                stroke-width="0.0833274"
              />
            </g>
          </g>
        </g>
        <g filter="url(#filter27_d_12072_7934)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.58459 6.6198C2.50156 6.09854 2.68536 5.66309 2.88993 5.41396L2.46597 4.40281L0.977356 1.22786C0.653176 1.94471 0.349245 3.84604 1.72696 5.71663C2.11106 6.23815 2.38825 6.51331 2.58459 6.6198Z"
            fill="url(#paint113_linear_12072_7934)"
          />
          <path
            d="M2.39976 4.43203L0.982239 1.40872C0.695768 2.19865 0.514027 3.94775 1.78523 5.67372C2.08981 6.08727 2.32209 6.3383 2.4954 6.47339C2.4658 6.02152 2.62325 5.64248 2.80669 5.40256L2.39976 4.43203Z"
            stroke="url(#paint114_linear_12072_7934)"
            stroke-width="0.144719"
          />
          <g filter="url(#filter28_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.8804 7.34568C2.51548 6.76881 2.52502 6.23352 2.66349 5.84008L0.402642 3.32944C0.4542 4.58277 1.01826 7.14027 2.86203 7.34368C2.86818 7.34436 2.87431 7.34503 2.8804 7.34568Z"
              fill="url(#paint115_linear_12072_7934)"
            />
          </g>
          <path
            d="M2.58477 5.85708L0.462347 3.50015C0.506649 4.11542 0.663734 4.96668 1.00479 5.708C1.36421 6.48924 1.92131 7.13561 2.75455 7.27244C2.4544 6.73637 2.46028 6.2378 2.58477 5.85708Z"
            stroke="url(#paint116_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            opacity="0.8"
            d="M1.68353 6.13129L2.5431 6.68423L2.59508 6.88596L1.68353 6.13129Z"
            fill="url(#paint117_linear_12072_7934)"
          />
          <g filter="url(#filter29_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.61274 8.14418C3.03798 7.69002 2.74672 7.23379 2.62923 6.8203L0.528915 5.27971C0.6475 6.39219 1.43055 8.50605 3.61274 8.14418Z"
              fill="url(#paint118_linear_12072_7934)"
            />
          </g>
          <path
            d="M2.57703 6.85682L0.606351 5.41132C0.685089 5.94968 0.908506 6.66643 1.34458 7.22785C1.79622 7.80929 2.47558 8.22477 3.46817 8.10408C2.96415 7.67957 2.69424 7.25229 2.57703 6.85682Z"
            stroke="url(#paint119_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M1.58215 7.01786L2.87471 7.35883L2.97356 7.5198L1.58215 7.01786Z"
            fill="url(#paint120_linear_12072_7934)"
          />
          <g filter="url(#filter30_d_12072_7934)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.93508 7.44617C3.19186 7.81221 3.60262 8.1925 4.22867 8.56201C4.47046 8.70471 4.67877 8.83817 4.85738 8.9628L4.87445 8.97704C1.87958 9.15168 0.920034 7.58724 0.810517 6.76728L2.93508 7.44617Z"
              fill="url(#paint121_linear_12072_7934)"
            />
          </g>
          <path
            d="M4.85782 8.96282C4.67914 8.83812 4.47071 8.70458 4.22876 8.56178C3.60268 8.19226 3.19192 7.81195 2.93514 7.4459L0.810643 6.76704"
            stroke="url(#paint122_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M2.85915 8.46792L3.56722 8.09901L3.70946 8.20577L2.85915 8.46792Z"
            fill="url(#paint123_linear_12072_7934)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M3.62368 8.1528C3.79833 8.2899 3.99887 8.42679 4.22834 8.56223C4.79255 8.89523 5.17448 9.17787 5.42199 9.4155C5.39123 9.48725 5.22098 9.47868 4.96436 9.32722C4.44419 9.02021 3.98315 8.96811 3.17146 9.00975C2.38271 9.05021 2.13878 8.96508 1.99872 8.82358C2.32055 8.68337 3.12555 8.3507 3.62368 8.1528Z"
            fill="url(#paint124_linear_12072_7934)"
          />
          <path
            d="M4.19769 8.61416C3.97804 8.48451 3.78426 8.35332 3.61388 8.2216C3.15726 8.40348 2.46868 8.68759 2.11369 8.83964C2.25175 8.92802 2.51304 8.98315 3.16837 8.94953C3.98445 8.90767 4.45973 8.95936 4.99501 9.27529C5.11957 9.34881 5.21886 9.38495 5.28707 9.39547C5.29754 9.39709 5.30694 9.39804 5.31531 9.39849C5.0718 9.17784 4.71196 8.91768 4.19769 8.61416ZM5.36741 9.39045C5.36738 9.39044 5.36722 9.39058 5.367 9.39091L5.36741 9.39045Z"
            stroke="url(#paint125_linear_12072_7934)"
            stroke-width="0.120599"
          />
          <path
            d="M2.83984 5.33105C2.64629 5.58172 2.40267 6.08335 2.49417 6.43839"
            stroke="url(#paint126_linear_12072_7934)"
            stroke-width="0.149989"
          />
          <g filter="url(#filter31_d_12072_7934)">
            <path
              d="M4.11971 8.57269C2.10627 7.13652 2.33056 6.0753 2.81196 5.37414C2.82049 5.36171 2.83882 5.36216 2.847 5.37482C2.85116 5.38125 2.85151 5.38947 2.84798 5.39628C2.41165 6.23834 2.39278 7.13236 4.25337 8.42351C6.71702 10.0034 6.36732 10.5606 6.32822 10.7428C6.29694 10.8886 6.1538 10.8451 6.08615 10.8052C6.03944 10.4013 5.9797 10.0582 4.11971 8.57269Z"
              fill="url(#paint127_linear_12072_7934)"
            />
            <path
              d="M4.11971 8.57269C2.10536 7.13587 2.33076 6.07435 2.81261 5.37319C2.82083 5.36122 2.83849 5.36165 2.84638 5.37385C2.85084 5.38075 2.85071 5.38969 2.84614 5.39652C2.31321 6.19303 2.25268 7.14054 4.25337 8.42351C6.71702 10.0034 6.36732 10.5606 6.32822 10.7428C6.29694 10.8886 6.1538 10.8451 6.08615 10.8052C6.31482 10.5981 6.12211 10.001 4.11971 8.57269Z"
              fill="url(#paint128_linear_12072_7934)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.2535 8.42334C4.17382 8.43578 4.05093 8.44582 3.93385 8.44194C3.52027 8.12919 3.21176 7.8313 2.99154 7.55871C2.95669 7.51023 2.9207 7.466 2.8922 7.42603C2.93382 7.4153 3.00012 7.42201 3.07437 7.46514C3.3437 7.76126 3.72453 8.08357 4.2535 8.42334Z"
              fill="url(#paint129_linear_12072_7934)"
            />
            <g filter="url(#filter32_f_12072_7934)">
              <path
                d="M2.82989 5.34835V5.34835C2.84435 5.37071 2.84393 5.3996 2.82936 5.42189C2.31216 6.21315 2.27388 7.15413 4.25337 8.42351C6.71702 10.0034 6.36732 10.5606 6.32822 10.7428C6.29694 10.8886 6.1538 10.8451 6.08615 10.8052"
                stroke="url(#paint130_linear_12072_7934)"
                stroke-width="0.0666619"
              />
            </g>
            <g filter="url(#filter33_f_12072_7934)">
              <path
                d="M6.29869 10.4061C6.14132 9.88442 5.48456 9.30599 5.17087 9.09043C4.84345 8.84017 4.09951 8.31671 3.78308 8.15731"
                stroke="#EED7B5"
                stroke-width="0.0833274"
              />
            </g>
          </g>
        </g>
      </g>
      <g filter="url(#filter34_d_12072_7934)">
        <mask
          id="mask4_12072_7934"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="5"
          y="9"
          width="2"
          height="2"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.91689 10.7539C5.91689 10.7539 5.91689 10.7539 5.91689 10.7539L5.91688 10.7539C5.91688 10.7539 5.91688 10.7539 5.91688 10.7539C5.91688 10.7539 5.91688 10.7539 5.91688 10.7539L5.38492 10.5566C5.28645 10.52 5.24591 10.4026 5.30085 10.3131L5.91688 9.3096C5.91688 9.3096 5.91688 9.30959 5.91688 9.30959L5.91688 9.30958C5.91688 9.30957 5.91688 9.30957 5.91689 9.30958C5.91689 9.30958 5.91689 9.30958 5.91689 9.30958C5.91689 9.30957 5.9169 9.30957 5.9169 9.30958L5.9169 9.30959C5.9169 9.30959 5.9169 9.3096 5.9169 9.3096L6.53292 10.3131C6.58787 10.4026 6.54733 10.52 6.44886 10.5566L5.9169 10.7539C5.9169 10.7539 5.9169 10.7539 5.9169 10.7539C5.9169 10.7539 5.91689 10.7539 5.91689 10.7539L5.91689 10.7539Z"
            fill="url(#paint131_linear_12072_7934)"
          />
        </mask>
        <g mask="url(#mask4_12072_7934)">
          <path
            d="M5.92089 10.7842L6.63672 10.4902L5.92089 9.19913L5.92089 10.7842Z"
            fill="url(#paint132_linear_12072_7934)"
          />
          <path
            d="M5.92091 10.7842L5.20508 10.4902L5.92091 9.19913L5.92091 10.7842Z"
            fill="url(#paint133_linear_12072_7934)"
          />
        </g>
      </g>
      <path
        d="M5.91637 9.69333L6.22191 9.80444L6.06914 9.55445L5.91637 9.31836L5.76361 9.55445L5.61084 9.80444L5.91637 9.69333Z"
        fill="url(#paint134_linear_12072_7934)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.61084 9.80444L5.91637 9.31836L6.22191 9.80444L5.91637 9.69333L5.61084 9.80444Z"
        fill="#EED6B3"
      />
      <defs>
        <filter
          id="filter0_d_12072_7934"
          x="3.15691"
          y="2.16504"
          width="0.904448"
          height="0.788068"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_12072_7934"
          x="2.55046"
          y="3.55566"
          width="0.873198"
          height="0.569318"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_12072_7934"
          x="1.89062"
          y="3.625"
          width="0.745099"
          height="1.29295"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.166655" dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_12072_7934"
          x="2.13249"
          y="4.75684"
          width="0.416166"
          height="1.1074"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_12072_7934"
          x="2.11247"
          y="5.67676"
          width="0.455229"
          height="1.23338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter5_d_12072_7934"
          x="1.5625"
          y="5.82324"
          width="0.882795"
          height="0.840802"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.166655" dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter6_d_12072_7934"
          x="1.67302"
          y="6.9375"
          width="0.794584"
          height="0.578107"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter7_d_12072_7934"
          x="7.74333"
          y="2.16504"
          width="0.904448"
          height="0.788068"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter8_d_12072_7934"
          x="8.38103"
          y="3.55566"
          width="0.873198"
          height="0.569318"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter9_d_12072_7934"
          x="9.41895"
          y="3.625"
          width="0.745099"
          height="1.29295"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.166655" dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter10_d_12072_7934"
          x="9.25603"
          y="4.75684"
          width="0.416166"
          height="1.1074"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter11_d_12072_7934"
          x="9.23699"
          y="5.67676"
          width="0.455229"
          height="1.23338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter12_d_12072_7934"
          x="9.60938"
          y="5.82324"
          width="0.882795"
          height="0.840802"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.166655" dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter13_d_12072_7934"
          x="9.33708"
          y="6.9375"
          width="0.794584"
          height="0.578107"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.166655" />
          <feGaussianBlur stdDeviation="0.0416637" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.541667 0 0 0 0 0.336104 0 0 0 0 0.0947917 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter14_f_12072_7934"
          x="2.87783"
          y="3.37832"
          width="6.01338"
          height="6.57636"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0124991"
            result="effect1_foregroundBlur_12072_7934"
          />
        </filter>
        <filter
          id="filter15_i_12072_7934"
          x="3.19434"
          y="3.71289"
          width="5.4165"
          height="5.91699"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.560305" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.297222 0 0 0 0 0.329917 0 0 0 0 0.445833 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_12072_7934" />
        </filter>
        <filter
          id="filter16_d_12072_7934"
          x="2.94435"
          y="3.36069"
          width="10.3325"
          height="16.2519"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.0833274" />
          <feGaussianBlur stdDeviation="0.124991" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.188125 0 0 0 0 0.223738 0 0 0 0 0.35 0 0 0 0.55 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter17_d_12072_7934"
          x="9.77719"
          y="3.44434"
          width="3.16632"
          height="1.29164"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.249982" />
          <feGaussianBlur stdDeviation="0.0833274" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.308611 0 0 0 0 0.319833 0 0 0 0 0.420833 0 0 0 0.36 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter18_d_12072_7934"
          x="9.46356"
          y="6.52734"
          width="3.62805"
          height="13.7125"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.480285" />
          <feGaussianBlur stdDeviation="0.240142" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.416875 0 0 0 0 0.431496 0 0 0 0 0.483333 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter19_d_12072_7934"
          x="9.25944"
          y="6.16713"
          width="4.10834"
          height="12.5248"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0360214" dy="0.360214" />
          <feGaussianBlur stdDeviation="0.360214" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.625387 0 0 0 0 0.624375 0 0 0 0 0.675 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter20_d_12072_7934"
          x="5.0246"
          y="1.22754"
          width="6.67443"
          height="10.316"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.33331" />
          <feGaussianBlur stdDeviation="0.166655" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.38125 0 0 0 0 0.416833 0 0 0 0 0.508333 0 0 0 0.38 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter21_d_12072_7934"
          x="8.39504"
          y="3.0075"
          width="3.12074"
          height="4.6598"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.160799" />
          <feGaussianBlur stdDeviation="0.160799" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.446731 0 0 0 0 0.444028 0 0 0 0 0.579167 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter22_d_12072_7934"
          x="7.66311"
          y="4.9577"
          width="3.72718"
          height="3.54945"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.160799" />
          <feGaussianBlur stdDeviation="0.160799" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.446731 0 0 0 0 0.444028 0 0 0 0 0.579167 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter23_d_12072_7934"
          x="6.07979"
          y="5.79693"
          width="5.67196"
          height="3.83065"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.166655" />
          <feGaussianBlur stdDeviation="0.401998" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.365689 0 0 0 0 0.351111 0 0 0 0 0.533333 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter24_d_12072_7934"
          x="5.19126"
          y="5.33008"
          width="4.41696"
          height="6.04684"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0833274" dy="0.249982" />
          <feGaussianBlur stdDeviation="0.124991" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7 0 0 0 0 0.526167 0 0 0 0 0.265417 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter25_f_12072_7934"
          x="5.33291"
          y="5.30508"
          width="3.95527"
          height="5.59687"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0124991"
            result="effect1_foregroundBlur_12072_7934"
          />
        </filter>
        <filter
          id="filter26_f_12072_7934"
          x="5.08661"
          y="7.78681"
          width="3.24084"
          height="2.96447"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.166655"
            result="effect1_foregroundBlur_12072_7934"
          />
        </filter>
        <filter
          id="filter27_d_12072_7934"
          x="0.0592686"
          y="1.22754"
          width="6.67443"
          height="10.316"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.33331" />
          <feGaussianBlur stdDeviation="0.166655" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.38125 0 0 0 0 0.416833 0 0 0 0 0.508333 0 0 0 0.38 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter28_d_12072_7934"
          x="-0.0795656"
          y="3.0075"
          width="3.12074"
          height="4.6598"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.160799" />
          <feGaussianBlur stdDeviation="0.160799" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.446731 0 0 0 0 0.444028 0 0 0 0 0.579167 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter29_d_12072_7934"
          x="0.046411"
          y="4.9577"
          width="3.72718"
          height="3.54945"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-0.160799" />
          <feGaussianBlur stdDeviation="0.160799" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.446731 0 0 0 0 0.444028 0 0 0 0 0.579167 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter30_d_12072_7934"
          x="0.00655079"
          y="5.79693"
          width="5.67196"
          height="3.83065"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.166655" />
          <feGaussianBlur stdDeviation="0.401998" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.365689 0 0 0 0 0.351111 0 0 0 0 0.533333 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter31_d_12072_7934"
          x="2.31674"
          y="5.33008"
          width="4.41696"
          height="6.04684"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.0833274" dy="0.249982" />
          <feGaussianBlur stdDeviation="0.124991" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.7 0 0 0 0 0.526167 0 0 0 0 0.265417 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <filter
          id="filter32_f_12072_7934"
          x="2.47012"
          y="5.30508"
          width="3.95527"
          height="5.59687"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.0124991"
            result="effect1_foregroundBlur_12072_7934"
          />
        </filter>
        <filter
          id="filter33_f_12072_7934"
          x="3.43085"
          y="7.78681"
          width="3.24084"
          height="2.96447"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="0.166655"
            result="effect1_foregroundBlur_12072_7934"
          />
        </filter>
        <filter
          id="filter34_d_12072_7934"
          x="5.19304"
          y="9.30957"
          width="1.78121"
          height="2.11095"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.166655" dy="0.416637" />
          <feGaussianBlur stdDeviation="0.124991" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0504688 0 0 0 0 0.0958375 0 0 0 0 0.2125 0 0 0 0.36 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_12072_7934"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_12072_7934"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_12072_7934"
          x1="2.27765"
          y1="6.77625"
          x2="2.07165"
          y2="7.05161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12072_7934"
          x1="3.14171"
          y1="2.6287"
          x2="2.55438"
          y2="3.41378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_12072_7934"
          x1="3.7447"
          y1="2.37836"
          x2="3.33637"
          y2="2.92417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_12072_7934"
          x1="2.25418"
          y1="4.68422"
          x2="2.46985"
          y2="4.77333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_12072_7934"
          x1="3.62405"
          y1="2.74767"
          x2="3.29096"
          y2="2.07826"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_12072_7934"
          x1="3.3769"
          y1="1.98971"
          x2="3.19338"
          y2="2.70001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_12072_7934"
          x1="2.89447"
          y1="4.66394"
          x2="2.17778"
          y2="5.62195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_12072_7934"
          x1="2.73376"
          y1="4.77533"
          x2="2.95561"
          y2="5.3777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_12072_7934"
          x1="2.54337"
          y1="3.74473"
          x2="2.16229"
          y2="4.25412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_12072_7934"
          x1="2.47323"
          y1="4.04094"
          x2="2.30198"
          y2="4.35928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C18E42" />
          <stop offset="1" stop-color="#D2AB68" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_12072_7934"
          x1="2.8662"
          y1="2.84345"
          x2="2.38433"
          y2="3.48755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_12072_7934"
          x1="3.24738"
          y1="3.12274"
          x2="2.78442"
          y2="3.74159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_12072_7934"
          x1="2.01129"
          y1="5.04436"
          x2="1.84157"
          y2="5.27123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_12072_7934"
          x1="2.13979"
          y1="4.6178"
          x2="1.73981"
          y2="5.15245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C28766" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_12072_7934"
          x1="2.75464"
          y1="5.9186"
          x2="2.23184"
          y2="6.61743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_12072_7934"
          x1="2.21368"
          y1="5.74379"
          x2="1.88299"
          y2="6.18583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_12072_7934"
          x1="2.27183"
          y1="5.71507"
          x2="1.8827"
          y2="6.23523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_12072_7934"
          x1="2.21324"
          y1="7.1616"
          x2="2.05262"
          y2="7.3763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_12072_7934"
          x1="4.2462"
          y1="1.34161"
          x2="3.66985"
          y2="2.11202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCF1D5" />
          <stop offset="1" stop-color="#EBCE6A" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_12072_7934"
          x1="4.31159"
          y1="1.39079"
          x2="3.73524"
          y2="2.16119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_12072_7934"
          x1="3.89517"
          y1="2.10313"
          x2="3.37236"
          y2="2.80197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_12072_7934"
          x1="3.75591"
          y1="2.16791"
          x2="3.77449"
          y2="2.842"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_12072_7934"
          x1="2.98617"
          y1="2.70205"
          x2="2.81787"
          y2="3.51121"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_12072_7934"
          x1="2.70276"
          y1="2.87129"
          x2="2.43462"
          y2="3.55741"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_12072_7934"
          x1="3.1371"
          y1="3.19124"
          x2="3.14812"
          y2="3.64998"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDD78" />
          <stop offset="1" stop-color="#FFF1C8" stop-opacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_12072_7934"
          x1="3.17697"
          y1="3.44368"
          x2="2.77069"
          y2="3.98675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_12072_7934"
          x1="3.18365"
          y1="3.43555"
          x2="2.69086"
          y2="3.9336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDA92" />
          <stop offset="1" stop-color="#DCBA84" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_12072_7934"
          x1="2.03895"
          y1="3.73523"
          x2="2.31014"
          y2="4.52248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#BC8647" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_12072_7934"
          x1="2.28902"
          y1="3.93049"
          x2="1.9662"
          y2="4.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_12072_7934"
          x1="1.93627"
          y1="4.57792"
          x2="1.66318"
          y2="5.06973"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_12072_7934"
          x1="2.5714"
          y1="4.86236"
          x2="2.08324"
          y2="5.51489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_12072_7934"
          x1="2.43229"
          y1="4.74516"
          x2="2.20118"
          y2="5.57095"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C087" />
          <stop offset="0.28125" stop-color="#FFE49E" />
          <stop offset="1" stop-color="#753C1D" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_12072_7934"
          x1="1.86029"
          y1="5.05167"
          x2="1.67699"
          y2="5.23199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18253" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_12072_7934"
          x1="2.56465"
          y1="5.8358"
          x2="2.06704"
          y2="6.50096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_12072_7934"
          x1="2.41965"
          y1="5.88663"
          x2="2.21964"
          y2="6.61459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_12072_7934"
          x1="1.94906"
          y1="6.02621"
          x2="1.77033"
          y2="6.26513"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_12072_7934"
          x1="1.87723"
          y1="5.97741"
          x2="2.02593"
          y2="6.33159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#9A5E33" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_12072_7934"
          x1="2.17684"
          y1="6.92888"
          x2="1.98933"
          y2="7.17953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_12072_7934"
          x1="2.18532"
          y1="7.04011"
          x2="1.97324"
          y2="7.3236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_12072_7934"
          x1="9.52703"
          y1="6.77625"
          x2="9.73304"
          y2="7.05161"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_12072_7934"
          x1="8.66298"
          y1="2.6287"
          x2="9.25031"
          y2="3.41378"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_12072_7934"
          x1="8.05999"
          y1="2.37836"
          x2="8.46832"
          y2="2.92417"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_12072_7934"
          x1="9.5505"
          y1="4.68422"
          x2="9.33484"
          y2="4.77333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_12072_7934"
          x1="8.18064"
          y1="2.74767"
          x2="8.51373"
          y2="2.07826"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_12072_7934"
          x1="8.42779"
          y1="1.98971"
          x2="8.61131"
          y2="2.70001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_12072_7934"
          x1="8.91022"
          y1="4.66394"
          x2="9.62691"
          y2="5.62195"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_12072_7934"
          x1="9.07092"
          y1="4.77534"
          x2="8.84908"
          y2="5.37777"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_12072_7934"
          x1="9.26132"
          y1="3.74473"
          x2="9.6424"
          y2="4.25412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_12072_7934"
          x1="9.33145"
          y1="4.04094"
          x2="9.50271"
          y2="4.35928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C18E42" />
          <stop offset="1" stop-color="#D2AB68" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_12072_7934"
          x1="8.93849"
          y1="2.84345"
          x2="9.42035"
          y2="3.48755"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_12072_7934"
          x1="8.55731"
          y1="3.12274"
          x2="9.02027"
          y2="3.74159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_12072_7934"
          x1="9.7934"
          y1="5.04436"
          x2="9.96312"
          y2="5.27123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_12072_7934"
          x1="9.6649"
          y1="4.6178"
          x2="10.0649"
          y2="5.15245"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C28766" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_12072_7934"
          x1="9.05004"
          y1="5.9186"
          x2="9.57285"
          y2="6.61743"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_12072_7934"
          x1="9.59101"
          y1="5.74379"
          x2="9.9217"
          y2="6.18583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_12072_7934"
          x1="9.53286"
          y1="5.71507"
          x2="9.92199"
          y2="6.23523"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_12072_7934"
          x1="9.59145"
          y1="7.1616"
          x2="9.75207"
          y2="7.3763"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_12072_7934"
          x1="7.55849"
          y1="1.34161"
          x2="8.13484"
          y2="2.11202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FCF1D5" />
          <stop offset="1" stop-color="#EBCE6A" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_12072_7934"
          x1="7.4931"
          y1="1.39079"
          x2="8.06944"
          y2="2.16119"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_12072_7934"
          x1="7.90952"
          y1="2.10313"
          x2="8.43232"
          y2="2.80197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_12072_7934"
          x1="8.04878"
          y1="2.16791"
          x2="8.03019"
          y2="2.84199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_12072_7934"
          x1="8.81852"
          y1="2.70203"
          x2="8.98681"
          y2="3.51112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_12072_7934"
          x1="9.10192"
          y1="2.87131"
          x2="9.37008"
          y2="3.55747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_12072_7934"
          x1="8.66758"
          y1="3.19126"
          x2="8.65658"
          y2="3.65006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDD78" />
          <stop offset="1" stop-color="#FFF1C8" stop-opacity="0.38" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_12072_7934"
          x1="8.62772"
          y1="3.44368"
          x2="9.034"
          y2="3.98675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_12072_7934"
          x1="8.62103"
          y1="3.43555"
          x2="9.11383"
          y2="3.9336"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFDA92" />
          <stop offset="1" stop-color="#DCBA84" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_12072_7934"
          x1="9.76574"
          y1="3.73523"
          x2="9.49455"
          y2="4.52248"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#BC8647" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_12072_7934"
          x1="9.51568"
          y1="3.93048"
          x2="9.83849"
          y2="4.36197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#8E573C" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_12072_7934"
          x1="9.86833"
          y1="4.57802"
          x2="10.1415"
          y2="5.07001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_12072_7934"
          x1="9.23329"
          y1="4.86236"
          x2="9.72145"
          y2="5.51489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_12072_7934"
          x1="9.37333"
          y1="4.74718"
          x2="9.59349"
          y2="5.56978"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E0C087" />
          <stop offset="0.28125" stop-color="#FFE49E" />
          <stop offset="1" stop-color="#753C1D" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_12072_7934"
          x1="9.94438"
          y1="5.05169"
          x2="10.1277"
          y2="5.23204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B18253" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_12072_7934"
          x1="9.24004"
          y1="5.8358"
          x2="9.73765"
          y2="6.50096"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_12072_7934"
          x1="9.38503"
          y1="5.88664"
          x2="9.58505"
          y2="6.61462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7E6B3" />
          <stop offset="1" stop-color="#F7E6B3" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_12072_7934"
          x1="9.85563"
          y1="6.02621"
          x2="10.0344"
          y2="6.26513"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_12072_7934"
          x1="9.92746"
          y1="5.97741"
          x2="9.77875"
          y2="6.33159"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#9A5E33" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_12072_7934"
          x1="9.62785"
          y1="6.92888"
          x2="9.81536"
          y2="7.17953"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FADA81" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_12072_7934"
          x1="9.61937"
          y1="7.04011"
          x2="9.83145"
          y2="7.3236"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D2AB68" />
          <stop offset="1" stop-color="#B67D3F" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_12072_7934"
          x1="4.43025"
          y1="3.61082"
          x2="7.55503"
          y2="9.81871"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#C2C4CD" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_12072_7934"
          x1="4.40256"
          y1="4.12494"
          x2="7.09682"
          y2="9.0968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CDCFD6" />
          <stop offset="1" stop-color="#EFEFF1" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_12072_7934"
          x1="4.69421"
          y1="3.94439"
          x2="7.59678"
          y2="9.16624"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#DDDFE2" />
          <stop offset="1" stop-color="white" />
        </linearGradient>
        <radialGradient
          id="paint81_radial_12072_7934"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(5.90248 6.67143) rotate(90) scale(3.14408 2.70814)"
        >
          <stop stop-color="#F5F7FF" />
          <stop offset="1" stop-color="#B3B5C5" />
        </radialGradient>
        <linearGradient
          id="paint82_linear_12072_7934"
          x1="5.90259"
          y1="3.40332"
          x2="5.90259"
          y2="9.94021"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_12072_7934"
          x1="2.55897"
          y1="3.99524"
          x2="5.82741"
          y2="9.65635"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_12072_7934"
          x1="9.00233"
          y1="3.995"
          x2="5.73388"
          y2="9.65611"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="#F2EDED" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_12072_7934"
          x1="5.90248"
          y1="7.82313"
          x2="5.88614"
          y2="9.59739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#D0DBFF" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_12072_7934"
          x1="9.90217"
          y1="4.27701"
          x2="11.1937"
          y2="6.02688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0245272" stop-color="#DBBA8F" />
          <stop offset="0.344022" stop-color="#FFEFBE" />
          <stop offset="1" stop-color="#DEBD84" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_12072_7934"
          x1="12.7352"
          y1="4.19368"
          x2="11.4436"
          y2="5.7769"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A06E48" />
          <stop offset="0.368198" stop-color="#BD9666" />
          <stop offset="1" stop-color="#A88255" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_12072_7934"
          x1="11.2723"
          y1="3.62256"
          x2="13.04"
          y2="3.62256"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C08D4E" />
          <stop offset="1" stop-color="#E2C69F" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_12072_7934"
          x1="10.2772"
          y1="4.11092"
          x2="11.4854"
          y2="5.23584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#C49357" />
          <stop offset="0.483067" stop-color="white" stop-opacity="0.87" />
          <stop offset="1" stop-color="#DFC299" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_12072_7934"
          x1="11.0662"
          y1="3.74891"
          x2="13.0525"
          y2="3.89418"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_12072_7934"
          x1="12.3044"
          y1="10.4977"
          x2="12.8566"
          y2="10.5586"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_12072_7934"
          x1="10.2163"
          y1="10.8507"
          x2="10.8094"
          y2="10.9531"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_12072_7934"
          x1="12.0859"
          y1="8.0159"
          x2="12.6505"
          y2="8.14713"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_12072_7934"
          x1="11.8785"
          y1="6.79409"
          x2="12.3803"
          y2="6.88847"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.168737" stop-color="#F4F3F8" />
          <stop offset="0.360439" stop-color="white" />
          <stop offset="0.451944" stop-color="white" />
          <stop offset="0.627859" stop-color="#E7E6EE" />
          <stop offset="0.80404" stop-color="#F3F3F3" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_12072_7934"
          x1="10.748"
          y1="1.2195"
          x2="9.82917"
          y2="5.13611"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_12072_7934"
          x1="10.7706"
          y1="1.22532"
          x2="8.67769"
          y2="6.16778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.575358" stop-color="#F8F8F9" />
          <stop offset="0.783289" stop-color="white" />
          <stop offset="0.884809" stop-color="white" stop-opacity="0.19" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_12072_7934"
          x1="10.6448"
          y1="3.14639"
          x2="9.89095"
          y2="6.28254"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_12072_7934"
          x1="10.6798"
          y1="3.15557"
          x2="9.14228"
          y2="7.19675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.292773" stop-color="#F8F8F9" stop-opacity="0.45" />
          <stop offset="0.629289" stop-color="white" stop-opacity="0.51" />
          <stop offset="0.742389" stop-color="white" stop-opacity="0.12" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_12072_7934"
          x1="9.55473"
          y1="6.31279"
          x2="9.49943"
          y2="6.9726"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_12072_7934"
          x1="10.1168"
          y1="4.99302"
          x2="9.50732"
          y2="7.4545"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_12072_7934"
          x1="10.1497"
          y1="5.00148"
          x2="9.08561"
          y2="8.24436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.21882" stop-color="#F8F8F9" stop-opacity="0.67" />
          <stop offset="0.45675" stop-color="white" stop-opacity="0.46" />
          <stop offset="0.742389" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_12072_7934"
          x1="9.32655"
          y1="7.06352"
          x2="9.22883"
          y2="7.64696"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_12072_7934"
          x1="9.30731"
          y1="7.21578"
          x2="9.48685"
          y2="8.5925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFEFEF" />
          <stop offset="0.411026" stop-color="#DEDFE6" />
          <stop offset="0.58979" stop-color="#D1D2D9" />
          <stop offset="1" stop-color="#D1D2D9" />
        </linearGradient>
        <linearGradient
          id="paint104_linear_12072_7934"
          x1="10.7753"
          y1="6.88814"
          x2="9.28209"
          y2="8.42578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.86" />
          <stop offset="0.319253" stop-color="#F8F8F9" />
          <stop offset="0.615431" stop-color="white" stop-opacity="0.88" />
          <stop offset="0.867925" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint105_linear_12072_7934"
          x1="8.70559"
          y1="8.21726"
          x2="8.39164"
          y2="7.97435"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_12072_7934"
          x1="8.2342"
          y1="8.17164"
          x2="8.3329"
          y2="9.8707"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0306468" stop-color="#DBDCE7" />
          <stop offset="0.274204" stop-color="#CBCCD7" />
          <stop offset="0.675444" stop-color="#D1D2D9" />
        </linearGradient>
        <linearGradient
          id="paint107_linear_12072_7934"
          x1="8.54286"
          y1="8.25045"
          x2="7.82149"
          y2="10.0295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.78" />
          <stop offset="0.319253" stop-color="#F8F8F9" stop-opacity="0" />
          <stop offset="0.615431" stop-color="white" stop-opacity="0" />
          <stop offset="0.867925" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint108_linear_12072_7934"
          x1="9.21758"
          y1="5.42248"
          x2="8.97499"
          y2="6.36388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint109_linear_12072_7934"
          x1="9.15192"
          y1="5.64975"
          x2="5.55804"
          y2="10.7471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#594530" />
          <stop offset="0.667292" stop-color="#7A5D3F" />
          <stop offset="1" stop-color="#564029" />
        </linearGradient>
        <linearGradient
          id="paint110_linear_12072_7934"
          x1="9.54705"
          y1="5.50777"
          x2="5.08983"
          y2="10.2823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#926C45" />
          <stop offset="0.198565" stop-color="#B98D5F" />
          <stop offset="0.568549" stop-color="#B98D5F" />
          <stop offset="1" stop-color="#94693C" />
        </linearGradient>
        <linearGradient
          id="paint111_linear_12072_7934"
          x1="8.33344"
          y1="7.48955"
          x2="8.4344"
          y2="8.43343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B98D5F" />
          <stop offset="0.0810439" stop-color="#B98D5F" />
          <stop offset="0.312564" stop-color="#ECD4AF" />
          <stop offset="0.465883" stop-color="#FDEED8" />
          <stop offset="0.619479" stop-color="#ECD4AF" />
          <stop offset="0.845306" stop-color="#B98D5F" />
          <stop offset="1" stop-color="#B98D5F" />
        </linearGradient>
        <linearGradient
          id="paint112_linear_12072_7934"
          x1="8.92474"
          y1="7.25484"
          x2="5.5716"
          y2="9.80406"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00453841" stop-color="#B98D5F" />
          <stop offset="0.184436" stop-color="white" />
          <stop offset="0.808004" stop-color="#D3B790" />
          <stop offset="0.912608" stop-color="#F7E1C2" />
          <stop offset="1" stop-color="#D3B790" />
        </linearGradient>
        <linearGradient
          id="paint113_linear_12072_7934"
          x1="1.00984"
          y1="1.21949"
          x2="1.92865"
          y2="5.13609"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint114_linear_12072_7934"
          x1="0.987209"
          y1="1.22532"
          x2="3.08013"
          y2="6.16778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.575358" stop-color="#F8F8F9" />
          <stop offset="0.783289" stop-color="white" />
          <stop offset="0.884809" stop-color="white" stop-opacity="0.19" />
        </linearGradient>
        <linearGradient
          id="paint115_linear_12072_7934"
          x1="1.11296"
          y1="3.1464"
          x2="1.86685"
          y2="6.28256"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint116_linear_12072_7934"
          x1="1.07846"
          y1="3.15557"
          x2="2.61603"
          y2="7.19675"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.292773" stop-color="#F8F8F9" stop-opacity="0.45" />
          <stop offset="0.629289" stop-color="white" stop-opacity="0.51" />
          <stop offset="0.742389" stop-color="white" stop-opacity="0.12" />
        </linearGradient>
        <linearGradient
          id="paint117_linear_12072_7934"
          x1="2.20358"
          y1="6.31279"
          x2="2.25887"
          y2="6.9726"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint118_linear_12072_7934"
          x1="1.64147"
          y1="4.99302"
          x2="2.251"
          y2="7.4545"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.220856" stop-color="#FAFAFA" />
          <stop offset="1" stop-color="#D8D9E1" />
        </linearGradient>
        <linearGradient
          id="paint119_linear_12072_7934"
          x1="1.60863"
          y1="5.00148"
          x2="2.67269"
          y2="8.24436"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.46" />
          <stop offset="0.21882" stop-color="#F8F8F9" stop-opacity="0.67" />
          <stop offset="0.45675" stop-color="white" stop-opacity="0.46" />
          <stop offset="0.742389" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint120_linear_12072_7934"
          x1="2.43176"
          y1="7.06352"
          x2="2.52947"
          y2="7.64696"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint121_linear_12072_7934"
          x1="2.45099"
          y1="7.21578"
          x2="2.27145"
          y2="8.5925"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EFEFEF" />
          <stop offset="0.411026" stop-color="#DEDFE6" />
          <stop offset="0.58979" stop-color="#D1D2D9" />
          <stop offset="1" stop-color="#D1D2D9" />
        </linearGradient>
        <linearGradient
          id="paint122_linear_12072_7934"
          x1="0.982981"
          y1="6.88814"
          x2="2.47621"
          y2="8.42578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.86" />
          <stop offset="0.319253" stop-color="#F8F8F9" />
          <stop offset="0.615431" stop-color="white" stop-opacity="0.88" />
          <stop offset="0.867925" stop-color="white" />
        </linearGradient>
        <linearGradient
          id="paint123_linear_12072_7934"
          x1="3.05271"
          y1="8.21726"
          x2="3.36666"
          y2="7.97435"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.374859" stop-color="#AD8357" />
          <stop offset="0.986946" stop-color="#714538" />
        </linearGradient>
        <linearGradient
          id="paint124_linear_12072_7934"
          x1="3.5241"
          y1="8.17164"
          x2="3.4254"
          y2="9.8707"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0306468" stop-color="#DBDCE7" />
          <stop offset="0.274204" stop-color="#CBCCD7" />
          <stop offset="0.675444" stop-color="#D1D2D9" />
        </linearGradient>
        <linearGradient
          id="paint125_linear_12072_7934"
          x1="3.21544"
          y1="8.25045"
          x2="3.93681"
          y2="10.0295"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F8F8F9" stop-opacity="0.78" />
          <stop offset="0.319253" stop-color="#F8F8F9" stop-opacity="0" />
          <stop offset="0.615431" stop-color="white" stop-opacity="0" />
          <stop offset="0.867925" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint126_linear_12072_7934"
          x1="2.54072"
          y1="5.42248"
          x2="2.78331"
          y2="6.36388"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint127_linear_12072_7934"
          x1="2.60638"
          y1="5.64975"
          x2="6.20026"
          y2="10.7471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#594530" />
          <stop offset="0.667292" stop-color="#7A5D3F" />
          <stop offset="1" stop-color="#564029" />
        </linearGradient>
        <linearGradient
          id="paint128_linear_12072_7934"
          x1="2.21126"
          y1="5.50777"
          x2="6.66847"
          y2="10.2823"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#926C45" />
          <stop offset="0.198565" stop-color="#B98D5F" />
          <stop offset="0.568549" stop-color="#B98D5F" />
          <stop offset="1" stop-color="#94693C" />
        </linearGradient>
        <linearGradient
          id="paint129_linear_12072_7934"
          x1="3.42486"
          y1="7.48955"
          x2="3.3239"
          y2="8.43343"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#B98D5F" />
          <stop offset="0.0810439" stop-color="#B98D5F" />
          <stop offset="0.312564" stop-color="#ECD4AF" />
          <stop offset="0.465883" stop-color="#FDEED8" />
          <stop offset="0.619479" stop-color="#ECD4AF" />
          <stop offset="0.845306" stop-color="#B98D5F" />
          <stop offset="1" stop-color="#B98D5F" />
        </linearGradient>
        <linearGradient
          id="paint130_linear_12072_7934"
          x1="2.83356"
          y1="7.25484"
          x2="6.1867"
          y2="9.80406"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.00453841" stop-color="#B98D5F" />
          <stop offset="0.184436" stop-color="white" />
          <stop offset="0.808004" stop-color="#D3B790" />
          <stop offset="0.912608" stop-color="#F7E1C2" />
          <stop offset="1" stop-color="#D3B790" />
        </linearGradient>
        <linearGradient
          id="paint131_linear_12072_7934"
          x1="5.9807"
          y1="9.70189"
          x2="5.49039"
          y2="10.349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D5D7D9" />
          <stop offset="1" stop-color="#E2E3E7" />
        </linearGradient>
        <linearGradient
          id="paint132_linear_12072_7934"
          x1="5.85697"
          y1="10.5797"
          x2="6.67072"
          y2="10.5238"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#9A9CA5" />
          <stop offset="0.259135" stop-color="#C4C6CF" />
          <stop offset="0.878536" stop-color="#E2E3E7" />
        </linearGradient>
        <linearGradient
          id="paint133_linear_12072_7934"
          x1="5.46832"
          y1="9.55451"
          x2="6.09667"
          y2="9.4525"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F4F5F8" />
          <stop offset="1" stop-color="#F5F6F7" />
        </linearGradient>
        <linearGradient
          id="paint134_linear_12072_7934"
          x1="5.87471"
          y1="9.48501"
          x2="6.16636"
          y2="9.51279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#916437" />
          <stop offset="0.522184" stop-color="#AF8356" />
          <stop offset="1" stop-color="#AF8356" />
        </linearGradient>
      </defs>
    </svg>
  ),
};
