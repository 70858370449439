import { XStack } from "@/components/common/stacks";
import "./styles/index.css";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "@/components/common/avatar";
import TwinValue from "@/components/common/twinValue";
import { CardAvatarIcons } from "@/assets/images/cards";
import SmartFilter from "@/components/common/smartFilter";
import { FakePaginationProps } from "@/utils/helpers";
import { useNavigate } from "react-router-dom";

const dummyCards = ["verve", "visa", "master"] as const;

export const PartnersCard = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Cards">
        <SmartFilter pagination={FakePaginationProps} />
        <RavenTable
          headerList={[
            "BUSINESS DETAILS",
            "CARD NAME",
            "CARD NUMBER",
            "CURR.",
            "BALANCE",
            "EXP. DATE",
            "DATE CREATED",
          ]}
        >
          {dummyCards.map((card) => (
            <RavenTableRow
              key={card}
              one={
                <XStack gap={0.8}>
                  <Avatar name="B V" />
                  <TwinValue one="Bolade Ventures" two="emmy4sure.web@gmail.com" />
                </XStack>
              }
              two="Rehk Mansa"
              three={<Avatar name="28473****1024" full imgSrc={CardAvatarIcons[card]} />}
              four="USD"
              five="$200"
              six="12 / 25"
              seven="5 Sept, 2022 - 5:48PM"
              onRowClick={() => navigate("/atlas-cards/idxs")}
            />
          ))}
        </RavenTable>
      </PageLayout>
    </DashboardLayout>
  );
};
