export const partnersIcons = {
  sidebar_overview: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_140107)">
        <path
          fill="#7F7F7F"
          d="M.727 5.303 7.013.54a1.63 1.63 0 0 1 1.974 0l6.286 4.763c.46.347.727.89.727 1.464v7.656c0 .757-.613 1.367-1.367 1.367H1.367A1.366 1.366 0 0 1 0 14.423V6.767C0 6.19.27 5.65.727 5.303"
        ></path>
        <path
          fill="#E2E2E2"
          d="M6.02 8.644h4.086c.677 0 1.227.55 1.227 1.226v5.924h-6.54V9.87c0-.676.55-1.226 1.227-1.226"
        ></path>
        <path
          fill="#BFBFBF"
          d="M8 6.313A1.107 1.107 0 1 0 8 4.1a1.107 1.107 0 0 0 0 2.213"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_140107">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_overview_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158879)">
        <path
          fill="#476885"
          d="M.727 5.303 7.013.54a1.63 1.63 0 0 1 1.974 0l6.286 4.763c.46.347.727.89.727 1.464v7.656c0 .757-.613 1.367-1.367 1.367H1.367A1.366 1.366 0 0 1 0 14.423V6.767C0 6.19.27 5.65.727 5.303"
        ></path>
        <path
          fill="#E9F5FF"
          d="M6.02 8.644h4.086c.677 0 1.227.55 1.227 1.226v5.924h-6.54V9.87c0-.676.55-1.226 1.227-1.226"
        ></path>
        <path
          fill="#B9DEFE"
          d="M8 6.313A1.107 1.107 0 1 0 8 4.1a1.107 1.107 0 0 0 0 2.213"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158879">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_insights: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7F7F7F"
        d="M15.947 7.807c.07.12.07.266 0 .386A9.23 9.23 0 0 1 8 12.717 9.23 9.23 0 0 1 .053 8.193a.38.38 0 0 1 0-.386A9.23 9.23 0 0 1 8 3.283a9.24 9.24 0 0 1 7.947 4.524"
      ></path>
      <path
        fill="#E2E2E2"
        d="M8 11.82a3.82 3.82 0 1 0 0-7.64 3.82 3.82 0 0 0 0 7.64"
      ></path>
      <path
        fill="#BFBFBF"
        d="M8 10.137a2.137 2.137 0 1 0 0-4.274 2.137 2.137 0 0 0 0 4.274"
      ></path>
    </svg>
  ),
  sidebar_insights_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#476885"
        d="M15.947 7.807c.07.12.07.266 0 .386A9.23 9.23 0 0 1 8 12.717 9.23 9.23 0 0 1 .053 8.192a.38.38 0 0 1 0-.386A9.23 9.23 0 0 1 8 3.283a9.24 9.24 0 0 1 7.947 4.524"
      ></path>
      <path
        fill="#E9F5FF"
        d="M8 11.82a3.82 3.82 0 1 0 0-7.64 3.82 3.82 0 0 0 0 7.64"
      ></path>
      <path
        fill="#B9DEFE"
        d="M8 10.137a2.137 2.137 0 1 0 0-4.274 2.137 2.137 0 0 0 0 4.274"
      ></path>
    </svg>
  ),
  sidebar_partners: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15917_551)">
        <path
          fill="#7F7F7F"
          d="M9.554 8c.033-.07.076-.14.12-.2zM10.337 5.303q0 .19-.014.374a5 5 0 0 0-2.506.89 5.1 5.1 0 0 0-1.43 1.523c-.4.66-.654 1.42-.714 2.233q-.186.014-.373.014A5.033 5.033 0 0 1 .267 5.303a5.037 5.037 0 0 1 10.073 0z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M7.816 6.567a5.1 5.1 0 0 0-1.43 1.523H2.83a.376.376 0 0 1-.374-.43c.15-1 .817-2.333 1.717-2.717a2.88 2.88 0 0 1 2.1-.06c.66.237 1.207.934 1.543 1.684"
        ></path>
        <path
          fill="#BFBFBF"
          d="M6.57 3.157a1.268 1.268 0 1 1-2.533-.003 1.27 1.27 0 1 1 2.54 0z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M10.323 5.677A5 5 0 0 1 9.676 7.8q-.07.095-.12.2a5.03 5.03 0 0 1-3.876 2.323c.06-.813.313-1.573.713-2.233a5.1 5.1 0 0 1 1.43-1.523 5 5 0 0 1 2.506-.89z"
        ></path>
        <path
          fill="#BFBFBF"
          d="M15.733 10.697a5.037 5.037 0 1 1-10.06-.374A5.03 5.03 0 0 0 9.55 8c.033-.07.077-.14.12-.2a5 5 0 0 0 .647-2.123 5.037 5.037 0 0 1 5.41 5.023z"
        ></path>
        <path
          fill="#7F7F7F"
          d="M13.17 13.487H8.23a.376.376 0 0 1-.373-.43c.15-1 .816-2.334 1.716-2.717a2.88 2.88 0 0 1 2.1-.06c.98.353 1.714 1.72 1.874 2.777a.376.376 0 0 1-.374.43z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M11.967 8.553A1.264 1.264 0 0 1 10.7 9.82a1.268 1.268 0 0 1-1.267-1.27 1.27 1.27 0 0 1 .244-.75 1.27 1.27 0 0 1 2.29.753"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15917_551">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_partners_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158881)">
        <path
          fill="#476885"
          d="M9.554 8c.033-.07.076-.14.12-.2zM10.337 5.303q0 .19-.014.374a5 5 0 0 0-2.506.89 5.1 5.1 0 0 0-1.43 1.523c-.4.66-.654 1.42-.714 2.233q-.187.014-.373.014A5.033 5.033 0 0 1 .267 5.303a5.037 5.037 0 0 1 10.073 0z"
        ></path>
        <path
          fill="#E9F5FF"
          d="M7.816 6.567a5.1 5.1 0 0 0-1.43 1.523H2.83a.376.376 0 0 1-.374-.43c.15-1 .817-2.333 1.717-2.717a2.88 2.88 0 0 1 2.1-.06c.66.237 1.207.934 1.543 1.684"
        ></path>
        <path
          fill="#B9DEFE"
          d="M6.57 3.157a1.268 1.268 0 1 1-2.533-.003 1.27 1.27 0 1 1 2.54 0z"
        ></path>
        <path
          fill="#E9F5FF"
          d="M10.323 5.677A5 5 0 0 1 9.676 7.8q-.069.095-.12.2a5.03 5.03 0 0 1-3.876 2.323c.06-.813.313-1.573.713-2.233a5.1 5.1 0 0 1 1.43-1.523 5 5 0 0 1 2.507-.89z"
        ></path>
        <path
          fill="#B9DEFE"
          d="M15.733 10.697a5.037 5.037 0 1 1-10.06-.373A5.03 5.03 0 0 0 9.55 8c.033-.07.077-.14.12-.2a5 5 0 0 0 .647-2.123 5.037 5.037 0 0 1 5.41 5.023z"
        ></path>
        <path
          fill="#476885"
          d="M13.17 13.487H8.23a.376.376 0 0 1-.373-.43c.15-1 .816-2.333 1.716-2.717a2.88 2.88 0 0 1 2.1-.06c.98.354 1.714 1.72 1.874 2.777a.376.376 0 0 1-.374.43z"
        ></path>
        <path
          fill="#E9F5FF"
          d="M11.967 8.554A1.264 1.264 0 0 1 10.7 9.82a1.268 1.268 0 0 1-1.267-1.27 1.27 1.27 0 0 1 .244-.75 1.27 1.27 0 0 1 2.29.753"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158881">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_partners_merchants: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15913_565)">
        <path
          fill="#BFBFBF"
          d="M12.593 1.303v5.23H3.406v-5.23C3.406.583 3.99 0 4.71 0h6.583c.72 0 1.303.583 1.303 1.303z"
        ></path>
        <path fill="#E2E2E2" d="M12.593 6.53H3.406v5.2h9.187z"></path>
        <path
          fill="#7F7F7F"
          d="M16 6.97v7.563c0 .81-.657 1.464-1.463 1.464H1.463A1.465 1.465 0 0 1 0 14.533V6.967a.44.44 0 0 1 .44-.44h2.967v5.2h9.186v-5.2h2.967a.44.44 0 0 1 .44.44zM6.243 5.293l1.434-3.566h.633l1.447 3.566h-.704l-.346-.87h-1.44l-.337.87h-.687m1.254-1.45h.956q-.28-.834-.473-1.39-.16.466-.483 1.39"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15913_565">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_partners_merchants_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158882)">
        <path
          fill="#B9DEFE"
          d="M12.593 1.303v5.23H3.406v-5.23C3.406.583 3.99 0 4.71 0h6.583c.72 0 1.303.583 1.303 1.303z"
        ></path>
        <path fill="#E9F5FF" d="M12.593 6.53H3.406v5.2h9.187z"></path>
        <path
          fill="#476885"
          d="M16 6.97v7.564c0 .81-.657 1.463-1.463 1.463H1.463A1.465 1.465 0 0 1 0 14.534V6.967a.44.44 0 0 1 .44-.44h2.967v5.2h9.186v-5.2h2.967a.44.44 0 0 1 .44.44zM6.243 5.293l1.434-3.566h.633l1.447 3.566h-.704l-.346-.87h-1.44l-.337.87h-.687m1.254-1.45h.956q-.28-.835-.473-1.39-.16.466-.483 1.39"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158882">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_transactions: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15913_574)">
        <path
          fill="#BFBFBF"
          d="M11.35 6.153 9.847 4.65 3.25 11.247l-.84-.84a1.77 1.77 0 0 1 0-2.504l4.63-4.63a.44.44 0 0 0 0-.623L5.79 1.4a.31.31 0 0 1 .22-.53h9.4c.45 0 .677.547.357.867l-4.42 4.42z"
        ></path>
        <path
          fill="#7F7F7F"
          d="m11.246 3.25 2.347 2.347c.69.69.69 1.813 0 2.503l-4.63 4.63a.44.44 0 0 0 0 .623l1.25 1.25a.31.31 0 0 1-.22.53H.59a.507.507 0 0 1-.357-.866z"
        ></path>
        <path fill="#E2E2E2" d="M11.246 3.25 3.25 11.249l1.504 1.504 7.997-7.998z"></path>
      </g>
      <defs>
        <clipPath id="clip0_15913_574">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_transactions_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158883)">
        <path
          fill="#B9DEFE"
          d="M11.35 6.153 9.847 4.65 3.25 11.247l-.84-.84a1.77 1.77 0 0 1 0-2.504l4.63-4.63a.44.44 0 0 0 0-.623L5.79 1.4a.31.31 0 0 1 .22-.53h9.4c.45 0 .677.547.357.867l-4.42 4.42z"
        ></path>
        <path
          fill="#476885"
          d="m11.246 3.25 2.347 2.347c.69.69.69 1.813 0 2.503l-4.63 4.63a.44.44 0 0 0 0 .623l1.25 1.25a.31.31 0 0 1-.22.53H.59a.507.507 0 0 1-.357-.866z"
        ></path>
        <path fill="#E9F5FF" d="M11.246 3.25 3.25 11.249l1.504 1.504 7.997-7.998z"></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158883">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_fee_details: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7F7F7F"
        d="M12.37 1.777V9.12c0 .693-.56 1.253-1.253 1.253H5.433a.52.52 0 0 1-.523-.523V1.537A1.33 1.33 0 0 1 5.507.43q-.036.024-.07.053c-.01.01-.024.017-.034.027q-.039.03-.073.067-.012.009-.023.023-.015.014-.03.033l-.03.034q-.095.108-.164.236c-.006.01-.01.024-.016.034q-.02.04-.04.083-.007.018-.014.037l-.01.03c-.003.013-.01.026-.013.04a.2.2 0 0 1-.013.04l-.014.046c0 .01-.006.024-.006.034-.014.053-.02.103-.027.16v.03q-.002.048-.003.096v.247h7.446z"
      ></path>
      <path
        fill="#BFBFBF"
        d="M11.093 2.573H6.186v.814h4.907zM11.093 4.11H6.186v.813h4.907zM11.093 5.65H6.186v.813h4.907zM13.697.2c-.733 0-1.327.593-1.327 1.327v.246H4.924V1.4a1 1 0 0 1 .026-.16q.001-.016.007-.033a.3.3 0 0 1 .013-.047.2.2 0 0 1 .014-.04c.003-.013.01-.027.013-.04 0-.01.007-.02.01-.03q.006-.018.013-.037L5.06.93q.008-.017.017-.033.07-.127.163-.237l.03-.033.03-.034q.01-.01.024-.023a1.3 1.3 0 0 1 .29-.217q.023-.01.046-.023c.177-.087.377-.137.587-.137h7.447z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M13.697.2v1.577H12.37V1.53c0-.733.593-1.327 1.327-1.327z"
      ></path>
      <path
        fill="#7F7F7F"
        d="M9.764 7.2v8.077c0 .29-.234.523-.524.523H2.827a.52.52 0 0 1-.523-.523V6.96A1.33 1.33 0 0 1 2.9 5.853a1 1 0 0 0-.07.054q-.017.013-.033.026A1 1 0 0 0 2.724 6q-.012.01-.024.023-.015.014-.03.034l-.03.033q-.094.108-.163.237c-.007.01-.01.023-.017.033q-.02.042-.04.083-.006.02-.013.037l-.01.03q-.007.02-.013.04a.2.2 0 0 1-.014.04c-.003.017-.01.03-.013.047 0 .01-.007.023-.007.033-.013.053-.02.103-.026.16v.03c0 .033-.004.063-.004.097v.246h7.447z"
      ></path>
      <path
        fill="#BFBFBF"
        d="M8.487 7.997H3.58v.813h4.907zM8.487 9.533H3.58v.814h4.907zM8.487 11.073H3.58v.814h4.907zM8.487 12.61H3.58v.813h4.907zM8.487 14.147H3.58v.813h4.907zM11.09 5.623c-.733 0-1.326.594-1.326 1.327v.247H2.317v-.37399999999999994a1 1 0 0 1 .027-.16q0-.017.007-.033a.3.3 0 0 1 .013-.047.2.2 0 0 1 .013-.04l.014-.04q.002-.015.01-.03.007-.018.013-.036.02-.044.04-.084c.007-.01.01-.023.017-.033q.069-.125.163-.237l.03-.033.03-.033q.01-.012.023-.024a1.3 1.3 0 0 1 .29-.216q.023-.012.047-.024c.177-.086.377-.136.587-.136h7.446z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M11.09 5.623v4.724H9.764V6.95c0-.733.593-1.327 1.326-1.327"
      ></path>
    </svg>
  ),
  sidebar_fee_details_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#476885"
        d="M12.37 1.777V9.12c0 .693-.56 1.253-1.253 1.253H5.433a.52.52 0 0 1-.523-.523V1.537A1.33 1.33 0 0 1 5.507.43q-.036.024-.07.053c-.01.01-.024.017-.034.027q-.039.03-.073.067-.012.009-.023.023-.015.014-.03.033l-.03.034q-.095.108-.164.236c-.006.01-.01.024-.016.034q-.02.04-.04.083-.007.018-.014.037l-.01.03q-.006.02-.013.04a.2.2 0 0 1-.013.04l-.014.046c0 .01-.006.024-.006.034-.014.053-.02.103-.027.16v.03q-.002.048-.003.096v.247h7.446z"
      ></path>
      <path
        fill="#B9DEFE"
        d="M11.093 2.573H6.186v.814h4.907zM11.093 4.11H6.186v.813h4.907zM11.093 5.65H6.186v.813h4.907zM13.697.2c-.733 0-1.327.593-1.327 1.327v.246H4.924V1.4a1 1 0 0 1 .026-.16q.001-.017.007-.033a.3.3 0 0 1 .013-.047q.005-.02.014-.04.006-.02.013-.04c0-.01.007-.02.01-.03q.006-.018.013-.037L5.06.93c.007-.01.01-.023.017-.033Q5.147.77 5.24.66l.03-.033.03-.034q.01-.01.024-.023a1.3 1.3 0 0 1 .29-.217q.023-.01.046-.023c.177-.087.377-.137.587-.137h7.447z"
      ></path>
      <path
        fill="#E9F5FF"
        d="M13.697.2v1.577H12.37V1.53c0-.733.593-1.326 1.327-1.326z"
      ></path>
      <path
        fill="#476885"
        d="M9.764 7.2v8.077c0 .29-.234.523-.524.523H2.827a.52.52 0 0 1-.523-.523V6.96c0-.493.27-.923.67-1.153q-.04.02-.074.046a1 1 0 0 0-.07.054q-.017.013-.033.026A1 1 0 0 0 2.724 6q-.012.01-.024.023-.015.014-.03.034l-.03.033q-.094.108-.163.237c-.007.01-.01.023-.017.033q-.02.042-.04.083-.006.02-.013.037l-.01.03q-.007.02-.013.04a.2.2 0 0 1-.014.04c-.003.017-.01.03-.013.047 0 .01-.007.023-.007.033-.013.053-.02.103-.026.16v.03c0 .033-.004.063-.004.097v.246h7.447z"
      ></path>
      <path
        fill="#B9DEFE"
        d="M8.487 7.997H3.58v.813h4.907zM8.487 9.533H3.58v.814h4.907zM8.487 11.073H3.58v.814h4.907zM8.487 12.61H3.58v.813h4.907zM8.487 14.147H3.58v.813h4.907zM11.09 5.623c-.733 0-1.326.594-1.326 1.327v.247H2.317v-.37399999999999994a1 1 0 0 1 .027-.16q0-.016.007-.033a.3.3 0 0 1 .013-.047.2.2 0 0 1 .013-.04l.014-.04q.002-.015.01-.03.007-.018.013-.036.02-.044.04-.084c.007-.01.01-.023.017-.033q.069-.125.163-.237l.03-.033.03-.033q.01-.012.023-.024a1.3 1.3 0 0 1 .29-.216q.023-.012.047-.024c.177-.086.377-.136.587-.136h7.446z"
      ></path>
      <path
        fill="#E9F5FF"
        d="M11.09 5.624v4.723H9.764V6.95c0-.733.593-1.326 1.326-1.326"
      ></path>
    </svg>
  ),
  sidebar_terminals: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15917_607)">
        <path
          fill="#8B8B8B"
          d="M9.067 2.633h-6.4c-.442 0-.8.336-.8.75v9.5c0 .415.358.75.8.75h6.4c.441 0 .8-.335.8-.75v-9.5c0-.414-.359-.75-.8-.75"
        ></path>
        <g filter="url(#filter0_i_15917_607)">
          <path
            fill="#E3E3E3"
            d="M4.835 12.633c.777 0 1.407-.335 1.407-.75 0-.414-.63-.75-1.407-.75-.776 0-1.406.336-1.406.75 0 .415.63.75 1.406.75"
          ></path>
        </g>
        <path
          fill="#E3E3E3"
          d="M9.523 9.633H-.79c-.258 0-.468-.092-.468-.207V3.84c0-.114.21-.207.468-.207H9.523c.259 0 .469.093.469.207v5.586c0 .115-.21.207-.47.207"
        ></path>
        <path
          fill="#8B8B8B"
          d="M13.398 2.133H6.835a.47.47 0 0 0-.468.47V7.29c0 .258.21.468.468.468h6.563c.259 0 .469-.21.469-.468V2.602a.47.47 0 0 0-.47-.469"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          fill="#fff"
          d="M13.867 3.386h-7.5v1.34h7.5zM9.715 5.529H7.572a.402.402 0 1 0 0 .803h2.143a.402.402 0 0 0 0-.803"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15917_607">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
        <filter
          id="filter0_i_15917_607"
          width="2.813"
          height="3.375"
          x="3.429"
          y="11.133"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.875"></feOffset>
          <feGaussianBlur stdDeviation="0.938"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_15917_607"></feBlend>
        </filter>
      </defs>
    </svg>
  ),
  sidebar_terminals_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158885)">
        <path
          fill="#476885"
          d="M9.067 2.633h-6.4c-.442 0-.8.336-.8.75v9.5c0 .415.358.75.8.75h6.4c.442 0 .8-.335.8-.75v-9.5c0-.414-.358-.75-.8-.75"
        ></path>
        <g filter="url(#filter0_i_16055_158885)">
          <path
            fill="#E9F5FF"
            d="M4.835 12.633c.777 0 1.407-.335 1.407-.75 0-.414-.63-.75-1.407-.75-.776 0-1.406.336-1.406.75 0 .415.63.75 1.406.75"
          ></path>
        </g>
        <path
          fill="#E9F5FF"
          d="M9.523 9.633H-.79c-.258 0-.468-.092-.468-.207V3.84c0-.114.21-.207.468-.207H9.523c.259 0 .469.093.469.207v5.586c0 .115-.21.207-.47.207"
        ></path>
        <path
          fill="#476885"
          d="M13.398 2.133H6.835a.47.47 0 0 0-.468.47V7.29c0 .258.21.468.468.468h6.563c.259 0 .469-.21.469-.468V2.602a.47.47 0 0 0-.47-.469"
          style={{ mixBlendMode: "multiply" }}
        ></path>
        <path
          fill="#fff"
          d="M13.867 3.386h-7.5v1.34h7.5zM9.715 5.529H7.572a.402.402 0 1 0 0 .803h2.143a.402.402 0 0 0 0-.803"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158885">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
        <filter
          id="filter0_i_16055_158885"
          width="2.813"
          height="3.375"
          x="3.429"
          y="11.133"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="1.875"></feOffset>
          <feGaussianBlur stdDeviation="0.938"></feGaussianBlur>
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"></feColorMatrix>
          <feBlend in2="shape" result="effect1_innerShadow_16055_158885"></feBlend>
        </filter>
      </defs>
    </svg>
  ),
  sidebar_payment_links: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#BFBFBF"
        d="M12.277 2.173v4.494H4.44c-.383 0-.697.3-.717.68v1.99H.977A.716.716 0 0 1 .26 8.62V2.173c0-.396.323-.716.717-.716h10.58c.396 0 .716.323.716.716z"
      ></path>
      <path
        fill="#7F7F7F"
        d="M15.74 7.383v6.444a.72.72 0 0 1-.716.716H4.44a.72.72 0 0 1-.716-.716V7.353v1.98h7.836c.397 0 .717-.32.717-.716v-1.95h2.743c.397 0 .717.32.717.716z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M12.277 6.667v1.95a.72.72 0 0 1-.717.716H3.724v-1.99a.72.72 0 0 1 .716-.68h7.837z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M4.44 6.667c-.383 0-.696.3-.716.68a.69.69 0 0 1 .686-.68zM12.277 8.617v.086a.63.63 0 0 1-.634.63h-.086c.396 0 .716-.32.716-.716z"
      ></path>
      <path
        fill="#7F7F7F"
        d="M3.62 3.08H1.76a.103.103 0 0 0-.104.103v.94c0 .057.047.104.104.104h1.86a.103.103 0 0 0 .103-.104v-.94a.103.103 0 0 0-.103-.103"
      ></path>
      <path fill="#BFBFBF" d="M15.74 12.307H3.724v1.123H15.74z"></path>
    </svg>
  ),
  sidebar_payment_links_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#B9DEFE"
        d="M12.277 2.173v4.494H4.44c-.383 0-.697.3-.717.68v1.99H.977A.716.716 0 0 1 .26 8.62V2.173c0-.396.323-.716.717-.716h10.58c.396 0 .716.323.716.716z"
      ></path>
      <path
        fill="#476885"
        d="M15.74 7.383v6.443a.72.72 0 0 1-.716.717H4.44a.72.72 0 0 1-.716-.716V7.352v1.98h7.836c.397 0 .717-.32.717-.716v-1.95h2.743c.397 0 .717.32.717.716z"
      ></path>
      <path
        fill="#E9F5FF"
        d="M12.277 6.666v1.95a.72.72 0 0 1-.717.717H3.724v-1.99a.72.72 0 0 1 .716-.68h7.837z"
      ></path>
      <path
        fill="#E9F5FF"
        d="M4.44 6.667c-.383 0-.696.3-.716.68a.69.69 0 0 1 .686-.68zM12.277 8.617v.086a.63.63 0 0 1-.634.63h-.086c.396 0 .716-.32.716-.716z"
      ></path>
      <path
        fill="#476885"
        d="M3.62 3.08H1.76a.103.103 0 0 0-.104.103v.94c0 .057.047.104.104.104h1.86a.103.103 0 0 0 .103-.104v-.94a.103.103 0 0 0-.103-.103"
      ></path>
      <path fill="#B9DEFE" d="M15.74 12.307H3.724v1.123H15.74z"></path>
    </svg>
  ),
  sidebar_device_requests: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15917_648)">
        <path
          fill="#E2E2E2"
          d="M0 9.853h16v4.317c0 .633-.513 1.147-1.147 1.147H1.147A1.147 1.147 0 0 1 0 14.17z"
        ></path>
        <path
          fill="#7F7F7F"
          d="M16 9.853H0l3.727-3.41a1.62 1.62 0 0 1 1.026-.423L7.5 8.767a.706.706 0 0 0 1 0l2.717-2.717c.27.053.52.177.733.357L16 9.857z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M11.216 6.05 8.5 8.767a.706.706 0 0 1-1 0L4.753 6.02h6.143q.165 0 .32.033z"
        ></path>
        <path
          fill="#BFBFBF"
          d="M12.697 3.433c0 .704-.27 1.407-.807 1.944l-.673.673a2 2 0 0 0-.32-.033H4.754l-.644-.644a2.74 2.74 0 0 1 0-3.886 2.75 2.75 0 0 1 3.89 0 2.74 2.74 0 0 1 3.887 0c.537.536.807 1.24.807 1.943z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15917_648">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_device_requests_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158888)">
        <path
          fill="#E9F5FF"
          d="M0 9.854h16v4.316c0 .633-.513 1.147-1.147 1.147H1.147A1.147 1.147 0 0 1 0 14.17z"
        ></path>
        <path
          fill="#476885"
          d="M16 9.853H0l3.727-3.41a1.62 1.62 0 0 1 1.026-.423L7.5 8.767a.706.706 0 0 0 1 0l2.717-2.717c.27.053.52.177.733.357L16 9.857z"
        ></path>
        <path
          fill="#E9F5FF"
          d="M11.216 6.05 8.5 8.767a.706.706 0 0 1-1 0L4.753 6.02h6.143q.165 0 .32.033z"
        ></path>
        <path
          fill="#B9DEFE"
          d="M12.697 3.434c0 .703-.27 1.406-.807 1.943l-.673.673a2 2 0 0 0-.32-.033H4.754l-.644-.643a2.74 2.74 0 0 1 0-3.887 2.75 2.75 0 0 1 3.89 0 2.74 2.74 0 0 1 3.887 0c.537.537.807 1.24.807 1.943z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158888">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_bills: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15913_623)">
        <path
          fill="#7F7F7F"
          d="m9.19 1.807 3.58 8.31c.07.16-.097.32-.253.243l-2.19-1.073a.347.347 0 0 0-.477.19l-.887 2.44a.46.46 0 0 1-.616.263l-2.364-1.037a.343.343 0 0 0-.466.214l-.74 2.343c-.054.163-.284.177-.35.017L.847 5.4z"
        ></path>
        <path
          fill="#E2E2E2"
          d="m8.806.91.387.897L.846 5.4.46 4.503c-.284-.66.02-1.423.68-1.706L7.1.23c.66-.283 1.423.02 1.706.68"
        ></path>
        <path
          fill="#BFBFBF"
          d="m3.679 6.277 4.086-1.76a.245.245 0 0 1 .32.126l.335.778-4.534 1.953-.335-.777a.245.245 0 0 1 .128-.32M4.454 8.08 8.54 6.318a.245.245 0 0 1 .32.127l.334.777-4.533 1.954-.335-.778a.245.245 0 0 1 .127-.32"
        ></path>
        <path
          fill="#E2E2E2"
          d="m15.38 6.937-2.117 8.796c-.04.17-.27.197-.35.04l-1.11-2.17a.347.347 0 0 0-.496-.133l-2.174 1.42a.46.46 0 0 1-.653-.16l-1.27-2.247a.344.344 0 0 0-.5-.11l-1.997 1.43c-.14.1-.333-.026-.29-.196l2.117-8.8 8.833 2.126z"
        ></path>
        <path
          fill="#BFBFBF"
          d="m15.61 5.987-.227.95L6.55 4.81l.227-.95a1.3 1.3 0 0 1 1.566-.96l6.31 1.52a1.3 1.3 0 0 1 .96 1.567z"
        ></path>
        <path
          fill="#7F7F7F"
          d="m8.29 7.21 4.326 1.042c.13.031.21.164.18.293l-.198.824-4.8-1.155.198-.823a.245.245 0 0 1 .293-.18M7.83 9.117l4.326 1.041c.13.031.21.164.18.294l-.199.823-4.8-1.155.199-.823a.245.245 0 0 1 .293-.18"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15913_623">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_bills_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158886)">
        <path
          fill="#476885"
          d="m9.19 1.807 3.58 8.31c.07.16-.097.32-.253.243l-2.19-1.073a.347.347 0 0 0-.477.19l-.887 2.44a.46.46 0 0 1-.616.263l-2.364-1.037a.343.343 0 0 0-.466.214l-.74 2.343c-.054.163-.284.177-.35.017L.847 5.4z"
        ></path>
        <path
          fill="#E9F5FF"
          d="m8.806.91.387.897L.846 5.4.46 4.503c-.284-.66.02-1.423.68-1.706L7.1.23c.66-.283 1.423.02 1.706.68"
        ></path>
        <path
          fill="#B9DEFE"
          d="m3.679 6.277 4.086-1.76a.245.245 0 0 1 .32.127l.335.777-4.534 1.954-.335-.778a.245.245 0 0 1 .128-.32M4.454 8.08 8.54 6.318a.245.245 0 0 1 .32.127l.334.777-4.533 1.954-.335-.778a.245.245 0 0 1 .127-.32"
        ></path>
        <path
          fill="#E9F5FF"
          d="m15.38 6.937-2.117 8.796c-.04.17-.27.197-.35.04l-1.11-2.17a.347.347 0 0 0-.496-.133l-2.174 1.42a.46.46 0 0 1-.653-.16l-1.27-2.247a.344.344 0 0 0-.5-.11l-1.997 1.43c-.14.1-.333-.026-.29-.196l2.117-8.8 8.833 2.126z"
        ></path>
        <path
          fill="#B9DEFE"
          d="m15.61 5.987-.227.95L6.55 4.81l.227-.95a1.3 1.3 0 0 1 1.566-.96l6.31 1.52a1.3 1.3 0 0 1 .96 1.567z"
        ></path>
        <path
          fill="#476885"
          d="m8.29 7.211 4.326 1.041c.13.032.21.164.18.294l-.198.823-4.8-1.155.198-.823a.245.245 0 0 1 .293-.18M7.83 9.117l4.326 1.041c.13.031.21.164.18.294l-.199.823-4.8-1.155.199-.823a.245.245 0 0 1 .293-.18"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158886">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_settlements: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15917_656)">
        <path
          fill="#8B8B8B"
          d="M14.267 5.277H1.733l.037-.44A1.576 1.576 0 0 1 3.343 3.39h9.314c.82 0 1.503.63 1.573 1.447zM16 6.593v7.514c0 .7-.567 1.266-1.267 1.266H1.267c-.7 0-1.267-.566-1.267-1.266V6.593c0-.356.29-.646.647-.646h4.206l.06.103q.029.05.064.1.1.161.22.307l.06.073c.066.08.14.16.213.233l.11.107q.003 0 .007.003.06.057.123.107.07.06.143.113.07.051.14.097.071.049.147.093.005.001.01.007l.14.08c.05.027.103.057.157.08l.13.06c.033.013.07.03.106.043q.055.022.114.044c.056.023.116.04.176.06l.104.03q.12.033.243.056.065.014.13.024h.013q.087.014.174.02.07.009.146.013.044.001.094.003h.26q.045 0 .09-.003c.05 0 .096-.007.146-.013l.174-.02h.013q.065-.01.13-.024a2 2 0 0 0 .243-.056l.104-.03c.06-.017.116-.037.176-.06q.06-.02.114-.044c.036-.013.073-.03.106-.043l.13-.06c.054-.023.104-.053.157-.08l.14-.08s.007 0 .01-.007q.074-.044.147-.093.07-.048.14-.097a2 2 0 0 0 .143-.113q.064-.05.123-.107l.007-.003q.055-.05.11-.107.114-.111.217-.233l.06-.073q.12-.146.22-.307l.063-.1.06-.103h4.207c.356 0 .646.29.646.646z"
        ></path>
        <path
          fill="#D1D1D1"
          d="M11.59 4.217a3.6 3.6 0 0 1-.444 1.73H4.853a3.595 3.595 0 0 1-.35-2.56A3.59 3.59 0 0 1 8 .627a3.593 3.593 0 0 1 3.593 3.59z"
        ></path>
        <path
          fill="#646464"
          d="m11.147 5.947-.06.103q-.029.05-.064.1-.1.161-.22.307l-.06.073q-.1.122-.216.233c-.037.037-.074.07-.11.107q-.001 0-.007.003-.06.057-.123.107a2 2 0 0 1-.144.113q-.07.051-.14.097-.07.049-.146.093l-.01.007-.14.08c-.05.027-.104.057-.157.08l-.13.06c-.033.013-.07.03-.107.043q-.053.022-.113.044c-.057.023-.117.04-.177.06l-.103.03q-.12.033-.243.056-.066.014-.13.024h-.014q-.086.014-.173.02-.071.009-.147.013l-.09.003h-.26q-.044 0-.093-.003-.074-.002-.147-.013l-.173-.02h-.013q-.066-.01-.13-.024a2 2 0 0 1-.244-.056l-.103-.03a2 2 0 0 1-.177-.06q-.056-.02-.113-.044c-.037-.013-.073-.03-.107-.043l-.13-.06c-.053-.023-.103-.053-.156-.08l-.14-.08s-.007 0-.01-.007q-.073-.044-.147-.093-.07-.048-.14-.097a2 2 0 0 1-.143-.113q-.065-.05-.124-.107l-.006-.003q-.056-.05-.11-.107a3 3 0 0 1-.214-.233c-.023-.023-.04-.05-.06-.073A3.4 3.4 0 0 1 4.9 6.05l-.06-.103h6.307"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15917_656">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_settlements_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158889)">
        <path
          fill="#476885"
          d="M14.267 5.277H1.733l.037-.44A1.576 1.576 0 0 1 3.343 3.39h9.314c.82 0 1.503.63 1.573 1.447zM16 6.593v7.514c0 .7-.567 1.266-1.267 1.266H1.267c-.7 0-1.267-.566-1.267-1.266V6.593c0-.356.29-.646.647-.646h4.206l.06.103q.029.05.064.1.1.161.22.307l.06.073c.066.08.14.16.213.233l.11.107q.003 0 .007.003.06.056.123.107a2.4 2.4 0 0 0 .283.21 2 2 0 0 0 .157.1l.14.08c.05.027.103.057.157.08l.13.06c.033.013.07.03.106.043q.055.022.114.044c.056.023.116.04.176.06l.104.03q.12.033.243.056.065.014.13.024h.013q.087.014.174.02.07.009.146.013.044.002.094.003h.26q.045 0 .09-.003c.05 0 .096-.007.146-.013l.174-.02h.013q.065-.01.13-.024.121-.02.243-.056l.104-.03c.06-.017.116-.037.176-.06q.06-.02.114-.044c.036-.013.073-.03.106-.043l.13-.06c.054-.023.104-.053.157-.08l.14-.08s.007 0 .01-.007q.074-.044.147-.093.07-.048.14-.097.074-.054.143-.113.064-.05.123-.107l.007-.003q.055-.05.11-.107.114-.111.217-.233l.06-.073q.12-.146.22-.307l.063-.1.06-.103h4.207c.356 0 .646.29.646.646z"
        ></path>
        <path
          fill="#B9DEFE"
          d="M11.59 4.216a3.6 3.6 0 0 1-.444 1.73H4.853a3.595 3.595 0 0 1-.35-2.56A3.59 3.59 0 0 1 8 .626a3.593 3.593 0 0 1 3.593 3.59z"
        ></path>
        <path
          fill="#476885"
          d="m11.147 5.947-.06.103q-.029.05-.064.1-.1.161-.22.307l-.06.073q-.1.122-.216.233-.056.054-.11.107-.001 0-.007.003-.06.056-.123.107-.07.06-.144.113-.07.051-.14.097a2 2 0 0 1-.146.093l-.01.007-.14.08c-.05.027-.104.057-.157.08l-.13.06c-.033.013-.07.03-.107.043q-.053.022-.113.044c-.057.023-.117.04-.177.06l-.103.03a3 3 0 0 1-.373.08h-.014q-.086.014-.173.02-.071.009-.147.013l-.09.003h-.26q-.044 0-.093-.003-.074-.002-.147-.013l-.173-.02h-.013q-.066-.01-.13-.024a2 2 0 0 1-.244-.056l-.103-.03a2 2 0 0 1-.177-.06q-.056-.02-.113-.044c-.037-.013-.073-.03-.107-.043l-.13-.06c-.053-.023-.103-.053-.156-.08l-.14-.08s-.007 0-.01-.007q-.074-.044-.147-.093-.07-.048-.14-.097a2 2 0 0 1-.143-.113q-.065-.05-.124-.107l-.006-.003q-.056-.05-.11-.107a3 3 0 0 1-.214-.233c-.023-.023-.04-.05-.06-.073a3 3 0 0 1-.22-.307q-.034-.05-.063-.1l-.06-.103h6.307"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158889">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_compliance: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_15913_670)">
        <path
          fill="#BFBFBF"
          d="M15.997 9.543c-.233 2.737-1.82 5.067-4.06 6.274a.83.83 0 0 1-.79 0 7.6 7.6 0 0 1-1.917-1.47h.404a1.37 1.37 0 0 0 1.37-1.37v-5.6q.212-.057.433-.054h.007c.45.007.88.19 1.213.507a3.65 3.65 0 0 0 2.517 1.01h.203c.367 0 .653.323.623.7z"
        ></path>
        <path
          fill="#E2E2E2"
          d="M11.003 7.38v5.6a1.37 1.37 0 0 1-1.37 1.37H9.23a7.93 7.93 0 0 1-2.144-4.82c-.03-.367.25-.687.61-.683h.007c.98 0 1.87-.39 2.536-1.03.22-.21.48-.357.764-.434z"
        ></path>
        <path
          fill="#7F7F7F"
          d="M11.003 1.453V7.38a1.7 1.7 0 0 0-.763.433 3.65 3.65 0 0 1-2.537 1.03h-.006a.625.625 0 0 0-.61.684 7.96 7.96 0 0 0 2.143 4.82H1.37A1.37 1.37 0 0 1 0 12.977V1.453C0 .697.613.083 1.37.083h8.267c.756 0 1.37.614 1.37 1.367z"
        ></path>
        <path
          fill="#BFBFBF"
          d="M9.06 2.757H1.946v1.386H9.06zM9.06 5.517H1.946v1.386H9.06zM5.503 8.273H1.946V9.66h3.557zM6.486 11.033h-4.54v1.387h4.54z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_15913_670">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_compliance_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_16055_158890)">
        <path
          fill="#B9DEFE"
          d="M15.997 9.543c-.233 2.737-1.82 5.067-4.06 6.273a.83.83 0 0 1-.79 0 7.6 7.6 0 0 1-1.917-1.47h.404a1.37 1.37 0 0 0 1.37-1.37v-5.6q.212-.056.433-.053h.007c.45.007.88.19 1.213.507a3.65 3.65 0 0 0 2.517 1.01h.203c.367 0 .653.323.623.7z"
        ></path>
        <path
          fill="#E9F5FF"
          d="M11.003 7.38v5.6a1.37 1.37 0 0 1-1.37 1.37H9.23a7.93 7.93 0 0 1-2.144-4.82c-.03-.367.25-.687.61-.683h.007c.98 0 1.87-.39 2.536-1.03.22-.21.48-.357.764-.434z"
        ></path>
        <path
          fill="#476885"
          d="M11.003 1.454V7.38a1.7 1.7 0 0 0-.763.434 3.65 3.65 0 0 1-2.537 1.03h-.006a.625.625 0 0 0-.61.683 7.96 7.96 0 0 0 2.143 4.82H1.37A1.37 1.37 0 0 1 0 12.977V1.454C0 .697.613.083 1.37.083h8.267c.756 0 1.37.613 1.37 1.366z"
        ></path>
        <path
          fill="#B9DEFE"
          d="M9.06 2.757H1.946v1.387H9.06zM9.06 5.517H1.946v1.386H9.06zM5.503 8.273H1.946V9.66h3.557zM6.486 11.033h-4.54v1.387h4.54z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_16055_158890">
          <path fill="#fff" d="M0 0h16v16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  ),
  sidebar_audit_trails: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#BFBFBF"
        d="M15.89 4.527v6.333a.88.88 0 0 1-.877.877h-1.04V7.05a.88.88 0 0 0-.877-.877H7.52c-.297 0-.577-.123-.78-.34l-.977-1.06c-.3-.323-.72-.51-1.163-.51H2.026v-1.65c0-.483.394-.876.877-.876h3.613c.44 0 .864.183 1.164.51l.976 1.06c.2.216.484.34.78.34h5.577c.483 0 .877.393.877.876z"
      ></path>
      <path
        fill="#E2E2E2"
        d="M13.973 7.05v4.687H2.903a.88.88 0 0 1-.877-.877V4.263H4.6c.443 0 .863.184 1.163.51l.977 1.06c.2.217.483.34.78.34h5.576c.484 0 .877.394.877.877"
      ></path>
      <path
        fill="#7F7F7F"
        d="M13.974 11.737v1.65a.88.88 0 0 1-.877.876H.987a.88.88 0 0 1-.877-.876V5.14c0-.483.394-.877.877-.877h1.04v6.597c0 .483.393.877.877.877z"
      ></path>
    </svg>
  ),
  sidebar_audit_trails_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#B9DEFE"
        d="M15.89 4.527v6.333a.88.88 0 0 1-.877.877h-1.04V7.05a.88.88 0 0 0-.877-.877H7.52c-.297 0-.577-.123-.78-.34l-.977-1.06c-.3-.323-.72-.51-1.163-.51H2.026v-1.65c0-.483.394-.876.877-.876h3.613c.44 0 .864.183 1.164.51l.976 1.06c.2.216.484.34.78.34h5.577c.483 0 .877.393.877.876z"
      ></path>
      <path
        fill="#E9F5FF"
        d="M13.973 7.05v4.687H2.903a.88.88 0 0 1-.877-.877V4.263H4.6c.443 0 .863.184 1.163.51l.977 1.06c.2.217.483.34.78.34h5.576c.484 0 .877.394.877.877"
      ></path>
      <path
        fill="#476885"
        d="M13.974 11.737v1.65a.88.88 0 0 1-.877.876H.987a.88.88 0 0 1-.877-.876V5.14c0-.483.394-.877.877-.877h1.04v6.597c0 .483.393.877.877.877z"
      ></path>
    </svg>
  ),
  sidebar_disputes: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#7F7F7F"
        d="M12.717 2.22v5.37h-4.25a.565.565 0 0 0-.564.563v2.03h-1.96c-.14 0-.273.05-.38.144l-2.52 2.236a.254.254 0 0 1-.423-.19v-1.62a.573.573 0 0 0-.573-.57h-1.01a.893.893 0 0 1-.894-.893V2.22c0-.493.4-.893.894-.893h10.786c.494 0 .894.4.894.893"
      ></path>
      <path
        fill="#E2E2E2"
        d="M12.717 7.59v1.703c0 .494-.4.894-.894.894h-3.92v-2.03c0-.314.254-.564.564-.564h4.25z"
      ></path>
      <path
        fill="#BFBFBF"
        d="M15.853 8.153v4.474a.563.563 0 0 1-.563.563h-.64c-.2 0-.36.16-.36.36v.91c0 .183-.22.28-.357.16l-1.506-1.337a.35.35 0 0 0-.24-.09h-3.72a.565.565 0 0 1-.564-.563v-2.443h3.92c.494 0 .894-.4.894-.894V7.59h2.573c.313 0 .563.253.563.563M7.873 4.97l-.786.603a.097.097 0 0 0 0 .157l.786.603a.321.321 0 0 1-.383.513l-1-.73a.095.095 0 0 0-.117 0l-1 .73a.321.321 0 0 1-.383-.516l.787-.603a.097.097 0 0 0 0-.157l-.787-.603a.321.321 0 0 1 .383-.513l1 .73a.095.095 0 0 0 .117 0l1-.73a.321.321 0 0 1 .383.513z"
      ></path>
    </svg>
  ),
  sidebar_disputes_active: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#476885"
        d="M12.717 2.22v5.37h-4.25a.565.565 0 0 0-.564.563v2.03h-1.96c-.14 0-.273.05-.38.144l-2.52 2.236a.254.254 0 0 1-.423-.19v-1.62a.573.573 0 0 0-.573-.57h-1.01a.893.893 0 0 1-.894-.893V2.22c0-.493.4-.893.894-.893h10.786c.494 0 .894.4.894.893"
      ></path>
      <path
        fill="#E9F5FF"
        d="M12.717 7.59v1.703c0 .494-.4.894-.894.894h-3.92v-2.03c0-.314.254-.564.564-.564h4.25z"
      ></path>
      <path
        fill="#B9DEFE"
        d="M15.853 8.153v4.474a.563.563 0 0 1-.563.563h-.64c-.2 0-.36.16-.36.36v.91c0 .183-.22.28-.357.16l-1.506-1.337a.35.35 0 0 0-.24-.09h-3.72a.565.565 0 0 1-.564-.563v-2.443h3.92c.494 0 .894-.4.894-.894V7.59h2.573c.313 0 .563.253.563.563M7.873 4.97l-.786.603a.097.097 0 0 0 0 .157l.786.603a.321.321 0 0 1-.383.513l-1-.73a.095.095 0 0 0-.117 0l-1 .73a.321.321 0 0 1-.383-.516l.787-.603a.097.097 0 0 0 0-.157l-.787-.603a.321.321 0 0 1 .383-.513l1 .73a.095.095 0 0 0 .117 0l1-.73a.321.321 0 0 1 .383.513z"
      ></path>
    </svg>
  ),
  status_metrics_failed: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      height={44}
      fill="none"
      viewBox="0 0 43 44"
    >
      <g filter="url(#filter0_dd_15112_199635)">
        <circle cx="21.684" cy="18.295" r="15" fill="#FF0F00"></circle>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m17.087 13.7 9.191 9.19m1.905-4.595a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
      ></path>
      <defs>
        <filter
          id="filter0_dd_15112_199635"
          width="42.079"
          height="43.589"
          x="0.644"
          y="0.275"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.51"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15112_199635"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4.53"></feOffset>
          <feGaussianBlur stdDeviation="3.02"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_15112_199635"
            result="effect2_dropShadow_15112_199635"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_15112_199635"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  ),
  status_metrics_pending: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      height={44}
      fill="none"
      viewBox="0 0 43 44"
    >
      <g filter="url(#filter0_dd_15112_199626)">
        <circle cx="21.684" cy="18.295" r="15" fill="#476885"></circle>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.683 20.895v-2.6m0-2.6h.006m6.494 2.6a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0"
      ></path>
      <defs>
        <filter
          id="filter0_dd_15112_199626"
          width="42.079"
          height="43.589"
          x="0.644"
          y="0.275"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.51"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15112_199626"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4.53"></feOffset>
          <feGaussianBlur stdDeviation="3.02"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_15112_199626"
            result="effect2_dropShadow_15112_199626"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_15112_199626"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  ),
  status_metrics_success: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={43}
      height={44}
      fill="none"
      viewBox="0 0 43 44"
    >
      <g filter="url(#filter0_dd_15112_199616)">
        <circle cx="21.684" cy="18.295" r="15" fill="#1ACE37"></circle>
      </g>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m26.883 14.395-7.15 7.15-3.25-3.25"
      ></path>
      <defs>
        <filter
          id="filter0_dd_15112_199616"
          width="42.079"
          height="43.589"
          x="0.644"
          y="0.275"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="1.51"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.02 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_15112_199616"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4.53"></feOffset>
          <feGaussianBlur stdDeviation="3.02"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow_15112_199616"
            result="effect2_dropShadow_15112_199616"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_15112_199616"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  ),
  closeIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M18 6 6 18M6 6l12 12"
      ></path>
    </svg>
  ),
  exportIcon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
    >
      <path
        stroke="#1B1B1B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.5 15v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C19.22 21 18.38 21 16.7 21H8.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C3.5 18.72 3.5 17.88 3.5 16.2V15m14-5-5 5m0 0-5-5m5 5V3"
      ></path>
    </svg>
  ),
  chevronRight: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#E3E3E3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m7.5 15 5-5-5-5"
      ></path>
    </svg>
  ),
  chevronLeft: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#F0F0F0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="m12.5 15-5-5 5-5"
      ></path>
    </svg>
  ),
  chart: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#D1D1D1"
        d="M1.8 9A7.2 7.2 0 0 1 9 1.8V9h7.2A7.2 7.2 0 0 1 1.8 9"
      ></path>
      <path fill="#D1D1D1" d="M10.8 2.027A7.21 7.21 0 0 1 15.975 7.2h-5.173z"></path>
    </svg>
  ),
  chartActive: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#F8FCFF"
        d="M1.8 9A7.2 7.2 0 0 1 9 1.8V9h7.2A7.2 7.2 0 0 1 1.8 9"
      ></path>
      <path fill="#F8FCFF" d="M10.8 2.027A7.21 7.21 0 0 1 15.975 7.2h-5.173z"></path>
    </svg>
  ),
  table: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#D1D1D1"
        fillRule="evenodd"
        d="M4.5 3.6a2.7 2.7 0 0 0-2.7 2.7v5.4a2.7 2.7 0 0 0 2.7 2.7h9a2.7 2.7 0 0 0 2.7-2.7V6.3a2.7 2.7 0 0 0-2.7-2.7zm-.9 8.1v-.9h4.5v1.8H4.5a.9.9 0 0 1-.9-.9m6.3.9h3.6a.9.9 0 0 0 .9-.9v-.9H9.9zm0-3.6h4.5V7.2H9.9zM8.1 7.2H3.6V9h4.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  tableActive: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill="#F8FCFF"
        fillRule="evenodd"
        d="M4.5 3.6a2.7 2.7 0 0 0-2.7 2.7v5.4a2.7 2.7 0 0 0 2.7 2.7h9a2.7 2.7 0 0 0 2.7-2.7V6.3a2.7 2.7 0 0 0-2.7-2.7zm-.9 8.1v-.9h4.5v1.8H4.5a.9.9 0 0 1-.9-.9m6.3.9h3.6a.9.9 0 0 0 .9-.9v-.9H9.9zm0-3.6h4.5V7.2H9.9zM8.1 7.2H3.6V9h4.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
};
