export const transfersDummy = {
  data: [
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-09-18T09:12:56.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-05-01T17:34:12.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-03-10T11:50:44.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-01-15T07:29:03.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2022-10-25T18:41:29.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2022-12-03T20:23:11.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
    {
      date: "2023-12-09T13:45:23.000Z",
      total_transfers: 31778588735.0,
      successful: 24278589498.0,
      failed: 2924578855.0,
      pending: 9382453755.0,
      pnl: 49828480.0,
      transfer_cost: 381948488.0,
    },
  ],
};
