import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency, renderConditionally } from "@/utils/helpers";

import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import { useState } from "react";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { Row } from "@/components/common/stacks";
import Gap from "@/components/common/styleComponents/Gap";
import usePartnersGetQuery from "@/apps/partners/__internals__/hooks/usePartnersGetQuery";

const PosMerchantTransactions = ({ email }: { email?: string }) => {
  const { isDarkMode } = useThemeMode();
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePartnersGetQuery<PartnersTransactions>(
      "getTransactions",
      { email: email },
      { skip: !email as boolean }
    );

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<{ meta: any; tx: any } & PartnersTransactions>;
  }>({
    on: false,
    data: {},
  });
  return (
    <>
      <SmartFilter {...SmartFilterProps} />

      <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
        {isEmpty(tableData) ? (
          <EmptyScreen loading={combinedLoading || isRedacting} />
        ) : (
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "MERCHANT DETAILS",
              "TYPE",
              "DETAILS",
              "AMOUNT",
              "TRANSACTION DATE",
            ]}
          >
            {tableData.map((data, index) => {
              // const meta = JSON.parse(data?.pdon_meta);

              // const tx: Partial<PosTxMetaData & PosCardTxMetaData & PosTrxTransferIn> =
              //   Util.safeParseJson(data?.meta_data, {});

              // const transfer_in = tx as PosTrxTransferIn;
              // const bill = tx as PosTrxAirtime;
              return (
                <RavenTableRow
                key={index}
                one={
                  <TwinValue
                    one={Util.safeText(data.merchant_name)}
                    two={`PARTNER: ${data.partner}`}
                  />
                }
                three={
                  data.type ? (
                    data?.type === "pdon_standalone" ? (
                      <TwinValue
                        one={Util.safeText(
                          JSON.parse(data?.meta_data ?? "{}").card_pan ?? ""
                        )}
                        two={`RRN: ${Util.safeText(
                          JSON.parse(data?.meta_data ?? "{}").rrn ?? ""
                        )}`}
                      />
                    ) : (
                      <TwinValue
                        one={Util.safeText(
                          JSON.parse(data?.meta_data ?? "{}").account_name ?? ""
                        )}
                        two={`${
                          JSON.parse(data?.meta_data ?? "{}").bank ?? ""
                        } • ${Util.safeText(
                          JSON.parse(data?.meta_data ?? "{}").account_number ?? ""
                        )}`}
                      />
                    )
                  ) : (
                    <TwinValue one={Util.safeText("")} two={Util.safeText("")} />
                  )
                }
                two={
               Util.safeText(data.type ?? "Unknown")
                }
                four={formatDateTime(data.created_at)}
                five={formatStatus(
                  detectStatus(parseInt(data.status ?? "10"), "transaction")
                )}
              />
              );
            })}
          </RavenTable>
        )}

        <ActionModal
          visible={view.on}
          onCancel={() => {
            setView({ data: {}, on: false });
          }}
          // onClick={() =>
          //   // handleDownload(String(view.data.trx_ref), String(view.data.business_id))
          // }
          onClick={() => {}}
          actionText={"Download Reciept"}
          btnColor={"#F7F7F7"}
          btnTextColor={"#020202"}
          className="dispute-modal"
          normal
          loading={combinedLoading}
          hideCancel
          title={`Transaction Details`}
        >
          {
            // <InlineDetails
            //   direction={view?.data?.direction}
            //   title={"Transaction Details"}
            //   isTransaction
            //   simple
            //   className="no-shadow no-padding"
            //   amount={0}
            //   onClick={() => {}}
            //   content={[
            //     {
            //       label: "Amount",
            //       value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
            //       // copy: true,
            //     },

            //     ...renderConditionally(
            //       "Card Pan",
            //       view.data?.tx?.card_pan
            //         ? Util.maskCardPan(view.data?.tx?.card_pan)
            //         : undefined
            //     ),

            //     {
            //       label: "Bal Before",
            //       value: `₦${formatNumWithComma(view.data.b_before ?? 0, "ngn")}`,
            //     },
            //     {
            //       label: "Bal After",
            //       value: `₦${formatNumWithComma(view.data.b_after ?? 0, "ngn")}`,
            //     },

            //     ...renderConditionally("RRN", view?.data?.rrn),

            //     {
            //       label: "Fee",
            //       value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
            //     },
            //     ...renderConditionally(
            //       "Recipient",
            //       formatMetaData(view.data.meta_data ?? "")?.account_name
            //     ),
            //     ...renderConditionally(
            //       "Account Number",
            //       view?.data?.tx?.account_number
            //         ? view?.data?.tx?.account_number
            //         : view?.data?.tx?.source
            //         ? view?.data?.tx?.account_number
            //         : null
            //     ),
            //     ...renderConditionally(
            //       "Bank",
            //       view?.data?.tx?.bank
            //         ? view?.data?.tx?.bank
            //         : view?.data?.tx?.source
            //         ? view?.data?.tx?.source?.bank
            //         : ""
            //     ),

            //     {
            //       label:
            //         view?.data?.direction === "credit" ? "Session ID" : "Transaction Ref",
            //       value: view.data?.reference,
            //       copy: true,
            //       trim: true,
            //     },

            //     // {
            //     //   label: "Status",
            //     //   value: formatStatus(detectStatus(Number(view.data?.meta?.status))),
            //     // },

            //     {
            //       label: "Date Authorized",
            //       value: formatDateTime(view?.data.created_at ?? ""),
            //     },
            //   ]}
            // />
          }
        </ActionModal>
      </div>
    </>
  );
};

export default PosMerchantTransactions;
