import env from "@/env";
import { PartnersBuilder, partnersGetHandler } from "..";
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const PartnersOverviewApi = (builder: PartnersBuilder) => {
  return {
    getOverview: builder.query<PartnersDashboardOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            route: `partner/overview`,
            params,
          },
          thunk
        );
      },
    }),
    getOverviewPartners: builder.query<PartnersOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            route: `partner/overview/partners`,
            params,
          },
          thunk
        );
      },
    }),
    getOverviewMerchants: builder.query<PartnersOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            route: `partner/overview/merchants`,
            params,
          },
          thunk
        );
      },
    }),
    getOverviewTransactions: builder.query<PartnersOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            route: `partner/overview/transactions`,
            params,
          },
          thunk
        );
      },
    }),
    getOverviewDeviceRequests: builder.query<PartnersOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            route: `partner/overview/device`,
            params,
          },
          thunk
        );
      },
    }),
    getInsight: builder.query<{ overview: InsightsMetrics }, any>({
      queryFn: (params: { module: string }, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            baseUrl: env.partners_api,
            route: `insight`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
