import { pbGetHandler, PersonalBaseParams, PersonalBuilder } from "..";



type RewardsModel = CreatePersonalPaginatedResponse<"users", NotificationsPersonal[]>;

export const PersonalNotificationsAPI = (builder: PersonalBuilder) => {
  return {
    getNotifications: builder.query<NotificationsPersonal[], any>({
      queryFn: (params: any) => {
        return pbGetHandler({ route: "notifications", params });
      },
    })}
};
