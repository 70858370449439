//  Force push
import "./style/index.css";
import {
  RavenModal,
  RavenTable,
  RavenTableRow,
  RavenToggleSlide,
  toast,
} from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../../shared";
import { formatNumberToCurrency } from "@/utils/helpers";
import {
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
} from "@/utils/helper/Helper";
import { ConditionalCopyText } from "@/components/common/copyCheck";
import ActionModal from "@/components/common/modal/ActionModal";
import { cn } from "@/utils/colorConvert";
import { GENERAL_CLASSES, TRANSFER_EMPTY_SCREEN } from "@/constants";
import TabComponent from "@/components/common/Tabs";
import { formatWord, uppercase } from "@/utils/formatWord";
import { useProductColor } from "@/context/ProductTheme";
import { XStack, YStack } from "@/components/common/stacks";
import { useEffect, useState } from "react";
import InlineDetails from "@/components/common/inlineDetails";
import { useMutation } from "@/hooks/useMutation";
import { PersonalAPIService } from "@/redux/slices/personal";
import { iife } from "@/utils/general";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { ReactElement } from "react";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import CodePreview from "@/components/common/codePreview/CodePreview";
import { Util } from "@/utils/utility";

type Props = CreateTableRowProps<{ data: PhysicalCardDebit[] }>;

const TableHeader = [
  "Reference",
  "Amount",
  "Terminal Type",
  "STAN",
  "RRN",
  "Transaction Date",
];

export const PhysicalCardDetailsTable = (props: Props) => {
  const { data, redactedFn } = props;

  const { RedactedTable } = redactedFn;

  return (
    <RavenTable headerList={TableHeader}>
      {data.map((row) => {
        const {
          id,
          amount,
          transaction_reference,
          stan,
          rrn,
          terminal_type,
          created_at,
        } = row;

        return (
          <RavenTableRow
            key={id}
            one={RedactedTable(<ConditionalCopyText text={transaction_reference} />)}
            two={RedactedTable(formatNumberToCurrency(amount, row.currency))}
            three={RedactedTable(formatStatus(terminal_type?.toUpperCase()))}
            four={RedactedTable(<ConditionalCopyText text={stan} />)}
            five={RedactedTable(<ConditionalCopyText text={rrn} />)}
            six={RedactedTable(created_at ? formatDateTime(created_at) : "--")}
          />
        );
      })}
    </RavenTable>
  );
};

type ModalProps = BaseModalProps<{ data: PersonalPhysicalCardModel; refetch: RefetchFn }>;

export const PhysicalCardLimitModal = (props: ModalProps) => {
  const [showNestedModal, setShowNestedModal] = useState(false);
  const { data, onCancel } = props;

  const tabs = [
    {
      single: "atm_single_limit",
      daily: "atm_daily_limit",
      value: "ATM",
      enabled: "atm",
    },
    {
      single: "web_single_limit",
      daily: "web_daily_limit",
      value: "WEB",
      enabled: "web",
    },
    {
      single: "pos_single_limit",
      daily: "pos_daily_limit",
      value: "POS",
      enabled: "pos",
    },
  ] as const;

  return (
    <ActionModal
      visible={!showNestedModal}
      onCancel={onCancel}
      onClick={onCancel}
      actionText="Close"
      btnColor="#F7F7F7"
      btnTextColor="#020202"
      className={cn(GENERAL_CLASSES.BIG_MODAL, "modal-button-faded")}
      title="Card Limit"
      hideCancel
    >
      <TabComponent variant="secondary" defaultValue={tabs[0].value}>
        <TabComponent.Tabs tabs={tabs.map((tab) => tab.value)} />
        {tabs.map((tab) => {
          const singleLimit = data[tab.single];
          const dailyLimit = data[tab.daily];
          const enabled = data[tab.enabled] === 1;

          const content = { singleLimit, dailyLimit };

          return (
            <TabComponent.Content
              value={uppercase(tab.value)}
              className={cn(GENERAL_CLASSES.INLINE_DETAILS_WRAPPER)}
            >
              <YStack>
                <CardComponent
                  {...props}
                  ctxValue={tab.enabled}
                  enabled={enabled}
                  onChange={() => setShowNestedModal((c) => !c)}
                />
                <InlineDetails
                  className={cn(
                    GENERAL_CLASSES.NO_SHADOW,
                    GENERAL_CLASSES.INLINE_DETAILS.OVERRIDE
                  )}
                  simple
                  content={Object.entries(content).map(([key, value]) => ({
                    label: formatWord(key),
                    value: formatNumberToCurrency(value),
                  }))}
                />
              </YStack>
            </TabComponent.Content>
          );
        })}
      </TabComponent>
    </ActionModal>
  );
};

interface CardComponentProps extends ModalProps {
  enabled: boolean;
  ctxValue: "atm" | "pos" | "web";
  onChange(): void;
}

const useUpdateCard = (props: ModalProps, message: string) => {
  return useMutation({
    mutationFn: PersonalAPIService.updatePhysicalCard,
    onSuccess: () => {
      props.refetch();
      toast.success(message);
    },
    onMutateComplete: props.onCancel,
  });
};

const CardComponent = (props: CardComponentProps) => {
  const [action, setAction] = useState({
    enabled: props.enabled,
    hasBeenTouched: false,
  });

  const { enabled, ctxValue } = props;
  const { colorVariable } = useProductColor();

  const { renderDynamicModal } = useDynamicModal(true);

  const { isMutating, mutate } = useUpdateCard(props, "Channel Updated successfully");

  const coercedValue = () => (action.enabled ? 1 : 0);

  const name = uppercase(ctxValue);

  const text = (_ctx: boolean) => (_ctx ? "enable" : "disable");

  const negativeText = text(!props.enabled);

  const forwardText = text(props.enabled);

  const pastTense = (text: string) => `${text}d`;

  const title = iife(() => {
    return cn(capitalizeFirstLetter(negativeText), name, "channel");
  });

  useEffect(() => {
    setAction({ enabled, hasBeenTouched: false });
  }, [enabled]);

  if (action.hasBeenTouched) {
    return renderDynamicModal(
      <ActionModal
        visible={action.hasBeenTouched}
        onCancel={props.onCancel}
        onClick={() =>
          mutate({
            [ctxValue]: coercedValue(),
            id: props.data.id,
          })
        }
        cancelText="No, Cancel"
        actionText={cn("Yes,", capitalizeFirstLetter(negativeText))}
        loading={isMutating}
        body={cn(
          "Are you sure you want to update",
          name,
          "channel ? You can always come back to perform the action.."
        )}
        title={title}
        btnColor="red"
      />
    ) as ReactElement;
  }

  return (
    <XStack gap={1} className="card_details_action_layer">
      <div className="stack-layer">
        <h4 className="stack-header">This Channel is {pastTense(forwardText)}</h4>
        <p>
          You could {negativeText} this channel for the user if the need arises by
          clicking on the switch
        </p>
      </div>
      <RavenToggleSlide
        color={colorVariable}
        checked={action.enabled}
        onChange={() => {
          setAction({
            enabled: !action.enabled,
            hasBeenTouched: true,
          });
          props.onChange();
        }}
      />
    </XStack>
  );
};

interface BlockOrUnblockCardModalProps extends ModalProps {
  ctx: "block" | "activate";
}

export const BlockOrUnblockCardModal = (props: BlockOrUnblockCardModalProps) => {
  const { isMutating, mutate } = useUpdateCard(props, "Card updated successfully");

  const status = props.ctx === "activate" ? "active" : "inactive";

  return (
    <ActionModal
      visible
      onCancel={props.onCancel}
      onClick={() => mutate({ id: props.data.id, status })}
      cancelText="No, Cancel"
      actionText={cn("Yes,", capitalizeFirstLetter(props.ctx))}
      loading={isMutating}
      body={cn(
        "Are you sure you want to",
        props.ctx,
        "this card ? You can always come back to perform the action.."
      )}
      btnColor="red"
      title={cn(capitalizeFirstLetter(props.ctx), "Card")}
    />
  );
};

export const CardLienDetailsModal = ({
  data,
  onCancel,
}: {
  data: CardLien;
  onCancel: () => void;
}) => {
  const tabs = ["Details", "Data"];

  return (
    <RavenModal
      visble={true}
      onClose={onCancel}
      onBtnClick={function (e?: any | undefined): void {
        throw new Error("Function not implemented.");
      }}
    >
      <h4 className="lem__title">Lien Details</h4>

      <TabComponent tabs={tabs} defaultValue={tabs[0]}>
        <div style={{ borderRadius: "10px" }} className="reward-rank__tabs">
          <TabComponent.Tabs tabs={tabs} />
        </div>
        <TabComponent.Content value={tabs[0]}>
          <InlineDetails
            direction={""}
            title={"Transaction Details"}
            simple
            amount={0}
            className="no-shadow no-padding"
            isTransaction
            onClick={() => {}}
            content={[
              {
                label: "Request ID",
                value: Util.safeText(data.requestId),
                copy: true,
                trim: true,
              },

              {
                label: "Amount",
                value: Util.safeText(formatNumberToCurrency(data.amount, "ngn")),
              },

              {
                label: "RRN",
                value: Util.safeText(data.rrn),
              },

              {
                label: "Status",
                value: formatStatus(data.reversed ? "reversed" : "pending"),
              },
              {
                label: "STAN",
                value: Util.safeText(data.stan),
              },
              {
                label: "Card Acceptor",
                value: Util.safeText(data.data.cardAcceptorNameLocation),
              },
              {
                label: "Date Liened",
                value: formatDateTime(data.created_at),
              },
            ]}
          />
        </TabComponent.Content>

        <TabComponent.Content value={tabs[1]}>
          <CodePreview json={data?.data as any} />
        </TabComponent.Content>
      </TabComponent>
    </RavenModal>
  );
};

// lien table
export const PhysicalCardLienTable = (
  props: Omit<Props, "data"> & { onRowClick?: (e: CardLien) => void; data: CardLien[] }
) => {
  const { redactedFn, data, onRowClick } = props;

  const { RedactedTable } = redactedFn;

  return (
    <>
      <RavenTable headerList={["REFERENCE", "AMOUNT", "RRN", "STAN", "DATE LIENED"]}>
        {data?.map((row) => {
          const { id, requestId, amount, rrn, stan, data } = row;

          return (
            <RavenTableRow
              key={id}
              onRowClick={() => onRowClick?.(row as any)}
              one={RedactedTable(<ConditionalCopyText text={requestId} />)}
              two={RedactedTable(formatNumberToCurrency(amount, "ngn"))}
              three={RedactedTable(rrn?.toUpperCase())}
              four={RedactedTable(<ConditionalCopyText text={stan} />)}
              six={RedactedTable(
                data.transactionDateTime
                  ? formatDateTime(data?.transactionDateTime)
                  : "--"
              )}
            />
          );
        })}
      </RavenTable>
    </>
  );
};
