import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { BusinessAPI } from "../../../utils/axios/business";
import { toast } from "@ravenpay/raven-bank-ui";
import { formatDate, setCookie } from "@/utils/helper/Helper";
import { toggleRedact } from "@/redux/actions/actions";
import { APIResponseHandler } from "@/utils/api";
import { BusinessBuilder } from "./api";
type ChartReqPayload = {
  duration_type: string;
  type_of_chat: string;
  date: string;
  date_2: string;
};
export const getOverview = createAsyncThunk<any, ChartReqPayload>(
  "/overview",
  async (payload, thunkAPI) => {
    try {
      const { duration_type, type_of_chat, date, date_2 } = payload;
      const data = await axios.get("/transaction/get_business_or_transaction_chat_data", {
        params: {
          duration_type: Boolean(duration_type) ? duration_type : "yearly",
          date_2,
          date: Boolean(date) ? date: formatDate(new Date()).replaceAll('/', '-'),
          type_of_chat,
        },
      });

      if (data?.data?.status === "fail") {
        if (typeof data?.data?.data === "string") {
          toast.error(data?.data.data, {
            position: "top-right",
          });
        } else {
          // toast.error(data?.data.message, {
          //   position: "top-right",
          // });
        }
        thunkAPI.dispatch(toggleRedact(false));

        return thunkAPI.rejectWithValue(data);
      }

      if (data?.data?.status === "success") {
        // toast.success(data?.data?.message, {
        //   position: "top-right",
        // });

        thunkAPI.dispatch(toggleRedact(false));

        thunkAPI.dispatch(SET_BUSINESS_OVERVIEW(data?.data?.data));
        return data;
      }
    } catch (error: any) {
      thunkAPI.dispatch(toggleRedact(false));
      if (error.message === "Network Error") {
        toast.error(error.message, {
          position: "top-right",
        });
      }
      if (error.response.data.status === "fail" && error.response.status !== 401) {
        return thunkAPI.rejectWithValue(error);
      }
    }
  }
);

const initialState: any = {
  loading: false,
  overview: [],
  isAuth: false,
};

export const businessOverviewSlice = createSlice({
  name: "business-overview",
  initialState,
  reducers: {
    SET_BUSINESS_OVERVIEW: (state, action) => {
      state.overview = action.payload;
      state.isAuth = true;
    },
  },

  extraReducers: (builder) => {
    // pending state changes
    builder.addCase(getOverview.pending, (state) => {
      state.loading = true;
    });

    //fullfilled state changes
    builder.addCase(getOverview.fulfilled, (state) => {
      state.loading = false;
    });

    //rejected state changes
    builder.addCase(getOverview.rejected, (state) => {
      state.loading = false;
    });
  },
});

// Action creators are generated for each case reducer function
export const { SET_BUSINESS_OVERVIEW } = businessOverviewSlice.actions;

// new api method
export const BusinessOverviewAPI = (builder: BusinessBuilder) => {
  return {
    getOverviewStats: builder.query<BusinessOverview, void>({
      queryFn: async () => {
        try {
          const response = await BusinessAPI.get("businesses/get_business_overview");
          APIResponseHandler.tryHandler({ response, hideSuccessToast: true });

          return { data: response.data.data };
        } catch (error) {
          APIResponseHandler.catchHandler(error);
          throw error;
        }
      },
    }),
  };
};
export default businessOverviewSlice.reducer;
