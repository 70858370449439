import { GENERAL_CLASSES } from "@/constants";
import { DetailsCheckerAPI } from "@/redux/slices/details-checker/api";
import { Validator } from "@/utils/validator";
import { useState } from "react";
import { z } from "zod";
import { useGetDetailsChecker } from "./details-wrapper/context";
import { APIResponseHandler } from "@/utils/api";
import { JoinedDetailsSubmitButton } from "./nin-bvn-voters-form";
import { isObjectWithValidValues } from "@/utils/helpers";
import { Input } from "@/components/common/input";
import { reactSelectStyle } from "@/utils/helper/Helper";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { Util } from "@/utils/utility";

const schema = Validator.object({
  meter_no: Validator.string("Meter Number"),
  provider: Validator.string("Provider"),
  meter_type: Validator.string("Meter Type"),
});

// LENGTH OF CARD NUMBER is 9
// 7034453573
//7551930236

/** 
 * {
    "status": "success",
    "message": "electricity meter information retrieved successfully",
    "data": {
        "meter_no": "54150419056",
        "cust_name": "CHUKWUJIMBE GODWIN",
        "address": "2, OBA FATAI STREET, OFF DR FASHEUN STREET, AGO. LAGOS",
        "district": null,
        "meter_type": "Prepaid"
    }
}
 */
export type METER_NUMBER_PAYLOAD = z.infer<typeof schema>;

type SelectOption = { label: string; value: string }[];

const meterTypeOptions = [
  { label: "Prepaid", value: "prepaid" },
  { label: "Postpaid", value: "postpaid" },
];

const initialState = {
  provider: "",
  meter_no: "",
  meter_type: "prepaid",
};

type State = typeof initialState;

export const MeterNumberCheckerForm = () => {
  const [formData, setFormData] = useState<State>(initialState);
  const [errors, setErrors] = useState(Validator.createError(schema));
  const { setData, setLoading, loading } = useGetDetailsChecker();

  const { data:allElectricityProviders } =
    personalReduxAPI.useGetElectricityProvidersQuery();

  // usingNewQueryApi
//   const [mutate, response] =
//     personalReduxAPI.useGetElectricityProvidersQuery();
// const { data:allElectricityProviders } = response;

  const handleChange = (key: keyof State, value: string) => {
    setFormData((old) => ({ ...old, [key]: value }));
  };

  const getSelected = (value: string, data: SelectOption) => {
    return data.find((opt) => value === opt.value);
  };
  const fields = [
    {
      label: "Provider",
      placeholder: "Select Provider",
      name: "provider" as const,
      selectOption: allElectricityProviders,
      onChange: (data: any) => handleChange("provider", data.value),
      type: "select" as const,
      selectStyles: reactSelectStyle,
      value: getSelected(formData.provider, Util.safeArray(allElectricityProviders)),
    },
    {
      label: "Meter Type",
      placeholder: "Select Meter Type",
      name: "meter_type" as const,
      selectOption: meterTypeOptions,
      onChange: (data: any) => handleChange("meter_type", data.value),
      type: "select" as const,
      selectStyles: reactSelectStyle,
      value: getSelected(formData.meter_type, meterTypeOptions),
    },
    {
      label: "Meter Number",
      placeholder: "Enter Meter Number",
      name: "meter_no" as const,
      onChange: (e: any) => handleChange("meter_no", e.target.value),
      type: "text",
      value: formData.meter_no,
    },
  ];

  const fetchDetails = async (payload: METER_NUMBER_PAYLOAD) => {
    setLoading(true);
    try {
      const response = await DetailsCheckerAPI.checkMeterCardNumber(payload);

      const success = APIResponseHandler.tryHandler({
        response,
        hideSuccessToast: false,
      });
      if (success) {
        setData(response.data.data);
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    }
    setLoading(false);
  };
  const handledSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    Validator.validatorFunc({
      data: formData,
      onError: setErrors,
      onSuccess: fetchDetails,
      schema,
    });
  };

  return (
    <form className={GENERAL_CLASSES.DETAILS_CHECKER_FORM} onSubmit={handledSubmit}>
      {fields.map(({ name, ...rest }) => {
        const errorText = errors?.[name];

        return (
          <Input
            key={name}
            {...rest}
            errorText={errorText}
            showError={Boolean(errorText)}
          />
        );
      })}
      <JoinedDetailsSubmitButton
        disabled={!isObjectWithValidValues(formData)}
        className="mt-20"
        loading={loading}
      />
    </form>
  );
};
