import { posIcons } from "@/assets/icons/posIcons";

export const posSideMenuList: SidebarMenuDataList = [
  {
    name: "Main Menu",
    numb: "one",
    icon: "",
    link: "",
    children: [
      {
        name: "Overview",
        link: "/pos-overview",
        icon: posIcons.sidebar_overview,
        activeIcon: posIcons.sidebar_overview_active,
      },

      {
        name: "Insights",
        link: "/pos-insights/users",
        icon: posIcons.sidebar_insights,
        activeIcon: posIcons.sidebar_insights_active,
      },
      {
        name: "Merchants",
        link: "/pos-merchants",
        icon: posIcons.sidebar_merchants,
        activeIcon: posIcons.sidebar_merchants_active,
      },
      {
        name: "Transactions",
        link: "/pos-transactions",
        icon: posIcons.sidebar_transactions,
        activeIcon: posIcons.sidebar_transactions_active,
      },
      // {
      //   name: "Expenses",
      //   link: "/pos-expenses",
      //   icon: posIcons.sidebar_expenses,
      //   activeIcon: posIcons.sidebar_expenses_active,
      // },
      {
        name: "Daily Summary",
        link: "/pos-daily-summary",
        icon: posIcons.sidebar_daily_summary,
        activeIcon: posIcons.sidebar_daily_summary_active,
      },
      {
        name: "Fee Details",
        link: "/pos-fee-details",
        icon: posIcons.sidebar_fee_details,
        activeIcon: posIcons.sidebar_fee_details_active,
      },
    ],
  },
  {
    name: "Features",
    numb: "two",
    icon: "",
    link: "",
    children: [
      // {
      //   name: "Transfers",
      //   link: "/pos-transfers",
      //   icon: posIcons.sidebar_transfers,
      //   activeIcon: posIcons.sidebar_transfers_active,
      // },

      {
        name: "Bank Collections",
        link: "/pos-collections",
        icon: posIcons.sidebar_bank_collections,
        activeIcon: posIcons.sidebar_bank_collections_active,
      },
      {
        name: "Terminals",
        link: "/pos-terminals",
        icon: posIcons.sidebar_terminals,
        activeIcon: posIcons.sidebar_terminals,
      },
      {
        name: "Settlement",
        link: "/pos-settlement",
        icon: posIcons.sidebar_settlement,
        activeIcon: posIcons.sidebar_settlement_active,
      },

      {
        name: "Cards Collection",
        link: "/pos-cards",
        icon: posIcons.sidebar_card_collection,
        activeIcon: posIcons.sidebar_card_collection_active,
      },

      {
        name: "Device Requests",
        link: "/pos-device-requests",
        icon: posIcons.sidebar_device_requests,
        activeIcon: posIcons.sidebar_device_requests_active,
      },
      {
        name: "Bills",
        link: "/pos-bills",
        icon: posIcons.sidebar_bills,
        activeIcon: posIcons.sidebar_bills_active,
      },
      // {
      //   name: "BankBox",
      //   link: "/pos-bankbox",
      //   icon: posIcons.sidebar_bank_box,
      // },
      // {
      //   name: "Customers",
      //   link: "/pos-customers",
      //   icon: posIcons.sidebar_customers,
      // },
    ],
  },

  {
    name: "HNI Tool",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Businesses",
        link: "/pos-hni",
        icon: posIcons.sidebar_hni_business,
      },
      {
        name: "Managers",
        link: "/pos-managers",
        icon: posIcons.sidebar_hni_managers,
      },
    ],
  },

  {
    name: "Support",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Fraud Center",
        link: "/pos-fraud-center",
        icon: posIcons.sidebar_fraud_center,
        activeIcon: posIcons.sidebar_fraud_center_active,
      },
      {
        name: "Audit Trails",
        link: "/pos-audit-trails",
        icon: posIcons.sidebar_merchants,
        hide: false,
        activeIcon: posIcons.sidebar_merchants_active,
      },
      {
        name: "Compliance",
        link: "/pos-compliance",
        icon: posIcons.sidebar_compliance,
        activeIcon: posIcons.sidebar_compliance_active,
      },
      {
        name: "Reversal Center",
        link: "/pos-reversal-center",
        icon: posIcons.sidebar_compliance,
        activeIcon: posIcons.sidebar_pos_reversal_center,
      },
      {
        name: "Disputes",
        link: "/pos-disputes",
        icon: posIcons.sidebar_disputes,
        activeIcon: posIcons.sidebar_disputes_active,
      },
      {
        name: "Notification Center",
        link: "/pos-notification-center",
        icon: posIcons.sidebar_notifications,
        activeIcon: posIcons.sidebar_notifications_active,
      },
    ],
  },
];
