import ActionModal from "@/components/common/modal/ActionModal";
import { Validator } from "@/utils/validator";
import { APIResponseHandler } from "@/utils/api";
import { PersonalAPIService } from "@/redux/slices/personal";
import { useState } from "react";
import { z } from "zod";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { reactSelectStyle } from "@/utils/helper/Helper";
import { Util } from "@/utils/utility";
import { Input } from "@/components/common/input";
import Gap from "@/components/common/styleComponents/Gap";
import { toast } from "@ravenpay/raven-bank-ui";

interface BaseModalProps {
  id: SN;
  refetch(): void;
  onCancel(): void;
}

const BasicSchema = Validator.object({
  label: Validator.string("Reason"),
  value: Validator.string("Action"),
});

type Payload = z.infer<typeof BasicSchema>;

export const RejectManualAddressVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery();

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectAddress({
        address_id: id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Address Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why address verification is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection*"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};
export const RejectIdVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery("manual_id");

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectManualId({
        id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        await refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Id Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why this ID verification is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection*"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};

export const RejectLimitRequest = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery("limit_request");

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  const handleChange = (event: any) => {
    setForm(event);
  };

  const rejectManualVerification = async (payload: Payload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectLimitRequest({
        request_id: id,
        action: payload.value,
        reason: payload.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        await refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      const res = BasicSchema.parse(form);
      setErrors({});
      rejectManualVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, BasicSchema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Reject Limit Request"
    >
      <p style={{ marginBottom: "2rem" }}>
        Give a reason why this limit request is being rejected
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection *"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={option}
        selectStyles={reactSelectStyle}
      />
      <Input
        className="bugiss-block-modal mt-20"
        label="Action to take"
        value={form.value}
        showError={Boolean(errors?.value)}
        errorText={errors?.value}
        placeholder="Action to take*"
        name="action"
        disabled
      />
    </ActionModal>
  );
};

const Schema = Validator.object({
  visitation_link: Validator.string("Visitation Link"),
});
type AcceptPayload = z.infer<typeof Schema>;

export const AcceptManualAddressVerification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [visitation_link, setVisitationLink] = useState("");
  const [errors, setErrors] = useState(Validator.createError(Schema));

  const handleChange = (event: InputOnChange) => {
    const { value } = event.target;
    setVisitationLink(value);
  };

  const acceptVerification = async ({ visitation_link }: AcceptPayload) => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.approveAddress({
        id,
        visitation_link,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    try {
      const res = Schema.parse({ visitation_link });
      setErrors({});
      acceptVerification(res);
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleAccept}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Accept Address Verification"
    >
      <p style={{ marginBottom: "2rem" }}>
        Enter visitation link to confirm the address is a live location
      </p>

      <Input
        className="bugiss-block-modal"
        label="Visitation Link*"
        value={visitation_link}
        onChange={handleChange}
        showError={Boolean(errors?.visitation_link)}
        errorText={errors?.visitation_link}
        placeholder="Enter Visitation Link*"
        name="reason"
      />
    </ActionModal>
  );
};

// Manual NIN
export const AcceptManualNinverification = (props: BaseModalProps) => {
  const { id, onCancel, refetch } = props;
  const [loading, setLoading] = useState(false);
  const [visitation_link, setVisitationLink] = useState("");
  const [errors, setErrors] = useState(Validator.createError(Schema));

  const handleChange = (event: InputOnChange) => {
    const { value } = event.target;
    setVisitationLink(value);
  };

  const acceptVerification = async () => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.approveNin({
        manual_kyc_id: id as string,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    try {
      setErrors({});
      acceptVerification();
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
      setErrors(err);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleAccept}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Are you sure to verify this user?"
    >
      <p style={{ marginBottom: "2rem" }}>
        Are you sure to verify this user? Note, this action cannot be undone
      </p>
    </ActionModal>
  );
};

export const RejectManualNinverification = (props: BaseModalProps & {type: 'nin' | 'bvn'}) => {
  const { id, onCancel, refetch, type } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ label: "", value: "" });
  const [customReasons, setCustomReasons] = useState<string>('');

  const rejectVerification = async () => {
    setLoading(true);
    try {
      const response = await PersonalAPIService.rejectNin({
        manual_kyc_id: id as string,
        reason: form?.value === 'custom' ? customReasons : form.label,
      });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        toast.success('User KYC rejected successfully');
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = () => {
    try {
      rejectVerification();
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
    }
  };

  const handleChange = (event: {
    label: React.ChangeEvent<HTMLInputElement>;
    value: React.ChangeEvent<HTMLInputElement>;
  }) => {
    setForm(event as any);
  };

  const [errors, setErrors] = useState(Validator.createError(BasicSchema));

  const { data } = personalReduxAPI.useGetDeclineReasonsQuery(type);

  const option = Util.safeArray(data).map(({ action, reason }) => ({
    label: reason,
    value: action,
  }));

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleReject}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Are you sure to reject this user detail?"
    >
      <p style={{ marginBottom: "2rem" }}>
        Are you sure to reject this user? Note, this action cannot be undone
      </p>

      <Input
        className="bugiss-block-modal"
        label="Reason for Rejection*"
        value={form}
        onChange={handleChange}
        showError={Boolean(errors?.label)}
        errorText={errors?.label}
        placeholder="Reason*"
        name="reason"
        type="select"
        selectOption={[...option, {value: 'custom', label: 'Custom Reason'}]}
        selectStyles={reactSelectStyle}
      />
      <Gap size={20}/>
      {form.value === 'custom' && (
        <Input
          className="bugiss-block-modal"
          label="Enter Reject Reason*"
          value={customReasons}
          onChange={(e: { target: { value: string } }) =>
            setCustomReasons(e.target.value)
          }
          showError={Boolean(errors?.label)}
          errorText={errors?.label}
          placeholder="Reason*"
          name="reason"
          type="text"
          // selectOption={option}
          // selectStyles={reactSelectStyle}
        />
      )}

    </ActionModal>
  );
};

export const ManuallyApproveTokens = (
  props: BaseModalProps & { token: string; email: string; type: "nin" | "bvn"; id?: "", image_url:string }
) => {
  const { token, email, onCancel, refetch, image_url, type } = props;
  const [loading, setLoading] = useState(false);

  const acceptVerification = async () => {
    setLoading(true);

    try {
      const response =
        type === "nin"
          ? await PersonalAPIService.verifyManualNIN({
              nin: token as string,
              email: email,
              image_url,
            })
          : await PersonalAPIService.verifyManualBVN({
              bvn: token as string,
              email: email,
              image_url
            });
      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    try {
      acceptVerification();
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleAccept}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Are you sure to verify this user?"
    >
      <p style={{ marginBottom: "2rem" }}>
        Are you sure to verify this user? Note, this action cannot be undone
      </p>
    </ActionModal>
  );
};



export const ClearMonitoredTransactionModal = (
  props: BaseModalProps & { id?: string }
) => {
  const { onCancel, refetch, id } = props;
  const [loading, setLoading] = useState(false);

  const markChecked = async () => {
    setLoading(true);

    try {
      const response =
        await PersonalAPIService.markMonitoredTransactionAsChecked({id
        })

      const res = APIResponseHandler.tryHandler({ response });
      if (res) {
        refetch();
        onCancel();
      }
    } catch (error) {
      APIResponseHandler.catchHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAccept = () => {
    try {
      markChecked();
    } catch (error) {
      const err = Validator.getErrorMessage(error, Schema);
    }
  };

  return (
    <ActionModal
      visible
      onCancel={onCancel}
      loading={loading}
      onClick={handleAccept}
      cancelText="No, Cancel"
      actionText="Yes"
      btnColor="red"
      title="Clear Flagged Transaction"
    >
      <p style={{ marginBottom: "2rem" }}>
        Are you sure to clear this flagged transaction? You can always come back to perform the action.
      </p>
    </ActionModal>
  );
};