import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import EmptyScreen from "@/components/common/emptyScreen";

export const PosInsightsCardCollection = () => {
  const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
    module: INSIGHT_TYPES.CARD_COLLECTION,
  });
  const combinedLoading = isFetching || isLoading;

  type Metric = {
    title: string;
    value: number;
    count: number;
    type: "success" | "pending" | "failed";
  };

  function formatMetrics(metrics: StatusMetrics | undefined): Metric[] {
    if (!metrics) return [];

    return [
      {
        title: "Successful Collection",
        value: metrics.successPayment ?? 0,
        count: metrics.successPaymentCount ?? 0,
        type: "success",
      },
      {
        title: "Pending Collection",
        value: metrics.pendingPayment ?? 0,
        count: metrics.pendingPaymentCount ?? 0,
        type: "pending",
      },
      {
        title: "Failed Collection",
        value: metrics.failedPayment ?? 0,
        count: metrics.failedPaymentCount ?? 0,
        type: "failed",
      },
    ];
  }

  return (
    <div className="pos-insights-tabs">
      {combinedLoading ? (
        <EmptyScreen loading={combinedLoading} />
      ) : (
        <Row>
          <PosInsightsSideBar
            volumeNumberOptions={{
              numberAbbreviate: true,
              hideSymbol: false,
              hideDecimal: false,
            }}
            metrics={formatMetrics(data?.overview?.statusMetrics)}
          />
          {/* <CategoryMetricsCard /> */}
          <MetricsGraph
            data={data?.overview?.volumeMetrics.monthlyRecharge}
            volumeNumberOptions={{ numberAbbreviate: true }}
            count={data?.overview?.volumeMetrics.totalYearCount ?? 0}
            totalVolume={data?.overview?.volumeMetrics.totalYearVol ?? 0}
            title={"Volume Metrics"}
          />
        </Row>
      )}
    </div>
  );
};
