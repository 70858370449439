import "./style/index.css";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { Column, XStack } from "@/components/common/stacks";
import { GENERAL_CLASSES } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import TabComponent from "@/components/common/Tabs";
import { icons } from "@/assets/icons";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import TransferStatusMetrics from "@/apps/pos/insights/components/transferStatusMetrics";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { SetStateAction, useState } from "react";

export interface ApproveManualAddressProps {
  view: string;
  setView: (view?: string) => void;
  // showRevokeModal(id: SN): void;
  handleVerification(data: {
    email: string;
    token: string;
    type: string;
    image_url: string;
  }): void;
  tab: string;
}

export const ManuallyVerifyApproveBVN = (props: ApproveManualAddressProps) => {
  const { setView, view, tab, handleVerification } = props;
  const [step, setStep] = useState(0);
  const [form, setForm] = useState({
    email: "",
    token: "",
  });

  const ninQuery = personalReduxAPI.useGetManualNinInfoQuery(
    {
      nin: form.token,
      email: form.email,
    },
    { skip: step !== 1 || tab !== "nin" }
  );
  const { isLoading, data, isFetching } = personalReduxAPI.useGetManualBvnInfoQuery(
    {
      bvn: form.token,
      email: form.email,
    },
    { skip: step !== 1 || tab !== "bvn" }
  );

  const joinedLoader =
    isLoading || isFetching || ninQuery.isFetching || ninQuery.isLoading;
  const { RedactedTable, RenderCustom } = renderRedactedComponent(joinedLoader);

  const getButtonText = () => {
    if (step === 1) return "Verify User";
    return "Fetch User Details";
  };

  const generateBvnView = () => {
    if (!view) return {};

    const info = data?.info;

    return {
      bvn: RedactedTable(cn(Util.safeValue(data?.token))),
      name: RedactedTable(
        cn(
          Util.safeText(info?.firstname),
          Util.safeText(info?.middlename),
          Util.safeText(info?.lastname)
        )
      ),
      residential_address: RedactedTable(Util.safeText(info?.residentialAddress)),
      lga_of_residence: RedactedTable(Util.safeText(info?.lga_of_residence)),
      state_of_residence: RedactedTable(Util.safeText(info?.state_of_residence)),
      phone: RedactedTable(Util.safeText(info?.phone_number)),
      gender: RedactedTable(Util.safeText(info?.gender)),
      email: RedactedTable(Util.safeText(info?.email)),
      dob: RedactedTable(Util.safeText(info?.date_of_birth)),
      // landmark: RedactedTable(Util.safeText(landmark)),
      // email: RedactedTable(Util.safeText(email)),
      // document_type: RedactedTable(Util.safeText(document_type))
    };
  };

  const generateNinView = () => {
    if (!view) return {};

    const nin = ninQuery.data;

    const info = nin;

    return {
      nin: RedactedTable(cn(Util.safeValue(form.token))),
      name: RedactedTable(
        cn(
          Util.safeText(info?.firstname),
          Util.safeText(info?.middlename),
          Util.safeText(info?.lastname)
        )
      ),
      residential_address: RedactedTable(Util.safeText(info?.residentialAddress)),
      phone: RedactedTable(Util.safeText(info?.phone)),
      gender: RedactedTable(Util.safeText(info?.gender)),
      // photo: RedactedTable(Util.safeText(home_address)),
      // landmark: RedactedTable(Util.safeText(landmark)),
      // email: RedactedTable(Util.safeText(email)),
      // document_type: RedactedTable(Util.safeText(document_type))
    };
  };

  const tabs = ["Details", "Image"];

  const BvnDetailsView = () => (
    <TabComponent className={"w-full"} defaultValue={tabs[0]}>
      <TabComponent.Tabs tabs={tabs} className="pb-insights-sidebar__tab w-full" />

      <TabComponent.Content className="w-full pt-4" value={tabs[1]} key={tabs[1]}>
        {RenderCustom(
          <ImagePreview
            imgData={[
              {
                src: TypeIs.any(data?.info?.photo),
                alt: "Image 2",
                containerClassName: "biz-compliance-results__image-preview w-full",
              },
              {
                src: TypeIs.any(data?.image),
                alt: "Image 1",
                containerClassName: "biz-compliance-results__image-preview w-full",
              },
            ]}
          />,
          { height: 278, width: 350, className: "shimmer redacted curved mt-20" }
        )}
      </TabComponent.Content>

      <TabComponent.Content value={tabs[0]} key={tabs[0]} className="mt-20 w-full">
        <div className="compliance-results__main flex flex-row w-full">
          <InlineDetails
            direction={""}
            title={"Transaction Details"}
            className="no-shadow w-full no-padding p-0"
            simple
            amount={0}
            onClick={() => {}}
            content={Object.entries(generateBvnView()).map(([key, value]) => ({
              label: formatWord(key),
              value,
            }))}
          />
        </div>
      </TabComponent.Content>
    </TabComponent>
  );

  const NinDetailsView = () => (
    <TabComponent className={"w-full"} defaultValue={tabs[0]}>
      <TabComponent.Tabs tabs={tabs} className="pb-insights-sidebar__tab w-full" />

      <TabComponent.Content className="w-full pt-4" value={tabs[1]} key={tabs[1]}>
        {RenderCustom(
          <ImagePreview
            imgData={[
              {
                src: TypeIs.any(ninQuery?.data?.image_url ?? ninQuery?.data?.photo),
                alt: "Image 1",
                containerClassName: "biz-compliance-results__image-preview w-full",
              },
            ]}
          />,
          { height: 278, width: 350, className: "shimmer redacted curved mt-20" }
        )}
      </TabComponent.Content>

      <TabComponent.Content value={tabs[0]} key={tabs[0]} className="mt-20 w-full">
        <div className="compliance-results__main flex flex-row w-full">
          <InlineDetails
            direction={""}
            title={"Transaction Details"}
            className="no-shadow w-full no-padding p-0"
            simple
            amount={0}
            onClick={() => {}}
            content={Object.entries(generateNinView()).map(([key, value]) => ({
              label: formatWord(key),
              value,
            }))}
          />
        </div>
      </TabComponent.Content>
    </TabComponent>
  );

  return (
    <ActionModal
      visible={Boolean(view)}
      onCancel={() => setView()}
      altCancelFunc={() => {
        setView();
        // showRevokeModal(view.id);
      }}
      onClick={
        step === 0
          ? () => setStep(1)
          : () =>
              handleVerification({
                ...form,
                type: tab,
                image_url: data?.image ?? ninQuery?.data?.image_url ?? "",
              })
      }
      actionText={getButtonText()}
      btnColor="var(--primary-product-color)"
      disableButton={step === 0 && (!form.email || !form.token)}
      className={cn("address-verification-modal ", GENERAL_CLASSES.SCROLLABLE_MODAL)}
      // cancelText={TypeIs.any("Reject Verification")}
      title={`Verify ${formatWord(tab).toUpperCase()} Manually`}
      loading={joinedLoader}
      hideCancel={joinedLoader || step === 0}
    >
      {/*Inline Style for Modal Button*/}
      <style>
        {`
        .bl-modal__action-btn-contain {
        flex-direction: column !important;
        width: 100%;
        }
        
        .bl-modal__action-btn-contain button{
        width: 100% !important;
        }
        
        .general-action-modal .raven-modal-content-wrap {
        width: 50rem !important;
        }import { color } from '../../../../../utils/colorConvert/index';

        `}
      </style>

      {/*End of Inline Style for Modal Button*/}
      <div className="biz-compliance-results w-full">
        <Column className="w-full">
          <div className="step-indicator cursor-pointer">
            {[0, 1].map((item, i) => (
              <p onClick={() => setStep(i)} className={cn(i === step && "active")}></p>
            ))}
          </div>
          {step === 0 ? (
            <FormView form={form} setForm={setForm} tab={tab} />
          ) : tab === "bvn" ? (
            <BvnDetailsView />
          ) : (
            <NinDetailsView />
          )}
        </Column>
      </div>
    </ActionModal>
  );
};

const FormView = ({ form, setForm, tab }: { form: any; setForm: any; tab: string }) => (
  <div className="manual-verification-form w-full">
    <Column>
      <RavenInputField
        color="purple-light"
        name="Email"
        label="email*"
        placeholder="Enter account email"
        value={form.email}
        onChange={(e: { target: { value: any } }) =>
          setForm({
            ...form,
            email: e.target.value,
          })
        }
      />
      <RavenInputField
        color="purple-light"
        name={tab?.toUpperCase()}
        onChange={(e: { target: { value: any } }) =>
          setForm({
            ...form,
            token: e.target.value,
          })
        }
        label={tab?.toUpperCase()}
        placeholder={`Enter ${tab?.toUpperCase()} No.`}
        value={form.token}
      />
    </Column>
  </div>
);
