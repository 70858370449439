import React, {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
  useEffect,
} from "react";

import { FilterType, Util } from "@/utils/utility";
import { formatDate } from "@/utils/helper/Helper";

export interface ExportType {
  fileType: "excel" | "csv" | "";
  start_date: string;
  end_date: string;
  date?: string
}

export interface UseExportModalState {
  isVisible: boolean;
  submitted: boolean;
  exportValue: ExportType;
  setTitle?: Dispatch<SetStateAction<string>>;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setExportValue: Dispatch<SetStateAction<ExportType>>;
  toggleModal: (title?: any, metaData?: any) => void; // Function to toggle modal state
  onSubmit: () => void;
  clearExportValue: () => void;
  title?: string;
  meta?: any;
}

const ExportModalContext = createContext<UseExportModalState | undefined>(undefined);

export const useExportModal = (
  submitListener?: Function,
  filterFn?: string
): UseExportModalState => {
  const context = useContext(ExportModalContext);
  if (!context) {
    throw new Error("useExportModal must be used within an ExportModalProvider");
  }

  useEffect(() => {
    if (context.submitted && submitListener) {
      submitListener();
      context.onSubmit();
    }
  }, [context.submitted, submitListener]);

  return context;
};

interface ExportModalProviderProps {
  initialVisibility?: boolean;
  initialExportValue?: ExportType;
  children: ReactNode;
}

function addDays(date: Date | string, days: number) {
  // Function to add Days
  var result = new Date(date);
  result.setDate(result.getDate() + days);

  return result;
}

export const ExportModalProvider: React.FC<ExportModalProviderProps> = ({
  children,
  initialVisibility = false,
  initialExportValue = {
    fileType: "",
    end_date: formatDate(addDays(new Date(), 1)),
    start_date: "2022-06-01",
  },
}) => {
  const [isVisible, setVisible] = useState(initialVisibility);
  const [submitted, setSubmitted] = useState(initialVisibility);
  const [meta, setMeta] = useState<any>();
  const [title, setTitle] = useState("");
  const [exportValue, setExportValue] = useState(initialExportValue);

  const toggleModal = (title?: string, metaData?: any): void => {
    setVisible((prev) => !prev);
    if (title && title?.length > 0) {
      setTitle(title);

      if (metaData) {
        setMeta(metaData);
      }
    }
  };

  const clearExportValue = () => {
    setExportValue(initialExportValue);
  };

  const onSubmit = (): void => {
    setSubmitted(true);

    setTimeout(() => {
      setSubmitted(false);
    }, 100);
  };

  const contextValue: UseExportModalState = {
    isVisible,
    exportValue,
    setVisible,
    setExportValue,
    setTitle,
    toggleModal,
    title,
    submitted,
    clearExportValue,
    meta,
    onSubmit, // Provide a default implementation for onSubmit
  };

  return (
    <ExportModalContext.Provider value={contextValue}>
      {children}
    </ExportModalContext.Provider>
  );
};

// const pair = {
//   name: "name",
// };

// function assertExportStruct(valuePair, data) {}

export const useExportHandler = ({
  setFilterValue,
  data,
  loading,
  valuePair,
  title,
  isStatement,
}: {
  setFilterValue: Dispatch<SetStateAction<FilterType>>;
  data: Record<any, any>;
  isStatement?: boolean;
  valuePair: { [x: string]: string | string[] };
  loading: boolean;
  title?: string;
}) => {
  const { exportValue, toggleModal, clearExportValue } = useExportModal();

  const [exportHandled, setExportHandled] = useState(false);

  useEffect(() => {
    if (exportHandled) {
      setExportHandled(false);
    }
  }, [exportHandled]);

  const handleExport = () => {
    if (!exportHandled && !isStatement) {
      const exportStruct = Util.exportStruct(exportValue as ExportType);

      const exportData = Util.reformatExportData(valuePair, data);

      if (exportData && !loading) {
        setFilterValue(exportStruct);

        setExportHandled(true);
        setTimeout(() => {
          Util.downloadExport(
            exportData,
            "csv",
            title
              ? `${title} for ${exportValue.start_date} - ${exportValue.end_date} `
              : undefined
          );

          // reset export value and close modal
          clearExportValue();
          toggleModal();
        }, 3500);
      }
    }
  };

  return { handleExport };
};
