import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import usePosGetQuery from "../__internals__/hooks/usePosGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import {
  formatDateTime,
  formatMetaData,
  formatNumWithComma,
  trimLongString,
} from "@/utils/helper/Helper";
import { formatNumberToCurrency, renderConditionally } from "@/utils/helpers";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import Gap from "@/components/common/styleComponents/Gap";
import { Row } from "@/components/common/stacks";
import { BugissApi } from "@/redux/slices";
import { sendRequest } from "@/utils/api-methods";
import PosApi from "@/utils/axios/pos";
import env from "@/env";

const PosTransactions = () => {
  const { isDarkMode } = useThemeMode();
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<PosTransactions>("getTransactions");

  const [recieptDownloading, setRecieptDownloading] = useState(false);

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<{ meta: any; tx: any } & PosTransactions>;
  }>({
    on: false,
    data: {},
  });

  async function handleDownload(ref: string) {
    setRecieptDownloading(true);
    const resp = await  PosApi.get('admin/v2/download/receipt?transaction_id=' + ref, {
      baseURL: env.pos_api,
    })

    // console.log(resp.data, 'the resp')
    if (resp.data.status === "success") {
      const url = resp.data.data?.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `transaction-reciept-#${ref}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

    setRecieptDownloading(false);

  }

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Transactions">
        <SmartFilter {...SmartFilterProps} searchTitle="Search transactions..." filters={
          [
            {
          label: "Transaction Type",
          filterBy: "type",
          options: [{
            label: "Terminal",
            value: "terminal",
          },
            {
              label: "Airtime",
              value: "airtime",
            },
            {
              label: "Data",
              value: "data",
            },
            {
              label: "Collection",
              value: "terminal",
            }
          ]
        }, {
            label: 'Channel',
            filterBy: 'channel',
            options: [{
              label: 'App',
              value: "app",
            }]
          }]} />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {isEmpty(tableData) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MERCHANT DETAILS",
                "CHANNEL",
                "REFERENCE",
                "DESCRIPTION",
                "AMOUNT",
                "TYPE",
                "TRANSACTION DATE",
              ]}
            >
              {tableData.map((data, idx) => {
                const meta = JSON.parse(data?.pdon_meta);

                const tx: Partial<PosTxMetaData & PosCardTxMetaData & PosTrxTransferIn> =
                  Util.safeParseJson(data?.meta_data, {});

                const transfer_in = tx as PosTrxTransferIn;
                const bill = tx as PosTrxAirtime;
                return (
                  <RavenTableRow
                    key={idx}
                    onRowClick={() => setView({ on: true, data: { ...data, meta, tx } })}
                    one={
                      isRedacting ? (
                        <Redacted.Labelled />
                      ) : (
                        <TwinValue  
                          one={Util.safeText(
                            trimLongString(  
                              Util.safeText(data?.businessName)?.toUpperCase(),
                              16
                            )
                          )}
                          copy
                          two={String(
                            trimLongString(Util.safeText(data?.email), 25)
                          ).toLowerCase()}
                        />
                      )
                    }
                    // two={<TwinValue one="BankBox Device " two="T ID • 2938377CF" />}
                    two={
                      isRedacting ? (
                        <Redacted.Labelled />
                      ) : (
                        <TwinValue
                          one={Util.safeText(
                            Util.cleanText(
                              data?.channel
                                ? data?.channel
                                : tx.trnx_type === "pdon_card_raven"
                                ? "BankBox"
                                : meta?.terminal_profile?.issuing_app
                            )
                          ).toUpperCase()}
                          two={
                            isRedacting ? (
                              <Redacted.Table />
                            ) : (
                              `TID:${Util.safeText(
                                trimLongString(
                                  meta?.terminal_profile?.poseidon_tid ?? data?.t_id,
                                  8
                                )
                              )}`
                            )
                          }
                        />
                      )
                    }
                    three={
                      isRedacting ? (
                        <Redacted.Table />
                      ) : tx?.card_pan ? (
                        <TwinValue
                          one={
                            <Txreference
                              direction={data?.direction}
                              reference={trimLongString(Util.safeValue(data?.rrn), 20)}
                              textToCopy={Util.safeValue(data?.rrn)}
                            />
                          }
                          two={
                            <Row>
                              <Gap size={8} /> {Util.safeText(tx.response_code)}/
                              {Util.safeText(tx.response_meaning)}
                            </Row>
                          }
                        />
                      ) : (
                        <Txreference
                          direction={data?.direction}
                          reference={trimLongString(Util.safeValue(data?.reference), 20)}
                          textToCopy={Util.safeValue(data?.reference)}
                        />
                      )
                    }
                    // four={
                    //   <TwinValue
                    //     one={"Adeeko Emmanuel"}
                    //     two="2135876118 • United Bank for Africa"
                    //   />
                    // }
                    four={
                      isRedacting ? (
                        <Redacted.Labelled />
                      ) : (
                        <TwinValue
                          one={trimLongString(
                            Util.safeText(
                              tx?.account_name
                                ? tx.account_name
                                : transfer_in?.source
                                ? Util.join(transfer_in?.source?.first_name).with(
                                    transfer_in?.source?.last_name
                                  )
                                : data?.type === "airtime"
                                ? `provider: ${bill.network}`
                                : `processor: ${tx.processor ?? "Unknown"}`
                            ),
                            20
                          )}
                          two={
                            isRedacting ? (
                              <Redacted.Labelled />
                            ) : data?.type === "airtime" ? (
                              Util.safeText(bill?.phone_number)
                            ) : (
                              trimLongString(
                                `${Util.safeValue(
                                  tx.account_number
                                    ? tx.account_number
                                    : transfer_in?.source
                                    ? transfer_in?.account_number
                                    : tx.card_pan
                                    ? Util.maskCardPan(tx.card_pan)
                                    : undefined
                                )} • ${Util.safeText(
                                  tx?.bank
                                    ? tx?.bank
                                    : transfer_in?.source
                                    ? transfer_in?.source?.bank
                                    : ""
                                )}`,
                                30
                              )
                            )
                          }
                        />
                      )
                    }
                    five={
                      isRedacting ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeText(formatNumberToCurrency(Number(data?.amount), "NGN"))
                      )
                    }
                    six={
                      isRedacting ? (
                        <Redacted.Table />
                      ) : (
                        Util.safeText(trimLongString(data?.type, 15))
                      )
                    }
                    seven={
                      isRedacting ? <Redacted.Table /> : formatDateTime(data?.created_at)
                    }
                  />
                );
              })}
            </RavenTable>
          )}
        </div>
      </PageLayout>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() =>
          handleDownload(String(view.data.id))
        }
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={combinedLoading}
        hideCancel
        inlineCopyContent={generateModalContent(view)}
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={view?.data?.direction}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            meta={JSON.stringify(view.data)}
            onClick={() => {}}
            content={generateModalContent(view)}
          />
        }
      </ActionModal>
    </DashboardLayout>
  );
};

export default PosTransactions;


function generateModalContent(view: {data: Partial<{
  meta: any;
  tx: any;
} & PosTransactions>}){
  
  return [
    {
      label: "Amount",
      value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
      // copy: true,
    },

    
    ...renderConditionally(
      "Card Pan",
      view.data?.tx?.card_pan
        ? Util.maskCardPan(view.data?.tx?.card_pan)
        : undefined
    ),

    {
      label: "Bal Before",
      value: `₦${formatNumWithComma(view.data.b_before ?? 0, "ngn")}`,
    },
    {
      label: "Bal After",
      value: `₦${formatNumWithComma(view.data.b_after ?? 0, "ngn")}`,
    },

    ...renderConditionally("RRN", view?.data?.rrn),

    {
      label: "Fee",
      value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
    },
    ...renderConditionally(
      "Recipient",
      formatMetaData(view.data.meta_data ?? "")?.account_name
    ),
    ...renderConditionally(
      "Account Number",
      view?.data?.tx?.account_number
        ? view?.data?.tx?.account_number
        : view?.data?.tx?.source
        ? view?.data?.tx?.account_number
        : null
    ),
    ...renderConditionally(
      "Bank",
      view?.data?.tx?.bank
        ? view?.data?.tx?.bank
        : view?.data?.tx?.source
        ? view?.data?.tx?.source?.bank
        : ""
    ),

    {
      label:
        view?.data?.direction === "credit" ? "Session ID" : "Transaction Ref",
      value: view.data?.reference,
      copy: true,
      trim: true,
      tooltip: true,
      tooltipValue: view.data?.reference,
    },

    

    {
      label: "TID",
      value: Util.safeText(view.data?.t_id),
    },


    {
      label: "Date Authorized",
      value: formatDateTime(view?.data.created_at ?? ""),
    },
  ]
}