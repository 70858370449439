import TabComponent from "@/components/common/Tabs";
import "./styles/index.css";
import { icons } from "@/assets/icons";
import TransferStatusMetrics from "../transferStatusMetrics";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

type Metrics = {
  title: string;
  value: number;
  count: number;
  type: "success" | "pending" | "failed";
}[];

const PosInsightsSideBar = ({
  tabs,
  metrics,
  title,
  volumeNumberOptions,
  countNumberOptions,
}: {
  tabs?: string[];
  metrics?: Metrics;
  title?: string;
  volumeNumberOptions?: NumberOption;
  countNumberOptions?: NumberOption;
}) => {
  const chartData = metrics && [
    {
      name: title,
      success: metrics?.[0]?.value ?? 0,
      pending: metrics?.[1]?.value ?? 0,
      failed: metrics?.[2]?.value ?? 0,
    },
  ];

  return (
    <aside className="pb-insights-sidebar">
      <h4 className="section-title">{title || "Status Metrics"}</h4>
      <div>
        {tabs && tabs?.length > 0 && metrics && (
          <TabComponent defaultValue={tabs[0]}>
            <TabComponent.Tabs tabs={tabs} className="pb-insights-sidebar__tab" />
            {tabs.map((n) => (
              <TabComponent.Content className="" value={n} key={n}>
                <div>
                  <div
                    style={{
                      padding: "1.2rem",
                      marginBottom: "2rem",
                      borderRadius: "10px ",
                    }}
                    className="pos-insights-tabs"
                  >
                    <div className="fake-select">
                      All time
                      <figure>{icons.chevron_down}</figure>
                    </div>
                    <div className="pos-insights-tabs__stacked-bars">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={chartData} layout="vertical" stackOffset="expand">
                          <XAxis type="number" hide />
                          <YAxis type="category" hide dataKey="name" />
                          <Tooltip />
                          <Bar
                            dataKey="success"
                            fill="#1ACE37"
                            stackId="a"
                            radius={[10, 0, 0, 10]}
                          />
                          <Bar dataKey="warning" fill="#EEBB87" stackId="a" />
                          <Bar
                            dataKey="error"
                            fill="#FF0F00"
                            stackId="a"
                            radius={[0, 10, 10, 0]}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <div className="pb-insights-sidebar__metrics">
                    {metrics?.map((tsm) => (
                      <TransferStatusMetrics
                        {...tsm}
                        key={tsm.title}
                        value={tsm.value.toString()}
                        count={tsm.count.toString()}
                        type={tsm.type}
                      />
                    ))}
                  </div>
                </div>
              </TabComponent.Content>
            ))}
          </TabComponent>
        )}
        {!tabs && metrics && (
          <div>
            <div
              style={{ padding: "1.2rem", marginBottom: "2rem", borderRadius: "10px " }}
              className="pos-insights-tabs"
            >
              <div className="fake-select">
                All time
                <figure>{icons.chevron_down}</figure>
              </div>
              <div className="pos-insights-tabs__stacked-bars">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={chartData} layout="vertical" stackOffset="expand">
                    <XAxis type="number" hide />
                    <YAxis type="category" hide dataKey="name" />
                    <Tooltip />
                    <Bar
                      dataKey="success"
                      fill="#1ACE37"
                      stackId="a"
                      radius={[10, 0, 0, 10]}
                    />
                    <Bar dataKey="warning" fill="#EEBB87" stackId="a" />
                    <Bar
                      dataKey="error"
                      fill="#FF0F00"
                      stackId="a"
                      radius={[0, 10, 10, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            <div className="pb-insights-sidebar__metrics">
              {metrics?.map((tsm) => (
                <TransferStatusMetrics
                  {...tsm}
                  valueNumberOptions={volumeNumberOptions}
                  countNumberOptions={countNumberOptions}
                  key={tsm.title}
                  value={tsm.value.toString()}
                  count={tsm.count.toString()}
                  type={tsm.type}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </aside>
  );
};

export default PosInsightsSideBar;
