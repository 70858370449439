import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import Redacted, { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { formatDateTime, trimLongString } from "@/utils/helper/Helper";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import { usePagination } from "@/hooks/usePagination";
import { GLOBAL_PER_PAGE } from "@/constants";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { useSelector } from "react-redux";
import { PersonalAuditTrailEntry } from "@/redux/slices/personal/api/analytics/api";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";

const PersonalAuditTrail = () => {
  const { isDarkMode } = useThemeMode();

  const pagination = usePagination();
  const { currentPage } = pagination;
  const { data, isLoading, isFetching } = personalReduxAPI.useGetAuditTrailQuery({
    page: currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate as string,
    start_date: pagination.filterValue.startDate as string,
  });

  console.log(data, 'datum')

  const combinedLoading = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(combinedLoading);

  const {isRedacting} = useSelector((state:RootState) => state.general)
  const [view, setView] = useState<{
    on: boolean;
    data: Partial<PersonalAuditTrailEntry>;
  }>({
    on: false,
    data: {},
  });
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Audit Trails">
        <SmartFilter hideFilter hideExport  />

        <div className={cn("personal-trx-table", isDarkMode && "personal-trx-table-dark")}>
          {isEmpty(data) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "USER EMAIL",
                "ACTION",
                "DESCRIPTION",
                "DATE",
              ]}
            >
              {Util.safeArray(data).map((data, idx) => (
                <RavenTableRow
                  key={idx}
                  onRowClick={() =>
                    setView({
                      on: true,
                      data,
                    })
                  }
                  one={
                    isRedacting ? <Redacted.Table /> : Util.safeValue(data?.user)
                  }
                  two={isRedacting ? <Redacted.Table /> : Util.safeValue(data?.action)}
                  three={
                    isRedacting ? (
                      <Redacted.Table />
                    ) : (
                      Util.safeValue(trimLongString(data?.action, 30))
                    )
                  }
                  four={
                    isRedacting ? <Redacted.Table /> : formatDateTime(data?.created_at)
                  }
                />
              ))}
            </RavenTable>
          )}
        </div>
      </PageLayout>
      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() => {
          setView({ data: {}, on: false });
        }}
        actionText={"Close Details"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="audit-modal"
        normal
        loading={combinedLoading}
        hideCancel
        title={`Audit Log`}
      >
        {
          <InlineDetails
            title={"Audit Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            onClick={() => {}}
            content={[
              {
                label: "User Email",
                value: Util.safeValue(view?.data?.user),
              },
              {
                label: "Action Taken",
                value: Util.safeValue(view?.data?.action),
              },
              {
                label: "Description",
                value: Util.safeValue(view?.data?.action),
              },
              {
                label: "Date",
                value: formatDateTime(view?.data.created_at ?? ""),
              },
            ]}
          />
        }
      </ActionModal>
    </DashboardLayout>
  );
};

export default PersonalAuditTrail;
