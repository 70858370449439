import React, { useEffect, useState } from "react";
import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import {
  IconVault,
  formatDateTime,
  formatStatus,
  mapHotkey,
} from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Dropdown from "@/components/common/dropdown";
import { icons } from "@/assets/icons";
import "../styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { Row } from "@/components/common/stacks";
import { AppDispatch } from "@/redux/store";

const BRagnarStrike = () => {
  const [empty, setEmpty] = useState(false);
  const [allChecked, setIsAllChecked] = useState(false);
  const [checked, setChecked] = useState<SN[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [blockModal, showBlockModal] = useState<{
    chi: any;
    on: boolean;
    reason: string;
    type?: string;
  }>({ chi: {}, on: false, reason: "" });
  const [sendMessages, showSendMessages] = useState<{
    chi: any;
    on: boolean;
  }>({ chi: {}, on: false });
  const [integrity, showIntegrityModal] = useState<{
    chi: any;
    on: boolean;
  }>({ chi: {}, on: false });

  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const dropdown = (status: number) => {
    if (status === 1) {
      return [
        { label: "Confirm Integrity", img: IconVault(icons.info_circle) },

        {
          label: "Unblock User",
          img: IconVault(icons.check_circle_green),
        },
      ] as const;
    } else {
      return [
        { label: "Confirm Integrity", img: IconVault(icons.info_circle) },
        {
          label: "Block User",
          img: IconVault(icons.block_circle),
        },
      ] as const;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    fetchRecords(dispatch, currentPage);
    setChecked([]);
    setIsAllChecked(false);
  }, [currentPage]);

  const { businesses, loading } = useSelector(
    (state: RootState) => state.businessBusinesses
  );

  const blockedBiz = businesses?.data;

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const tableHeader = [
    "Business Details",
    "Amount",
    "Transaction Type",
    "Date Captured",
    "Status",
    "",
  ];

  const isCheckActive = checked?.length > 0 || allChecked;

  return (
    <div style={{ height: "67vh", display: "flex", flex: 1, flexDirection: "column" }}>
      <SmartFilter
        page="insights"
        hideFilter={isCheckActive}
        hideExport={isCheckActive}
        dynamicNode={
          isCheckActive ? (
            <Row gap={1.6}>
              <UnblockBizBtn
                onClick={() => {
                  triggerBlock(showBlockModal, blockModal);
                }}
              />
              <MessageBizBtn
                onClick={() => {
                  showSendMessages({
                    on: true,
                    chi: {
                      count: checked?.length,
                    },
                  });
                }}
              />
            </Row>
          ) : null
        }
        searchTitle={`Search ragnarred biz by email...`}
        pagination={{
          currentPage: currentPage,
          itemsPerPage: Number(businesses.per_page),
          // itemsPerPage: Number(businesses.per_page),
          totalItems: businesses.total,
          onPageChange: function (page: number): void {
            setCurrentPage(page);
          },
          activeFilter: `All ragnarred`,
        }}
      />

      {(!isRedacting && loading) || blockedBiz?.length === 0 || !blockedBiz ? (
        <EmptyScreen loading={loading} />
      ) : (
        <div className="table">
          <RavenTable action={false} className="table__main" headerList={tableHeader}>
            {blockedBiz?.map((data: Partial<BlockedBusiness>, index: number) => (
              <RavenTableRow
                key={index}
                {...{
                  one: loading ? <Redacted.Table /> : data.business_email,
                  two: loading ? (
                    <Redacted.Table />
                  ) : (
                    formatNumberToCurrency(Number(data.amount), "NGN")
                  ),
                  three: loading ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    Util.cleanText(Util.safeValue(data.type_of_transaction))
                  ),
                  four: loading ? (
                    <Redacted.Table />
                  ) : (
                    formatDateTime(String(data.created_at))
                  ),
                  five: loading ? (
                    <Redacted.Badge />
                  ) : (
                    <div>
                      {formatStatus(
                        data.ragnar_status === 1 ? "indecisive" : "genuine"
                        // discoverStatus(data) === 1 ? "active" : "inactive"
                      )}
                    </div>
                  ),

                  six: (
                    <div>
                      <Dropdown
                        items={dropdown(Number(data.user_blocked)) as any}
                        defaultValue={dropdown(Number(data.user_blocked))[0]}
                        onChange={(e) => {
                          if (e.label.includes("lock")) {
                            showBlockModal({
                              chi: {
                                user_id: data.user_id,
                                status: data.user_blocked,
                                type: "block-user",
                              },
                              on: true,
                              reason: "",
                            });
                          }

                          if (e.label.includes("Integrity")) {
                            showIntegrityModal({ chi: data, on: true });
                          }
                        }}
                        className="ragna-dropdown"
                        buttonChild={() => (
                          <ActionDrop
                            more
                            // onMoreClick={() => {
                            //   navigate("/personal-users/re43rfs");
                            // }}
                          />
                        )}
                      />
                    </div>
                  ),
                }}
              />
            ))}
          </RavenTable>
        </div>
      )}
    </div>
  );
};

export default BRagnarStrike;

function triggerBlock(
  showBlockModal: React.Dispatch<
    React.SetStateAction<{ chi: any; on: boolean; reason: string }>
  >,
  blockModal: { chi: any; on: boolean; reason: string }
  // data: Partial<BlockedBusiness>
) {
  showBlockModal({
    ...blockModal,
    on: !blockModal.on,
    chi: {
      type: "multiple",
      status: 1,
    },

    // chi: {
    //   business_id: data.business_id,
    //   status: data.block_business,
    // },
  });
}

function fetchRecords(dispatch: AppDispatch, currentPage: number) {
  dispatch(
    BugissApi.business.fetchRagnaroked({
      current_page: currentPage,
    })
  );
}

const MessageBizBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="resend-btn" color="orange-dark" onClick={onClick}>
      Resend Message
    </RavenButton>
  );
};

const UnblockBizBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="block-btn" color="orange-dark" onClick={onClick}>
      Unblock
    </RavenButton>
  );
};
