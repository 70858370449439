import env from "@/env";
import { APIResponseHandler } from "@/utils/api";
import { PersonalApi } from "@/utils/axios/personal";
import { filterAPIQueryParams } from "@/utils/helpers";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateBuilderContext } from "../..";
import { PersonalTransactionsAPI } from "./transactions/api";
import { PersonalUsersAPI } from "./user";
import { PersonalTransfersAPI } from "./transfers/api";
import { PersonalBillersAPI } from "./bills/api";
import { PersonalCardsAPI } from "./cards/api";
import { PersonalDepositsAPI } from "./deposits/api";
import { PersonalSavingsAPI } from "./savings/api";
import { PersonalComplianceAPI } from "./compliance/api";
import { PersonalFraudAPI } from "./fraud/api";
import { PersonalGeneralAPI } from "./general/api";
import { PersonalOverviewAPI } from "./overview/api";
import { PersonalAnalyticsAPI } from "./analytics/api";
import { PersonalRewardsAPI } from "./rewards/api";
import { PersonalNotificationsAPI } from "./notifications/api";

export const PersonalTagTypes = ["PersonalAPI.getTransfers"] as const;

/**`start_date` `end_date` `per_page` `page` `search` */
export type PersonalBaseParams = {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
  per_page: SN;
  page: SN;
  search?: string;
  email?: string;
};

export interface PersonalPaginationParams {
  per_page: SN;
  page: SN;
  transaction_id: SN;
  email: string;
  transaction_type: SN;
  status: SN;
  type: SN;
  search: string;
  start_date: string;
  end_date: string;
  username: string;
}

type TagTypes = (typeof PersonalTagTypes)[number];

export const personalReduxAPI = createApi({
  reducerPath: "personal-api",
  baseQuery: fetchBaseQuery({ baseUrl: env.business_api }),
  tagTypes: PersonalTagTypes,
  endpoints: (builder) => ({
    ...PersonalTransactionsAPI(builder),
    ...PersonalUsersAPI(builder),
    ...PersonalTransfersAPI(builder),
    ...PersonalBillersAPI(builder),
    ...PersonalCardsAPI(builder),
    ...PersonalDepositsAPI(builder),
    ...PersonalSavingsAPI(builder),
    ...PersonalComplianceAPI(builder),
    ...PersonalFraudAPI(builder),
    ...PersonalGeneralAPI(builder),
    ...PersonalOverviewAPI(builder),
    ...PersonalAnalyticsAPI(builder),
    ...PersonalRewardsAPI(builder),
    ...PersonalNotificationsAPI(builder),
  }),
});

export type PersonalBuilder = CreateBuilderContext<TagTypes, "personal-api">;

interface GetHandlerArgs<T> {
  route: string;
  params?: T;
  baseUrl?: string;
  objectKey?: string;
  mutateData?: (data: any) => any;
}

export const createPersonalURL = (route: string, search: IsUncertain<SN>) => {
  if (search) return `${route}/search`;
  return route;
};

export const pbGetHandler = async <T extends Record<string, IsUncertain<SN>>>(
  args: GetHandlerArgs<T>
) => {
  const { params = {}, route, baseUrl = "support", objectKey, mutateData } = args;

  const _fakeMutate = (data: any) => data;

  const mutateFunc = mutateData ?? _fakeMutate;

  try {
    const response = await PersonalApi.get<APIResponse<any>>(`${baseUrl}/${route}`, {
      params: filterAPIQueryParams(params),
    });

    APIResponseHandler.tryHandler({ response });

    if (objectKey) return { data: mutateFunc(response.data.data[objectKey]) };

    return { data: mutateFunc(response.data.data) };
  } catch (error) {
    APIResponseHandler.catchHandler(error);
    throw error;
  }
};
