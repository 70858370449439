import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import EmptyScreen from "@/components/common/emptyScreen";

export const PosInsightsUsers = () => {
  const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
    module: INSIGHT_TYPES.USER,
  });

  const uMetrics: InsightsUser = data?.overview as any;
  const combinedLoading = isFetching || isLoading;

  type Metric = {
    title: string;
    value: number;
    count: number;
    type: "success" | "pending" | "failed";
  };

  return (
    <div className="pos-insights-tabs">
      {combinedLoading ? (
        <EmptyScreen loading={combinedLoading} />
      ) : (
        <Row>
          {/* <PosInsightsSideBar metrics={formatMetrics(data?.overview?.statusMetrics)} /> */}
          <CategoryMetricsCard
            title="PARTNERS USER METRICS"
            data={[
              { label: "Merchant", value: uMetrics?.category?.Merchant },
              { label: "Agent", value: uMetrics?.category?.Agent },
            ]}
          />
          <MetricsGraph
            data={uMetrics?.usersByMonth as any}
            count={"Users for 2024"}
            dataKey="totalCount"
            totalVolume={uMetrics?.totalUsers ?? 0}
            volumeNumberOptions={{ hideDecimal: true, hideSymbol: true }}
          />
        </Row>
      )}
    </div>
  );
};
