import { BaseQueryApi } from "@reduxjs/toolkit/query";
import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PartnersTransactionsApi = (builder: PartnersBuilder) => {
  return {
    getTransactions: createQueryWithRoute<
      PartnersTransactionsModel,
      Partial<PartnersPaginationParams>
    >(builder, "partner/all-transactions", { name: "getTransactions" }),

    getTransactionsExport: createQueryWithRoute<
      PartnersTransactionsModel,
      Partial<PartnersPaginationParams>
    >(builder, "partner/all-transactions", { export: true, name: "getTransactionsExport" }),

    getTerminalTransactions: createQueryWithRoute<
    PartnersTransactionsModel,
    Partial<PartnersPaginationParams>
  >(builder, "partner/terminal/transactions", { name: "getTerminalTransactions" }),

  getTerminalTransactionsExport: createQueryWithRoute<
    PartnersTransactionsModel,
    Partial<PartnersPaginationParams>
  >(builder, "partner/terminal/transactions", { export: true, name: "getTerminalTransactionsExport" }),


    getATransaction: builder.query<PartnersTransactions, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `transaction/${params?.id}` }, thunk);
      },
    }),

    getReversals: builder.query<{ data: any; pagination: Pagination }, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `pending_reversals`, params }, thunk);
      },
    }),
  };
};
