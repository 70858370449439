import "./styles/index.css";
import { DashboardInfoCard } from "../../../components/common/dashboardInfo";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import TabComponent from "@/components/common/Tabs";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { OverviewLineChart } from "@/components/charts/overview-chart";

import { useDispatch, useSelector } from "react-redux";



const TempData = [
  {
    title: "Total Merchants",
    value: "11,456,000",
    stats: "+6000 This month",
    isPositive: true,
  },
  {
    title: "Total Transactions",
    value: "₦1,414,456,000",
    stats: "+6% This month",
    isPositive: true,
  },
  {
    title: "Volume Processed",
    value: "₦1,556,490,000",
    stats: "+8% This month",
    isPositive: true,
  },

  {
    title: "Merchant Balance",
    value: "₦1,556,490,000",
  },
  {
    title: "Virtual Account",
    value: "1,556,490,000",
    stats: "+8% This month",
    isPositive: true,
  },
  {
    title: "Collection",
    value: "₦1,556,490,000",
    stats: "+8% This month",
    isPositive: true,
  },
];

const Tabs = ["Merchants", "Transaction", "Virtual Account"];

export const reactSelectStyle = {
  control: (base: any, state: any) => ({
    ...base,
    border: state.isFocused ? "0.1rem solid #6F6F6F" : "0.1rem solid #6F6F6F",
    // backgroundColor: state.isSelected ? "#6F6F6F" : "white",
    boxShadow: state.isFocused ? "0.1rem solid #6F6F6F" : 0,
    "&:hover": {
      // border: state.isFocused ? 0 : 0
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "rgba(204, 204, 204, .3)" : "white",
    color: state.isSelected ? "#020202" : "#020202",
  }),
};

function AtlasOverview() {
  const dispatch = useDispatch();


  return (
    <DashboardLayout>

      <main className="buggies-business-dashboard">
        <h1 className="page-title text-white-black">Overview</h1>
        <DashboardInfoCard.Wrapper className="highlighted-content-wrapper">
          {TempData.map((ele) => (
            <DashboardInfoCard key={ele.title} {...ele} />
          ))}
        </DashboardInfoCard.Wrapper>

        <div className="buggies-business-dashboard__chart-area highlighted-content-wrapper">
          <TabComponent defaultValue={Tabs[0]}>
            <TabComponent.Tabs tabs={Tabs} />
            {[0, 1].map((n) => (
              <div
                key={n}
                className="buggies-business-dashboard__chart-content bdr-eee-333"
              >
                <TabComponent.Content value={Tabs[n]}>
                  <div hidden className="buggies-business-dashboard__select-area">
                    <RavenInputField
                      selectOption={[
                        { label: "Yearly", value: "yearly" },
                        { label: "Monthly", value: "monthly" },
                        { label: "Weekly", value: "weekly" },
                        { label: "Daily", value: "daily" },
                      ]}
                      type="select"
                      color="black-light"
                      selectStyles={reactSelectStyle}
                      placeholder="Yearly"
                    />
                  </div>
                  <div className="buggies-business-dashboard__chart">
                    <OverviewLineChart data={[]} />
                  </div>
                </TabComponent.Content>
              </div>
            ))}
          </TabComponent>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default AtlasOverview;
