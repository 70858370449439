import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import EmptyScreen from "@/components/common/emptyScreen";

export class PosInsightsTransfers {
  static BankTransfers = () => {
    const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
      module: INSIGHT_TYPES.SETTLEMENT,
    });

    type Metric = {
      title: string;
      value: number;
      count: number;
      type: "success" | "pending" | "failed";
    };

    const combinedLoading = isFetching || isLoading;

    function formatMetrics(metrics: StatusMetrics | undefined): Metric[] {
      if (!metrics) return [];

      return [
        {
          title: "Successful Transfers",
          value: metrics.successPayment ?? 0,
          count: metrics.successPaymentCount ?? 0,
          type: "success",
        },
        {
          title: "Pending Transfers",
          value: metrics.pendingPayment ?? 0,
          count: metrics.pendingPaymentCount ?? 0,
          type: "pending",
        },
        {
          title: "Failed Transfers",
          value: metrics.failedPayment ?? 0,
          count: metrics.failedPaymentCount ?? 0,
          type: "failed",
        },
      ];
    }

    return (
      <div className="pos-insights-tabs">
        {combinedLoading ? (
          <EmptyScreen loading={combinedLoading} />
        ) : (
          <Row>
            <PosInsightsSideBar metrics={formatMetrics(data?.overview?.statusMetrics)} />
            {/* <CategoryMetricsCard /> */}
            <MetricsGraph
              data={data?.overview?.volumeMetrics.monthlyRecharge}
              count={data?.overview?.volumeMetrics.totalYearCount ?? 0}
              totalVolume={data?.overview?.volumeMetrics.totalYearVol ?? 0}
            />
          </Row>
        )}
      </div>
    );
  };
}
