import EmptyScreen from "@/components/common/emptyScreen";
import { useEffect, useState } from "react";
import "../../styles/index.css";

import ComplianceCards from "@/components/common/complianceCards/ComplianceCards";
import { BugissApi } from "@/redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "@/redux/store";

const SingleBusinessCompliance = () => {
  const { email } = useParams();

  const dispatch = useAppDispatch();

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });

  const { isRedacting } = useSelector((state: RootState) => state.general);

  // compliance int
  const [biz, setBiz] = useState<Partial<XBusinessesModel["business"]>>({});
  const [bizEmail, setBizEmail] = useState("");

  const availableComliances = [
    {
      label: "BVN Verification",
      value: "bvn",
    },
    { label: "NIN Verification", value: "nin" },

    {
      label: "Address Verification",
      value: "address",
    },
    {
      label: "CAC Verification",
      value: "cac",
    },
    {
      label: "Business Details",
      value: "business_info",
    },
  ];
  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  const compliance =
    business_compliances?.data?.business_verification_details;

  function detectCompliance(t: string): Partial<VerificationDetails> {
    const veriDetail = assertVeri(email as string, t);

    if (veriDetail) {
      return veriDetail;
    } else return {};
  }
  const [centralBiz, setCentralBiz] = useState<
    Partial<XBusinessesModel["business_info_on_central"]>
  >({});

  async function fetchData() {
    if (bizEmail) {
      const [businessesResponse] = await Promise.all([
        dispatch(
          BugissApi.business.fetchBusinessDetails({
            per_page: 20,
            business_email: email as string,
            // current_page: currentPage,
            // search_term: searchQuery,
          })
        ),
      ]);

      const resp = await businessesResponse;

      if (resp.payload?.status === "success") {
        setBiz(resp.payload.data.business);
        setCentralBiz(resp.payload.data.business_info_on_central);
      }
    }
  }

  useEffect(() => {
    setBizEmail(email ?? "");
  }, []);
  useEffect(() => {
    fetchData();
  }, [email, bizEmail]);
  useEffect(() => {
    fetchData();
  }, [email, bizEmail]);

  const assertVeri = (email: string, t: string): any => {
    try {
      var data: any = compliance[email as any];
      // const filter = data.filter((d) => d.type === selectedTab.value);
      const l = data?.filter((d: VerificationDetails) =>
        d?.id_verification_type.includes(t)
      )[0];

      if (l) {
        return l;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error, "caught exception");
    }
  };

  return (
    <div className="business-trx-wrap">
      {/* <h6>Transactions</h6> */}

      <>
        {/* <div className="type-title">
          <h6>{capitalizeFirstLetter(type.label)}</h6>
        </div> */}

        {(!isRedacting && loading) || availableComliances?.length === 0 ? (
          <EmptyScreen loading={loading} />
        ) : (
          availableComliances.map((chi, idx) => (
            <ComplianceCards
              key={idx}
              data={{
                business_email: email,
                business_details: biz,
                central_biz: centralBiz,
                director_details: business_compliances?.data
                  ?.directors_details_object,
                app: "Business Banking",
                type: chi,
                veriDetail: detectCompliance(chi.value),
              }}
            />
          ))
        )}
      </>
    </div>
  );
};

export default SingleBusinessCompliance;
