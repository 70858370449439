import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./styles/index.css";
import { OverviewLineChart } from "@/components/charts/overview-chart";
import { months } from "../../graphFilter/months";
import { iife } from "@/utils/general";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { formatNumWithComma } from "@/utils/helper/Helper";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";
import { FormatOptions } from "date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: Options = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    line: { tension: 0.4, borderWidth: 2 },
  },
  plugins: {
    legend: {
      display: false,
    },
    // tooltip: {
    //   callbacks: {
    //     label: function (context: any) {
    //       return `₦${context.raw.toLocaleString()}`;
    //     },
    //   },
    // },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        color: "#EEEEEE",
      },
      ticks: {
        callback: (value) => RavenNumberFormat(value, { numberAbbreviate: true }),
      },
    },
  },
};

type Options = React.ComponentProps<typeof Line>["options"];

const MetricsGraph: React.FC<{
  data?: MonthlyRecharge[];
  count: any;
  dataKey?: string;
  title?: string;
  totalVolume: number;
  maintainAspectRatio?: boolean;
  volumeNumberOptions?: NumberOption;
  countNumberOptions?: NumberOption;
  graphStyle?: {
    width: number;
    height: number;
  };
  containerHeight?: string;
}> = ({
  data,
  count,
  totalVolume,
  dataKey,
  title,
  maintainAspectRatio = true,
  volumeNumberOptions,
  graphStyle,
  containerHeight,
  countNumberOptions = { hideDecimal: true, hideSymbol: true },
}) => {
  let assignMonths: string[] = [];

  useEffect(() => {
    if (data) {
      data?.map((item, index) => {
        console.log(assignMonths.push(months[item.month - 1]));
      });
    }
  }, [data]);

  const datar = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: title ?? "User Metrics",
        data:
          (data as any)?.map((item: any) => item[dataKey ?? "totalVol"] as number) ?? [],
        fill: true,
        backgroundColor: "rgba(234, 135, 45, 0.1)",
        borderColor: "#EA872D",
        pointBackgroundColor: "#EA872D",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#EA872D",
      },
    ],
  };

  return (
    <div className="metrics-card" style={{ width: "100%" }}>
      <div className="metrics-card__header">
        <h2 className="metrics-card__title">{title?.toUpperCase()}</h2>
        <div className="metrics-card__year-selector">
          <span>Yearly</span>
          <span>2024</span>
        </div>
      </div>
      <div className="metrics-card__metrics">
        <div className="metrics-card__total-value">
          {RavenNumberFormat(totalVolume, volumeNumberOptions)}
        </div>
        <div className="metrics-card__count">
          {TypeIs.number(count)
            ? `COUNT • ${RavenNumberFormat(count, countNumberOptions)}`
            : count}
        </div>
      </div>
      <div
        className="metrics-card__chart-container"
        style={{ width: "100%", height: containerHeight }}
      >
        <Line
          data={datar}
          options={{
            ...options,

            responsive: true,
            maintainAspectRatio: maintainAspectRatio,
          }}
          {...graphStyle}
        />
      </div>
    </div>
  );
};

export default MetricsGraph;
