import "~/common/used-by-details/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import {
  RavenButton,
  RavenDateTime,
  RavenInputField,
  RavenToggleSlide,
  RavenToolTip,
  toast,
} from "@ravenpay/raven-bank-ui";
import TabComponent, { TabsElement } from "@/components/common/Tabs";
import { Column, Row, YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { PageLayoutTopBackButton } from "@/components/common/atoms";
import { useQueryLoadingState } from "../../__internals__/hooks/useQueryLoadingState";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import { useParams } from "react-router-dom";
import { renderMerchantTable } from "../pages";
import Gap from "@/components/common/styleComponents/Gap";
import { useEffect, useState } from "react";
import ActionModal from "@/components/common/modal/ActionModal";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import { formatNumberToCurrency } from "@/utils/helpers";
import { formatDate, trimLongString } from "@/utils/helper/Helper";
import AccountSummaryItemBlock from "@/components/common/accountSummary/itemBlock";
import CameraAssignBox from "../../__internals__/cameraSign/CameraAssignBox";
import "./styles/assign.css";
import { useProductColor } from "@/context/ProductTheme";
import Operators from "@/apps/business/businesses/components/operators";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { cn } from "@/utils/colorConvert";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { validateTerminalId } from "../../__internals__/validateTerminalId";
import InlineDetails from "@/components/common/inlineDetails";

export const MerchantDetails = () => {
  const { id } = useParams();

  const res = posReduxAPI.useGetAMerchantQuery({ id });

  const { data } = res;
  const combinedLoading = useQueryLoadingState(res);

  const [selectedTab, setSelectedTab] = useState<any>();
  const tabElement: TabsElement[] = [
    "Transactions",
    // {
    //   value: "Bank Transfers",
    //   label: "Bank Transfers",
    //   dropdown: {
    //     tabs: [
    //       { label: "Bank Transfers", value: "Bank Transfers" },
    //       { label: "Internal Transfers", value: "Internal Transfers" },
    //     ],
    //     maxWidth: "20rem",
    //   },
    // },
    {
      label: "Bills",
      value: "Bills",
      dropdown: {
        onChange(tab) {
          setSelectedTab(tab);
        },
        maxWidth: "15rem",
        tabs: ["Airtime", "Data", "Cable TV", "Betting", "Electricity"],
      },
    },
    "Card Collection",
    "Bank Collection",
    "Terminals",
    "Settlement",
    "Device Requests",
    // "Dispute",
  ];

  const [modal, setModal] = useState<any>("");
  const [isReqn, setIsReqn] = useState(false);

  async function handleActivateDeactivate() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      modal?.blocked ? "users/un_block" : "users/block",
      {
        email: data?.email,
        reason: modal.reason,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }
  // handleLienUnlien
  async function handleLienUnlien() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      "users/lien",
      {
        email: data?.email,
        amount: modal.amount,
        reason: modal.reason,
      },
      {
        base_url: env.pos_api + "/admin/v2/",
      }
    );

    if (resp?.status === "success") {
      setModal("");

      res.refetch();

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      setModal(undefined);

      toast.error(resp?.message);
    }
  }

  interface DataField {
    title: string;
    value: any; // The type can be more specific if you know the exact type of each field
  }

  interface Column {
    fields: DataField[];
  }

  // Define columns, each with its own set of fields
  const columns: Column[] = [
    {
      fields: [
        { title: "Merchant Name", value: data?.business_name },
        {
          title: "Date Joined",
          value: RavenDateTime({
            date: data?.created_at as Date,
            hideAgo: true,
          }),
        },
        {
          title: "Single Transfer Limit",
          value: formatNumberToCurrency(Number(data?.single_transfer_limit), "ngn"),
        },
      ],
    },
    {
      fields: [
        {
          title: "Owner's Fullname",
          value: Util.join(data?.fname).with(data?.lname ?? ""),
        },
        { title: "Last seen", value: "Yesterday" },
        {
          title: "Daily Transfer Limit",
          value: formatNumberToCurrency(Number(data?.daily_transfer_limit), "ngn"),
        },
      ],
    },
    {
      fields: [
        {
          title: "Business Email Address",
          value: trimLongString(data?.email, 25),
        },
        { title: "Completed Verifications", value: "1 of 4" },
        {
          title: "Daily Volume",
          value: formatNumberToCurrency(Number(data?.daily_volume), "ngn"),
        },
      ],
    },
    {
      fields: [
        { title: "Mobile Number", value: data?.phone },
        { title: "Terminals Owned", value: "unknown" },
        { title: "Daily Count", value: data?.daily_count ?? 0 },
      ],
    },
  ];

  const columns2: Column[] = [
    {
      fields: [
        { title: "Single Transfer Limit", value: data?.single_transfer_limit },
        { title: "Fee Reference", value: data?.fee_ref },
        { title: "Profile Bank", value: data?.profile_bank },
      ],
    },
    {
      fields: [
        { title: "Daily Transfer Limit", value: data?.daily_transfer_limit },

        {
          title: "Ref Bonus",
          value: 0,
        },
        { title: "Last seen", value: "Yesterday" },
      ],
    },
    {
      fields: [
        { title: "Daily Volume", value: data?.daily_volume },

        {
          title: "Cashback Paid(Merchant)",
          value: formatNumberToCurrency(Number(data?.cashback_paid), "ngn"),
        },
        { title: "Completed Verifications", value: "1 of 4" },
      ],
    },
    {
      fields: [
        { title: "Daily Count", value: data?.daily_count },

        { title: "Profile Account No", value: data?.profile_account_number },
        { title: "Profile Account Name", value: data?.profile_account_name },
      ],
    },
  ];

  const [activeColumn, setActiveColumn] = useState(0);

  function toggleActiveColumn() {
    if (activeColumn === 0) {
      setActiveColumn(1);
    } else {
      setActiveColumn(0);
    }
  }

  const summaryBlock = [
    {
      label: "Available Balance",
      value: formatNumberToCurrency(Number(data?.available_bal), "ngn"),
    },
    {
      label: "Ledger Balance",
      value: formatNumberToCurrency(Number(data?.ledger_balance), "ngn"),
    },
    {
      label: "Liened Amount",
      value: formatNumberToCurrency(Number(data?.lien), "ngn"),
    },
    {
      label: "Business Managers",
      value: 0,
    },
  ];

  const isMerchantVerified = data?.bvn && data?.nin && data?.email_verified;

  const [autoAssign, setAutoAssign] = useState(true);
  const [selectedType, setSelectedType] = useState("Yes, It is");
  const selectTypeList = ["Yes, It is", "No, it’s not"];

  const [uploadViaCamera, setUploadViaCamera] = useState(false);
  const [assignBankbox, setAssignBankbox] = useState<{
    on: boolean;
    chi: any; //BankBoxUser;
  }>({
    on: false,
    chi: {
      id: 0,
      fname: "",
      lname: "",
      poseidon_email: "",
      bvn: "",
      nin: "",
      phone: "",
      affiliate_app: "",
      affiliate_app_id: "",
      poseidon_business_name: "",
      poseidon_business_address: "",
      poseidon_business_type: null,
      poseidon_business_description: "",
      poseidon_profile_reference: "",
      business_category: "",
      region: "",
      created_at: "",
      updated_at: "",
    },
  });

  const [terminalDetails, setTerminalDetails] = useState<any>({});
  const [assignFormData, setAssignFormData] = useState<any>({
    // affiliate_app_id: '',
    poseidon_business_email: data?.email,
    serial_number: "",
    label: "",
    poseidon_business_name: data?.business_name,
    exist_nibbs: true,
    // term_id: '',
    // auto_gen: true,
    auto_generate: true,
    is_nibss: true,
    terminal_id: "",
  });

  const [showTerminalError, setShowTerminalError] = useState(false);
  async function handleAssign() {
    if (!validateTerminalId(assignFormData?.terminal_id) && !autoAssign) {
      toast.error(
        "Terminal ID must be exactly 8 characters, all capitalized and alphanumeric."
      );
      setShowTerminalError(true);
      return;
    }
    const obj = {
      ...assignFormData,
      auto_generate: autoAssign,
      is_nibss: autoAssign ? false : assignFormData?.exist_nibbs,
      terminal_id: assignFormData?.terminal_id,
    };

    setIsReqn(true);

    const resp = await sendRequest("admin/v2/terminal/assign_bankbox", obj, {
      base_url: env.pos_api,
    });

    if (resp?.status === "success") {
      setAssignBankbox({
        ...assignBankbox,
        on: false,
      });
      setIsReqn(false);
      // 	// getFirstFetch();
      setAssignFormData({
        exist_nibbs: true,
        // affiliate_app_id: '',
        poseidon_business_email: data?.email,
        serial_number: "",
        label: "",
        poseidon_business_name: data?.business_name,
        auto_generate: false,
        is_nibss: true,
        terminal_id: "",
      });
    } else {
      setIsReqn(false);
    }
  }

  const { colorToken, colorVariable } = useProductColor();

  // hni manager

  const managersReq = businessReduxAPI.useGetOperationStaffsQuery(
    {},
    { skip: !modal.managers }
  );
  // const managersReq = posReduxAPI.useGetManagersQuery({}, { skip: !modal.managers });

  // const hniOps = managersReq?.data?.result;

  const hniOps = managersReq?.data?.operation_staffs;

  const opsLoading = managersReq?.isFetching || managersReq?.isLoading;

  function addManagerToList(e: HNIOperators) {
    const exists = bizManagers.find((el) => el?.id === e.id);

    if (!exists) {
      setBizManagers((prev) => [...prev, e]);
    }
  }

  function removeManagerToList(e: HNIOperators) {
    const newOps = bizManagers.filter((el) => el?.id !== e.id) as HNIOperators[];

    setBizManagers(newOps);
  }

  async function handleAddManager() {
    setIsAddn(true);

    let resp;

    resp = await sendRequest(
      "admin/v2/hni/assign/manager",
      {
        merchantId: id?.toString(),
        staffEmail: bizManagers.map((d) => d?.email)[0],
      },
      {
        base_url: env.pos_api,
      }
    );

    if (resp?.status === "success") {
      setModal({
        managers: false,
      });

      toast.success(resp?.message);
      setIsAddn(false);
    } else {
      setIsAddn(false);
      toast.success(resp?.message);
    }
  }

  const [managerValue, setManagerValue] = useState({});
  const [bizManagers, setBizManagers] = useState<Partial<HNIOperators[]>>([]);
  const [isAddn, setIsAddn] = useState(false);

  // modal form state
  interface FormState {
    amount?: SN;
    reason_for_deduction?: string;
    description?: string;
    reason?: string;
    user_id?: string;
    user_pin?: string;
  }

  type Errors = Partial<Record<keyof FormState, string>>;

  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setErrors({});
  }, [modal]);

  async function handleDeduction() {
    setIsReqn(true);

    const resp = await sendRequest("admin/v2/users/deduct_balance", modal, {
      base_url: env.pos_api,
    });

    if (resp?.status === "success") {
      setAssignBankbox({
        ...assignBankbox,
        on: false,
      });
      setIsReqn(false);
      // 	// getFirstFetch();
      setModal({
        ...modal,
        reason: "",
        description: "",
        amount: "",
        email: "",
      });
    } else {
      setIsReqn(false);
    }
  }

  const assignPosTab = ["Bankbox", "K11"];
  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Merchant Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
        topRightContent={
          <RavenButton
            onClick={() => setModal({ managers: true })}
            color={colorVariable}
            label="Assign a Manager"
          />
        }
      >
        <div className="single-user-details__top-wrap">
          <div style={{ position: "relative" }} className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img">
              {icons.bank_profile}
              <div
                style={{ marginTop: "-3rem", marginBottom: "3rem" }}
                className="top-wrap__main-content--profile-img__user-status"
              >
                {!isMerchantVerified ? icons.slash_circle_01 : icons.check_verified}
                <p>{isMerchantVerified ? "Verified Merchant" : "Unverified Merchant"}</p>
              </div>
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                {(activeColumn === 0 ? columns : columns2).map((column, colIndex) => (
                  <MainContentCol key={colIndex}>
                    {column.fields.map((field, fieldIndex) => (
                      <MainContentCol.Item key={fieldIndex} title={field.title}>
                        {combinedLoading ? (
                          <Redacted.Table />
                        ) : (
                          Util.safeValue(field.value)
                        )}
                      </MainContentCol.Item>
                    ))}
                  </MainContentCol>
                ))}
              </div>

              <Column className="user-data__info-swapper">
                <figure
                  onClick={toggleActiveColumn}
                  className={cn({ inactive: activeColumn !== 0 })}
                >
                  {icons.chevron_down}
                </figure>
                <figure
                  onClick={toggleActiveColumn}
                  className={cn({ inactive: activeColumn !== 1 })}
                >
                  {icons.chevron_down}
                </figure>
              </Column>

              <AccountSummaryItemBlock
                data={summaryBlock as any}
                loading={combinedLoading}
              />
            </div>
          </div>

          <div className="user-data__account-summary">
            <div className="top-wrap__actions">
              <IconButton
                borderColor="#FF0F00"
                text="Block Merchant"
                icon="slash_circle_01"
                onClick={() =>
                  setModal({
                    type: "block",
                    blocked: Boolean(data?.block_),
                    label: Boolean(data?.block_) ? "Unblock" : "Block",
                  })
                }
              />
              <IconButton
                text="Lien Account"
                icon="info_circle"
                onClick={() =>
                  setModal({
                    type: "lien",
                    label: "Lien",
                  })
                }
              />
              <IconButton
                text="Assign Bankbox"
                icon="user_with_left_arrow"
                onClick={() =>
                  setModal({
                    type: "assign",
                    label: "Assign Bankbox",
                  })
                }
              />
              <IconButton
                text="Settlement Details"
                icon="info_circle"
                onClick={() =>
                  setModal({
                    type: "settlement",
                    label: "Settlement Details",
                  })
                }
              />
              <IconButton
                text="Make a Deduction"
                icon="minus_circle"
                onClick={() =>
                  setModal({
                    type: "deduct",
                    label: "Make a Deduction",
                  })
                }
              />
            </div>
          </div>
        </div>

        <TabComponent
          onTabValueChange={(e) => setSelectedTab(e)}
          tabs={tabElement}
          defaultValue={tabElement[0]}
        >
          <div
            style={{ minHeight: "60rem", height: "100%" }}
            className="single-user-details__table-wrap"
          >
            <YStack>
              <TabComponent.Tabs tabs={tabElement} />
            </YStack>
            <Gap size={10} />

            {renderMerchantTable(selectedTab, data?.email)}
          </div>
        </TabComponent>
      </PageLayout>

      <ActionModal
        visible={Boolean(modal.type === "block")}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleActivateDeactivate();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${modal?.label}`}
        btnColor={"red"}
        disableButton={!modal.reason && modal.blocked}
        body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
        title={`${modal?.label} Merchant?`}
      >
        {Util.renderIf(
          !modal.blocked,
          <>
            <p>Are you sure to Block Merchant</p>

            <Gap size={20} />

            <RavenInputField
              onChange={(e: { target: { value: any } }) =>
                setModal({
                  ...modal,
                  reason: e.target?.value,
                })
              }
              label="Block Reason*"
              placeholder="Enter Block Reason"
              color={"orange-light"}
            />
          </>
        )}
      </ActionModal>

      <ActionModal
        visible={Boolean(modal.type === "lien")}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleLienUnlien();
        }}
        cancelText="No, Cancel"
        actionText={`Yes, ${modal?.label}`}
        btnColor={"red"}
        disableButton={!modal.reason && modal.blocked}
        body={`Are you sure to ${modal?.label?.toLowerCase()} this terminal? You can always come back to perform the action.`}
        title={`${modal?.label} Merchant?`}
      >
        {Util.renderIf(
          !modal.lien,
          <>
            <p>Are you sure to Lien Merchant</p>

            <Gap size={20} />

            <RavenInputField
              onChange={(e: { target: { value: any } }) =>
                setModal({
                  ...modal,
                  reason: e.target?.value,
                })
              }
              label="Lien Reason*"
              placeholder="Enter Lien Reason"
              color={"orange-light"}
            />
            <Gap size={10} />

            <RavenInputField
              onChange={(e: { target: { value: any } }) =>
                setModal({
                  ...modal,
                  amount: e.target?.value,
                })
              }
              label="Lien Amount*"
              placeholder="Enter Lien Amount"
              type="amount"
              color={"orange-light"}
            />
          </>
        )}
      </ActionModal>

      <ActionModal
        visible={Boolean(modal.type === "assign")}
        onCancel={() => {
          setModal("");
        }}
        loading={isReqn}
        onClick={() => {
          handleAssign();
        }}
        cancelText="No, Cancel"
        hideCancel
        actionText={`Yes, ${modal?.label}`}
        btnColor={colorToken}
        title={`${modal?.label}`}
        disableButton={
          autoAssign && (!assignFormData?.label || !assignFormData?.serial_number)
            ? true
            : !autoAssign && (!assignFormData?.terminal_id || !assignFormData?.label)
            ? true
            : false
        }
      >
        <div className="assign-bb-modal">
          <div className="assign-bb-modal__title">
            <p>Allocate a bankbox to a user </p>
          </div>

          <TabComponent tabs={assignPosTab} defaultValue={assignPosTab[0]}>
            <div style={{ borderRadius: "10px", marginBottom: "1.3rem" }}>
              <TabComponent.Tabs tabs={assignPosTab} />
            </div>

            <TabComponent.Content value={assignPosTab[0]}>
              {uploadViaCamera ? (
                <>
                  {/* <WebcamCapture /> */}
                  <CameraAssignBox
                    onCancel={() => {
                      setUploadViaCamera(false);
                    }}
                    onFinish={(param: any) => {
                      setUploadViaCamera(false);
                      setTerminalDetails(param);
                      setAssignFormData((prev: any) => {
                        return { ...prev, serial_number: param?.actualValue };
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <div className="assign-bb-modal__form-body">
                    <RavenInputField
                      type={`account-number`}
                      maxLength={10}
                      color={colorVariable}
                      value={assignFormData?.serial_number}
                      label="BankBox Serial Number"
                      onChange={(e: any) => {
                        setAssignFormData({
                          ...assignFormData,
                          serial_number: e.target.value,
                        });
                        if (terminalDetails) {
                          setTerminalDetails({});
                        }
                      }}
                      placeholder="4989q3****"
                      name="serial"
                      labelSpanText={
                        terminalDetails?.fullValue ? (
                          ((
                            <Row
                              onClick={() => {
                                setUploadViaCamera(true);
                              }}
                              ai="center"
                            >
                              {icons.scan_img}

                              <p>Use webcam</p>
                            </Row>
                          ) as any)
                        ) : (
                          <div
                            onClick={() => {
                              setUploadViaCamera(true);
                            }}
                            className="camera-icon-box"
                          >
                            <div className="camera-icon">
                              <figure className="img-box grey-bg">
                                {icons.scan_img}
                              </figure>
                            </div>
                            <p>Use webcam </p>
                          </div>
                        )
                      }
                    />
                    <RavenInputField
                      type="text"
                      value={assignFormData?.label}
                      onChange={(e: any) => {
                        setAssignFormData({
                          ...assignFormData,
                          label: e.target.value,
                        });
                      }}
                      color={colorVariable}
                      label="BankBox Label"
                      placeholder="E.g Eatery one"
                      name="serial"
                    />
                    {/* address starts here */}
                    <Row>
                      <RavenInputField
                        type="text"
                        value={assignFormData?.city}
                        onChange={(e: any) => {
                          setAssignFormData({
                            ...assignFormData,
                            city: e.target.value,
                          });
                        }}
                        color={colorVariable}
                        label="City"
                        placeholder="E.g Lekki"
                        name="city"
                      />
                      <RavenInputField
                        type="text"
                        value={assignFormData?.state}
                        onChange={(e: any) => {
                          setAssignFormData({
                            ...assignFormData,
                            state: e.target.value,
                          });
                        }}
                        color={colorVariable}
                        label="State"
                        placeholder="E.g Lagos"
                        name="state"
                      />
                    </Row>
                    {/* address ends here */}
                    {/* auto generate box start */}
                    <div
                      style={{ position: "relative", zIndex: "20" }}
                      className="auto-generate-box-wrap"
                    >
                      <div className="togle-info-box">
                        <RavenToggleSlide
                          value={autoAssign}
                          checked={autoAssign}
                          onChange={() => {
                            setAutoAssign(!autoAssign);
                          }}
                          color={colorVariable}
                        />
                      </div>

                      <Row ai="center" gap={1}>
                        <p className="text">Autogenerate Terminal ID</p>
                        <figure className="img-box tooltip-hover-wrap">
                          {icons?.info_circle}
                          <RavenToolTip
                            textColor="white-light"
                            color={colorVariable}
                            position={`top-left`}
                            text="Autogenerate Terminal info"
                          />
                        </figure>
                      </Row>
                    </div>
                    {/* auto generate box end */}
                    {/* auto assin content start */}
                    {!autoAssign && (
                      <div className="auto-assign-content-box">
                        <RavenInputField
                          type="text"
                          value={assignFormData?.terminal_id}
                          showError={showTerminalError}
                          onChange={(e: any) => {
                            if (e.target.value?.length < 9) {
                              setShowTerminalError(false);
                              setAssignFormData((prev: any) => {
                                return { ...prev, terminal_id: e.target.value };
                              });
                            }
                          }}
                          color={colorVariable}
                          label="Terminal ID"
                          placeholder="Enter Terminal ID"
                          name="term_id"
                          // maxSize={8}
                        />
                        {/* select option start  */}
                        <div
                          className="select-option-box-wrap"
                          style={{ marginTop: "2.4rem" }}
                        >
                          <label htmlFor="">
                            Is this Terminal ID registered on NIBSS?
                          </label>
                          <div className={"export-modal__export-as--selector-wrap"}>
                            {selectTypeList?.map((chi, idx) => {
                              return (
                                <div
                                  key={idx}
                                  onClick={() => {
                                    setSelectedType(chi);
                                    setAssignFormData((prev: any) => {
                                      return {
                                        ...prev,
                                        exist_nibbs: chi === "Yes, It is" ? true : false,
                                      };
                                    });
                                  }}
                                  className={`selector-wrap__item ${
                                    selectedType === chi && "selected"
                                  }`}
                                >
                                  <figure>
                                    {selectedType === chi
                                      ? icons.radio_check
                                      : icons.radio_unchecked}
                                  </figure>
                                  <p>{chi}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* select option end */}
                      </div>
                    )}
                    {/* auto assin content end */}
                  </div>
                </>
              )}
            </TabComponent.Content>

            <TabComponent.Content value={assignPosTab[1]}>
              {uploadViaCamera ? (
                <>
                  {/* <WebcamCapture /> */}
                  <CameraAssignBox
                    onCancel={() => {
                      setUploadViaCamera(false);
                    }}
                    onFinish={(param: any) => {
                      setUploadViaCamera(false);
                      setTerminalDetails(param);
                      setAssignFormData((prev: any) => {
                        return { ...prev, serial_number: param?.actualValue };
                      });
                    }}
                  />
                </>
              ) : (
                <>
                  {" "}
                  <div className="assign-bb-modal__form-body">
                    <RavenInputField
                      type={`account-number`}
                      maxLength={10}
                      color={colorVariable}
                      value={assignFormData?.serial_number}
                      label="Terminal Serial Number"
                      onChange={(e: any) => {
                        setAssignFormData({
                          ...assignFormData,
                          serial_number: e.target.value,
                        });
                        if (terminalDetails) {
                          setTerminalDetails({});
                        }
                      }}
                      placeholder="4989q3****"
                      name="serial"
                      labelSpanText={
                        terminalDetails?.fullValue ? (
                          ((
                            <Row
                              onClick={() => {
                                setUploadViaCamera(true);
                              }}
                              ai="center"
                            >
                              {icons.scan_img}

                              <p>Use webcam</p>
                            </Row>
                          ) as any)
                        ) : (
                          <div
                            onClick={() => {
                              setUploadViaCamera(true);
                            }}
                            className="camera-icon-box"
                          >
                            <div className="camera-icon">
                              <figure className="img-box grey-bg">
                                {icons.scan_img}
                              </figure>
                            </div>
                            <p>Use webcam </p>
                          </div>
                        )
                      }
                    />
                    <RavenInputField
                      type="text"
                      value={assignFormData?.label}
                      onChange={(e: any) => {
                        setAssignFormData({
                          ...assignFormData,
                          label: e.target.value,
                        });
                      }}
                      color={colorVariable}
                      label="Terminal Label"
                      placeholder="E.g Eatery one"
                      name="serial"
                    />
                    {/* address starts here */}
                    <Row>
                      <RavenInputField
                        type="text"
                        value={assignFormData?.city}
                        onChange={(e: any) => {
                          setAssignFormData({
                            ...assignFormData,
                            city: e.target.value,
                          });
                        }}
                        color={colorVariable}
                        label="City"
                        placeholder="E.g Lekki"
                        name="city"
                      />
                      <RavenInputField
                        type="text"
                        value={assignFormData?.state}
                        onChange={(e: any) => {
                          setAssignFormData({
                            ...assignFormData,
                            state: e.target.value,
                          });
                        }}
                        color={colorVariable}
                        label="State"
                        placeholder="E.g Lagos"
                        name="state"
                      />
                    </Row>
                    {/* address ends here */}
                    {/* auto generate box start */}
                    <div
                      style={{ position: "relative", zIndex: "20" }}
                      className="auto-generate-box-wrap"
                    >
                      <div className="togle-info-box">
                        <RavenToggleSlide
                          value={autoAssign}
                          checked={autoAssign}
                          onChange={() => {
                            setAutoAssign(!autoAssign);
                          }}
                          color={colorVariable}
                        />
                      </div>

                      <Row ai="center" gap={1}>
                        <p className="text">Autogenerate Terminal ID</p>
                        <figure className="img-box tooltip-hover-wrap">
                          {icons?.info_circle}
                          <RavenToolTip
                            textColor="white-light"
                            color={colorVariable}
                            position={`top-left`}
                            text="Autogenerate Terminal info"
                          />
                        </figure>
                      </Row>
                    </div>
                    {/* auto generate box end */}
                    {/* auto assin content start */}
                    {!autoAssign && (
                      <div className="auto-assign-content-box">
                        <RavenInputField
                          type="text"
                          value={assignFormData?.terminal_id}
                          showError={showTerminalError}
                          onChange={(e: any) => {
                            if (e.target.value?.length < 9) {
                              setShowTerminalError(false);
                              setAssignFormData((prev: any) => {
                                return { ...prev, terminal_id: e.target.value };
                              });
                            }
                          }}
                          color={colorVariable}
                          label="Terminal ID"
                          placeholder="Enter Terminal ID"
                          name="term_id"
                          // maxSize={8}
                        />
                        {/* select option start  */}
                        <div
                          className="select-option-box-wrap"
                          style={{ marginTop: "2.4rem" }}
                        >
                          <label htmlFor="">
                            Is this Terminal ID registered on NIBSS?
                          </label>
                          <div className={"export-modal__export-as--selector-wrap"}>
                            {selectTypeList?.map((chi, idx) => {
                              return (
                                <div
                                  key={idx}
                                  onClick={() => {
                                    setSelectedType(chi);
                                    setAssignFormData((prev: any) => {
                                      return {
                                        ...prev,
                                        exist_nibbs: chi === "Yes, It is" ? true : false,
                                      };
                                    });
                                  }}
                                  className={`selector-wrap__item ${
                                    selectedType === chi && "selected"
                                  }`}
                                >
                                  <figure>
                                    {selectedType === chi
                                      ? icons.radio_check
                                      : icons.radio_unchecked}
                                  </figure>
                                  <p>{chi}</p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        {/* select option end */}
                      </div>
                    )}
                    {/* auto assin content end */}
                  </div>
                </>
              )}
            </TabComponent.Content>
          </TabComponent>
        </div>
      </ActionModal>

      <ActionModal
        visible={modal?.managers as boolean}
        onCancel={() => {
          setModal({ beneficiary: false });
        }}
        loading={isAddn}
        onClick={handleAddManager}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Assign Manager`}
        btnColor={`#EA872D`}
        subTitle="Assign a Manager to this business"
        title={"Assign Manager to Business"}
      >
        <RavenInputField
          color={colorVariable}
          value={managerValue}
          style={{ zIndex: "444" }}
          className="bugiss"
          loadingSelect={opsLoading}
          selectComponents={{ Option: CustomManagerOption }}
          label="Managers Email*"
          onChange={(e: any) => {
            setManagerValue({
              ...managerValue,
              e,
              label: Util.join(e.fname).with(e.lname),
            });
            addManagerToList(e);
          }}
          loading={opsLoading}
          placeholder="Enter managers email"
          selectOption={hniOps}
          type="select"
        />

        <Operators
          data={bizManagers as HNIOperators[]}
          onDelete={(e) => removeManagerToList(e)}
        />
      </ActionModal>

      {/* Deduction Modal */}

      <ActionModal
        visible={modal?.type === "deduct"}
        onCancel={() => {
          setModal({ type: "" });
        }}
        loading={isReqn}
        onClick={() => {
          handleDeduction();
        }}
        hideCancel
        disableButton={!modal.reason || !modal.amount}
        // cancelText="No, Cancel"
        actionText={`Make a Deduction`}
        btnColor={colorToken}
        body="Deduct specified amount from merchant balance"
        title={"Make a Deduction"}
      >
        <p style={{ marginBottom: "2rem" }}>Deduct a certain amount from this business</p>
        <RavenInputField
          color={"black-light"}
          // value={blockReason}
          className="bugiss-block-modal"
          label="Amount*"
          value={modal.amount}
          onChange={(e: any) =>
            setModal({
              ...modal,
              email: data?.email,
              amount: e.target.value,
            })
          }
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          placeholder="Amount*"
          type="number"
        />
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          // value={blockReason}
          className="bugiss-block-modal"
          label="Deduction Reason*"
          value={modal.reason}
          onChange={(e: any) =>
            setModal({
              ...modal,
              reason: e.target.value,
            })
          }
          showError={errors.amount ? true : false}
          errorText={errors.amount}
          placeholder="Enter reason..."
          type="text"
        />
        <Gap v={10} />

        <RavenInputField
          color={"black-light"}
          value={modal.description}
          className="bugiss-block-modal"
          label="Details"
          showError={errors.description ? true : false}
          errorText={errors.reason_for_deduction}
          onChange={(e: any) =>
            setModal({
              ...modal,
              description: e.target.value,
            })
          }
          placeholder="Reason for Deduction"
          type="textarea"
        />
      </ActionModal>

      {/* Settlement Modal */}

      <ActionModal
        visible={modal?.type === "settlement"}
        onCancel={() => {
          setModal({ type: "" });
        }}
        onClick={() => {
          setModal({});
        }}
        hideCancel
        // cancelText="No, Cancel"
        actionText={`Close`}
        btnColor={colorToken}
        // body="Deduct specified amount from merchant balance"
        title={"Settlement Details"}
      >
        <p style={{ marginBottom: "2rem" }}>Settlement details of a Merchant</p>

        <Gap />
        <InlineDetails
          className="no-shadow no-padding"
          simple
          content={[
            {
              label: "Account Name",
              value: Util.safeText(data?.settlement_account_name),
            },
            {
              label: "Account No.",
              value: Util.safeText(data?.settlement_account_number),
            },
            {
              label: "Bank",
              value: Util.safeText(data?.settlement_bank_name),
            },
            {
              label: "Created at",
              value: formatDate(data?.created_at as Date),
            },
          ]}
        />
      </ActionModal>
    </DashboardLayout>
  );
};

const CustomManagerOption = ({
  innerProps,
  label,
  data,
}: {
  innerProps: any;
  label: string;
  data: HNIOperators;
}) => {
  return (
    <div
      style={{ cursor: "pointer" }}
      {...innerProps}
      className={cn(innerProps.isFocused && "active")}
    >
      <AvatarWithTwinValue
        name={Util.join(data.fname).with(data?.lname)}
        one={Util.join(data.fname).with(data?.lname)}
        two={Util.safeValue(data?.email)}
      />
      <Gap size={10} />
    </div>
  );
};
