import { TypeIs } from "@/utils/helpers";
import { PersonalBaseParams, PersonalBuilder, pbGetHandler } from "..";
import { searchPhysicalCards } from "./post.api";
import { PersonalAPIService } from "../..";

interface BaseParam extends PersonalBaseParams {
  email: string;
  card_id: string;
  status: SN;
  currency: string;
}

type CardParams = Partial<BaseParam>;

type CardResponse = CreatePersonalPaginatedResponse<
  "virtualCards",
  PersonalVirtualCardModel[]
>;

type GetTransactionParams = Partial<PersonalBaseParams> & {
  card_id: SN;
};

interface GetPhysicalCardsArgs extends CardParams {}

type Transactions = PersonalCardTransactions[];
type CardFunding = PersonalCardFunding[];

export const PersonalCardsAPI = (builder: PersonalBuilder) => {
  return {
    getVirtualCard: builder.query<CardResponse, CardParams>({
      queryFn: (params) => {
        const route = "user/virtual-cards";
        return pbGetHandler({ route, params: TypeIs.any(params) });
      },
    }),
    getVirtualCardDetails: builder.query<PersonalVirtualCardDetails, SN>({
      queryFn: (id) => {
        const route = `virtual-card/${id}`;
        return pbGetHandler({ route, params: {} });
      },
    }),
    getVirtualCardTransactions: builder.query<Transactions, GetTransactionParams>({
      queryFn: ({ card_id, ...params }) => {
        const route = `virtual-card/${card_id}/transactions`;
        return pbGetHandler({ route, params });
      },
    }),
    getVirtualCardFunding: builder.query<CardFunding, any>({
      queryFn: ({ card_id, ...params }) => {
        const route = `card-fundings`;
        return pbGetHandler({ route, params: { ...params, card_id } });
      },
    }),
    getPhysicalCards: builder.query<GetPhysicalCards, GetPhysicalCardsArgs>({
      queryFn: (params) => {
        if (params.search && !TypeIs.email(params.search)) {
          return searchPhysicalCards(params.search) as any;
        }

        const { search, ...rest } = params;

        return pbGetHandler({
          route: "physical_cards",
          params: { ...rest, email: search || params.email },
        });
      },
    }),
    getPhysicalCardsRequest: builder.query<GetCardRequest, GetPhysicalCardsArgs>({
      queryFn: (params) => {
        return pbGetHandler({ route: "physical_card_requests", params });
      },
    }),

    getPhysicalCardLiens: builder.query<GetCardLien, GetPhysicalCardsArgs>({
      queryFn: (params) => {
        return pbGetHandler({ route: "user-physical-card-liens", params });
      },
    }),
    getSinglePhysicalCard: builder.query<SinglePhysicalCard, string>({
      queryFn: async (id) => {
        return pbGetHandler({ route: `physical_card/${id}` });
      },
    }),
    getCardDeliveryHistory: builder.query({
      queryFn: PersonalAPIService.trackCardOrder,
    }),
  };
};
