import { configureStore, Store, AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { authSlice } from "./slices/auth/auth";
import generalReducer from "./general";
import themeReducer from "./theme";
import { businessStore } from "./slices/business/store";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { businessReduxAPI } from "./slices/business/api";
import { personalReduxAPI } from "./slices/personal/api";
import { posReduxAPI } from "./slices/pos/apis";
import { partnersReduxAPI } from "./slices/partners/apis";

const makeStore = () =>
  configureStore({
    reducer: {
      general: generalReducer,
      auth: authSlice.reducer,
      theme: themeReducer,
      ...businessStore,
      [businessReduxAPI.reducerPath]: businessReduxAPI.reducer,
      [personalReduxAPI.reducerPath]: personalReduxAPI.reducer,
      [posReduxAPI.reducerPath]: posReduxAPI.reducer,
      [partnersReduxAPI.reducerPath]: partnersReduxAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat(
        businessReduxAPI.middleware,
        personalReduxAPI.middleware,
        posReduxAPI.middleware,
        partnersReduxAPI.middleware
      ),
  });

const store = makeStore();

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
