import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Column, Row } from "@/components/common/stacks";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import RequestCard from "../components/requestCard";
import EmptyScreen from "@/components/common/emptyScreen";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";

export const PosInsightsTransfers = () => {
  const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
    module: INSIGHT_TYPES.DEVICE_REQUEST,
  });

  console.log(data);

  const uMetrics: InsightsDeviceRequest = data?.overview as any;
  type Metric = {
    title: string;
    value: number;
    count: number;
    type: "success" | "pending" | "failed";
  };

  const combinedLoading = isFetching || isLoading;

  function formatMetrics(metrics: StatusMetrics | undefined): Metric[] {
    if (!metrics) return [];

    return [
      {
        title: "Successful Transfers",
        value: metrics.successPayment ?? 0,
        count: metrics.successPaymentCount ?? 0,
        type: "success",
      },
      {
        title: "Pending Transfers",
        value: metrics.pendingPayment ?? 0,
        count: metrics.pendingPaymentCount ?? 0,
        type: "pending",
      },
      {
        title: "Failed Transfers",
        value: metrics.failedPayment ?? 0,
        count: metrics.failedPaymentCount ?? 0,
        type: "failed",
      },
    ];
  }

  const topCardData = [
    {
      title: "Total Volume Transferred",
      count: uMetrics?.allRequests,
    },
    {
      title: "Total Transfer Cost",
      count: uMetrics?.approvedRequests,
    },
    {
      title: "Transfer PNL",
      count: uMetrics?.dispatchedDevices,
    },
  ];
  return (
    <div style={{ height: "100%" }} className="pos-insights-tabs">
      {combinedLoading ? (
        <EmptyScreen loading={combinedLoading} />
      ) : (
        <Column>
          <Row ai="center" style={{ justifyContent: "space-between" }}>
            {topCardData?.map((d, i) => (
              <RequestCard {...d} />
            ))}
          </Row>
          {/* <PosInsightsSideBar metrics={formatMetrics(data?.overview?.statusMetrics)} /> */}
          {/* <CategoryMetricsCard
          title="POS DEPOSIT METRICS"
          data={[
            { label: "Total Charges", value: uMetrics?.approvedRequests },
            { label: "Total Volume", value: uMetrics?.approvedRequests },
            { label: "Total PNL", value: uMetrics?.approvedRequests },
          ]}
        /> */}

          <Row>
            <PosInsightsSideBar
              volumeNumberOptions={{
                numberAbbreviate: true,
                hideSymbol: false,
                hideDecimal: false,
              }}
              metrics={formatMetrics(data?.overview?.statusMetrics)}
            />

            <MetricsGraph
              data={uMetrics?.requestsPerMonth as any}
              count={`Yearly Device Request`}
              totalVolume={uMetrics?.yearlyRequests ?? 0}
              volumeNumberOptions={{
                hideDecimal: true,
                hideSymbol: true,
              }}
              title="Volume Metrics"
              containerHeight="33rem"
              graphStyle={{
                width: 100,
                height: 100,
              }}
              maintainAspectRatio={false}
              // countNumberOptions={{ hideDecimal: true, hideSymbol: true }}
            />
          </Row>
        </Column>
      )}
    </div>
  );
};
