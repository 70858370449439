import React, { useEffect, useState } from "react";
import { RavenTableRow } from "@ravenpay/raven-bank-ui";
import {
  formatDate,
  formatDateTime,
  formatMetaData,
  formatStatus,
  trimLongString,
} from "@/utils/helper/Helper";
import { BillerStack, XStack } from "@/components/common/stacks";

import Copy, { CopyText } from "@/components/common/copyCheck";
import TwinValue from "@/components/common/twinValue";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { useSelector } from "react-redux";
import Redacted from "@/components/common/shimmer/redact";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { autoBillersLogo } from "@/constants/auto-billers-logo";
import ActionButtons from "../__common__/action_buttons";

interface Defaults {
  merchant_name: string;
  merchant_email: string;
  account_no: string;
  account_name: string;
  type: string;
  amount: SN;
  date: string | Date;
  status: string;
  bank: string;
}
export interface SettlementDataProps extends Defaults {}

export interface AirtimeDataProps extends Defaults {
  phoneNumber: string;
  reference: string;
}

export class BillsTable {
  static AirtimeData = (props: ReversalCenterAirtime & { isRedacting: boolean }) => {
    const {
      amount,
      email,
      reference,
      isRedacting,
      transaction_date,
      provider,
      onAction,
      phoneNumber,
      action,
    } = props;

    const biller = autoBillersLogo(Util.safeValue(provider?.toLowerCase()));

    return (
      <RavenTableRow
        // one={
        //   isRedacting ? (
        //     <Redacted.Labelled />
        //   ) : (
        //     <AvatarWithTwinValue
        //       one={
        //         <CopyText text={trimLongString(email, 20) as string} textToCopy={email} />
        //       }
        //       two={`TID: ${Util.safeValue(reference)}`}
        //       name={Util.safeValue(email)}
        //     />
        //   )
        // }
        one={Util.safeValue(email)}
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText
              text={trimLongString(reference, 20) as string}
              textToCopy={reference}
            />
          )
        }
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        three={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <AvatarWithTwinValue
              imgSrc={biller as string}
              two={provider}
              fallbackSrc={
                "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              }
              one={Util.safeText(phoneNumber)}
              name={provider}
            />
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDate(transaction_date)}
        six={isRedacting ? <Redacted.Table /> : formatDateTime(transaction_date)}
        seven={
          isRedacting ? (
            <Redacted.Badge />
          ) : (
            <ActionButtons
              action={action}
              onRefund={function (): void {
                onAction({ ctx: "refund", id: props?.reference });
              }}
              onDiscard={function (): void {
                onAction({ ctx: "discard", id: props?.reference });
              }}
            />
          )
        }
      />
    );
  };
  static Electricity = (props: ReversalCenterAirtime & { isRedacting: boolean }) => {
    const {
      amount,
      email,
      isRedacting,
      reference,
      meter_number,
      onAction,
      transaction_date,
      action,
      provider,
    } = props;

    const biller = autoBillersLogo(Util.safeValue(provider?.toLowerCase()));
    return (
      <RavenTableRow
        one={Util.safeValue(email)}
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText
              text={trimLongString(reference, 20) as string}
              textToCopy={reference}
            />
          )
        }
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        three={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <AvatarWithTwinValue
              imgSrc={biller as string}
              two={provider}
              fallbackSrc={
                "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              }
              one={Util.safeText(meter_number)}
              name={provider}
            />
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDate(transaction_date)}
        six={isRedacting ? <Redacted.Table /> : formatDateTime(transaction_date)}
        seven={
          isRedacting ? (
            <Redacted.Badge />
          ) : (
            <ActionButtons
              action={action}
              onRefund={function (): void {
                onAction({ ctx: "refund", id: props?.reference });
              }}
              onDiscard={function (): void {
                onAction({ ctx: "discard", id: props?.reference });
              }}
            />
          )
        }
      />
    );
  };
  static Cable = (props: ReversalCenterAirtime & { isRedacting: boolean }) => {
    const {
      amount,
      email,
      isRedacting,
      action,
      smart_card_number,
      reference,
      transaction_date,
      provider,
      onAction,
    } = props;

    const biller = autoBillersLogo(provider);

    return (
      <RavenTableRow
        one={Util.safeValue(email)}
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText
              text={trimLongString(reference, 20) as string}
              textToCopy={reference}
            />
          )
        }
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        three={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <AvatarWithTwinValue
              imgSrc={biller as string}
              two={provider}
              fallbackSrc={
                "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              }
              one={Util.safeText(smart_card_number)}
              name={provider}
            />
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDate(transaction_date)}
        six={isRedacting ? <Redacted.Table /> : formatDateTime(transaction_date)}
        seven={
          isRedacting ? (
            <Redacted.Badge />
          ) : (
            <ActionButtons
              action={action}
              onRefund={function (): void {
                onAction({ ctx: "refund", id: props?.reference });
              }}
              onDiscard={function (): void {
                onAction({ ctx: "discard", id: props?.reference });
              }}
            />
          )
        }
      />
    );
  };
  static Betting = (
    props: ReversalCenterAirtime & { isRedacting: boolean; loading: boolean }
  ) => {
    const {
      amount,
      email,
      reference,
      isRedacting,
      phoneNumber,
      transaction_date,
      provider,
      onAction,
      action,
    } = props;

    const biller = autoBillersLogo(Util.safeValue(provider?.toLowerCase()));

    return (
      <RavenTableRow
        one={Util.safeValue(email)}
        two={
          isRedacting ? (
            <Redacted.Table />
          ) : (
            <CopyText
              text={trimLongString(reference, 20) as string}
              textToCopy={reference}
            />
          )
        }
        four={
          isRedacting ? <Redacted.Table /> : formatNumberToCurrency(Number(amount), "NGN")
        }
        three={
          isRedacting ? (
            <Redacted.AvatarWithValue />
          ) : (
            <AvatarWithTwinValue
              imgSrc={biller as string}
              two={provider}
              fallbackSrc={
                "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
              }
              one={Util.safeText(phoneNumber)}
              name={provider}
            />
          )
        }
        five={isRedacting ? <Redacted.Table /> : formatDate(transaction_date)}
        six={isRedacting ? <Redacted.Table /> : formatDateTime(transaction_date)}
        seven={
          isRedacting ? (
            <Redacted.Badge />
          ) : (
            <ActionButtons
              action={action}
              onRefund={function (): void {
                onAction({ ctx: "refund", id: props?.reference });
              }}
              onDiscard={function (): void {
                onAction({ ctx: "discard", id: props?.reference });
              }}
            />
          )
        }
      />
    );
  };
}
export const ReversalTransfersTable = (props: PosTransferReversal) => {
  const {
    account_name,
    email,
    reference,
    account_number,
    bank,
    onAction,
    amount,
    created_at,
    action,
  } = props;
  const { isRedacting } = useSelector((state: RootState) => state.general);

  return (
    <RavenTableRow
      one={
        isRedacting ? (
          <Redacted.Table />
        ) : (
          <CopyText text={Util.safeValue(email)} textToCopy={Util.safeValue(email)} />
        )
      }
      two={
        isRedacting ? (
          <Redacted.Table />
        ) : (
          <CopyText
            text={Util.safeValue(reference)}
            textToCopy={Util.safeValue(reference)}
          />
        )
      }
      three={
        isRedacting ? (
          <Redacted.Labelled />
        ) : (
          <TwinValue
            one={Util.safeValue(account_name)}
            two={`${Util.safeValue(account_number)} • ${Util.safeValue(bank)} `}
          />
        )
      }
      four={isRedacting ? <Redacted.Table /> : amount}
      five={isRedacting ? <Redacted.Table /> : formatDateTime(created_at as Date)}
      six={
        isRedacting ? (
          <Redacted.Table />
        ) : (
          <ActionButtons
            action={action}
            onRefund={function (): void {
              onAction({ ctx: "refund", id: props?.reference });
            }}
            onDiscard={function (): void {
              onAction({ ctx: "discard", id: props?.reference });
            }}
          />
        )
      }
    />
  );
};
