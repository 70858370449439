import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Avatar from "@/components/common/avatar";

const PartnersCustomers = () => {
  const { isDarkMode } = useThemeMode();

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Customers">
        <SmartFilter
          pagination={{
            currentPage: 0,
            itemsPerPage: 0,
            totalItems: 0,
            onPageChange: function (page: number): void {
              throw new Error("Function not implemented.");
            },
            activeFilter: "All Transactions",
          }}
          searchTitle="Search Users by username..."
        />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          <RavenTable
            action={false}
            className="table__main"
            headerList={[
              "MERCHANT DETAILS",
              "CUSTOMER NAME",
              "CUSTOMER EMAIL",
              "MOBILE NUMBER",
              "DATE REGISTERED",
            ]}
          >
            {[0, 1, 2, 3].map((n) => (
              <RavenTableRow
                key={n}
                one={
                  <TwinValue one="Nathaniel & Sons LTD" two="emmy4sure.web@gmail.com" />
                }
                two={<Avatar name="Dayo Aderamo" full />}
                three="kenzi.lawson@example.com"
                four="08121987655"
                five="5 Sept, 2022 - 5:48PM"
              />
            ))}
          </RavenTable>
        </div>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PartnersCustomers;
