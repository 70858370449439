import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import { usePagination } from "@/hooks/usePagination";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { formatDateTime } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { useSingleUserState } from "../../context";
import { GLOBAL_PER_PAGE, TRANSFER_EMPTY_SCREEN } from "@/constants";
import { useNavigate } from "react-router-dom";
import { usePbPocketExport } from "@/apps/personal/__internals__/internal-hooks/savings";
import { ConditionalCopyText } from "@/components/common/copyCheck";

export const Pockets = () => {
  const pagination = usePagination();
  const { email } = useSingleUserState();
  const { ExportModal, onExport } = usePbPocketExport(email);
  const { data, isFetching, isLoading } = personalReduxAPI.useGetPocketsQuery({
    page: pagination.currentPage,
    per_page: GLOBAL_PER_PAGE,
    end_date: pagination.filterValue.endDate,
    start_date: pagination.filterValue.startDate,
    email,
  });

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable } = renderRedactedComponent(joinedLoader);
  const { SmartFilterProps } = useSmartFilterProps(
    mapTransfersPagination(data),
    pagination
  );

  const navigate = useNavigate();

  const handleClick = (row: PersonalPocketData) => {
    navigate(`/personal-savings/pocket/${row.id}`);
  };

  if (isLoading || !data)
    return (
      <div className="mt-20">
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      </div>
    );

  const filteredSavings = data.pockets.filter((record) => {
    if (!pagination.searchQuery) return true;
    const search = pagination.searchQuery ?? "";
    return Util.search(record.name, search) || Util.search(record.reference, search);
  });

  return (
    <div className="mt-20">
      <SmartFilter
        searchTitle="Search by reference or name"
        {...SmartFilterProps}
        pagination={{
          ...SmartFilterProps.pagination,
          currentPage: pagination.searchQuery ? 1 : pagination.currentPage,
          totalItems: pagination.searchQuery
            ? filteredSavings.length
            : SmartFilterProps.pagination.totalItems,
        }}
        onExport={onExport}
      />
      {filteredSavings.length > 0 ? (
        <RavenTable
          className="table__main"
          headerList={["REFERENCE", "TITLE", "BALANCE", "DATE CREATED"]}
        >
          {filteredSavings.map((row) => {
            const { id, balance, reference, created_at, name } = row;

            return (
              <RavenTableRow
                key={id}
                two={RedactedTable(<ConditionalCopyText text={reference} />)}
                four={RedactedTable(Util.safeText(formatNumberToCurrency(balance)))}
                three={RedactedTable(<ConditionalCopyText text={name} />)}
                five={RedactedTable(
                  Util.safeText(created_at ? formatDateTime(created_at) : "--")
                )}
                onRowClick={() => handleClick(row)}
              />
            );
          })}
        </RavenTable>
      ) : (
        <TransferEmptyScreen {...TRANSFER_EMPTY_SCREEN} loading={joinedLoader} />
      )}
      <ExportModal />
    </div>
  );
};
