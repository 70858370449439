import env from "@/env";
import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const AllPartnersApi = (builder: PartnersBuilder) => {
  return {
    getPartners: createQueryWithRoute<
      PartnersTerminalsModel,
      Partial<PartnersPaginationParams>
    >(builder, "partner", { name: "getPartners" }),

    getPartnersExport: createQueryWithRoute<
      PosTerminalsModel,
      Partial<PosPaginationParams>
    >(builder, "partner", { export: true, name: "getPartnersExport" }),

    getAPartner: builder.query<PartnersDetails, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          { route: `partner/details?email=${params?.email}` },
          thunk
        );
      },
    }),

    getMerchants: createQueryWithRoute<
      PartnersMerchantsModel,
      Partial<PartnersPaginationParams>
    >(builder, "users", { name: "getMerchants" }),

    getMerchantsExport: createQueryWithRoute<
      PartnersMerchantsModel,
      Partial<PosPaginationParams>
    >(builder, "users", { export: true, name: "getMerchantsExport" }),

    getAMerchant: builder.query<PartnersMerchants, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `users/${params.id}`, params }, thunk);
      },
    }),

    getTransactions: createQueryWithRoute<
      PartnersTransactionsModel,
      Partial<PartnersPaginationParams>
    >(builder, "transactions", { name: "getTransactions" }),

    getTerminalRequests: createQueryWithRoute<PosDeviceRequestsModel, any>(
      builder,
      "terminal/requests",
      { name: "getTerminalRequests" }
    ),

    getTerminalRequestsExport: createQueryWithRoute<PosDeviceRequestsModel, any>(
      builder,
      "terminal/requests",
      {
        export: true,
        name: "getTerminalRequestsExport",
      }
    ),

    getAlertRecievers: builder.query<PosAlertRecievers[], any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `terminal/alert/detail/`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
