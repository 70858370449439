import { icons } from "@/assets/icons";
import React, { ReactElement, useEffect, useState } from "react";
import "./styles/index.css";
import InlineDetails from "../inlineDetails";
import { RavenButton } from "@ravenpay/raven-bank-ui";
import { Util } from "@/utils/utility";
import {
  capitalizeFirstLetter,
  formatDateTime,
  formatStatus,
} from "@/utils/helper/Helper";
import ImagePreview from "../imagePreview";
import { detectStatus } from "@/utils/helpers";
import TabComponent from "../Tabs";
import { Row } from "@/components/common/stacks";


type CompliancCardProp = {
  type: {
    label: string;
    value: string;
  };
  business_details: Partial<IXBusiness>;
  central_biz: Partial<BusinessInfoOnCentral>;
  app: string;
  business_email?: string;
  splitCac?:boolean // this removes shareholder view from cac tab
  director_details?: DirectorDetails;
  shareholders_details?: any;
  referee_details?: any;
  veriDetail: Partial<VerificationDetails>;
};
const ComplianceCards = ({ data, onRevoke }: { data: CompliancCardProp,
  onRevoke?: () => void;
}) => {
  const [selectedDirectorTab, setSelectedDirectorTab] = useState<any>("CAC Details");
  const [tabIndex, setTabIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const { type, business_details, central_biz, app, veriDetail, director_details, splitCac, shareholders_details, referee_details } = data;

// console.log(data, 'the data')
  return (
    <div className="compliance-card">
      {/* <h6 className="compliance-card__title"></h6> */}
      {!veriDetail ? (
        <div className="compliance-card__empty">
          <div className="compliance-card__inner-title">
            <p className="">{type?.label}</p>
          </div>

          <div className="compliance-card__empty-content-wrap">
            <figure className="">{icons.compliance_image_placeholder}</figure>
            <div className="empty-content-wrap__item">
              <h6>{`${type?.label} hasn’t been Verified`}</h6>
              <p>{` This user is yet to verify their ${type.label} on the ${app} Platform`}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="compliance-main-content">
          <div className="compliance-main-content__main-content-wrap">
            <div className="compliance-main-content__inner-title">

              <Row ai={'center'} >
                <p className="">{type?.label}</p>
                {['shareholders', 'referees'].includes(type.value) && (
                  <div className="shareholders-pagination">
                    <figure
                      onClick={() => {
                        handlePrev(
                          type?.value === 'shareholders' ? shareholders_details[data.business_email as string]?.length : referee_details[data.business_email as string]?.length,
                          currentIndex,
                          setCurrentIndex
                        );
                      }}
                    >
                      {icons.arrow_forward_circular}
                    </figure>

                    <span>
                  <p>
                    {currentIndex === 0 ? 1 : currentIndex} of{" "}
                    {type?.value === 'shareholders' ? shareholders_details[data.business_email as string]?.length : referee_details[data.business_email as string]?.length}{" "}
                    {type.label}
                  </p>
                </span>

                    <figure
                      onClick={() => {
                        handleNext(
                          type?.value === 'shareholders' ? shareholders_details[data.business_email as string]?.length : referee_details[data.business_email as string]?.length,
                          currentIndex,
                          setCurrentIndex
                        );
                      }}
                    >
                      {icons.arrow_forward_circular}
                    </figure>
                  </div>
                )}
              </Row>

              {onRevoke && <RavenButton
                onClick={onRevoke}
                className="revoke-btn"
                color="error-light"
                label={`Revoke ${type.label.split(" ")[0]} Verification`}
              /> }
            </div>

            {!splitCac && type.value === "cac" && (
              <div style={{ marginTop: "2rem" }}>
                <TabComponent
                  onTabValueChange={(e: any) => {
                    if (e === "Director Details" && tabIndex === 0) {
                      setTabIndex(1);
                    }
                    setSelectedDirectorTab(e);
                  }}
                  tabs={renderCacTab(
                    business_details as unknown as any,
                    director_details
                  )}
                  defaultValue={
                    renderCacTab(business_details as unknown as any, director_details)[0]
                  }
                >
                  <TabComponent.Tabs
                    tabs={renderCacTab(
                      business_details as unknown as any,
                      director_details
                    )}
                  />
                </TabComponent>
              </div>
            )}

            {selectedDirectorTab === "Director Details" && (
              <div className="cac-details__pagination">
                <figure
                  onClick={() => {
                    handleDirectorsPrev(
                      veriDetail,
                      director_details as DirectorDetails,
                      selectedDirectorTab,
                      tabIndex,
                      business_details,
                      setTabIndex
                    );
                  }}
                >
                  {icons.arrow_forward_circular}
                </figure>

                <span>
                  <p>
                    {tabIndex} of{" "}
                    {
                      assertDirectorList(
                        business_details as unknown as any,
                        director_details
                      )?.length
                    }{" "}
                    {" Directors "}
                  </p>
                </span>

                <figure
                  onClick={() => {
                    handleDirectorsNext(
                      veriDetail,
                      director_details as DirectorDetails,
                      business_details,
                      selectedDirectorTab,
                      tabIndex,
                      setTabIndex
                    );
                  }}
                >
                  {icons.arrow_forward_circular}
                </figure>
              </div>
            )}


            <div className="compliance-main-content__table">
              {type.value === "address"
                ? renderAddressImage({
                    business_details_from_central: central_biz as any,
                  })
                : type.value === "cac"
                ? renderCacImage({
                    business_details_from_central: central_biz as any,
                  })
                : type.value !== "business_info" && (
                    <ImagePreview
                      imgData={[
                        {
                          src: `${
                            veriDetail.response?.verification?.verification_image ??
                            veriDetail?.verification_data?.photo
                          }`,
                          alt: "Image 1",
                          containerClassName: "biz-compliance-results__image-preview", // Optional custom container class
                        },
                        {
                          src: `${
                            veriDetail.response?.verification?.user_image ??
                            veriDetail?.verification_data?.photo
                          }`,
                          alt: "Image 2",
                          containerClassName: "biz-compliance-results__image-preview", // Optional custom container class
                        },
                      ]}
                    />
                  )}

              <InlineDetails
                direction={""}
                title={""}
                className="compliance-card__content-table no-shadow"
                amount={0}
                simple
                onClick={() => {}}
                content={
                  type.value === "business_info"
                    ? businessInfoComplianceData({
                        business: business_details as IXBusiness,
                        business_info_on_central: central_biz as BusinessInfoOnCentral,
                      })
                    : type.value === "address"
                    ? addressComplianceData({
                        business: business_details as IXBusiness,
                        business_info_on_central: central_biz as BusinessInfoOnCentral,
                      })
                    : type.value === "cac"
                    ? cacComplianceData({
                        biz: {
                          business: business_details as IXBusiness,
                          business_info_on_central: central_biz as BusinessInfoOnCentral,
                        },
                        selectedTab: selectedDirectorTab,
                        activeIndex: tabIndex,
                        directors: assertDirectorList(
                          business_details as unknown as any,
                          director_details
                        ) as unknown as SingleDirector[],
                      })
                    : type.value === 'shareholders' ? shareholdersComplianceData(shareholders_details[data?.business_email as string] && shareholders_details[data?.business_email as string]?.length > 0 ? shareholders_details[data?.business_email as string][currentIndex === 0 ? currentIndex : currentIndex-1]  : []): type.value === 'referees' ? shareholdersComplianceData(referee_details[data?.business_email as string] && referee_details[data?.business_email as string]?.length > 0 ? referee_details[data?.business_email as string][currentIndex === 0 ? currentIndex : currentIndex-1]  : []): generalComplianceData(type, veriDetail)
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComplianceCards;

function handleDirectorsPrev(
  verification_data: Partial<VerificationDetails>,
  director_details: DirectorDetails,
  selectedDirectorTab: any,
  tabIndex: number,
  business: any,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {
  const rectors = assertDirectorList(business, director_details);

  if (selectedDirectorTab.includes("director") && tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === 1) {
      setTabIndex(rectors?.length);
    } else {
      setTabIndex(tabIndex - 1);
    }
  }
}

function handlePrev(
  length: number,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {
  if ( tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === 1) {
      setTabIndex(length);
    } else {
      setTabIndex(tabIndex - 1);
    }
  }
}

function handleNext(
  length: number,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {


  if ( tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === length) {
      setTabIndex(1);
    } else {
      setTabIndex(tabIndex + 1);
    }
  }
}
function handleDirectorsNext(
  veriDetail: Partial<VerificationDetails>,
  director_details: DirectorDetails,
  business: any,
  selectedDirectorTab: any,
  tabIndex: number,
  setTabIndex: React.Dispatch<React.SetStateAction<number>>
) {
  const rectors = assertDirectorList(business, director_details);

  if (selectedDirectorTab === "Director Details" && tabIndex === 0) {
    setTabIndex(1);
  } else {
    if (tabIndex === rectors?.length) {
      setTabIndex(1);
    } else {
      setTabIndex(tabIndex + 1);
    }
  }
}

function renderAddressImage(data: Partial<Business>): React.ReactNode {
  return (
    <ImagePreview
      imgData={[
        {
          src: data?.business_details_from_central?.address_image ?? "",
          alt: "image",
          isMap: false,
        },
        {
          src: renderGoogleMap(
            data?.business_details_from_central?.business_address ?? ""
          ) as any,
          alt: "business-address",
          isMap: true,
        },
      ]}
    />
  );
}

function renderCacImage(data: Partial<Business>): React.ReactNode {
  return (
    <ImagePreview
      imgData={[
        {
          src: Util.safeValue(data?.business_details_from_central?.cac_certificate),
          alt: "image",
        },
      ]}
    />
  );
}

function shareholdersComplianceData(data: SingleBusinessShareholder) {
  try {
    const parsedData = Object.entries(data);

    // Attempt to parse and check if it's an array
    if (Array.isArray(parsedData)) {
      // Map each object to { label, value } pairs
      return parsedData
        .filter(([key, value]) => value !== undefined && value !== null && value !== "" && key !== 'id' && key !== 'updated_at') // Filter out empty, null, or undefined values, and skip 'id' and 'updated_at'
        .map(([key, value]) => {
          if (key === 'created_at') {
            value = formatDateTime(value); // Format created_at
          }

          // if(key === 'bvn_verified'){
          //   value = formatStatus(detectStatus(Number(value)))
          // }
          //
          // if(key === 'nin_verified'){
          //   value = formatStatus(detectStatus(Number(value)))
          // }
          return {
            label: Util.cleanText(key) || "", // Default label to an empty string if key is undefined
            value: value || "" // Default value to an empty string if value is undefined
          };
        })
        .filter(item => item.label && item.value); // Ensure both label and value are present
    } else {
      console.warn("Shareholder data is not an array");
      return []; // Return empty array if parsed data is not an array
    }
  } catch (error) {
    console.error("Error parsing meta:", error);
    return []; // Return empty array if JSON parsing fails
  }
}
function generalComplianceData(
  selectedTab: { label: string; value: string },
  data: Partial<VerificationDetails>
): {
  label: string;
  value: string;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  return [
    {
      label: Util.join(selectedTab.value.toUpperCase()).with("Number"),
      value: Util.safeValue(
        detectIdNumber(selectedTab.value, {
          ...data.response?.verification,
          ...data.verification_data,
        })
      ),
      copy: true,
    },
    {
      label: "Fullname",
      value: Util.safeValue(
        Util.join(Util.safeValue(data.verification_data?.firstName)).with(
          Util.safeValue(data.verification_data?.lastName)
        )
      ),
    },

    {
      label: "Mobile Number",
      value: Util.safeValue(detectPhoneNo(selectedTab.value, data)),
    },

    {
      label: "Address",
      value: Util.join(
        Util.safeValue(capitalizeFirstLetter(data?.verification_data?.residentialAddress))
      ).with(
        capitalizeFirstLetter(
          `${Util.safeValue(
            data?.verification_data?.lga_of_residence
          )} , ${Util.safeValue(data?.verification_data?.state_of_residence)} `
        )
      ),
    },

    {
      label: "Date of Birth",
      value:
        formatDateTime(data?.verification_data?.dateOfBirth ?? "") === "Invalid Date"
          ? Util.safeValue(data?.verification_data?.dateOfBirth as any)
          : formatDateTime(data?.verification_data?.dateOfBirth ?? ""),
    },

    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data.created_at ?? "")),
    },
  ];
}


function addressComplianceData(biz: Partial<XBusinessesModel>): {
  label: string;
  value: string | ReactElement;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  const data = {
    ...biz.business,
    business_details_from_central: biz.business_info_on_central,
  };

  return [
    {
      label: "Business",
      value: Util.safeValue(data.business_name),
    },

    {
      label: "Email Address",
      value: Util.safeValue(data.business_owner_email),
    },

    {
      label: "LGA",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.lga)
      ),
    },

    {
      label: "Status",
      value: formatStatus(
        detectStatus(Number(data?.address), "compliance")
      ) as ReactElement,
    },

    {
      label: "Full Address",
      copy: true,
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.business_address)
      ),
    },
    {
      label: "Address URL",
      copy: true,
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.address_report_url)
      ),
    },

    {
      label: "Document URL",
      copy: true,
      trim: true,
      value: data?.business_details_from_central?.address_image as unknown as string,
    },

    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data.created_at ?? "")),
    },
  ];
}

function cacComplianceData({
  biz,
  selectedTab,
  directors,
  activeIndex,
}: {
  biz: Partial<XBusinessesModel>;
  selectedTab: string;
  directors: SingleDirector[];
  activeIndex?: SN;
}): {
  label: string;
  activeIndex?: SN;
  value: string | ReactElement;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  const data = {
    ...biz.business,
    business_details_from_central: biz.business_info_on_central,
  };
  const director = directors && directors[Number(activeIndex) - 1 ];

  if (selectedTab.includes("CAC")) {
    return [
      {
        label: "Business Name",
        value: Util.safeValue(data.business_name),
      },

      {
        label: "Business Email",
        value: Util.safeValue(data.business_owner_email),
        copy: true,
        trim: true,
      },

      {
        label: "Business Type",
        value: Util.safeValue(
          capitalizeFirstLetter(data?.business_details_from_central?.business_type)
        ),
      },

      {
        label: "RC Number",
        value: Util.safeValue(data?.business_details_from_central?.registration_number),
      },

      {
        label: "Date Authorized",
        value: Util.safeValue(formatDateTime(data.created_at ?? "")),
      },
    ];
  } else {
    return [
      {
        label: "Full Name",
        value: Util.join(Util.safeValue(director?.fname)).with(
          Util.safeValue(director?.lname)
        ),
      },

      {
        label: "Directors Email",
        value: Util.safeValue(director?.email),
      },

      {
        label: "Mobile Number",
        value: Util.safeValue(capitalizeFirstLetter(director?.phone_number)),
      },

      {
        label: "BVN",
        value: Util.safeValue(capitalizeFirstLetter(director?.bvn)),
      },
      {
        label: "NIN",
        value: Util.safeValue(capitalizeFirstLetter(director?.nin)),
      },

      {
        label: "Verification Status",
        value: formatStatus(detectStatus(Number(director?.bvn_verified))) as ReactElement,
      },
      {
        label: "Full Address",
        value: Util.safeValue(director?.address),
      },
    ];
  }
}

function businessInfoComplianceData(biz: XBusinessesModel): {
  label: string;
  value: string | ReactElement;
  copy?: boolean | undefined;
  trim?: boolean | undefined;
}[] {
  const data = {
    ...biz.business,
    business_details_from_central: biz.business_info_on_central,
  };

  return [
    {
      label: "Business",
      value: Util.safeValue(capitalizeFirstLetter(data.business_name)),
    },

    {
      label: "Email Address",
      value: Util.safeValue(
        data?.business_details_from_central?.business_email ?? data?.email
      ),
    },

    {
      label: "Industry",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.industry)
      ),
    },

    {
      label: "Staff",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.employee_size)
      ),
    },

    {
      label: "Transaction Range",
      value: Util.safeValue(
        capitalizeFirstLetter(data?.business_details_from_central?.transaction_range)
      ),
    },
    // {
    //   label: "Status",
    //   value: formatStatus(
    //     Number(data?.business_verified) === 0 ? "unverified" : "verified"
    //   ) as ReactElement,
    // },
    {
      label: "Date Authorized",
      value: Util.safeValue(formatDateTime(data?.created_at ?? "")),
    },
  ];
}

function renderGoogleMap(address: string) {
  return (
    <iframe
      id="map-canvas"
      frameBorder="0"
      className="map_part"
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyALt4gqjbZPw3ISNJs4CBjIzpMfeBBkPbI&q=${address}`}
    />
  );
}

function detectIdNumber(type: string, data: any) {
  if (type === "bvn") {
    return (data && data?.bvn_number) ?? data.bvn;
  } else if (type === "nin" || type === "document") {
    return data && data?.document_number;
  }
}

function detectPhoneNo(type: string, data: any) {
  if (data?.response?.verification?.verification_data?.phone?.length > 3) {
    return data?.response?.verification?.verification_data?.phone;
  } else {
    return data && data?.phone?.length > 5
      ? data?.phone
      : data?.verification_data?.phone
      ? data?.verification_data?.phone
      : data?.phone_number;
  }
}

function renderCacTab(data: Partial<Business>, compliance: any): string[] {
  try {
    var director_details: any = compliance[data?.business_owner_email as any] ?? [];

    let directorMap = [];
    directorMap = director_details?.map((d: any, idx: string) => {
      return `Director ${idx + 1}`;
    });

    if (directorMap?.length > 0) {
      return ["CAC Details", "Director Details"];
    } else {
      return ["CAC Details"];
    }
  } catch (error) {
    return [];
  }
}

function assertDirectorList(data: Partial<Business>, compliance: any): string[] {
  try {
    var director_details: any = compliance[data?.business_owner_email as any] ?? [];

    return director_details;
  } catch (error) {
    return [];
  }
}
