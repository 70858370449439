import MetricsGraph from "@/components/common/insights/metrics-graph";
import { Row } from "@/components/common/stacks";
import CategoryMetricsCard from "@/components/common/insights/category-metrics";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { INSIGHT_TYPES } from "../__internal__/constants";
import PosInsightsSideBar from "../components/insightsSidebar";
import EmptyScreen from "@/components/common/emptyScreen";

export const PosInsightsDeposits = () => {
  const { data, isLoading, isFetching } = posReduxAPI.useGetInsightQuery({
    module: INSIGHT_TYPES.DEPOSIT,
  });

  const uMetrics: InsightsDeposits = data?.overview as any;
  const combinedLoading = isFetching || isLoading;

  return (
    <div className="pos-insights-tabs">
      {combinedLoading ? (
        <EmptyScreen loading={combinedLoading} />
      ) : (
        <Row>
          {/* <PosInsightsSideBar metrics={formatMetrics(data?.overview?.statusMetrics)} /> */}
          <CategoryMetricsCard
            title="POS DEPOSIT METRICS"
            data={[
              { label: "Total Charges", value: uMetrics?.totalCharges },
              { label: "Total Volume", value: uMetrics?.totalVol },
              { label: "Total PNL", value: uMetrics?.totalPNL },
            ]}
          />
          <MetricsGraph
            data={uMetrics?.monthlyDepositsData as any}
            count={Number(uMetrics?.totalCount)}
            totalVolume={uMetrics?.totalVol ?? 0}
            title="Deposit Metrics"
            // countNumberOptions={{ hideDecimal: true, hideSymbol: true }}
          />
        </Row>
      )}
    </div>
  );
};
