import React, { useEffect, useState } from "react";
import "../styles/index.css";
import ModalLayout from "../modal_layout";
import { Column, Row } from "@/components/common/stacks";
import { RavenButton, RavenInputField, toast } from "@ravenpay/raven-bank-ui";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import { useProductColor } from "@/context/ProductTheme";
import { cn } from "@/utils/colorConvert";

const RefireNotification = ({
  onSuccess,
  data,
}: {
  onSuccess: () => void;
  data: NotificationsPersonal;
}) => {
  const [formData, setFormData] = useState<{ title: any; topic: any; message: any }>({
    title: undefined,
    topic: undefined,
    message: undefined,
  });
  const [reqn, setIsReqn] = useState(false);
  const [step, setStep] = useState(0);

  const [notificationDelivery, setNotificationDelivery] = useState({
    type: "instant",
    date: new Date(),
    time: "0:00pm",
  });
  function handleForm(e: any) {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      message: data.message,
      title: data.title,
      topic: data.topic,
    }));
  }, [data]);
  async function handleSendPushNotification() {
    setIsReqn(true);

    try {
      // Prepare formData based on the notificationDelivery type
      const additionalData =
        notificationDelivery.type === "scheduled"
          ? {
              scheduled_time: `${formatDateToYYYYMMDD(
                new Date(notificationDelivery.date)
              )} ${notificationDelivery.time}`,
            }
          : {};

      // Send the request with the merged data
      const resp = await sendRequest(
        `/support/create-notification`,
        { ...formData, ...additionalData },
        { base_url: env.personal_api }
      );

      // Handle success
      if (resp?.status === "success") {
        setFormData({
          title: undefined,
          topic: undefined,
          message: undefined,
        });
        onSuccess();
        setStep(0);
        toast.success(resp?.message);
      } else {
        // Handle failure
        toast.error(resp?.message || "An error occurred");
      }
    } catch (error) {
      // Handle any unexpected errors
      toast.error("Failed to send notification");
    } finally {
      // Always reset the request status flag
      setIsReqn(false);
    }
  }

  const { colorVariable } = useProductColor();

  const radio = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <circle cx="10" cy="10" r="9" stroke="#DBD6FC" stroke-width="0.8" />
    </svg>
  );

  const radioChecked = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <rect x="2" y="2" width="18" height="18" rx="9" fill="#DBD6FC" />
      <circle cx="11" cy="11" r="9" stroke="#755AE2" stroke-width="4" />
    </svg>
  );

  const notification = (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#F0EEFF" />
      <path
        d="M29.5999 11.6004C29.5999 11.1845 29.3846 10.7983 29.0308 10.5796C28.677 10.361 28.2352 10.3411 27.8632 10.5271L18.5166 15.2004H13.9999C12.0117 15.2004 10.3999 16.8122 10.3999 18.8004C10.3999 20.7886 12.0117 22.4004 13.9999 22.4004H14.335L16.4615 28.7799C16.6248 29.2699 17.0834 29.6004 17.5999 29.6004H18.7999C19.4627 29.6004 19.9999 29.0631 19.9999 28.4004V23.142L27.8632 27.0737C28.2352 27.2597 28.677 27.2398 29.0308 27.0212C29.3846 26.8025 29.5999 26.4163 29.5999 26.0004V11.6004Z"
        fill="#B4A4FC"
      />
    </svg>
  );

  const dateIcon = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#F0EEFF" />
      <path
        d="M12 7.11122H4M9.77778 3.55566V5.33344M6.22222 3.55566V5.33344M6.13333 12.4446H9.86667C10.6134 12.4446 10.9868 12.4446 11.272 12.2992C11.5229 12.1714 11.7268 11.9674 11.8547 11.7165C12 11.4313 12 11.058 12 10.3112V6.57789C12 5.83115 12 5.45778 11.8547 5.17257C11.7268 4.92168 11.5229 4.71771 11.272 4.58988C10.9868 4.44455 10.6134 4.44455 9.86667 4.44455H6.13333C5.3866 4.44455 5.01323 4.44455 4.72801 4.58988C4.47713 4.71771 4.27316 4.92168 4.14532 5.17257C4 5.45778 4 5.83115 4 6.57789V10.3112C4 11.058 4 11.4313 4.14532 11.7165C4.27316 11.9674 4.47713 12.1714 4.72801 12.2992C5.01323 12.4446 5.3866 12.4446 6.13333 12.4446Z"
        stroke="#755AE2"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  const timeIcon = (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#F0EEFF" />
      <path
        d="M7.99962 5.33344V8.00011L9.7774 8.889M12.4441 8.00011C12.4441 10.4547 10.4542 12.4446 7.99962 12.4446C5.54502 12.4446 3.55518 10.4547 3.55518 8.00011C3.55518 5.54551 5.54502 3.55566 7.99962 3.55566C10.4542 3.55566 12.4441 5.54551 12.4441 8.00011Z"
        stroke="#755AE2"
        stroke-width="0.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );

  return (
    <ModalLayout>
      <div className="create-notification">
        <Column>
          <Column gap={0}>
            <h6>Push Notification</h6>
            <h5>Refire Notification</h5>

            <div className="step-indicator">
              {[0, 1].map((item, i) => (
                <p onClick={() => setStep(i)} className={cn(i === step && "active")}></p>
              ))}
            </div>
          </Column>

          {step === 0 && (
            <Column>
              <Column className="input-view__input">
                <RavenInputField
                  label="Topic"
                  placeholder="Enter topic..."
                  type={"text"}
                  name="topic"
                  color="black-light"
                  value={formData.topic}
                  onChange={handleForm}
                />
                <RavenInputField
                  label="Notification Title"
                  placeholder="Enter a Notification title"
                  type={"text"}
                  name="title"
                  color="black-light"
                  value={formData.title}
                  onChange={handleForm}
                />
                <RavenInputField
                  label="Notification Message"
                  placeholder="Write your thoughts here"
                  type={"textarea"}
                  name="message"
                  color="black-light"
                  value={formData.message}
                  onChange={handleForm}
                />
              </Column>
            </Column>
          )}

          {step === 1 && (
            <Column>
              <p className="confirmation-title">
                When would you like to send this notification?
              </p>

              <Column className="selector">
                <Row
                  onClick={() =>
                    setNotificationDelivery({ ...notificationDelivery, type: "instant" })
                  }
                  ai="center"
                  className="selector__item"
                >
                  Send notification Immediately
                  <figure className="cursor-pointer">
                    {notificationDelivery.type === "instant" ? radioChecked : radio}
                  </figure>
                </Row>

                <Column
                  onClick={() =>
                    setNotificationDelivery({
                      ...notificationDelivery,
                      type: "scheduled",
                    })
                  }
                  className="selector__item scheduled"
                >
                  <Row ai="center" className="scheduled__title">
                    <p>Schedule notification for later</p>

                    <figure className="cursor-pointer">
                      {notificationDelivery.type === "scheduled" ? radioChecked : radio}
                    </figure>
                  </Row>

                  {notificationDelivery.type === "scheduled" && (
                    <Column>
                      <Row>
                        <RavenInputField
                          type="date"
                          color={colorVariable}
                          placeholder="Enter date..."
                          onChange={(e: any) =>
                            setNotificationDelivery((prev) => {
                              return {
                                ...prev,
                                date: e,
                              };
                            })
                          }
                        />

                        <RavenInputField
                          type="time"
                          color={colorVariable}
                          onChange={(e: any) =>
                            setNotificationDelivery((prev) => {
                              return {
                                ...prev,
                                time: e.target.value,
                              };
                            })
                          }
                        />
                      </Row>

                      <Column className="date-preview">
                        <Row gap={1} className="date-preview__info">
                          {notification}
                          <p>Notification would be sent on the date displayed below</p>
                        </Row>

                        <Row gap={1}>
                          <Row className="date-preview__item">
                            {dateIcon}
                            <p>
                              {new Date(notificationDelivery.date).toLocaleDateString()}
                            </p>
                          </Row>

                          <Row className="date-preview__item">
                            {timeIcon}
                            <p>{notificationDelivery?.time}</p>
                          </Row>
                        </Row>
                      </Column>
                    </Column>
                  )}
                </Column>
              </Column>
            </Column>
          )}

          <RavenButton
            className={cn(
              notificationDelivery.type === "scheduled" && "action-btn__scheduled"
            )}
            size="small"
            loading={reqn}
            disabled={
              (step === 0 && !formData.message) || !formData.title || !formData.title
            }
            label={
              notificationDelivery.type === "instant" ? "Continue" : "Create Notification"
            }
            onClick={() => {
              step === 0 ? setStep(1) : handleSendPushNotification();
            }}
            color={colorVariable}
          ></RavenButton>
        </Column>
      </div>
    </ModalLayout>
  );
};

export default RefireNotification;

function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so we add 1
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
