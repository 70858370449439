import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import TwinValue from "@/components/common/twinValue";
import Txreference from "@/components/common/reference/TxRef";
import Dropdown from "@/components/common/dropdown";
import { IconVault, formatDateTime, formatStatus } from "@/utils/helper/Helper";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import ActionModal from "@/components/common/modal/ActionModal";
import { useEffect, useState } from "react";
import { icons } from "@/assets/icons";
import Avatar from "@/components/common/avatar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import { FilterType, Util } from "@/utils/utility";
import { formatNumberToCurrency } from "@/utils/helpers";
import EmptyScreen from "@/components/common/emptyScreen";
import Redacted from "@/components/common/shimmer/redact";
import { usePagination } from "@/hooks/usePagination";
import { useExportHandler, useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { PAYROLL_STATUS } from "../__internals__/constants";
import { useAppDispatch } from "@/redux/store";

const BusinessPayrolls = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });
  const [filterValue, setFilterValue] = useState<any>({});

  const dropdown = [
    {
      label: `Mark as Paid`,
      img: IconVault(icons.check_circle),
      func: showMarkPaid,
    },
    {
      label: `Refire`,
      img: IconVault(icons.refire),
      func: showRefireModal,
    },
  ] as const;

  const [currentPage, setCurrentPage] = useState(1);

  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      BugissApi.business.getPayrolls({
        current_page: currentPage,
        search_term: searchQuery,
        ...filterValue,
      })
    );
  }, [currentPage, searchQuery, filterValue, dispatch]);

  const { payrolls, loading } = useSelector((state: RootState) => state.businessPayroll);

  const rolls = payrolls?.data ?? [];
  const { isRedacting } = useSelector((state: RootState) => state.general);

  const valuePair = {
    "TRANSFER COUNT": "transfer_out_count",
    "TRANSFER SUM": "transfer_out_sum",
    "TRANSFER FEES": "transfer_out_fees",
    CREATED: "created_at",
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    // data: rolls,
    loading,
    valuePair,
    title: "Business Payroll(Bugiss)",
    filterValue,
    apiFunction: BugissApi.business.getPayrolls,
    dataPath: "payroll_disbursement.data",
  });
  const { toggleModal } = useExportModal(handleExport);

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Payrolls">
          <SmartFilter
            onSearchChange={(e: string) => setSearchQuery(e)}
            onFilter={(e: any) => {
              setFilterValue(Util.filterStruct(e));
            }}
            onExport={() => {
              toggleModal("Export Payroll");
            }}
            singleSelect
            pagination={{
              currentPage: currentPage,
              itemsPerPage: Number(payrolls.per_page),
              totalItems: payrolls.total,

              onPageChange: function (page: number): void {
                setCurrentPage(page);
              },
              activeFilter: "All Pending Deposits",
            }}
            filters={[
              {
                label: "Status",
                filterBy: "status",
                options: [
                  {
                    value: PAYROLL_STATUS.PENDING_APPROVAL.toString(),
                    label: "Pending Approval",
                  },
                  {
                    value: PAYROLL_STATUS.PENDING_PROCESSING.toString(),
                    label: "Pending Processing",
                  },

                  {
                    value: PAYROLL_STATUS.CANCELLED.toString(),
                    label: "Cancelled",
                  },
                  {
                    value: PAYROLL_STATUS.PROCESSED.toString(),
                    label: "Processed",
                  },
                ],
              },
            ]}
            searchTitle="Search Payrolls..."
          />

          <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
            {(!isRedacting && loading) || rolls?.length === 0 ? (
              <EmptyScreen loading={loading} />
            ) : (
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "Business Details",
                  "Employees",
                  "Amounts",
                  "Overdraft",
                  "Date Created",
                  "Payout Date",
                  "Status",
                  "",
                ]}
              >
                {rolls.map((data, index) => (
                  <RavenTableRow
                    key={index}
                    onRowClick={() => navigate(`details?biz=${data.business_email}`)}
                    one={
                      loading ? (
                        <Redacted.AvatarWithValue />
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center", gap: "1.2rem" }}
                        >
                          <Avatar name={Util.safeValue(data.business_name)} />
                          <TwinValue
                            one={Util.safeValue(data.business_name)}
                            two={Util.safeValue(data.business_email)}
                            copy
                          />
                        </div>
                      )
                    }
                    two={loading ? <Redacted.Table /> : data.recipients}
                    three={
                      loading ? (
                        <Redacted.Table />
                      ) : (
                        formatNumberToCurrency(data?.net_amount ?? 0, "NGN")
                      )
                    }
                    four={
                      loading ? (
                        <Redacted.Table />
                      ) : (
                        formatNumberToCurrency(data?.overdraft_deductions ?? 0, "NGN")
                      )
                    }
                    five={loading ? <Redacted.Table /> : formatDateTime(data.created_at)}
                    six={loading ? <Redacted.Table /> : formatDateTime(data.pay_date)}
                    seven={
                      loading ? <Redacted.Badge /> : formatStatus(data.status as string)
                    }
                    eight={
                      loading ? (
                        <Redacted.Avatar />
                      ) : (
                        <ActionDrop
                          visit
                          onVisitClick={() => {
                            navigate(`details?biz=${data.business_email}`);
                          }}
                        />
                      )
                    }
                  />
                ))}
              </RavenTable>
            )}
          </div>
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPayrolls;
