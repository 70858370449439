import TabComponent from "@/components/common/Tabs";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import React from "react";
import "./styles/index.css";
import EmptyScreen from "@/components/common/emptyScreen";
import { RavenButton, RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Txreference from "@/components/common/reference/TxRef";
import { formatStatus } from "@/utils/helper/Helper";
import { icons } from "@/assets/icons";
import { TabsElement } from "@/components/common/Tabs/shared";
import Avatar from "@/components/common/avatar";
import SmartFilter from "@/components/common/smartFilter";
import { FakePaginationProps } from "@/utils/helpers";

const PartnersTransfers = () => {
  const [selectedTab, setSelectedTab] = React.useState<string>("Bank Transfer");
  const tableHeader =
    selectedTab === "Bank Transfer"
      ? [
          "Merchant Details",
          "Reference",
          "Recipients",
          "Amount",
          "Date Initiated",
          "Status",
        ]
      : selectedTab === "Deducted Deposits"
      ? ["Recipients", "Reason", "Amount", "Date Deducted", "Action"]
      : selectedTab === "Transfer Summary"
      ? ["Count", "Volume Processed", "Transfer Cost", "Charges", "PNL", "DATE", " "]
      : selectedTab === "Bulk Transfers"
      ? ["Reference", "Account Name", "Username", "Amount", "Date Initiated", "Status"]
      : [
          "Merchant Details",
          "Reference",
          "Recipients",
          "Amount",
          "Date Initiated",
          "Status",
        ];
  const tabs: TabsElement[] = [
    {
      value: "Bank Transfers",
      label: "Bank Transfers",
      dropdown: {
        onChange: (e: { event: Event; label: string; value: string }) => {
          setSelectedTab(e.label);
        },
        tabs: [
          { label: "Bank Transfers", value: "Bank Transfers" },
          { label: "Internal Transfers", value: "Internal Transfers" },
          { label: "Bulk Transfers", value: "Bulk Transfers" },
        ],
      },
    },
    "Withdrawal Issues",
    "Deducted Deposits",
    "Transfer Summary",
  ];

  return (
    <div>
      <DashboardLayout>
        <PageLayout pageTitle="Bank Transfer">
          <div className="business-transfers">
            <div className="business-transfers__top-wrap">
              <TabComponent
                onValueChange={(e) => setSelectedTab(e)}
                defaultValue={tabs[0]}
                className="tabled"
              >
                <TabComponent.Tabs tabs={tabs} />
                <div style={{ marginTop: "2rem", marginBottom: "-2rem" }}>
                  <SmartFilter pagination={FakePaginationProps} />
                </div>
                {tabs.map((tab, idx) => (
                  <TabComponent.Content key={idx} value={tab}></TabComponent.Content>
                ))}
              </TabComponent>
            </div>

            {selectedTab === "Bulk Transfers" && (
              <div className="top-wrap__main-content">
                <div className="top-wrap__main-content--profile-img">
                  {icons.bulk_transfer_illus}
                </div>

                <div className="top-wrap__main-content--user-data">
                  <div className="user-data__main-content">
                    <div className="main-content__col">
                      <div className="main-content__col--item">
                        <small>Total Amount</small>
                        <p>Adeeko Emmanuel</p>
                      </div>

                      <div className="main-content__col--item">
                        <small>Date Initiated</small>
                        <p>204</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="main-content__col">
                      <div className="main-content__col--item">
                        <small>Total Recipient</small>
                        <p>@monalee</p>
                      </div>
                      <div className="main-content__col--item">
                        <small>Total Succesful</small>
                        <p>3 months ago</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="main-content__col">
                      <div className="main-content__col--item">
                        <small>Total Fees</small>
                        <p>₦12,000.00</p>
                      </div>

                      <div className="main-content__col--item">
                        <small>Total Pending</small>
                        <p>Verified</p>
                      </div>
                    </div>
                    {/*  */}
                    <div className="main-content__col">
                      <div className="main-content__col--item">
                        <small>Mobile Number</small>
                        <p>+234 • 701 •5263 •711</p>
                      </div>

                      <div className="main-content__col--item">
                        <small>Total Referred Users</small>
                        <p>324</p>
                      </div>
                    </div>
                  </div>
                  <div className="retry-btn">
                    <RavenButton color={"black-light"}>
                      <figure>{icons.retry}</figure>
                      Retry Failed Transfers
                    </RavenButton>
                  </div>
                </div>
              </div>
            )}
            <div className="business-transfers__table-wrap">
              {selectedTab === "Withdrawal Issues" ? (
                <EmptyScreen title={`No ${selectedTab} transfers for now.`} />
              ) : (
                <RavenTable headerList={tableHeader}>
                  {selectedTab === "Bank Transfer" ? (
                    <RavenTableRow
                      one={<Txreference reference={"nin2832nxns923"} />}
                      two="₦1,325,000.00"
                      three="₦125,000.00"
                      four="5289"
                      five="5289"
                      six={formatStatus(1)}
                    />
                  ) : selectedTab === "Deducted Deposits" ? (
                    <>
                      <RavenTableRow
                        one={<Txreference reference={"nin2832nxns923"} />}
                        two="₦1,325,000.00"
                        three="₦125,000.00"
                        four="5289"
                        five={formatStatus("paid")}
                      />
                      <RavenTableRow
                        one={<Txreference reference={"nin2832nxns923"} />}
                        two="₦1,325,000.00"
                        three="₦125,000.00"
                        four="5289"
                        five={formatStatus("markPaid")}
                      />
                    </>
                  ) : selectedTab === "Transfer Summary" ? (
                    <RavenTableRow
                      one="22 Oct 2023"
                      two="₦1,325,000.00"
                      three="₦125,000.00"
                      four="5289"
                      five={<p style={{ color: "#FF0F00" }}>-₦100,000.00</p>}
                      six="5 Sept, 2023 • 5:24pm"
                      seven={icons.download_02}
                    />
                  ) : selectedTab === "Bulk Transfers" ? (
                    <RavenTableRow
                      one="22 Oct 2023"
                      two={
                        <div>
                          <Avatar full name="Olakunle Olutayo " />
                        </div>
                      }
                      three="@loomie"
                      four="₦12,000,000.00"
                      six="5 Sept, 2023 • 5:24pm"
                      seven={formatStatus("pending")}
                    />
                  ) : (
                    <RavenTableRow
                      one="22 Oct 2023"
                      two="₦1,325,000.00"
                      three="₦125,000.00"
                      four="5289"
                    />
                  )}
                </RavenTable>
              )}
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>
    </div>
  );
};

export default PartnersTransfers;
