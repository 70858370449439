import "~/common/used-by-details/index.css";
import "~/common/shared/single-user/index.css";
import { icons } from "@/assets/icons";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { MainContentCol } from "@/components/common/mainContentCol";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import Avatar from "@/components/common/avatar";
import { formatStatus } from "@/utils/helper/Helper";
import SmartFilter from "@/components/common/smartFilter";
import TabComponent, { TabsElement } from "@/components/common/Tabs";
import { YStack } from "@/components/common/stacks";
import { IconButton } from "@/components/common/icon-button";
import { PageLayoutTopBackButton } from "@/components/common/atoms";

const status = ["successful", "pending", "failed"];

const tabElement: TabsElement[] = [
  "Transactions",
  {
    value: "Bank Transfers",
    label: "Bank Transfers",
    dropdown: {
      tabs: [
        { label: "Bank Transfers", value: "Bank Transfers" },
        { label: "Internal Transfers", value: "Internal Transfers" },
      ],
      maxWidth: "20rem",
    },
  },
  "Referrals",
  {
    label: "Bills",
    value: "Bills",
    dropdown: {
      maxWidth: "15rem",
      tabs: ["Airtime", "Data", { label: "Cable TV", value: "Cable TV" }, "Betting"],
    },
  },
  "Deposits",
  "Collections",
  "BankBox",
  "Cards",
  "Virtual Accounts",
  "Compliance",
  "Dispute",
];

export const MerchantDetails = () => {
  return (
    <DashboardLayout>
      <PageLayout
        pageTitle="Merchant Details"
        topLeftContent={<PageLayoutTopBackButton />}
        style={{ backgroundColor: "unset" }}
        childrenClassName="product-single-details-page"
      >
        <div className="single-user-details__top-wrap">
          <div className="top-wrap__main-content">
            <div className="top-wrap__main-content--profile-img product-single-details-page__avatar">
              {icons.send_04_double_filled}
            </div>

            <div className="top-wrap__main-content--user-data">
              <div className="user-data__main-content">
                <MainContentCol>
                  <MainContentCol.Item title="Merchant Name">
                    Partime spiderman and sons
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Date Joined">
                    26, October 2023
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Industry">
                    Financial Technology
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Owner's Fullname">
                    Adeeko Emmanuel
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Last seen">Yesterday</MainContentCol.Item>
                  <MainContentCol.Item title="RC Number">692576</MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Merchant Email Address">
                    godspowernathniel25@gmail.com
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Merchant Address">
                    10, Oyedale close
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Monthly Transaction Range">
                    ₦500,000 - ₦1,000,000
                  </MainContentCol.Item>
                </MainContentCol>
                <MainContentCol>
                  <MainContentCol.Item title="Mobile Number">
                    +234 • 701 •5263 •711
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Merchant Description">
                    Deals in Importing an...
                  </MainContentCol.Item>
                  <MainContentCol.Item title="Staff">51-100</MainContentCol.Item>
                </MainContentCol>
              </div>
            </div>
          </div>
          <div className="user-data__account-summary">
            <div className="top-wrap__actions">
              <IconButton
                borderColor="#FF0F00"
                text="Block Merchant"
                icon="slash_circle_01"
              />
              <IconButton borderColor="#EA872D" text="Strip Account" icon="info_circle" />
              <IconButton text="Lien Account" icon="info_circle" />
              <IconButton text="Make a Deduction  " icon="minus_circle" />
            </div>
          </div>
        </div>

        <TabComponent className="tabled">
          <div className="single-user-details__table-wrap">
            <YStack>
              <TabComponent.Tabs tabs={tabElement} />
              <SmartFilter />
            </YStack>
            <RavenTable
              headerList={[
                "REFERENCE",
                "ACCOUNT NAME",
                "BANK",
                "ACCOUNT NO.",
                "AMOUNT",
                "DATE INITIATED",
                "STATUS",
              ]}
            >
              {status.map((status) => (
                <RavenTableRow
                  key={status}
                  one="7685493023456..."
                  two={<Avatar name="Rehk Mansa" full />}
                  three="UBA"
                  four="2135876118"
                  five="₦12,000,000.00"
                  six="5 Sept, 2022 • 5:48PM"
                  seven={formatStatus(status)}
                />
              ))}
            </RavenTable>
          </div>
        </TabComponent>
      </PageLayout>
    </DashboardLayout>
  );
};
