export const LOADING_WORDS = [
  "🧘 Doing some yoga for your request",
  "🔢 Calculating the transaction length, hope NEPA doesn’t take light",
  "🧚 Summoning digital fairies, but traffic dey for their side",
  "☕ Brewing a cup of virtual coffee, with plenty sugar",
  "🤖 Tickling the servers, but they’re asking for jollof first",
  "🐹 Summoning a team of hamsters... these ones no be Nigerian rats o!",
  "✨ Polishing the code to make it shine brighter than Lagos at night",
  "🤝 Convincing the bits and bytes, but they’re asking for awoof",
  "🧠 Practicing mindfulness while NEPA takes light... again",
  "🤣 Tickling the algorithms, they’re laughing in pidgin",
  "🚀 Hitting the turbo boost button, but fuel price dey high",
  "☁️ Channeling the power of the cloud, hope rain no go start",
  "💃 Dancing with the data, zanku moves activated",
  "🌐 Sending positive vibes to the internet... when the network comes back",
  "🔄 Spinning the wheel of progress, but PHCN is watching",
  "💬 Whispering sweet nothings to the database, it said ‘try again later’",
  "🖐️ High-fiving the network connections, na elbow bump dem give",
  "🌊 Riding the digital wave, hope no potholes for the road",
  "🌱 Nurturing the pixels with care, like Mama’s garden",
  "⛴️ Captaining the ship through cyberspace, avoiding traffic at Mile 2",
  "😤 Taking some abido shaker to shake the servers awake",
  "🛠️ Tightening some digital screws, Naija style: with pure willpower",
  "🎨 Painting the pixels, sharper than Aba-made designs",
  "🌌 Exploring the vast expanse of cyberspace, e be like Lagos Third Mainland",
  "🤔 Asking ChatGPT for advice, but I dey speak pidgin so e delay",
  "🍪 Baking some virtual cookies, but small chops dey for queue",
  "📡 Aligning the virtual satellites, hope NCC no catch us",
  "🦄 Wrangling the unicorns, they’re charging in naira",
  "🎢 Riding the rollercoaster of ones and zeroes, hold tight o!",
  "📖 Reading the internet's terms of service, abi who dey read T&Cs?",
  "🕵️ Searching for clues in the data maze, like CID looking for wahala",
  "🌞 Soaking up solar energy, because fuel price no be am",
  "📦 Packing your digital goodies, but custom dey delay clearance",
  "🧵 Stitching the data, no tailor wahala here o!",
  "🔋 Recharging the digital batteries, steady NEPA prayers ongoing",
  "🎭 Putting on a show for the processors, front row seat for Zikoko",
  "🏗️ Building the digital castle brick by brick, like Lekki estate developers",
  "🐢 Racing against a slow tortoise, but naija snail pass am",
  "🛸 Hovering over the cloud, avoiding Amotekun checkpoints",
  "🌟 Shooting for the stars, but traffic hold-up dey",
  "🎯 Aiming for precision, sharper than 9ja hawker skills",
  "🕹️ Playing a digital game of patience, with Naija network providers",
  "🌀 Spinning into a vortex of speed, NEPA permitting",
  "💡 Lighting up the data highway, generator on standby",
  "🏓 Ponging your request back and forth, like table tennis in Yaba market",
];
