import "./styles/index.css";
import { DashboardInfoCard } from "../../../components/common/dashboardInfo";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import TabComponent from "@/components/common/Tabs";
import { Graph } from "@/components/charts/overview-chart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { BugissApi } from "@/redux/slices";
import { formatNumWithComma } from "@/utils/helper/Helper";
import Loader from "@/components/common/loader";
import { Util } from "@/utils/utility";
import GraphFilter from "@/components/common/graphFilter/graphFilter";
import { Row } from "@/components/common/stacks";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { formatNumberToCurrency } from "@/utils/helpers";
import { useAppDispatch } from "@/redux/store";

const Tabs = ["Business Metrics", "Transaction Metrics"];

function BusinessOverview() {
  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState("Business");
  const [graphFilter, setGraphFilter] = useState({
    duration_type: "",
    date: "",
    date_2: "",
  });
  useEffect(() => {
    dispatch(
      BugissApi.business.getOverview({
        type_of_chat: selectedTab?.includes("Business")
          ? "business_count"
          : "transaction",
        ...graphFilter,
      })
    );
    // dispatch(BugissApi.business.getBusinesses({}));
  }, [graphFilter, selectedTab]);

  const resp = businessReduxAPI.useGetOverviewStatsQuery();

  const stats = resp.data;

  const { overview, loading } = useSelector((state: RootState) => state.businessOverview);

  const TempData = [
    {
      title: "Total Businesses",
      value: `${Util.safeValue(stats?.businesses_count)}`,
      // stats: "+6000 This month",
      isPositive: true,
      loading: resp.isFetching || resp.isLoading,
    },
    {
      title: "Total Transactions",
      value: `${Util.safeValue(formatNumWithComma(String(stats?.total_transactiions)))}`,
      // stats: "+% This month",
      isPositive: true,
      loading: resp.isFetching || resp.isLoading,
    },
    {
      title: "Volume Processed",
      value: `${Util.safeValue(
        formatNumberToCurrency(Number(stats?.total_volume), "NGN")
      )}`,
      // stats: "+0% This month",
      isPositive: true,
      loading: resp.isFetching || resp.isLoading,
    },
    {
      title: "Businesses Cash Balance",
      value: `${Util.safeValue(
        formatNumberToCurrency(Number(stats?.business_cash_balance), "NGN")
      )}`,
      loading: resp.isFetching || resp.isLoading,
    },
  ];

  function objectValuesToArray(obj: Record<string, any>): any[] {
    if (obj instanceof Object) {
      return Object.values(obj);
    } else {
      return [];
    }
  }

  return (
    <DashboardLayout>
      <main className="buggies-business-dashboard">
        <h1 className="page-title text-white-black">Overview</h1>

        <DashboardInfoCard.Wrapper className="highlighted-content-wrapper">
          {TempData.map((ele) => (
            <DashboardInfoCard key={ele.title} {...ele} />
          ))}
        </DashboardInfoCard.Wrapper>

        <div className="buggies-business-dashboard__chart-area highlighted-content-wrapper">
          <TabComponent
            tabs={Tabs}
            onTabValueChange={setSelectedTab as any}
            defaultValue={Tabs[0]}
          >
            <Row style={{ justifyContent: "space-between", width: "100%" }}>
              <TabComponent.Tabs tabs={Tabs} />
              <GraphFilter
                onChange={(e) => {
                  const f = Util.getDateRange(
                    String(e?.type)?.toLowerCase() as "daily",
                    e.for
                  );
                  setGraphFilter({
                    duration_type: String(e.type).toLowerCase(),
                    date: f.start_date,
                    date_2: f.end_date,
                  });
                }}
              />
            </Row>
            {[0, 1].map((n) => (
              <div
                key={n}
                className="buggies-business-dashboard__chart-content bdr-eee-333"
              >
                <div className="buggies-business-dashboard__chart-content--tabnfilter"></div>
                <TabComponent.Content value={Tabs[n]}>
                  {/* <div className="buggies-business-dashboard__select-area">
                    <RavenInputField
                      selectOption={[
                        { label: "Yearly", value: "yearly" },
                        { label: "Monthly", value: "monthly" },
                        { label: "Weekly", value: "weekly" },
                        { label: "Daily", value: "daily" },
                      ]}
                      type="select"
                      color="black-light"
                      selectStyles={reactSelectStyle}
                      placeholder="Yearly"
                    />
                  </div> */}

                  <div className="buggies-business-dashboard__chart">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Graph
                        data={objectValuesToArray(
                          n === 1
                            ? overview.transactionSummaryObject
                            : overview.businessCountObject
                        )}
                        labels={
                          overview &&
                          overview.labelArray?.map((d: any) => d.split(",")[0])
                        }
                      />
                    )}
                  </div>
                </TabComponent.Content>
              </div>
            ))}
          </TabComponent>
        </div>
      </main>
    </DashboardLayout>
  );
}

export default BusinessOverview;
