import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import Avatar from "@/components/common/avatar";
import EmptyScreen from "@/components/common/emptyScreen";
import ActionModal from "@/components/common/modal/ActionModal";
import Redacted from "@/components/common/shimmer/redact";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { useExportModal } from "@/hooks/useExportModal";
import useIsolatedExport from "@/hooks/useIsolatedExport";
import { usePagination } from "@/hooks/usePagination";
import useThemeMode from "@/hooks/useThemeMode";
import { BugissApi } from "@/redux/slices";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatStatus, trimLongString } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { RavenTable, RavenTableRow, RavenToolTip } from "@ravenpay/raven-bank-ui";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./styles/index.css";
import { GENERAL_CLASSES } from "@/constants";
import { BIZ_INVOICE_STATUS, BIZ_TRANSACTION_STATUS } from "../__internals__/constants";
import { useAppDispatch } from "@/redux/store";

const BusinessPaymentLinks = () => {
  const { isDarkMode } = useThemeMode();

  const [refireModal, showRefireModal] = useState({ chi: "", on: false });
  const [markPaid, showMarkPaid] = useState({ chi: "", on: false });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    currentPage,
    setCurrentPage,
    filterValue,
    setFilterValue,
    searchQuery,
    setSearchQuery,
  } = usePagination();
  useEffect(() => {
    dispatch(
      BugissApi.business.fetchPaymentLinks({
        current_page: currentPage,
        search_term: searchQuery,
        per_page: 20,
        ...filterValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filterValue, searchQuery]);

  const { payment_links, loading } = useSelector(
    (state: RootState) => state.businessPaymentLinks
  );

  const links = payment_links?.data ?? [];

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const valuePair = {
    "BUSINESS NAME": "business_name",
    "BUSINESS EMAIL": "business_email",
    "LINK TITLE": "title",
    "LINK DESCRIPTION": "request_description",
    AMOUNT: "request_amount",
    CURRENCY: "currency",
    RECURRING: "recurring",
    TYPE: "type",
    CREATED: "created_at",
    STATUS: ["status", "paymentLinks"],
  };

  const handleExport = useIsolatedExport({
    setFilterValue,
    // data: exportApi?.data?.data as any,
    loading: loading,
    valuePair,
    apiFunction: BugissApi.business.fetchPaymentLinks,
    dataPath: "payment_link_transactions_records.data",
    title: "Business Payment Links(Bugiss)",
    filterValue,
  });
  const [exportTriggered, triggerExport] = useState(false);

  const forwardExportCall = () => {
    handleExport();
    triggerExport(false);
  };
  const { toggleModal } = useExportModal(exportTriggered ? forwardExportCall : () => {});

  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Payment Links">
          <SmartFilter
            pagination={{
              currentPage: currentPage,
              itemsPerPage: Number(payment_links.per_page),
              totalItems: payment_links.total,
              onPageChange: function (page: number): void {
                setCurrentPage(page);
              },
              activeFilter: "All Pending Deposits",
            }}
            onFilter={(e: any) => {
              setFilterValue(Util.filterStruct(e) as any);
              setCurrentPage(1);
            }}
            onExport={() => {
              toggleModal("Export Payment Links");
              triggerExport(true);
            }}
            singleSelect
            filters={[
              {
                label: "Status",
                filterBy: "status",
                options: [
                  { value: BIZ_INVOICE_STATUS.ACTIVE.toString(), label: "Active" },
                  {
                    value: BIZ_INVOICE_STATUS.INACTIVE.toString(),
                    label: "Inactive",
                  },
                ],
              },
              {
                label: "Type",
                filterBy: "payment_link_type",
                options: [
                  {
                    value: "0",
                    label: "Onetime",
                  },
                  {
                    value: "1",
                    label: "Recurring",
                  },
                ],
              },
            ]}
            onSearchChange={(e: string) => setSearchQuery(e)}
            searchTitle="Search links..."
          />

          {(!isRedacting && loading) || links?.length === 0 ? (
            <EmptyScreen loading={loading} />
          ) : (
            <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
              <RavenTable
                action={false}
                className="table__main"
                headerList={[
                  "Business Details",
                  "Link Details",
                  "Amount",
                  "Type",
                  "Date Created",
                  "Status",
                  "Action",
                ]}
              >
                {links.map((data, index) => (
                  <RavenTableRow
                    key={index}
                    onRowClick={() => navigate(String(data.id))}
                    one={
                      loading ? (
                        <Redacted.Labelled />
                      ) : (
                        <div
                          style={{ display: "flex", alignItems: "center", gap: "1.2rem" }}
                        >
                          <Avatar name={data.business_name} />
                          <TwinValue
                            one={Util.capitalizeEachWord(
                              Util.safeValue(trimLongString(data.business_name, 30))
                            )}
                            two={Util.safeValue(data.business_email)}
                            copy
                          />
                        </div>
                      )
                    }
                    two={
                      loading ? (
                        <Redacted.Labelled />
                      ) : (
                        <div className={cn(GENERAL_CLASSES.TOOL_TIP_WRAPPER)}>
                          <TwinValue
                            one={Util.capitalizeEachWord(
                              Util.safeValue(trimLongString(data.title, 30))
                            )}
                            two={Util.capitalizeEachWord(
                              Util.safeValue(trimLongString(data.request_description, 30))
                            )}
                          />
                          <RavenToolTip
                            color="black-light"
                            text={data.title}
                            position="top-center"
                          />
                        </div>
                      )
                    }
                    three={
                      loading ? (
                        <Redacted.Table />
                      ) : (
                        formatNumberToCurrency(
                          data.request_amount,
                          data?.currency?.toUpperCase() ?? "NGN"
                        )
                      )
                    }
                    four={
                      loading ? (
                        <Redacted.Badge />
                      ) : (
                        formatStatus(
                          Number(data.recurring) === 0 ? "onetime" : "recurring"
                        )
                      )
                    }
                    five={loading ? <Redacted.Table /> : formatDateTime(data.created_at)}
                    six={
                      loading ? (
                        <Redacted.Table />
                      ) : (
                        formatStatus(detectStatus(data.status, "paymentLinks"))
                      )
                    }
                    seven={
                      loading ? (
                        <Redacted.Avatar />
                      ) : (
                        <ActionDrop
                          visit
                          onVisitClick={() => {
                            navigate(data.id);
                          }}
                        />
                      )
                    }
                  />
                ))}
              </RavenTable>
            </div>
          )}
        </PageLayout>
      </DashboardLayout>

      <ActionModal
        visible={refireModal.on}
        onCancel={() => {
          showRefireModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Refire Transaction`}
      />

      <ActionModal
        visible={markPaid.on}
        onCancel={() => {
          showMarkPaid({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Verify"
        btnColor="#755AE2"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Mark Transaction as Paid`}
      />
    </>
  );
};

export default BusinessPaymentLinks;
