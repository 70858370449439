import {
  RavenButton,
  RavenInputField,
  RavenModal,
  RavenPagination,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import React, { ReactNode } from "react";
import "./styles/pickup.css";
import { cn } from "@/utils/colorConvert";
import { useProductColor } from "@/context/ProductTheme";
import ReactDOM from "react-dom";
import { partnersIcons } from "@/assets/icons/partnersIcons";
import SmartFilter from "../smartFilter";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
// import { Input } from "../input";

type Modal = {
  title: string;
  body?: string;
  cancelText?: string;
  actionText?: string;
  className?: string;
  btnClassName?: string;
  visible: boolean;
  onCancel: () => void;
  onClick: () => void;
  hideCancel?: boolean;
  big?: boolean;
  extraBig?: boolean;
  normal?: boolean;
  children?: ReactNode;
  loading?: boolean;
  btnTextColor?: string;
  hideNormalButton?: boolean;
  preventOuterClose?: boolean;
  altCancelFunc?(): void;
  subTitle?: string;
  disableButton?: boolean;
  pinEnabled?: true;
};

const sample = [
  {
    station: "First Bank HeadOffice ",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank  Maryland  Ikeja",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank Surulere",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank Mushin",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank Mushin",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank Banana Island",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
  {
    station: "First Bank Lekki",
    state: "Lagos",
    duration: "MON - FRI 09:00AM - 6:00PM",
  },
];

//TODO: Some of the props may not be used but I copied them from the ActionModal component so I will have to discard some, and implement those needed
const PickupStationModal = (props: Modal) => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } =
    usePosGetQuery<PosMerchants>("getMerchants");

  const {
    title,
    onCancel,
    visible = false,
    onClick,
    hideCancel = false,
    big = false,
    extraBig = false,
    normal = false,
    children,
    hideNormalButton,
    altCancelFunc,
    preventOuterClose,
  } = props;

  const { colorToken } = useProductColor();

  const portal = document.getElementById("modal") as Element;
  if (children) {
    return ReactDOM.createPortal(
      <section className={`pickup_modal`} style={{ display: visible ? "flex" : "none" }}>
        <div className={`pickup_modal_inner_wrapper`}>
          <div className={`pickup_modal_header`}>
            <h6>{title}</h6>
            <div onClick={onCancel} className={`pickup_modal_close_btn`}>
              {partnersIcons.closeIcon}
            </div>
          </div>
          <SmartFilter
            {...SmartFilterProps}
            hideFilter
            searchTitle="Search Pickup Stations"
          />
          <RavenTable headerList={["PICK UP STATION", "STATE", "DURATION"]}>
            {sample.map((data, index) => {
              return (
                <RavenTableRow
                  key={index}
                  one={data.station}
                  two={data.state}
                  three={data.duration}
                />
              );
            })}
          </RavenTable>
        </div>
      </section>,
      portal
    );
  } else {
    return ReactDOM.createPortal(
      <section className={`pickup_modal`} style={{ display: visible ? "flex" : "none" }}>
        <div className={`pickup_modal_inner_wrapper`}>
          <div className={`pickup_modal_header`}>
            <h6>{title}</h6>
            <div onClick={onCancel} className={`pickup_modal_close_btn`}>
              {partnersIcons.closeIcon}
            </div>
          </div>
          <SmartFilter
            {...SmartFilterProps}
            hideFilter
            searchTitle="Search Pickup Stations"
          />
          <RavenTable headerList={["PICK UP STATION", "STATE", "DURATION"]}>
            {sample.map((data, index) => {
              return (
                <RavenTableRow
                  key={index}
                  one={data.station}
                  two={data.state}
                  three={data.duration}
                />
              );
            })}
          </RavenTable>
        </div>
      </section>,
      portal
    );
  }
};

export default PickupStationModal;
