import { useEffect, useState } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { RavenToast } from "@ravenpay/raven-bank-ui";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { auth_routes_group } from "./routes/auth";
import { Helmet } from "react-helmet";

import store from "./redux/store";
import "@ravenpay/raven-bank-ui/dist/esm/styles/index.css";
import { dashboard_routes_group } from "./routes/dashboard";
import "./styles/mobile/index.css";
import "./styles/tablets/index.css";
import "./styles/index.css";
import { useProductTheme } from "./context/ProductTheme";
import { getThemePrimaryColor } from "./utils/helpers";
import { ExportType, useExportModal } from "./hooks/useExportModal";
import ExportModal from "./components/common/exportModal";
import "~/common/shared/index.css";
import { PERSONAL_EXPORT_MODAL_ID, RENDER_DYNAMIC_MODAL_ID } from "./constants";
import { ErrorBoundary, ErrorBoundaryVariant } from "./components/common/error-boundary";
import useDynamicStyle from "@/hooks/useDynamicStyle";
import { $event } from "./utils/events/eventEmitter";

const renderRouteNode = (routes: RouteArray) => {
  const { element: Component, path, children } = routes;

  if (!children) {
    return (
      <Route
        key={path}
        element={
          <ErrorBoundary variant={ErrorBoundaryVariant.page}>
            <Component />
          </ErrorBoundary>
        }
        path={path}
      />
    );
  } else
    return (
      <Route key={path} element={<Component />} path={path}>
        {children.map((route) => renderRouteNode(route))}
      </Route>
    );
};

type PossibleUrlMatch = `/${RavenProducts}-`;

const possibleSlugs: PossibleUrlMatch[] = [
  "/atlas-",
  "/business-",
  "/personal-",
  "/pos-",
  "/partners-",
];

function App() {
  const location = useLocation();
  const { productTheme, setProductTheme } = useProductTheme();
  const [loading, setLoading] = useState(process.env.NODE_ENV === "development");
  const { pathname } = useLocation();

  const token = localStorage.getItem("token");

  useEffect(() => {
    let url: RavenProducts = "personal";

    possibleSlugs.forEach((slug) => {
      if (pathname.includes(slug)) {
        // eslint-disable-next-line no-useless-escape
        url = slug.replace(/[\/-]/g, "") as RavenProducts;
      }
    });

    setProductTheme(url);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const color = getThemePrimaryColor();

    const styles = document.body.style;

    const sideBarTheme = `var(--sidebar-${productTheme}-gradient)`;

    styles.setProperty("--primary-product-color", color);
    styles.setProperty("--sidebar-gradient", sideBarTheme);
  }, [productTheme]);

  useEffect(() => {
    setTimeout(() => {
      // livechat();
    }, 2000);
  }, []);

  // const updateExportData = (data: Partial<ExportType>): void => {
  //   setExportValue((prev) => ({
  //     ...prev,
  //     ...data,
  //   }));
  // };
  const { setVisible, isVisible, setExportValue, onSubmit, title, meta, exportValue } =
    useExportModal();

  useDynamicStyle()
  return (
    <Provider store={store}>
      <RavenToast position="top-left" />
      <div className="App">
        <Helmet>
          <title>Raven Bugiss || Internal Management System </title>
          <meta
            name="description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            property="og:description"
            content="All-in-one banking suite for businesses of all sizes, Transfer, Payments, Invoices, Payroll."
          />
          <meta
            name="keywords"
            content="bank, banking, loan, savings, roi, Transfer, Invoice, payroll,  interest, nigeria, africa, fintech"
          />
          <meta name="page-topic" content="Banking" />
          <meta name="page-type" content="Landing" />
          <meta name="audience" content="Everyone" />
          <meta
            property="og:image"
            content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
          />
          <meta property="og:url" content="https://getravenbank.com" />
          <meta name="robots" content="index, follow" />
          <meta
            property="twitter:image:src"
            content="https://pbs.twimg.com/profile_images/1485498638973517826/aX6F2jHy_400x400.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="GetRavenBank" />
          <meta name="twitter:site" content="https://www.twitter.com/theravenbank" />
          <meta
            httpEquiv="Content-Security-Policy"
            content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
          />
        </Helmet>

        <>
          <Routes location={location}>
            <Route
              path="/"
              element={<Navigate to={token ? "/personal-overview" : "/login"} />}
            />
            <Route path="*" element={<h1>404 Not Found</h1>} />
            {/* auth group start */}
            {auth_routes_group?.map((route, idx) => (
              <Route key={idx} path={route.path} element={<route.element />} />
            ))}

            {dashboard_routes_group.map((route, idx) => renderRouteNode(route))}
            {/* dashboard group end */}
          </Routes>
        </>
        <RavenToast />
      </div>
      {/* Prevent glitching of sidebar */}
      {loading && (
        <div
          style={{ position: "fixed", zIndex: "9999", inset: 0, background: "#fff" }}
        />
      )}
      {/** Used to render modals out of their normal html placement */}
      <div id={RENDER_DYNAMIC_MODAL_ID} />
      <div id={PERSONAL_EXPORT_MODAL_ID} />
      <ExportModal
        visible={isVisible}
        onClose={() => setVisible(false)}
        onChange={(e: ExportType) => {
          setExportValue(e)

          $event.emit('onExportFilterChange', e)
        }}
        onClick={onSubmit}
        value={exportValue as any}
        title={title}
        business_id={meta?.biz}
      />
    </Provider>
  );
}

export default App;
