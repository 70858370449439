import { partnersIcons } from "@/assets/icons/partnersIcons";

export const partnersSideMenuList: SidebarMenuDataList = [
  {
    name: "Main Menu",
    numb: "one",
    icon: "",
    link: "",
    children: [
      {
        name: "Overview",
        link: "/partners-overview",
        icon: partnersIcons.sidebar_overview,
        activeIcon: partnersIcons.sidebar_overview_active,
      },

      {
        name: "Insights",
        link: "/partners-insights",
        icon: partnersIcons.sidebar_insights,
        activeIcon: partnersIcons.sidebar_insights_active,
      },
      {
        name: "Partners",
        link: "/partners-partners",
        icon: partnersIcons.sidebar_partners,
        activeIcon: partnersIcons.sidebar_partners_active,
      },
      {
        name: "Partner’s Merchants",
        link: "/partners-merchants",
        icon: partnersIcons.sidebar_partners_merchants,
        activeIcon: partnersIcons.sidebar_partners_merchants_active,
      },
      {
        name: "Transactions",
        link: "/partners-transactions",
        icon: partnersIcons.sidebar_transactions,
        activeIcon: partnersIcons.sidebar_transactions_active,
      },
      {
        name: "Fee Details",
        link: "/partners-fee-details",
        icon: partnersIcons.sidebar_fee_details,
        activeIcon: partnersIcons.sidebar_fee_details_active,
      },
    ],
  },
  {
    name: "Features",
    numb: "two",
    icon: "",
    link: "",
    children: [
      {
        name: "Terminals",
        link: "/partners-terminals",
        icon: partnersIcons.sidebar_terminals,
        activeIcon: partnersIcons.sidebar_terminals_active,
      },
      {
        name: "Bills",
        link: "/partners-bills",
        icon: partnersIcons.sidebar_bills,
        activeIcon: partnersIcons.sidebar_bills_active,
      },
      // {
      //   name: "Settlements",
      //   link: "/partners-settlements",
      //   icon: partnersIcons.sidebar_payment_links,
      //   activeIcon: partnersIcons.sidebar_payment_links_active,
      // },
      {
        name: "Device Requests",
        link: "/partners-device-requests",
        icon: partnersIcons.sidebar_device_requests,
        activeIcon: partnersIcons.sidebar_device_requests_active,
      },
      {
        name: "Settlements",
        link: "/partners-settlements",
        icon: partnersIcons.sidebar_settlements,
        activeIcon: partnersIcons.sidebar_settlements_active,
      },
    ],
  },
  {
    name: "Support",
    numb: "three",
    icon: "",
    link: "",
    children: [
      {
        name: "Compliance",
        link: "/partners-compliance",
        icon: partnersIcons.sidebar_compliance,
        activeIcon: partnersIcons.sidebar_compliance_active,
      },
      {
        name: "Audit Trails",
        link: "/partners-audit-trails",
        icon: partnersIcons.sidebar_audit_trails,
        activeIcon: partnersIcons.sidebar_audit_trails_active,
      },
      {
        name: "Disputes",
        link: "/partners-disputes",
        icon: partnersIcons.sidebar_disputes,
        activeIcon: partnersIcons.sidebar_disputes_active,
      },
    ],
  },
];
