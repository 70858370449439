export function getLastThreeMonthsAndCurrentDate() {
  const currentDate = new Date();
  const formattedCurrentDate = formatDate(currentDate);

  const pastDate = new Date();
  pastDate.setMonth(pastDate.getMonth() - 3);
  const formattedPastDate = formatDate(pastDate);

  return {
    currentDate: formattedCurrentDate,
    pastDate: formattedPastDate
  };
}

function formatDate(date:Date) {
  const year = date.getFullYear();
  const month = String(date.getDay() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


