import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import React from "react";
import { PBVirtualCardFundingTable } from "./tables";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { TransferEmptyScreen } from "@/components/common/shell-layouts";
import { mapTransfersPagination, useSmartFilterProps } from "@/hooks/useSmartFilterProps";
import { GLOBAL_PER_PAGE } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { usePhysicalPersonalCardFilter } from "../../../internal-hooks/cards";
import SmartFilter from "@/components/common/smartFilter";
import { useParams } from "react-router-dom";

const PBVirtualCardFunding = () => {
  const { filterArgs, filterProps, pagination } = usePhysicalPersonalCardFilter();
  const { card_id } = useParams();
  const { data, isFetching, isLoading, refetch } =
    personalReduxAPI.useGetVirtualCardFundingQuery({
      page: pagination?.currentPage,
      per_page: GLOBAL_PER_PAGE,
      status: filterArgs.status,
      search: pagination.searchQuery,
      card_id: card_id,
      end_date: pagination.filterValue.endDate,
      start_date: pagination.filterValue.startDate,
    });

  // const { SmartFilterProps } = useSmartFilterProps(
  //   mapTransfersPagination(data as any),
  //   pagination
  // );

  const joinedLoader = isLoading || isFetching;

  const redactedFn = renderRedactedComponent(joinedLoader);

  return (
    <DashboardLayout>
      <PageLayout pageTitle="Card Funding">
        <>
          <SmartFilter
            searchTitle="Search by card pan or email"
            // {...SmartFilterProps}
            {...filterProps}
            hideExport
            // tableExport={{ data: data as any }}
          />
          {!data || isLoading || data.length === 0 ? (
            <TransferEmptyScreen loading={joinedLoader} />
          ) : (
            <PBVirtualCardFundingTable
              ctx="page"
              data={data as any}
              onRefetch={refetch}
              redactedFn={redactedFn}
            />
          )}
        </>
      </PageLayout>
    </DashboardLayout>
  );
};

export default PBVirtualCardFunding;
