import env from "@/env";
import { PosBuilder, posGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods"; // Adjust the path as needed
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const PosTerminalsApi = (builder: PosBuilder) => {
  return {
    getTerminals: createQueryWithRoute<PosTerminalsModel, Partial<PosPaginationParams>>(builder, "terminals", { name: "getTerminals" }),

    getTerminalsExport: createQueryWithRoute<
      PosTerminalsModel,
      Partial<PosPaginationParams>
    >(builder, "terminals", { export: true, name: "getTerminalsExport" }),

    getATerminal: builder.query<SinglePosTerminal, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler({ route: `terminal/${params?.id}` }, thunk);
      }
    }),

    getMerchants: createQueryWithRoute<PosMerchantsModel, Partial<PosPaginationParams>>(builder, "users", { name: "getMerchants" }),

    getMerchantsExport: createQueryWithRoute<
      PosMerchantsModel,
      Partial<PosPaginationParams>
    >(builder, "users", { export: true, name: "getMerchantsExport" }),

    getAMerchant: builder.query<PosMerchants, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler({ route: `users/${params.id}`, params }, thunk);
      },
    }),

    getTerminalRequests: createQueryWithRoute<PosDeviceRequestsModel, any>(builder, "terminal/requests", { name: "getTerminalRequests" }),

    getTerminalRequestsExport: createQueryWithRoute<PosDeviceRequestsModel, any>(builder, "terminal/requests", {
      export: true,
      name: "getTerminalRequestsExport"
    }),

    getAlertRecievers: builder.query<PosAlertRecievers[], any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `terminal/alert/detail/`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
