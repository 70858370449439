import env from "@/env";
import { APIResponseHandler } from "@/utils/api";
import { filterAPIQueryParams } from "@/utils/helpers";
import { BaseQueryApi, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CreateBuilderContext } from "../..";
import { AllPartnersApi } from "./partners/api";
import PartnersApi from "@/utils/axios/partners";
import { PosCollectionsApi } from "./collections/api";
import { PartnersSettlementApi } from "./settlement/api";
import { PosDailySummaryApi } from "./dailySummary/api";
import { PartnersFeesApi } from "./fees/api";
import { PosAuditTrailApi } from "./auditTrail/api";
import { PosBillsApi } from "./bills/api";
import { PartnersTransactionsApi } from "./transactions/api";
import { PartnersMerchantsApi } from "./merchants/api";
import { toggleRedact } from "@/redux/actions/actions";
import { createExportEndpoint } from "./util";
import { PosHniApi } from "./hni/api";
import { PartnersOverviewApi } from "./overview/api";
import { PartnersTerminalsApi } from "@/redux/slices/partners/apis/terminals/api";

export const PartnersTagTypes = ["PartnersApi.getMerchants"] as const;

/**`start_date` `end_date` `per_page` `page` `search` */
export type PartnersBaseParams = {
  start_date: IsUncertain<string>;
  end_date: IsUncertain<string>;
  page_size: SN;
  page: SN;
  search?: string;
  email?: string;
};

// "pagination": {
//   "perPage": 200,
//   "currentPage": 1,
//   "nextPage": null,
//   "prevPage": null,
//   "totalPages": 1,
//   "total": 151
// }

type TagTypes = (typeof PartnersTagTypes)[number];

export const partnersReduxAPI = createApi({
  reducerPath: "partners-api",
  baseQuery: fetchBaseQuery({ baseUrl: env.partners_api }),
  tagTypes: PartnersTagTypes,
  endpoints: (builder) => ({
    ...AllPartnersApi(builder),
    ...PosCollectionsApi(builder),
    ...PartnersSettlementApi(builder),
    ...PosDailySummaryApi(builder),
    ...PartnersFeesApi(builder),
    ...PosAuditTrailApi(builder),
    ...PosBillsApi(builder),
    ...PartnersTransactionsApi(builder),
    ...PartnersMerchantsApi(builder),
    ...PosHniApi(builder),
    ...PartnersOverviewApi(builder),
    ...PartnersTerminalsApi(builder),
  }),
});

export const partnersExportAPI = createApi({
  reducerPath: "partnersExportAPI",
  baseQuery: fetchBaseQuery({ baseUrl: env.partners_api }),
  endpoints: (builder) => ({
    ...createExportEndpoint("getAirtimeRecords", partnersReduxAPI)(builder),
    ...createExportEndpoint("getDataRecords", partnersReduxAPI)(builder),
    ...createExportEndpoint("getCableRecords", partnersReduxAPI)(builder),
    ...createExportEndpoint("getElectricityRecords", partnersReduxAPI)(builder),
    ...createExportEndpoint("getBettingRecords", partnersReduxAPI)(builder),
    // Add other endpoints here as needed
  }),
});

export type PartnersBuilder = CreateBuilderContext<TagTypes, "partners-api">;

interface GetHandlerArgs<T> {
  route: string;
  params?: T;
  baseUrl?: string;
  objectKey?: string;
  mutateData?: (data: any) => any;
}

export const createPartnersURL = (route: string, search: IsUncertain<SN>) => {
  if (search) return `${route}/search`;
  return route;
};

export const partnersGetHandler = async <T extends Record<string, IsUncertain<SN>>>(
  args: GetHandlerArgs<T>,
  thunk?: BaseQueryApi
) => {
  const { params = {}, route, baseUrl = "", objectKey, mutateData } = args;

  thunk && thunk?.dispatch(toggleRedact(true));
  const _fakeMutate = (data: any) => data;
  const p: any = params;

  const finalRoute = createPartnersURL(route, p.search);

  const mutateFunc = mutateData ?? _fakeMutate;

  try {
    const response = await PartnersApi.get<APIResponse<any>>(`${baseUrl}/${finalRoute}`, {
      params: filterAPIQueryParams(params),
    });

    APIResponseHandler.tryHandler({
      response,
      dispatch: thunk?.dispatch,
    });

    if (objectKey) return { data: mutateFunc(response.data.data[objectKey]) };
    thunk && thunk?.dispatch(toggleRedact(false));

    return { data: mutateFunc(response.data.data) };
  } catch (error) {
    APIResponseHandler.catchHandler(error);
    throw error;
  }
};
