import React from "react";
import "./style/index.css";
import rewardGift from "@/assets/images/reward_gift.png";
import { getRewardIcons, ranks } from "@/apps/personal/rewards";
import { personalIcons } from "@/assets/icons/personal-icons";
import TabComponent from "@/components/common/Tabs";
import { Column, Row } from "@/components/common/stacks";
import { AvatarWithTwinValue } from "@/components/common/avatar";
import { trimLongString } from "@/utils/helper/Helper";
import { formatNumberToCurrency } from "@/utils/helpers";
import EmptyScreen from "@/components/common/emptyScreen";

interface RewardGridItemProps {
  icon: React.ReactNode;
  bgColor: "green" | "blue" | "orange" | "light-blue";
  title: string;
  amount: string;
  transactions: number;
}

const RewardGridItem: React.FC<RewardGridItemProps> = ({
  icon,
  bgColor,
  title,
  amount,
  transactions,
}) => (
  <div className={`reward-rank__grid-item reward-rank__grid-item--${bgColor}`}>
    <div className={`reward-rank__grid-icon `}>{icon}</div>
    <h3>{title}</h3>
    <h2>{amount}</h2>
    <p>{transactions} Transactions</p>
  </div>
);

const tabs = ["Insight", "Referrals"];

const RewardRankOffset: React.FC<{
  rank: string;
  insight: RewardInsight;
  referral: RewardReferral[];
}> = ({ rank, insight, referral }) => {
  function getRewardSection(label: string) {
    return insight?.sections.find((section) => section.label.includes(label));
  }

  const gridItems: RewardGridItemProps[] = [
    // { icon: personalIcons.reward_money_sent, bgColor: 'green', title: 'MONEY SENT', amount: '₦10,000.00', transactions: 129 },
    {
      icon: personalIcons.reward_savings,
      bgColor: "blue",
      title: "SAVINGS",
      amount: formatNumberToCurrency(getRewardSection("Savings")?.reward || 0, "NGN"),
      transactions: getRewardSection("Savings")?.count || 0,
    },
    {
      icon: personalIcons.reward_bills,
      bgColor: "orange",
      title: "AIRTIME",
      amount: formatNumberToCurrency(getRewardSection("Airtime")?.reward || 0, "NGN"),
      transactions: getRewardSection("Airtime")?.count || 0,
    },
    {
      icon: personalIcons.reward_bills,
      bgColor: "orange",
      title: "DATA",
      amount: formatNumberToCurrency(getRewardSection("Data")?.reward || 0, "NGN"),
      transactions: getRewardSection("Data")?.count || 0,
    },
    {
      icon: personalIcons.reward_refferals,
      bgColor: "light-blue",
      title: "REFERRALS",
      amount: formatNumberToCurrency(getRewardSection("Referrals")?.reward || 0, "NGN"),
      transactions: getRewardSection("Referral")?.count || 0,
    },
  ];

  return (
    <div className="reward-rank">
      <Column gap={0} className="reward-rank__header">
        <div className="reward-rank__title">
          <h2>REWARD RANK</h2>
          <h1>{trimLongString(rank, 40)}</h1>
        </div>
        <Row ai="center" gap={0.8} className="reward-rank__balance">
          {/* <span>#5</span> */}
          <p>Balance: {formatNumberToCurrency(insight?.reward_balance || 0, "NGN")}</p>
        </Row>
        <div className="reward-rank__icon">{getRewardIcons(rank)}</div>
      </Column>

      <TabComponent tabs={tabs} defaultValue={tabs[0]}>
        <div className="reward-rank__tabs">
          <TabComponent.Tabs tabs={tabs} />
        </div>
        <TabComponent.Content value={tabs[0]}>
          <div className="reward-rank__content">
            <div className="reward-rank__card reward-rank__card--earnings">
              <h3>ALL TIME EARNINGS</h3>
              <h2>{formatNumberToCurrency(insight?.all_time_reward || 0, "NGN")}</h2>
              <div className="reward-rank__gift-icon">
                <img src={rewardGift} alt="Reward Gift" />
              </div>
            </div>

            {/* <div className="reward-rank__card">
          <h3>TOTAL WITHDRAWN</h3>
          <h2>₦37,940.00</h2>
        </div>
         */}
            <div className="reward-rank__grid">
              {gridItems.map((item, index) => (
                <RewardGridItem key={index} {...item} />
              ))}
            </div>
          </div>
        </TabComponent.Content>

        <TabComponent.Content className="reward-rank__content" value={tabs[1]}>
          {!referral?.length ? (
            <EmptyScreen />
          ) : (
            referral?.map((item, index) => (
              <Row ai="center" className="reward-rank__content-referrals">
                <AvatarWithTwinValue
                  name={item.username}
                  one={`@${item.username}`}
                  two={item.email}
                />

                <h2 className="reward-rank__referrals">
                  {formatNumberToCurrency(item.reward, "NGN")}
                </h2>
              </Row>
            ))
          )}
        </TabComponent.Content>
      </TabComponent>
    </div>
  );
};

export default RewardRankOffset;
