import { RavenModal, RavenTable, toast } from "@ravenpay/raven-bank-ui";
import { BillsTable, ReversalTransfersTable } from "../tables/PosTables";
import { ReactElement, useState } from "react";
import SmartFilter from "@/components/common/smartFilter";
import usePosGetQuery from "@/apps/pos/__internals__/hooks/usePosGetQuery";
import { Util } from "@/utils/utility";
import { isEmpty } from "@/apps/pos/__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { posReduxAPI } from "@/redux/slices/pos/apis";
import { formatNumberToCurrency } from "@/utils/helpers";
import ActionModal from "@/components/common/modal/ActionModal";
import { sendRequest } from "@/utils/api-methods";
import env from "@/env";
import { cn } from "@/utils/colorConvert";
import { capitalizeFirstLetter } from "@/utils/helper/Helper";

const AirtimeHeader = [
  "MERCHANT DETAILS",
  "REFERENCE",
  "PROVIDER",
  "AMOUNT",
  "TRANSACTION DATE",
  "ACTION",
  "",
];
export const PosReversalBillsAirtimePage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "betting" });

  const airtime = tableData;
  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(airtime) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(airtime).map((bill, idx) => (
          <BillsTable.AirtimeData
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

export const PosReversalBillsDataPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "data" });

  const bundles = tableData;
  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(bundles) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable headerList={AirtimeHeader}>
        {Util.safeArray(bundles).map((bill, idx) => (
          <BillsTable.AirtimeData
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

export const PosReversalBillsElectricityPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "electricity" });
  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "REFERENCE",
          "PROVIDER",
          "AMOUNT",
          "TRANSACTION DATE",
          "ACTION",
          "",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Electricity
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

export const PosReversalBillsCablePage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "cable" });
  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "REFERENCE",
          "PROVIDER",
          "AMOUNT",
          "TRANSACTION DATE",
          "ACTION",
          "",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Cable
            key={idx}
            {...bill}
            isRedacting={isRedacting}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

export const PosReversalBillsBettingPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "betting" });

  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "REFERENCE",
          "PROVIDER",
          "AMOUNT",
          "TRANSACTION DATE",
          "ACTION",
          "",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <BillsTable.Betting
            key={idx}
            {...bill}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
            isRedacting={isRedacting}
            loading={combinedLoading}
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

export const PosReversalTransferPage = () => {
  const { tableData, isRedacting, combinedLoading, SmartFilterProps } =
    usePosGetQuery<ReversalCenterAirtime>("getReversals", { type: "transfer_out" });
  const [modalAction, setModalAction] =
    useState<Omit<RefundDiscardModalProp, "onMutate">>();
  return renderWithSmartFilter(
    isEmpty(tableData) ? (
      <EmptyScreen loading={combinedLoading || isRedacting} />
    ) : (
      <RavenTable
        headerList={[
          "MERCHANT DETAILS",
          "REFERENCE",
          "RECIPIENT",
          "AMOUNT",
          "TRANSACTION DATE",
          "ACTION",
        ]}
      >
        {Util.safeArray(tableData).map((bill, idx) => (
          <ReversalTransfersTable
            key={idx}
            id={0}
            onAction={(e) =>
              setModalAction((d) => ({
                ...d,
                ctx: e.ctx,
                id: e.id,
              }))
            }
            email={Util.safeValue(bill?.email)}
            amount={formatNumberToCurrency(bill?.amount, "ngn")}
            account_number={Util.safeText(bill?.account_number)}
            account_name={Util.safeText(bill?.account_name)}
            bank={Util.safeText(bill?.bank)}
            action={Util.safeText(bill?.action)}
            reference={Util.safeValue(bill?.reference)}
            created_at={`${new Date()}` as any}
          />
        ))}
      </RavenTable>
    ),
    {
      onMutate: setModalAction as any,
      ctx: modalAction?.ctx as any,
      id: modalAction?.id as string,
    },
    SmartFilterProps
  );
};

function renderWithSmartFilter(
  node: ReactElement,
  modalAction: RefundDiscardModalProp,
  SmartFilterProps: any
): ReactElement {
  return (
    <>
      <SmartFilter {...SmartFilterProps} />
      {node}

      <RefundDiscardModal {...modalAction} />
    </>
  );
}

type RefundDiscardModalProp = {
  onMutate: (e?: any) => void;
  id: string;
  ctx: "refund" | "discard";
};

const RefundDiscardModal = (props: RefundDiscardModalProp) => {
  const [isReqn, setIsReqn] = useState(false);

  const { onMutate, id } = props;

  async function handleDiscard() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      `pending_reversals/discard/${cn(id)}`,
      {
        id: id,
      },
      { base_url: env.pos_api?.replace("v1", "v2") }
    );
    if (resp?.status === "success") {
      onMutate({
        lien: false,
      });

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }

  async function handleRefund() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest(
      `pending_reversals/refund/${cn(id)}`,
      {
        id: id,
      },
      { base_url: env.pos_api?.replace("v1", "v2") }
    );
    if (resp?.status === "success") {
      onMutate({
        lien: false,
      });

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }
  return (
    <ActionModal
      visible={Boolean(props.ctx)}
      onCancel={() => onMutate({})}
      onClick={props?.ctx === "discard" ? handleDiscard : handleRefund}
      cancelText="No, Cancel"
      actionText={cn("Yes,", capitalizeFirstLetter(props.ctx))}
      loading={isReqn}
      body={cn(
        "Are you sure you want to",
        props.ctx,
        "this transaction ? This action is irreversible"
      )}
      btnColor="red"
      title={cn(capitalizeFirstLetter(props.ctx), "Transaction")}
    />
  );
};
