import InlineDetails, { InlineDetailsContent } from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { GENERAL_CLASSES } from "@/constants";
import { cn } from "@/utils/colorConvert";
import { replaceSymbolInText } from "@/utils/formatWord";
import { formatDateTime, formatMetaData, formatStatus } from "@/utils/helper/Helper";
import { formatNumberToCurrency, TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import TabComponent from "@/components/common/Tabs";

interface Props {
  transaction: TransactionMonitored['transaction'];
  user: TransactionMonitored['user'];
  log?: {
    status: string;
    risk_score:string;
    date: string | Date;
    reason: string;
    id: string;
  }
}

export const PersonalTransactionMonitoringModal = (props: CreateDetailsProps<Props> & {onClick:Function}) => {
  const { onCancel, transaction, log, user, onClick } = props;

  const { amount, email, reference, description, type, fee, created_at } = transaction;

  const Content: InlineDetailsContent[] = [
    { label: "Amount", value: replaceSymbolInText(amount) },
    { label: "Balance Before", value: formatNumberToCurrency(transaction.b_before ?? 0) },
    { label: "Balance After", value: formatNumberToCurrency(transaction.b_after ?? 0) },
    { label: "Recipient", value: Util.safeText(email) },
    { label: "Description", value: Util.safeText(description) },
    { label: "Type", value: Util.safeText(type) },
    { label: "Fee", value: fee ? formatNumberToCurrency(fee) : "--" },
    { label: "Reference ID", value: Util.safeText(reference), newCopy: true },
    { label: "Date Logged", value: formatDateTime(created_at) },
  ];

  const userInline: InlineDetailsContent[] = [
    { label: "First Name", value: (user?.fname) },
    { label: "Last Name", value: (user?.lname ) },
    { label: "Phone Number", value: (user?.phone) },
    { label: "Username", value: Util.safeText(user?.username) },
  ];

  const logInline: InlineDetailsContent[] = [
    { label: "Status", value: formatStatus(log?.status as string) },
    { label: "Risk Score", value: (log?.risk_score ?? 0) },
    { label: "Date Logged", value: formatDateTime(log?.date as string) },
    { label: "Reason for Flag", value: log?.reason },

  ];
const Tabs = ["Flag Details", "User", "Transaction"];
  return (
    <ActionModal
      visible
      onCancel={onCancel}
      onClick={() => {
        onCancel();
        onClick(log?.id)
      }}
      title="Transaction Details"
      hideCancel
      btnColor="#1ACE37"
      btnTextColor="#FAFAFA"
      // DISABLING download receipt for now
      actionText="Unflag Transaction"
      hideNormalButton={log?.status === "cleared"}
      className={cn(GENERAL_CLASSES.BIG_MODAL, "pb-transaction-dt-modal")}
    >

      <TabComponent defaultValue={Tabs[0]}>

        {  <TabComponent.Tabs
          tabs={Tabs}

          className="pb-insights-sidebar__tab "
        />}
        <TabComponent.Content
          className=""
          value={Tabs[2]} // Set dynamic value here based on tab name
          key={Tabs[2]}
        >
          <div className={cn(GENERAL_CLASSES.INLINE_DETAILS.WRAPPER)}>
            <InlineDetails
              title="Transaction Details"
              simple
              className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
              amount={0}
              content={Content}
            />
          </div>
        </TabComponent.Content>

        <TabComponent.Content
          className=""
          value={Tabs[1]} // Set dynamic value here based on tab name
          key={Tabs[1]}
        >
          <div className={cn(GENERAL_CLASSES.INLINE_DETAILS.WRAPPER)}>
            <InlineDetails
              title="Transaction Details"
              simple
              className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
              amount={0}
              content={userInline}
            />
          </div>
        </TabComponent.Content>

        <TabComponent.Content
          className=""
          value={Tabs[0]} // Set dynamic value here based on tab name
          key={Tabs[0]}
        >
          <div className={cn(GENERAL_CLASSES.INLINE_DETAILS.WRAPPER)}>
            <InlineDetails
              title="Transaction Details"
              simple
              className={cn(GENERAL_CLASSES.INLINE_DETAILS.NO_SHADOW, "small-padding")}
              amount={0}
              content={logInline}
            />
          </div>
        </TabComponent.Content>
      </TabComponent>


    </ActionModal>
  );
};
