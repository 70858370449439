import "./style/index.css";
import ImagePreview from "@/components/common/imagePreview";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { renderRedactedComponent } from "@/components/common/shimmer/redact";
import { XStack } from "@/components/common/stacks";
import { GENERAL_CLASSES } from "@/constants";
import { personalReduxAPI } from "@/redux/slices/personal/api";
import { cn } from "@/utils/colorConvert";
import { formatWord } from "@/utils/formatWord";
import { TypeIs } from "@/utils/helpers";
import { Util } from "@/utils/utility";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import TabComponent from "@/components/common/Tabs";
import { icons } from "@/assets/icons";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import TransferStatusMetrics from "@/apps/pos/insights/components/transferStatusMetrics";

export interface ApproveManualAddressProps {
  view: ManualCompliance;
  setView: (view?: ManualCompliance) => void;
  showRevokeModal(id: SN): void;
  handleVerification(): void;
  tab: string;
}

export const ApproveManualBvn = (props: ApproveManualAddressProps) => {
  const { setView, showRevokeModal, view, handleVerification, tab } = props;
  const { isLoading, data, isFetching } = personalReduxAPI.useGetManualBvnInfoQuery({
    bvn: view.token,
    email: view.email,
  });

  const joinedLoader = isLoading || isFetching;
  const { RedactedTable, RenderCustom } = renderRedactedComponent(joinedLoader);

  const getButtonText = () => {
    if (!view) return "--";

    if (view.status === "pending") return "Verify User";
    return "Close Modal";
  };

  const generateBvnView = () => {
    if (!view) return {};

    const info = data?.info;

    return {
      bvn: RedactedTable(cn(Util.safeValue(data?.token))),
      name: RedactedTable(
        cn(
          Util.safeText(info?.firstname),
          Util.safeText(info?.middlename),
          Util.safeText(info?.lastname)
        )
      ),
      residential_address: RedactedTable(Util.safeText(info?.residentialAddress)),
      lga_of_residence: RedactedTable(Util.safeText(info?.lga_of_residence)),
      state_of_residence: RedactedTable(Util.safeText(info?.state_of_residence)),
      phone: RedactedTable(Util.safeText(info?.phone_number)),
      gender: RedactedTable(Util.safeText(info?.gender)),
      email: RedactedTable(Util.safeText(info?.email)),
      dob: RedactedTable(Util.safeText(info?.date_of_birth)),
      // landmark: RedactedTable(Util.safeText(landmark)),
      // email: RedactedTable(Util.safeText(email)),
      // document_type: RedactedTable(Util.safeText(document_type))
    };
  };

  const tabs = ["Details", "Image"];
  return (
    <ActionModal
      visible={Boolean(view)}
      onCancel={() => setView()}
      altCancelFunc={() => {
        setView();
        showRevokeModal(view.id);
      }}
      onClick={handleVerification}
      actionText={getButtonText()}
      btnColor="var(--primary-product-color)"
      className={cn("address-verification-modal ", GENERAL_CLASSES.SCROLLABLE_MODAL)}
      cancelText={TypeIs.any("Reject Verification")}
      title={`Verify ${formatWord(tab).toUpperCase()} Manually`}
      loading={joinedLoader}
      hideCancel={joinedLoader}
    >
      {/*Inline Style for Modal Button*/}
      <style>
        {`
        .bl-modal__action-btn-contain {
        flex-direction: column !important;
        width: 100%;
        }
        
        .bl-modal__action-btn-contain button{
        width: 100% !important;
        }
        
        .general-action-modal .raven-modal-content-wrap {
        width: 50rem !important;
        }
        `}
      </style>

      {/*End of Inline Style for Modal Button*/}
      <div className="biz-compliance-results w-full">
        <TabComponent className={"w-full"} defaultValue={tabs[0]}>
          <TabComponent.Tabs tabs={tabs} className="pb-insights-sidebar__tab w-full" />

          <TabComponent.Content className="w-full pt-4" value={tabs[1]} key={tabs[1]}>
            {RenderCustom(
              <ImagePreview
                imgData={[
                  {
                    src: TypeIs.any(data?.image),
                    alt: "Image 1",
                    containerClassName: "biz-compliance-results__image-preview w-full",
                  },
                  {
                    src: TypeIs.any(data?.info?.photo),
                    alt: "Image 2",
                    containerClassName: "biz-compliance-results__image-preview w-full",
                  },
                ]}
              />,
              { height: 278, width: 350, className: "shimmer redacted curved mt-20" }
            )}
          </TabComponent.Content>

          <TabComponent.Content value={tabs[0]} key={tabs[0]} className="mt-20 w-full">
            <div className="compliance-results__main flex flex-row w-full">
              <InlineDetails
                direction={""}
                title={"Transaction Details"}
                className="no-shadow w-full no-padding p-0"
                simple
                amount={0}
                onClick={() => {}}
                content={Object.entries(generateBvnView()).map(([key, value]) => ({
                  label: formatWord(key),
                  value,
                }))}
              />
            </div>
          </TabComponent.Content>
        </TabComponent>
      </div>
    </ActionModal>
  );
};
