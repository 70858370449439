import "./style/index.css";
import { icons } from "@/assets/icons";
import { cn } from "@/utils/colorConvert";
import { RavenButton } from "@ravenpay/raven-bank-ui";

interface IconButtonProps extends IClass {
  icon: keyof typeof icons;
  text?: string;
  borderColor?: string;
  onClick?: () => void;
}

export const IconButton = (props: IconButtonProps) => {
  const { className, icon, text, borderColor, onClick } = props;

  return (
    <RavenButton
      style={{ borderColor: borderColor ?? "#ACACAC" }}
      className={cn("action-button-row-button", className)}
      color="white-light"
      onClick={onClick}
    >
      <figure className="action-button-row-button--icon">{icons[icon]}</figure>
      {text && <p>{text}</p>}
    </RavenButton>
  );
};
