import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import { CreateTableRowProps } from "../shared";
import { cn } from "@/utils/colorConvert";
import { formatDateTime, formatStatus, IconVault, symbol } from "@/utils/helper/Helper";
import { ConditionalCopyText } from "@/components/common/copyCheck";
import { Util } from "@/utils/utility";
import { ClickAbleEmail } from "@/components/common/clickable-row";
import { STRING_TRIM_LENGTH } from "@/constants";
import Dropdown from "@/components/common/dropdown";
import ActionDrop from "@/components/common/actionDrop/ActionDrop";
import { icons } from "@/assets/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FreezeOrUnfreezeCardModal } from "@/apps/personal/__internals__/combined-tables/cards/modals/modals";
import { useDynamicModal } from "@/hooks/useDynamicModal";
import { iife } from "@/utils/general";
import { getPersonalVirtualCardStatus } from "./utils";

type Props = CreateTableRowProps<{
  data: PersonalVirtualCardModel[];
  refetch: RefetchFn;
}>;

type PhsyicalCardProps = CreateTableRowProps<{
  data: PersonalPhysicalCardModel[];
  refetch: RefetchFn;
}>;

const FREEZE_CARD = {
  label: "Freeze Card",
  img: IconVault(icons.trash_03),
  ctx: "freeze",
};

const UNFREEZE_CARD = {
  label: "Unfreeze Card",
  img: IconVault(icons.file_download_02_alt),
  ctx: "unfreeze",
};

const dropdown = [
  { label: "View Details", img: IconVault(icons.arrow_circle_broken), ctx: "view" },
  { label: "View Funding", img: IconVault(icons.arrow_circle_broken), ctx: "funding" },
];

type CardActions = "freeze" | "unfreeze";

export type SelectedCard = {
  ctx: CardActions;
  card: PersonalVirtualCardModel | PersonalVirtualCardDetails;
};

const header = [
  "USER DETAILS",
  "CARD NAME",
  "CURR.",
  "CARD ID",
  "DATE CREATED",
  "STATUS",
  " ",
];

const physicalCardHeader = [
  "USER DETAILS",
  "CARD PAN",
  "CARD BRAND",
  "CARD ID",
  "COLOR",
  "DATE CREATED",
  "STATUS",
  " ",
];

const getDropdown = (status: number, type: "physical" | "virtual" = "virtual") => {
  if (type === "physical") {
    return [...dropdown];
  }
  if (status === 1) return [...dropdown, FREEZE_CARD];
  return [...dropdown, UNFREEZE_CARD];
};

export const PersonalVirtualCardsTable = (props: Props) => {
  const { ctx, data, redactedFn, refetch } = props;
  const { RedactedTable } = redactedFn;
  const [selectedRow, setSelectedRow] = useState<SelectedCard>();
  const navigate = useNavigate();

  const { renderDynamicModal } = useDynamicModal(selectedRow);

  const handleFreezeUnfreeze = (ctx: CardActions, card: PersonalVirtualCardModel) => {
    setSelectedRow({ ctx, card });
  };

  const HEADER = iife(() => {
    return ctx === "page" ? header : header.slice(1);
  });

  const handleClick = (ctx: string, card: PersonalVirtualCardModel) => {
    switch (ctx) {
      case "view":
        return navigate(`/personal-cards/virtual/${card.id}`);
      case "funding":
        return navigate(`/personal-cards/virtual-funding/${card.card_id}`);
      case "freeze":
      case "unfreeze":
        return handleFreezeUnfreeze(ctx, card);
    }
  };

  return (
    <RavenTable className="table-with-dropdown" headerList={HEADER}>
      {data.map((card) => {
        const { id, created_at, status, label, email, currency } = card;

        const cardStatus = getPersonalVirtualCardStatus(status);

        const RowOne =
          ctx === "page"
            ? RedactedTable(<ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />)
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            two={RedactedTable(Util.safeText(label))}
            three={RedactedTable(cn(symbol(currency), currency.toUpperCase()))}
            four={RedactedTable(<ConditionalCopyText text={card.card_id} />)}
            five={RedactedTable(Util.safeText(formatDateTime(created_at)))}
            six={RedactedTable(formatStatus(cardStatus))}
            seven={
              <Dropdown
                rightPosition
                items={getDropdown(status)}
                defaultValue={dropdown[0]}
                onChange={(e: any) => {
                  e?.event?.stopPropagation();
                  handleClick(e.ctx, card);
                }}
                buttonChild={() => <ActionDrop more />}
              />
            }
          />
        );
      })}

      {renderDynamicModal(
        selectedRow ? (
          <FreezeOrUnfreezeCardModal
            onCancel={() => setSelectedRow(undefined)}
            refetch={refetch as any}
            selectedCard={selectedRow}
          />
        ) : null
      )}
    </RavenTable>
  );
};

export const PersonalPhysicalCardsTable = (props: PhsyicalCardProps) => {
  const { ctx, data, redactedFn, refetch } = props;
  const { RedactedTable } = redactedFn;
  const [selectedRow, setSelectedRow] = useState<SelectedCard>();
  const navigate = useNavigate();

  const { renderDynamicModal } = useDynamicModal(selectedRow);

  const handleFreezeUnfreeze = (ctx: CardActions, card: PersonalVirtualCardModel) => {
    setSelectedRow({ ctx, card });
  };

  const HEADER = iife(() => {
    return ctx === "page" ? physicalCardHeader : physicalCardHeader.slice(1);
  });

  const handlePhysicalClick = (ctx: string, card: PersonalPhysicalCardModel) => {
    switch (ctx) {
      case "view":
        return navigate(`/personal-cards/physical/${card.id}`);
      case "freeze":
      case "unfreeze":
        return handleFreezeUnfreeze(ctx, card as any);
    }
  };

  return (
    <RavenTable className="table-with-dropdown" headerList={HEADER}>
      {data.map((card) => {
        const { id, created_at, status, email, masked_pan, card_brand, color } = card;

        const cardStatus = getPersonalVirtualCardStatus(status);

        const RowOne =
          ctx === "page"
            ? RedactedTable(<ClickAbleEmail email={email} trim={STRING_TRIM_LENGTH} />)
            : undefined;

        return (
          <RavenTableRow
            key={id}
            one={RowOne}
            two={RedactedTable(Util.safeText(masked_pan))}
            three={RedactedTable(Util.safeText(card_brand))}
            four={RedactedTable(<ConditionalCopyText text={card.id} />)}
            five={RedactedTable(Util.safeText(color))}
            six={RedactedTable(Util.safeText(formatDateTime(created_at)))}
            seven={RedactedTable(formatStatus(cardStatus))}
            eight={
              <Dropdown
                rightPosition
                items={getDropdown(Number(status), "physical")}
                defaultValue={dropdown[0]}
                onChange={(e: any) => {
                  e?.event?.stopPropagation();
                  handlePhysicalClick(e.ctx, card);
                }}
                buttonChild={() => <ActionDrop more />}
              />
            }
          />
        );
      })}

      {renderDynamicModal(
        selectedRow ? (
          <FreezeOrUnfreezeCardModal
            onCancel={() => setSelectedRow(undefined)}
            refetch={refetch as any}
            selectedCard={selectedRow}
          />
        ) : null
      )}
    </RavenTable>
  );
};
