import { PartnersBuilder, partnersGetHandler } from "@/redux/slices/partners/apis";
import { APIResponseHandler } from "../api";
import { BusinessAPI } from "../axios/business";
// import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { BaseQueryApi } from "@reduxjs/toolkit/query";

interface CreateQueryWithRouteOptions {
  export?: boolean;
  name?: string;
}

export const createQueryWithRoute = <
  TResponse,
  TParams extends Record<string, IsUncertain<SN>> | undefined
>(
  builder: PartnersBuilder, // Correct type for builder
  route: string,
  options: CreateQueryWithRouteOptions = {}
) => {
  const endpointName = options.name || route.split("/").pop();

  const normalEndpoint = builder.query<TResponse, TParams>({
    queryFn: async (params: TParams, thunk: BaseQueryApi) => {
      const { filterBy, filterValue, ...restParams } = params || {};

      return partnersGetHandler(
        {
          route,
          params: { ...restParams, ...(filterBy ? { [filterBy]: filterValue } : {}) },
        },
        thunk
      );
    },
    keepUnusedDataFor: 5, // Optional, just for example
    // TODO: revisit this implementation
    providesTags: [endpointName as any], // Optional, just for example
  });

  if (options.export) {
    return builder.query<TResponse, TParams>({
      queryFn: async (params: TParams, thunk: BaseQueryApi | undefined) => {
        return partnersGetHandler({ route: `${route}/export`, params }, thunk);
      },
      keepUnusedDataFor: 5, // Optional, just for example
      // TODO: revisit this implementation
      providesTags: [`${endpointName}Export` as any], // Optional, just for example
    });
  }

  return normalEndpoint;
};

export class BusinessAPIService {
  static async recheckTransferStatusInBulk(payload: SN[]) {
    try {
      const response = await BusinessAPI.post(
        "transfer/recheck_transfer_status_in_bulk",
        { transfer_ids: payload }
      );
      APIResponseHandler.tryHandler({ response });
      return response.data.data;
    } catch (error) {
      APIResponseHandler.catchHandler(error);
      throw error;
    }
  }

  static async markDeductedTransferAsPaid(id: SN) {
    try {
      const response = await BusinessAPI.post(`transaction/mark_deduction_as_paid`, {
        deduction_id: String(id),
      });
      APIResponseHandler.tryHandler({ response });
      return response.data.data;
    } catch (error) {
      APIResponseHandler.catchHandler(error);
      throw error;
    }
  }
}

interface RequestOptions {
  base_url?: string;
}

export async function sendRequest(url: string, data: any, options: RequestOptions = {}) {
  const { base_url } = options;
  const requestUrl = base_url ? `${base_url}${url}` : url;

  try {
    const response = await BusinessAPI.post(requestUrl, data);
    APIResponseHandler.tryHandler({ response });
    return response?.data ?? response;
  } catch (error) {
    APIResponseHandler.catchHandler(error);
    throw error;
  }
}
