import SmartFilter from "@/components/common/smartFilter";
import DashboardLayout from "@/components/layouts/dashboard/dashboardLayout";
import PageLayout from "@/components/layouts/dashboard/pageLayout";
import { RavenTable, RavenTableRow } from "@ravenpay/raven-bank-ui";
import useThemeMode from "@/hooks/useThemeMode";
import { cn } from "@/utils/colorConvert";
import Txreference from "@/components/common/reference/TxRef";
import TwinValue from "@/components/common/twinValue";
import { formatDateTime, formatMetaData, formatNumWithComma, formatStatus } from "@/utils/helper/Helper";
import { detectStatus, formatNumberToCurrency, renderConditionally } from "@/utils/helpers";
import usePartnersGetQuery from "../__internals__/hooks/usePartnersGetQuery";
import { isEmpty } from "../__internals__/assertIfEmpty";
import EmptyScreen from "@/components/common/emptyScreen";
import { Util } from "@/utils/utility";
import Redacted from "@/components/common/shimmer/redact";
import InlineDetails from "@/components/common/inlineDetails";
import ActionModal from "@/components/common/modal/ActionModal";
import { useState } from "react";
import PosApi from "@/utils/axios/pos";
import env from "@/env";


const PartnersTransactions = () => {
  const { tableData, SmartFilterProps, refetch, isRedacting, combinedLoading } =
    usePartnersGetQuery<PartnersTransactions>("getTransactions");
  const { isDarkMode } = useThemeMode();
  const [recieptDownloading, setRecieptDownloading] = useState(false);

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<{ meta: any; tx: any } & PartnersTransactions>;
  }>({
    on: false,
    data: {},
  });

  async function handleDownload(ref: string) {
    setRecieptDownloading(true);
    const resp = await  PosApi.get('admin/v2/download/receipt?transaction_id=' + ref, {
      baseURL: env.pos_api,
    })

    // console.log(resp.data, 'the resp')
    if (resp.data.status === "success") {
      const url = resp.data.data?.url;
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `transaction-reciept-#${ref}`;
      a.target = "_blank"; // Add this line
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }

    setRecieptDownloading(false);

  }
  return (
    <DashboardLayout>
      <PageLayout pageTitle="Transactions">
        <SmartFilter
          {...SmartFilterProps}
          searchTitle="Search Bugisss Transactions..."
        />

        <div className={cn("pb-trx-table", isDarkMode && "pb-trx-table-dark")}>
          {isEmpty(tableData) ? (
            <EmptyScreen loading={combinedLoading || isRedacting} />
          ) : (
            <RavenTable
              action={false}
              className="table__main"
              headerList={[
                "MERCHANT DETAILS",
                "DETAILS",
                "TYPE",
                "TRANSACTION DATE",
                "STATUS",
                "",
              ]}
            >
              {tableData.map((data, index) => {
                const meta = {}

                const tx: Partial<PosTxMetaData & PosCardTxMetaData & PosTrxTransferIn> =
                  Util.safeParseJson(data?.meta_data as string, {});
                return(
                <RavenTableRow
                  key={index}
                  onRowClick={() => setView({ on: true, data: { ...data, meta, tx } })}
                  one={
                  isRedacting ? <Redacted.Labelled/> :  <TwinValue
                      one={Util.safeText(data.poseidon_business_name)}
                      two={`PARTNER: ${data.app_name}`}
                    />
                  }
                  two={
                    isRedacting ? <Redacted.Labelled/> :   data.type ? (
                      data?.type === "pdon_standalone" ? (
                        <TwinValue
                          one={Util.safeText(
                            JSON.parse(data?.meta_data ?? "{}").card_pan ?? ""
                          )}
                          two={`RRN: ${Util.safeText(
                            JSON.parse(data?.meta_data ?? "{}").rrn ?? ""
                          )}`}
                        />
                      ) : (
                        isRedacting ? <Redacted.Labelled/> :  <TwinValue
                          one={Util.safeText(
                            JSON.parse(data?.meta_data ?? "{}").account_name ?? ""
                          )}
                          two={`${
                            JSON.parse(data?.meta_data ?? "{}").bank ?? ""
                          } • ${Util.safeText(
                            JSON.parse(data?.meta_data ?? "{}").account_number ?? ""
                          )}`}
                        />
                      )
                    ) : (
                      isRedacting ? <Redacted.Labelled/> :  <TwinValue one={Util.safeText("")} two={Util.safeText("")} />
                    )
                  }
                  three={
                    isRedacting ? <Redacted.Labelled/> :   <TwinValue
                      one={data.type ?? "Unknown"}
                      two={`Amount: ${formatNumberToCurrency(Number(data.amount))}`}
                    />
                  }
                  four={  isRedacting ? <Redacted.Table/> : formatDateTime(data.created_at)}
                  five={   isRedacting ? <Redacted.Badge/> :formatStatus(
                    detectStatus(parseInt(data.status ?? "10"), "transaction")
                  )}
                />
              )})}
            </RavenTable>
          )}
        </div>
      </PageLayout>

      <ActionModal
        visible={view.on}
        onCancel={() => {
          setView({ data: {}, on: false });
        }}
        onClick={() =>
          handleDownload(String(view.data))
        }
        actionText={"Download Reciept"}
        btnColor={"#F7F7F7"}
        btnTextColor={"#020202"}
        className="dispute-modal"
        normal
        loading={combinedLoading}
        hideCancel
        inlineCopyContent={generateModalContent(view as any)}
        title={`Transaction Details`}
      >
        {
          <InlineDetails
            direction={view?.data?.direction as string}
            title={"Transaction Details"}
            isTransaction
            simple
            className="no-shadow no-padding"
            amount={0}
            meta={JSON.stringify(view.data)}
            onClick={() => {}}
            content={generateModalContent(view as any)}
          />
        }
      </ActionModal>
    </DashboardLayout>
  );
};

function generateModalContent(view: {data: Partial<{
  meta: any;
  tx: any;
} & PosTransactions>}){
  
  return [
    {
      label: "Amount",
      value: `₦${formatNumWithComma(view.data.amount ?? 0, "ngn")}`,
      // copy: true,
    },

    
    ...renderConditionally(
      "Card Pan",
      view.data?.tx?.card_pan
        ? Util.maskCardPan(view.data?.tx?.card_pan)
        : undefined
    ),

    {
      label: "Bal Before",
      value: `₦${formatNumWithComma(view.data.b_before ?? 0, "ngn")}`,
    },
    {
      label: "Bal After",
      value: `₦${formatNumWithComma(view.data.b_after ?? 0, "ngn")}`,
    },

    ...renderConditionally("RRN", view?.data?.rrn),

    {
      label: "Fee",
      value: `₦${formatNumWithComma(view.data.fee ?? 0, "ngn")}`,
    },
    ...renderConditionally(
      "Recipient",
      formatMetaData(view.data.meta_data ?? "")?.account_name
    ),
    ...renderConditionally(
      "Account Number",
      view?.data?.tx?.account_number
        ? view?.data?.tx?.account_number
        : view?.data?.tx?.source
        ? view?.data?.tx?.account_number
        : null
    ),
    ...renderConditionally(
      "Bank",
      view?.data?.tx?.bank
        ? view?.data?.tx?.bank
        : view?.data?.tx?.source
        ? view?.data?.tx?.source?.bank
        : ""
    ),

    {
      label:
        view?.data?.direction === "credit" ? "Session ID" : "Transaction Ref",
      value: view.data?.reference,
      copy: true,
      trim: true,
      tooltip: true,
      tooltipValue: view.data?.reference,
    },

    ...renderConditionally("TID", Util.safeText(view.data?.t_id)),


    {
      label: "Date Authorized",
      value: formatDateTime(view?.data.created_at ?? ""),
    },
  ]
}


export default PartnersTransactions;
