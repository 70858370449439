import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/layouts/dashboard/dashboardLayout";
import PageLayout from "../../../components/layouts/dashboard/pageLayout";

import EmptyScreen from "../../../components/common/emptyScreen";
import {
  RavenButton,
  RavenInputField,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import { IconVault, formatStatus, mapHotkey } from "@/utils/helper/Helper";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "../../../components/common/modal/ActionModal";

import { icons } from "@/assets/icons";
import illust1 from "@/assets/images/notification-center-1.png";
import illust2 from "@/assets/images/notification-center-2.png";
import illust3 from "@/assets/images/email_notif.svg";
import "./styles/index.css";
import SmartFilter from "@/components/common/smartFilter";
import TwinValue from "@/components/common/twinValue";

const Tabs = ["Push Notifications", "App Message", "Email Notification"];

const generateMockData = (tab: string) => {
  const mockData = [];

  mockData.push(
    {
      email: "tim.jennings@example.com",
      phone: "+234 9077 3437",
      bvn: "24348938934",
      initiator: "Dayo Aderamo",
      date: "Today • 12:48pm",
      status: "pending",
    },
    {
      email: "john.doe@example.com",
      phone: "+234 9077 3437",
      bvn: "24348938934",
      initiator: "Admin Jope",
      date: "Yesterday • 10:30am",
      status: "inReview",
    },
    {
      email: "john.doe@example.com",
      phone: "+234 9077 3437",
      bvn: "24348938934",
      initiator: "Admin Jope",
      date: "Yesterday • 10:30am",
      status: "resolved",
    }
    // Add more mock data as needed
  );

  return mockData;
};

const PosNotification = () => {
  const [empty, setEmpty] = useState(false);

  const [verify, showVerifyModal] = useState({ chi: "", on: false });
  const [view, setView] = useState<{ chi: any; on: boolean }>({
    chi: {},
    on: false,
  });
  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const [selectedTab, setSelectedTab] = React.useState<string>("Push Notifications");
  const mockData = generateMockData(selectedTab);
  const dropdown = [
    {
      label: `Set to Resolved`,
      img: IconVault(icons.check_circle),
      func: showVerifyModal,
    },
  ] as const;

  const tableHeader =
    selectedTab === "Email Notification"
      ? ["Notification Message", "Email", "Date Posted"]
      : ["Notification Message", "Type", "Date Posted"];
  return (
    <>
      <DashboardLayout>
        <PageLayout pageTitle="Notification Center">
          <div className="personal-notification__top-wrap">
            <TabComponent onValueChange={(e) => setSelectedTab(e)} defaultValue={Tabs[0]}>
              <TabComponent.Tabs tabs={Tabs} />
              {Tabs.map((tab) => (
                <TabComponent.Content key={tab} value={tab}></TabComponent.Content>
              ))}
            </TabComponent>
          </div>
          <div className="personal-notification__container">
            <div className="personal-notification__container--input-view">
              <div className="input-view">
                <div className="input-view__illust">
                  <figure>
                    <img
                      src={
                        selectedTab === "Email Notification"
                          ? illust3
                          : selectedTab === "Push Notifications"
                          ? illust1
                          : illust2
                      }
                      alt=""
                    />
                  </figure>
                </div>
                {selectedTab === "Push Notification" ? (
                  <>
                    <div className="input-view__input">
                      <RavenInputField
                        label="Category"
                        placeholder="Select a Notification Category"
                        type={"select"}
                        color="black-light"
                      />
                      <RavenInputField
                        label="Notification Title"
                        placeholder="Enter a Nofication title"
                        type={"text"}
                        color="black-light"
                      />
                      <RavenInputField
                        label="Notification Message"
                        placeholder="Write your thoughts here"
                        type={"textarea"}
                        color="black-light"
                      />
                    </div>
                  </>
                ) : (
                  <div className="input-view__input">
                    <RavenInputField
                      label="Message Title"
                      placeholder="Enter a Nofication title"
                      type={"text"}
                      color="black-light"
                    />
                    <RavenInputField
                      label="Notification Message"
                      placeholder="Write your thoughts here"
                      type={"textarea"}
                      color="black-light"
                    />
                  </div>
                )}

                {selectedTab === "Email Notification" ? (
                  <>
                    <div className="input-view__input">
                      <RavenInputField
                        label="Category"
                        placeholder="Select a Notification Category"
                        type={"select"}
                        color="black-light"
                      />
                      <RavenInputField
                        label="Notification Title"
                        placeholder="Enter a Nofication title"
                        type={"text"}
                        color="black-light"
                      />
                      <RavenInputField
                        label="Notification Message"
                        placeholder="Write your thoughts here"
                        type={"textarea"}
                        color="black-light"
                      />
                    </div>
                  </>
                ) : (
                  <div className="input-view__input">
                    <RavenInputField
                      label="Message Title"
                      placeholder="Enter a Nofication title"
                      type={"text"}
                      color="black-light"
                    />
                    <RavenInputField
                      label="Notification Message"
                      placeholder="Write your thoughts here"
                      type={"textarea"}
                      color="black-light"
                    />
                  </div>
                )}

                <div className="input-view__button">
                  <RavenButton color={"orange-light"} label="Push Notification" />
                </div>
              </div>
            </div>

            <div className="personal-notification__container--result-view focused">
              {/* <EmptyScreen noBorder /> */}
              {!empty ? (
                <>
                  <SmartFilter
                    page="insights"
                    simple
                    searchTitle={`Search Dispute...`}
                    pagination={{
                      currentPage: 0,
                      itemsPerPage: 0,
                      totalItems: 0,
                      onPageChange: function (page: number): void {
                        throw new Error("Function not implemented.");
                      },
                      activeFilter: `All ${selectedTab}`,
                    }}
                  />

                  <div className="table">
                    <RavenTable
                      action={false}
                      className="table__main"
                      headerList={tableHeader}
                    >
                      {mockData.map((data, index) => (
                        <RavenTableRow
                          key={index}
                          onRowClick={() => setView({ on: true, chi: data })}
                          {...{
                            one: (
                              <div className="table-icon-row">
                                <TwinValue
                                  one={(data.initiator as string) ?? " - -"}
                                  two="Ensure you have part of your salary saved for..."
                                />
                              </div>
                            ),

                            two: data.phone,
                            three: data.email,
                          }}
                        />
                      ))}
                    </RavenTable>
                  </div>
                </>
              ) : (
                <EmptyScreen />
              )}
            </div>
          </div>
        </PageLayout>
      </DashboardLayout>

      {/* <ActionModal
        visible={blockModal.on}
        onCancel={() => {
          showBlockModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Yes, Unblock"
        btnColor="#755AE2"
        body="Are you sure to unblock this user? You can always come back to perform the action."
        title="Unblock User?"
      /> */}

      <ActionModal
        visible={verify.on}
        onCancel={() => {
          showVerifyModal({ chi: "", on: false });
        }}
        onClick={() => {}}
        cancelText="No, Cancel"
        actionText="Transaction is Genuine"
        btnColor="#1ACE37"
        hideCancel={true}
        body="This would be sending a message to 235 businesses pertaining to their blocked accounts."
        title="Confirm the Integrity of Transaction"
      />
    </>
  );
};

export default PosNotification;
