import React, { useEffect, useState } from "react";
import SmartFilter from "../../../../components/common/smartFilter";
import EmptyScreen from "../../../../components/common/emptyScreen";
import {
  RavenButton,
  RavenCheckBox,
  RavenTable,
  RavenTableRow,
} from "@ravenpay/raven-bank-ui";
import { IconVault, formatDateTime, mapHotkey } from "@/utils/helper/Helper";
import { icons } from "@/assets/icons";
import "../styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import { BugissApi } from "@/redux/slices";
import Redacted from "@/components/common/shimmer/redact";
import { Util } from "@/utils/utility";
import { AnyAction, Dispatch, UnknownAction } from "@reduxjs/toolkit";
import { Row, XStack } from "@/components/common/stacks";
import TwinValue from "@/components/common/twinValue";
import { AppDispatch, useAppDispatch } from "@/redux/store";

const BlockedBusiness = () => {
  const [empty, setEmpty] = useState(false);
  const [allChecked, setIsAllChecked] = useState(false);
  const [checked, setChecked] = useState<SN[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [serverBlockReasons, setServerBlockReasons] = useState([]);
  const [blockModal, showBlockModal] = useState<{
    chi: any;
    on: boolean;
    reason: string;
    type?: string;
  }>({ chi: {}, on: false, reason: "" });
  const [sendMessages, showSendMessages] = useState<{
    chi: any;
    on: boolean;
  }>({ chi: {}, on: false });

  useEffect(() => {
    mapHotkey("l", function () {
      setEmpty(!empty);
    });
  }, [empty]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchRecords(dispatch, currentPage);
    setChecked([]);
    setIsAllChecked(false);
  }, [currentPage]);

  const { businesses, loading } = useSelector(
    (state: RootState) => state.businessBusinesses
  );

  const blockedBiz = businesses?.data;

  async function fetchBlockReasons() {
    const resp = await dispatch(BugissApi.business.fetchBlockReasons({}));

    if (resp.payload) {
      const r =
        resp.payload?.length > 0 &&
        resp.payload?.map((d: string) => {
          return {
            label: Util.cleanText(d),
            value: d,
          };
        });
      setServerBlockReasons(r);
    }
  }

  useEffect(() => {
    if (blockModal.on && blockModal?.type !== "multiple") {
      fetchBlockReasons();
    }
  }, [blockModal]);

  const { isRedacting } = useSelector((state: RootState) => state.general);

  const handleSelectAll = () => {
    if (allChecked) {
      setChecked([]);
      setIsAllChecked(false);
    } else {
      setChecked(blockedBiz.map(({ business_id }) => business_id));
      setIsAllChecked(!allChecked);
    }
  };
  const tableHeader = [
    <XStack gap={1} style={{ alignItems: "center" }}>
      <div style={{ opacity: false ? 0 : 1 }}>
        <RavenCheckBox
          checked={allChecked}
          id={69247}
          onChange={handleSelectAll}
          color="black-light"
        />
      </div>
      <span> BUSINESS DETAILS </span>
    </XStack>,
    "Reason for Block",
    "Initiator",
    "Date Blocked",
  ];
  const handleCheckBoxChange = (id: SN, state: boolean) => {
    if (state) {
      setIsAllChecked(false);

      setChecked((old) => [...old, id]);
    } else {
      setIsAllChecked(false);

      const unselectedIds = checked.filter((ref) => ref !== id);
      if (unselectedIds?.length < checked?.length) setChecked(unselectedIds);
    }
  };

  const isCheckActive = checked?.length > 0 || allChecked;

  return (
    <div style={{ height: "67vh", display: "flex", flex: 1, flexDirection: "column" }}>
      <SmartFilter
        page="insights"
        hideFilter={isCheckActive}
        hideExport={isCheckActive}
        dynamicNode={
          isCheckActive ? (
            <Row gap={1.6}>
              <UnblockBizBtn
                onClick={() => {
                  triggerBlock(showBlockModal, blockModal);
                }}
              />
              <MessageBizBtn
                onClick={() => {
                  showSendMessages({
                    on: true,
                    chi: {
                      count: checked?.length,
                    },
                  });
                }}
              />
            </Row>
          ) : null
        }
        searchTitle={`Search Blocked Businesses...`}
        pagination={{
          currentPage: Number(currentPage ?? 1),
          itemsPerPage: Number(businesses.per_page),
          // itemsPerPage: Number(businesses.per_page),
          totalItems: businesses.total,
          onPageChange: function (page: number): void {
            setCurrentPage(page);
          },
          activeFilter: `All Blocked Businesses`,
        }}
      />

      {(!isRedacting && loading) || blockedBiz?.length === 0 || !blockedBiz ? (
        <EmptyScreen loading={loading} />
      ) : (
        <div className="table">
          <RavenTable action={false} className="table__main" headerList={tableHeader}>
            {blockedBiz?.map((data: Partial<BlockedBusiness>, index: number) => (
              <RavenTableRow
                key={index}
                {...{
                  one: loading ? (
                    <Redacted.Table />
                  ) : (
                    <XStack gap={1} style={{ alignItems: "center" }}>
                      <div style={{ opacity: loading ? 0 : 1 }}>
                        <RavenCheckBox
                          id={Number(data?.business_id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleCheckBoxChange(Number(data?.business_id), true);
                            } else {
                              handleCheckBoxChange(Number(data?.business_id), false);
                            }
                          }}
                          color="black-light"
                          checked={
                            allChecked
                              ? allChecked
                              : checked.includes(
                                  (Number(data.business_id) as any) || allChecked
                                )
                          }
                        />
                      </div>
                      <TwinValue
                        one={Util.capitalizeEachWord(Util.safeValue(data.business_name))}
                        two={Util.safeValue(data.business_email)}
                      />
                    </XStack>
                  ),
                  two: loading ? <Redacted.Table /> : data.reason_for_block,
                  three: loading ? (
                    <Redacted.AvatarWithValue />
                  ) : (
                    Util.safeValue(data.initiator_id)
                  ),
                  four: loading ? (
                    <Redacted.Table />
                  ) : (
                    formatDateTime(String(data.blocked_date))
                  ),
                }}
              />
            ))}
          </RavenTable>
        </div>
      )}
    </div>
  );
};

export default BlockedBusiness;

function triggerBlock(
  showBlockModal: React.Dispatch<
    React.SetStateAction<{ chi: any; on: boolean; reason: string }>
  >,
  blockModal: { chi: any; on: boolean; reason: string }
  // data: Partial<BlockedBusiness>
) {
  showBlockModal({
    ...blockModal,
    on: !blockModal.on,
    chi: {
      type: "multiple",
      status: 1,
    },

    // chi: {
    //   business_id: data.business_id,
    //   status: data.block_business,
    // },
  });
}

function fetchRecords(dispatch: AppDispatch, currentPage: number) {
  dispatch(
    BugissApi.business.fetchBlockedUsers({
      current_page: currentPage,
    })
  );
}

const MessageBizBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="resend-btn" color="orange-dark" onClick={onClick}>
      Resend Message
    </RavenButton>
  );
};

const UnblockBizBtn = ({ onClick }: { onClick(): void }) => {
  return (
    <RavenButton className="block-btn" color="orange-dark" onClick={onClick}>
      Unblock
    </RavenButton>
  );
};
