import EmptyScreen from "@/components/common/emptyScreen";
import React, { useEffect, useState } from "react";
import "../../styles/index.css";

import ComplianceCards from "@/components/common/complianceCards/ComplianceCards";
import { BugissApi } from "@/redux/slices";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import TabComponent from "@/components/common/Tabs";
import ActionModal from "@/components/common/modal/ActionModal";
import Gap from "@/components/common/styleComponents/Gap";
import { RavenInputField } from "@ravenpay/raven-bank-ui";
import { businessReduxAPI } from "@/redux/slices/business/api";
import { Util } from "@/utils/utility";
import { useAppDispatch } from "@/redux/store";

const SingleBusinessCompliance = () => {
  const { email } = useParams();

  const dispatch = useAppDispatch();

  const [view, setView] = useState<{
    on: boolean;
    data: Partial<BusinessTransactionData>;
  }>({
    on: false,
    data: {},
  });

  const { isRedacting } = useSelector((state: RootState) => state.general);

  // compliance int
  const [biz, setBiz] = useState<Partial<XBusinessesModel["business"]>>({});
  const [bizEmail, setBizEmail] = useState("");

  const [revokeModal, setRevokeModal] = useState<{on: boolean, type?: {label: string, value: string}, reason?: string}>({on: false, type: {label:'', value : ''}, reason: ''});
  const availableComliances = [
    {
      label: "BVN Verification",
      value: "bvn",
    },
    { label: "NIN Verification", value: "nin" },

    {
      label: "Address Verification",
      value: "address",
    },
    {
      label: "CAC Verification",
      value: "cac",
    },
    {
      label: "Business Details",
      value: "business_info",
    },
    {
      label: "Referees",
      value: "referees",
    },
    {
      label: "Shareholders",
      value: "shareholders",
    },
  ];
  const { business_compliances, loading } = useSelector(
    (state: RootState) => state.businessCompliance
  );

  const compliance =
    business_compliances?.data?.business_verification_details;

  const shareholders = business_compliances?.data?.shareholder_details_object
  const referrers = business_compliances?.data?.referral_details_object

  function detectCompliance(t: string): Partial<VerificationDetails> {
    const veriDetail = assertVeri(email as string, t);

    if (veriDetail) {
      return veriDetail;
    } else return {};
  }
  const [centralBiz, setCentralBiz] = useState<
    Partial<XBusinessesModel["business_info_on_central"]>
  >({});

  async function fetchData() {
    if (bizEmail) {
      const [businessesResponse] = await Promise.all([
        dispatch(
          BugissApi.business.fetchBusinessDetails({
            per_page: 20,
            business_email: email as string,
            // current_page: currentPage,
            // search_term: searchQuery,
          })
        ),
      ]);

      const resp = await businessesResponse;

      if (resp.payload?.status === "success") {
        setBiz(resp.payload.data.business);
        setCentralBiz(resp.payload.data.business_info_on_central);
      }
    }
  }

  useEffect(() => {
    if (Boolean(bizEmail)) {
      dispatch(
        BugissApi.business.fetchCompliance({
          business_email: bizEmail,
        })
      );
    }
  }, [bizEmail, email]);

  useEffect(() => {
    setBizEmail(email ?? "");
  }, []);
  useEffect(() => {
    fetchData();
  }, [email, bizEmail]);


  const assertVeri = (email: string, t: string): any => {
    try {
      var data: any = compliance[email as any];
      // const filter = data.filter((d) => d.type === selectedTab.value);
      const l = data?.filter((d: VerificationDetails) =>
        d?.id_verification_type.includes(t)
      )[0];

      if (l) {
        return l;
      } else {
        return [];
      }
    } catch (error) {
      console.log(error, "caught exception");
    }
  };

  const TierTab = ['Tier 1', 'Tier 2', 'Tier 3', 'Tier 4'];

  const reasonReq = businessReduxAPI.useFetchReasonsQuery(
    { type: "cac_decline" },
    { skip: !revokeModal?.on }
  );

  const declineReason = reasonReq?.data?.reasons ?? [];

  function formatDeclineReason() {
    return declineReason.map((reason: { reason: string; id: any; }) => {
      return {
        label: Util.cleanText(reason?.reason),
        value: reason?.id,
      };
    });
  }


  function selectCompliance(c:string[]) {
    return availableComliances.filter(d => c.some(keyword => d.value.includes(keyword)));
  }

  const handleRevokeDecline = async () => {

    const payload = {
      status: 0,
      business_id: biz.id,
      cac_revoke_reason: revokeModal?.reason,
      reason: revokeModal?.reason,
      action_to_take: declineReason.find((d: { reason: string | undefined; }) => d.reason === revokeModal.reason)?.action,
      deny_or_revoke: 'revoke',
    };


    let resp: any;

    switch (revokeModal.type?.value) {
      case 'nin':
        resp = await dispatch(
          BugissApi.business.verifyDeclineNIN(payload)
        );
        break
      case 'bvn':
        resp = await dispatch(
          BugissApi.business.verifyDeclineBVN(payload)
        );
        break
      case 'address':
        resp = await dispatch(
          BugissApi.business.verifyDeclineAddressVerification(payload)
        );
        break
      case 'shareholder':
        resp = await dispatch(
          BugissApi.business.verifyDeclineAddressVerification(payload)
        );
        break
      case 'referral':
        resp = await dispatch(
          BugissApi.business.verifyDeclineAddressVerification(payload)
        );
        break
      case 'cac':
        resp = await dispatch(
          BugissApi.business.verifyDeclineAddressVerification(payload)
        );
        break

      default:
        return {}
    }
     resp = await dispatch(
      BugissApi.business.verifyDeclineAddressVerification(payload)
    );
    if (resp?.payload) {
      setRevokeModal({
        on: false, type: { label: '', value: '' }
      });
    }


    dispatch(
      BugissApi.business.fetchCompliance({
        verification_type: "address",
        business_email: email as string,
      })
    );
  };

  return (
    <div className="business-trx-wrap">
      <style>
        {`
          .compliance_tab {
            padding:0.5rem;
            overflow: hidden;
            button[data-state=active] {
            background-color: white;
           
           }
            button {
            min-width: 10rem;
            padding: 1rem;
            }
            
            .tabcomponent__list {
            border-bottom: none !important;
          }
        `}
      </style>
      <TabComponent defaultValue={TierTab[0]}>

        <div style={{ width: '100%', background: "#F9F9F9", border: "none", borderRadius: "1rem" }}>
          <TabComponent.Tabs tabs={TierTab} className={'compliance_tab'} />
        </div>
        <TabComponent.Content key={TierTab[0]} value={TierTab[0]}>
          {(!isRedacting && loading) || availableComliances?.length === 0 ? (
            <EmptyScreen loading={loading} />
          ) : (
            selectCompliance(['bvn', 'nin']).map((chi, idx) => {

                return <ComplianceCards
                  key={idx}
                  // onRevoke={() => setRevokeModal({
                  //   on: true,
                  //   type: chi
                  // })}
                  data={{
                    business_email: email,
                    business_details: biz,
                    central_biz: centralBiz,
                    director_details: business_compliances?.data
                      ?.directors_details_object ?? [],
                    app: "Business Banking",
                    shareholders_details: shareholders,
                    referee_details: referrers,
                    type: chi,
                    veriDetail: detectCompliance(chi.value),
                  }}
                />
              }
            )
          )}
        </TabComponent.Content>

        <TabComponent.Content key={TierTab[1]} value={TierTab[1]}>
          {(!isRedacting && loading) || availableComliances?.length === 0 ? (
            <EmptyScreen loading={loading} />
          ) : (
            selectCompliance(['address']).map((chi, idx) => {

                return <ComplianceCards
                  key={idx}
                  data={{
                    business_email: email,
                    business_details: biz,
                    central_biz: centralBiz,
                    director_details: business_compliances?.data
                      ?.directors_details_object,
                    shareholders_details: shareholders,
                    referee_details: referrers,
                    app: "Business Banking",
                    type: chi,
                    veriDetail: detectCompliance(chi.value),
                  }}
                />
              }
            )
          )}
        </TabComponent.Content>

        <TabComponent.Content key={TierTab[2]} value={TierTab[2]}>
          {(!isRedacting && loading) || availableComliances?.length === 0 ? (
            <EmptyScreen loading={loading} />
          ) : (
            selectCompliance(['cac', 'business_info', 'shareholder']).map((chi, idx) => {

                return <ComplianceCards
                  key={idx}
                  data={{
                    business_email: email,
                    business_details: biz,
                    central_biz: centralBiz,
                    director_details: business_compliances?.data
                      ?.directors_details_object,
                    app: "Business Banking",
                    type: chi,
                    shareholders_details: shareholders,
                    referee_details: referrers,
                    veriDetail: detectCompliance(chi.value),
                  }}
                />
              }
            )
          )}
        </TabComponent.Content>


        <TabComponent.Content key={TierTab[3]} value={TierTab[3]}>
          {(!isRedacting && loading) || availableComliances?.length === 0 ? (
            <EmptyScreen loading={loading} />
          ) : (
            selectCompliance(['referee']).map((chi, idx) => {

                return <ComplianceCards
                  key={idx}
                  data={{
                    business_email: email,
                    business_details: biz,
                    central_biz: centralBiz,
                    director_details: business_compliances?.data
                      ?.directors_details_object,
                    app: "Business Banking",
                    shareholders_details: shareholders,
                    referee_details: referrers,
                    type: chi,
                    veriDetail: detectCompliance(chi.value),
                  }}
                />
              }
            )
          )}
        </TabComponent.Content>
      </TabComponent>

      <>


      </>

      <ActionModal
        visible={revokeModal.on}
        onCancel={() => {
          setRevokeModal({ on: false, type: { label: '', value: '' } });
        }}
        onClick={handleRevokeDecline}
        loading={loading}
        cancelText="No, Cancel"
        actionText="Yes, Revoke"
        btnColor="#FF0F00"
        body="Are you sure you want to cancel the verification process? You can always come back to perform the action.."
        title={`Revoke ${revokeModal?.type?.label}?`}
      >
        <>
          <p>
            Are you sure you want to cancel the verification process? You can always come
            back to perform the action..
          </p>
          <Gap size={20} />
          <RavenInputField
            color={"black-light"}
            onChange={(e: any) => {
              setRevokeModal({
                ...revokeModal,
                reason: e,
              });
            }}
            type="select"
            selectOption={formatDeclineReason()}
            value={revokeModal.reason}
            label={"Revoke reason*"}
            placeholder="e.g 'Fake CAC'"
          />
        </>
      </ActionModal>
    </div>
  );
};

export default SingleBusinessCompliance;
