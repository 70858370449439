import env from "@/env";
import { PosBuilder, posGetHandler } from "..";
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const PosOverviewApi = (builder: PosBuilder) => {
  return {
    getOverview: builder.query<PosOverview, any>({
      queryFn: (params: any, thunk: BaseQueryApi | undefined) => {
        return posGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `dashboard`,
            params
          },
          thunk
        );
      }
    }),
    getInsight: builder.query<{ overview: InsightsMetrics }, any>({
      queryFn: (params: { module: string }, thunk: BaseQueryApi | undefined) => {
        return posGetHandler(
          {
            baseUrl: env.pos_api + "/admin/v2",
            route: `insight`,
            params,
          },
          thunk
        );
      },
    }),
  };
};
