import { getOverview } from "./overview";
import { downloadReciept, generateStatement, getTransactions } from "./transaction";
import {
  blockUnlockBusiness,
  fetchBusinessDetails,
  fetchSingleBusinessTransfer,
  fetchBusinessTrx,
  getBusinesses,
  fetchSingleInternalTransfer,
  fetchBulkTransfer,
  fetchBulkTransferTrx,
  fetchAirtime,
  fetchData,
  fetchCable,
  fetchElectricity,
  fetchBetting,
  deductABusiness,
  fetchCards,
  fetchCardsTrx,
  lienBusiness,
  removeLienBusiness,
  getBizBankAccounts,
  fetchBlockedUsers,
  fetchRagnaroked,
  blockUnlockUser,
  fetchBlockReasons,
  confirmBusinessIntegrity,
  unblockMultipleBusiness,
  sendBulkMessages,
  fetchBulkTransferTrxTwo,
} from "./business";
import {
  freezeBusinessCard,
  getBusinessCardDetails,
  getBusinessCards,
  unfreezeBusinessCard,
} from "./cards";
import { getExpenses, getExpenseCategory } from "./expenses";
import {
  fetchAddressDeclineReason,
  fetchCacDeclineReason,
  fetchCompliance,
  verifyDeclineAddressVerification,
  verifyDeclineBVN,
  verifyDeclineCacVerification,
  verifyDeclineNIN,
} from "./compliance";
import { getDisbursements, getEmployeeDetails, getPayrolls } from "./payroll";
import {
  deleteInvoice,
  downloadInvoice,
  fetchInvoice,
  fetchSingleInvoice,
} from "./invoice";
import {
  deletePaymentLink,
  fetchPaymentLinkTrx,
  fetchPaymentLinks,
} from "./paymentLinks";
import { fetchDeposits } from "./deposits";

export const BusinessBankingAPI = {
  getOverview: getOverview,
  getTransactions: getTransactions,
  getBusinesses: getBusinesses,
  blockUnlockBusiness: blockUnlockBusiness,
  blockUnlockUser: blockUnlockUser,
  fetchBusinessDetails: fetchBusinessDetails,
  fetchBusinessTrx: fetchBusinessTrx,
  getExpenses: getExpenses,
  getExpenseCategory,
  fetchSingleBusinessTransfer: fetchSingleBusinessTransfer,
  fetchSingleInternalTransfer: fetchSingleInternalTransfer,
  fetchBulkTransfer: fetchBulkTransfer,
  fetchBulkTransferTrx: fetchBulkTransferTrx,
  fetchBulkTransferTrxTwo: fetchBulkTransferTrxTwo,
  fetchAirtime: fetchAirtime,
  fetchData: fetchData,
  fetchCable: fetchCable,
  getBusinessCards,
  fetchElectricity: fetchElectricity,
  fetchBetting: fetchBetting,
  deductABusiness: deductABusiness,
  fetchCards: fetchCards,
  fetchCardsTrx: fetchCardsTrx,
  fetchCompliance,
  verifyDeclineAddressVerification: verifyDeclineAddressVerification,
  verifyDeclineBVN: verifyDeclineBVN,
  verifyDeclineNIN: verifyDeclineNIN,
  lienBusiness: lienBusiness,
  removeLien: removeLienBusiness,
  getBizBankAccounts: getBizBankAccounts,
  getBusinessCardDetails: getBusinessCardDetails,
  freezeBusinessCard: freezeBusinessCard,
  unfreezeBusinessCard: unfreezeBusinessCard,
  getPayrolls: getPayrolls,
  getDisbursements: getDisbursements,
  getEmployeeDetails: getEmployeeDetails,
  fetchInvoices: fetchInvoice,
  fetchSingleInvoice: fetchSingleInvoice,
  fetchBlockedUsers: fetchBlockedUsers,
  fetchRagnaroked: fetchRagnaroked,
  verifyDeclineCacVerification: verifyDeclineCacVerification,
  fetchCacDeclineReason: fetchCacDeclineReason,
  fetchAddressDeclineReason: fetchAddressDeclineReason,
  fetchBlockReasons: fetchBlockReasons,
  confirmBusinessIntegrity: confirmBusinessIntegrity,
  unblockMultipleBusiness: unblockMultipleBusiness,
  sendBulkMessages: sendBulkMessages,
  fetchPaymentLinks,
  fetchPaymentLinkTrx: fetchPaymentLinkTrx,
  deletePaymentLink: deletePaymentLink,
  deleteInvoice: deleteInvoice,
  downloadInvoice: downloadInvoice,
  downloadTransactionReciept: downloadReciept,
  generateStatement: generateStatement,
  fetchDeposits: fetchDeposits,

};
