import React from "react";
import styled from "styled-components";
import { icons } from "@/assets/icons";
import { RavenNumberFormat } from "@ravenpay/raven-bank-ui";

interface TransferStatusMetricsProps {
  icon?: React.ReactNode;
  type?: "success" | "failed" | "pending";
  title: string;
  value: string;
  count?: string;
  countNumberOptions?: NumberOption;
  valueNumberOptions?: NumberOption;
}

const StyledMetricsContainer = styled.div`
  background-color: white;
  border-radius: 0.8rem;
  padding: 1.6rem;
  border-radius: 8px;
  position: relative;
  border: 1px solid var(--RAVEN-black-100, #eee);
  background: #fff;
`;

const StyledTitle = styled.h4`
  color: var(--BaseBlack-Base-BG-Dark, #676767);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
`;

const StyledValue = styled.p`
  font-size: 2.4rem;
  font-weight: bold;
  color: #111827;
  margin: 0 0 0.4rem 0;
`;

const StyledCount = styled.p`
  color: var(--BaseBlack-Base-BG-Dark, #676767);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
`;

const StyledIcon = styled.figure<{ type: string }>`
  width: 3.5rem;
  height: 3.5rem;
  margin: 0;
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  color: ${(props) =>
    props.type === "success"
      ? "#10B981"
      : props.type === "failed"
      ? "#EF4444"
      : "#F59E0B"};
`;

const assertIcon = (type: TransferStatusMetricsProps["type"]) => {
  switch (type) {
    case "failed":
      return icons.error_filled_red;
    case "success":
      return icons.check_green_filled;
    case "pending":
      return icons.info_filled_orange;
  }
};

const TransferStatusMetrics: React.FC<TransferStatusMetricsProps> = ({
  title,
  value,
  icon,
  type = "success",
  count,
  countNumberOptions = { hideDecimal: true, hideSymbol: true },
  valueNumberOptions = { hideDecimal: true, hideSymbol: true },
}) => {
  const IconElement = icon ?? assertIcon(type);

  return (
    <StyledMetricsContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledValue>{RavenNumberFormat(value, valueNumberOptions)}</StyledValue>
      {count && (
        <StyledCount>Count: {RavenNumberFormat(count, countNumberOptions)}</StyledCount>
      )}
      <StyledIcon type={type}>{IconElement}</StyledIcon>
    </StyledMetricsContainer>
  );
};

export default TransferStatusMetrics;
