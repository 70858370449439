import { PartnersBuilder, partnersGetHandler } from "..";
import { createQueryWithRoute } from "@/utils/api-methods/partners"; // Adjust the path as needed

export const PosDailySummaryApi = (builder: PartnersBuilder) => {
  return {
    getDailySummary: createQueryWithRoute<
      PosDailySummaryModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "daily_summary", { name: "getDailySummary" }),

    getDailySummaryExport: createQueryWithRoute<
      PosDailySummaryModel,
      Partial<PosPaginationParams & { status?: SN }>
    >(builder, "daily_summary", { export: true, name: "getDailySummaryExport" }),
  };
};
