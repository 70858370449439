import PartnersOverview from "@/apps/partners/overview";
import PartnersInsights from "@/apps/partners/insights";
import PartnersTransactions from "@/apps/partners/transactions";
import PartnersCollections from "@/apps/partners/collections";
import PartnersTransfers from "@/apps/partners/transfers";
import { PartnersExpenses } from "@/apps/partners/expenses";
import { PartnersMerchants } from "@/apps/partners/merchants";
import { MerchantDetails } from "@/apps/partners/merchants/merchant-details";
import { PartnersVirtualAccounts } from "@/apps/partners/virtual-accounts";
import { PartnersVirtualAccountDetails } from "@/apps/partners/virtual-accounts/virtual-account-details";
import { PartnersBills } from "@/apps/partners/bills";
import {
  BillsAirtimePage,
  BillsBettingPage,
  BillsCablePage,
  BillsDataPage,
  BillsElectricityPage,
} from "@/apps/partners/bills/components/pages";
import { PartnersCard } from "@/apps/partners/cards";
import { PartnersCardDetails } from "@/apps/partners/cards/card-details";
import PartnersBankbox from "@/apps/partners/bankbox";
import PartnersBankBoxDetail from "@/apps/partners/bankbox/bankboxDetail";
import PartnersCustomers from "@/apps/partners/customers";
import PartnersCompliance from "@/apps/partners/compliance";
import { PartnersTerminals } from "@/apps/partners/terminals";
import { PartnersPartners } from "@/apps/partners/partners";
import { PartnerDetails } from "@/apps/partners/partners/partners-details";
import { PartnersDeviceRequests } from "@/apps/partners/device-requests";
import { partners_insights_routes } from "./nested-routes/insights";
import PartnersFeeDetails from "@/apps/pos/fee-details";
import { PartnersSettlement } from "@/apps/partners/settlement";
import { PartnersTerminalDetails } from "@/apps/partners/terminals/terminal-details";

export const partners_routes_group: RouteArray[] = [
  { path: "/partners-overview", element: PartnersOverview },
  { path: "/partners-partners", element: PartnersPartners },
  { path: "/partners-partners/:email", element: PartnerDetails },
  { path: "/partners-insights", element: PartnersInsights },
  { path: "/partners-merchants", element: PartnersMerchants },
  { path: "/partners-merchants/:id", element: MerchantDetails },
  { path: "/partners-transfers", element: PartnersTransfers },
  { path: "/partners-transactions", element: PartnersTransactions },
  { path: "/partners-device-requests", element: PartnersDeviceRequests },
  { path: "/partners-collections", element: PartnersCollections },
  { path: "/partners-terminals", element: PartnersTerminals },
  { path: "/partners-fee-details", element: PartnersFeeDetails },
  { path: "/partners-terminals/:id", element: PartnersTerminalDetails },
  {
    path: "/partners-bills",
    element: PartnersBills,
    children: [
      { path: "", element: BillsAirtimePage },
      { path: "airtime", element: BillsAirtimePage },
      { path: "data", element: BillsDataPage },
      { path: "electricity", element: BillsElectricityPage },
      { path: "cable-tv", element: BillsCablePage },
      { path: "betting", element: BillsBettingPage },
    ],
  },
  { path: "/partners-cards", element: PartnersCard },
  { path: "/partners-cards/:id", element: PartnersCardDetails },
  { path: "/partners-bankbox", element: PartnersBankbox },
  { path: "/partners-bankbox/:id", element: PartnersBankBoxDetail },
  { path: "/partners-customers", element: PartnersCustomers },
  { path: "/partners-compliance", element: PartnersCompliance },
  { path: "/partners-settlements", element: PartnersSettlement },
  ...partners_insights_routes,
];
