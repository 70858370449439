import React, { useState, useEffect, ReactNode } from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import { icons } from "@/assets/icons";
import EmbedPDF from "../embedPDF/EmbedPDF";

interface LightboxProps {
  show: boolean;
  sources: ItemType[];
  onClose: () => void;
  onShow?: Function;
}

const Lightbox: React.FC<LightboxProps> = ({ show, sources, onClose, onShow }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    setIsMounted(true);
    onShow && onShow();
    return () => {
      setIsMounted(false);
    };
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? sources?.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === sources?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (isMaximized) {
        closeFullscreen();
      } else {
        onClose();
      }
    } else if (event.key === "ArrowLeft") {
      handlePrevious();
    } else if (event.key === "ArrowRight") {
      handleNext();
    }
  };

  const openFullscreen = () => {
    const element: any = document.documentElement;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
    setIsMaximized(true);
  };

  const closeFullscreen = () => {
    const element: any = document;

    if (element.fullscreenElement) {
      // Exit fullscreen
      if (element.exitFullscreen) {
        element.exitFullscreen();
      } else if (element.mozCancelFullScreen) {
        /* Firefox */
        element.mozCancelFullScreen();
      } else if (element.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        element.webkitExitFullscreen();
      } else if (element.msExitFullscreen) {
        /* IE/Edge */
        element.msExitFullscreen();
      }
    } else {
      console.log("Document is not in fullscreen mode.");
    }
    setIsMaximized(false);
  };

  // Attach event listener for keyboard arrow keys
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      setIsMaximized(false);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  if (!isMounted || !show) return null;
  const portal = document.getElementById("modal") as Element;

  return ReactDOM.createPortal(
    <div className="lightbox-overlay" onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()} className="lightbox-controls">
        <div className="lightbox-controls__pagination">{`${currentIndex + 1} of ${
          sources?.length
        }`}</div>
        <div className="lightbox-controls__actions">
          <figure onClick={isMaximized ? closeFullscreen : openFullscreen}>
            {isMaximized ? icons.minimize : icons.maximize}
          </figure>
          <figure onClick={onClose}>{icons.x_close}</figure>
        </div>
      </div>

      <div onClick={(e) => e.stopPropagation()} className="lightbox-nextprev">
        <figure onClick={handlePrevious}>{icons.arrow_circle_right}</figure>
        <figure onClick={handleNext}>{icons.arrow_circle_right}</figure>
      </div>
      <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
        {/* <span className="lightbox-close" onClick={onClose}>
          &times;
        </span> */}

        {detectType(sources[currentIndex] as ItemType) === "image" && (
          <img
            key={currentIndex}
            className="fade-in"
            src={sources[currentIndex] as string}
            alt={`lightbox-${currentIndex}`}
          />
        )}

        {detectType(sources[currentIndex] as ItemType) === "reactElement" && (
          <>{sources[currentIndex] as ReactNode}</>
        )}
        {detectType(sources[currentIndex] as ItemType) === "pdf" && (
          <EmbedPDF source={sources[currentIndex] as string} />
          //TODO:remember to remove
          // <EmbedPDF
          //   source={
          //     "https://jistflow.s3.amazonaws.com/upload/fbfc3ee1-c96e-4f2f-b669-98ff30c9e810"
          //   }
          // />
        )}

        {detectType(sources[currentIndex] as ItemType) === "iframe" && (
          <>{sources[currentIndex] as ReactNode}</>
        )}

        {sources?.length === 0 && <h6>No Sources Passed</h6>}
      </div>
    </div>,
    portal
  );
};

export default Lightbox;

type ImageItem = string;

type PdfItem = string;

type ReactElementItem = React.ReactNode;

type IframeItem = HTMLIFrameElement;

type ItemType = ImageItem | PdfItem | ReactElementItem | IframeItem;

function detectType(item: ItemType): string {
  if (typeof item === "string" && /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(item)) {
    return "image";
  } else if (typeof item === "string" && /\.(pdf)$/i.test(item)) {
    return "pdf";
  } else if (React.isValidElement(item)) {
    return "reactElement";
  } else if (item instanceof window.HTMLIFrameElement) {
    return "iframe";
  } else {
    return "unknown";
  }
}
