import { SEARCH_DOES_NOT_EXIST } from "@/constants";
import { PersonalBaseParams, PersonalBuilder, pbGetHandler, createPersonalURL } from "..";

type Response = CreatePersonalPaginatedResponse<
  "verifications",
  PersonalTokenVerification[]
>;

type AddressResponse = CreatePersonalPaginatedResponse<"addresses", PersonalAddress[]>;
type ManualComplianceReviewsResponse = CreatePersonalPaginatedResponse<
  "reviews",
  ManualCompliance[]
>;

type IDResponse = CreatePersonalPaginatedResponse<
  "verifications",
  PersonalIdVerification[]
>;

type Params = PersonalBaseParams & {
  type: "bvn" | "nin";
};

type DeclineReason = {
  reason: string;
  action: string;
};

type DeclineReasonRes = DeclineReason[];

type LimitRequest = CreatePersonalPaginatedResponse<"requests", PersonalLimitRequests[]>;

const filterByEmail = (params: any) => {
  return { ...params, search: null, email: params?.search };
};

export const PersonalComplianceAPI = (builder: PersonalBuilder) => {
  return {
    getUserCompliance: builder.query<PersonalComplianceResponse, string>({
      queryFn: (email) => {
        return pbGetHandler({ route: "compliance/user", params: { email } });
      },
    }),
    getUserIdToken: builder.query<Response, Params>({
      queryFn: (params) => {
        return pbGetHandler({
          route: createPersonalURL("compliance/id-tokens", params?.search),
          params,
        });
      },
    }),
    getManualAddress: builder.query<AddressResponse, PersonalBaseParams>({
      queryFn: (params) => {
        const route = params.search ? "search_user_addresses" : "pending-user-addresses";
        return pbGetHandler({ route, params: params });
      },
    }),
    getManualComplianceReview: builder.query<
      ManualComplianceReviewsResponse,
      PersonalBaseParams & { type: string }
    >({
      queryFn: (params) => {
        const route = params.search
          ? "compliance/manual-kyc-reviews"
          : "compliance/manual-kyc-reviews";
        return pbGetHandler({ route, params: params });
      },
    }),
    getManualIdVerification: builder.query<IDResponse, PersonalBaseParams>({
      queryFn: (params) => {
        const route = params.search
          ? "search_pending_manual_id_verifications"
          : "pending-user-manual-id-verifications";

        return pbGetHandler({ route, params: params });
      },
    }),

    getManualNinVerificationInfo: builder.query<
      ManualNinComplianceData,
      { nin: string; email: string }
    >({
      queryFn: (params) => {
        const route = "compliance/user-nin-information";

        return pbGetHandler({ route, params: params });
      },
    }),

    getManualBvnInfo: builder.query<ManualBvnData, { bvn: string; email: string }>({
      queryFn: (params) => {
        const route = "compliance/user-bvn-information";
        return pbGetHandler({ route, params: params });
      },
    }),
    getManualNinInfo: builder.query<Info, { nin: string; email: string }>({
      queryFn: (params) => {
        const route = "check-user-nin";
        return pbGetHandler({ route, params: params });
      },
    }),
    getDeclineReasons: builder.query<DeclineReasonRes, string | void>({
      queryFn: (type) => {
        return pbGetHandler({
          route: createPersonalURL(
            `get_decline_reasons?type=${type ?? "address"}`,
            SEARCH_DOES_NOT_EXIST
          ),
          params: {},
        });
      },
    }),
    getLimitRequests: builder.query<LimitRequest, PersonalBaseParams>({
      queryFn: (params) => {
        return pbGetHandler({
          route: createPersonalURL("limit-requests", SEARCH_DOES_NOT_EXIST),
          params: filterByEmail(params),
        });
      },
    }),
  };
};
