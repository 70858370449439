import React, { ReactNode } from 'react'
import './styles/index.css'
const ModalLayout = ({children}: {children:ReactNode}) => {
  return (
    <div className="new-notification">
        <div className="new-notification__left">

        </div>

        <div className="new-notification__right">
            {children}

        </div>
    </div>
  )
}

export default ModalLayout