import React, { useState } from 'react'
import '../styles/index.css'
import ModalLayout from '../modal_layout'
import { Column, Row } from '@/components/common/stacks'
import { RavenButton, RavenInputField, toast } from '@ravenpay/raven-bank-ui'
import { sendRequest } from '@/utils/api-methods'
import env from '@/env'
import { useProductColor } from '@/context/ProductTheme'
import { cn } from '@/utils/colorConvert'
import { icons } from '@/assets/icons'
import { formatDate, formatStatus, IconVault } from '@/utils/helper/Helper'
import { Util } from '@/utils/utility'
import Dropdown from '@/components/common/dropdown'
import ActionDrop from '@/components/common/actionDrop/ActionDrop'
const PreviewNotification = ({data, onAction}: {data: NotificationsPersonal, onAction: (e?:any) => void}) => {

  const [formData, setFormData] = useState({
    title: undefined,
    topic: undefined,
    message: undefined,
  });
  const [reqn, setIsReqn] = useState(false);

  function handleForm(e: any) {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  }

  async function handleSendPushNotification() {
    setIsReqn(true);

    let resp;

    resp = await sendRequest("/support/create-notification", formData, {
      base_url: env.personal_api
    });
    if (resp?.status === "success") {
      setFormData({
        title: undefined,
        topic: undefined,
        message: undefined,
      });

      toast.success(resp?.message);
      setIsReqn(false);
    } else {
      setIsReqn(false);
      toast.success(resp?.message);
    }
  }

  const { colorVariable } = useProductColor()

  const [step, setStep] = useState(0)

  const instantIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E9F5FF" />
    <path
      d="M11.1107 9.66408C11.8875 9.38952 12.4441 8.6487 12.4441 7.77789C12.4441 6.7363 11.6478 5.88068 10.6308 5.78649C10.4228 4.52106 9.32396 3.55566 7.99962 3.55566C6.67528 3.55566 5.57643 4.52106 5.36839 5.78649C4.3514 5.88068 3.55518 6.7363 3.55518 7.77789C3.55518 8.6487 4.11171 9.38952 4.88851 9.66408M8.44406 7.11122L6.66629 9.77789H9.33295L7.55518 12.4446"
      stroke="#476885" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


  const timeIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="#E9F5FF" />
    <path
      d="M7.99962 5.33295V7.99962L9.7774 8.88851M12.4441 7.99962C12.4441 10.4542 10.4542 12.4441 7.99962 12.4441C5.54502 12.4441 3.55518 10.4542 3.55518 7.99962C3.55518 5.54502 5.54502 3.55518 7.99962 3.55518C10.4542 3.55518 12.4441 5.54502 12.4441 7.99962Z"
      stroke="#476885" stroke-width="0.8" stroke-linecap="round" stroke-linejoin="round" />
  </svg>


  const [notificationDelivery, setNotificationDelivery] = useState({
    type: 'instant',
    date: new Date(),
    time: '0:00pm'
  })


  return (
    <ModalLayout>
      <div className="create-notification">
        <Column style={{ marginTop: '5rem' }}>
          <Column style={{ width: '100%' }} gap={0}>
            <h6>Push Notification</h6>
            <h5>{Util.safeText(data.title)}</h5>
          </Column>

          <Row jc='space-between' className="status-action-bar">
            <Row ai='center' gap={1}>
              {formatStatus(data?.status)}
              {formatStatus(data?.scheduled_time ? <Row ai='center' gap={0.5}>
                {timeIcon}
                Scheduled
              </Row> as any : <Row ai='center' gap={0.5}>
                {instantIcon}
                Instant Dispatch
              </Row> as any)}
            </Row>

            <Row ai='center' gap={0.5}>
              {data?.status !== 'approved' &&
              <figure onClick={() => onAction?.({
                type: 'edit',
                data
              })} className="">
                {React.cloneElement(icons.edit_04, {
                  style: {
                    background: '#F7F7F7',
                    padding: '1rem',
                    borderRadius: '50px',
                    width: '3.5rem',
                    height: '3.5rem'
                  }
                })}
              </figure>}

              <Dropdown

                rightPosition
                items={[
                  ...(data?.status?.toLowerCase() !== "approved"
                    ? [
                      {
                        label: "Edit Notifications",
                        img: IconVault(icons.edit_04),
                      },
                    ]
                    : [
                      {
                        label: "Refire Notifications",
                        img: IconVault(icons.refire),
                      },
                    ]),

                  ...(data?.status?.toLowerCase() !== "approved"
                    ? [
                      {
                        label: "Approve Notifications",
                        img: IconVault(icons.check_circle_green),
                      },
                    ]
                    : []),

                  ...(!["rejected", "approved"].includes(data?.status?.toLowerCase())
                    ? [
                      {
                        label: "Reject Notifications",
                        img: IconVault(icons.x_circle_red),
                      },
                    ]
                    : []),
                ]}
                onChange={(e) =>

                  onAction?.({
                    type: e.label.split(" ")[0].toLowerCase(),
                    data
                  })
                }
                closeOnChange
                buttonChild={() => {
                  return React.cloneElement(icons.more, { style: { width: '3.5rem', height: '3.5rem' } })
                }}
              />

            </Row>
          </Row>

          <Column gap={1}>
            <div className="notification-content-box">
              {Util.safeText(data.message)}
            </div>

            <p className="last-edited">
              Last Edited: {formatDate(data?.updated_at)}
            </p>


          </Column>

        </Column>

      </div>
    </ModalLayout>
  )
}

export default PreviewNotification